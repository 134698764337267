import { s, string, number } from 'modules/types'

export class ActiveEmployee {
	constructor({
		id,
		name,
		NIK,
		department_id: departmentId,
		direct_supervisor_id: superiorId,
		title_id: titleId,
		group_id: groupId,
		rank_id: rankId,
		position_id: positionId,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.departmentId = departmentId
		this.superiorId = superiorId
		this.titleId = titleId
		this.groupId = groupId
		this.rankId = rankId
		this.positionId = positionId
	}
}

export const ActiveEmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	departmentId: number.isRequired,
	superiorId: number.isRequired,
	titleId: number.isRequired,
	groupId: number.isRequired,
	rankId: number.isRequired,
	positionId: number.isRequired,
})

export default ActiveEmployee
