import { s, number, string } from 'modules/types'

export class Employee {
	constructor({
		id,
		NIK,
		full_name: fullName,
		department_name: departmentName,
		group_name: groupName,
		title_name: titleName,
		rank_name: rankName,
		department_id: departmentId,
		group_id: groupId,
		title_id: titleId,
		rank_id: rankId,
		direct_supervisor_id: directSupervisorId,
		position_id: positionId,
		position_name: positionName,
	}) {
		this.id = id
		this.NIK = NIK
		this.fullName = fullName
		this.departmentId = departmentId
		this.departmentName = departmentName
		this.groupId = groupId
		this.groupName = groupName
		this.titleId = titleId
		this.titleName = titleName
		this.superiorId = directSupervisorId
		this.rankId = rankId
		this.rankName = rankName
		this.positionId = positionId
		this.positionName = positionName
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	fullName: string.isRequired,
	departmentId: number,
	departmentName: string,
	groupId: number,
	groupName: string,
	titleId: number,
	titleName: string,
	rankId: number,
	rankName: string,
	positionId: number,
	positionName: string,
})

export default Employee
