import { s, arrayOf } from 'modules/types'
import IdeaFlow, { IdeaFlowType } from './IdeaFlow'
import EmployeeIdeaFlow, { EmployeeIdeaFlowType } from './EmployeeIdeaFlow'

export class IdeaReviewer {
	constructor({ idea_flows: ideaFlows, employee }) {
		this.ideaFlows = ideaFlows.map(item => new IdeaFlow(item))
		this.employee = new EmployeeIdeaFlow(employee)
	}
}

export const IdeaReviewerType = s({
	ideaFlows: arrayOf(IdeaFlowType),
	employee: EmployeeIdeaFlowType,
})

export default IdeaReviewer
