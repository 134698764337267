import { s, number, string } from 'modules/types'

class Filter {
	constructor({ filter_value_id: id, filter_value_name: name }) {
		this.id = id
		this.name = name
	}
}

export const FilterType = s({
	id: number.isRequired,
	name: string.isRequired,
})

export default Filter
