import { s, number } from 'modules/types'
import EmployeeWithRank, { EmployeeWithRankType } from './EmployeeWithRank'

export class ProfileSummary {
	constructor({ complete_percentage: completePercentage, employee }) {
		this.completePercentage = completePercentage
		this.employee = new EmployeeWithRank(employee)
	}
}

export const ProfileSummaryType = s({
	completePercentage: number.isRequired,
	employee: EmployeeWithRankType,
})

export default ProfileSummary
