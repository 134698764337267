import _ from 'lodash'
import api from 'modules/api'
import { PERMISSIONS } from 'modules/constants'
import { refreshRanks, refreshGroupLeaderRoles } from '../../TimeoffSetting/modules/timeoffSetting'
import EditTimeoffApprover from 'modules/models/setting/timeoff/EditTimeoffApprover'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `employeeSchedule`

export const API_URLS = {
	employees: () => `/api/setting/idea/employees/`,
	holidayTypes: () => `/api/setting/timeoff/holiday_types/`,
	workingCycleTypes: () => `/api/setting/timeoff/working_cycle_types/`,
	workingCycleGroups: () => `/api/setting/timeoff/working_cycle_groups/`,
	workingCycleGroupDetail: workingCycleGroupId => `/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/`,
	holidayGroups: () => `/api/setting/timeoff/holiday_groups/`,
	holidayGroupDetail: groupId => `/api/setting/timeoff/holiday_groups/${groupId}/`,
	timeoffGroups: () => `/api/setting/timeoff/timeoff_groups/`,
	timeoffGroupDetail: groupId => `/api/setting/timeoff/timeoff_groups/${groupId}/`,
	timeoffSetting: employeeId => `/api/setting/timeoff/employees/${employeeId}/`,
	timeoffApprovers: employeeId => `/api/setting/timeoff/employees/${employeeId}/get_timeoff_approvers/`,
	timeoffApprovees: employeeId => `/api/setting/timeoff/employees/${employeeId}/get_timeoff_approvees/`,
}

const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING_EMPLOYEES`
const REFRESHING_WORKING_CYCLE_TYPES = `${MODULE_NAME} | REFRESHING_WORKING_CYCLE_TYPES`
const REFRESHING_HOLIDAY_TYPES = `${MODULE_NAME} | REFRESHING_HOLIDAY_TYPES`
const REFRESHING_HOLIDAY_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUP_DETAIL`
const REFRESHING_TIMEOFF_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_DETAIL`
const REFRESHING_WORKING_CYCLE_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING_WORKING_CYCLE_GROUP_DETAIL`
const REFRESHING_HOLIDAY_GROUPS = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUPS`
const REFRESHING_WORKING_CYCLE_GROUPS = `${MODULE_NAME} | REFRESHING_WORKING_CYCLE_GROUPS`
const REFRESHING_TIMEOFF_GROUPS = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUPS`
const REFRESHING_TIMEOFF_SETTING = `${MODULE_NAME} | REFRESHING_TIMEOFF_SETTING`
const REFRESHING_TIMEOFF_APPROVERS = `${MODULE_NAME} | REFRESHING_TIMEOFF_APPROVERS`
const REFRESHING_TIMEOFF_APPROVEES = `${MODULE_NAME} | REFRESHING_TIMEOFF_APPROVEES`

const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED_EMPLOYEES`
const REFRESHED_WORKING_CYCLE_TYPES = `${MODULE_NAME} | REFRESHED_WORKING_CYCLE_TYPES`
const REFRESHED_HOLIDAY_TYPES = `${MODULE_NAME} | REFRESHED_HOLIDAY_TYPES`
const REFRESHED_HOLIDAY_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUP_DETAIL`
const REFRESHED_TIMEOFF_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUP_DETAIL`
const REFRESHED_WORKING_CYCLE_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED_WORKING_CYCLE_GROUP_DETAIL`
const REFRESHED_HOLIDAY_GROUPS = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUPS`
const REFRESHED_WORKING_CYCLE_GROUPS = `${MODULE_NAME} | REFRESHED_WORKING_CYCLE_GROUPS`
const REFRESHED_TIMEOFF_GROUPS = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUPS`
const REFRESHED_TIMEOFF_SETTING = `${MODULE_NAME} | REFRESHED_TIMEOFF_SETTING`
const REFRESHED_TIMEOFF_APPROVERS = `${MODULE_NAME} | REFRESHED_TIMEOFF_APPROVERS`
const REFRESHED_TIMEOFF_APPROVEES = `${MODULE_NAME} | REFRESHED_TIMEOFF_APPROVEES`

const CHANGED_HOLIDAY_GROUP = `${MODULE_NAME} | CHANGED_HOLIDAY_GROUP`
const CHANGED_WORKING_CYCLE_GROUP = `${MODULE_NAME} | CHANGED_WORKING_CYCLE_GROUP`
const CHANGED_TIMEOFF_GROUP = `${MODULE_NAME} | CHANGED_TIMEOFF_GROUP`
const EDITED_APPROVERS = `${MODULE_NAME} | EDITED_APPROVERS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------

function refreshEmployees() {
	return dispatch => {
		let apiUrl = API_URLS.employees()
		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.setting.idea.getEmployees().then(({ data }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, data, apiUrl })
		})
	}
}

function refreshWorkingCycleTypes() {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleTypes()
		dispatch({ type: REFRESHING_WORKING_CYCLE_TYPES, apiUrl })
		return api.working_cycle_setting.working_cycle_types.get().then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_TYPES, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayTypes() {
	return dispatch => {
		let apiUrl = API_URLS.holidayTypes()
		dispatch({ type: REFRESHING_HOLIDAY_TYPES, apiUrl })
		return api.setting.timeoff.holiday_types.get().then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_TYPES, data, apiUrl })
			return data
		})
	}
}

function refreshWorkingCycleGroups() {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroups()
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUPS, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUPS, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroups() {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroups()
		dispatch({ type: REFRESHING_HOLIDAY_GROUPS, apiUrl })
		return api.setting.timeoff.holiday_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUPS, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroups() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroups()
		dispatch({ type: REFRESHING_TIMEOFF_GROUPS, apiUrl })
		return api.setting.timeoff.timeoff_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUPS, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffSetting(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffSetting(employeeId)
		dispatch({ type: REFRESHING_TIMEOFF_SETTING, apiUrl })
		return api.setting.timeoff.employees.getTimeoffSetting(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_SETTING, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffApprovers(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffApprovers(employeeId)
		dispatch({ type: REFRESHING_TIMEOFF_APPROVERS, apiUrl })
		return api.setting.timeoff.employees.getTimeoffApprovers(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_APPROVERS, data: data.sort((a, b) => a.order - b.order), apiUrl })
			return data
		})
	}
}

function refreshTimeoffApprovees(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffApprovees(employeeId)
		dispatch({ type: REFRESHING_TIMEOFF_APPROVEES, apiUrl })
		return api.setting.timeoff.employees.getTimeoffApprovees(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_APPROVEES, data, apiUrl })
			return data
		})
	}
}

export function refreshWorkingCycleGroupDetail(workingCycleGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroupDetail(workingCycleGroupId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_DETAIL, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.getDetail(workingCycleGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function refreshTimeoffGroupDetail(timeoffGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupDetail(timeoffGroupId)
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_DETAIL, apiUrl })
		return api.setting.timeoff.timeoff_groups.getDetail(timeoffGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function refreshHolidayGroupDetail(holidayGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupDetail(holidayGroupId)
		dispatch({ type: REFRESHING_HOLIDAY_GROUP_DETAIL, apiUrl })
		return api.setting.timeoff.holiday_groups.getDetail(holidayGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function changeWorkingCycleGroup(employeeId, workingCycleGroupId) {
	const timeoffSettingApiUrl = API_URLS.timeoffSetting(employeeId)
	return dispatch => {
		return api.working_cycle_setting.working_cycle_groups
			.moveEmployees({ employeeIds: [employeeId], workingCycleGroupId })
			.then(() => {
				dispatch({ type: CHANGED_WORKING_CYCLE_GROUP, timeoffSettingApiUrl, workingCycleGroupId })
			})
	}
}

export function changeHolidayGroup(employeeId, holidayGroupId) {
	const timeoffSettingApiUrl = API_URLS.timeoffSetting(employeeId)
	return dispatch => {
		return api.setting.timeoff.holiday_groups.moveEmployees({ employeeIds: [employeeId], holidayGroupId }).then(() => {
			dispatch({ type: CHANGED_HOLIDAY_GROUP, timeoffSettingApiUrl, holidayGroupId })
		})
	}
}

export function changeTimeoffGroup(employeeId, timeoffGroupId) {
	const timeoffSettingApiUrl = API_URLS.timeoffSetting(employeeId)
	return dispatch => {
		return api.setting.timeoff.timeoff_groups.moveEmployees({ employeeIds: [employeeId], timeoffGroupId }).then(() => {
			dispatch({ type: CHANGED_TIMEOFF_GROUP, timeoffSettingApiUrl, timeoffGroupId })
		})
	}
}

export function editApprovers(employeeId, timeoffApprovers) {
	return dispatch => {
		return api.setting.timeoff.timeoff_groups
			.editApprovers([
				{ employee_id: employeeId, timeoff_approvers: timeoffApprovers.map(item => new EditTimeoffApprover(item)) },
			])
			.then(() => {
				dispatch(refreshTimeoffApprovers(employeeId))
				dispatch({ type: EDITED_APPROVERS })
			})
	}
}

export function loadModule(employeeId) {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { permissions },
			},
		} = getState()
		let hasWorkingCycleSettingPermission = permissions.includes(PERMISSIONS.SETTING_WORKING_CYCLE)
		let hasPublicHolidaySettingPermission = permissions.includes(PERMISSIONS.SETTING_PUBLIC_HOLIDAY)
		let hasTimeoffSettingPermission = permissions.includes(PERMISSIONS.SETTING_TIMEOFF)
		if (hasWorkingCycleSettingPermission || hasPublicHolidaySettingPermission || hasTimeoffSettingPermission) {
			dispatch(refreshEmployees())
			dispatch(refreshTimeoffSetting(employeeId))
			dispatch(refreshGroupLeaderRoles())
			dispatch(refreshRanks())
		}
		if (hasWorkingCycleSettingPermission) {
			dispatch(refreshWorkingCycleTypes())
			dispatch(refreshWorkingCycleGroups())
		}
		if (hasPublicHolidaySettingPermission) {
			dispatch(refreshHolidayTypes())
			dispatch(refreshHolidayGroups())
		}
		if (hasTimeoffSettingPermission) {
			dispatch(refreshTimeoffGroups())
			dispatch(refreshTimeoffApprovers(employeeId))
			dispatch(refreshTimeoffApprovees(employeeId))
		}
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	let timeoffSetting
	if (action.timeoffSettingApiUrl) {
		timeoffSetting = state.api[action.timeoffSettingApiUrl]
	}
	switch (action.type) {
		case REFRESHING_EMPLOYEES:
		case REFRESHING_WORKING_CYCLE_TYPES:
		case REFRESHING_HOLIDAY_TYPES:
		case REFRESHING_HOLIDAY_GROUP_DETAIL:
		case REFRESHING_TIMEOFF_GROUP_DETAIL:
		case REFRESHING_WORKING_CYCLE_GROUP_DETAIL:
		case REFRESHING_HOLIDAY_GROUPS:
		case REFRESHING_WORKING_CYCLE_GROUPS:
		case REFRESHING_TIMEOFF_GROUPS:
		case REFRESHING_TIMEOFF_SETTING:
		case REFRESHING_TIMEOFF_APPROVERS:
		case REFRESHING_TIMEOFF_APPROVEES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_EMPLOYEES:
		case REFRESHED_WORKING_CYCLE_TYPES:
		case REFRESHED_HOLIDAY_TYPES:
		case REFRESHED_HOLIDAY_GROUP_DETAIL:
		case REFRESHED_TIMEOFF_GROUP_DETAIL:
		case REFRESHED_WORKING_CYCLE_GROUP_DETAIL:
		case REFRESHED_HOLIDAY_GROUPS:
		case REFRESHED_WORKING_CYCLE_GROUPS:
		case REFRESHED_TIMEOFF_GROUPS:
		case REFRESHED_TIMEOFF_SETTING:
		case REFRESHED_TIMEOFF_APPROVERS:
		case REFRESHED_TIMEOFF_APPROVEES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CHANGED_HOLIDAY_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[action.timeoffSettingApiUrl]: {
						...timeoffSetting,
						holidayGroupId: action.holidayGroupId,
					},
				},
			}
		case CHANGED_TIMEOFF_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[action.timeoffSettingApiUrl]: {
						...timeoffSetting,
						timeoffGroupId: action.timeoffGroupId,
					},
				},
			}
		case CHANGED_WORKING_CYCLE_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[action.timeoffSettingApiUrl]: {
						...timeoffSetting,
						workingCycleGroupId: action.workingCycleGroupId,
					},
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
