import { s, number, string } from 'modules/types'

export class Column {
	constructor({ id, name, order, question_id: questionId }) {
		this.id = id
		this.name = name
		this.order = order
		this.questionId = questionId
	}
}

export const ColumnType = s({
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
	questionId: number.isRequired,
})

export default Column
