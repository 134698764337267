import { s, number, string } from 'modules/types'
import { AWARD_PERMISSION, COMMENT_PERMISSION, POST_PERMISSION } from './enums'

export class IdeaFlow {
	constructor({
		id,
		name,
		level,
		comment_permission: commentPermission,
		post_permission: postPermission,
		like_permission: likePermission,
		flower_permission: flowerPermission,
		star_permission: starPermission,
	}) {
		this.id = id
		this.name = name
		this.level = level
		this.commentPermission = commentPermission
		this.postPermission = postPermission
		this.likePermission = likePermission
		this.flowerPermission = flowerPermission
		this.starPermission = starPermission
	}

	displayAwardPermission(permission) {
		switch (permission) {
			case AWARD_PERMISSION.NEVER:
				return 'Never'
			case AWARD_PERMISSION.ALLOW:
				return 'Allow'
			case AWARD_PERMISSION.ONLY_IF_LAST_REVIEWER:
				return 'Only if last reviewer'
			case AWARD_PERMISSION.AUTO_AFTER_COMMENT:
				return 'After post'
			case AWARD_PERMISSION.AUTO_AFTER_FORWARD:
				return 'After forward'
			case AWARD_PERMISSION.AUTO_AFTER_POST:
				return 'After post'
			default:
				return 'Other'
		}
	}

	displayCommentPermission() {
		switch (this.commentPermission) {
			case COMMENT_PERMISSION.NEVER:
				return 'Never'
			case COMMENT_PERMISSION.ONCE:
				return 'Once'
			case COMMENT_PERMISSION.MULTIPLE:
				return 'Multiple'
			default:
				return 'Other'
		}
	}

	displayPostPermission() {
		switch (this.postPermission) {
			case POST_PERMISSION.NEVER:
				return 'Never'
			case POST_PERMISSION.ALLOW:
				return 'Allow'
			case POST_PERMISSION.ONLY_IF_LAST_REVIEWER:
				return 'Only if last reviewer'
			default:
				return 'Other'
		}
	}
}

export const IdeaFlowType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
	commentPermission: number.isRequired,
	postPermission: number.isRequired,
	likePermission: number.isRequired,
	flowerPermission: number.isRequired,
	starPermission: number.isRequired,
})

export default IdeaFlow
