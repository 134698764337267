import { s, bool, string, number } from 'modules/types'

export class Title {
	constructor({
		id,
		name,
		active_employee_count: activeEmployeeCount,
		archived_employee_count: archivedEmployeeCount,
	}) {
		this.id = id
		this.name = name
		this.activeEmployeeCount = activeEmployeeCount
		this.archivedEmployeeCount = archivedEmployeeCount
		this.hasEmployee = Boolean(activeEmployeeCount) || Boolean(archivedEmployeeCount)
	}
}

export const TitleType = s({
	id: number.isRequired,
	name: string.isRequired,
	activeEmployeeCount: number.isRequired,
	archivedEmployeeCount: number.isRequired,
	hasEmployee: bool.isRequired,
})

export default Title
