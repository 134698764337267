import _ from 'lodash'
import api from 'modules/api'
import { PERMISSIONS } from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `employeePermissions`

export const PERMISSION_PARENT = {
	ROOT: 0,
	SURVEY_ADMIN: 1,
	HR: 2,
	SETTING: 3,
	MY_COMPANY: 4,
	PA_ADMIN: 5,
}

export function mapPermissionToParent(rootPermissions, permissionId) {
	const permission = rootPermissions.find(permission => permission.id === permissionId)
	if (permission === undefined) {
		throw new Error('Unknown root-level permission')
	}
	switch (permission.name) {
		case 'Survey Admin':
			return PERMISSION_PARENT.SURVEY_ADMIN
		case 'Human Resources':
			return PERMISSION_PARENT.HR
		case 'Setting':
			return PERMISSION_PARENT.SETTING
		case 'Manage My Company':
			return PERMISSION_PARENT.MY_COMPANY
		case 'Performance Appraisal':
			return PERMISSION_PARENT.PA_ADMIN
		default:
			throw new Error('Unknown root-level permission')
	}
}

export const API_URLS = {
	ranks: () => `/api/setting/permission/get_ranks/`,
	employeePermissions: employeeId => `/api/setting/permission/employees/${employeeId}/get_employee_with_permissions/`,
	availablePermissions: parent => `/api/setting/permission/employees/get_available_permissions/?parent=${parent}`,
}

const EDITED_PERMISSIONS = `${MODULE_NAME} | EDITED_PERMISSIONS`

const REFRESHING_RANKS = `${MODULE_NAME} | REFRESHING_RANKS`
const REFRESHING_EMPLOYEE_PERMISSIONS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_PERMISSIONS`
const REFRESHING_ROOT_PERMISSIONS = `${MODULE_NAME} | REFRESHING_ROOT_PERMISSIONS`
const REFRESHING_AVAILABLE_PERMISSIONS = `${MODULE_NAME} | REFRESHING_AVAILABLE_PERMISSIONS`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED_RANKS`
const REFRESHED_EMPLOYEE_PERMISSIONS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_PERMISSIONS`
const REFRESHED_ROOT_PERMISSIONS = `${MODULE_NAME} | REFRESHED_ROOT_PERMISSIONS`
const REFRESHED_AVAILABLE_PERMISSIONS = `${MODULE_NAME} | REFRESHED_AVAILABLE_PERMISSIONS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------
function refreshRanks() {
	return dispatch => {
		const apiUrl = API_URLS.ranks()
		dispatch({ type: REFRESHING_RANKS, apiUrl })
		return api.setting.permission.get_ranks().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, data: ranks, apiUrl })
			return ranks
		})
	}
}

function refreshEmployeePermissions(employeeId) {
	const apiUrl = API_URLS.employeePermissions(employeeId)
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEE_PERMISSIONS, apiUrl })
		return api.setting.permission.employees
			.getEmployeeWithPermissions(employeeId)
			.then(({ data: employeePermissions }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_PERMISSIONS, apiUrl, data: employeePermissions })
				return employeePermissions
			})
	}
}

export function refreshRootPermissions() {
	const apiUrl = API_URLS.availablePermissions(PERMISSION_PARENT.ROOT)
	return dispatch => {
		dispatch({ type: REFRESHING_ROOT_PERMISSIONS, apiUrl })
		return api.setting.permission.employees
			.get_available_permissions(PERMISSION_PARENT.ROOT)
			.then(({ permissions }) => {
				dispatch({ type: REFRESHED_ROOT_PERMISSIONS, apiUrl, data: permissions })
				return permissions
			})
	}
}

export function refreshAvailablePermissions(parent) {
	const apiUrl = API_URLS.availablePermissions(parent)
	return dispatch => {
		dispatch({ type: REFRESHING_AVAILABLE_PERMISSIONS, apiUrl })
		return api.setting.permission.employees.get_available_permissions(parent).then(({ permissions }) => {
			dispatch({ type: REFRESHED_AVAILABLE_PERMISSIONS, apiUrl, data: permissions })
			return permissions
		})
	}
}

export function editPermissions(employeeId, permissions) {
	return dispatch => {
		return api.setting.permission.employees.edit_permissions(employeeId, permissions).then(() => {
			dispatch({
				type: EDITED_PERMISSIONS,
				employeeId,
				permissions: permissions.map(permission => ({
					permissionId: permission.id,
					belowRankId: permission.belowRankId,
				})),
			})
		})
	}
}

export function loadModule(employeeId) {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { permissions },
			},
		} = getState()
		dispatch(refreshRanks())
		if (permissions.includes(PERMISSIONS.SETTING_PERMISSION_ACCESS)) {
			dispatch(refreshRootPermissions()).then(rootPermissions => {
				dispatch(refreshEmployeePermissions(employeeId))
				rootPermissions.forEach(rootPermission => {
					const permissionParent = mapPermissionToParent(rootPermissions, rootPermission.id)
					dispatch(refreshAvailablePermissions(permissionParent))
				})
			})
		}
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_RANKS:
		case REFRESHING_EMPLOYEE_PERMISSIONS:
		case REFRESHING_ROOT_PERMISSIONS:
		case REFRESHING_AVAILABLE_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_RANKS:
		case REFRESHED_EMPLOYEE_PERMISSIONS:
		case REFRESHED_ROOT_PERMISSIONS:
		case REFRESHED_AVAILABLE_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case EDITED_PERMISSIONS:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employeePermissions(action.employeeId)]: {
						...state.api[API_URLS.employeePermissions(action.employeeId)],
						permissions: action.permissions,
					},
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
