import { s, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class NoResponseReviewer {
	constructor({ reviewer, idea_creator: ideaCreator, submission_date: submissionDate }) {
		this.reviewer = new Employee(reviewer)
		this.ideaCreator = new Employee(ideaCreator)
		this.submissionDate = new Date(submissionDate * 1000)
	}
}

export const NoResponseReviewerType = s({
	reviewer: EmployeeType.isRequired,
	ideaCreator: EmployeeType.isRequired,
	submissionDate: object.isRequired,
})

export default NoResponseReviewer
