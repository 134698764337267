import { s, bool, number, object, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export const AUDIENCE_TYPE = {
	ALL: 0,
	GROUP: 1,
}

export const QUESTION_STATUS = {
	OPEN: 0,
	CLOSED: 1,
}

export class BrainstormQuestion {
	constructor({
		id,
		content,
		attachment_url: attachmentUrl,
		audience_type: audienceType,
		created_by: createdBy,
		notification,
		status,
		added,
		answer_count: answerCount,
	}) {
		this.id = id
		this.content = content
		this.attachmentUrl = attachmentUrl
		this.audienceType = audienceType
		this.createdBy = new Employee(createdBy)
		this.notification = notification
		this.status = status
		this.added = new Date(added * 1000)
		this.answerCount = answerCount
	}
}

export const BrainstormQuestionType = s({
	id: number.isRequired,
	content: string.isRequired,
	attachmentUrl: string,
	audienceType: number.isRequired,
	createdBy: EmployeeType.isRequired,
	notification: bool.isRequired,
	status: number.isRequired,
	added: object.isRequired,
	answerCount: number.isRequired,
})

export default BrainstormQuestion
