import { s, arrayOf } from 'modules/types'
import QuestionWithPreviousStageAnswer, { QuestionWithPreviousStageAnswerType } from './QuestionWithPreviousStageAnswer'
import TemplateWithQuestion, { TemplateWithQuestionType } from './TemplateWithQuestion'
import Appraisee, { AppraiseeType } from './Appraisee'
import PreviousStage, { PreviousStageType } from './PreviousStage'

export class AppraiseeScoring {
	constructor({ questions, template, appraisee, previous_stages: previousStages }) {
		this.questions = questions.map(item => new QuestionWithPreviousStageAnswer(item))
		this.template = new TemplateWithQuestion(template)
		this.appraisee = new Appraisee(appraisee)
		this.previousStages = previousStages.map(item => new PreviousStage(item))
	}
}

export const AppraiseeScoringType = s({
	questions: arrayOf(QuestionWithPreviousStageAnswerType),
	template: TemplateWithQuestionType,
	appraisee: AppraiseeType,
	previousStages: arrayOf(PreviousStageType),
})

export default AppraiseeScoring
