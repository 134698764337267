import { s, arrayOf, number } from 'modules/types'
import AppraisalGroup, { AppraisalGroupType } from './AppraisalGroup'

class AllowedGroupsToMoveResponse {
	constructor({ groups, sample_template_id: sampleTemplateId }) {
		this.groups = groups.map(item => new AppraisalGroup(item))
		this.sampleTemplateId = sampleTemplateId
	}
}

export const AllowedGroupsToMoveResponseType = s({
	groups: arrayOf(AppraisalGroupType),
	sampleTemplateId: number,
})

export default AllowedGroupsToMoveResponse
