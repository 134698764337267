import { s, arrayOf, number, string } from 'modules/types'
import TimeoffRule, { TimeoffRuleType } from './TimeoffRule'

export class TimeoffGroupWithRules {
	constructor({ id, name, timeoff_rules: timeoffRules }) {
		this.id = id
		this.name = name
		this.timeoffRules = timeoffRules.map(item => new TimeoffRule(item))
	}
}

export const TimeoffGroupWithRulesType = s({
	id: number.isRequired,
	name: string.isRequired,
	timeoffRules: arrayOf(TimeoffRuleType),
})

export default TimeoffGroupWithRules
