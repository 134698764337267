import { s, number } from 'modules/types'
import { APPRAISER_TYPE } from './index'

export class AppraiserType {
	constructor({ id, type, stage_id: stageId, rank_id: rankId }) {
		this.id = id
		this.type = type
		this.stageId = stageId
		this.rankId = rankId
	}

	displayType() {
		switch (this.type) {
			case APPRAISER_TYPE.SELF:
				return 'SELF'
			case APPRAISER_TYPE.PEER:
				return 'PEER'
			case APPRAISER_TYPE.SUPERIOR:
				return 'SUPERIOR'
			case APPRAISER_TYPE.SECOND_DEGREE_SUPERIOR:
				return 'SECOND_DEGREE_SUPERIOR'
			case APPRAISER_TYPE.SUBORDINATE:
				return 'SUBORDINATE'
			case APPRAISER_TYPE.OTHERS:
				return 'OTHERS'
			case APPRAISER_TYPE.EXTERNAL:
				return 'EXTERNAL'
			case APPRAISER_TYPE.GROUP_LEADER:
				return 'GROUP_LEADER'
			case APPRAISER_TYPE.DEPARTMENT_HEAD:
				return 'DEPARTMENT_HEAD'
			case APPRAISER_TYPE.RANK_SUPERVISOR:
				return 'RANK_SUPERVISOR'
			default:
				throw new Error('Unknown appraiser type')
		}
	}
}

export const AppraiserTypeType = s({
	id: number.isRequired,
	type: number.isRequired, // @APPRAISER_TYPE
	stageId: number.isRequired,
})

export default AppraiserType
