import { s, number, string } from 'modules/types'

export class TemplateUsed {
	constructor({ id, session_name: sessionName, group_name: groupName }) {
		this.id = id
		this.sessionName = sessionName
		this.groupName = groupName
	}
}

export const TemplateUsedType = s({
	id: number.isRequired,
	sessionName: string.isRequired,
	groupName: string.isRequired,
})

export default TemplateUsed
