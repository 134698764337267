import { s, arrayOf } from 'modules/types'
import { SubSession, SubSessionType } from './SubSession'
import { QuestionWithAnswer, QuestionWithAnswerType } from './QuestionWithAnswer'

export class SubSessionDetail {
	constructor({ questions_with_answer: questionsWithAnswer, sub_session: subSession }) {
		this.questionsWithAnswer = questionsWithAnswer.map(item => new QuestionWithAnswer(item))
		this.subSession = new SubSession(subSession)
	}
}

export const SubSessionDetailType = s({
	questionsWithAnswer: arrayOf(QuestionWithAnswerType),
	subSession: SubSessionType,
})

export default SubSessionDetail
