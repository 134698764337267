import { s, number, string } from 'modules/types'

export class TimeoffApprovee {
	constructor({
		id,
		approvee_id: approveeId,
		order,
		NIK,
		name,
		approvee_name: approveeName,
		position_name: positionName,
	}) {
		this.id = id
		this.approveeId = approveeId
		this.order = order
		this.NIK = NIK
		this.name = name || approveeName
		this.positionName = positionName
	}
}

export const TimeoffApproveeType = s({
	id: number.isRequired,
	approveeId: number.isRequired,
	order: number.isRequired,
	NIK: string.isRequired,
	name: string.isRequired,
	positionName: string.isRequired,
})

export default TimeoffApprovee
