import { s, arrayOf, object } from 'modules/types'
import CalendarWorkingCycle, { CalendarWorkingCycleType } from './CalendarWorkingCycle'
import CalendarEventDate, { CalendarEventDateType } from './CalendarEventDate'

export class Calendar {
	constructor({ date, working_cycle: workingCycle, events }) {
		this.date = new Date(date)
		if (workingCycle) {
			// workingCycle can be null if there's no rule in the working cycle group
			this.workingCycle = new CalendarWorkingCycle(workingCycle)
		}
		this.events = events.map(item => new CalendarEventDate(item))
	}
}

export const CalendarType = s({
	date: object.isRequired,
	workingCycle: CalendarWorkingCycleType,
	events: arrayOf(CalendarEventDateType),
})

export default Calendar
