import { s, arrayOf, number } from 'modules/types'
import IdeaFlowReport, { IdeaFlowReportType } from './IdeaFlowReport'

export class IdeaReportSummary {
	constructor({
		total_idea_created: totalIdeaCreated,
		total_commented: totalCommented,
		total_flower: totalFlower,
		total_like: totalLike,
		total_no_response: totalNoResponse,
		total_posted: totalPosted,
		total_star: totalStar,
		idea_flows: ideaFlows,
	}) {
		this.totalIdeaCreated = totalIdeaCreated
		this.totalCommented = totalCommented
		this.totalFlower = totalFlower
		this.totalLike = totalLike
		this.totalNoResponse = totalNoResponse
		this.totalPosted = totalPosted
		this.totalStar = totalStar
		this.ideaFlows = ideaFlows.map(item => new IdeaFlowReport(item))
	}
}

export const IdeaReportSummaryType = s({
	totalIdeaCreated: number.isRequired,
	totalCommented: number.isRequired,
	totalFlower: number.isRequired,
	totalLike: number.isRequired,
	totalNoResponse: number.isRequired,
	totalPosted: number.isRequired,
	totalStar: number.isRequired,
	ideaFlows: arrayOf(IdeaFlowReportType),
})

export default IdeaReportSummary
