import _ from 'lodash'
import api from 'modules/api'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisal_completed`

const SELECT_SUB_SESSION = `${MODULE_NAME} | SELECT SUB SESSION`
const CLEAR_SELECTION_SUB_SESSION = `${MODULE_NAME} | CLEAR SELECTION SUB SESSION`

const REFRESHED_COMPLETED_SUB_SESSIONS = `${MODULE_NAME} | REFRESHED COMPLETED SUB SESSIONS`
const REFRESHED_COMPLETED_SUB_SESSION_DETAIL = `${MODULE_NAME} | REFRESHED COMPLETED SUB SESSION`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------
function refreshCompletedAppraisal() {
	return dispatch => {
		return api.appraisal.subSessions.completed.getSubsessions().then(({ data: subSessions }) => {
			dispatch({ type: REFRESHED_COMPLETED_SUB_SESSIONS, data: subSessions })
			if (subSessions && subSessions.length > 0) {
				dispatch(selectSubSession(subSessions[0].id))
			}
			return subSessions
		})
	}
}

function refreshSubSessionDetail(subSessionId) {
	return dispatch => {
		return api.appraisal.subSessions.completed.getSubsessionDetail(subSessionId).then(({ data: subSessionDetail }) => {
			dispatch({ type: REFRESHED_COMPLETED_SUB_SESSION_DETAIL, data: subSessionDetail })
			return subSessionDetail
		})
	}
}

export function selectSubSession(subSessionId) {
	return dispatch => {
		dispatch({ type: SELECT_SUB_SESSION, subSessionId })
		dispatch(refreshSubSessionDetail(subSessionId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshCompletedAppraisal())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {
		selectedSubSession: undefined,
		completedSubSessions: undefined,
	},
	data: {
		selectedSubSessionId: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_COMPLETED_SUB_SESSIONS:
			return {
				...state,
				api: {
					...state.api,
					completedSubSessions: action.data,
				},
			}
		case REFRESHED_COMPLETED_SUB_SESSION_DETAIL:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: action.data,
				},
			}
		case SELECT_SUB_SESSION:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: undefined,
				},
				data: {
					...state.data,
					selectedSubSessionId: action.subSessionId,
				},
			}
		case CLEAR_SELECTION_SUB_SESSION:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: undefined,
				},
				data: {
					...state.data,
					selectedSubSessionId: undefined,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
