import { s, number, string } from 'modules/types'

export class AllRequestFilter {
	constructor({ label, value }) {
		this.label = label
		this.value = value
	}
}

export const AllRequestFilterType = s({
	label: string.isRequired,
	value: number.isRequired,
})

export default AllRequestFilter
