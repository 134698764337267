import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AppraisalAdminScoringComponent

const AppraisalAdminScoring = store => {
	if (AppraisalAdminScoringComponent === undefined) {
		AppraisalAdminScoringComponent = Loadable({
			loader: () => {
				return import('views/AppraisalAdminScoring/containers/AppraisalAdminScoringContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalAdminScoringComponent
}

export default AppraisalAdminScoring
