import { s, arrayOf } from 'modules/types'
import { Question, QuestionType } from './Question'
import { AnswerStatistic, AnswerStatisticType } from './AnswerStatistic'

export class Summary {
	constructor({ question, answer_statistics: answerStatistics }) {
		this.question = new Question(question)
		this.answerStatistics = answerStatistics.map(item => new AnswerStatistic(item))
	}
}

export const SummaryType = s({
	question: QuestionType,
	answerStatistics: arrayOf(AnswerStatisticType),
})

export default Summary
