import { s, arrayOf } from 'modules/types'
import EmployeeWithGroupPosition, { EmployeeWithGroupPositionType } from './EmployeeWithGroupPosition'
import ExperienceWalletSummary, { ExperienceWalletSummaryType } from './ExperienceWalletSummary'

export class EmployeeWithWallets {
	constructor({ employee, experience_wallets: experienceWallets }) {
		this.employee = new EmployeeWithGroupPosition(employee)
		this.experienceWallets = experienceWallets.map(item => new ExperienceWalletSummary(item))
	}
}

export const EmployeeWithWalletsType = s({
	employee: EmployeeWithGroupPositionType,
	experienceWallets: arrayOf(ExperienceWalletSummaryType),
})

export default EmployeeWithWallets
