import { s, arrayOf, bool, number, string } from 'modules/types'
import QuestionLinkWithAnswer, { QuestionLinkWithAnswerType } from './QuestionLinkWithAnswer'

export class CategoryWithQuestionLink {
	constructor({
		name,
		order,
		question_links_with_answer: questionLinksWithAnswer,
		has_answer_permission: hasAnswerPermission,
		has_view_permission: hasViewPermission,
	}) {
		this.name = name
		this.order = order
		this.questionLinksWithAnswer = questionLinksWithAnswer.map(item => new QuestionLinkWithAnswer(item))
		this.hasAnswerPermission = hasAnswerPermission
		this.hasViewPermission = hasViewPermission
	}
}

export const CategoryWithQuestionLinkType = s({
	name: string.isRequired,
	order: number.isRequired,
	questionLinksWithAnswer: arrayOf(QuestionLinkWithAnswerType),
	hasAnswerPermission: bool.isRequired,
	hasViewPermission: bool.isRequired,
})

export default CategoryWithQuestionLink
