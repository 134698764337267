import { s, number } from 'modules/types'

export class GroupStatistics {
	constructor({
		default_category_id: defaultCategoryId,
		question_count: questionCount,
		average_score: averageScore,
		max_score: maxScore,
		min_score: minScore,
	}) {
		this.defaultCategoryId = defaultCategoryId
		this.questionCount = questionCount
		this.averageScore = averageScore
		this.maxScore = maxScore
		this.minScore = minScore
	}
}

export const GroupStatisticsType = s({
	defaultCategoryId: number.isRequired,
	fullName: number.isRequired,
	averageScore: number.isRequired,
	maxScore: number.isRequired,
	minScore: number.isRequired,
})

export default GroupStatistics
