import { s, arrayOf } from 'modules/types'
import ExperienceWallet, { ExperienceWalletType } from './ExperienceWallet'
import CalendarData, { CalendarDataType } from './CalendarData'

export class WalletCalendar {
	constructor({ wallet, calendar }) {
		this.wallet = new ExperienceWallet(wallet)
		this.calendar = calendar.map(item => new CalendarData(item))
	}
}

export const WalletCalendarType = s({
	wallet: ExperienceWalletType,
	calendar: arrayOf(CalendarDataType),
})

export default WalletCalendar
