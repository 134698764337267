import React from 'react'
import PropTypes from 'prop-types'

// lib
import cx from 'classnames'
import { Switch, Route, Redirect } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// material ui
import withStyles from '@material-ui/core/styles/withStyles'

// template components
import Snackbar from 'newComponents/Snackbar/Snackbar'
import Sidebar from 'components/Sidebar'

// custom components
import Header from 'components/Header/Header'

// redux
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { logout, setWebSetting, fetchMoreNotifications, switchUser, changePassword } from 'store/session'

import { getObjectOrUndefined } from 'utils'
import { MainPanelContext } from 'modules/mainPanelContext'

import dashboardRoutes from 'routes/Home'

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'

import imageSidebar1 from 'assets/img/sidebar-1.jpg'
import imageSidebar2 from 'assets/img/sidebar-2.jpg'
import imageSidebar3 from 'assets/img/sidebar-3.jpg'
import imageSidebar4 from 'assets/img/sidebar-4.jpg'

const NoPermissionView = () => <div>Sorry, You have no permission to access this page</div>

class SwitchRoutes extends React.Component {
	render() {
		const { store, currentUserPermissions } = this.props
		return (
			<Switch>
				{dashboardRoutes.map((prop, key) => {
					let hasPermission = true
					if (prop.permissionsRequired) {
						hasPermission = prop.permissionsRequired.reduce((prev, cur) => {
							return prev && currentUserPermissions.indexOf(cur) > -1
						}, true)
					}

					if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />
					if (prop.collapse) {
						let firstTabWithPermission = null
						const result = prop.views.map((view, key) => {
							let hasPermissionView = true
							if (view.permissionsRequired) {
								hasPermissionView = view.permissionsRequired.reduce((prev, cur) => {
									return prev && currentUserPermissions.indexOf(cur) > -1
								}, true)
							}
							if (firstTabWithPermission === null && hasPermissionView) {
								firstTabWithPermission = <Redirect from={prop.path} to={view.path} key={key} />
							}
							return (
								<Route
									path={view.path}
									component={hasPermissionView ? view.component(store) : NoPermissionView}
									key={key}
								/>
							)
						})
						if (firstTabWithPermission !== null) {
							return [...result, firstTabWithPermission]
						} else {
							return result
						}
					}
					if (!hasPermission) {
						return <Redirect from={prop.path} to={'/dashboard/home/'} key={key} />
					}
					return (
						<Route path={prop.path} component={hasPermission ? prop.component(store) : NoPermissionView} key={key} />
					)
				})}
			</Switch>
		)
	}
}

var ps

class Dashboard extends React.Component {
	state = {
		mobileOpen: false,
		miniActive: false,
		mainPanelHandlers: {},
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen })
	}
	getSidebarImage = () => {
		const { session } = this.props
		switch (getObjectOrUndefined(session.frontendSetting, 'sidebar_image')) {
			case 'sidebar1':
				return imageSidebar1
			case 'sidebar2':
				return imageSidebar2
			case 'sidebar3':
				return imageSidebar3
			case 'sidebar4':
				return imageSidebar4
			default:
				return imageSidebar1
		}
	}
	getSidebarColor = () => {
		const { session } = this.props
		return getObjectOrUndefined(session.frontendSetting, 'sidebar_select_color') || 'white'
	}
	getSidebarBackgroundColor = () => {
		const { session } = this.props
		return getObjectOrUndefined(session.frontendSetting, 'sidebar_bg_color') || 'black'
	}
	handleChangeSetting = (key, value) => this.props.setWebSetting(key, value)

	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			// eslint-disable-next-line
			ps = new PerfectScrollbar(document.querySelector('html'), {
				suppressScrollX: true,
				suppressScrollY: false,
			})
		}

		document.addEventListener('scroll', this.handleOnScroll, true)
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1 && Boolean(ps)) {
			ps.destroy()
		}
		document.removeEventListener('scroll', this.handleOnScroll, true)
	}

	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			document.querySelector('html').scrollTop = 0
		}
	}

	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive })
	}

	handleOnScroll = e => {
		const event = 'scroll'
		const subscribers = this.state.mainPanelHandlers[event] || []
		subscribers.forEach(subscriber => subscriber(e, document.querySelector('html')))
	}

	handleSubscribeMainPanel = (event, handler) => {
		// currently only allow event == 'scroll'
		if (event !== 'scroll') {
			throw Error('only allow scroll event at the moment')
		}

		this.setState(prevState => {
			let subscribers = prevState.mainPanelHandlers[event] || []
			return {
				mainPanelHandlers: {
					...prevState.mainPanelHandlers,
					[event]: [...subscribers.filter(subscriber => subscriber !== handler), handler],
				},
			}
		})

		return () =>
			this.setState(prevState => ({
				mainPanelHandlers: {
					...prevState.mainPanelHandlers,
					[event]: prevState.mainPanelHandlers[event].filter(subscriber => subscriber !== handler),
				},
			}))
	}

	renderToastMessage = message => {
		if (message instanceof String) {
			return <div>message</div>
		} else if (message instanceof Array) {
			return message.map(msg => <div key={msg}>{msg}</div>)
		} else {
			return <div>{message}</div>
		}
	}

	render() {
		const {
			classes,
			store,
			session,
			toastr,
			redirect,
			hideToast,
			logout,
			setWebSetting,
			fetchMoreNotifications,
			switchUser,
			...rest
		} = this.props
		const mainPanel =
			classes.mainPanel +
			' ' +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
			})
		if (!session || !session.frontendSetting || !session.currentUser) {
			return 'Loading..'
		} // TODO: add proper loading page
		return (
			<div className={classes.wrapper}>
				<Snackbar
					place={toastr.place}
					color={toastr.color}
					message={this.renderToastMessage(toastr.message)}
					open={Boolean(toastr.message)}
					close={toastr.withClose}
					closeNotification={() => hideToast()}
				/>
				<Sidebar
					routes={dashboardRoutes}
					logoText={'Skleem'}
					logo={session.currentUser.company_logo}
					image={this.getSidebarImage()}
					currentUser={session.currentUser}
					currentUserPermissions={session.currentUser.permissions}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color={this.getSidebarColor()}
					bgColor={this.getSidebarBackgroundColor()} // black, white, blue
					miniActive={this.state.miniActive}
					handleLogout={() => logout()}
					frontendSetting={session.frontendSetting}
					notifications={session.notifications}
					handleChangeSetting={setWebSetting}
					switchUser={switchUser}
					{...rest}
				/>
				<MainPanelContext.Provider value={this.handleSubscribeMainPanel}>
					<div className={mainPanel} ref="mainPanel" id={'mainPanel'}>
						<Header
							sidebarMinimize={this.sidebarMinimize.bind(this)}
							currentUserPermissions={session.currentUser.permissions}
							miniActive={this.state.miniActive}
							routes={dashboardRoutes}
							handleDrawerToggle={this.handleDrawerToggle}
							currentUser={session.currentUser}
							redirect={redirect}
							handleLogout={() => logout()}
							fetchMoreNotifications={fetchMoreNotifications}
							frontendSetting={session.frontendSetting}
							notifications={session.notifications}
							notificationPagination={session.pagination.notifications}
							handleChangeSetting={setWebSetting}
							changePassword={changePassword}
							{...rest}
						/>
						<div className={classes.content}>
							<div className={classes.container}>
								<SwitchRoutes currentUserPermissions={session.currentUser.permissions} store={store} />
							</div>
						</div>
					</div>
				</MainPanelContext.Provider>
			</div>
		)
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
	redirect: url => dispatch(push(url)),
	hideToast: () => dispatch({ type: 'HIDE_TOAST' }),
	logout: () => dispatch(logout()),
	setWebSetting: (key, value) => dispatch(setWebSetting(key, value)),
	fetchMoreNotifications: () => dispatch(fetchMoreNotifications()),
	switchUser: data => dispatch(switchUser(data)),
	changePassword: data => dispatch(changePassword(data)),
})

const mapStateWithProps = state => ({
	session: state.session,
	toastr: state.toastr,
})

export default withStyles(appStyle)(
	connect(
		mapStateWithProps,
		mapDispatchToProps
	)(Dashboard)
)
