import { s, number } from 'modules/types'
import AppraisalQuestion, { AppraisalQuestionType } from './AppraisalQuestion'

export class DefaultQuestionLink {
	constructor({ id, default_question_link_id: defaultQuestionLinkId = null, question, weight, order }) {
		this.id = id
		if (defaultQuestionLinkId !== null) {
			this.defaultQuestionLinkId = defaultQuestionLinkId
		}
		this.question = new AppraisalQuestion(question)
		this.weight = weight
		this.order = order
	}
}

export const DefaultQuestionLinkType = s({
	id: number.isRequired,
	defaultQuestionLinkId: number,
	question: AppraisalQuestionType,
	weight: number,
	order: number.isRequired,
})

export default DefaultQuestionLink
