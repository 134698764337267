import { s, oneOfType, number, string } from 'modules/types'
import { AppraiserType, AppraiserTypeType } from './AppraiserType'

export class PreviousStageAnswer {
	constructor({ reviewer_name: reviewerName, appraiser_type, answer }) {
		this.reviewerName = reviewerName
		this.appraiserType = new AppraiserType(appraiser_type)
		this.answer = answer
	}
}

export const PreviousStageAnswerType = s({
	reviewerName: string,
	appraiserType: AppraiserTypeType,
	answer: oneOfType([number, string]),
})

export default PreviousStageAnswer
