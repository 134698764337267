import { s, number, string } from 'modules/types'

export class IdeaReportDate {
	constructor({
		date,
		idea_count: ideaCount,
		like_count: likeCount,
		flower_count: flowerCount,
		star_count: starCount,
	}) {
		this.date = date
		this.ideaCount = ideaCount
		this.likeCount = likeCount
		this.flowerCount = flowerCount
		this.starCount = starCount
	}
}

export const IdeaReportDateType = s({
	date: string.isRequired,
	ideaCount: number.isRequired,
	likeCount: number.isRequired,
	flowerCount: number.isRequired,
	starCount: number.isRequired,
})

export default IdeaReportDate
