import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

export function checkNumber(number) {
	return number > -1 ? number : '-'
}

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `engagement`

const APPROVED_REQUEST = `${MODULE_NAME} | APPROVED REQUEST`
const REJECTED_REQUEST = `${MODULE_NAME} | REJECTED REQUEST`

const REFRESHING_TELEGRAM_REQUESTS = `${MODULE_NAME} | REFRESHING TELEGRAM REQUESTS`
const REFRESHING_BRAINSTORMING_REPORT = `${MODULE_NAME} | REFRESHING BRAINSTORMING REPORT`
const REFRESHING_IDEA_REPORT = `${MODULE_NAME} | REFRESHING IDEA REPORT`

const REFRESHED_TELEGRAM_REQUESTS = `${MODULE_NAME} | REFRESHED TELEGRAM REQUESTS`
const REFRESHED_BRAINSTORMING_REPORT = `${MODULE_NAME} | REFRESHED BRAINSTORMING REPORT`
const REFRESHED_IDEA_REPORT = `${MODULE_NAME} | REFRESHED IDEA REPORT`

const CHANGE_BRAINSTORMING_DATE_RANGE = `${MODULE_NAME} | CHANGE BRAINSTORMING DATE RANGE`
const CHANGE_IDEA_DATE_RANGE = `${MODULE_NAME} | CHANGE IDEA DATE RANGE`
const TOGGLE_BRAINSTORMING_DETAIL = `${MODULE_NAME} | TOGGLE BRAINSTORMING DETAIL`
const TOGGLE_IDEA_DETAIL = `${MODULE_NAME} | TOGGLE IDEA DETAIL`

const ERROR_APPROVING_REQUEST = `${MODULE_NAME} | APPROVING REQUEST`
const ERROR_REJECTING_REQUEST = `${MODULE_NAME} | REJECTING REQUEST`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------

function refreshTelegramRequests() {
	return dispatch => {
		const apiUrl = `/api/engagement/get_telegram_requests/`
		dispatch({ type: REFRESHING_TELEGRAM_REQUESTS, apiUrl })
		return api.engagement.getTelegramRequests().then(({ data }) => {
			dispatch({ type: REFRESHED_TELEGRAM_REQUESTS, data, apiUrl })
		})
	}
}

function refreshBrainstormingReport(
	startDate = `1-${new Date().getFullYear()}`,
	endDate = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
) {
	return dispatch => {
		const apiUrl = `/api/engagement/get_brainstorm_report/`
		dispatch({ type: REFRESHING_BRAINSTORMING_REPORT, apiUrl })
		return api.engagement.getBrainstormReport(startDate, endDate).then(({ data }) => {
			dispatch({ type: REFRESHED_BRAINSTORMING_REPORT, data, apiUrl })
		})
	}
}

function refreshIdeaReport(
	startDate = `1-${new Date().getFullYear()}`,
	endDate = `${new Date().getMonth() + 1}-${new Date().getFullYear()}`
) {
	return dispatch => {
		const apiUrl = `/api/engagement/get_idea_report/`
		dispatch({ type: REFRESHING_IDEA_REPORT, apiUrl })
		return api.engagement.getIdeaReport(startDate, endDate).then(({ data }) => {
			dispatch({ type: REFRESHED_IDEA_REPORT, data, apiUrl })
		})
	}
}

export function approveRequest(telegramRequestId) {
	return dispatch => {
		return api.engagement.telegramRequests
			.approve(telegramRequestId)
			.then(() => {
				dispatch(refreshTelegramRequests())
				toast('Request approved')
				dispatch({ type: APPROVED_REQUEST })
			})
			.catch(err => {
				dispatch({ type: ERROR_APPROVING_REQUEST })
				throw err
			})
	}
}

export function rejectRequest(telegramRequestId) {
	return dispatch => {
		return api.engagement.telegramRequests
			.reject(telegramRequestId)
			.then(() => {
				dispatch(refreshTelegramRequests())
				toast('Request deleted')
				dispatch({ type: REJECTED_REQUEST })
			})
			.catch(err => {
				dispatch({ type: ERROR_REJECTING_REQUEST })
				throw err
			})
	}
}

export function changeBrainstormingDateRange(start, end) {
	return dispatch => {
		dispatch({ type: CHANGE_BRAINSTORMING_DATE_RANGE, start, end })
		dispatch(refreshBrainstormingReport(start.month + '-' + start.year, end.month + '-' + end.year))
	}
}

export function changeIdeaDateRange(start, end) {
	return dispatch => {
		dispatch({ type: CHANGE_IDEA_DATE_RANGE, start, end })
		dispatch(refreshIdeaReport(start.month + '-' + start.year, end.month + '-' + end.year))
	}
}

export function toggleBrainstormingDetail() {
	return dispatch => {
		dispatch({ type: TOGGLE_BRAINSTORMING_DETAIL })
	}
}

export function toggleIdeaDetail() {
	return dispatch => {
		dispatch({ type: TOGGLE_IDEA_DETAIL })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshTelegramRequests())
		dispatch(refreshIdeaReport())
		dispatch(refreshBrainstormingReport())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		isBrainstormingDetailOpen: false,
		isIdeaDetailOpen: false,
		ideaRangeStart: { year: new Date().getFullYear(), month: 1 },
		ideaRangeEnd: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
		brainstormingRangeStart: { year: new Date().getFullYear(), month: 1 },
		brainstormingRangeEnd: { year: new Date().getFullYear(), month: new Date().getMonth() + 1 },
	},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_TELEGRAM_REQUESTS:
		case REFRESHING_BRAINSTORMING_REPORT:
		case REFRESHING_IDEA_REPORT:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_TELEGRAM_REQUESTS:
		case REFRESHED_BRAINSTORMING_REPORT:
		case REFRESHED_IDEA_REPORT:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CHANGE_BRAINSTORMING_DATE_RANGE:
			return {
				...state,
				data: {
					...state.data,
					brainstormingRangeStart: action.start,
					brainstormingRangeEnd: action.end,
				},
			}
		case CHANGE_IDEA_DATE_RANGE:
			return {
				...state,
				data: {
					...state.data,
					ideaRangeStart: action.start,
					ideaRangeEnd: action.end,
				},
			}
		case TOGGLE_BRAINSTORMING_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					isBrainstormingDetailOpen: !state.data.isBrainstormingDetailOpen,
				},
			}
		case TOGGLE_IDEA_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					isIdeaDetailOpen: !state.data.isIdeaDetailOpen,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
