import { s, arrayOf, string, number } from 'modules/types'
import { Employee, EmployeeType } from './Employee'

export class SuperiorWithEmployees {
	constructor({ id, name, employees }) {
		this.id = id
		this.name = name
		this.employees = employees.map(item => new Employee(item))
	}
}

export const SuperiorWithEmployeesType = s({
	id: number.isRequired,
	name: string.isRequired,
	employees: arrayOf(EmployeeType),
})

export default SuperiorWithEmployees
