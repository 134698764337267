import { s, number, string } from 'modules/types'

export class Audience {
	constructor({
		id,
		NIK,
		full_name: fullName,
		title_name: titleName,
		group_name: groupName,
		department_name: departmentName,
	}) {
		this.id = id
		this.NIK = NIK
		this.fullName = fullName
		this.titleName = titleName
		this.groupName = groupName
		this.departmentName = departmentName
	}
}

export const AudienceType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	fullName: string.isRequired,
	titleName: string,
	groupName: string,
	departmentName: string,
})

export default Audience
