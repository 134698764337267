import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AnnouncementComponent

const Announcement = store => {
	if (AnnouncementComponent === undefined) {
		AnnouncementComponent = Loadable({
			loader: () => {
				return import('views/Announcement/containers/AnnouncementContainer')
			},
			loading: RouteLoader,
		})
	}
	return AnnouncementComponent
}

export default Announcement
