import { s, number, string } from 'modules/types'

export class WorkingCycleType {
	constructor({ id, name, start_time: startTime, end_time: endTime }) {
		this.id = id
		this.name = name
		this.startTime = startTime
		this.endTime = endTime
	}
}

export const WorkingCycleTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
	startTime: string.isRequired,
	endTime: string.isRequired,
})

export default WorkingCycleType
