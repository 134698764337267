/* eslint-disable no-sequences */

var _createClass = (function() {
	function defineProperties(target, props) {
		for (var i = 0; i < props.length; i++) {
			var descriptor = props[i]
			descriptor.enumerable = descriptor.enumerable || false
			descriptor.configurable = true
			if ('value' in descriptor) descriptor.writable = true
			Object.defineProperty(target, descriptor.key, descriptor)
		}
	}
	return function(Constructor, protoProps, staticProps) {
		if (protoProps) defineProperties(Constructor.prototype, protoProps)
		if (staticProps) defineProperties(Constructor, staticProps)
		return Constructor
	}
})()

var _react = require('react')

var _react2 = _interopRequireDefault(_react)

var _propTypes = require('prop-types')

var _propTypes2 = _interopRequireDefault(_propTypes)

var _objectAssign = require('object-assign')

var _objectAssign2 = _interopRequireDefault(_objectAssign)

var _SweetAlertStyles = require('./styles/SweetAlertStyles')

var _SweetAlertStyles2 = _interopRequireDefault(_SweetAlertStyles)

function _interopRequireDefault(obj) {
	return obj && obj.__esModule ? obj : { default: obj }
}

function _classCallCheck(instance, Constructor) {
	if (!(instance instanceof Constructor)) {
		throw new TypeError('Cannot call a class as a function')
	}
}

function _possibleConstructorReturn(self, call) {
	if (!self) {
		throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
	}
	return call && (typeof call === 'object' || typeof call === 'function') ? call : self
}

function _inherits(subClass, superClass) {
	if (typeof superClass !== 'function' && superClass !== null) {
		throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass)
	}
	subClass.prototype = Object.create(superClass && superClass.prototype, {
		constructor: { value: subClass, enumerable: false, writable: true, configurable: true },
	})
	if (superClass)
		Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : (subClass.__proto__ = superClass)
}

var Buttons = (function(_React$Component) {
	_inherits(Buttons, _React$Component)

	function Buttons() {
		var _ref

		var _temp, _this, _ret

		_classCallCheck(this, Buttons)

		for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
			args[_key] = arguments[_key]
		}

		return (
			(_ret = ((_temp = ((_this = _possibleConstructorReturn(
				this,
				(_ref = Buttons.__proto__ || Object.getPrototypeOf(Buttons)).call.apply(_ref, [this].concat(args))
			)),
			_this)),
			(_this.buttonStyles = {}),
			(_this.getButtonStyle = function(bsStyle) {
				if (bsStyle === 'error') bsStyle = 'danger'
				if (_this.buttonStyles[bsStyle] == null) {
					var style = Buttons.styles[bsStyle] || Buttons.styles.default
					var borderColor = 'borderColor: ' + style.borderColor + ' !important'
					var boxShadow = 'boxShadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px ' + style.shadowColor + ' !important;'
					_this.buttonStyles[bsStyle] = ' ' + borderColor + ' ' + boxShadow
				}
				return _this.buttonStyles[bsStyle]
			}),
			_temp)),
			_possibleConstructorReturn(_this, _ret)
		)
	}

	_createClass(Buttons, [
		{
			key: 'componentDidMount',
			value: function componentDidMount() {
				this.focusButton()
			},
		},
		{
			key: 'componentDidUpdate',
			value: function componentDidUpdate(prevProps, prevState) {
				var _this2 = this

				// when displaying back to back alerts React will treat this
				// as an update to the same alert. this causes componentDidMount
				// to not be called for the subsequent alerts. i hope to find a better
				// way to handle this in the future, but for now i'm checking if the
				// title, type, or button text has changed
				if (
					prevProps.type !== this.props.type ||
					prevProps.confirmBtnText !== this.props.confirmBtnText ||
					prevProps.title !== this.props.title
				) {
					setTimeout(function() {
						return _this2.focusButton()
					}, 0)
				}
			},
		},
		{
			key: 'focusButton',
			value: function focusButton() {
				if (this.props.focusConfirmBtn && this.refs.confirmBtn) {
					try {
						this.refs.confirmBtn.focus()
					} catch (e) {
						// whoops
					}
				}
			},
		},
		{
			key: 'render',
			value: function render() {
				if (!this.props.showConfirm && !this.props.showCancel) {
					return false
				}
				var cancelBtnBsStyle = this.props.cancelBtnBsStyle === 'error' ? 'danger' : this.props.cancelBtnBsStyle
				var confirmBtnBsStyle = this.props.confirmBtnBsStyle === 'error' ? 'danger' : this.props.confirmBtnBsStyle
				return _react2.default.createElement(
					'p',
					{ style: { marginTop: 20 } },
					this.props.showCancel &&
						_react2.default.createElement(
							'span',
							null,
							_react2.default.createElement(
								'style',
								{ type: 'text/css', scoped: true },
								'button { outline: 0 !important; ' + this.getButtonStyle(cancelBtnBsStyle) + '}'
							),
							_react2.default.createElement(
								'button',
								{
									style: (0, _objectAssign2.default)({}, _SweetAlertStyles2.default.button, this.props.cancelBtnStyle),
									className:
										'btn btn-' + this.props.btnSize + ' btn-' + cancelBtnBsStyle + ' ' + this.props.cancelBtnCssClass,
									onClick: this.props.onCancel,
									type: 'button',
								},
								this.props.cancelBtnText
							)
						),
					this.props.showConfirm &&
						_react2.default.createElement(
							'span',
							null,
							_react2.default.createElement(
								'style',
								{ type: 'text/css', scoped: true },
								'button { outline: 0 !important; ' + this.getButtonStyle(confirmBtnBsStyle) + '}'
							),
							_react2.default.createElement(
								'button',
								{
									ref: 'confirmBtn',
									disabled: this.props.disabled,
									style: (0, _objectAssign2.default)({}, _SweetAlertStyles2.default.button, this.props.confirmBtnStyle),
									className:
										'btn btn-' + this.props.btnSize + ' btn-' + confirmBtnBsStyle + ' ' + this.props.confirmBtnCssClass,
									onClick: this.props.onConfirm,
									type: 'button',
								},
								this.props.confirmBtnText
							)
						)
				)
			},
		},
	])

	return Buttons
})(_react2.default.Component)

Buttons.propTypes = {
	confirmBtnText: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string]),
	confirmBtnBsStyle: _propTypes2.default.oneOf(['default', 'primary', 'link', 'info', 'success', 'warning', 'danger']),
	confirmBtnCssClass: _propTypes2.default.string,
	confirmBtnStyle: _propTypes2.default.object,
	cancelBtnText: _propTypes2.default.oneOfType([_propTypes2.default.node, _propTypes2.default.string]),
	cancelBtnBsStyle: _propTypes2.default.oneOf(['default', 'primary', 'link', 'info', 'success', 'warning', 'danger']),
	cancelBtnCssClass: _propTypes2.default.string,
	cancelBtnStyle: _propTypes2.default.object,
	btnSize: _propTypes2.default.string,
	onCancel: _propTypes2.default.func,
	onConfirm: _propTypes2.default.func,
	showConfirm: _propTypes2.default.bool,
	showCancel: _propTypes2.default.bool,
	focusConfirmBtn: _propTypes2.default.bool,
}
Buttons.defaultProps = {
	confirmBtnText: 'OK',
	confirmBtnBsStyle: 'primary',
	confirmBtnCssClass: '',
	confirmBtnStyle: {},
	cancelBtnText: 'Cancel',
	cancelBtnBsStyle: 'link',
	cancelBtnCssClass: '',
	cancelBtnStyle: {},
	focusConfirmBtn: true,
	disabled: false,
	showConfirm: true,
	showCancel: false,
	btnSize: 'lg',
}
Buttons.styles = {
	primary: {
		borderColor: '#286090',
		shadowColor: 'rgb(165, 202, 234)',
	},
	success: {
		borderColor: '#4cae4c',
		shadowColor: 'rgba(76, 174, 76, 0.6)',
	},
	info: {
		borderColor: '#46b8da',
		shadowColor: 'rgba(70, 184, 218, 0.6)',
	},
	danger: {
		borderColor: '#d43f3a',
		shadowColor: 'rgba(212, 63, 58, 0.6)',
	},
	warning: {
		borderColor: '#eea236',
		shadowColor: 'rgba(238, 162, 54, 0.6)',
	},
	default: {
		borderColor: '#cccccc',
		shadowColor: 'rgba(204, 204, 204, 0.6)',
	},
}
export default Buttons
