import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { push } from 'react-router-redux'
// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-working-cycle'
export const API_URLS = {
	workingCycleTypes: () => `/api/setting/timeoff/working_cycle_types/`,
	workingCycleGroups: () => `/api/setting/timeoff/working_cycle_groups/`,
	workingCycleGroupDetail: workingCycleGroupId => `/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/`,
	workingCycleGroupEmployees: workingCycleGroupId =>
		`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/employees/`,
	workingCycleGroupTodaySummary: workingCycleGroupId => `/api/setting/timeoff/working_cycle_groups/get_today_summary/`,
	workingCycleGroupLogs: workingCycleGroupId =>
		`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_logs/`,
	workingCycleGroupLogDetail: (workingCycleGroupId, workingCycleGroupLogId) =>
		`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_logs/${workingCycleGroupLogId}/`,
}

const REFRESHING_WORKING_CYCLE_TYPES = `${MODULE_NAME} | REFRESHING WORKING CYCLE TYPES`
const REFRESHED_WORKING_CYCLE_TYPES = `${MODULE_NAME} | REFRESHED WORKING CYCLE TYPES`
const REFRESHING_WORKING_CYCLE_GROUPS = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUPS`
const REFRESHED_WORKING_CYCLE_GROUPS = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUPS`
const REFRESHING_WORKING_CYCLE_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUP DETAIL`
const REFRESHED_WORKING_CYCLE_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUP DETAIL`
const REFRESHING_WORKING_CYCLE_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUP EMPLOYEES`
const REFRESHED_WORKING_CYCLE_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUP EMPLOYEES`
const REFRESHING_WORKING_CYCLE_GROUP_TODAY_SUMMARY = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUP TODAY SUMMARY`
const REFRESHED_WORKING_CYCLE_GROUP_TODAY_SUMMARY = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUP TODAY SUMMARY`
const REFRESHING_WORKING_CYCLE_GROUP_LOGS = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUP LOGS`
const REFRESHED_WORKING_CYCLE_GROUP_LOGS = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUP LOGS`
const REFRESHING_WORKING_CYCLE_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHING WORKING CYCLE GROUP LOG DETAIL`
const REFRESHED_WORKING_CYCLE_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHED WORKING CYCLE GROUP LOG DETAIL`

const CREATED_WORKING_CYCLE_TYPE = `${MODULE_NAME} | CREATED WORKING CYCLE TYPE`
const EDITED_WORKING_CYCLE_TYPE = `${MODULE_NAME} | EDITED WORKING CYCLE TYPE`
const DELETED_WORKING_CYCLE_TYPE = `${MODULE_NAME} | DELETED WORKING CYCLE TYPE`
const CREATED_WORKING_CYCLE_GROUP = `${MODULE_NAME} | CREATED WORKING CYCLE GROUP`
const EDITED_WORKING_CYCLE_GROUP = `${MODULE_NAME} | EDITED WORKING CYCLE GROUP`
const DELETED_WORKING_CYCLE_GROUP = `${MODULE_NAME} | DELETED WORKING CYCLE GROUP`
const MOVED_EMPLOYEES = `${MODULE_NAME} | MOVED EMPLOYEES`

const TURN_ON_EDITING_SUMMARY = `${MODULE_NAME} | TURN ON EDITING SUMMARY`
const TURN_OFF_EDITING_SUMMARY = `${MODULE_NAME} | TURN OFF EDITING SUMMARY`
const TURN_ON_EDITING_GROUP = `${MODULE_NAME} | TURN ON EDITING GROUP`
const TURN_OFF_EDITING_GROUP = `${MODULE_NAME} | TURN OFF EDITING GROUP`
const OPEN_GROUP_DETAIL = `${MODULE_NAME} | OPEN GROUP DETAIL`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshWorkingCycleTypes() {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleTypes()
		dispatch({ type: REFRESHING_WORKING_CYCLE_TYPES, apiUrl })
		return api.working_cycle_setting.working_cycle_types.get().then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_TYPES, data, apiUrl })
			return data
		})
	}
}

function refreshWorkingCycleGroups() {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroups()
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUPS, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUPS, data, apiUrl })
			data.forEach(groupDetail => {
				dispatch({
					type: REFRESHED_WORKING_CYCLE_GROUP_DETAIL,
					data: groupDetail,
					apiUrl: API_URLS.workingCycleGroupDetail(groupDetail.id),
				})
			})
			return data
		})
	}
}

export function refreshWorkingCycleGroupDetail(workingCycleGroupId) {
	return dispatch => {
		if (workingCycleGroupId === undefined) {
			return
		}
		let apiUrl = API_URLS.workingCycleGroupDetail(workingCycleGroupId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_DETAIL, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.getDetail(workingCycleGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function refreshWorkingCycleGroupEmployees(workingCycleGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroupEmployees(workingCycleGroupId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_EMPLOYEES, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.getEmployees(workingCycleGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_EMPLOYEES, data, apiUrl })
		})
	}
}

function refreshWorkingCycleGroupTodaySummary() {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroupTodaySummary()
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_TODAY_SUMMARY, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.getTodaySummary().then(({ data }) => {
			dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_TODAY_SUMMARY, data, apiUrl })
			return data
		})
	}
}

export function refreshWorkingCycleGroupLogs(workingCycleGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroupLogs(workingCycleGroupId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_LOGS, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.working_cycle_logs
			.get(workingCycleGroupId)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_LOGS, data, apiUrl })
				return data
			})
	}
}

function refreshWorkingCycleGroupLogDetail(workingCycleGroupId, workingCycleLogId) {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleGroupLogDetail(workingCycleGroupId, workingCycleLogId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_GROUP_LOG_DETAIL, apiUrl })
		return api.working_cycle_setting.working_cycle_groups.working_cycle_logs
			.getDetail(workingCycleGroupId, workingCycleLogId)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_WORKING_CYCLE_GROUP_LOG_DETAIL, data, apiUrl })
				return data
			})
	}
}

export function fetchLogDetail(workingCycleGroupId, workingCycleLogId) {
	return dispatch => {
		return dispatch(refreshWorkingCycleGroupLogDetail(workingCycleGroupId, workingCycleLogId))
	}
}

export function createWorkingCycleType(data) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_types.create(data).then(({ data }) => {
			dispatch(refreshWorkingCycleGroupTodaySummary())
			dispatch(refreshWorkingCycleTypes())
			toast(`Successfully created working cycle type`)
			dispatch({ type: CREATED_WORKING_CYCLE_TYPE, data })
		})
	}
}

export function editWorkingCycleType(workingCycleTypeId, data) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_types.edit(workingCycleTypeId, data).then(({ data }) => {
			dispatch(refreshWorkingCycleGroupTodaySummary())
			dispatch(refreshWorkingCycleTypes())
			toast(`Successfully edited working cycle type`)
			dispatch({ type: EDITED_WORKING_CYCLE_TYPE, data })
		})
	}
}

export function deleteWorkingCycleType(workingCycleTypeId) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_types.delete(workingCycleTypeId).then(() => {
			dispatch(refreshWorkingCycleGroupTodaySummary())
			dispatch(refreshWorkingCycleTypes())
			toast(`Successfully deleted working cycle type`)
			dispatch({ type: DELETED_WORKING_CYCLE_TYPE })
		})
	}
}

export function createWorkingCycleGroup(data) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_groups.create(data).then(({ data: workingCycleGroup }) => {
			dispatch(refreshWorkingCycleGroups())
			dispatch(refreshWorkingCycleGroupTodaySummary())
			toast(`Successfully created working cycle group`)
			dispatch({ type: CREATED_WORKING_CYCLE_GROUP })
			return workingCycleGroup
		})
	}
}

export function editWorkingCycleGroup(workingCycleGroupId, data) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_groups
			.edit(workingCycleGroupId, data)
			.then(({ data: workingCycleGroup }) => {
				dispatch(refreshWorkingCycleGroupLogs(workingCycleGroupId))
				dispatch(refreshWorkingCycleGroupTodaySummary())
				toast(`Successfully edited working cycle group`)
				dispatch(refreshWorkingCycleGroupDetail(workingCycleGroupId)).then(workingCycleGroupDetail => {
					dispatch({ type: EDITED_WORKING_CYCLE_GROUP, groupId: workingCycleGroupId, data: workingCycleGroupDetail })
				})
				return workingCycleGroup
			})
	}
}

export function deleteWorkingCycleGroup(workingCycleGroupId) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_groups.delete(workingCycleGroupId).then(() => {
			dispatch({ type: DELETED_WORKING_CYCLE_GROUP, groupId: workingCycleGroupId })
			toast(`Successfully deleted working cycle group`)
		})
	}
}

export function moveEmployee(selectedGroupId, data) {
	return dispatch => {
		return api.working_cycle_setting.working_cycle_groups.moveEmployees(data).then(() => {
			dispatch(refreshWorkingCycleGroups())
			dispatch(refreshWorkingCycleGroupEmployees(selectedGroupId))
			dispatch(refreshWorkingCycleGroupLogs(selectedGroupId))
			toast(`Successfully moved employees`)
			dispatch({ type: MOVED_EMPLOYEES })
		})
	}
}

export function turnOnEditingSummary() {
	return dispatch => {
		return dispatch({ type: TURN_ON_EDITING_SUMMARY })
	}
}

export function turnOffEditingSummary() {
	return dispatch => {
		return dispatch({ type: TURN_OFF_EDITING_SUMMARY })
	}
}

export function turnOnEditingGroup(groupId) {
	return dispatch => {
		return dispatch({ type: TURN_ON_EDITING_GROUP, groupId })
	}
}

export function turnOffEditingGroup(groupId) {
	return dispatch => {
		return dispatch({ type: TURN_OFF_EDITING_GROUP, groupId })
	}
}

export function openGroupDetail(groupId) {
	return dispatch => {
		dispatch({ type: OPEN_GROUP_DETAIL })
		dispatch(push(`/setting/working-cycle/${groupId}`))
		dispatch(refreshWorkingCycleGroupEmployees(groupId))
		dispatch(refreshWorkingCycleGroupLogs(groupId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshWorkingCycleGroupTodaySummary())
		dispatch(refreshWorkingCycleTypes())
		dispatch(refreshWorkingCycleGroups())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		isEditingSummary: false,
		isEditingGroupIds: [],
	},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_WORKING_CYCLE_TYPES:
		case REFRESHING_WORKING_CYCLE_GROUPS:
		case REFRESHING_WORKING_CYCLE_GROUP_DETAIL:
		case REFRESHING_WORKING_CYCLE_GROUP_EMPLOYEES:
		case REFRESHING_WORKING_CYCLE_GROUP_TODAY_SUMMARY:
		case REFRESHING_WORKING_CYCLE_GROUP_LOGS:
		case REFRESHING_WORKING_CYCLE_GROUP_LOG_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_WORKING_CYCLE_GROUPS:
			let groupDetailApiStates = {}
			action.data.forEach(groupDetail => {
				groupDetailApiStates[API_URLS.workingCycleGroupDetail(groupDetail.id)] = groupDetail
			})
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
					...groupDetailApiStates,
				},
			}
		case REFRESHED_WORKING_CYCLE_TYPES:
		case REFRESHED_WORKING_CYCLE_GROUP_DETAIL:
		case REFRESHED_WORKING_CYCLE_GROUP_EMPLOYEES:
		case REFRESHED_WORKING_CYCLE_GROUP_TODAY_SUMMARY:
		case REFRESHED_WORKING_CYCLE_GROUP_LOGS:
		case REFRESHED_WORKING_CYCLE_GROUP_LOG_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case EDITED_WORKING_CYCLE_GROUP: {
			return {
				...state,
				api: {
					...state.api,
					// update the groupDetailList state in the summary view
					[API_URLS.workingCycleGroups()]: state.api[API_URLS.workingCycleGroups()].map(groupDetail => {
						if (groupDetail.id === action.groupId) {
							return action.data
						}
						return groupDetail
					}),
				},
			}
		}
		case DELETED_WORKING_CYCLE_GROUP: {
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.workingCycleGroupDetail(action.groupId)]: undefined,
					[API_URLS.workingCycleGroups()]: state.api[API_URLS.workingCycleGroups()].filter(
						groupDetail => groupDetail.id !== action.groupId
					),
				},
			}
		}
		case TURN_ON_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingSummary: true,
				},
			}
		case TURN_OFF_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingSummary: false,
				},
			}
		case TURN_ON_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroupIds: [...state.data.isEditingGroupIds, action.groupId],
				},
			}
		case TURN_OFF_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroupIds: state.data.isEditingGroupIds.filter(editingGroupId => editingGroupId !== action.groupId),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
