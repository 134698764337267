import { s, number, string } from 'modules/types'

export class WorkingCycleGroupSummary {
	constructor({ id, name, employee_count: employeeCount }) {
		this.id = id
		this.name = name
		this.employeeCount = employeeCount
	}
}

export const WorkingCycleGroupSummaryType = s({
	id: number.isRequired,
	name: string.isRequired,
	employeeCount: number.isRequired,
})

export default WorkingCycleGroupSummary
