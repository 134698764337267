import form from './form'
import sessionReducer from './session'
import toastrReducer from './toastr'
import alertReducer from './alert'
import { reducer as formReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import authenticatedReducer from '../modules/authenticated'

import announcementReducer from 'views/Announcement/modules/announcement'
import appraisalAdminScoringReducer from 'views/AppraisalAdminScoring/modules/appraisal_admin_scoring'
import dashboardReducer from 'views/Dashboard/modules/dashboard'
import appraisalAdminSessionReducer from 'views/AppraisalAdminSession/modules/appraisal_admin_session'
import appraisalAdminTemplateReducer from 'views/AppraisalAdminTemplate/modules/appraisal_admin_template'
import appraisalCompletedReducer from 'views/AppraisalCompleted/modules/appraisalCompleted'
import appraisalOngoingReducer from 'views/AppraisalOngoing/modules/appraisalOngoing'
import brainstormingReducer from 'views/Brainstorming/modules/brainstorming'
import companyPolicyReducer from 'views/CompanyPolicy/modules/companyPolicy'
import visionMissionSettingReducer from 'views/DashboardSetting/routes/VisionMissionSetting/modules/visionMissionSetting'
import employeeDatabaseReducer from 'views/EmployeeDatabase/modules/employee_database'
import employeeExperienceReducer from 'views/EmployeeDatabase/modules/employeeExperience.js'
import employeeContractReducer from 'views/EmployeeDatabase/modules/employeeContract'
import employeePermissionsReducer from 'views/EmployeeDatabase/modules/employeePermissions'
import employeeIdeaReducer from 'views/EmployeeDatabase/modules/employeeIdea'
import employeeScheduleReducer from 'views/EmployeeDatabase/modules/employeeSchedule'
import onboardingReducer from 'views/EmployeeDatabase/onboarding/modules/onboarding'
import onOffboardingReducer from 'views/EmployeeDatabase/modules/onOffboarding'
import engagementReducer from 'views/Engagement/modules/engagement'
import experienceReducer from 'views/Experience/modules/experience'
import hrExperienceReducer from 'views/HrExperience/modules/hrExperience'
import hrTimeoffReducer from 'views/HrTimeoff/modules/hrTimeoff'
import titleAndRankReducer from 'views/HumanResourceSetting/routes/TitleAndRank/modules/titleAndRank'
import employeeDatabaseSettingReducer from 'views/HumanResourceSetting/routes/EmployeeDatabaseSetting/modules/employeeDatabaseSetting'
import ideaReducer from 'views/Idea/modules/idea'
import ideaWallReducer from 'views/IdeaWall/modules/ideaWall'
import jobInformationReducer from 'views/JobInformation/modules/jobInformation'
import employeeAccessReducer from 'views/PermissionSetting/routes/EmployeeAccess/employeeAccess'
import publicHolidaySettingReducer from 'views/PublicHolidaySetting/modules/publicHolidaySetting'
import requestTimeoffReducer from 'views/RequestTimeoff/modules/requestTimeoff'
import surveyAdminReducer from 'views/SurveyAdmin/modules/surveyAdmin'
import surveyCompletedReducer from 'views/SurveyCompleted/modules/surveyCompleted'
import surveyOngoingReducer from 'views/SurveyOngoing/modules/surveyOngoing'
import timeoffSettingReducer from 'views/TimeoffSetting/modules/timeoffSetting'
import workingCycleSettingReducer from 'views/WorkingCycleSetting/modules/workingCycleSetting'
import settingIdeaReducer from 'views/WorkstationSetting/routes/Idea/modules/settingIdea'
import jobDescriptionReducer from 'views/WorkstationSetting/routes/JobDescription/modules/jobDescription'
import demographicsReducer from 'views/Demographics/modules/demographics'
import appraisalAdminAnalytics from 'views/AppraisalAdminAnalytics/modules/appraisalAdminAnalytics'

export const makeRootReducer = asyncReducers => {
	return {
		session: sessionReducer,
		form: formReducer.plugin(form),
		routing: routerReducer,
		// notification: notificationReducer,
		toastr: toastrReducer,
		authenticated: authenticatedReducer,
		alert: alertReducer,
		announcement: announcementReducer,
		appraisalAdminScoring: appraisalAdminScoringReducer,
		dashboard: dashboardReducer,
		appraisalAdminSession: appraisalAdminSessionReducer,
		appraisalAdminTemplate: appraisalAdminTemplateReducer,
		appraisalCompleted: appraisalCompletedReducer,
		appraisalOngoing: appraisalOngoingReducer,
		brainstorming: brainstormingReducer,
		companyPolicy: companyPolicyReducer,
		visionMissionSetting: visionMissionSettingReducer,
		employeeDatabase: employeeDatabaseReducer,
		employeeContract: employeeContractReducer,
		employeePermissions: employeePermissionsReducer,
		employeeIdea: employeeIdeaReducer,
		employeeSchedule: employeeScheduleReducer,
		onboarding: onboardingReducer,
		onOffboarding: onOffboardingReducer,
		engagement: engagementReducer,
		experience: experienceReducer,
		hrExperience: hrExperienceReducer,
		hrTimeoff: hrTimeoffReducer,
		titleAndRank: titleAndRankReducer,
		employeeDatabaseSetting: employeeDatabaseSettingReducer,
		idea: ideaReducer,
		ideaWall: ideaWallReducer,
		jobInformation: jobInformationReducer,
		settingPermissionEmployeeAccess: employeeAccessReducer,
		publicHolidaySetting: publicHolidaySettingReducer,
		requestTimeoff: requestTimeoffReducer,
		surveyAdmin: surveyAdminReducer,
		surveyCompleted: surveyCompletedReducer,
		surveyOngoing: surveyOngoingReducer,
		timeoffSetting: timeoffSettingReducer,
		workingCycleSetting: workingCycleSettingReducer,
		settingIdea: settingIdeaReducer,
		jobDescription: jobDescriptionReducer,
		demographics: demographicsReducer,
		appraisalAdminAnalytics: appraisalAdminAnalytics,
		employeeExperience: employeeExperienceReducer,
		...asyncReducers,
	}
}

export default makeRootReducer
