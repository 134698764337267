import api from 'modules/api'
import { toast } from 'utils'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-employment_info'

export const API_URLS = {
	employees: () => `/api/setting/permission/get_active_employees/`,
	groupLeaderRoles: () => `/api/setting/permission/group_leader_roles/`,
	employeesByRank: (rankId, isActive) =>
		`/api/setting/permission/ranks/${rankId}/employees/${isActive === false ? '?is_active=false' : ''}`,
	employeesByTitle: (titleId, isActive) =>
		`/api/setting/permission/titles/${titleId}/employees/${isActive === false ? '?is_active=false' : ''}`,
	employeesByGroup: (groupId, isActive) =>
		`/api/setting/permission/groups/${groupId}/employees/${isActive === false ? '?is_active=false' : ''}`,
	employeesByDepartment: (departmentId, isActive) =>
		`/api/setting/permission/departments/${departmentId}/employees/${isActive === false ? '?is_active=false' : ''}`,
	employeesByPosition: (positionId, isActive) =>
		`/api/setting/permission/positions/${positionId}/employees/${isActive === false ? '?is_active=false' : ''}`,
}

const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING EMPLOYEES`
const REFRESHING_RANKS = `${MODULE_NAME} | REFRESHING RANKS`

const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED EMPLOYEES`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED RANKS`
const REFRESHED_TITLES = `${MODULE_NAME} | REFRESHED TITLES`
const REFRESHED_GROUPS = `${MODULE_NAME} | REFRESHED GROUPS`
const REFRESHED_DEPARTMENTS = `${MODULE_NAME} | REFRESHED DEPARTMENTS`
const REFRESHED_POSITIONS = `${MODULE_NAME} | REFRESHED POSITIONS`
const REFRESHED_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHED GROUP LEADER ROLES`
const REFRESHED_EMPLOYEES_BY_RANK = `${MODULE_NAME} | REFRESHED EMPLOYEES BY RANK`
const REFRESHED_EMPLOYEES_BY_TITLE = `${MODULE_NAME} | REFRESHED EMPLOYEES BY TITLE`
const REFRESHED_EMPLOYEES_BY_GROUP = `${MODULE_NAME} | REFRESHED EMPLOYEES BY GROUP`
const REFRESHED_EMPLOYEES_BY_DEPARTMENT = `${MODULE_NAME} | REFRESHED EMPLOYEES BY DEPARTMENT`
const REFRESHED_EMPLOYEES_BY_POSITION = `${MODULE_NAME} | REFRESHED EMPLOYEES BY POSITION`

const CREATED_TITLE = `${MODULE_NAME} | CREATED TITLE`
const EDITED_TITLE = `${MODULE_NAME} | EDITED TITLE`
const DELETED_TITLE = `${MODULE_NAME} | DELETED TITLE`
const CREATED_GROUP = `${MODULE_NAME} | CREATED GROUP`
const EDITED_GROUP = `${MODULE_NAME} | EDITED GROUP`
const DELETED_GROUP = `${MODULE_NAME} | DELETED GROUP`
const CREATED_DEPARTMENT = `${MODULE_NAME} | CREATED DEPARTMENT`
const EDITED_DEPARTMENT = `${MODULE_NAME} | EDITED DEPARTMENT`
const DELETED_DEPARTMENT = `${MODULE_NAME} | DELETED DEPARTMENT`
const CREATED_POSITION = `${MODULE_NAME} | CREATED POSITION`
const EDITED_POSITION = `${MODULE_NAME} | EDITED POSITION`
const DELETED_POSITION = `${MODULE_NAME} | DELETED POSITION`
const CREATED_RANK = `${MODULE_NAME} | CREATED RANK`
const EDITED_RANK = `${MODULE_NAME} | EDITED RANK`
const DELETED_RANK = `${MODULE_NAME} | DELETED RANK`
const MOVED_UP_RANK = `${MODULE_NAME} | MOVED UP RANK`
const MOVED_DOWN_RANK = `${MODULE_NAME} | MOVED DOWN RANK`
const TOGGLE_EDIT = `${MODULE_NAME} | TOGGLE EDIT`

const ERROR_CREATED_TITLE = `${MODULE_NAME} | ERROR CREATED TITLE`
const ERROR_EDITED_TITLE = `${MODULE_NAME} | ERROR EDITED TITLE`
const ERROR_DELETED_TITLE = `${MODULE_NAME} | ERROR DELETED TITLE`
const ERROR_CREATED_RANK = `${MODULE_NAME} | ERROR CREATED RANK`
const ERROR_EDITED_RANK = `${MODULE_NAME} | ERROR EDITED RANK`
const ERROR_DELETED_RANK = `${MODULE_NAME} | ERROR DELETED RANK`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

const ascendingName = (a, b) => {
	if (a.name < b.name) {
		return -1
	} else if (a.name > b.name) {
		return 1
	}
	return 0
}
// ------------------------------------
// Actions
// ------------------------------------

function refreshEmployees() {
	return dispatch => {
		let apiUrl = API_URLS.employees()
		return api.setting.permission.getActiveEmployees().then(({ data }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, data, apiUrl })
		})
	}
}

export function refreshEmployeesByRank(rankId, isActive) {
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEES })
		const apiUrl = API_URLS.employeesByRank(rankId, isActive)
		return api.setting.permission.ranks.employees.get(rankId, isActive).then(({ data: employeeDetails }) => {
			dispatch({ type: REFRESHED_EMPLOYEES_BY_RANK, apiUrl, data: employeeDetails })
			return employeeDetails
		})
	}
}

export function refreshEmployeesByTitle(titleId, isActive) {
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEES })
		const apiUrl = API_URLS.employeesByTitle(titleId, isActive)
		return api.setting.permission.titles.employees.get(titleId, isActive).then(({ data: employeeDetails }) => {
			dispatch({ type: REFRESHED_EMPLOYEES_BY_TITLE, apiUrl, data: employeeDetails })
			return employeeDetails
		})
	}
}

export function refreshEmployeesByGroup(groupId, isActive) {
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEES })
		const apiUrl = API_URLS.employeesByGroup(groupId, isActive)
		return api.setting.permission.groups.employees.get(groupId, isActive).then(({ data: employeeDetails }) => {
			dispatch({ type: REFRESHED_EMPLOYEES_BY_GROUP, apiUrl, data: employeeDetails })
			return employeeDetails
		})
	}
}

export function refreshEmployeesByDepartment(departmentId, isActive) {
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEES })
		const apiUrl = API_URLS.employeesByDepartment(departmentId, isActive)
		return api.setting.permission.departments.employees
			.get(departmentId, isActive)
			.then(({ data: employeeDetails }) => {
				dispatch({ type: REFRESHED_EMPLOYEES_BY_DEPARTMENT, apiUrl, data: employeeDetails })
				return employeeDetails
			})
	}
}

export function refreshEmployeesByPosition(positionId, isActive) {
	return dispatch => {
		dispatch({ type: REFRESHING_EMPLOYEES })
		const apiUrl = API_URLS.employeesByPosition(positionId, isActive)
		return api.setting.permission.positions.employees.get(positionId, isActive).then(({ data: employeeDetails }) => {
			dispatch({ type: REFRESHED_EMPLOYEES_BY_POSITION, apiUrl, data: employeeDetails })
			return employeeDetails
		})
	}
}
export function refreshRanks() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_ranks/`
		dispatch({ type: REFRESHING_RANKS, apiUrl })
		return api.setting.permission.get_ranks().then(({ data }) => {
			dispatch({ type: REFRESHED_RANKS, data, apiUrl })
		})
	}
}

export function refreshTitles() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_titles/`
		return api.setting.permission.get_titles().then(({ data }) => {
			dispatch({ type: REFRESHED_TITLES, data: data.sort(ascendingName), apiUrl })
		})
	}
}

export function refreshGroups() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_groups/`
		return api.setting.permission.get_groups().then(({ data }) => {
			dispatch({ type: REFRESHED_GROUPS, data: data.sort(ascendingName), apiUrl })
		})
	}
}

export function refreshDepartments() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_departments/`
		return api.setting.permission.get_departments().then(({ data }) => {
			dispatch({ type: REFRESHED_DEPARTMENTS, data: data.sort(ascendingName), apiUrl })
		})
	}
}

export function refreshPositions() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_positions/`
		return api.setting.permission.get_positions().then(({ data }) => {
			dispatch({ type: REFRESHED_POSITIONS, data: data.sort(ascendingName), apiUrl })
		})
	}
}

export function refreshGroupLeaderRoles() {
	return dispatch => {
		const apiUrl = API_URLS.groupLeaderRoles()
		return api.setting.permission.group_leader_roles.get().then(({ data }) => {
			dispatch({ type: REFRESHED_GROUP_LEADER_ROLES, data, apiUrl })
		})
	}
}

export function createTitle(name) {
	return dispatch => {
		return api.setting.permission.titles
			.create(name)
			.then(() => {
				dispatch({ type: CREATED_TITLE })
				dispatch(refreshTitles())
				toast(`Created "${name}" title`)
			})
			.catch(err => {
				dispatch({ type: ERROR_CREATED_TITLE })
				throw err
			})
	}
}

export function editTitle(titleId, name) {
	return dispatch => {
		return api.setting.permission.titles
			.edit(titleId, name)
			.then(() => {
				dispatch({ type: EDITED_TITLE })
				dispatch(refreshTitles())
				toast(`Renamed title to "${name}"`)
			})
			.catch(err => {
				dispatch({ type: ERROR_EDITED_TITLE })
				throw err
			})
	}
}

export function deleteTitle(title) {
	return dispatch => {
		return api.setting.permission.titles
			.delete(title.id)
			.then(() => {
				dispatch({ type: DELETED_TITLE })
				dispatch(refreshTitles())
				toast(`Deleted "${title.name}" title`)
			})
			.catch(err => {
				dispatch({ type: ERROR_DELETED_TITLE })
				throw err
			})
	}
}

export function createGroup(data) {
	return dispatch => {
		return api.setting.permission.groups.create(data).then(() => {
			dispatch({ type: CREATED_GROUP })
			dispatch(refreshGroups())
			toast(`Created "${data.name}" group`)
		})
	}
}

export function editGroup(groupId, data) {
	return dispatch => {
		return api.setting.permission.groups.edit(groupId, data).then(() => {
			dispatch(refreshGroupLeaderRoles())
			dispatch({ type: EDITED_GROUP })
			dispatch(refreshGroups())
		})
	}
}

export function deleteGroup(group) {
	return dispatch => {
		return api.setting.permission.groups.delete(group.id).then(() => {
			dispatch({ type: DELETED_GROUP })
			dispatch(refreshGroups())
			toast(`Deleted "${group.name}" group`)
		})
	}
}

export function createDepartment(data) {
	return dispatch => {
		return api.setting.permission.departments.create(data).then(() => {
			dispatch({ type: CREATED_DEPARTMENT })
			dispatch(refreshDepartments())
			toast(`Created "${data.name}" department`)
		})
	}
}

export function editDepartment(departmentId, data) {
	return dispatch => {
		return api.setting.permission.departments.edit(departmentId, data).then(() => {
			dispatch({ type: EDITED_DEPARTMENT })
			dispatch(refreshDepartments())
		})
	}
}

export function deleteDepartment(department) {
	return dispatch => {
		return api.setting.permission.departments.delete(department.id).then(() => {
			dispatch({ type: DELETED_DEPARTMENT })
			dispatch(refreshDepartments())
			toast(`Deleted "${department.name}" department`)
		})
	}
}

export function createPosition(name) {
	return dispatch => {
		return api.setting.permission.positions.create(name).then(() => {
			dispatch({ type: CREATED_POSITION })
			dispatch(refreshPositions())
			toast(`Created "${name}" position`)
		})
	}
}

export function editPosition(positionId, name) {
	return dispatch => {
		return api.setting.permission.positions.edit(positionId, name).then(() => {
			dispatch({ type: EDITED_POSITION })
			dispatch(refreshPositions())
			toast(`Renamed position to "${name}"`)
		})
	}
}

export function deletePosition(position) {
	return dispatch => {
		return api.setting.permission.positions.delete(position.id).then(() => {
			dispatch({ type: DELETED_POSITION })
			dispatch(refreshPositions())
			toast(`Deleted "${position.name}" position`)
		})
	}
}

export function createRank(name) {
	return dispatch => {
		return api.setting.permission.ranks
			.create(name)
			.then(({ data }) => {
				dispatch({ type: CREATED_RANK, data, apiUrl: '/api/setting/permission/get_ranks/' })
			})
			.catch(err => {
				dispatch({ type: ERROR_CREATED_RANK })
				throw err
			})
	}
}

export function editRank(rankId, name) {
	return dispatch => {
		return api.setting.permission.ranks
			.edit(rankId, name)
			.then(() => {
				dispatch({ type: EDITED_RANK })
				dispatch(refreshRanks())
				toast(`Renamed rank to "${name}"`)
			})
			.catch(err => {
				let errors = err.response.jsonData.errors
				dispatch(refreshRanks())
				dispatch({ type: ERROR_EDITED_RANK, errors })
				throw err
			})
	}
}

export function deleteRank(rank) {
	return dispatch => {
		return api.setting.permission.ranks
			.delete(rank.id)
			.then(() => {
				dispatch({ type: DELETED_RANK })
				dispatch(refreshRanks())
				toast(`Deleted "${rank.name}" rank`)
			})
			.catch(err => {
				let errors = err.response.jsonData.errors
				dispatch(refreshRanks())
				dispatch({ type: ERROR_DELETED_RANK, errors })
				throw err
			})
	}
}

export function moveUpRank(rankId) {
	return dispatch => {
		return api.setting.permission.ranks.move_up(rankId).then(() => {
			dispatch({ type: MOVED_UP_RANK })
			dispatch(refreshRanks())
		})
	}
}

export function moveDownRank(rankId) {
	return dispatch => {
		return api.setting.permission.ranks.move_down(rankId).then(() => {
			dispatch({ type: MOVED_DOWN_RANK })
			dispatch(refreshRanks())
		})
	}
}

export function toggleEdit() {
	return dispatch => {
		dispatch({ type: TOGGLE_EDIT })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshEmployees())
		dispatch(refreshRanks())
		dispatch(refreshTitles())
		dispatch(refreshGroups())
		dispatch(refreshDepartments())
		dispatch(refreshPositions())
		dispatch(refreshGroupLeaderRoles())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {
		employees: false,
	},
	data: {
		isEditMode: false,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_EMPLOYEES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					employees: true,
				},
			}
		case REFRESHING_RANKS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_EMPLOYEES_BY_RANK:
		case REFRESHED_EMPLOYEES_BY_TITLE:
		case REFRESHED_EMPLOYEES_BY_GROUP:
		case REFRESHED_EMPLOYEES_BY_DEPARTMENT:
		case REFRESHED_EMPLOYEES_BY_POSITION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					employees: false,
				},
			}
		case REFRESHED_EMPLOYEES:
		case REFRESHED_RANKS:
		case REFRESHED_TITLES:
		case REFRESHED_GROUPS:
		case REFRESHED_DEPARTMENTS:
		case REFRESHED_POSITIONS:
		case REFRESHED_GROUP_LEADER_ROLES:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case CREATED_RANK:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [...state.api[action.apiUrl], action.data],
				},
			}
		case TOGGLE_EDIT:
			return {
				...state,
				data: {
					...state.data,
					isEditMode: !state.data.isEditMode,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
