import { s, arrayOf, number, string } from 'modules/types'
import DefaultQuestionLink, { DefaultQuestionLinkType } from './DefaultQuestionLink'

export class DefaultQuestionCategory {
	constructor({
		id,
		name,
		order,
		view_permissions: viewPermissions,
		answer_permissions: answerPermissions,
		question_links: questionLinks,
	}) {
		this.id = id
		this.name = name
		this.order = order
		this.viewPermissions = viewPermissions
		this.answerPermissions = answerPermissions
		this.questionLinks = questionLinks.map(item => new DefaultQuestionLink(item))
	}
}

export const DefaultQuestionCategoryType = s({
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
	viewPermissions: arrayOf(number),
	answerPermissions: arrayOf(number),
	questionLinks: arrayOf(DefaultQuestionLinkType),
})

export default DefaultQuestionCategory
