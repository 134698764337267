import { s, arrayOf } from 'modules/types'
import EmployeeCalendar, { EmployeeCalendarType } from './EmployeeCalendar'

export class TimeoffCalendar {
	constructor({ data }) {
		this.data = data.map(item => new EmployeeCalendar(item))
	}
}

export const TimeoffCalendarType = s({
	data: arrayOf(EmployeeCalendarType),
})

export default TimeoffCalendar
