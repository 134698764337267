import { s, bool, number } from 'modules/types'

export class ExperienceSetting {
	constructor({
		employee_allowed_to_submit: employeeAllowedToSubmit,
		approver_allowed_to_submit: approverAllowedToSubmit,
		hours_to_submit: hoursToSubmit,
		approver_type: approverType,
		number_of_approver: numberOfApprover,
	}) {
		this.employeeAllowedToSubmit = employeeAllowedToSubmit
		this.approverAllowedToSubmit = approverAllowedToSubmit
		this.hoursToSubmit = hoursToSubmit
		this.approverType = approverType
		this.numberOfApprover = numberOfApprover
	}
}

export const ExperienceSettingType = s({
	employeeAllowedToSubmit: bool.isRequired,
	approverAllowedToSubmit: bool.isRequired,
	hoursToSubmit: number,
	approverType: number, // @ExperienceSettingApproverType
	numberOfApprover: number,
})

export default ExperienceSetting
