import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let PublicHolidaySettingComponent

const PublicHolidaySetting = store => {
	if (PublicHolidaySettingComponent === undefined) {
		PublicHolidaySettingComponent = Loadable({
			loader: () => {
				return import('views/PublicHolidaySetting/containers/PublicHolidaySettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return PublicHolidaySettingComponent
}
export default PublicHolidaySetting
