import { s, number, string } from 'modules/types'

export class WorkingCycleGroupEmployee {
	constructor({
		id,
		NIK,
		full_name: fullName,
		title_name: titleName,
		superior_name: superiorName,
		group_name: groupName,
	}) {
		this.id = id
		this.NIK = NIK
		this.fullName = fullName
		this.titleName = titleName
		this.superiorName = superiorName
		this.groupName = groupName
	}
}

export const WorkingCycleGroupEmployeeType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	fullName: string.isRequired,
	titleName: string.isRequired,
	superiorName: string.isRequired,
	groupName: string.isRequired,
})

export default WorkingCycleGroupEmployee
