import Choice from './Choice'
import Field from './Field'
import FieldGroup from './FieldGroup'
import FieldWithQuestion from './FieldWithQuestion'
import Question from './Question'
import Rank from './Rank'
import Title from './Title'

export default {
	Choice,
	Field,
	FieldGroup,
	FieldWithQuestion,
	Question,
	Rank,
	Title,
}
