import { number, string, bool, s } from 'modules/types'

export class AnswerChoice {
	constructor({ id, content, order, disabled }) {
		this.id = id
		this.content = content
		this.order = order
		this.disabled = disabled
	}
}

export const AnswerChoiceType = s({
	id: number,
	content: string.isRequired,
	order: number.isRequired,
	disabled: bool.isRequired,
})

export default AnswerChoice
