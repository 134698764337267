import { s, bool, number, object, string } from 'modules/types'
import { Employee, EmployeeType } from './Employee'

export const IDEA_STATUS = {
	DRAFT: 1,
	PENDING: 2,
}

export const IDEA_CATEGORY = {
	SAVED: 0,
	SUBMITTED: 1,
	SUBORDINATE: 2,
	SECOND_SUBORDINATE: 3,
}

export class Idea {
	constructor({
		id,
		title,
		description,
		attachment_skleem_files: attachmentSkleemFiles, // attachment is a json containing file_key and file_name, only for redux form, do not use this
		attachments, // dict contain file_url and file_name
		created_by: createdBy,
		given_star: givenStar,
		given_like: givenLike,
		given_flower: givenFlower,
		status,
		posted_to_idea_wall: postedToIdeaWall,
		added,
		submitted_date: submittedDate,
	}) {
		this.id = id
		this.title = title
		this.description = description
		this.attachmentSkleemFiles = attachmentSkleemFiles
		this.attachments = attachments.map(x => ({
			fileUrl: x.file_url,
			fileName: x.file_name,
		}))
		this.createdBy = new Employee(createdBy)
		this.givenStar = givenStar
		this.givenLike = givenLike
		this.givenFlower = givenFlower
		this.status = status
		this.postedToIdeaWall = postedToIdeaWall
		this.added = new Date(added * 1000)
		this.submittedDate = new Date(submittedDate * 1000)
	}
}

export const IdeaType = s({
	id: number.isRequired,
	title: string.isRequired,
	description: string.isRequired,
	attachmentUrl: string,
	attachmentFileName: string,
	createdBy: EmployeeType.isRequired,
	givenStar: bool.isRequired,
	givenLike: bool.isRequired,
	givenFlower: bool.isRequired,
	status: number.isRequired,
	postedToIdeaWall: bool.isRequired,
	added: object.isRequired,
	submittedDate: object.isRequired,
})

export default Idea
