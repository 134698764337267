import { s, number, string } from 'modules/types'
import { SCORE_CARD_STATUS } from 'modules/constants'

export class EmployeeWithScoreCardStatus {
	constructor({ id, NIK, name, score_card_status: scoreCardStatus, profile_picture: profilePicture }) {
		this.id = id
		this.NIK = NIK
		this.name = name
		this.scoreCardStatus = scoreCardStatus
		this.profilePicture = profilePicture
	}

	displayStatus() {
		switch (this.scoreCardStatus) {
			case SCORE_CARD_STATUS.NOT_STARTED:
				return 'NOT_STARTED'
			case SCORE_CARD_STATUS.IN_PROGRESS:
				return 'IN_PROGRESS'
			case SCORE_CARD_STATUS.COMPLETED:
				return 'COMPLETED'
			default:
				throw new Error('Unhandled score card status')
		}
	}

	getStatusColor() {
		switch (this.scoreCardStatus) {
			case SCORE_CARD_STATUS.IN_PROGRESS:
				return 'orange'
			case SCORE_CARD_STATUS.COMPLETED:
				return 'green'
			case SCORE_CARD_STATUS.NOT_STARTED:
			default:
				return 'inherit'
		}
	}
}

export const EmployeeWithScoreCardStatusType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	name: string,
	scoreCardStatus: number.isRequired,
	profilePicture: string,
})

export default EmployeeWithScoreCardStatus
