import { s, arrayOf } from 'modules/types'
import EmployeeWithGroup, { EmployeeWithGroupType } from './EmployeeWithGroup'
import IdeaFlowColumn, { IdeaFlowColumnType } from './IdeaFlowColumn'

export class EmployeeIdeaFlow {
	constructor({ employee, idea_flows: ideaFlows }) {
		this.employee = new EmployeeWithGroup(employee)
		this.ideaFlows = ideaFlows.map(item => new IdeaFlowColumn(item))
	}
}

export const EmployeeIdeaFlowType = s({
	employee: EmployeeWithGroupType,
	ideaFlows: arrayOf(IdeaFlowColumnType),
})

export default EmployeeIdeaFlow
