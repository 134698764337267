import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let CompanyPolicyComponent

const CompanyPolicy = store => {
	if (CompanyPolicyComponent === undefined) {
		CompanyPolicyComponent = Loadable({
			loader: () => {
				return import('views/CompanyPolicy/containers/CompanyPolicyContainer')
			},
			loading: RouteLoader,
		})
	}
	return CompanyPolicyComponent
}

export default CompanyPolicy
