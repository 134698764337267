import Employee from './Employee'
import HolidayGroup from './HolidayGroup'
import HolidayGroupWithEmployeeCount from './HolidayGroupWithEmployeeCount'
import HolidayGroupWithRules from './HolidayGroupWithRules'
import HolidayGroupLog from './HolidayGroupLog'
import HolidayGroupLogDetail from './HolidayGroupLogDetail'
import HolidayType from './HolidayType'
import HolidayYearSummary from './HolidayYearSummary'

export default {
	Employee,
	HolidayGroup,
	HolidayGroupWithEmployeeCount,
	HolidayGroupWithRules,
	HolidayGroupLog,
	HolidayGroupLogDetail,
	HolidayType,
	HolidayYearSummary,
}
