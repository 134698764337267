import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AppraisalAdminTemplateComponent

const AppraisalAdminTemplate = store => {
	if (AppraisalAdminTemplateComponent === undefined) {
		AppraisalAdminTemplateComponent = Loadable({
			loader: () => {
				return import('views/AppraisalAdminTemplate/containers/AppraisalAdminTemplateContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalAdminTemplateComponent
}

export default AppraisalAdminTemplate
