import { s, string, number } from 'modules/types'

export const NEWS_CATEGORY = {
	GENERAL: 0,
	ECONOMICS_POLITICS: 1,
	BUSINESS_MANAGEMENT: 2,
	SPORT_ACTIVITIES: 3,
	TRAVEL_LEISURE: 4,
	COMEDY_ICE_BREAKER: 5,
	PSYCHOLOGY_HUMAN_DEVELOPMENT: 6,
	HEALTH_MEDICINE: 7,
	COMPUTER_TECHNOLOGY: 8,
	SCIENCE_ENGINEERING: 9,
	ART_LITERATURE: 10,
	FUN_FACTS: 11,
	SCIENCE_GEOGRAPHY: 12,
	LAW_POLICIES: 13,
}

export const newsCategoryOptions = [
	{
		value: -1,
		content: 'All Categories',
	},
	{
		value: NEWS_CATEGORY.ART_LITERATURE,
		content: 'Art and Literature',
	},
	{
		value: NEWS_CATEGORY.BUSINESS_MANAGEMENT,
		content: 'Business and Management',
	},
	{
		value: NEWS_CATEGORY.COMEDY_ICE_BREAKER,
		content: 'Comedy and Ice Breaker',
	},
	{
		value: NEWS_CATEGORY.COMPUTER_TECHNOLOGY,
		content: 'Computer and Technology',
	},
	{
		value: NEWS_CATEGORY.ECONOMICS_POLITICS,
		content: 'Economics and Politics',
	},
	{
		value: NEWS_CATEGORY.FUN_FACTS,
		content: 'Fun Facts',
	},
	{
		value: NEWS_CATEGORY.GENERAL,
		content: 'General Post',
	},
	{
		value: NEWS_CATEGORY.HEALTH_MEDICINE,
		content: 'Healt and Medicine',
	},
	{
		value: NEWS_CATEGORY.LAW_POLICIES,
		content: 'Law and Policies',
	},
	{
		value: NEWS_CATEGORY.PSYCHOLOGY_HUMAN_DEVELOPMENT,
		content: 'Psychology and Human Development',
	},
	{
		value: NEWS_CATEGORY.SCIENCE_ENGINEERING,
		content: 'Science and Engineering',
	},
	{
		value: NEWS_CATEGORY.SCIENCE_GEOGRAPHY,
		content: 'Science and Geography',
	},
	{
		value: NEWS_CATEGORY.SPORT_ACTIVITIES,
		content: 'Sport and Activities',
	},
	{
		value: NEWS_CATEGORY.TRAVEL_LEISURE,
		content: 'Travel and Leisure',
	},
]

export class News {
	constructor({ id, url, photo_url: photoUrl, title, description, extra_info_json: extraInfoJson, category }) {
		this.id = id
		this.url = url
		this.photoUrl = photoUrl
		this.description = description
		this.extraInfoJson = extraInfoJson
		this.category = category
	}
}

export const NewsType = s({
	id: number.isRequired,
	url: string.isRequired,
	photoUrl: string.isRequired, // photo URL
	description: string.isRequired,
	extraInfoJson: string.isRequired,
	category: number.isRequired, // @NEWS_CATEGORY
})

export default News
