import { s, number, string } from 'modules/types'

export class PreviousStage {
	constructor({
		stage_id: stageId,
		stage_number: stageNumber,
		group_stage_id: groupStageId,
		group_appraiser_type_type: groupAppraiserTypeType,
		group_appraiser_type_id: groupAppraiserTypeId,
		appraiser_name: appraiserName,
	}) {
		this.stageId = stageId
		this.stageNumber = stageNumber
		this.groupStageId = groupStageId
		this.groupAppraiserTypeType = groupAppraiserTypeType
		this.groupAppraiserTypeId = groupAppraiserTypeId
		this.appraiserName = appraiserName
	}
}

export const PreviousStageType = s({
	stageId: number.isRequired,
	stageNumber: number.isRequired,
	groupStageId: number.isRequired,
	groupAppraiserTypeType: number.isRequired,
	groupAppraiserTypeId: number.isRequired,
	appraiserName: string.isRequired,
})

export default PreviousStage
