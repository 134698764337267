import EmployeeWithPermissions from './EmployeeWithPermissions'
import Permission from './Permission'
import PermissionWithExt from './PermissionWithExt'

export const PERMISSION_TYPE = {
	USER: 1,
	RANK: 2,
}

export default {
	EmployeeWithPermissions,
	Permission,
	PermissionWithExt,
}
