import { s, arrayOf } from 'modules/types'
import Idea, { IdeaType } from './Idea'
import Comment, { CommentType } from './Comment'

export class IdeaDetail {
	constructor({ idea, comments }) {
		this.idea = new Idea(idea)
		this.comments = comments.map(item => new Comment(item))
	}
}

export const IdeaDetailType = s({
	idea: IdeaType,
	comments: arrayOf(CommentType),
})

export default IdeaDetail
