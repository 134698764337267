import { s, string, number } from 'modules/types'

export const STATUS = {
	DRAFT: 1,
	PENDING: 2,
}

export class Idea {
	constructor({ id, title, description, attachment_url: attachmentUrl, status }) {
		this.id = id
		this.title = title
		this.description = description
		this.attachmentUrl = attachmentUrl
		this.status = status
	}
}

export const IdeaType = s({
	id: number.isRequired,
	title: string.isRequired,
	description: string.isRequired,
	attachmentUrl: string,
	status: number.isRequired, // @STATUS
})

export default Idea
