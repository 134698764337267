import React from 'react'

// material ui
import TablePagination from '@material-ui/core/TablePagination'

// modules
import skleemi18n from 'utils/skleemi18n'

const i18nDict = {
	ROWS_PER_PAGE: {
		id: 'Baris per halaman:',
		en: 'Rows per page:',
	},
	OF: {
		id: 'dari',
		en: 'of',
	},
}

class CustomTablePagination extends React.Component {
	state = {
		page: this.props.page,
	}

	handleChangePage = (event, page) => {
		this.setState({ page })
		this.props.onPageChange(page)
	}

	render() {
		const {
			// Computed
			// pages,
			// Props
			count,
			page,
			// showPageSizeOptions,
			pageSize = 25,
			// canPrevious,
			// canNext,
			onPageSizeChange,
			translater,
		} = this.props
		return (
			<TablePagination
				component={'div'}
				count={count}
				rowsPerPage={pageSize}
				page={page}
				onChangePage={this.handleChangePage}
				rowsPerPageOptions={[10, 25, 50, 100]}
				onChangeRowsPerPage={e => onPageSizeChange(parseInt(e.target.value, 10))}
				labelRowsPerPage={translater.ROWS_PER_PAGE}
				labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${translater.OF} ${count}`}
				style={{ float: 'right', borderBottom: 0 }}
			/>
		)
	}
}

export default skleemi18n(CustomTablePagination, i18nDict)
