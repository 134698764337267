import { s, number, string } from 'modules/types'

export class EmployeeWithDetail {
	constructor({
		id,
		name,
		superior_name: superiorName,
		NIK,
		rank_name: rankName,
		department_name: departmentName,
		position_name: positionName,
		group_name: groupName,
		title_name: titleName,
	}) {
		this.id = id
		this.name = name
		this.superiorName = superiorName
		this.NIK = NIK
		this.rankName = rankName
		this.departmentName = departmentName
		this.positionName = positionName
		this.groupName = groupName
		this.titleName = titleName
	}
}

export const EmployeeWithDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	superiorName: string.isRequired,
	NIK: string.isRequired,
	rankName: string.isRequired,
	departmentName: string.isRequired,
	positionName: string.isRequired,
	groupName: string.isRequired,
	titleName: string.isRequired,
})

export default EmployeeWithDetail
