import { s, number, string } from 'modules/types'
import ExternalCompany, { ExternalCompanyType } from './ExternalCompany'

export class EmployeeExternal {
	constructor({ id, name, NIK, company }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.company = new ExternalCompany(company)
	}
}

export const EmployeeExternalType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string,
	company: ExternalCompanyType,
})

export default EmployeeExternal
