import { s, arrayOf, number, string } from 'modules/types'

class GroupSimple {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const GroupSimpleType = s({
	id: number.isRequired,
	name: string.isRequired,
})

class AppraisalGroupStageAppraiserType {
	constructor({ id, type, appraiser_id: appraiserId, rank_id: rankId, group_role_id: groupRoleId }) {
		this.id = id
		this.type = type
		this.appraiserId = appraiserId
		this.rankId = rankId
		this.groupRoleId = groupRoleId
	}
}

const AppraisalGroupStageAppraiserTypeType = s({
	id: number.isRequired,
	type: number.isRequired,
	appraiserId: number.isRequired,
})

class GroupStage {
	constructor({ id, stage_id: stageId, stage_number: stageNumber, appraiser_types: appraiserTypes }) {
		this.id = id
		this.stageId = stageId
		this.stageNumber = stageNumber
		this.appraiserTypes = appraiserTypes.map(item => new AppraisalGroupStageAppraiserType(item))
	}
}

const GroupStageType = s({
	id: number.isRequired,
	stageId: number.isRequired,
	appraiserTypes: arrayOf(AppraisalGroupStageAppraiserTypeType),
})

class AppraiseeDetail {
	constructor({ id, employee_id: employeeId, group_stages: groupStages }) {
		this.id = id
		this.employeeId = employeeId
		this.groupStages = groupStages.map(item => new GroupStage(item))
	}
}

const AppraiseeDetailType = s({
	id: number.isRequired,
	employeeId: number.isRequired,
	groupStages: arrayOf(GroupStageType),
})

class GroupWithAppraisees {
	constructor({ group, appraisees }) {
		this.group = new GroupSimple(group)
		this.appraisees = appraisees.map(item => new AppraiseeDetail(item))
	}
}

export const GroupWithAppraiseesType = s({
	group: GroupSimpleType,
	appraisees: arrayOf(AppraiseeDetailType),
})

export default GroupWithAppraisees
