import { s, number } from 'modules/types'
import BrainstormAudienceSummaryReport, { BrainstormAudienceSummaryReportType } from './BrainstormAudienceSummaryReport'

export class BrainstormSummaryReport {
	constructor({ total, group, all }) {
		this.total = total
		this.group = new BrainstormAudienceSummaryReport(group)
		this.all = new BrainstormAudienceSummaryReport(all)
	}
}

export const BrainstormSummaryReportType = s({
	total: number.isRequired,
	group: BrainstormAudienceSummaryReportType,
	all: BrainstormAudienceSummaryReportType,
})

export default BrainstormSummaryReport
