import _ from 'lodash'
import { push, replace } from 'react-router-redux'
import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
import { defaultFieldsDict } from 'modules/dictionaries'
import { refreshOffboardingRequests } from './onOffboarding'

// ------------------------------------
// Constants
// ------------------------------------

export const EMPLOYEE_MENU = {
	EXPERIENCE: 1,
}

export const ONBOARDING_VIEW = {
	MENU: 0,
	ADD: 1,
	IMPORT: 2,
}

export const DEFAULT_FIELD_GROUP_NAMES = {
	ACCOUNT_AND_PERSONAL_INFO: 'Account and Personal Information',
	EMPLOYMENT_INFO: 'Employment Information',
}

const MODULE_NAME = 'employee_database'

export const ROUTES = {
	base: () => `/human_resources/employee_database/`,
	employeeDetail: employeeId => `/human_resources/employee_database/employees/${employeeId}`,
	accountPersonalGroup: employeeId =>
		`/human_resources/employee_database/employees/${employeeId}/field_groups/account_personal`,
	employmentGroup: employeeId => `/human_resources/employee_database/employees/${employeeId}/field_groups/employment`,
	fieldGroup: (employeeId, fieldGroupId) =>
		`/human_resources/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}`,
	onboarding: () => `/human_resources/employee_database/onboarding`,
	initOffboarding: employeeId => `/human_resources/employee_database/employees/${employeeId}/init-offboarding`,
	editOffboarding: requestId => `/human_resources/employee_database/offboarding-requests/${requestId}`,
	processOffboarding: requestId => `/human_resources/employee_database/offboarding-requests/${requestId}/process`,
}

export const API_URLS = {
	employeeList: () => `/api/employee_database/get_employees/`,
	fieldGroups: () => `/api/employee_database/field_groups/`,
	allColumns: () => `/api/employee_database/columns/get_all_question_columns/`,
	fieldWithQuestionAnswerList: (employeeId, fieldGroupId) =>
		`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/`,
	profileSummary: employeeId => `/api/employee_database/employees/${employeeId}/profile_summary/`,
	detailChangeLogs: employeeId => `/api/employee_database/employees/${employeeId}/detail_change_logs/`,
	registerEmployeeChoices: () => `/api/employee_database/employees/get_register_employee_choices/`,
}

const EDITED_COLUMN = `${MODULE_NAME} | EDITED COLUMN`
const CHANGE_PROFILE_PICTURE = `${MODULE_NAME} | CHANGE PROFILE PICTURE`

const ANSWERED_TEXT = `${MODULE_NAME} | ANSWERED TEXT`
const ANSWERED_MULTIPLE_CHOICE = `${MODULE_NAME} | ANSWERED MULTIPLE CHOICE`
const ANSWERED_WITH_CHOICES = `${MODULE_NAME} | ANSWERED WITH CHOICES`
const UPLOADED_FILE = `${MODULE_NAME} | UPLOADED FILE`
const REMOVED_FILE = `${MODULE_NAME} | REMOVED FILE`

const ADDED_FIELD_ANSWER = `${MODULE_NAME} | ADDED FIELD ANSWER`
const DELETED_FIELD_ANSWER = `${MODULE_NAME} | DELETED FIELD ANSWER`
const ANSWERED_TEXT_FIELD_ANSWER = `${MODULE_NAME} | ANSWERED TEXT FIELD ANSWER`
const ANSWERED_MULTIPLE_CHOICE_FIELD_ANSWER = `${MODULE_NAME} | ANSWERED MULTIPLE CHOICE FIELD ANSWER`
const ANSWERED_WITH_CHOICES_FIELD_ANSWER = `${MODULE_NAME} | ANSWERED WITH CHOICES FIELD ANSWER`
const UPLOADED_FILE_FIELD_ANSWER = `${MODULE_NAME} | UPLOADED FILE FIELD ANSWER`
const REMOVED_FILE_FIELD_ANSWER = `${MODULE_NAME} | REMOVED FILE FIELD ANSWER`

const ARCHIVED_EMPLOYEE = `${MODULE_NAME} | ARCHIVED_EMPLOYEE`
const RESTORED_EMPLOYEE = `${MODULE_NAME} | RESTORED EMPLOYEE`
const CHANGE_ONBOARDING_VIEW = `${MODULE_NAME} | CHANGE ONBOARDING VIEW`
const CHANGE_NAME_OR_NIK_FILTER = `${MODULE_NAME} | CHANGE NAME FILTER`
const CHANGE_PAGE_FILTER = `${MODULE_NAME} | CHANGE PAGE FILTER`
const CHANGE_LIMIT_FILTER = `${MODULE_NAME} | CHANGE LIMIT FILTER`
const TOGGLE_EDIT_EMPLOYEE = `${MODULE_NAME} | TOGGLE EDIT EMPLOYEE`
const OPEN_FIELD_GROUP_DETAIL = `${MODULE_NAME} | OPEN FIELD GROUP DETAIL`
const TOGGLE_SHOW_ARCHIVED = `${MODULE_NAME} | TOGGLE_SHOW_ARCHIVED`
const CHANGE_COLUMN_FILTER = `${MODULE_NAME} | CHANGE_COLUMN_FILTER`
const REMOVE_COLUMN_FILTER = `${MODULE_NAME} | REMOVE_COLUMN_FILTER`

const REFRESHING_EMPLOYEE_DATABASE = `${MODULE_NAME} | REFRESHING EMPLOYEE DATABASE`
const REFRESHING_DETAIL_CHANGE_LOGS = `${MODULE_NAME} | REFRESHING DETAIL CHANGE LOGS`

const REFRESHED_EMPLOYEE_DATABASE = `${MODULE_NAME} | REFRESHED EMPLOYEE DATABASE`
const REFRESHED_FIELD_GROUPS = `${MODULE_NAME} | REFRESHED FIELD GROUPS`
const REFRESHED_ADDED_COLUMNS = `${MODULE_NAME} | REFRESHED ADDED COLUMNS`
const REFRESHED_EMPLOYEE_DETAIL = `${MODULE_NAME} | REFRESHED EMPLOYEE DETAIL`
const REFRESHED_PROFILE_SUMMARY = `${MODULE_NAME} | REFRESHED PROFILE SUMMARY`
const REFRESHED_DETAIL_CHANGE_LOGS = `${MODULE_NAME} | REFRESHED DETAIL CHANGE LOGS`
const REFRESHED_REGISTER_EMPLOYEE_CHOICES = `${MODULE_NAME} | REFRESHED REGISTER EMPLOYEE CHOICES`

const LOADED_MORE_DETAIL_CHANGE_LOGS = `${MODULE_NAME} | LOADED MORE DETAIL CHANGE LOGS`

const ERROR_REFRESHING_EMPLOYEE_DATABASE = `${MODULE_NAME} | ERROR REFRESHING EMPLOYEE DATABASE`
const ERROR_REFRESHING_FIELD_GROUPS = `${MODULE_NAME} | ERROR REFRESHING FIELD GROUPS`
const ERROR_REFRESHING_ADDED_COLUMNS = `${MODULE_NAME} | ERROR REFRESHING ADDED COLUMNS`
const ERROR_REFRESHING_EMPLOYEE_DETAIL = `${MODULE_NAME} | ERROR REFRESHING EMPLOYEE DETAIL`
const ERROR_EDITING_COLUMN = `${MODULE_NAME} | ERROR EDITING COLUMN`
const ERROR_ANSWERING_TEXT = `${MODULE_NAME} | ERROR ANSWERING TEXT`
const ERROR_ANSWERING_MULTIPLE_CHOICE = `${MODULE_NAME} | ERROR ANSWERING MULTIPLE CHOICE`
const ERROR_ANSWERING_WITH_CHOICES = `${MODULE_NAME} | ERROR ANSWERING WITH CHOICES`
const ERROR_UPLOADING_FILE = `${MODULE_NAME} | ERROR UPLOADING FILE`
const ERROR_REMOVING_FILE = `${MODULE_NAME} | ERROR REMOVING FILE`
const ERROR_ADDING_FIELD_ANSWER = `${MODULE_NAME} | ERROR ADDING FIELD ANSWER`
const ERROR_DELETING_FIELD_ANSWER = `${MODULE_NAME} | ERROR DELETING FIELD ANSWER`
const ERROR_ANSWERING_TEXT_FIELD_ANSWER = `${MODULE_NAME} | ERROR ANSWERING TEXT FIELD ANSWER`
const ERROR_ANSWERING_MULTIPLE_CHOICE_FIELD_ANSWER = `${MODULE_NAME} | ERROR ANSWERING MULTIPLE CHOICE FIELD ANSWER`
const ERROR_ANSWERING_WITH_CHOICES_FIELD_ANSWER = `${MODULE_NAME} | ERROR ANSWERING WITH CHOICES FIELD ANSWER`
const ERROR_UPLOADING_FILE_FIELD_ANSWER = `${MODULE_NAME} | ERROR UPLOADING FILE FIELD ANSWER`
const ERROR_REMOVING_FILE_FIELD_ANSWER = `${MODULE_NAME} | ERROR REMOVING FILE FIELD ANSWER`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshEmployeeList() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.employeeList()
		let state = getState()
		if (state.employeeDatabase === undefined) {
			state.data = {}
		} else {
			state = state.employeeDatabase
		}
		let args = {
			show_archived: state.data.showArchived || false,
			filters: [],
		}
		if (state.data.nameOrNIKFilter) {
			args.name_or_nik = state.data.nameOrNIKFilter
		}
		if (state.data.pageFilter) {
			args.page = state.data.pageFilter
		}
		if (state.data.limitFilter) {
			args.limit = state.data.limitFilter
		}
		args.filters = state.data.filters
		dispatch({ type: REFRESHING_EMPLOYEE_DATABASE, apiUrl })
		return api.employee_database
			.getEmployees(args)
			.then(({ data, pagination }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_DATABASE, data, apiUrl, pagination })
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_EMPLOYEE_DATABASE })
				throw err
			})
	}
}

export function toggleShowArchived() {
	return dispatch => {
		dispatch({ type: TOGGLE_SHOW_ARCHIVED })
		dispatch(refreshEmployeeList())
	}
}

export function refreshFieldGroups() {
	return dispatch => {
		let apiUrl = API_URLS.fieldGroups()
		return api.employee_database
			.getFieldGroups()
			.then(({ data: fieldGroups }) => {
				dispatch({ type: REFRESHED_FIELD_GROUPS, data: fieldGroups.sort((a, b) => a.order - b.order), apiUrl })
				return fieldGroups
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_FIELD_GROUPS })
				throw err
			})
	}
}

function refreshAllColumns() {
	return (dispatch, getState) => {
		const {
			employeeDatabase: {
				data: { dict },
			},
			session: {
				frontendSetting: { language },
			},
		} = getState()
		let apiUrl = API_URLS.allColumns()
		return api.employee_database.columns
			.getAllQuestionColumns()
			.then(({ data }) => {
				dispatch({
					type: REFRESHED_ADDED_COLUMNS,
					data: data
						.sort((a, b) => a.question.order - b.question.order)
						.sort((a, b) => {
							if (language === 'en') {
								return a.field.name.localeCompare(b.field.name)
							} else {
								return (dict[a.field.name] || a.field.name).localeCompare(dict[b.field.name] || b.field.name)
							}
						}),
					apiUrl,
				})
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_ADDED_COLUMNS })
				throw err
			})
	}
}

export function refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.fieldWithQuestionAnswerList(employeeId, fieldGroupId)
		return api.employee_database.employees.fieldGroups
			.get(employeeId, fieldGroupId)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_DETAIL, data, apiUrl })
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_EMPLOYEE_DETAIL })
				throw err
			})
	}
}

export function refreshProfileSummary(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.profileSummary(employeeId)
		return api.employee_database.employees.profile_summary(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_PROFILE_SUMMARY, data, apiUrl })
		})
	}
}

export function refreshDetailChangeLogs(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.detailChangeLogs(employeeId)
		dispatch({ type: REFRESHING_DETAIL_CHANGE_LOGS, apiUrl })
		return api.employee_database.employees
			.getDetailChangeLogs(employeeId)
			.then(({ data: detailChangeLogs, pagination }) => {
				dispatch({ type: REFRESHED_DETAIL_CHANGE_LOGS, data: detailChangeLogs, apiUrl, pagination })
				return detailChangeLogs
			})
	}
}

export function loadMoreDetailChangeLogs(employeeId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.detailChangeLogs(employeeId)
		const {
			employeeDatabase: { pagination },
		} = getState()
		const detailChangeLogsPagination = getObjectOrUndefined(pagination, apiUrl)
		const { currentPage, totalPage } = detailChangeLogsPagination
		if (currentPage === totalPage) {
			return null
		}
		dispatch({ type: REFRESHING_DETAIL_CHANGE_LOGS, apiUrl })
		return api.employee_database.employees
			.getDetailChangeLogs(employeeId, currentPage + 1)
			.then(({ data: detailChangeLogs, pagination }) => {
				dispatch({ type: LOADED_MORE_DETAIL_CHANGE_LOGS, data: detailChangeLogs, pagination, apiUrl })
			})
	}
}

function refreshRegisterEmployeeChoices() {
	return dispatch => {
		let apiUrl = API_URLS.registerEmployeeChoices()
		return api.employee_database.employees.get_register_employee_choices().then(({ data }) => {
			dispatch({ type: REFRESHED_REGISTER_EMPLOYEE_CHOICES, data, apiUrl })
		})
	}
}

export function editColumn(columnId, questionId, prevQuestionId) {
	return (dispatch, getState) => {
		const {
			employeeDatabase: {
				data: { filters: currentFilters },
			},
		} = getState()
		return api.employee_database.columns
			.edit(columnId, questionId)
			.then(() => {
				dispatch({ type: EDITED_COLUMN })
				dispatch(refreshAllColumns())
				if (currentFilters.find(filter => filter.question_id === prevQuestionId)) {
					dispatch(removeColumnFilter(prevQuestionId))
				}
				dispatch(refreshEmployeeList())
			})
			.then(() => {
				toast('Successfully changed column')
			})
			.catch(err => {
				dispatch({ type: ERROR_EDITING_COLUMN })
				throw err
			})
	}
}

export function archiveEmployee(employeeId) {
	return dispatch => {
		return api.employee_database.employees.archive_employee(employeeId).then(() => {
			dispatch({ type: ARCHIVED_EMPLOYEE })
			toast('Successfully archived employee')
			dispatch(push(ROUTES.base()))
			dispatch(refreshEmployeeList())
		})
	}
}

export function restoreEmployee(employeeId) {
	return dispatch => {
		return api.employee_database.employees.restore_employee(employeeId).then(() => {
			dispatch({ type: RESTORED_EMPLOYEE })
			toast('Successfully restored employee')
			dispatch(push(ROUTES.base()))
			dispatch(refreshEmployeeList())
		})
	}
}

export function changeProfilePicture(employeeId, newPictureData) {
	return (dispatch, getState) => {
		return api.employee_database.employees.change_profile_picture(employeeId, newPictureData).then(() => {
			dispatch({ type: CHANGE_PROFILE_PICTURE })
			toast("Successfully changed employee's profile picture")
			dispatch(refreshDetailChangeLogs(employeeId))
			dispatch(refreshProfileSummary(employeeId))
			// const fieldGroups = getObjectOrUndefined(getState().employeeDatabase.api, API_URLS.fieldGroups())
			// if (fieldGroups) {
			// 	dispatch(openFieldGroupDetail(fieldGroups[0].id)) // it's supposedly fine to access the first element of fieldGroups as every company will have 2 default field groups at any time
			// }
		})
	}
}

export function answerText(employeeId, fieldGroupId, fieldId, questionId, answer) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answer(employeeId, fieldGroupId, fieldId, questionId, answer)
			.then(() => {
				dispatch({ type: ANSWERED_TEXT })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshProfileSummary(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_TEXT })
				throw err
			})
	}
}

export function answerMultipleChoice(employeeId, fieldGroupId, fieldId, questionId, choiceId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answerMultipleChoice(employeeId, fieldGroupId, fieldId, questionId, choiceId)
			.then(() => {
				dispatch({ type: ANSWERED_MULTIPLE_CHOICE })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshProfileSummary(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_MULTIPLE_CHOICE })
				throw err
			})
	}
}

export function answerWithChoices(employeeId, fieldGroupId, fieldId, questionId, choiceIds) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answerWithChoices(employeeId, fieldGroupId, fieldId, questionId, choiceIds)
			.then(() => {
				dispatch({ type: ANSWERED_WITH_CHOICES })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshProfileSummary(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_WITH_CHOICES })
				throw err
			})
	}
}

export function uploadFile(employeeId, fieldGroupId, fieldId, questionId, formData) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.uploadFile(employeeId, fieldGroupId, fieldId, questionId, formData)
			.then(() => {
				dispatch({ type: UPLOADED_FILE })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshProfileSummary(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_UPLOADING_FILE })
				throw err
			})
	}
}

export function removeFile(employeeId, fieldGroupId, fieldId, questionId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.removeFile(employeeId, fieldGroupId, fieldId, questionId)
			.then(() => {
				dispatch({ type: REMOVED_FILE })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshProfileSummary(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_REMOVING_FILE })
				throw err
			})
	}
}

export function addFieldAnswer(employeeId, fieldGroupId, fieldId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.addFieldAnswer(employeeId, fieldGroupId, fieldId)
			.then(({ data }) => {
				dispatch({ type: ADDED_FIELD_ANSWER, data })
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_ADDING_FIELD_ANSWER })
				throw err
			})
	}
}

export function deleteFieldAnswer(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.deleteFieldAnswer(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId)
			.then(() => {
				dispatch({ type: DELETED_FIELD_ANSWER })
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
			})
			.catch(err => {
				dispatch({ type: ERROR_DELETING_FIELD_ANSWER })
				throw err
			})
	}
}

export function answerTextFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, answer) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answerFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, answer)
			.then(() => {
				dispatch({ type: ANSWERED_TEXT_FIELD_ANSWER })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_TEXT_FIELD_ANSWER })
				throw err
			})
	}
}

export function answerMultipleChoiceFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answerMultipleChoiceFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceId)
			.then(() => {
				dispatch({ type: ANSWERED_MULTIPLE_CHOICE_FIELD_ANSWER })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_MULTIPLE_CHOICE_FIELD_ANSWER })
				throw err
			})
	}
}

export function answerWithChoicesFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceIds) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.answerWithChoicesFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceIds)
			.then(() => {
				dispatch({ type: ANSWERED_WITH_CHOICES_FIELD_ANSWER })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_WITH_CHOICES_FIELD_ANSWER })
				throw err
			})
	}
}

export function uploadFileFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, formData) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.uploadFileFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, formData)
			.then(() => {
				dispatch({ type: UPLOADED_FILE_FIELD_ANSWER })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_UPLOADING_FILE_FIELD_ANSWER })
				throw err
			})
	}
}

export function removeFileFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId) {
	return dispatch => {
		return api.employee_database.employees.fieldGroups
			.removeFileFieldAns(employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId)
			.then(() => {
				dispatch({ type: REMOVED_FILE_FIELD_ANSWER })
				dispatch(refreshDetailChangeLogs(employeeId))
				dispatch(refreshFieldWithQuestionAnswerList(employeeId, fieldGroupId))
				dispatch(refreshEmployeeList())
			})
			.catch(err => {
				dispatch({ type: ERROR_REMOVING_FILE_FIELD_ANSWER })
				throw err
			})
	}
}

export function openFieldGroupDetail(employeeId, fieldGroupId) {
	return dispatch => {
		dispatch({ type: OPEN_FIELD_GROUP_DETAIL })
		dispatch(replace(ROUTES.fieldGroup(employeeId, fieldGroupId)))
	}
}

export function changeOnboardingView(view) {
	return dispatch => {
		if (view === ONBOARDING_VIEW.ADD) {
			dispatch(refreshRegisterEmployeeChoices())
		}
		dispatch({ type: CHANGE_ONBOARDING_VIEW, view })
	}
}

export function changeNameOrNIKFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_NAME_OR_NIK_FILTER, name })
		dispatch(changePageFilter(1))
		dispatch(refreshEmployeeList())
	}
}

export function changePageFilter(page) {
	return dispatch => {
		dispatch({ type: CHANGE_PAGE_FILTER, page })
		dispatch(refreshEmployeeList())
	}
}

export function changeLimitFilter(limit) {
	return dispatch => {
		dispatch({ type: CHANGE_LIMIT_FILTER, limit })
		dispatch(refreshEmployeeList())
	}
}

export function toggleEditEmployee() {
	return dispatch => {
		dispatch({ type: TOGGLE_EDIT_EMPLOYEE })
	}
}

export function changeColumnFilter(filters) {
	return dispatch => {
		dispatch({ type: CHANGE_COLUMN_FILTER, filters })
		dispatch(refreshEmployeeList())
	}
}

export function removeColumnFilter(questionId) {
	return dispatch => {
		dispatch({ type: REMOVE_COLUMN_FILTER, questionId })
	}
}

export function loadModule() {
	return (dispatch, getState) => {
		const {
			session: { frontendSetting },
		} = getState()
		const keys = Object.keys(defaultFieldsDict)
		const language = getObjectOrUndefined(frontendSetting, 'language') || 'en'
		const dict = keys.reduce((prev, cur) => {
			return {
				...prev,
				[cur]: defaultFieldsDict[cur][language],
			}
		}, {})
		dispatch({ type: LOAD_MODULE, dict })
		dispatch(refreshOffboardingRequests())
		dispatch(refreshEmployeeList())
		dispatch(refreshFieldGroups())
		dispatch(refreshAllColumns())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	data: {
		onboardingView: ONBOARDING_VIEW.MENU,
		isEditEmployee: false,
		nameOrNIKFilter: '',
		pageFilter: 1,
		limitFilter: 25,
		showArchived: false,
		filters: [],
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_EMPLOYEE_DATABASE:
		case REFRESHING_DETAIL_CHANGE_LOGS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_FIELD_GROUPS:
		case REFRESHED_ADDED_COLUMNS:
		case REFRESHED_EMPLOYEE_DETAIL:
		case REFRESHED_PROFILE_SUMMARY:
		case REFRESHED_REGISTER_EMPLOYEE_CHOICES:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_DETAIL_CHANGE_LOGS:
		case REFRESHED_EMPLOYEE_DATABASE:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case LOADED_MORE_DETAIL_CHANGE_LOGS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [...state.api[action.apiUrl], ...action.data],
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case CHANGE_ONBOARDING_VIEW:
			return {
				...state,
				data: {
					...state.data,
					onboardingView: action.view,
				},
			}
		case CHANGE_NAME_OR_NIK_FILTER:
			return {
				...state,
				data: {
					...state.data,
					nameOrNIKFilter: action.name,
				},
			}
		case CHANGE_PAGE_FILTER:
			return {
				...state,
				data: {
					...state.data,
					pageFilter: action.page,
				},
			}
		case CHANGE_LIMIT_FILTER:
			return {
				...state,
				data: {
					...state.data,
					limitFilter: action.limit,
					pageFilter: 1,
				},
			}
		case TOGGLE_EDIT_EMPLOYEE:
			return {
				...state,
				data: {
					...state.data,
					isEditEmployee: !state.data.isEditEmployee,
				},
			}
		case TOGGLE_SHOW_ARCHIVED:
			return {
				...state,
				data: {
					...state.data,
					pageFilter: 1,
					showArchived: !state.data.showArchived,
				},
			}
		case OPEN_FIELD_GROUP_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					isEditEmployee: false,
				},
			}
		case CHANGE_COLUMN_FILTER:
			return {
				...state,
				data: {
					...state.data,
					filters: action.filters,
				},
			}
		case REMOVE_COLUMN_FILTER:
			return {
				...state,
				data: {
					...state.data,
					filters: state.data.filters.filter(item => item.question_id !== action.questionId),
				},
			}
		case LOAD_MODULE:
			return {
				...state,
				data: {
					...state.data,
					dict: action.dict,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
