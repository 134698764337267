import { s, number, object, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class BrainstormAnswer {
	constructor({ id, content, created_by: createdBy, added }) {
		this.id = id
		this.content = content
		this.createdBy = new Employee(createdBy)
		this.added = new Date(added * 1000)
	}
}

export const BrainstormAnswerType = s({
	id: number,
	content: string.isRequired,
	createdBy: EmployeeType,
	added: object.isRequired,
})

export default BrainstormAnswer
