import { s, arrayOf } from 'modules/types'
import { Column, ColumnType } from './Column'
import { EmployeeColumn, EmployeeColumnType } from './EmployeeColumn'

export class EmployeeWithColumn {
	constructor({ columns, employees }) {
		this.columns = columns.map(item => new Column(item))
		this.employees = employees.map(item => new EmployeeColumn(item))
	}
}

export const EmployeeWithColumnType = s({
	columns: arrayOf(ColumnType).isRequired,
	employees: arrayOf(EmployeeColumnType).isRequired,
})

export default EmployeeWithColumn
