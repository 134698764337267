import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AppraisalAdminAnalyticsComponent

const AppraisalAdminAnalytics = store => {
	if (AppraisalAdminAnalyticsComponent === undefined) {
		AppraisalAdminAnalyticsComponent = Loadable({
			loader: () => {
				return import('views/AppraisalAdminAnalytics/containers/AppraisalAdminAnalyticsContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalAdminAnalyticsComponent
}

export default AppraisalAdminAnalytics
