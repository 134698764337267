import Comment from './Comment'
import Employee from './Employee'
import Idea from './Idea'
import IdeaDetail from './IdeaDetail'
import IdeaFlow from './IdeaFlow'
import IdeaFlowWithSubscription from './IdeaFlowWithSubscription'
import Ideas from './Ideas'
import IdeaSubscription from './IdeaSubscription'
import IdeaSubscriptionDetail from './IdeaSubscriptionDetail'

export default {
	Comment,
	Employee,
	Idea,
	IdeaDetail,
	IdeaFlow,
	IdeaFlowWithSubscription,
	Ideas,
	IdeaSubscription,
	IdeaSubscriptionDetail,
}
