import PropTypes from 'prop-types'

export const any = PropTypes.any
export const arrayOf = PropTypes.arrayOf
export const bool = PropTypes.bool
export const func = PropTypes.func
export const node = PropTypes.node
export const number = PropTypes.number
export const object = PropTypes.object
export const oneOf = PropTypes.oneOf
export const oneOfType = PropTypes.oneOfType
export const string = PropTypes.string
export const s = PropTypes.shape
