import { s, number } from 'modules/types'
import HolidayTypeSnapshot, { HolidayTypeSnapshotType } from './HolidayTypeSnapshot'
import HolidayDateRangeSnapshot, { HolidayDateRangeSnapshotType } from './HolidayDateRangeSnapshot'

export class HolidayRuleSnapshot {
	constructor({ id, type, date_range: dateRange }) {
		this.id = id
		this.type = new HolidayTypeSnapshot(type)
		this.dateRange = new HolidayDateRangeSnapshot(dateRange)
	}
}

export const HolidayRuleSnapshotType = s({
	id: number.isRequired,
	type: HolidayTypeSnapshotType,
	dateRange: HolidayDateRangeSnapshotType,
})

export default HolidayRuleSnapshot
