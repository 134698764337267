import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from './reducers'
import selectPlugin from '@rematch/select'
import immerPlugin from '@rematch/immer'
import { init } from '@rematch/core'
import { routerMiddleware } from 'react-router-redux'
import models from './models'

export function configureStore(preloadedState = {}) {
	// const initialState = preloadedState
	const history = createHistory()
	const store = init({
		models,
		redux: {
			// initialState,
			reducers: rootReducer(),
			middlewares: [
				// skleemToastMiddleware
				thunk,
				routerMiddleware(history),
			],
		},
		plugins: [selectPlugin(), immerPlugin()],
	})
	return { store, history }
}

const storeConfiguration = configureStore()
export const history = storeConfiguration.history
export type Dispatch = typeof storeConfiguration.store.dispatch
export default storeConfiguration.store
