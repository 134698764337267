import { s, arrayOf } from 'modules/types'
import JobDescription, { JobDescriptionType } from './JobDescription'
import JobDescriptionVersionDetail, { JobDescriptionVersionDetailType } from './JobDescriptionVersionDetail'
import JobDescriptionVersion, { JobDescriptionVersionType } from './JobDescriptionVersion'

export class JobDescriptionDetail {
	constructor({ job_description: jobDescription, latest_version_detail: latestVersionDetail, versions }) {
		this.jobDescription = new JobDescription(jobDescription)
		this.latestVersionDetail = latestVersionDetail && new JobDescriptionVersionDetail(latestVersionDetail)
		this.versions = versions.map(item => new JobDescriptionVersion(item))
	}
}

export const JobDescriptionDetailType = s({
	jobDescription: JobDescriptionType,
	latestVersionDetail: JobDescriptionVersionDetailType,
	versions: arrayOf(JobDescriptionVersionType),
})

export default JobDescriptionDetail
