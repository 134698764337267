import { s, arrayOf, number, string } from 'modules/types'
import Appraisee, { AppraiseeType } from './Appraisee'
import GroupStage, { GroupStageType } from './GroupStage'
import TemplateWithQuestionCategory, { TemplateWithQuestionCategoryType } from './TemplateWithQuestionCategory'

export class Group {
	constructor({ id, name, template, appraisees, group_stages: groupStages }) {
		this.id = id
		this.name = name
		this.template = new TemplateWithQuestionCategory(template)
		this.appraisees = appraisees.map(item => new Appraisee(item))
		this.groupStages = groupStages.map(item => new GroupStage(item))
	}
}

export const GroupType = s({
	id: number.isRequired,
	name: string.isRequired,
	template: TemplateWithQuestionCategoryType,
	appraisees: arrayOf(AppraiseeType),
	groupStages: arrayOf(GroupStageType),
})

export default Group
