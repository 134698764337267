import { s, string } from 'modules/types'

export class Section {
	constructor({ title, content }) {
		this.title = title
		this.content = content
	}
}

export const SectionType = s({
	title: string.isRequired,
	content: string.isRequired,
})

export default Section
