import { s, string } from 'modules/types'

export class VisionAndMission {
	constructor({ vision, mission, read_more: readMore }) {
		this.vision = vision
		this.mission = mission
		this.readMore = readMore
	}
}

export const VisionAndMissionType = s({
	vision: string,
	mission: string,
	readMore: string,
})

export default VisionAndMission
