import { s, arrayOf, number } from 'modules/types'
import Section, { SectionType } from './Section'

export class JobDescriptionVersionDetail {
	constructor({ id, sections }) {
		this.id = id
		this.sections = sections.map(item => new Section(item))
	}
}

export const JobDescriptionVersionDetailType = s({
	id: number.isRequired,
	sections: arrayOf(SectionType),
})

export default JobDescriptionVersionDetail
