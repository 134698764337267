import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let JobInformationComponent

const JobInformation = store => {
	if (JobInformationComponent === undefined) {
		JobInformationComponent = Loadable({
			loader: () => {
				return import('views/JobInformation/containers/JobInformationContainer')
			},
			loading: RouteLoader,
		})
	}
	return JobInformationComponent
}

export default JobInformation
