import { s, bool, number, object } from 'modules/types'
import format from 'date-fns/format'

export const EmploymentHistoryTypeEnum = {
	DATA_CHANGE: 0,
	RANK_RENAMED: 1,
	GROUP_RENAMED: 2,
	TITLE_RENAMED: 3,
	DEPARTMENT_RENAMED: 4,
	DIRECT_SUPERVISOR_RENAMED: 5,
	COMPANY_JOIN_DATE_CHANGED: 6,
	POSITION_RENAMED: 7,
	LEFT_THE_COMPANY: 8,
	REJOIN_THE_COMPANY: 9,
}

export class EmploymentHistory {
	constructor({ added, type, is_initial: isInitial, old_data: oldData, new_data: newData }) {
		this.added = new Date(added * 1000)
		this.type = type
		this.oldData = oldData
		this.newData = newData
		this.isInitial = isInitial
	}

	getOldData() {
		if (this.type === EmploymentHistoryTypeEnum.DATA_CHANGE) {
			return {
				rankName: this.oldData.rank_name,
				groupName: this.oldData.group_name,
				titleName: this.oldData.title_name,
				departmentName: this.oldData.department_name,
				positionName: this.oldData.position_name,
				directSupervisorName: this.oldData.direct_supervisor_name,
			}
		} else if (this.type === EmploymentHistoryTypeEnum.RANK_RENAMED) {
			return this.oldData.rank_name
		} else if (this.type === EmploymentHistoryTypeEnum.GROUP_RENAMED) {
			return this.oldData.group_name
		} else if (this.type === EmploymentHistoryTypeEnum.TITLE_RENAMED) {
			return this.oldData.title_name
		} else if (this.type === EmploymentHistoryTypeEnum.DEPARTMENT_RENAMED) {
			return this.oldData.department_name
		} else if (this.type === EmploymentHistoryTypeEnum.POSITION_RENAMED) {
			return this.oldData.position_name
		} else if (this.type === EmploymentHistoryTypeEnum.DIRECT_SUPERVISOR_RENAMED) {
			return this.oldData.direct_supervisor_name
		} else {
			throw new Error('Not implemented EmploymentHistoryTypeEnum')
		}
	}

	departmentChanged() {
		return (
			this.oldData.department_id !== this.newData.department_id ||
			this.oldData.department_name !== this.newData.department_name
		)
	}

	positionChanged() {
		return (
			this.oldData.position_id !== this.newData.position_id || this.oldData.position_name !== this.newData.position_name
		)
	}

	titleChanged() {
		return this.oldData.title_id !== this.newData.title_id || this.oldData.title_name !== this.newData.title_name
	}

	groupChanged() {
		return this.oldData.group_id !== this.newData.group_id || this.oldData.group_name !== this.newData.group_name
	}

	directSupervisorChanged() {
		return (
			this.oldData.direct_supervisor_id !== this.newData.direct_supervisor_id ||
			this.oldData.direct_supervisor_name !== this.newData.direct_supervisor_name
		)
	}

	rankChanged() {
		return this.oldData.rank_id !== this.newData.rank_id || this.oldData.rank_name !== this.newData.rank_name
	}

	companyJoinDateChanged() {
		return this.oldData.company_join_date !== this.newData.company_join_date
	}

	getNewJoinDate() {
		return format(new Date(parseInt(this.newData.company_join_date, 10) * 1000), 'DD MMMM YYYY')
	}

	rejoinedTheCompany() {
		return this.type === EmploymentHistoryTypeEnum.REJOIN_THE_COMPANY
	}

	leftTheCompany() {
		return this.type === EmploymentHistoryTypeEnum.LEFT_THE_COMPANY
	}

	getNewData() {
		if (this.type === EmploymentHistoryType.DATA_CHANGE) {
			return {
				rankName: this.newData.rank_name,
				groupName: this.newData.group_name,
				titleName: this.newData.title_name,
				departmentName: this.newData.department_name,
				positionName: this.newData.position_name,
				directSupervisorName: this.newData.direct_supervisor_name,
			}
		} else if (this.type === EmploymentHistoryTypeEnum.RANK_RENAMED) {
			return this.newData.rank_name
		} else if (this.type === EmploymentHistoryTypeEnum.GROUP_RENAMED) {
			return this.newData.group_name
		} else if (this.type === EmploymentHistoryTypeEnum.TITLE_RENAMED) {
			return this.newData.title_name
		} else if (this.type === EmploymentHistoryTypeEnum.DEPARTMENT_RENAMED) {
			return this.newData.department_name
		} else if (this.type === EmploymentHistoryTypeEnum.POSITION_RENAMED) {
			return this.newData.position_name
		} else if (this.type === EmploymentHistoryTypeEnum.DIRECT_SUPERVISOR_RENAMED) {
			return this.newData.direct_supervisor_name
		} else {
			throw new Error('Not implemented EmploymentHistoryTypeEnum')
		}
	}
}

export const EmploymentHistoryType = s({
	added: object,
	type: number.isRequired,
	oldData: object,
	newData: object,
	isInitial: bool,
})

export default EmploymentHistory
