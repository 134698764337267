import { s, arrayOf, number, string } from 'modules/types'
import QuestionAnswerChoice, { QuestionAnswerChoiceType } from './QuestionAnswerChoice'

export const QUESTION_TYPE = {
	CHECKBOX: 0,
	COMBOBOX: 1,
	DATE: 2,
	DATE_RANGE: 3,
	FILE: 4,
	MULTIPLE_CHOICE: 5,
	NUMBER: 6,
	TEXT: 7,
	READ_ONLY: 8,
}

export class Question {
	constructor({ id, name, type, order, choices }) {
		this.id = id
		this.name = name
		this.type = type
		this.order = order
		this.choices = choices && choices.map(item => new QuestionAnswerChoice(item))
	}
}

export const QuestionType = s({
	id: number.isRequired,
	name: string.isRequired,
	type: number.isRequired,
	order: number.isRequired,
	choices: arrayOf(QuestionAnswerChoiceType),
})

export default Question
