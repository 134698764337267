import { s, number, object, string } from 'modules/types'

class Employee {
	constructor({ id, full_name: fullName, NIK, picture }) {
		this.id = id
		this.fullName = fullName
		this.NIK = NIK
		this.picture = picture
	}
}

const EmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
	picture: string,
})

export class PendingRequest {
	constructor({ id, employee, last_date: lastDate }) {
		this.id = id
		this.employee = new Employee(employee)
		this.lastDate = new Date(lastDate)
	}
}

export const PendingRequestType = s({
	id: number.isRequired,
	employee: EmployeeType.isRequired,
	lastDate: object.isRequired,
})

export default PendingRequest
