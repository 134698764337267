import { s, arrayOf, number, string } from 'modules/types'
import HolidayRuleGroupDetail, { HolidayRuleGroupDetailType } from './HolidayRuleGroupDetail'

export class HolidayGroupWithRules {
	constructor({ id, name, rules }) {
		this.id = id
		this.name = name
		this.rules = rules.map(item => new HolidayRuleGroupDetail(item))
	}
}

export const HolidayGroupWithRulesType = s({
	id: number.isRequired,
	name: string.isRequired,
	rules: arrayOf(HolidayRuleGroupDetailType),
})

export default HolidayGroupWithRules
