import { s, number, object } from 'modules/types'

export class ExperienceSubscription {
	constructor({ id, approver_id: approverId, approver_num: approverNum, approved_hours: approvedHours, added }) {
		this.id = id
		this.approverId = approverId
		this.approverNum = approverNum
		this.approvedHours = approvedHours
		this.added = new Date(added * 1000)
	}
}

export const ExperienceSubscriptionType = s({
	id: number.isRequired,
	approverId: number.isRequired,
	approverNum: number.isRequired,
	approvedHours: number.isRequired,
	added: object.isRequired,
})

export default ExperienceSubscription
