import { s, arrayOf } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import ColumnContent, { ColumnContentType } from './ColumnContent'

export class EmployeeColumn {
	constructor({ employee, columns }) {
		this.employee = new Employee(employee)
		this.columns = columns.map(item => new ColumnContent(item))
	}
}

export const EmployeeColumnType = s({
	employee: EmployeeType,
	columns: arrayOf(ColumnContentType),
})

export default EmployeeColumn
