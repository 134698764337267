import { getObjectOrUndefined } from './index'
export const alphabetical = ({ desc = false, path = '' }) => (a, b) => {
	let aString = a
	let bString = b
	if (Boolean(path)) {
		aString = getObjectOrUndefined(a, path)
		bString = getObjectOrUndefined(b, path)
	}
	if (desc) {
		return bString.localeCompare(aString)
	} else {
		return aString.localeCompare(bString)
	}
}

export const by = (key, options = { desc: false }) => (a, b) => {
	var { desc } = options
	if (desc) {
		return b[key] - a[key]
	} else {
		return a[key] - b[key]
	}
}
