import { s, number } from 'modules/types'

export class Approver {
	constructor({ id, approver_id: approverId, group_leader_role_id: groupLeaderRoleId, rank_id: rankId, type }) {
		this.id = id
		this.approverId = approverId
		this.groupLeaderRoleId = groupLeaderRoleId
		this.rankId = rankId
		this.type = type
	}
}

export const ApproverType = s({
	id: number.isRequired,
	approverId: number,
	groupLeaderRoleId: number,
	rankId: number,
	type: number.isRequired,
})

export default Approver
