import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AppraisalCompletedComponent

const AppraisalCompleted = store => {
	if (AppraisalCompletedComponent === undefined) {
		AppraisalCompletedComponent = Loadable({
			loader: () => {
				return import('views/AppraisalCompleted/containers/AppraisalCompletedContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalCompletedComponent
}

export default AppraisalCompleted
