import { s, arrayOf, oneOfType, number, string } from 'modules/types'

class Dependency {
	constructor({
		stage_name: stageName,
		dependency_id: dependencyId,
		full_name: fullName,
		NIK,
		position,
		department_or_group: departmentOrGroup,
	}) {
		this.stageName = stageName
		this.dependencyId = dependencyId
		this.fullName = fullName
		this.NIK = NIK
		this.position = position
		this.departmentOrGroup = departmentOrGroup
	}
}

const DependencyType = s({
	stageName: string,
	dependencyId: oneOfType([number, string]), // if type is LEADERSHIP_POSITION, this is string, either "dept-<id>" or "group-<id>"
	// if type is one of SUBORDINATE, TIMEOFF_APPROVEE, EXPERIENCE_APPROVEE, IDEA_REVIEWEE
	fullName: string,
	NIK: string,
	// if type is LEADERSHIP_POSITION
	position: string, // if group, this is in the form of "Group Leader - <Role name>". if department, this is "Department Head"
	departmentOrGroup: string, // name of department or group
})

export class EmployeeDependency {
	constructor({ type, dependencies }) {
		this.type = type
		this.dependencies = dependencies.map(item => new Dependency(item))
	}
}

export const EmployeeDependencyType = s({
	type: number.isRequired, // @EMPLOYEE_DEPENDENCY_TYPE
	dependencies: arrayOf(DependencyType),
})

export default EmployeeDependency
