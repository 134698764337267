import { s, string } from 'modules/types'
import EmployeeWithPicture, { EmployeeWithPictureType } from './EmployeeWithPicture'

export class EmployeeWithAnswer {
	constructor({ employee, answer }) {
		this.employee = new EmployeeWithPicture(employee)
		this.answer = answer
	}
}

export const EmployeeWithAnswerType = s({
	employee: EmployeeWithPictureType,
	answer: string.isRequired,
})

export default EmployeeWithAnswer
