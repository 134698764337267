import { s, number } from 'modules/types'

class EmployeeMonthCount {
	constructor({ month, count }) {
		this.month = month
		this.count = count
	}
}

export const EmployeeMonthCountType = s({
	month: number.isRequired,
	count: number.isRequired,
})

export default EmployeeMonthCount
