import { s, arrayOf, number, string } from 'modules/types'
import WorkingCycleRule, { WorkingCycleRuleType } from './WorkingCycleRule'

export class WorkingCycleGroupDetail {
	constructor({ id, name, rules, employee_count: employeeCount, day_today: dayToday }) {
		this.id = id
		this.name = name
		this.rules = rules.map(item => new WorkingCycleRule(item))
		this.employeeCount = employeeCount
		this.dayToday = dayToday
	}
}

export const WorkingCycleGroupDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	rules: arrayOf(WorkingCycleRuleType),
	employeeCount: number.isRequired,
	dayToday: number.isRequired,
})

export default WorkingCycleGroupDetail
