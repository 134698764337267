import { s, arrayOf, bool, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import EmployeeCalendar, { EmployeeCalendarType } from './EmployeeCalendar'
import TimeoffComment, { TimeoffCommentType } from './TimeoffComment'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'

export class EmployeeTimeoffRequest {
	constructor({
		id,
		calendar,
		start_date: startDate,
		end_date: endDate,
		start_half_day: startHalfDay,
		end_half_day: endHalfDay,
		total_days: totalDays,
		status,
		next_approver: nextApprover,
		timeoff_type: timeoffType,
		current_balance: currentBalance,
		timeoff_comments: timeoffComments,
		added,
	}) {
		this.id = id
		this.calendar = calendar.map(item => new EmployeeCalendar(item))
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.startHalfDay = startHalfDay
		this.endHalfDay = endHalfDay
		this.totalDays = totalDays
		this.status = status
		if (nextApprover) {
			this.nextApprover = new Employee(nextApprover)
		}
		this.timeoffType = new TimeoffType(timeoffType)
		this.currentBalance = currentBalance
		this.timeoffComments = timeoffComments.map(item => new TimeoffComment(item))
		this.added = new Date(added * 1000)
	}
}

export const EmployeeTimeoffRequestType = s({
	id: number.isRequired,
	calendar: arrayOf(EmployeeCalendarType),
	startDate: object.isRequired,
	endDate: object.isRequired,
	startHalfDay: bool.isRequired,
	endHalfDay: bool.isRequired,
	totalDays: number.isRequired,
	status: number.isRequired, // timeoff TIMEOFF_REQUEST_STATUS
	nextApprover: EmployeeType,
	timeoffType: TimeoffTypeType,
	currentBalance: number.isRequired,
	timeoffComments: arrayOf(TimeoffCommentType),
	added: object.isRequired,
})

export default EmployeeTimeoffRequest
