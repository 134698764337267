import { s, arrayOf, number, string } from 'modules/types'
import QuestionLink, { QuestionLinkType } from './QuestionLink'

export class QuestionCategory {
	constructor({
		id,
		name,
		order,
		question_links: questionLinks,
		answer_permissions: answerPermissions,
		view_permissions: viewPermissions,
	}) {
		this.id = id
		this.name = name
		this.order = order
		this.questionLinks = questionLinks.map(item => new QuestionLink(item))
		this.answerPermissions = answerPermissions
		this.viewPermissions = viewPermissions
	}
}

export const QuestionCategoryType = s({
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
	questionLinks: arrayOf(QuestionLinkType),
	answerPermissions: arrayOf(number),
	viewPermissions: arrayOf(number),
})

export default QuestionCategory
