import JobDescription from './JobDescription'
import JobDescriptionDetail from './JobDescriptionDetail'
import JobDescriptionVersion from './JobDescriptionVersion'
import JobDescriptionVersionDetail from './JobDescriptionVersionDetail'
import Rank from './Rank'
import Section from './Section'
import Title from './Title'

export default {
	JobDescription,
	JobDescriptionDetail,
	JobDescriptionVersion,
	JobDescriptionVersionDetail,
	Rank,
	Section,
	Title,
}
