import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `idea`

export const CREATE_ACTION = {
	SAVE_AS_DRAFT: 0,
	SUBMIT: 1,
	POST_TO_IDEA_WALL: 2,
}

const CREATED_IDEA = `${MODULE_NAME} | CREATED IDEA`
const EDITED_IDEA = `${MODULE_NAME} | EDITED IDEA`
const DELETED_IDEA = `${MODULE_NAME} | DELETED IDEA`
const COMMENTED_IDEA = `${MODULE_NAME} | COMMENTED IDEA`
const RESENT_IDEA = `${MODULE_NAME} | RESENT IDEA`
const FORWARDED_IDEA = `${MODULE_NAME} | FORWARDED IDEA`
const ADDED_IDEA_TO_WALL = `${MODULE_NAME} | ADDED IDEA TO WALL`
const SENT_STAR = `${MODULE_NAME} | SENT STAR`
const AWARDED_LIKE = `${MODULE_NAME} | AWARDED LIKE`
const AWARDED_FLOWER = `${MODULE_NAME} | AWARDED FLOWER`

const SELECTED_IDEA = `${MODULE_NAME} | SELECTED IDEA`
const OPEN_CREATE_IDEA_FORM = `${MODULE_NAME} | OPEN CREATE IDEA FORM`

const REFRESHING_IDEAS = `${MODULE_NAME} | REFRESHING_IDEAS`
const REFRESHING_IDEA_DETAIL = `${MODULE_NAME} | REFRESHING IDEA DETAIL`
const REFRESHING_SELF_IDEA_FLOW = `${MODULE_NAME} | REFRESHING SELF IDEA FLOW`

const REFRESHED_IDEAS = `${MODULE_NAME} | REFRESHED_IDEAS`
const REFRESHED_IDEA_DETAIL = `${MODULE_NAME} | REFRESHED IDEA DETAIL`
const REFRESHED_SELF_IDEA_FLOW = `${MODULE_NAME} | REFRESHED SELF IDEA FLOW`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshIdeas() {
	return dispatch => {
		let apiUrl = `/api/workstation/idea/ideas/`
		dispatch({ type: REFRESHING_IDEAS, apiUrl })
		return api.workstation.idea.ideas.get().then(({ data }) => {
			dispatch({ type: REFRESHED_IDEAS, data, apiUrl })
		})
	}
}

function refreshIdeaDetail(ideaSubscriptionId) {
	return dispatch => {
		let apiUrl = `/api/workstation/idea/ideas/${ideaSubscriptionId}/`
		dispatch({ type: REFRESHING_IDEA_DETAIL })
		return api.workstation.idea.ideas.getDetail(ideaSubscriptionId).then(({ data }) => {
			dispatch({ type: REFRESHED_IDEA_DETAIL, data, apiUrl })
			dispatch({ type: SELECTED_IDEA, selectedIdea: data })
			return data
		})
	}
}

function refreshSelfIdeaFlow() {
	return dispatch => {
		let apiUrl = `/api/workstation/idea/self_idea_flow/`
		dispatch({ type: REFRESHED_SELF_IDEA_FLOW, apiUrl })
		return api.workstation.idea.ideas.getSelfIdeaFlow().then(({ data }) => {
			dispatch({ type: REFRESHED_SELF_IDEA_FLOW, data, apiUrl })
		})
	}
}

export function createIdea(data) {
	return (dispatch, getState) => {
		const {
			idea: {
				data: { selectedIdea },
			},
		} = getState()
		return api.workstation.idea.ideas.create(data).then(() => {
			dispatch(refreshIdeas())
			dispatch({ type: CREATED_IDEA })
			toast(selectedIdea ? `Successfully submitted idea` : `Successfully created an idea`)
		})
	}
}

export function editIdea(ideaSubscriptionId, title, description, attachments, submit) {
	return dispatch => {
		return api.workstation.idea.ideas
			.edit(ideaSubscriptionId, title, description, attachments, submit)
			.then(({ data }) => {
				dispatch({ type: EDITED_IDEA })
				dispatch(refreshIdeas())
				dispatch(refreshIdeaDetail(ideaSubscriptionId))
				toast(`Successfully edited idea`)
				return data
			})
	}
}

export function deleteIdea(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.delete(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(openCreateIdeaForm())
			dispatch({ type: DELETED_IDEA })
			toast(`Successfully deleted idea`)
		})
	}
}

export function commentIdea(ideaSubscriptionId, message) {
	return dispatch => {
		return api.workstation.idea.ideas.comment(ideaSubscriptionId, message).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: COMMENTED_IDEA })
		})
	}
}

export function resendIdea(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.resend(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: RESENT_IDEA })
			toast(`Successfully resent idea`)
		})
	}
}

export function forwardIdea(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.forward(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: FORWARDED_IDEA })
			toast(`Successfully forwarded idea`)
		})
	}
}

export function addToIdeaWall(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.addToIdeaWall(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: ADDED_IDEA_TO_WALL })
			toast(`Successfully posted idea to idea wall`)
		})
	}
}

export function sendStar(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.sendStar(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: SENT_STAR })
			toast(`Successfully awarded star`)
		})
	}
}

export function awardLike(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.awardLike(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: AWARDED_LIKE })
			toast(`Successfully awarded like`)
		})
	}
}

export function awardFlower(ideaSubscriptionId) {
	return dispatch => {
		return api.workstation.idea.ideas.awardFlower(ideaSubscriptionId).then(() => {
			dispatch(refreshIdeas())
			dispatch(refreshIdeaDetail(ideaSubscriptionId))
			dispatch({ type: AWARDED_FLOWER })
			toast(`Successfully awarded flower`)
		})
	}
}

export function selectIdea(ideaSubscriptionId) {
	return (dispatch, getState) => {
		let apiUrl = `/api/workstation/idea/ideas/${ideaSubscriptionId}/`
		const {
			idea: { api },
		} = getState()
		if (api[apiUrl] === undefined) {
			dispatch(refreshIdeaDetail(ideaSubscriptionId)).then(ideaDetail =>
				dispatch({ type: SELECTED_IDEA, selectedIdea: ideaDetail })
			)
		} else {
			dispatch({ type: SELECTED_IDEA, selectedIdea: api[apiUrl] })
		}
	}
}

export function openCreateIdeaForm() {
	return dispatch => {
		dispatch({ type: OPEN_CREATE_IDEA_FORM })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshSelfIdeaFlow())
		dispatch(refreshIdeas())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {
		detail: false,
	},
	data: {
		isCreateIdeaFormOpen: true,
		isSavedListExpanded: true,
		isSubmittedListExpanded: true,
		isSubordinateListExpanded: true,
		isSecondSubordinateListExpanded: true,
		expandedIdeaFlowIds: [],
		selectedIdea: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_SELF_IDEA_FLOW:
		case REFRESHING_IDEAS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_SELF_IDEA_FLOW:
		case REFRESHED_IDEAS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHING_IDEA_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					detail: true,
				},
			}
		case REFRESHED_IDEA_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					detail: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case SELECTED_IDEA:
			return {
				...state,
				data: {
					...state.data,
					isCreateIdeaFormOpen: false,
					selectedIdea: action.selectedIdea,
				},
			}
		case OPEN_CREATE_IDEA_FORM:
			return {
				...state,
				data: {
					...state.data,
					isCreateIdeaFormOpen: true,
					selectedIdea: undefined,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
