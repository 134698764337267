import CreateTimeoffGroupRule from './CreateTimeoffGroupRule'
import EditTimeoffApprover from './EditTimeoffApprover'
import Employee from './Employee'
import RankSimple from './RankSimple'
import EmployeeTimeoffSetting from './EmployeeTimeoffSetting'
import TimeoffApprovee from './TimeoffApprovee'
import TimeoffApprover from './TimeoffApprover'
import TimeoffGroup from './TimeoffGroup'
import TimeoffGroupDetail from './TimeoffGroupDetail'
import TimeoffGroupEmployee from './TimeoffGroupEmployee'
import TimeoffGroupWithRules from './TimeoffGroupWithRules'
import TimeoffGroupLog from './TimeoffGroupLog'
import TimeoffGroupLogDetail from './TimeoffGroupLogDetail'
import TimeoffType from './TimeoffType'
import TimeoffSummary from './TimeoffSummary'

export const APPROVER_TYPE = {
	GROUP_LEADER: 1,
	DEPARTMENT_HEAD: 2,
	CUSTOM: 3,
	RANK_SUPERVISOR: 4,
	DIRECT_SUPERVISOR: 5,
}

export const TIMEOFF_RULE_PERIOD = {
	DAILY: 1,
	WEEKLY: 2,
	MONTHLY: 3,
	ANNUALLY: 4,
}

export default {
	CreateTimeoffGroupRule,
	EditTimeoffApprover,
	Employee,
	RankSimple,
	EmployeeTimeoffSetting,
	TimeoffApprovee,
	TimeoffApprover,
	TimeoffGroup,
	TimeoffGroupDetail,
	TimeoffGroupEmployee,
	TimeoffGroupWithRules,
	TimeoffGroupLog,
	TimeoffGroupLogDetail,
	TimeoffType,
	TimeoffSummary,
}
