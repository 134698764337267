import React from 'react'
import PropTypes from 'prop-types'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Hidden from '@material-ui/core/Hidden'
import Collapse from '@material-ui/core/Collapse'

// core components
import HeaderLinks from 'components/HeaderLinks'
import Anchor from 'components/Anchor'

// modules
import skleemi18n from 'utils/skleemi18n'
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx'

const styles = theme => ({
	...sidebarStyle(theme),
	selectedLink: {
		cursor: 'auto !important',
	},
	img: {
		width: '100%',
		height: '55px',
		objectFit: 'contain',
		verticalAlign: 'middle',
		border: '0',
	},
	itemTextGrowing: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		height: '30px'
	}
})

var ps

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false,
			})
		}
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy()
		}
	}

	render() {
		const { className, user, headerLinks, links } = this.props
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		)
	}
}

const i18nDict = {
	DASHBOARD: {
		id: 'Dasbor',
		en: 'Dashboard',
	},
	COMPANY: {
		id: 'Perusahaanku',
		en: 'My Company',
	},
	WORKSTATION: {
		id: 'Pekerjaan',
		en: 'Workstation',
	},
	SURVEY: {
		id: 'Survei',
		en: 'Survey',
	},
	APPRAISAL: {
		id: 'Penilaian Kinerja',
		en: 'Appraisal',
	},
	HUMAN_RESOURCES: {
		id: 'HRD',
		en: 'Human Resources',
	},
	SURVEY_ADMIN: {
		id: 'Admin Survei',
		en: 'Survey Admin',
	},
	APPRAISAL_ADMIN: {
		id: 'Admin PA',
		en: 'PA Admin',
	},
	SETTINGS: {
		id: 'Pengaturan',
		en: 'Settings',
	},
	POLICIES: {
		id: 'Peraturan',
		en: 'Policies',
	},
	REQUEST: {
		id: 'Permohonan',
		en: 'Request',
	},
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openAvatar: false,
			openComponents: this.activeRoute('/components'),
			openForms: this.activeRoute('/forms'),
			openTables: this.activeRoute('/tables'),
			openMaps: this.activeRoute('/maps'),
			openPages: this.activeRoute('-page'),
			miniActive: true,
		}
		this.activeRoute.bind(this)
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) === 0 ? true : false
	}

	openCollapse(collapse) {
		var st = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	render() {
		const {
			classes,
			color,
			logo,
			image,
			logoText,
			routes,
			bgColor,
			rtlActive,
			currentUser,
			currentUserPermissions,
			handleLogout,
			frontendSetting,
			handleChangeSetting,
			switchUser,
			notifications,
		} = this.props
		const itemText =
			classes.itemText +
			' ' +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive,
			})
		const userWrapperClass = ''
		const caret =
			classes.caret +
			' ' +
			cx({
				[classes.caretRTL]: rtlActive,
			})
		// const collapseItemMini =
		// 	classes.collapseItemMini +
		// 	" " +
		// 	cx({
		// 		[classes.collapseItemMiniRTL]: rtlActive
		// 	})
		const collapseItemText =
			classes.collapseItemText +
			' ' +
			cx({
				[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive,
			})
		const selectedAccount =
			currentUser.secondaryAccounts && currentUser.secondaryAccounts.find(account => account.id === currentUser.id)
		var user = (
			<div className={userWrapperClass}>
				<List className={classes.list}>
					<ListItem className={classes.item + ' ' + classes.userItem}>
						{!(this.props.miniActive && this.state.miniActive) ? (
							<Anchor
								className={classes.itemLink + ' ' + classes.userCollapseButton}
								onClick={() => this.openCollapse('openAvatar')}
							>
								<ListItemText
									primary={selectedAccount ? selectedAccount.company.name : null}
									secondary={
										<b
											className={
												caret + ' ' + classes.userCaret + ' ' + (this.state.openAvatar ? classes.caretActive : '')
											}
										/>
									}
									disableTypography={true}
									className={itemText + ' ' + classes.userItemText}
								/>
							</Anchor>
						) : <div style={{height: '34px'}} />}
					</ListItem>
				</List>
				<Collapse in={this.state.openAvatar} unmountOnExit>
					<List className={classes.list + ' ' + classes.collapseList}>
						{currentUser.secondaryAccounts &&
							currentUser.secondaryAccounts.map(account => (
								<ListItem
									key={account.id}
									className={cx({
										[classes.item]: true,
										[classes.collapseItem]: true,
									})}
									onClick={() => {
										if (selectedAccount && selectedAccount.id === account.id) {
											return null
										}
										switchUser({ accountId: account.id, companyId: account.company.id })
									}}
								>
									<Anchor
										className={cx({
											[classes.itemLink]: true,
											[classes.selectedLink]: selectedAccount && selectedAccount.id === account.id,
											// [classes.userCollapseLinks]: true,
											[classes[color]]: selectedAccount && selectedAccount.id === account.id,
										})}
									>
										<ListItemText
											primary={account.company.name}
											disableTypography={true}
											className={this.props.miniActive && this.state.miniActive ? 'd-none' : collapseItemText}
										/>
									</Anchor>
								</ListItem>
							))}
					</List>
				</Collapse>
			</div>
		)
		var links = (
			<List className={classes.list}>
				{routes
					.filter((prop, key) => {
						if (prop.permissionsRequired) {
							return prop.permissionsRequired.reduce((prev, cur) => {
								return prev && currentUserPermissions.indexOf(cur) > -1
							}, true)
						} else {
							return true
						}
					})
					.map((prop, key) => {
						if (prop.redirect) {
							return null
						}
						if (prop.headerTab) {
							const navLinkClasses =
								classes.itemLink +
								' ' +
								cx({
									[' ' + classes[color]]: this.activeRoute(prop.path),
								})
							const itemText =
								classes.itemText +
								' ' +
								classes.itemTextGrowing +
								' ' +
								cx({
									[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
									[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
									[classes.itemTextRTL]: rtlActive,
								})
							const itemIcon =
								classes.itemIcon +
								' ' +
								cx({
									[classes.itemIconRTL]: rtlActive,
									[classes.itemIconHidden]: this.props.miniActive && this.state.miniActive,
								})
							return (
								<ListItem
									key={key}
									className={classes.item}
									onMouseOver={() =>
										prop.views[0].component && prop.views[0].component.preload
											? prop.views[0].component.preload()
											: undefined
									}
								>
									<NavLink to={prop.path} className={navLinkClasses}>
										<ListItemIcon className={itemIcon}>
											<prop.icon />
										</ListItemIcon>
										<ListItemText
											primary={this.props.translater[prop.name]}
											disableTypography={true}
											className={itemText}
										/>
									</NavLink>
								</ListItem>
							)
						}
						if (prop.collapse) {
							const navLinkClasses =
								classes.itemLink +
								' ' +
								cx({
									[' ' + classes.collapseActive]: this.activeRoute(prop.path),
								})
							const itemText =
								classes.itemText +
								' ' +
								cx({
									[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
									[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
									[classes.itemTextRTL]: rtlActive,
								})
							const collapseItemText =
								classes.collapseItemText +
								' ' +
								cx({
									[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
									[classes.collapseItemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
									[classes.collapseItemTextRTL]: rtlActive,
								})
							const itemIcon =
								classes.itemIcon +
								' ' +
								cx({
									[classes.itemIconRTL]: rtlActive,
									[classes.itemIconHidden]: this.props.miniActive && this.state.miniActive,
								})
							const caret =
								classes.caret +
								' ' +
								cx({
									[classes.caretRTL]: rtlActive,
								})
							return (
								<ListItem key={key} className={classes.item}>
									<div className={navLinkClasses} onClick={() => this.openCollapse(prop.state)}>
										<ListItemIcon className={itemIcon}>
											<prop.icon />
										</ListItemIcon>
										<ListItemText
											primary={prop.name}
											secondary={<b className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')} />}
											disableTypography={true}
											className={itemText}
										/>
									</div>
									<Collapse in={this.state[prop.state]} unmountOnExit>
										<List className={classes.list + ' ' + classes.collapseList}>
											{prop.views.map((prop, key) => {
												if (prop.redirect) {
													return null
												}
												const navLinkClasses =
													classes.collapseItemLink +
													' ' +
													cx({
														[' ' + classes[color]]: this.activeRoute(prop.path),
													})
												const collapseItemMini =
													classes.collapseItemMini +
													' ' +
													cx({
														[classes.collapseItemMiniRTL]: rtlActive,
													})
												return (
													<ListItem key={key} className={classes.collapseItem}>
														<NavLink to={prop.path} className={navLinkClasses}>
															<span className={collapseItemMini}>{prop.icon ? <prop.icon /> : prop.mini}</span>
															<ListItemText primary={prop.name} disableTypography={true} className={collapseItemText} />
														</NavLink>
													</ListItem>
												)
											})}
										</List>
									</Collapse>
								</ListItem>
							)
						}
						const navLinkClasses =
							classes.itemLink +
							' ' +
							cx({
								[' ' + classes[color]]: this.activeRoute(prop.path),
							})
						const itemText =
							classes.itemText +
							' ' +
							classes.itemTextGrowing +
							' ' +
							cx({
								[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
								[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
								[classes.itemTextRTL]: rtlActive,
							})
						const itemIcon =
							classes.itemIcon +
							' ' +
							cx({
								[classes.itemIconRTL]: rtlActive,
								[classes.itemIconHidden]: this.props.miniActive && this.state.miniActive,
							})
						return (
							<ListItem
								key={key}
								className={classes.item}
								onMouseOver={() => (prop.component && prop.component.preload ? prop.component.preload() : undefined)}
							>
								<NavLink to={prop.path} className={navLinkClasses}>
									<ListItemIcon className={itemIcon}>
										<prop.icon />
									</ListItemIcon>
									<ListItemText
										primary={this.props.translater[prop.name]}
										disableTypography={true}
										className={itemText}
									/>
								</NavLink>
							</ListItem>
						)
					})}
			</List>
		)

		const logoMini =
			classes.logoMini +
			' ' +
			cx({
				[classes.logoMiniRTL]: rtlActive,
			})
		const logoClasses =
			classes.logo +
			' ' +
			cx({
				[classes.whiteAfter]: bgColor === 'white',
			})
		var brand = (
			<div className={logoClasses}>
				<a href={currentUser.company_url} className={logoMini}>
					<img src={logo} alt={logoText} className={classes.img} />
				</a>
			</div>
		)
		const drawerPaper =
			classes.drawerPaper +
			' ' +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive,
			})
		const sidebarWrapper =
			classes.sidebarWrapper +
			' ' +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
			})
		return (
			<div ref="mainPanel">
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={rtlActive ? 'left' : 'right'}
						open={this.props.open}
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={
								<HeaderLinks
									rtlActive={rtlActive}
									handleLogout={handleLogout}
									currentUser={currentUser}
									frontendSetting={frontendSetting}
									notifications={notifications}
									handleChangeSetting={handleChangeSetting}
								/>
							}
							links={links}
						/>
						{image !== undefined ? (
							<div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown>
					<Drawer
						onMouseOver={() => this.setState({miniActive: false})}
						onMouseOut={() => this.setState({miniActive: true})}
						anchor={rtlActive ? 'right' : 'left'}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
						}}
					>
						{brand}
						<SidebarWrapper className={sidebarWrapper} user={user} links={links} />
						{image !== undefined ? (
							<div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
						) : null}
					</Drawer>
				</Hidden>
			</div>
		)
	}
}

Sidebar.defaultProps = {
	bgColor: 'blue',
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
}

export default withStyles(styles)(skleemi18n(Sidebar, i18nDict))
