import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let SurveyCompletedComponent

const SurveyCompleted = store => {
	if (SurveyCompletedComponent === undefined) {
		SurveyCompletedComponent = Loadable({
			loader: () => {
				return import('views/SurveyCompleted/containers/SurveyCompletedContainer')
			},
			loading: RouteLoader,
		})
	}
	return SurveyCompletedComponent
}

export default SurveyCompleted
