import { s, arrayOf, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import TimeoffComment, { TimeoffCommentType } from './TimeoffComment'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'
import TimeoffRequestLog, { TimeoffRequestLogType } from './TimeoffRequestLog'
import { TIMEOFF_REQUEST_STATUS } from './index'

export class EmployeeTimeoffRequestHistory {
	constructor({
		id,
		start_date: startDate,
		end_date: endDate,
		total_days: totalDays,
		status,
		next_approver: nextApprover,
		timeoff_type: timeoffType,
		current_balance: currentBalance,
		timeoff_comments: timeoffComments,
		timeoff_request_logs: timeoffRequestLogs,
		added,
	}) {
		this.id = id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.totalDays = totalDays
		this.status = status
		if (nextApprover) {
			this.nextApprover = new Employee(nextApprover)
		}
		this.timeoffType = new TimeoffType(timeoffType)
		this.currentBalance = currentBalance
		this.timeoffComments = timeoffComments.map(item => new TimeoffComment(item))
		this.added = new Date(added * 1000)
		this.timeoffRequestLogs = timeoffRequestLogs.map(item => new TimeoffRequestLog(item))
	}

	displayStatus() {
		switch (this.status) {
			case TIMEOFF_REQUEST_STATUS.PENDING:
				return 'PENDING'
			case TIMEOFF_REQUEST_STATUS.REJECTED:
				return 'REJECTED'
			case TIMEOFF_REQUEST_STATUS.APPROVED:
				return 'APPROVED'
			case TIMEOFF_REQUEST_STATUS.CANCELLED:
				return 'CANCELLED'
			default:
				return 'Unknown status'
		}
	}
}

export const EmployeeTimeoffRequestHistoryType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
	totalDays: number.isRequired,
	status: number.isRequired, // timeoff TIMEOFF_REQUEST_STATUS
	nextApprover: EmployeeType,
	timeoffType: TimeoffTypeType,
	currentBalance: number.isRequired,
	timeoffComments: arrayOf(TimeoffCommentType),
	added: object.isRequired,
	timeoffRequestLogs: arrayOf(TimeoffRequestLogType),
})

export default EmployeeTimeoffRequestHistory
