import { s, number, string } from 'modules/types'

export class GroupingWithCount {
	constructor({
		employee_detail_answer_id: employeeDetailAnswerId,
		employee_detail_answer_name: employeeDetailAnswerName,
		employee_count: employeeCount,
	}) {
		this.employeeDetailAnswerId = employeeDetailAnswerId
		this.employeeDetailAnswerName = employeeDetailAnswerName
		this.employeeCount = employeeCount
	}
}

export const GroupingWithCountType = s({
	employeeDetailAnswerId: number.isRequired,
	employeeDetailAnswerName: string.isRequired,
	employeeCount: number.isRequired,
})

export default GroupingWithCount
