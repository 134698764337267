import { s, arrayOf, number, string } from 'modules/types'

class AppraisalGroup {
	constructor({ appraisal_group_id: appraisalGroupId, name }) {
		this.appraisalGroupId = appraisalGroupId
		this.name = name
	}
}

const AppraisalGroupType = s({
	appraisalGroupId: number.isRequired,
	name: string.isRequired,
})

class DefaultCategory {
	constructor({ default_question_category_id: defaultQuestionCategoryId, name }) {
		this.defaultQuestionCategoryId = defaultQuestionCategoryId
		this.name = name
	}
}

const DefaultCategoryType = s({
	defaultQuestionCategoryId: number.isRequired,
	name: string.isRequired,
})

class DefaultTemplate {
	constructor({ default_template_id: defaultTemplateId, name, categories }) {
		this.defaultTemplateId = defaultTemplateId
		this.name = name
		this.categories = categories.map(item => new DefaultCategory(item))
	}
}

const DefaultTemplateType = s({
	defaultTemplateId: number.isRequired,
	name: string.isRequired,
	categories: arrayOf(DefaultCategoryType).isRequired,
})

export class DefaultTemplateWithGroups {
	constructor({ default_template: defaultTemplate, participant_count: participantCount, groups }) {
		this.defaultTemplate = new DefaultTemplate(defaultTemplate)
		this.participantCount = participantCount
		this.groups = groups.map(item => new AppraisalGroup(item))
	}
}

export const DefaultTemplateWithGroupsType = s({
	defaultTemplate: DefaultTemplateType,
	participantCount: number.isRequired,
	groups: arrayOf(AppraisalGroupType),
})

export default DefaultTemplateWithGroups
