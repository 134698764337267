import { s, arrayOf, number, string } from 'modules/types'
import DefaultQuestionCategory, { DefaultQuestionCategoryType } from './DefaultQuestionCategory'
import TemplateUsed, { TemplateUsedType } from './TemplateUsed'

export class DefaultTemplate {
	constructor({ id, name, description, categories, templates }) {
		this.id = id
		this.name = name
		this.description = description
		this.categories = categories.map(item => new DefaultQuestionCategory(item))
		this.templates = templates.map(item => new TemplateUsed(item))
	}
}

export const DefaultTemplateType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	categories: arrayOf(DefaultQuestionCategoryType),
	templates: arrayOf(TemplateUsedType),
})

export default DefaultTemplate
