import { s, arrayOf, number } from 'modules/types'

class QuestionLinkWeight {
	constructor({ defaultQuestionLinkId: default_question_link_id, weight }) {
		this.default_question_link_id = default_question_link_id
		this.weight = weight
	}
}

const QuestionLinkWeightType = {
	default_question_link_id: number.isRequired,
	weight: number,
}

class QuestionCategoryPermissions {
	constructor({
		defaultQuestionCategoryId: default_question_category_id,
		viewPermissions: view_permissions,
		answerPermissions: answer_permissions,
		questionLinks: question_links,
	}) {
		this.default_question_category_id = default_question_category_id
		this.view_permissions = view_permissions
		this.answer_permissions = answer_permissions
		this.question_links = question_links.map(item => new QuestionLinkWeight(item))
	}
}

const QuestionCategoryPermissionsType = {
	default_question_category_id: number.isRequired,
	view_permissions: arrayOf(number),
	answer_permissions: arrayOf(number),
	question_links: arrayOf(QuestionLinkWeightType),
}

export class AddGroupCustomTemplate {
	constructor({ defaultTemplateId: default_template_id, questionCategoryPermissions: question_category_permissions }) {
		this.default_template_id = default_template_id
		this.question_category_permissions = question_category_permissions.map(
			item => new QuestionCategoryPermissions(item)
		)
	}
}

export const AddGroupCustomTemplateType = s({
	default_template_id: number.isRequired,
	question_category_permissions: arrayOf(QuestionCategoryPermissionsType),
})

export default AddGroupCustomTemplate
