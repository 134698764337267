import { s, number, string } from 'modules/types'

export class Creator {
	constructor({ id, name, picture_url: pictureUrl, title_name: titleName }) {
		this.id = id
		this.name = name
		this.pictureUrl = pictureUrl
		this.titleName = titleName
	}
}

export const CreatorType = s({
	id: number.isRequired,
	name: string.isRequired,
	pictureUrl: string,
	titleName: string.isRequired,
})

export default Creator
