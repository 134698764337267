import { s, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import ExperienceRequestLogExtInfo, { ExperienceRequestLogExtInfoType } from './ExperienceRequestLogExtInfo'

export class ExperienceRequestLog {
	constructor({ id, operator, type, ext_info: extInfo, added }) {
		this.id = id
		this.operator = new Employee(operator)
		this.type = type // @EXPERIENCE_REQUEST_LOG_TYPE
		this.extInfo = new ExperienceRequestLogExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}
}

export const ExperienceRequestLogType = s({
	id: number.isRequired,
	operator: EmployeeType,
	type: number.isRequired,
	extInfo: ExperienceRequestLogExtInfoType,
	added: object.isRequired,
})

export default ExperienceRequestLog
