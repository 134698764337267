import { s, number, object, string } from 'modules/types'
import EmployeeDetailQuestionAnswerLogExtInfo, {
	EmployeeDetailQuestionAnswerLogExtInfoType,
} from './EmployeeDetailQuestionAnswerLogExtInfo'
import EmployeeDetailLogOperator from './EmployeeDetailLogOperator'
import Question, { QuestionType } from './Question'

class EmployeeDetailQuestionAnswerLog {
	constructor({
		id,
		question,
		operator,
		type,
		log_type: logType,
		ext_info: extInfo,
		added,
		field_group_name: fieldGroupName = null,
		field_name: fieldName = null,
	}) {
		this.id = id
		this.question = new Question(question)
		this.operator = new EmployeeDetailLogOperator(operator)
		this.type = type
		this.logType = logType
		this.extInfo = new EmployeeDetailQuestionAnswerLogExtInfo(extInfo)
		this.added = added
		this.fieldGroupName = fieldGroupName
		this.fieldName = fieldName
	}
}

export const EmployeeDetailQuestionAnswerLogType = s({
	id: number.isRequired,
	question: QuestionType,
	type: number.isRequired,
	logType: number.isRequired,
	extInfo: EmployeeDetailQuestionAnswerLogExtInfoType,
	added: object.isRequired,
	fieldGroupName: string,
	fieldName: string,
})

export default EmployeeDetailQuestionAnswerLog
