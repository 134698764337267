import { s, arrayOf, number, string } from 'modules/types'

export class ColumnContent {
	constructor({ column_id: columnId, column_order: columnOrder, content = null, contents = null }) {
		this.columnId = columnId
		this.columnOrder = columnOrder
		if (content !== null) {
			this.content = content
		}
		if (contents !== null) {
			this.contents = contents
		}
	}
}

export const ColumnContentType = s({
	columnId: number.isRequired,
	columnOrder: number.isRequired,
	content: string,
	contents: arrayOf(string),
})

export default ColumnContent
