import { s, string, number, object } from 'modules/types'

export class SummaryDetail {
	constructor({
		title,
		description,
		start_date: startDate,
		end_date: endDate,
		number_of_question: numberOfQuestion,
		number_of_audience: numberOfAudience,
		number_of_responded_audience: numberOfRespondedAudience,
	}) {
		this.title = title
		this.description = description
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.numberOfQuestion = numberOfQuestion
		this.numberOfAudience = numberOfAudience
		this.numberOfRespondedAudience = numberOfRespondedAudience
	}
}

export const SummaryDetailType = s({
	title: string.isRequired,
	description: string,
	startDate: object.isRequired,
	endDate: object.isRequired,
	numberOfQuestion: number.isRequired,
	numberOfAudience: number.isRequired,
	numberOfRespondedAudience: number.isRequired,
})

export default SummaryDetail
