import { s, arrayOf, bool, number, object, string } from 'modules/types'
import AttachmentUrl, { AttachmentUrlType } from './AttachmentUrl'

class Contract {
	constructor({
		id,
		no_pkwt: noPKWT,
		end_date: endDate,
		notes,
		status,
		is_ongoing: isOngoing,
		attachments,
		attachment_urls: attachmentUrls,
	}) {
		this.id = id
		this.noPKWT = noPKWT
		this.endDate = new Date(endDate)
		this.notes = notes
		this.status = status
		this.isOngoing = isOngoing
		this.attachments = attachments
		this.attachmentUrls = attachmentUrls.map(item => new AttachmentUrl(item))
	}
}

export const ContractType = s({
	id: number.isRequired,
	noPKWT: string.isRequired,
	endDate: object,
	notes: string,
	status: number.isRequired, // @CONTRACT_STATUS
	isOngoing: bool.isRequired,
	attachments: arrayOf(string),
	attachmentUrls: arrayOf(AttachmentUrlType),
})

export default Contract
