import { s, number, string } from 'modules/types'
import WorkingCycleGroupLogExtInfo, { WorkingCycleGroupLogExtInfoType } from './WorkingCycleGroupLogExtInfo'
import WorkingCycleGroupLogExtInfoDetail, {
	WorkingCycleGroupLogExtInfoDetailType,
} from './WorkingCycleGroupLogExtInfoDetail'

export class WorkingCycleGroupLogDetail {
	constructor({ id, operator_name: operatorName, type, ext_info: extInfo, ext_info_detail: extInfoDetail }) {
		this.id = id
		this.operatorName = operatorName
		this.type = type
		this.extInfo = new WorkingCycleGroupLogExtInfo(extInfo)
		if (extInfoDetail) {
			this.extInfoDetail = new WorkingCycleGroupLogExtInfoDetail(extInfoDetail)
		}
	}
}

export const WorkingCycleGroupLogDetailType = s({
	id: number.isRequired,
	operatorName: string.isRequired,
	type: number.isRequired,
	extInfo: WorkingCycleGroupLogExtInfoType,
	extInfoDetail: WorkingCycleGroupLogExtInfoDetailType,
})

export default WorkingCycleGroupLogDetail
