import { s, arrayOf } from 'modules/types'
import EmployeeCalendarEmployee, { EmployeeCalendarEmployeeType } from './EmployeeCalendarEmployee'
import Calendar, { CalendarType } from './Calendar'

export class EmployeeCalendar {
	constructor({ employee, calendar }) {
		this.employee = new EmployeeCalendarEmployee(employee)
		this.calendar = calendar.map(item => new Calendar(item))
	}
}

export const EmployeeCalendarType = s({
	employee: EmployeeCalendarEmployeeType,
	calendar: arrayOf(CalendarType),
})

export default EmployeeCalendar
