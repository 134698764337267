import { s, arrayOf, number, object } from 'modules/types'
import Approver, { ApproverType } from './Approver'
import EmployeeWithGroup, { EmployeeWithGroupType } from './EmployeeWithGroup'
import ExperienceType, { ExperienceTypeType } from './ExperienceType'

export class ExperienceRequest {
	constructor({ id, employee, type, submitted_hours: submittedHours, approvers, added }) {
		this.id = id
		this.employee = new EmployeeWithGroup(employee)
		this.type = new ExperienceType(type)
		this.submittedHours = submittedHours
		this.approvers = approvers.map(item => new Approver(item))
		this.added = new Date(added * 1000)
	}
}

export const ExperienceRequestType = s({
	id: number.isRequired,
	employee: EmployeeWithGroupType,
	type: ExperienceTypeType,
	submittedHours: number.isRequired,
	approvers: arrayOf(ApproverType),
	added: object.isRequired,
})

export default ExperienceRequest
