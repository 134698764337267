import { s, oneOfType, bool, number, object, string } from 'modules/types'
import { TIMEOFF_RULE_PERIOD } from './index'

class EmployeeSnapshot {
	constructor({ id, full_name: fullName }) {
		this.id = id
		this.fullName = fullName
	}
}

const EmployeeSnapshotType = s({
	id: number.isRequired,
	fullName: string.isRequired,
})

class TimeoffGroupSnapshot {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const TimeoffGroupSnapshotType = s({
	id: number.isRequired,
	name: string.isRequired,
})

class TimeoffTypeSnapshot {
	constructor({ id, name, color }) {
		this.id = id
		this.name = name
		this.color = color
	}
}

const TimeoffTypeSnapshotType = s({
	id: number.isRequired,
	name: string.isRequired,
	color: number.isRequired,
})

class TimeoffWalletSnapshot {
	constructor({ id, employee_id: employeeId, balance, type }) {
		this.id = id
		this.employeeId = employeeId
		this.balance = balance
		this.type = new TimeoffTypeSnapshot(type)
	}
}

const TimeoffWalletSnapshotType = s({
	id: number.isRequired,
	employeeId: number.isRequired,
	balance: number.isRequired,
	type: TimeoffTypeSnapshotType,
})

class TimeoffRequestSnapshot {
	constructor({
		id,
		employee,
		status,
		balance_taken: balanceTaken,
		wallet,
		next_approver: nextApprover,
		start_date: startDate,
		end_date: endDate,
		created_by: createdBy,
	}) {
		this.id = id
		if (employee) {
			this.employee = new EmployeeSnapshot(employee)
		}
		this.status = status
		if (nextApprover) {
			this.nextApprover = new EmployeeSnapshot(nextApprover)
		}
		if (startDate) {
			this.startDate = new Date(startDate)
		}
		if (endDate) {
			this.endDate = new Date(endDate)
		}
		this.balanceTaken = balanceTaken
		this.createdBy = new EmployeeSnapshot(createdBy)
	}
}

const TimeoffRequestSnapshotType = s({
	id: number.isRequired,
	employee: EmployeeSnapshotType,
	status: number.isRequired,
	nextApprover: EmployeeSnapshotType,
	startDate: object,
	endDate: object,
	balanceTaken: number,
	createdBy: EmployeeSnapshotType,
})

class TimeoffRuleSnapshot {
	constructor({
		id,
		group,
		type,
		allowance,
		period,
		max_accumulation: maxAccumulation,
		allow_over_limit: allowOverLimit,
	}) {
		this.id = id
		this.group = new TimeoffGroupSnapshot(group)
		this.type = new TimeoffTypeSnapshot(type)
		this.allowance = allowance
		this.period = period // TIMEOFF_RULE_PERIOD
		this.maxAccumulation = maxAccumulation
		this.allowOverLimit = allowOverLimit
	}

	displayPeriod() {
		switch (this.period) {
			case TIMEOFF_RULE_PERIOD.ANNUALLY:
				return 'ANNUALLY'
			case TIMEOFF_RULE_PERIOD.MONTHLY:
				return 'MONTHLY'
			case TIMEOFF_RULE_PERIOD.WEEKLY:
				return 'WEEKLY'
			case TIMEOFF_RULE_PERIOD.DAILY:
				return 'DAILY'
			default:
				return 'UNKNOWN_PERIOD'
		}
	}
}

const TimeoffRuleSnapshotType = s({
	id: number.isRequired,
	group: TimeoffGroupSnapshotType,
	type: TimeoffTypeSnapshotType,
	allowance: number.isRequired,
	period: number.isRequired,
	maxAccumulation: number.isRequired,
	allowOverLimit: bool.isRequired,
})

export class WalletLogExtInfo {
	constructor({
		timeoff_wallet: timeoffWallet,
		timeoff_request: timeoffRequest, // TIMEOFF_DEDUCTED, AUTO_ADJUSTMENT
		timeoff_rule: timeoffRule, // ENTITLEMENT
		date_entitled: dateEntitled, // ENTITLEMENT
		allowance, // ENTITLEMENT
		deducted_count: deductedCount, // TIMEOFF_DEDUCTED
		prev_balance_taken: prevBalanceTaken, // TIMEOFF_DEDUCTED
		new_balance_taken: newBalanceTaken, // TIMEOFF_DEDUCTED
		prev_wallet_balance: prevWalletBalance, // TIMEOFF_DEDUCTED
		new_wallet_balance: newWalletBalance, // TIMEOFF_DEDUCTED
		reason, // AUTO_ADJUSTMENT (@WALLET_AUTO_ADJUSTMENT_REASON), MANUAL_ADJUSTMENT (string)
		balance_taken: balanceTaken, // AUTO_ADJUSTMENT, MANUAL_ADJUSTMENT
		days_adjustment: daysAdjustment, // AUTO_ADJUSTMENT, MANUAL_ADJUSTMENT
	}) {
		this.timeoffWallet = new TimeoffWalletSnapshot(timeoffWallet)
		if (timeoffRequest) {
			this.timeoffRequest = new TimeoffRequestSnapshot(timeoffRequest)
		}
		if (timeoffRule) {
			this.timeoffRule = new TimeoffRuleSnapshot(timeoffRule)
		}
		if (dateEntitled) {
			this.dateEntitled = new Date(dateEntitled)
		}
		if (deductedCount) {
			this.deductedCount = deductedCount
		}
		if (prevBalanceTaken) {
			this.prevBalanceTaken = prevBalanceTaken
		}
		if (newBalanceTaken) {
			this.newBalanceTaken = newBalanceTaken
		}
		if (prevWalletBalance) {
			this.prevWalletBalance = prevWalletBalance
		}
		if (newWalletBalance) {
			this.newWalletBalance = newWalletBalance
		}
		if (allowance) {
			this.allowance = allowance
		}
		if (reason) {
			this.reason = reason
		}
		if (balanceTaken) {
			this.balanceTaken = balanceTaken
		}
		if (daysAdjustment) {
			this.daysAdjustment = daysAdjustment
		}
	}
}

export const WalletLogExtInfoType = s({
	timeoffRequest: TimeoffRequestSnapshotType,
	timeoffWallet: TimeoffWalletSnapshotType,
	timeoffRule: TimeoffRuleSnapshotType,
	dateEntitled: object,
	deductedCount: number,
	prevBalanceTaken: number,
	newBalanceTaken: number,
	prevWalletBalance: number,
	newWalletBalance: number,
	allowance: number,
	reason: oneOfType([number, string]),
	balanceTaken: number,
	daysAdjustment: number,
})

export default WalletLogExtInfo
