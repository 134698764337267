import CreateDepartment from './CreateDepartment'
import CreateGroupLeader from './CreateGroupLeader'
import DepartmentCount from './DepartmentCount'
import EmployeeDetail from './EmployeeDetail'
import EmployeeSimple from './EmployeeSimple'
import PositionCount from './PositionCount'
import GroupCount from './GroupCount'
import GroupLeaderRole from './GroupLeaderRole'
import RankCount from './RankCount'
import TitleCount from './TitleCount'

export default {
	CreateDepartment,
	CreateGroupLeader,
	DepartmentCount,
	EmployeeDetail,
	EmployeeSimple,
	PositionCount,
	GroupCount,
	GroupLeaderRole,
	RankCount,
	TitleCount,
}
