import { s, arrayOf, number } from 'modules/types'
import TemplateWithQuestion, { TemplateWithQuestionType } from './TemplateWithQuestion'
import AppraiseeSuperiorScoring, { AppraiseeSuperiorScoringType } from './AppraiseeSuperiorScoring'
import EmployeeWithDetail, { EmployeeWithDetailType } from './EmployeeWithDetail'

export class AppraiseesCustomEmployeeScoring {
	constructor({ appraisees, custom_employee: customEmployee, template, table_header_types: tableHeaderTypes }) {
		this.appraisees = appraisees.map(item => new AppraiseeSuperiorScoring(item))
		this.customEmployee = new EmployeeWithDetail(customEmployee)
		this.template = new TemplateWithQuestion(template)
		this.tableHeaderTypes = tableHeaderTypes
	}
}

export const AppraiseesCustomEmployeeScoringType = s({
	appraisees: arrayOf(AppraiseeSuperiorScoringType),
	customEmployee: EmployeeWithDetailType,
	template: TemplateWithQuestionType,
	tableHeaderTypes: arrayOf(number),
})

export default AppraiseesCustomEmployeeScoring
