import { s, number, string } from 'modules/types'

export class TimeoffGroupSnapshot {
	constructor({ id, name }) {
		this.id = id // @TimeoffGroup id
		this.name = name
	}
}

export const TimeoffGroupSnapshotType = s({
	id: number.isRequired,
	name: string.isRequired,
})

export default TimeoffGroupSnapshot
