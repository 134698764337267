import _ from 'lodash'
import api from 'modules/api'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `job_information`

export const API_URLS = {
	jobInformation: () => `/api/job_description/my_job_information/`,
}

const REFRESHED_JOB_INFORMATION = `${MODULE_NAME} | REFRESHED JOB INFORMATION`
const ERROR_REFRESHING_JOB_INFORMATION = `${MODULE_NAME} | ERROR REFRESHING JOB INFORMATION`
const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshJobInformation() {
	return dispatch => {
		const apiUrl = API_URLS.jobInformation()
		return api.job_description
			.myJobInformation()
			.then(({ data }) => {
				dispatch({ type: REFRESHED_JOB_INFORMATION, data, apiUrl })
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_JOB_INFORMATION })
			})
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshJobInformation())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
	permissions: [],
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_JOB_INFORMATION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
