import { s, number, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class EmploymentInfo {
	constructor({
		id,
		full_name: fullName,
		superior,
		picture,
		rank_name: rankName,
		title_name: titleName,
		position_name: positionName,
		group_name: groupName,
		department_name: departmentName,
	}) {
		this.id = id
		this.fullName = fullName
		if (superior) {
			this.superior = new Employee(superior)
		}
		this.picture = picture
		this.rankName = rankName
		this.titleName = titleName
		this.positionName = positionName
		this.groupName = groupName
		this.departmentName = departmentName
	}
}

export const EmploymentInfoType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	superior: EmployeeType,
	picture: string,
	rankName: string.isRequired,
	titleName: string.isRequired,
	positionName: string.isRequired,
	groupName: string.isRequired,
	departmentName: string.isRequired,
})

export default EmploymentInfo
