import { s, number, object, string } from 'modules/types'
import WorkingCycleGroupExtInfo, { WorkingCycleGroupLogExtInfoType } from './WorkingCycleGroupLogExtInfo'

export const WORKING_CYCLE_GROUP_LOG_TYPE = {
	CREATED: 1,
	EMPLOYEE_MOVED_IN: 2,
	EMPLOYEE_MOVED_OUT: 3,
	ADDED_RULE: 4,
	CHANGED_RULE: 5,
	DELETED_RULE: 6,
	EDITED_NAME: 7,
	EDITED_DAY: 8,
	EDITED_RULES: 9,
}

export class WorkingCycleGroupLog {
	constructor({
		id,
		operator_name: operatorName,
		operator_picture_url: operatorPictureUrl,
		type,
		ext_info: extInfo,
		added,
	}) {
		this.id = id
		this.operatorName = operatorName
		this.operatorPictureUrl = operatorPictureUrl
		this.type = type
		this.extInfo = new WorkingCycleGroupExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}
}

export const WorkingCycleGroupLogType = s({
	id: number.isRequired,
	operatorName: string.isRequired,
	operatorPictureUrl: string,
	type: number.isRequired,
	extInfo: WorkingCycleGroupLogExtInfoType,
	added: object,
})

export default WorkingCycleGroupLog
