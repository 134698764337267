import { s, number, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class BrainstormQuestionReport {
	constructor({
		id,
		question_content: questionContent,
		asker,
		audience_type: audienceType,
		answer_count: answerCount,
	}) {
		this.id = id
		this.questionContent = questionContent
		this.asker = new Employee(asker)
		this.audienceType = audienceType // @AUDIENCE_TYPE in ../brainstorming/BrainstormQuestion/
		this.answerCount = answerCount
	}
}

export const BrainstormQuestionReportType = s({
	id: number.isRequired,
	questionContent: string.isRequired,
	asker: EmployeeType,
	audienceType: number.isRequired,
	answerCount: number.isRequired,
})

export default BrainstormQuestionReport
