import { s, number, object, string } from 'modules/types'
import TimeoffGroupLogExtInfo, { TimeoffGroupLogExtInfoType } from './TimeoffGroupLogExtInfo'
import TimeoffGroupLogExtInfoDetail, { TimeoffGroupLogExtInfoDetailType } from './TimeoffGroupLogExtInfoDetail'

export class TimeoffGroupLogDetail {
	constructor({
		id,
		operator_name: operatorName,
		operator_picture_url: operatorPictureUrl,
		type,
		ext_info: extInfo,
		ext_info_detail: extInfoDetail,
		added,
	}) {
		this.id = id
		this.operatorName = operatorName
		this.operatorPictureUrl = operatorPictureUrl
		this.type = type
		this.extInfo = new TimeoffGroupLogExtInfo(extInfo)
		this.extInfoDetail = new TimeoffGroupLogExtInfoDetail(extInfoDetail)
		this.added = new Date(added * 1000)
	}
}

export const TimeoffGroupLogDetailType = s({
	id: number.isRequired,
	operatorName: string.isRequired,
	operatorPictureUrl: string.isRequired,
	type: number.isRequired,
	extInfo: TimeoffGroupLogExtInfoType,
	extInfoDetail: TimeoffGroupLogExtInfoDetailType,
	added: object.isRequired,
})

export default TimeoffGroupLogDetail
