import { s, number, string } from 'modules/types'

export class ExportResult {
	constructor({ url, count }) {
		this.url = url
		this.count = count
	}
}

export const ExportResultType = s({
	url: string.isRequired,
	count: number.isRequired,
})

export default ExportResult
