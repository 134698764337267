import { s, number, string } from 'modules/types'

class CompanyDistribution {
	constructor({ filter_value_id: filterValueId, filter_value_name: filterValueName, count, percentage }) {
		this.filterValueId = filterValueId
		this.filterValueName = filterValueName
		this.count = count
		this.percentage = percentage
	}
}

export const CompanyDistributionType = s({
	filterValueId: number.isRequired,
	filterValueName: string.isRequired,
	count: number.isRequired,
	percentage: number.isRequired,
})

export default CompanyDistribution
