export const COMMENT_PERMISSION = {
	NEVER: 0,
	ONCE: 1,
	MULTIPLE: 2,
}

export const POST_PERMISSION = {
	NEVER: 0,
	ALLOW: 1,
	ONLY_IF_LAST_REVIEWER: 2,
}

export const AWARD_PERMISSION = {
	NEVER: 0,
	ALLOW: 1,
	ONLY_IF_LAST_REVIEWER: 2,
	AUTO_AFTER_COMMENT: 3,
	AUTO_AFTER_FORWARD: 4,
	AUTO_AFTER_POST: 5,
}
