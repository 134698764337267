import { s, arrayOf, number } from 'modules/types'
import QuestionAnswer, { QuestionAnswerType } from './QuestionAnswer'

export class FieldAnswer {
	constructor({ id, question_answers: questionAnswers, question_answer: questionAnswer }) {
		this.id = id
		this.questionAnswers = questionAnswers && questionAnswers.map(item => new QuestionAnswer(item))
		this.questionAnswer = questionAnswer && new QuestionAnswer(questionAnswer)
	}
}

export const FieldAnswerType = s({
	id: number,
	questionAnswers: arrayOf(QuestionAnswerType),
	questionAnswer: QuestionAnswerType,
})

export default FieldAnswer
