import { push } from 'react-router-redux'
import api from 'modules/api'
import _ from 'lodash'
import { getObjectOrUndefined, toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisal_admin_template`

export const API_URLS = {
	defaultTemplateListSimple: () => `/api/appraisal_admin/appraisal_template/default_templates/simple/`,
	defaultTemplateDetail: defaultTemplateId =>
		`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/`,
	defaultTemplateUsedTemplateDetail: (defaultTemplateId, templateId) =>
		`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/templates/${templateId}/`,
	unaddedQuestions: defaultTemplateId =>
		`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/get_unadded_questions/`,
	questionList: () => `/api/appraisal_admin/appraisal_template/questions/`,
	questionDetail: questionId => `/api/appraisal_admin/appraisal_template/questions/${questionId}/`,
}
const CREATED_DEFAULT_TEMPLATE = `${MODULE_NAME} | CREATED DEFAULT TEMPLATE`
const COPIED_DEFAULT_TEMPLATE = `${MODULE_NAME} | COPIED DEFAULT TEMPLATE`
const EDITED_DEFAULT_TEMPLATE = `${MODULE_NAME} | EDITED DEFAULT TEMPLATE`
const DELETED_DEFAULT_TEMPLATE = `${MODULE_NAME} | DELETED DEFAULT TEMPLATE`
const SELECT_DEFAULT_TEMPLATE = `${MODULE_NAME} | SELECT DEFAULT TEMPLATE`

const CREATED_QUESTION = `${MODULE_NAME} CREATED QUESTION`
const EDITED_QUESTION = `${MODULE_NAME} EDITED QUESTION`
const DELETED_QUESTION = `${MODULE_NAME} DELETED QUESTION`
const CHANGED_LINEAR_SIZE = `${MODULE_NAME} CHANGED LINEAR SIZE`
const ADDED_ANSWER_CHOICE = `${MODULE_NAME} ADDED ANSWER CHOICE`

const EDITED_ANSWER_CHOICE = `${MODULE_NAME} EDITED ANSWER CHOICE`
const DELETED_ANSWER_CHOICE = `${MODULE_NAME} DELETED ANSWER CHOICE`
const MOVED_UP_ANSWER_CHOICE = `${MODULE_NAME} MOVED UP ANSWER CHOICE`
const MOVED_DOWN_ANSWER_CHOICE = `${MODULE_NAME} MOVED DOWN ANSWER CHOICE`

const REFRESHING_DEFAULT_TEMPLATE_LIST_SIMPLE = `${MODULE_NAME} | REFRESHING DEFAULT TEMPLATE LIST SIMPLE`
const REFRESHED_DEFAULT_TEMPLATE_LIST_SIMPLE = `${MODULE_NAME} | REFRESHED DEFAULT TEMPLATE LIST SIMPLE`
const REFRESHING_DEFAULT_TEMPLATE_DETAIL = `${MODULE_NAME} | REFRESHING DEFAULT TEMPLATE DETAIL`
const REFRESHED_DEFAULT_TEMPLATE_DETAIL = `${MODULE_NAME} | REFRESHED DEFAULT TEMPLATE DETAIL`
const REFRESHING_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL = `${MODULE_NAME} | REFRESHING DEFAULT TEMPLATE USED TEMPLATE DETAIL`
const REFRESHED_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL = `${MODULE_NAME} | REFRESHED DEFAULT TEMPLATE USED TEMPLATE DETAIL`
const REFRESHING_QUESTION_LIST = `${MODULE_NAME} | REFRESHING QUESTION LIST`
const REFRESHED_QUESTION_LIST = `${MODULE_NAME} | REFRESHED QUESTION LIST`
const REFRESHING_QUESTION_DETAIL = `${MODULE_NAME} | REFRESHING QUESTION DETAIL`
const REFRESHED_QUESTION_DETAIL = `${MODULE_NAME} | REFRESHED QUESTION DETAIL`
const REFRESHING_UNADDED_QUESTIONS = `${MODULE_NAME} | REFRESHING UNADDED QUESTIONS`
const REFRESHED_UNADDED_QUESTIONS = `${MODULE_NAME} | REFRESHED UNADDED QUESTIONS`

const LOADED_QUESTIONS = `${MODULE_NAME}| LOADED QUESTIONS`
const LOAD_MORE_EXISTING_QUESTIONS = `${MODULE_NAME} | LOAD_MORE_EXISTING_QUESTIONS`

const TURN_ON_EDITING_DEFAULT_TEMPLATE = `${MODULE_NAME} | TURN ON EDITING DEFAULT TEMPLATE`
const TURN_OFF_EDITING_DEFAULT_TEMPLATE = `${MODULE_NAME} | TURN OFF EDITING DEFAULT TEMPLATE`

const LOAD_USED_TEMPLATE_DETAIL_DATA = `${MODULE_NAME} | LOAD USED TEMPLATE DETAIL DATA`
const LOAD_QUESTION_LIST_DATA = `${MODULE_NAME} | LOAD QUESTION LIST DATA`
const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------
export function refreshDefaultTemplateListSimple() {
	return dispatch => {
		let apiUrl = API_URLS.defaultTemplateListSimple()
		dispatch({ type: REFRESHING_DEFAULT_TEMPLATE_LIST_SIMPLE, apiUrl })
		return api.appraisal_admin.appraisal_template.default_templates.getTemplatesSimple().then(({ data: templates }) => {
			dispatch({ type: REFRESHED_DEFAULT_TEMPLATE_LIST_SIMPLE, data: templates, apiUrl })
			return templates
		})
	}
}

export function refreshDefaultTemplateDetail(defaultTemplateId) {
	return dispatch => {
		let apiUrl = API_URLS.defaultTemplateDetail(defaultTemplateId)
		dispatch({ type: REFRESHING_DEFAULT_TEMPLATE_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_template.default_templates
			.getTemplateDetail(defaultTemplateId)
			.then(({ data: templateDetail }) => {
				dispatch({ type: REFRESHED_DEFAULT_TEMPLATE_DETAIL, data: templateDetail, apiUrl })
				return templateDetail
			})
	}
}

export function refreshDefaultTemplateUsedTemplateDetail(defaultTemplateId, templateId) {
	return dispatch => {
		let apiUrl = API_URLS.defaultTemplateUsedTemplateDetail(defaultTemplateId, templateId)
		dispatch({ type: REFRESHING_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_template.default_templates.templates
			.getDetail(defaultTemplateId, templateId)
			.then(({ data: templateDetail }) => {
				dispatch({ type: REFRESHED_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL, data: templateDetail, apiUrl })
				return templateDetail
			})
	}
}

export function refreshQuestionList(page = 1) {
	return dispatch => {
		let apiUrl = API_URLS.questionList()
		dispatch({ type: REFRESHING_QUESTION_LIST, apiUrl })
		return api.appraisal_admin.appraisal_template.questions.getQuestions(page).then(({ data, pagination }) => {
			dispatch({ type: REFRESHED_QUESTION_LIST, data, apiUrl, pagination })
			return data
		})
	}
}

export function loadQuestions(page) {
	return dispatch => {
		dispatch(refreshQuestionList(page)).then(data => {
			return dispatch({ type: LOADED_QUESTIONS, data })
		})
	}
}

export function loadMoreExistingQuestions() {
	return (dispatch, getState) => {
		const {
			appraisalAdminTemplate: {
				pagination: {
					existingQuestions: { currentPage },
				},
			},
		} = getState()
		return api.appraisal_admin.appraisal_template.questions
			.getQuestions(currentPage + 1)
			.then(({ data, pagination }) => {
				dispatch({ type: LOAD_MORE_EXISTING_QUESTIONS, data, pagination })
			})
	}
}

export function refreshQuestionDetail(questionId) {
	return dispatch => {
		let apiUrl = API_URLS.questionDetail(questionId)
		dispatch({ type: REFRESHING_QUESTION_DETAIL })
		return api.appraisal_admin.appraisal_template.questions.getQuestionDetail(questionId).then(({ data }) => {
			return dispatch({ type: REFRESHED_QUESTION_DETAIL, data, apiUrl })
		})
	}
}

export function refreshDefaultTemplateUnaddedQuestions(defaultTemplateId) {
	return dispatch => {
		let apiUrl = API_URLS.unaddedQuestions(defaultTemplateId)
		dispatch({ type: REFRESHING_UNADDED_QUESTIONS, apiUrl })
		return api.appraisal_admin.appraisal_template.default_templates
			.getUnaddedQuestions(defaultTemplateId)
			.then(({ data }) => {
				return dispatch({ type: REFRESHED_UNADDED_QUESTIONS, apiUrl, data })
			})
	}
}

// TEMPLATE
export function selectDefaultTemplate(defaultTemplateId) {
	return dispatch => {
		dispatch(push(`/admin_appraisal/template/${defaultTemplateId}`))
		dispatch(refreshDefaultTemplateDetail(defaultTemplateId))
		dispatch({ type: SELECT_DEFAULT_TEMPLATE })
	}
}

export function createDefaultTemplate(data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.default_templates.create(data).then(({ data: defaultTemplate }) => {
			dispatch({ type: CREATED_DEFAULT_TEMPLATE, defaultTemplate })
			dispatch(selectDefaultTemplate(defaultTemplate.id))
			toast('Created new template')
			return defaultTemplate
		})
	}
}

export function copyDefaultTemplate(defaultTemplateId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.default_templates
			.copy(defaultTemplateId)
			.then(({ data: defaultTemplate }) => {
				dispatch({ type: COPIED_DEFAULT_TEMPLATE })
				dispatch(refreshDefaultTemplateListSimple())
				toast(`Copied "${defaultTemplate.name}" template`)
				return defaultTemplate
			})
	}
}

export function editDefaultTemplate(defaultTemplateId, editedTemplate) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.default_templates
			.edit(defaultTemplateId, editedTemplate)
			.then(({ data: defaultTemplate }) => {
				dispatch({ type: EDITED_DEFAULT_TEMPLATE, defaultTemplate })
				toast(`Edited "${editedTemplate.name}" template`)
				return defaultTemplate
			})
	}
}

export function deleteDefaultTemplate(template) {
	return (dispatch, getState) => {
		return api.appraisal_admin.appraisal_template.default_templates.delete(template.id).then(() => {
			dispatch({ type: DELETED_DEFAULT_TEMPLATE, deletedTemplateId: template.id })
			toast(`Deleted "${template.name}" template`)
			const {
				appraisalAdminTemplate: { api },
			} = getState()
			const templates = getObjectOrUndefined(api, API_URLS.defaultTemplateListSimple())
			if (templates.length > 0) {
				dispatch(selectDefaultTemplate(templates[0].id))
			} else {
				dispatch(push(`/admin_appraisal/template/`))
			}
		})
	}
}

// QUESTION
export function createQuestion(newQuestion) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.create(newQuestion).then(({ data }) => {
			dispatch({ type: CREATED_QUESTION })
			dispatch(refreshQuestionList())
			toast(`Created "${newQuestion.alternativeContent}" question`)
			return data
		})
	}
}

export function editQuestion(questionId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.edit(questionId, data).then(() => {
			dispatch({ type: EDITED_QUESTION })
			dispatch(refreshQuestionDetail(questionId))
			toast(`Edited "${data.alternativeContent}" question`)
			return dispatch(refreshQuestionList())
		})
	}
}

export function deleteQuestion(question) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.delete(question.id).then(() => {
			dispatch({ type: DELETED_QUESTION })
			toast(`Deleted "${question.alternativeContent}" question`)
			return dispatch(refreshQuestionList())
		})
	}
}

export function changeLinearSize(questionId, linearSize, disabledChoices) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions
			.change_linear_size(questionId, linearSize, disabledChoices)
			.then(({ data }) => {
				dispatch({ type: CHANGED_LINEAR_SIZE, data })
				dispatch(refreshQuestionList())
				return data
			})
	}
}

// QUESTION - ANSWER CHOICE
export function addAnswerChoice(questionId, { content, disabled }) {
	let data = { content, disabled }

	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.add_answer_choice(questionId, data).then(({ data }) => {
			dispatch({ type: ADDED_ANSWER_CHOICE, data })
			dispatch(refreshQuestionList())
			return data
		})
	}
}

export function editAnswerChoice(questionId, answerChoiceId, { content, disabled }) {
	let data = { content, disabled }
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.answer_choices
			.edit(questionId, answerChoiceId, data)
			.then(({ data }) => {
				dispatch({ type: EDITED_ANSWER_CHOICE, data })
				dispatch(refreshQuestionDetail(questionId))
				return data
			})
	}
}

export function deleteAnswerChoice(questionId, answerChoiceId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.answer_choices
			.delete(questionId, answerChoiceId)
			.then(() => {
				return dispatch({ type: DELETED_ANSWER_CHOICE })
			})
	}
}

export function moveUpAnswerChoice(questionId, answerChoiceId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.answer_choices
			.move_up(questionId, answerChoiceId)
			.then(({ data }) => {
				dispatch({ type: MOVED_UP_ANSWER_CHOICE, data })
				return data
			})
	}
}

export function moveDownAnswerChoice(questionId, answerChoiceId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_template.questions.answer_choices
			.move_down(questionId, answerChoiceId)
			.then(({ data }) => {
				dispatch({ type: MOVED_DOWN_ANSWER_CHOICE, data })
				return data
			})
	}
}

export function turnOnEditingDefaultTemplate() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING_DEFAULT_TEMPLATE })
	}
}

export function turnOffEditingDefaultTemplate() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING_DEFAULT_TEMPLATE })
	}
}

export function loadUsedTemplateDetailData(defaultTemplateId, templateId) {
	return dispatch => {
		dispatch({ type: LOAD_USED_TEMPLATE_DETAIL_DATA })
		return dispatch(refreshDefaultTemplateUsedTemplateDetail(defaultTemplateId, templateId))
	}
}

export function loadQuestionListData() {
	return dispatch => {
		dispatch({ type: LOAD_QUESTION_LIST_DATA })
		return dispatch(refreshQuestionList())
	}
}

export function loadModule() {
	return dispatch => {
		dispatch({ type: LOAD_MODULE })
		return dispatch(refreshDefaultTemplateListSimple()).then(templates => {
			if (templates.length > 0 && window.location.pathname === '/admin_appraisal/template/') {
				dispatch(selectDefaultTemplate(templates[0].id))
			}
		})
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {
		existingQuestions: undefined,
	},
	data: {
		isEditingDefaultTemplate: false,
		existingQuestions: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_QUESTION_LIST:
		case REFRESHING_DEFAULT_TEMPLATE_LIST_SIMPLE:
		case REFRESHING_DEFAULT_TEMPLATE_DETAIL:
		case REFRESHING_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL:
		case REFRESHING_QUESTION_DETAIL:
		case REFRESHING_UNADDED_QUESTIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_QUESTION_LIST:
			return {
				...state,
				data: {
					existingQuestions: action.data,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
					existingQuestions: action.pagination,
				},
			}
		case REFRESHED_DEFAULT_TEMPLATE_LIST_SIMPLE:
		case REFRESHED_DEFAULT_TEMPLATE_DETAIL:
		case REFRESHED_DEFAULT_TEMPLATE_USED_TEMPLATE_DETAIL:
		case REFRESHED_QUESTION_DETAIL:
		case REFRESHED_UNADDED_QUESTIONS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case CREATED_DEFAULT_TEMPLATE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.defaultTemplateListSimple()]: [
						{
							id: action.defaultTemplate.id,
							name: action.defaultTemplate.name,
							description: action.defaultTemplate.description,
						},
						...state.api[API_URLS.defaultTemplateListSimple()],
					],
				},
			}
		case EDITED_DEFAULT_TEMPLATE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.defaultTemplateListSimple()]: state.api[API_URLS.defaultTemplateListSimple()].map(
						defaultTemplateSimple => {
							if (defaultTemplateSimple.id === action.defaultTemplate.id) {
								return {
									...defaultTemplateSimple,
									name: action.defaultTemplate.name,
									description: action.defaultTemplate.description,
								}
							}
							return defaultTemplateSimple
						}
					),
					[API_URLS.defaultTemplateDetail(action.defaultTemplate.id)]: action.defaultTemplate,
				},
			}
		case DELETED_DEFAULT_TEMPLATE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.defaultTemplateListSimple()]: state.api[API_URLS.defaultTemplateListSimple()].filter(
						defaultTemplateSimple => defaultTemplateSimple.id !== action.deletedTemplateId
					),
				},
			}
		case TURN_ON_EDITING_DEFAULT_TEMPLATE:
			return {
				...state,
				data: {
					...state.data,
					isEditingDefaultTemplate: true,
				},
			}
		case TURN_OFF_EDITING_DEFAULT_TEMPLATE:
			return {
				...state,
				data: {
					...state.data,
					isEditingDefaultTemplate: false,
				},
			}
		case LOAD_MORE_EXISTING_QUESTIONS:
			return {
				...state,
				data: {
					...state.data,
					existingQuestions: [...state.data.existingQuestions, ...action.data],
				},
				pagination: {
					...state.pagination,
					existingQuestions: action.pagination,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
