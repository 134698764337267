import { s, number, string } from 'modules/types'

export class EmployeeProfile {
	constructor({
		id,
		NIK,
		picture,
		full_name: fullName,
		title_name: titleName,
		department_name: departmentName,
		superior_name: superiorName,
		rank_name: rankName,
		group_name: groupName,
	}) {
		this.id = id
		this.NIK = NIK
		this.picture = picture
		this.fullName = fullName
		this.titleName = titleName
		this.departmentName = departmentName
		this.superiorName = superiorName
		this.rankName = rankName
		this.groupName = groupName
	}
}

export const EmployeeProfileType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	picture: string,
	fullName: string.isRequired,
	titleName: string.isRequired,
	departmentName: string.isRequired,
	superiorName: string,
	rankName: string.isRequired,
	groupName: string.isRequired,
})

export default EmployeeProfile
