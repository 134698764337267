import { s, arrayOf } from 'modules/types'
import BrainstormQuestion, { BrainstormQuestionType } from './BrainstormQuestion'
import BrainstormAnswer, { BrainstormAnswerType } from './BrainstormAnswer'

export class BrainstormQuestionDetail {
	constructor({ question, answers }) {
		this.question = new BrainstormQuestion(question)
		this.answers = answers.map(item => new BrainstormAnswer(item))
	}
}

export const BrainstormQuestionDetailType = s({
	question: BrainstormQuestionType.isRequired,
	answers: arrayOf(BrainstormAnswerType).isRequired,
})

export default BrainstormQuestionDetail
