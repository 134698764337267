import { s, number, string } from 'modules/types'

export class EmployeeWithScore {
	constructor({
		employee_id: employeeId,
		NIK,
		full_name: fullName,
		score,
		employee_group_name: employeeGroupName,
		appraisal_group_name: appraisalGroupName,
	}) {
		this.employeeId = employeeId
		this.NIK = NIK
		this.fullName = fullName
		this.score = score
		this.employeeGroupName = employeeGroupName
		this.appraisalGroupName = appraisalGroupName
	}
}

export const EmployeeWithScoreType = s({
	employeeId: number.isRequired,
	NIK: string.isRequired,
	fullName: string.isRequired,
	score: number.isRequired,
	employeeGroupName: string.isRequired,
	appraisalGroupName: string.isRequired,
})

export default EmployeeWithScore
