import { s, arrayOf } from 'modules/types'
import { CategoryWithQuestionLink, CategoryWithQuestionLinkType } from './CategoryWithQuestionLink'
import { GroupStage, GroupStageType } from './GroupStage'
import { SubSession, SubSessionType } from './SubSession'

export class SubSessionDetail {
	constructor({ categories, group_stages: groupStages, sub_session: subSession }) {
		this.categories = categories.map(item => new CategoryWithQuestionLink(item))
		this.groupStages = groupStages.map(item => new GroupStage(item))
		this.subSession = new SubSession(subSession)
	}
}

export const SubSessionDetailType = s({
	categories: arrayOf(CategoryWithQuestionLinkType).isRequired,
	groupStages: arrayOf(GroupStageType),
	subSession: SubSessionType,
})

export default SubSessionDetail
