import { s, arrayOf, bool, number, string } from 'modules/types'
import QuestionLinkWithAnswer, { QuestionLinkWithAnswerType } from './QuestionLinkWithAnswer'
import Question, { QuestionType } from './Question'
import Employee, { EmployeeType } from './Employee'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'

class SheetViewCategory {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const SheetViewCategoryType = s({
	id: number.isRequired,
	name: string.isRequired,
})

class SheetViewQuestionLink {
	constructor({ id, question, order }) {
		this.id = id
		this.question = new Question(question)
		this.order = order
	}
}

const SheetViewQuestionLinkType = s({
	id: number.isRequired,
	question: QuestionType,
	order: number.isRequired,
})

class SheetViewEmployee {
	constructor({
		sub_session_id: subSessionId,
		appraiser_type: appraiserType,
		employee,
		has_answer_permission: hasAnswerPermission,
		has_view_permission: hasViewPermission,
		question_link_with_answers: questionLinkWithAnswers,
	}) {
		this.appraiserType = new AppraiserType(appraiserType)
		this.subSessionId = subSessionId
		this.employee = new Employee(employee)
		this.hasAnswerPermission = hasAnswerPermission
		this.hasViewPermission = hasViewPermission
		this.questionLinkWithAnswers = questionLinkWithAnswers.map(item => new QuestionLinkWithAnswer(item))
	}
}

const SheetViewEmployeeType = s({
	appraiserType: AppraiserTypeType,
	subSessionId: number.isRequired,
	employee: EmployeeType,
	hasAnswerPermission: bool.isRequired,
	hasViewPermission: bool.isRequired,
	questionLinkWithAnswers: arrayOf(QuestionLinkWithAnswerType),
})

class SheetViewCategoryResult {
	constructor({ category, question_links: questionLinks, employees, appraiser_types: appraiserTypes }) {
		this.category = new SheetViewCategory(category)
		this.questionLinks = questionLinks.map(item => new SheetViewQuestionLink(item))
		this.employees = employees.map(item => new SheetViewEmployee(item))
		this.appraiserTypes = appraiserTypes.map(item => new AppraiserType(item))
	}
}

export const SheetViewCategoryResultType = s({
	category: SheetViewCategoryType,
	questionLinks: arrayOf(SheetViewQuestionLinkType),
	employees: arrayOf(SheetViewEmployeeType),
	appraiserTypes: arrayOf(AppraiserTypeType),
})

export default SheetViewCategoryResult
