import store from 'store/createStore'

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_TOAST = 'SHOW_TOAST'
export const HIDE_TOAST = 'HIDE_TOAST'

// ------------------------------------
// Actions
// ------------------------------------

export function showToast(message, options, autoHideToast = true, withClose = true) {
	return dispatch => {
		const toastOptions = {
			color: 'success',
			place: 'tr',
			...options,
		}

		dispatch({ type: SHOW_TOAST, message, color: toastOptions.color, place: toastOptions.place, withClose })
		if (autoHideToast) {
			setTimeout(() => dispatch(hideToast()), 2000)
		}
	}
}

export function hideToast() {
	return dispatch => {
		dispatch({ type: HIDE_TOAST })
	}
}

export function showUploadingToastr() {
	store.dispatch(
		showToast(
			'We are still uploading your file.. please do not close this browser.',
			{
				color: 'warning',
				place: 'tr',
			},
			false,
			false
		)
	)
}

export function hideUploadingToastr() {
	store.dispatch(hideToast())
	store.dispatch(showToast('Submitting...', { color: 'warning', place: 'tr' }, false, false))
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	message: '',
	color: 'success',
	place: 'tr',
	withClose: false,
}

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SHOW_TOAST:
			return {
				...state,
				message: action.message,
				color: action.color,
				place: action.place,
				withClose: action.withClose,
			}
		case HIDE_TOAST:
			return {
				...state,
				message: '',
			}
		default:
			return state
	}
}
