import { s, number } from 'modules/types'
import RuleTypeSnapshot, { RuleTypeSnapshotType } from './RuleTypeSnapshot'

export class RuleSnapshot {
	constructor({ id, day, type }) {
		this.id = id
		this.day = day
		this.type = new RuleTypeSnapshot(type)
	}
}

export const RuleSnapshotType = s({
	id: number.isRequired,
	day: number.isRequired,
	type: RuleTypeSnapshotType,
})

export default RuleSnapshot
