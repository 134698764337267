import _ from 'lodash'
import api from 'modules/api'
// import { PERMISSIONS } from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `employeeExperience`

export const API_URLS = {
	activeWallets: employeeId => `/api/hr_experience/employees/${employeeId}/get_employee_active_wallets/`,
	summary: employeeId => `/api/hr_experience/employees/${employeeId}/get_all_employee_wallet_summaries/`,
}

const REFRESHED_ACTIVE_WALLETS = `${MODULE_NAME} | REFRESHED ACTIVE WALLETS`
const REFRESHED_SUMMARY = `${MODULE_NAME} | REFRESHED SUMMARY`

const EDITED_EMPLOYEE_EXPERIENCES = `${MODULE_NAME} | EDITED_EMPLOYEE_EXPERIENCES`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------

function refreshActiveWallets(employeeId) {
	return dispatch => {
		const apiUrl = API_URLS.activeWallets(employeeId)
		return api.hr_experience.employees.getActiveExperienceWallets(employeeId).then(({ data: activeWallets }) => {
			dispatch({ type: REFRESHED_ACTIVE_WALLETS, data: activeWallets, apiUrl })
			return activeWallets
		})
	}
}

export function refreshSummary(employeeId) {
	return dispatch => {
		const apiUrl = API_URLS.summary(employeeId)
		return api.hr_experience.employees.getAllExperienceWalletSummaries(employeeId).then(({ data: summary }) => {
			dispatch({ type: REFRESHED_SUMMARY, data: summary, apiUrl })
			return summary
		})
	}
}

export function editEmployeeExperiences(employeeId, experienceTypeIds, cb = null) {
	// experienceTypeIds = [] means revoke employee's access to experience module
	return dispatch => {
		const apiUrl = API_URLS.summary(employeeId)
		return api.hr_experience.employees
			.editExperiences(employeeId, experienceTypeIds)
			.then(() => {
				dispatch({ type: EDITED_EMPLOYEE_EXPERIENCES, apiUrl, experienceTypeIds })
				return dispatch(refreshSummary(employeeId))
			})
			.then(() => {
				if (cb) {
					cb()
				}
			})
	}
}

export function loadModule(employeeId) {
	return dispatch => {
		// const { session: { currentUser: { permissions } } } = getState() // TODO
		dispatch(refreshSummary(employeeId))
		dispatch(refreshActiveWallets(employeeId))
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_ACTIVE_WALLETS:
		case REFRESHED_SUMMARY:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
