import { s, number, string } from 'modules/types'

export class Stage {
	constructor({ id, stage_number: stageNumber, start_date: startDate, end_date: endDate }) {
		this.id = id
		this.stageNumber = stageNumber
		this.startDate = startDate
		this.endDate = endDate
	}
}

export const StageType = s({
	id: number.isRequired,
	stageNumber: number.isRequired,
	startDate: string.isRequired,
	endDate: string.isRequired,
})

export default Stage
