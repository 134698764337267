import { s, string, number } from 'modules/types'

export class Announcement {
	constructor({ id, title, description, attachment_url: attachmentUrl = null }) {
		this.id = id
		this.title = title
		this.description = description
		if (attachmentUrl !== null) {
			this.attachmentUrl = attachmentUrl
		}
	}
}

export const AnnouncementType = s({
	id: number.isRequired,
	title: string.isRequired,
	description: string.isRequired,
	attachmentUrl: string,
})

export default Announcement
