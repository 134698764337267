import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let SurveyOngoingComponent

const SurveyOngoing = store => {
	if (SurveyOngoingComponent === undefined) {
		SurveyOngoingComponent = Loadable({
			loader: () => {
				return import('views/SurveyOngoing/containers/SurveyOngoingContainer')
			},
			loading: RouteLoader,
		})
	}
	return SurveyOngoingComponent
}

export default SurveyOngoing
