import { s, bool, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import EmployeeDetail, { EmployeeDetailType } from './EmployeeDetail'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'

export class ApproverTimeoffRequest {
	constructor({
		id,
		created_by: createdBy,
		start_date: startDate,
		end_date: endDate,
		start_half_day: startHalfDay,
		end_half_day: endHalfDay,
		total_days: totalDays,
		next_approver: nextApprover,
		timeoff_type: timeoffType,
		current_balance: currentBalance,
		added,
	}) {
		this.id = id
		this.createdBy = new EmployeeDetail(createdBy)
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.startHalfDay = startHalfDay
		this.endHalfDay = endHalfDay
		this.totalDays = totalDays
		this.nextApprover = new Employee(nextApprover)
		this.timeoffType = new TimeoffType(timeoffType)
		this.currentBalance = currentBalance
		this.added = new Date(added * 1000)
	}
}

export const ApproverTimeoffRequestType = s({
	id: number.isRequired,
	createdBy: EmployeeDetailType,
	startDate: object.isRequired,
	endDate: object.isRequired,
	startHalfDay: bool.isRequired,
	endHalfDay: bool.isRequired,
	totalDays: number.isRequired,
	nextApprover: EmployeeType,
	timeoffType: TimeoffTypeType,
	currentBalance: number.isRequired,
	added: object.isRequired,
})

export default ApproverTimeoffRequest
