import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let HrExperienceComponent

const HrExperience = store => {
	if (HrExperienceComponent === undefined) {
		HrExperienceComponent = Loadable({
			loader: () => {
				return import('views/HrExperience/containers/HrExperienceContainer')
			},
			loading: RouteLoader,
		})
	}
	return HrExperienceComponent
}

export default HrExperience
