import { s, arrayOf, number, string } from 'modules/types'
import Template, { TemplateType } from './Template'
import Appraisee, { AppraiseeType } from './Appraisee'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'

export class AppraisalGroup {
	constructor({ id, name, template, appraisees, group_appraiser_types: groupAppraiserTypes = null }) {
		this.id = id
		this.name = name
		this.template = new Template(template)
		this.appraisees = appraisees.map(item => new Appraisee(item))
		if (groupAppraiserTypes !== null) {
			this.groupAppraiserTypes = groupAppraiserTypes.map(item => new AppraiserType(item))
		}
	}
}

export const AppraisalGroupType = s({
	id: number.isRequired,
	name: string.isRequired,
	template: TemplateType,
	appraisees: arrayOf(AppraiseeType),
	groupAppraiserTypes: arrayOf(AppraiserTypeType),
})

export default AppraisalGroup
