import Home from 'layouts/Home'
import Login from 'layouts/Login'

var indexRoutes = [
	// { path: "/rtl", name: "RTL", component: RTL },
	// { path: "/pages", name: "Pages", component: Pages },
	{ path: '/login', name: 'Login', component: Login },
	{ path: '/', name: 'Home', component: Home, authenticatedRoute: true },
]

export default indexRoutes
