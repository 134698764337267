import { s, arrayOf } from 'modules/types'
import IdeaFlow, { IdeaFlowType } from './IdeaFlow'
import EmployeeIdeaFlow, { EmployeeIdeaFlowType } from './EmployeeIdeaFlow'

export class IdeaReviewers {
	constructor({ idea_flows: ideaFlows, employees }) {
		this.ideaFlows = ideaFlows.map(item => new IdeaFlow(item))
		this.employees = employees.map(item => new EmployeeIdeaFlow(item))
	}
}

export const IdeaReviewersType = s({
	ideaFlows: arrayOf(IdeaFlowType),
	employees: arrayOf(EmployeeIdeaFlowType),
})

export default IdeaReviewers
