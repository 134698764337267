import { s, number, object } from 'modules/types'

class SnapshotEmployee {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const SnapshotEmployeeType = {
	id: number.isRequired,
	name: number.isRequired,
}

class SnapshotExperienceType {
	constructor({ id, name, code }) {
		this.id = id
		this.name = name
		this.code = code
	}
}

const SnapshotExperienceTypeType = {
	id: number.isRequired,
	name: number.isRequired,
	code: number.isRequired,
}

class SnapshotExperienceWallet {
	constructor({ id, type, balance }) {
		this.id = id
		this.type = new SnapshotExperienceType(type)
		this.balance = balance
	}
}

const SnapshotExperienceWalletType = {
	id: number.isRequired,
	type: SnapshotExperienceTypeType.isRequired,
	balance: number.isRequired,
}

class SnapshotExperienceRequest {
	constructor({
		id,
		wallet,
		submitted_hours: submittedHours,
		final_hours: finalHours,
		next_approver: nextApprover,
		next_approver_num: nextApproverNum,
		status,
		added,
	}) {
		this.id = id
		this.wallet = new SnapshotExperienceWallet(wallet)
		this.submittedHours = submittedHours
		this.finalHours = finalHours
		if (nextApprover !== null) {
			this.nextApprover = new SnapshotEmployee(nextApprover)
		}
		this.nextApproverNum = nextApproverNum
		this.status = status
		this.added = new Date(added * 1000)
	}
}

const SnapshotExperienceRequestType = {
	id: number.isRequired,
	wallet: SnapshotExperienceWalletType,
	submittedHours: number.isRequired,
	finalHours: number.isRequired,
	nextApprover: SnapshotEmployeeType,
	nextApproverNum: number.isRequired,
	status: number.isRequired,
	added: object.isRequired,
}

export class ExperienceRequestLogExtInfo {
	constructor({
		experience_request: experienceRequest,
		current_approver: currentApprover,
		current_approver_num: currentApproverNum,
		notes,
		next_approver: nextApprover,
		old_snapshot_experience_request: oldSnapshotExperienceRequest,
		new_snapshot_experience_request: newSnapshotExperienceRequest,
	}) {
		if (experienceRequest) {
			this.experienceRequest = new SnapshotExperienceRequest(experienceRequest)
		}
		if (currentApprover) {
			this.currentApprover = new SnapshotEmployee(currentApprover)
		}
		if (currentApproverNum) {
			this.currentApproverNum = currentApproverNum
		}
		if (notes) {
			this.notes = notes
		}
		if (nextApprover) {
			this.nextApprover = new SnapshotEmployee(nextApprover)
		}
		if (oldSnapshotExperienceRequest) {
			this.oldSnapshotExperienceRequest = new SnapshotExperienceRequest(oldSnapshotExperienceRequest)
		}
		if (newSnapshotExperienceRequest) {
			this.newSnapshotExperienceRequest = new SnapshotExperienceRequest(newSnapshotExperienceRequest)
		}
	}
}

export const ExperienceRequestLogExtInfoType = s({
	experienceRequest: SnapshotExperienceRequestType,
	currentApprover: SnapshotEmployeeType,
	currentApproverNum: number,
	notes: number,
	nextApprover: SnapshotEmployeeType,
	oldSnapshotExperienceRequest: SnapshotExperienceRequestType,
	newSnapshotExperienceRequest: SnapshotExperienceRequestType,
})

export default ExperienceRequestLogExtInfo
