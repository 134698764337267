import { s, number } from 'modules/types'
import AppraiseeEmployee, { AppraiseeEmployeeType } from './AppraiseeEmployee'
import GroupStageAppraiserType, { GroupStageAppraiserTypeType } from './GroupStageAppraiserType'
import Session, { SessionType } from './Session'

export class SubSessionSimple {
	constructor({
		id,
		session,
		appraisee,
		group_stage_appraiser_type: groupStageAppraiserType,
		stage_end_date: stageEndDate,
		submitted_at: submittedAt,
	}) {
		this.id = id
		this.session = new Session(session)
		this.appraisee = new AppraiseeEmployee(appraisee)
		this.groupStageAppraiserType = new GroupStageAppraiserType(groupStageAppraiserType)
		this.stageEndDate = new Date(stageEndDate)
		this.submittedAt = submittedAt !== null ? new Date(parseInt(submittedAt, 10) * 1000) : null
	}
}

export const SubSessionSimpleType = s({
	id: number.isRequired,
	session: SessionType,
	appraisee: AppraiseeEmployeeType,
	groupStageAppraiserType: GroupStageAppraiserTypeType,
})

export default SubSessionSimple
