import { s, bool, number, string } from 'modules/types'
import Appraisee, { AppraiseeType } from './Appraisee'
import Employee, { EmployeeType } from './Employee'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'

export class SubSession {
	constructor({
		id,
		appraisee,
		appraiser = null,
		appraiser_type: appraiserType,
		group_stage_id: groupStageId,
		is_completed: isCompleted,
		group_name: groupName,
		stage_number: stageNumber,
	}) {
		this.id = id
		this.appraisee = new Appraisee(appraisee)
		if (appraiser !== null) {
			this.appraiser = new Employee(appraiser)
		}
		this.appraiserType = new AppraiserType(appraiserType)
		this.groupStageId = groupStageId
		this.isCompleted = isCompleted
		this.groupName = groupName
		this.stageNumber = stageNumber
	}
}

export const SubSessionType = s({
	id: number.isRequired,
	appraisee: AppraiseeType,
	appraiser: EmployeeType,
	appraiserType: AppraiserTypeType,
	groupStageId: number,
	isCompleted: bool.isRequired,
	groupName: string.isRequired,
	stageNumber: number.isRequired,
})

export default SubSession
