import { s, number, object } from 'modules/types'

export class HolidayDateRangeSnapshot {
	constructor({ id, start_date: startDate, end_date: endDate }) {
		this.id = id // @HolidayDateRange id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
	}
}

export const HolidayDateRangeSnapshotType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
})

export default HolidayDateRangeSnapshot
