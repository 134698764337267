import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let IdeaComponent

const Idea = store => {
	if (IdeaComponent === undefined) {
		IdeaComponent = Loadable({
			loader: () => {
				return import('views/Idea/containers/IdeaContainer')
			},
			loading: RouteLoader,
		})
	}
	return IdeaComponent
}

export default Idea
