import { s, string } from 'modules/types'

class AttachmentUrl {
	constructor({ file_name: fileName, file_url: fileUrl }) {
		this.fileName = fileName
		this.fileUrl = fileUrl
	}
}

export const AttachmentUrlType = s({
	fileName: string.isRequired,
	fileUrl: string.isRequired,
})

export default AttachmentUrl
