import { s, number } from 'modules/types'

export class Pagination {
	constructor({ current_page: currentPage, total_page: totalPage, total_count: totalCount }) {
		this.currentPage = currentPage
		this.totalPage = totalPage
		this.totalCount = totalCount
	}
}

export const PaginationType = s({
	currentPage: number.isRequired,
	totalPage: number.isRequired,
	totalCount: number.isRequired,
})

export class PaginationV2 {
	constructor({ currentPage, totalPage, totalCount }) {
		this.currentPage = currentPage
		this.totalPage = totalPage
		this.totalCount = totalCount
	}
}

export default Pagination
