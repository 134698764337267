import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
import _ from 'lodash'

import AppraiserType from 'modules/models/appraisal_admin/scoring/AppraiserType'
import { push } from 'react-router-redux'
import { reset } from 'redux-form'
import GROUP_TYPES from './group_types'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisal_admin_scoring`

export const API_URLS = {
	currentTableHeader: () => `/api/appraisal_admin/appraisal_scoring/settings/get_current_table_header/`,
	sessionsSimple: () => `/api/appraisal_admin/appraisal_scoring/sessions/simple/`,
	sessionDetail: sessionId => `/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/`,
	sessionGroups: sessionId => `/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/`,
	sessionGroupStages: (sessionId, groupId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/stages/`,
	sessionGroupTemplate: (sessionId, groupId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/get_template/`,
	employeesIndividualView: (sessionId, groupId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/`,
	employeesIndividualViewDetail: (sessionId, groupId, employeeId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/`,
	employeesSuperiorView: (sessionId, groupId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/superior/${groupId}/employees/`,
	employeesSuperiorViewDetail: (sessionId, groupId, employeeId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/superior/${groupId}/employees/${employeeId}/`,
	employeesCustomView: (sessionId, groupId, groupType) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/`,
	employeesCustomViewDetail: (sessionId, groupId, employeeId, groupType) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/`,
	template: (sessionId, templateId) =>
		`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/templates/${templateId}/`,
}

export const SCORING_VIEW = GROUP_TYPES

const SELECTED_SESSION = `${MODULE_NAME} | SELECTED SESSION`
const EDITED_PROPORTION = `${MODULE_NAME} | EDITED PROPORTION`
const CHANGED_GROUP_VIEW = `${MODULE_NAME} | CHANGED GROUP VIEW`

const SELECT_EMPLOYEE = `${MODULE_NAME} | SELECT EMPLOYEE`
const UNSELECT_EMPLOYEE = `${MODULE_NAME} | UNSELECT EMPLOYEE`
const SELECT_GROUP = `${MODULE_NAME} | SELECT GROUP`
const UNSELECT_GROUP = `${MODULE_NAME} | UNSELECT GROUP`
const SELECT_CUSTOM_EMPLOYEE = `${MODULE_NAME} | SELECT CUSTOM_EMPLOYEE`
const UNSELECT_CUSTOM_EMPLOYEE = `${MODULE_NAME} | UNSELECT CUSTOM_EMPLOYEE`
const SELECT_ALL = `${MODULE_NAME} | SELECT ALL`
const UNSELECT_ALL = `${MODULE_NAME} | UNSELECT ALL`
const EXPAND_GROUPS = `${MODULE_NAME} | EXPAND GROUPS`
const COLLAPSE_GROUPS = `${MODULE_NAME} | COLLAPSE GROUPS`
const MOVE_EMPLOYEES_TO_EXISTING_GROUP = `${MODULE_NAME} | MOVE EMPLOYEES TO EXISTING GROUP`
const MOVE_EMPLOYEES_TO_NEW_GROUP = `${MODULE_NAME} | MOVE EMPLOYEES TO NEW GROUP`

const LOADED_MORE_EMPLOYEES_INDIVIDUAL_VIEW = `${MODULE_NAME} LOADED MORE EMPLOYEES INDIVIDUAL VIEW`

const EXPORTED_ALL_PDF_ZIP = `${MODULE_NAME} | EXPORTED ALL PDF ZIP`
const EXPORTED_SELECTED_SCORE_CARDS_ZIP = `${MODULE_NAME} | EXPORTED SELECTED SCORE CARDS ZIP`
const EXPORTED_ALL_EXCEL_RESUME = `${MODULE_NAME} | EXPORTED ALL EXCEL RESUME`
const EXPORTED_SELECTED_EXCEL_RESUME = `${MODULE_NAME} | EXPORTED SELECTED EXCEL RESUME`
const EXPORTED_ALL_EXCEL_RANK = `${MODULE_NAME} | EXPORTED ALL EXCEL RANK`
const EXPORTED_SELECTED_EXCEL_RANK = `${MODULE_NAME} | EXPORTED SELECTED EXCEL RANK`
const EDITED_TEMPLATE_WEIGHT = `${MODULE_NAME} | EDITED TEMPLATE WEIGHT`

const UPDATED_SINGLE_SNAPSHOT = `${MODULE_NAME} UPDATED SINGLE SNAPSHOT`
const SAVED_PROGRESS_INDIVIDUAL = `${MODULE_NAME} SAVED PROGRESS INDIVIDUAL`
const CANCELLED_INDIVIDUAL = `${MODULE_NAME} CANCELLED INDIVIDUAL`
const SUBMITTED_SESSION_APPRAISEES_INDIVIDUAL_VIEW = `${MODULE_NAME} | SUBMITTED SESSION APPRAISEES INDIVIDUAL VIEW`

const SAVED_PROGRESS_CUSTOM_VIEW = `${MODULE_NAME} | SAVED PROGRESS SUPERIOR VIEW`
const CANCELLED_CUSTOM_VIEW = `${MODULE_NAME} | CANCELLED SUPERIOR VIEW`
const SUBMITTED_EMPLOYEES_CUSTOM_VIEW = `${MODULE_NAME} | SUBMITTED EMPLOYEES SUPERIOR VIEW`

const REFRESHING_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHING SESSIONS SIMPLE`
const REFRESHED_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHED SESSIONS SIMPLE`
const REFRESHING_SESSION_DETAIL = `${MODULE_NAME} | REFRESHING SESSION DETAIL`
const REFRESHED_SESSION_DETAIL = `${MODULE_NAME} | REFRESHED SESSION DETAIL`
const REFRESHING_SESSION_GROUPS = `${MODULE_NAME} | REFRESHING SESSION GROUPS`
const REFRESHED_SESSION_GROUPS = `${MODULE_NAME} | REFRESHED SESSION GROUPS`
const REFRESHING_SESSION_GROUP_STAGES = `${MODULE_NAME} | REFRESHING SESSION GROUP STAGES`
const REFRESHED_SESSION_GROUP_STAGES = `${MODULE_NAME} | REFRESHED SESSION GROUP STAGES`
const REFRESHING_SESSION_GROUP_TEMPLATE = `${MODULE_NAME} | REFRESHING SESSION GROUP TEMPLATE`
const REFRESHED_SESSION_GROUP_TEMPLATE = `${MODULE_NAME} | REFRESHED SESSION GROUP TEMPLATE`
const REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW = `${MODULE_NAME} | REFRESHING SESSION APPRAISEES INDIVIDUAL VIEW`
const REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW = `${MODULE_NAME} | REFRESHED SESSION APPRAISEES INDIVIDUAL VIEW`
const REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL = `${MODULE_NAME} | REFRESHING SESSION APPRAISEES INDIVIDUAL VIEW DETAIL`
const REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL = `${MODULE_NAME} | REFRESHED SESSION APPRAISEES INDIVIDUAL VIEW DETAIL`
const REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW = `${MODULE_NAME} | REFRESHING SESSION APPRAISEES CUSTOM VIEW`
const REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW = `${MODULE_NAME} | REFRESHED SESSION APPRAISEES CUSTOM VIEW`
const REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL = `${MODULE_NAME} | REFRESHING SESSION APPRAISEES CUSTOM VIEW DETAIL`
const REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL = `${MODULE_NAME} | REFRESHED SESSION APPRAISEES CUSTOM VIEW DETAIL`
const REFRESHING_TEMPLATE = `${MODULE_NAME} | REFRESHING TEMPLATE`
const REFRESHED_TEMPLATE = `${MODULE_NAME} | REFRESHED TEMPLATE`
const REFRESHING_CURRENT_TABLE_HEADER = `${MODULE_NAME} | REFRESHING CURRENT TABLE HEADER`
const REFRESHED_CURRENT_TABLE_HEADER = `${MODULE_NAME} | REFRESHED CURRENT TABLE HEADER`

const CHANGE_NAME_OR_NIK_FILTER = `${MODULE_NAME} | CHANGE NAME OR NIK FILTER`
const SEARCH_EMPLOYEES = `${MODULE_NAME} | SEARCH EMPLOYEES`
const EDIT_SETTING_TABLE_HEADER = `${MODULE_NAME} | EDIT SETTING TABLE HEADER`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshCurrentTableHeader() {
	return dispatch => {
		const apiUrl = API_URLS.currentTableHeader()
		dispatch({ type: REFRESHING_CURRENT_TABLE_HEADER, apiUrl })
		return api.appraisal_admin.appraisal_scoring.settings.getCurrentTableHeader().then(({ data: tableHeaders }) => {
			dispatch({ type: REFRESHED_CURRENT_TABLE_HEADER, data: tableHeaders, apiUrl })
			return tableHeaders
		})
	}
}

export function refreshSessionsSimple() {
	return dispatch => {
		const apiUrl = API_URLS.sessionsSimple()
		dispatch({ type: REFRESHING_SESSIONS_SIMPLE, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.getSessionsSimple().then(({ data: sessions }) => {
			dispatch({ type: REFRESHED_SESSIONS_SIMPLE, data: sessions, apiUrl })
			return sessions
		})
	}
}

function refreshSessionDetail(sessionId) {
	return dispatch => {
		const apiUrl = API_URLS.sessionDetail(sessionId)
		dispatch({ type: REFRESHING_SESSION_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.getSessionDetail(sessionId).then(({ data: session }) => {
			dispatch({ type: REFRESHED_SESSION_DETAIL, data: session, apiUrl })
			return session
		})
	}
}

function refreshSessionGroups(sessionId) {
	return dispatch => {
		const apiUrl = API_URLS.sessionGroups(sessionId)
		dispatch({ type: REFRESHING_SESSION_GROUPS, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups
			.getSessionGroups(sessionId)
			.then(({ data: appraisalGroups }) => {
				dispatch({ type: REFRESHED_SESSION_GROUPS, data: appraisalGroups, apiUrl })
				return appraisalGroups
			})
	}
}

export function refreshSessionGroupStages(sessionId, groupId) {
	return dispatch => {
		const apiUrl = API_URLS.sessionGroupStages(sessionId, groupId)
		dispatch({ type: REFRESHING_SESSION_GROUP_STAGES, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.stages
			.get(sessionId, groupId)
			.then(({ data: groupStages }) => {
				dispatch({
					type: REFRESHED_SESSION_GROUP_STAGES,
					data: groupStages.map((groupStage, groupStageIndex) => {
						if (groupStageIndex === groupStages.length - 1) {
							return {
								...groupStage,
								appraiserTypes: groupStage.appraiserTypes.map((appraiserType, appraiserTypeIndex) => {
									if (appraiserTypeIndex === groupStage.appraiserTypes.length - 1) {
										return new AppraiserType({
											...appraiserType,
											proportion: appraiserType.proportion === null ? 1 : appraiserType.proportion, // set proportion for last appraiser type in last group stage to 1 if it hasn't been set
										})
									}
									return appraiserType
								}),
							}
						}
						return groupStage
					}),
					apiUrl,
				})
				return groupStages
			})
	}
}

export function refreshSessionGroupTemplate(sessionId, groupId) {
	return dispatch => {
		const apiUrl = API_URLS.sessionGroupTemplate(sessionId, groupId)
		dispatch({ type: REFRESHING_SESSION_GROUP_TEMPLATE, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups
			.getTemplate(sessionId, groupId)
			.then(({ data: templateWithQuestions }) => {
				dispatch({ type: REFRESHED_SESSION_GROUP_TEMPLATE, data: templateWithQuestions, apiUrl })
				return templateWithQuestions
			})
	}
}

export function refreshSessionEmployeesIndividualView(sessionId, groupId, page = 1) {
	return (dispatch, getState) => {
		const apiUrl = API_URLS.employeesIndividualView(sessionId, groupId)
		const {
			appraisalAdminScoring: {
				data: { nameOrNIKFilter },
			},
		} = getState()
		dispatch({ type: REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.getSessionEmployeesIndividualView(sessionId, groupId, page, nameOrNIKFilter)
			.then(({ data: employees, pagination }) => {
				dispatch({ type: REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW, data: employees, pagination, apiUrl })
				return employees
			})
	}
}

export function loadMoreEmployees(sessionId, groupId, nextPage) {
	return dispatch => {
		const apiUrl = API_URLS.employeesIndividualView(sessionId, groupId)
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.getSessionEmployeesIndividualView(sessionId, groupId, nextPage)
			.then(({ data, pagination }) => {
				dispatch({ type: LOADED_MORE_EMPLOYEES_INDIVIDUAL_VIEW, data, pagination, apiUrl })
			})
	}
}

export function refreshSessionEmployeesIndividualViewDetail(sessionId, groupId, employeeId) {
	return dispatch => {
		const apiUrl = API_URLS.employeesIndividualViewDetail(sessionId, groupId, employeeId)
		dispatch({ type: REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.getSessionEmployeesIndividualViewDetail(sessionId, groupId, employeeId)
			.then(({ data }) => {
				return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
					.getSnapshot(sessionId, groupId, employeeId)
					.then(snapshotResponse => {
						const { data: snapshotData } = snapshotResponse
						dispatch({
							type: REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL,
							data: {
								...data,
								questions: data.questions.map(question => {
									return {
										...question,
										..._.find(snapshotData, ['questionLinkId', question.questionLinkId]),
									}
								}),
							},
							apiUrl,
						})
					})
			})
	}
}

export function refreshSessionEmployeesCustomView(sessionId, groupId, groupType, page = 1) {
	return (dispatch, getState) => {
		const apiUrl = API_URLS.employeesCustomView(sessionId, groupId, groupType)
		const {
			appraisalAdminScoring: {
				data: { nameOrNIKFilter },
			},
		} = getState()
		dispatch({ type: REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
			.getSessionEmployeesCustomView(sessionId, groupId, groupType, page, nameOrNIKFilter)
			.then(({ data: employeesWithScoreCardStatus, pagination }) => {
				dispatch({
					type: REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW,
					data: employeesWithScoreCardStatus,
					apiUrl,
					pagination,
				})
				return employeesWithScoreCardStatus
			})
	}
}

export function refreshSessionEmployeesCustomViewDetail(sessionId, groupId, employeeId, groupType) {
	return dispatch => {
		const apiUrl = API_URLS.employeesCustomViewDetail(sessionId, groupId, employeeId, groupType)
		dispatch({ type: REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
			.getSessionEmployeesCustomViewDetail(sessionId, groupId, employeeId, groupType)
			.then(({ data: appraiseesCustomEmployeeScoring }) => {
				return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
					.getSnapshot(sessionId, groupId, employeeId, groupType)
					.then(({ data: snapshotsWithEmployeeIdList }) => {
						dispatch({
							type: REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL,
							data: {
								...appraiseesCustomEmployeeScoring,
								appraisees: appraiseesCustomEmployeeScoring.appraisees.map(appraisee => {
									let employeeSnapshotData = _.find(snapshotsWithEmployeeIdList, [
										'employeeId',
										appraisee.appraisee.employee.id,
									])
									if (employeeSnapshotData) {
										return {
											...appraisee,
											questions: appraisee.questions.map(question => {
												return {
													...question,
													..._.find(employeeSnapshotData.snapshot, ['questionLinkId', question.questionLinkId]),
												}
											}),
										}
									}
									return appraisee
								}),
							},
							apiUrl,
						})
						return appraiseesCustomEmployeeScoring
					})
			})
	}
}

export function refreshTemplate(sessionId, templateId) {
	return dispatch => {
		const apiUrl = API_URLS.template(sessionId, templateId)
		dispatch({ type: REFRESHING_TEMPLATE, apiUrl })
		return api.appraisal_admin.appraisal_scoring.sessions.templates
			.getDetail(sessionId, templateId)
			.then(({ data: templateWithQuestion }) => {
				dispatch({ type: REFRESHED_TEMPLATE, data: templateWithQuestion, apiUrl })
				return templateWithQuestion
			})
	}
}

export function editProportion(sessionId, groupId, appraiserTypeId, proportion) {
	return dispatch => {
		dispatch({ type: EDITED_PROPORTION, sessionId, groupId, appraiserTypeId, proportion })
		return api.appraisal_admin.appraisal_scoring.sessions.groups.group_stage_appraiser_types.editProportion(
			sessionId,
			groupId,
			appraiserTypeId,
			proportion
		)
	}
}

export function selectSession(sessionId) {
	return dispatch => {
		dispatch(push(`/admin_appraisal/scoring/${sessionId}`))
		dispatch(changeNameOrNIKFilter(''))
		dispatch({ type: SELECTED_SESSION, sessionId })
		dispatch(refreshSessionDetail(sessionId))
		dispatch(refreshSessionGroups(sessionId)).then(groups => {
			groups.forEach(group => {
				dispatch(changeGroupView(sessionId, group.id, GROUP_TYPES.INDIVIDUAL))
				dispatch(refreshSessionGroupStages(sessionId, group.id))
			})
		})
	}
}

export function changeGroupView(sessionId, groupId, groupType = GROUP_TYPES.INDIVIDUAL) {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: { api },
		} = getState()
		dispatch({ type: CHANGED_GROUP_VIEW, groupId, viewCode: groupType })
		dispatch(unselectGroup(groupId))
		if (groupType === GROUP_TYPES.INDIVIDUAL) {
			const employees = getObjectOrUndefined(api, API_URLS.employeesIndividualView(sessionId, groupId))
			if (employees) {
				const employeeIdsInTheGroup = employees.map(employee => employee.id)
				employeeIdsInTheGroup.forEach(employeeId => dispatch(unselectEmployee(employeeId)))
			}
			dispatch(refreshSessionEmployeesIndividualView(sessionId, groupId))
		} else {
			const customEmployees = getObjectOrUndefined(api, API_URLS.employeesCustomView(sessionId, groupId, groupType))
			if (customEmployees) {
				const employeeIdsInTheGroup = customEmployees.map(employee => employee.id)
				employeeIdsInTheGroup.forEach(employeeId => dispatch(unselectCustomEmployee(employeeId)))
			}
			dispatch(refreshSessionEmployeesCustomView(sessionId, groupId, groupType))
		}
	}
}

export function selectEmployee(employeeId) {
	return dispatch => {
		dispatch({ type: SELECT_EMPLOYEE, employeeId })
	}
}

export function unselectEmployee(employeeId) {
	return dispatch => {
		dispatch({ type: UNSELECT_EMPLOYEE, employeeId })
	}
}

export function selectCustomEmployee(employeeId) {
	return dispatch => {
		dispatch({ type: SELECT_CUSTOM_EMPLOYEE, employeeId })
	}
}

export function unselectCustomEmployee(employeeId) {
	return dispatch => {
		dispatch({ type: UNSELECT_CUSTOM_EMPLOYEE, employeeId })
	}
}

export function selectGroup(groupId) {
	return dispatch => {
		dispatch({ type: SELECT_GROUP, groupId })
	}
}

export function unselectGroup(groupId) {
	return dispatch => {
		dispatch({ type: UNSELECT_GROUP, groupId })
	}
}

export function expandGroups(groupIds) {
	return dispatch => {
		dispatch({ type: EXPAND_GROUPS, groupIds })
	}
}

export function collapseGroups(groupIds) {
	return dispatch => {
		dispatch({ type: COLLAPSE_GROUPS, groupIds })
	}
}

export function moveEmployeesToExistingGroup(sessionId, employeeIds, groupId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions
			.moveEmployeesToExistingGroup(sessionId, employeeIds, groupId)
			.then(() => {
				dispatch({ type: MOVE_EMPLOYEES_TO_EXISTING_GROUP })
				dispatch(refreshSessionGroups(sessionId)).then(groups => {
					groups.forEach(group => {
						dispatch(refreshSessionEmployeesIndividualView(sessionId, group.id))
					})
				})
				toast('Successfully moved employees')
			})
	}
}

export function moveEmployeesToNewGroup(sessionId, employeeIds, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions
			.moveEmployeesToNewGroup(sessionId, employeeIds, data)
			.then(() => {
				dispatch({ type: MOVE_EMPLOYEES_TO_NEW_GROUP })
				dispatch(reset('new-scoring-group'))
				dispatch(refreshSessionGroups(sessionId)).then(groups => {
					groups.forEach(group => {
						dispatch(refreshSessionGroupStages(sessionId, group.id))
						dispatch(refreshSessionEmployeesIndividualView(sessionId, group.id))
					})
				})
				toast('Successfully moved employees')
			})
	}
}

export function selectAll() {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: {
				api,
				data: { groupViewDict, selectedSessionId },
			},
		} = getState()
		const groupsUrl = `/api/appraisal_admin/appraisal_scoring/sessions/${selectedSessionId}/groups/`
		const groups = getObjectOrUndefined(api, groupsUrl)

		groups.forEach(group => {
			if (groupViewDict[group.id] === GROUP_TYPES.INDIVIDUAL) {
				const employeesUrl = `/api/appraisal_admin/appraisal_scoring/sessions/${selectedSessionId}/groups/individual/${
					group.id
				}/employees/`
				const employees = getObjectOrUndefined(api, employeesUrl)
				employees.forEach(employee => dispatch(selectEmployee(employee.id)))
			} else {
				const superiorsUrl = `/api/appraisal_admin/appraisal_scoring/sessions/${selectedSessionId}/groups/superior/${
					group.id
				}/employees/`
				const superiors = getObjectOrUndefined(api, superiorsUrl)
				superiors.forEach(superior => dispatch(selectCustomEmployee(superior.id)))
			}
		})

		dispatch({ type: SELECT_ALL })
	}
}

export function unselectAll() {
	return dispatch => {
		dispatch({ type: UNSELECT_ALL })
	}
}

export function updateSnapshot(sessionId, groupId, employeeId, answer) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.update(sessionId, groupId, employeeId, answer)
			.then(({ data }) => {
				dispatch({ type: UPDATED_SINGLE_SNAPSHOT, data })
			})
	}
}

export function saveProgress(sessionId, groupId, employeeId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.saveProgress(sessionId, groupId, employeeId)
			.then(() => {
				dispatch(refreshSessionEmployeesIndividualView(sessionId, groupId))
				dispatch(refreshSessionEmployeesIndividualViewDetail(sessionId, groupId, employeeId))
				dispatch({ type: SAVED_PROGRESS_INDIVIDUAL })
				toast(`Successfully saved score card`)
			})
	}
}

export function saveProgressCustom(sessionId, groupId, employeeId, groupType) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
			.saveProgress(sessionId, groupId, employeeId, groupType)
			.then(() => {
				dispatch(refreshSessionEmployeesIndividualView(sessionId, groupId))
				dispatch(refreshSessionEmployeesCustomView(sessionId, groupId, groupType))
				dispatch(refreshSessionEmployeesCustomViewDetail(sessionId, groupId, employeeId, groupType))
				dispatch({ type: SAVED_PROGRESS_CUSTOM_VIEW })
				toast(`Successfully saved all score cards`)
			})
	}
}

export function cancel(sessionId, groupId, employeeId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.cancel(sessionId, groupId, employeeId)
			.then(() => {
				dispatch({ type: CANCELLED_INDIVIDUAL })
				toast(`Cancelled editing score card`)
			})
	}
}

export function cancelCustom(sessionId, groupId, employeeId, groupType) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
			.cancel(sessionId, groupId, employeeId, groupType)
			.then(() => {
				dispatch({ type: CANCELLED_CUSTOM_VIEW })
				toast(`Cancelled editing all score cards`)
			})
	}
}

export function submitIndividualForm(sessionId, groupId, employeeId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.individual
			.submit(sessionId, groupId, employeeId, data)
			.then(() => {
				dispatch(refreshSessionEmployeesIndividualView(sessionId, groupId))
				dispatch(refreshSessionEmployeesIndividualViewDetail(sessionId, groupId, employeeId))
				dispatch({ type: SUBMITTED_SESSION_APPRAISEES_INDIVIDUAL_VIEW })
				toast(`Successfully submitted score card`)
			})
	}
}

export function submitCustomForm(sessionId, groupId, employeeId, data, groupType) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.groups.custom
			.submit(sessionId, groupId, employeeId, data, groupType)
			.then(() => {
				if (groupType === GROUP_TYPES.SUMMARY) {
					dispatch(refreshSessionEmployeesIndividualView(sessionId, groupId))
				} else {
					dispatch(refreshSessionEmployeesCustomView(sessionId, groupId, groupType))
				}
				dispatch(refreshSessionEmployeesCustomViewDetail(sessionId, groupId, employeeId, groupType))
				dispatch({ type: SUBMITTED_EMPLOYEES_CUSTOM_VIEW })
				toast(`Successfully submitted all score cards`)
			})
	}
}

export function exportAllPdfZip(sessionId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.exportAllPdfZip(sessionId).then(({ data }) => {
			dispatch({ type: EXPORTED_ALL_PDF_ZIP, data })
			return data
		})
	}
}

export function exportSelectedScoreCardsZip(sessionId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: {
				data: { toBeExportedCustomEmployeeIds, selectedEmployeeIds },
			},
		} = getState()
		return api.appraisal_admin.appraisal_scoring.sessions
			.exportSelectedScoreCardsZip(sessionId, toBeExportedCustomEmployeeIds, selectedEmployeeIds, [])
			.then(({ data }) => {
				dispatch({ type: EXPORTED_SELECTED_SCORE_CARDS_ZIP })
				return data
			})
	}
}

export function exportAllExcelResume(sessionId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.exportAllExcelResume(sessionId).then(({ data }) => {
			dispatch({ type: EXPORTED_ALL_EXCEL_RESUME, data })
			return data
		})
	}
}

export function exportSelectedExcelResume(sessionId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: {
				data: { toBeExportedCustomEmployeeIds, selectedEmployeeIds },
			},
		} = getState()
		return api.appraisal_admin.appraisal_scoring.sessions
			.exportSelectedExcelResume(sessionId, toBeExportedCustomEmployeeIds, selectedEmployeeIds, [])
			.then(({ data }) => {
				dispatch({ type: EXPORTED_SELECTED_EXCEL_RESUME })
				return data
			})
	}
}

export function exportAllExcelRank(sessionId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_scoring.sessions.exportAllExcelRank(sessionId).then(({ data }) => {
			dispatch({ type: EXPORTED_ALL_EXCEL_RANK, data })
			return data
		})
	}
}

export function exportSelectedExcelRank(sessionId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: {
				data: { toBeExportedCustomEmployeeIds, selectedEmployeeIds },
			},
		} = getState()
		return api.appraisal_admin.appraisal_scoring.sessions
			.exportSelectedExcelRank(sessionId, toBeExportedCustomEmployeeIds, selectedEmployeeIds, [])
			.then(({ data }) => {
				dispatch({ type: EXPORTED_SELECTED_EXCEL_RANK })
				return data
			})
	}
}

export function editTemplateWeight(sessionId, groupId, data) {
	return dispatch => {
		let apiUrl = API_URLS.sessionGroupTemplate(sessionId, groupId)
		return api.appraisal_admin.appraisal_scoring.sessions.groups
			.editTemplateWeight(sessionId, groupId, data)
			.then(({ data: templateWithQuestion }) => {
				dispatch({ type: EDITED_TEMPLATE_WEIGHT, apiUrl, templateWithQuestion })
				return templateWithQuestion
			})
	}
}

export function searchEmployees(sessionId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminScoring: { api, data },
		} = getState()
		const groups = getObjectOrUndefined(api, API_URLS.sessionGroups(sessionId))
		const groupViewDict = getObjectOrUndefined(data, 'groupViewDict')
		groups.forEach(group => {
			let groupView = groupViewDict[group.id]
			if (groupView === 'individual') {
				dispatch(refreshSessionEmployeesIndividualView(sessionId, group.id))
			} else {
				dispatch(refreshSessionEmployeesCustomView(sessionId, group.id, groupView))
			}
		})
		dispatch({ type: SEARCH_EMPLOYEES })
	}
}

export function editSettingTableHeader(tableHeaders) {
	return dispatch => {
		const apiUrl = API_URLS.currentTableHeader()
		dispatch({ type: EDIT_SETTING_TABLE_HEADER, tableHeaders, apiUrl })
		return api.appraisal_admin.appraisal_scoring.settings.editSettingTableHeader(tableHeaders)
	}
}

export function changeNameOrNIKFilter(filter) {
	return dispatch => {
		dispatch({ type: CHANGE_NAME_OR_NIK_FILTER, filter })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshSessionsSimple())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		scoringView: 'GR', // GR: groups, IN: individual, SU: superior
		groupViewDict: {},
		selectedSessionId: undefined,
		expandedGroupIds: [],
		toBeExportedGroupIds: [],
		selectedEmployeeIds: [],
		toBeExportedCustomEmployeeIds: [],
		nameOrNIKFilter: '',
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_SESSIONS_SIMPLE:
		case REFRESHING_SESSION_DETAIL:
		case REFRESHING_SESSION_GROUPS:
		case REFRESHING_SESSION_GROUP_STAGES:
		case REFRESHING_SESSION_GROUP_TEMPLATE:
		case REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW:
		case REFRESHING_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL:
		case REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW:
		case REFRESHING_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL:
		case REFRESHING_TEMPLATE:
		case REFRESHING_CURRENT_TABLE_HEADER:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_SESSIONS_SIMPLE:
		case REFRESHED_SESSION_DETAIL:
		case REFRESHED_SESSION_GROUP_STAGES:
		case REFRESHED_SESSION_GROUP_TEMPLATE:
		case REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW_DETAIL:
		case REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW:
		case REFRESHED_SESSION_APPRAISEES_CUSTOM_VIEW_DETAIL:
		case REFRESHED_TEMPLATE:
		case REFRESHED_CURRENT_TABLE_HEADER:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_SESSION_GROUPS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				data: {
					...state.data,
					expandedGroupIds: [...state.data.expandedGroupIds, ...action.data.map(group => group.id)],
					groupViewDict: action.data.reduce((accGroup, currGroup) => {
						return {
							...accGroup,
							[currGroup.id]: GROUP_TYPES.INDIVIDUAL,
						}
					}, {}),
				},
			}
		case REFRESHED_SESSION_APPRAISEES_INDIVIDUAL_VIEW:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case LOADED_MORE_EMPLOYEES_INDIVIDUAL_VIEW:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [...state.api[action.apiUrl], ...action.data],
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case SELECTED_SESSION:
			return {
				...state,
				data: {
					...state.data,
					selectedSessionId: action.sessionId,
					toBeExportedGroupIds: [],
					toBeExportedCustomEmployeeIds: [],
					selectedEmployeeIds: [],
				},
			}
		case CHANGED_GROUP_VIEW:
			return {
				...state,
				data: {
					...state.data,
					groupViewDict: {
						...state.data.groupViewDict,
						[action.groupId]: action.viewCode,
					},
				},
			}
		case SELECT_EMPLOYEE:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: _.union(state.data.selectedEmployeeIds, [action.employeeId]),
				},
			}
		case UNSELECT_EMPLOYEE:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: state.data.selectedEmployeeIds.filter(id => id !== action.employeeId),
				},
			}
		case SELECT_CUSTOM_EMPLOYEE:
			return {
				...state,
				data: {
					...state.data,
					toBeExportedCustomEmployeeIds: _.union(state.data.toBeExportedCustomEmployeeIds, [action.employeeId]),
				},
			}
		case UNSELECT_CUSTOM_EMPLOYEE:
			return {
				...state,
				data: {
					...state.data,
					toBeExportedCustomEmployeeIds: state.data.toBeExportedCustomEmployeeIds.filter(
						id => id !== action.employeeId
					),
				},
			}
		case SELECT_GROUP:
			return {
				...state,
				data: {
					...state.data,
					toBeExportedGroupIds: _.union(state.data.toBeExportedGroupIds, [action.groupId]),
				},
			}
		case UNSELECT_GROUP:
			return {
				...state,
				data: {
					...state.data,
					toBeExportedGroupIds: state.data.toBeExportedGroupIds.filter(id => id !== action.groupId),
				},
			}
		case EXPAND_GROUPS:
			return {
				...state,
				data: {
					...state.data,
					expandedGroupIds: _.union(state.data.expandedGroupIds, action.groupIds),
				},
			}
		case COLLAPSE_GROUPS:
			return {
				...state,
				data: {
					...state.data,
					expandedGroupIds: state.data.expandedGroupIds.filter(groupId => !action.groupIds.includes(groupId)),
				},
			}
		case UNSELECT_ALL:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: [],
					toBeExportedCustomEmployeeIds: [],
					toBeExportedGroupIds: [],
				},
			}
		case EDITED_PROPORTION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionGroupStages(action.sessionId, action.groupId)]: state.api[
						API_URLS.sessionGroupStages(action.sessionId, action.groupId)
					].map(groupStage => {
						return {
							...groupStage,
							appraiserTypes: groupStage.appraiserTypes.map(appraiserType => {
								if (appraiserType.id === action.appraiserTypeId) {
									return {
										...appraiserType,
										proportion: action.proportion,
									}
								}
								return appraiserType
							}),
						}
					}),
				},
			}
		case EDITED_TEMPLATE_WEIGHT:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.templateWithQuestion,
				},
			}
		case CHANGE_NAME_OR_NIK_FILTER:
			return {
				...state,
				data: {
					...state.data,
					nameOrNIKFilter: action.filter,
				},
			}
		case EDIT_SETTING_TABLE_HEADER:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [...action.tableHeaders],
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
