import { s, bool, number, string } from 'modules/types'

export const COMMENT_PERMISSION = {
	NEVER: 0,
	ONCE: 1,
	MULTIPLE: 2,
}

export const POST_PERMISSION = {
	NEVER: 0,
	ALLOW: 1,
	ONLY_IF_LAST_REVIEWER: 2,
}

export const AWARD_PERMISSION = {
	NEVER: 0,
	ALLOW: 1,
	ONLY_IF_LAST_REVIEWER: 2,
	AUTO_AFTER_COMMENT: 3,
	AUTO_AFTER_FORWARD: 4,
	AUTO_AFTER_POST: 5,
}

export class IdeaFlow {
	constructor({
		id,
		name,
		level,
		comment_permission: commentPermission,
		post_permission: postPermission,
		like_permission: likePermission,
		flower_permission: flowerPermission,
		star_permission: starPermission,
		allowed_to_forward: allowedToForward,
	}) {
		this.id = id
		this.name = name
		this.level = level
		this.commentPermission = commentPermission
		this.postPermission = postPermission
		this.likePermission = likePermission
		this.flowerPermission = flowerPermission
		this.starPermission = starPermission
		this.allowedToForward = allowedToForward
	}
}

export const IdeaFlowType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
	commentPermission: number.isRequired,
	postPermission: number.isRequired,
	likePermission: number.isRequired,
	flowerPermission: number.isRequired,
	starPermission: number.isRequired,
	allowedToForward: bool.isRequired,
})

export default IdeaFlow
