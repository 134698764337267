import AllRequest from './AllRequest'
import AllRequestFilter from './AllRequestFilter'
import DayCount from './DayCount'
import EmployeeBalance from './EmployeeBalance'
import EmployeeCalendar from './EmployeeCalendar'
import EmployeePeriodRequest from './EmployeePeriodRequest'
import EmployeeProfile from './EmployeeProfile'
import TimeoffCalendarFilter from './TimeoffCalendarFilter'
import TimeoffComment from './TimeoffComment'
import TimeoffFeed from './TimeoffFeed'
import TimeoffFeedWithCalendar from './TimeoffFeedWithCalendar'
import TimeoffType from './TimeoffType'
import TimeoffWallet from './TimeoffWallet'
import WalletLog from './WalletLog'
import WalletLogWithInitial from './WalletLogWithInitial'

export const WALLET_AUTO_ADJUSTMENT_REASON = {
	CANCEL: 1,
	PASSED_START_DATE: 2,
}

export const WALLET_LOG_TYPE = {
	ENTITLEMENT: 1, // given when entitlement date
	AUTO_ADJUSTMENT: 2, // when timeoff get cancelled, or approved but start date >= today
	MANUAL_ADJUSTMENT: 3, // when HR give manual adjustment
	TIMEOFF_DEDUCTED: 4, // daily
	INITIAL_BALANCE: 5, // balance from a one-time time off
}

export const TIMEOFF_REQUEST_STATUS = {
	PENDING: 1,
	APPROVED: 2,
	REJECTED: 3,
	CANCELLED: 4,
	TAKEN: 5,
}

export const CALENDAR_EVENT_DATE_TYPE = {
	PUBLIC_HOLIDAY: 1,
	TIMEOFF: 2,
}

export const ALL_REQUEST_FILTER_TYPE = {
	APPROVER: 1,
	DEPARTMENT: 2,
	GROUP: 3,
	TITLE: 4,
	SUPERIOR: 5,
	RANK: 6,
	TIMEOFF_TYPE: 7,
}

export const TIMEOFF_RULE_PERIOD = {
	DAILY: 1,
	WEEKLY: 2,
	MONTHLY: 3,
	ANNUALLY: 4,
}

export default {
	AllRequest,
	AllRequestFilter,
	DayCount,
	EmployeeBalance,
	EmployeeCalendar,
	EmployeePeriodRequest,
	EmployeeProfile,
	TimeoffCalendarFilter,
	TimeoffComment,
	TimeoffFeed,
	TimeoffFeedWithCalendar,
	TimeoffType,
	TimeoffWallet,
	WalletLog,
	WalletLogWithInitial,
}
