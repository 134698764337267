import { s, number, arrayOf } from 'modules/types'
import HolidayTypeSummary, { HolidayTypeSummaryType } from './HolidayTypeSummary'

export class HolidayYearSummary {
	constructor({ year, holiday_types: holidayTypes }) {
		this.year = year
		this.holidayTypes = holidayTypes.map(item => new HolidayTypeSummary(item))
	}
}

export const HolidayYearSummaryType = s({
	year: number.isRequired,
	holidayTypes: arrayOf(HolidayTypeSummaryType),
})

export default HolidayYearSummary
