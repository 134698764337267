import { s, number, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class TelegramRequest {
	constructor({ id, employee, new_number: newNumber, new_username: newUsername, new_chat_id: newChatId }) {
		this.id = id
		this.employee = new Employee(employee)
		this.newNumber = newNumber
		this.newUsername = newUsername
		this.newChatId = newChatId
	}
}

export const TelegramRequestType = s({
	id: number.isRequired,
	employee: EmployeeType.isRequired,
	newNumber: string.isRequired,
	newUsername: string.isRequired,
	newChatId: string.isRequired,
})

export default TelegramRequest
