import { s, number, string } from 'modules/types'

export class Group {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

export const GroupType = s({
	id: number.isRequired,
	name: string.isRequired,
})

export default Group
