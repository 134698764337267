import { s, number } from 'modules/types'
import EmployeeDetail, { EmployeeDetailType } from './EmployeeDetail'

export class Appraisee {
	constructor({ id, employee }) {
		this.id = id
		this.employee = new EmployeeDetail(employee)
	}
}

export const AppraiseeType = s({
	id: number.isRequired,
	employee: EmployeeDetailType,
})

export default Appraisee
