import Employee from './Employee'
import JobDescription from './JobDescription'
import JobInformation from './JobInformation'
import EmploymentHistory from './EmploymentHistory'
import Section from './Section'

export default {
	Employee,
	JobDescription,
	JobInformation,
	EmploymentHistory,
	Section,
}
