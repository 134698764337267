import { s, arrayOf, number, string } from 'modules/types'
import TimeoffSummaryRule, { TimeoffSummaryRuleType } from './TimeoffSummaryRule'
import AllowDelete, { AllowDeleteType } from './AllowDelete'

export class TimeoffSummary {
	constructor({
		id,
		order,
		name,
		timeoff_rules: timeoffRules,
		total_employees: totalEmployees,
		color,
		allow_delete: allowDelete,
	}) {
		this.id = id
		this.order = order
		this.name = name
		this.timeoffRules = timeoffRules.map(item => new TimeoffSummaryRule(item))
		this.totalEmployees = totalEmployees
		this.color = color
		this.allowDelete = new AllowDelete(allowDelete)
	}
}

export const TimeoffSummaryType = s({
	id: number.isRequired,
	name: string.isRequired,
	timeoffRules: arrayOf(TimeoffSummaryRuleType),
	totalEmployees: number.isRequired,
	color: number.isRequired, // TimeoffRule TIMEOFF_TYPE_COLOR
	allowDelete: AllowDeleteType,
})

export default TimeoffSummary
