import { s, arrayOf, bool, string, number } from 'modules/types'
import { Choice, ChoiceType } from './Choice'

export const QUESTION_TYPE = {
	CHECKBOX: 0,
	COMBOBOX: 1,
	DATE: 2,
	DATE_RANGE: 3,
	FILE: 4,
	MULTIPLE_CHOICE: 5,
	NUMBER: 6,
	TEXT: 7,
	MORE_DETAILS: 8,
}

export const QUESTION_TYPE_OPTIONS_WITH_MORE_DETAILS = [
	{ value: -1, content: 'Select type', disabled: true },
	...Object.keys(QUESTION_TYPE).map((key, index) => ({
		value: index,
		content: key
			.split('_')
			.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
			.join(' '),
	})),
]

export const QUESTION_TYPE_OPTIONS = QUESTION_TYPE_OPTIONS_WITH_MORE_DETAILS.slice(
	0,
	QUESTION_TYPE_OPTIONS_WITH_MORE_DETAILS.length - 1
)

export class Question {
	constructor({ id, name, type, order, is_default: isDefault = false, is_required: isRequired = false, choices }) {
		this.id = id
		this.name = name
		this.type = type
		this.order = order
		this.isDefault = isDefault
		this.isRequired = isRequired
		this.choices = choices.map(item => new Choice(item))
	}

	getQuestionTypeDisplay() {
		switch (this.type) {
			case QUESTION_TYPE.CHECKBOX:
				return 'Checkbox'
			case QUESTION_TYPE.COMBOBOX:
				return 'Combobox'
			case QUESTION_TYPE.RADIO:
				return 'Radio'
			case QUESTION_TYPE.DATE:
				return 'Date'
			case QUESTION_TYPE.DATE_RANGE:
				return 'Date Range'
			case QUESTION_TYPE.FILE:
				return 'File'
			case QUESTION_TYPE.MULTIPLE_CHOICE:
				return 'Multiple choices'
			case QUESTION_TYPE.NUMBER:
				return 'Number'
			case QUESTION_TYPE.TEXT:
				return 'Text'
			case QUESTION_TYPE.MORE_DETAILS:
				return 'Read only'
			default:
				throw new Error('Unhandled question type')
		}
	}
}

export const QuestionType = s({
	id: number.isRequired,
	name: string.isRequired,
	type: number.isRequired,
	order: number.isRequired,
	isDefault: bool.isRequired,
	isRequired: bool.isRequired,
	choices: arrayOf(ChoiceType).isRequired,
})

export default Question
