import { s, arrayOf, number, string } from 'modules/types'

export class UnaddedAppraiseeEmployee {
	constructor({
		id,
		NIK,
		name,
		peer_ids: peerIds,
		subordinate_ids: subordinateIds,
		superior_id: superiorId = null,
		second_superior_id: secondSuperiorId = null,
		rank_id: rankId,
		title_id: titleId,
		position_id: positionId,
		department_id: departmentId,
		group_id: groupId,
		group_leader_ids: groupLeaderIds,
		department_head_id: departmentHeadId = null,
		rank_supervisor_ids: rankSupervisorIds,
	}) {
		this.id = id
		this.NIK = NIK
		this.name = name
		this.peerIds = peerIds
		this.subordinateIds = subordinateIds
		this.superiorId = superiorId
		if (secondSuperiorId !== null) {
			this.secondSuperiorId = secondSuperiorId
		}
		this.rankId = rankId
		this.titleId = titleId
		this.positionId = positionId
		this.departmentId = departmentId
		this.groupId = groupId
		this.groupLeaderIds = groupLeaderIds
		if (departmentHeadId !== null) {
			this.departmentHeadId = departmentHeadId
		}
		this.rankSupervisorIds = rankSupervisorIds
	}
}

export const UnaddedAppraiseeEmployeeType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	name: string.isRequired,
	peerIds: arrayOf(number),
	subordinateIds: arrayOf(number),
	superiorId: number,
	secondSuperiorId: number,
	rankId: number,
	titleId: number,
	positionId: number,
	departmentId: number,
	groupId: number,
	groupLeaderIds: arrayOf(number),
	departmentHeadId: number,
})

export default UnaddedAppraiseeEmployee
