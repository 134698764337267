import { s, arrayOf, oneOfType, number, string } from 'modules/types'

class SubSessionAnswer {
	constructor({ questionLinkId: question_link_id, answer }) {
		this.question_link_id = question_link_id
		this.answer = answer
	}
}

const SubSessionAnswerType = s({
	question_link_id: number.isRequired,
	answer: oneOfType([number, string]),
})

// used for sheet view /submit/ and /save_progress/
class SubSessionsWithAnswers {
	constructor({ subSessionId: sub_session_id, answers }) {
		this.sub_session_id = sub_session_id
		this.answers = answers.map(item => new SubSessionAnswer(item))
	}
}

export const SubSessionsWithAnswersType = s({
	sub_session_id: number.isRequired,
	answers: arrayOf(SubSessionAnswerType),
})

export default SubSessionsWithAnswers
