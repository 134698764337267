import { s, string, number, object } from 'modules/types'

export class Employee {
	constructor({ id, name, birth_date: birthDate, picture = null }) {
		this.id = id
		this.name = name
		this.birthDate = new Date(birthDate * 1000)
		if (picture !== null) {
			this.picture = picture
		}
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	birthDate: object.isRequired,
	picture: string,
})

export default Employee
