import { s, bool, number } from 'modules/types'
import ExperienceType, { ExperienceTypeType } from './ExperienceType'

export class ExperienceWalletSummary {
	constructor({
		id,
		experience_type: experienceType,
		balance,
		this_month_balance: thisMonthBalance,
		is_active: isActive,
	}) {
		this.id = id
		this.experienceType = new ExperienceType(experienceType)
		this.balance = balance
		this.thisMonthBalance = thisMonthBalance
		this.isActive = isActive
	}
}

export const ExperienceWalletSummaryType = s({
	id: number.isRequired,
	experienceType: ExperienceTypeType,
	balance: number.isRequired,
	thisMonthBalance: number.isRequired,
	isActive: bool.isRequired,
})

export default ExperienceWalletSummary
