import { s, bool, number } from 'modules/types'
import ExperienceTypeSimple, { ExperienceTypeSimpleType } from './ExperienceTypeSimple'

export class ExperienceWalletSummary {
	constructor({ id, type, balance, is_active: isActive }) {
		this.id = id
		this.type = new ExperienceTypeSimple(type)
		this.balance = balance
		this.isActive = isActive
	}
}

export const ExperienceWalletSummaryType = s({
	id: number.isRequired,
	type: ExperienceTypeSimpleType,
	balance: number.isRequired,
	isActive: bool.isRequired,
})

export default ExperienceWalletSummary
