import { s, bool, number } from 'modules/types'

export const TIMEOFF_RULE_PERIOD = {
	DAILY: 1,
	WEEKLY: 2,
	MONTHLY: 3,
	ANNUALLY: 4,
}

export class TimeoffRule {
	constructor({
		id,
		type_id: typeId,
		allowance,
		period,
		max_accumulation: maxAccumulation,
		allow_over_limit: allowOverLimit,
	}) {
		this.id = id
		this.typeId = typeId
		this.allowance = allowance
		this.period = period
		this.maxAccumulation = maxAccumulation
		this.allowOverLimit = allowOverLimit
	}
}

export const TimeoffRuleType = s({
	id: number.isRequired,
	typeId: number.isRequired,
	allowance: number,
	period: number, // @TIMEOFF_RULE_PERIOD
	maxAccumulation: number,
	allowOverLimit: bool.isRequired,
})

export default TimeoffRule
