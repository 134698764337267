import AddGroupCustomTemplate from './AddGroupCustomTemplate'
import AppraisalGroup from './AppraisalGroup'
import Appraisee from './Appraisee'
import AppraiserType from './AppraiserType'
import CategorizeOption from './CategorizeOption'
import DefaultTemplate from './DefaultTemplate'
import EditGroupData from './EditGroupData'
import Employee from './Employee'
import EmployeeExternal from './EmployeeExternal'
import EmployeeSimple from './EmployeeSimple'
import FilterItem from './FilterItem'
import Group from './Group'
import GroupWithAppraisees from './GroupWithAppraisees'
import GroupStage from './GroupStage'
import Session from './Session'
import SessionDetail from './SessionDetail'
import Stage from './Stage'
import SubSession from './SubSession'
import Template from './Template'
import UnaddedAppraiseeEmployee from './UnaddedAppraiseeEmployee'

export const APPRAISER_TYPE = {
	SELF: 0,
	PEER: 1,
	SUPERIOR: 2,
	SECOND_DEGREE_SUPERIOR: 3,
	SUBORDINATE: 4,
	OTHERS: 5,
	EXTERNAL: 6,
	GROUP_LEADER: 7,
	DEPARTMENT_HEAD: 8,
	RANK_SUPERVISOR: 9,
}

export const APPRAISAL_SESSION_STATUS = {
	STOPPED: 0,
	STARTED: 1,
	DRAFT: 2,
}

export const QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
}

export const APPRAISAL_SESSION_CATEGORIZE = {
	CUSTOM: 1,
	TITLE: 2,
	RANK: 3,
	POSITION: 4,
	GROUP: 5,
	SUPERIOR: 6,
	DEPARTMENT: 7,
}

export default {
	AddGroupCustomTemplate,
	AppraisalGroup,
	Appraisee,
	AppraiserType,
	CategorizeOption,
	DefaultTemplate,
	EditGroupData,
	Employee,
	EmployeeExternal,
	EmployeeSimple,
	FilterItem,
	Group,
	GroupStage,
	GroupWithAppraisees,
	Session,
	SessionDetail,
	Stage,
	SubSession,
	Template,
	UnaddedAppraiseeEmployee,
}
