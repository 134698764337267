import { s, number, string } from 'modules/types'

export class RankSimple {
	constructor({ id, name, order }) {
		this.id = id
		this.name = name
		this.order = order
	}
}

export const RankSimpleType = s({
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
})

export default RankSimple
