import { s, number, string } from 'modules/types'

export class IdeaFlow {
	constructor({ id, name, level }) {
		this.id = id
		this.name = name
		this.level = level
	}
}

export const IdeaFlowType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
})

export default IdeaFlow
