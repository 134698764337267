import { s, number } from 'modules/types'

export class IdeaFlowColumn {
	constructor({
		idea_flow_id: ideaFlowId,
		reviewer_id: reviewerId,
		reviewer_type: reviewerType = null,
		group_role_id: groupRoleId = null,
		rank_id: rankId = null,
	}) {
		this.ideaFlowId = ideaFlowId
		this.reviewerId = reviewerId
		if (reviewerType !== null) {
			this.reviewerType = reviewerType
		}
		if (groupRoleId !== null) {
			this.groupRoleId = groupRoleId
		}
		if (rankId !== null) {
			this.rankId = rankId
		}
	}
}

export const IdeaFlowColumnType = s({
	ideaFlowId: number.isRequired,
	reviewerId: number,
	reviewerType: number,
	groupRoleId: number,
	rankId: number,
})

export default IdeaFlowColumn
