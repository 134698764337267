import { s, number } from 'modules/types'
import AnswerSnapshot, { AnswerSnapshotType } from './AnswerSnapshot'

export class IndividualSnapshot {
	constructor({ appraisee_id: appraiseeId, snapshot }) {
		this.appraiseeId = appraiseeId
		this.snapshot = AnswerSnapshot(snapshot)
	}
}

export const IndividualSnapshotType = s({
	appraiseeId: number.isRequired,
	snapshot: AnswerSnapshotType,
})

export default IndividualSnapshot
