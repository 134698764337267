import { s, arrayOf, number, string } from 'modules/types'
import Category, { CategoryType } from './Category'

export class TemplateWithQuestion {
	constructor({ id, name, description, categories }) {
		this.id = id
		this.name = name
		this.description = description
		this.categories = categories.map(item => new Category(item))
	}
}

export const TemplateWithQuestionType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	categories: arrayOf(CategoryType),
})

export default TemplateWithQuestion
