import { s, bool, number } from 'modules/types'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'

export class TimeoffWallet {
	constructor({ id, timeoff_type: timeoffType, current_balance: currentBalance, allow_over_limit: allowOverLimit }) {
		this.id = id
		this.timeoffType = new TimeoffType(timeoffType)
		this.currentBalance = currentBalance
		this.allowOverLimit = allowOverLimit
	}
}

export const TimeoffWalletType = s({
	id: number.isRequired,
	timeoffType: TimeoffTypeType,
	currentBalance: number.isRequired,
	allowOverLimit: bool.isRequired,
})

export default TimeoffWallet
