import { s, number, string } from 'modules/types'

export class AnnouncementCreator {
	constructor({ id, name, title_name, picture_url }) {
		this.id = id
		this.name = name
		this.title_name = title_name
		this.picture_url = picture_url
	}
}

export const AnnouncementType = s({
	id: number.isRequired,
	name: string,
	title_name: string,
	picture_url: string,
})

export default AnnouncementCreator
