import React from 'react'
import PropTypes from 'prop-types'
// creates a beautiful scrollbar
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import PagesHeader from 'components/PagesHeader'
import Footer from 'newComponents/Footer/Footer.jsx'

import Login from 'views/Login'

import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx'

import bgImage from 'assets/img/register.jpeg'
import nphImage from 'assets/img/nph/nph_1.png'

// var ps;

class LoginLayout extends React.Component {
	static getBackgroundImage() {
		// TODO: currently all test env use nph image, later change to nph.test.skleem.co
		if (
			window.location.host === 'nph.skleem.co' ||
			window.location.host === 'nphdemo.skleem.co' ||
			window.location.host === 'test.skleem.co' ||
			window.location.host === 'localhost:3000'
		) {
			return nphImage
		}
		return bgImage
	}

	render() {
		const { classes, ...rest } = this.props
		return (
			<div>
				<PagesHeader {...rest} />
				<div className={classes.wrapper} ref="wrapper">
					<div className={classes.fullPage}>
						<Login />
						<Footer white />
						<div
							className={classes.fullPageBackground}
							style={{ backgroundImage: 'url(' + LoginLayout.getBackgroundImage() + ')' }}
						/>
					</div>
				</div>
			</div>
		)
	}
}

LoginLayout.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(pagesStyle)(LoginLayout)
