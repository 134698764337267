import { s, bool, number, object, string } from 'modules/types'

class SnapshotEmployee {
	constructor({ id, name, full_name: fullName }) {
		this.id = id
		this.name = name || fullName
	}
}

const SnapshotEmployeeType = s({
	id: number.isRequired,
	name: string,
})

class SnapshotTimeoffType {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const SnapshotTimeoffTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
})

class SnapshotTimeoffWallet {
	constructor({ id, balance, type }) {
		this.id = id
		this.balance = balance
		this.type = new SnapshotTimeoffType(type)
	}
}

const SnapshotTimeoffWalletType = s({
	id: number.isRequired,
	balance: number.isRequired,
	type: SnapshotTimeoffTypeType,
})

class SnapshotTimeoffRequest {
	constructor({
		id,
		employee,
		status,
		balance_taken: balanceTaken,
		approved_at: approvedAt,
		approved_by: approvedBy,
		rejected_at: rejectedAt,
		rejected_by: rejectedBy,
		wallet,
		next_approver: nextApprover,
		start_date: startDate,
		start_half_day: startHalfDay,
		end_date: endDate,
		end_half_day: endHalfDay,
		added,
	}) {
		this.id = id
		if (employee) {
			this.employee = new SnapshotEmployee(employee)
		}
		this.status = status
		this.balanceTaken = balanceTaken
		if (approvedAt) {
			this.approvedAt = new Date(approvedAt * 1000)
		}
		if (approvedBy) {
			this.approvedBy = new SnapshotEmployee(approvedBy)
		}
		if (rejectedAt) {
			this.rejectedAt = new Date(rejectedAt * 1000)
		}
		if (rejectedBy) {
			this.rejectedBy = new SnapshotEmployee(rejectedBy)
		}
		if (wallet) {
			this.wallet = new SnapshotTimeoffWallet(wallet)
		}
		if (nextApprover) {
			this.nextApprover = new SnapshotEmployee(nextApprover)
		}
		if (startDate) {
			this.startDate = new Date(startDate)
		}
		this.startHalfDay = startHalfDay
		if (endDate) {
			this.endDate = new Date(endDate)
		}
		this.endHalfDay = endHalfDay
		this.added = new Date(added * 1000)
	}
}

const SnapshotTimeoffRequestType = s({
	id: number.isRequired,
	employee: SnapshotEmployeeType,
	status: number,
	balanceTaken: number,
	approvedAt: object,
	approvedBy: SnapshotEmployeeType,
	rejectedAt: object,
	rejectedBy: SnapshotEmployeeType,
	wallet: SnapshotTimeoffWalletType,
	nextApprover: SnapshotEmployeeType,
	startDate: object,
	startHalfDay: bool,
	endDate: object,
	endHalfDay: bool,
	added: object,
})

export class TimeoffRequestLogExtInfo {
	constructor({
		timeoff_request: timeoffRequest,
		start_date: startDate,
		date_deducted: dateDeducted,
		deducted_count: deductedCount,
		end_date: endDate,
		wallet_id: walletId,
		prev_balance_taken: prevBalanceTaken,
		new_balance_taken: newBalanceTaken,
		prev_wallet_balance: prevWalletBalance,
		new_wallet_balance: newWalletBalance,
		date_added: dateAdded,
		added_count: addedCount,
		approved_by: approvedBy,
		forwarded_to: forwardedTo,
		forwarded_to_id: forwardedToId,
		approved_at: approvedAt,
		rejected_by: rejectedBy,
		rejected_at: rejectedAt,
		old_request_snapshot: oldRequestSnapshot,
		new_request_snapshot: newRequestSnapshot,
		cancelled_at: cancelledAt,
	}) {
		if (timeoffRequest) {
			this.timeoffRequest = new SnapshotTimeoffRequest(timeoffRequest)
		}
		if (startDate) {
			this.startDate = new Date(startDate)
		}
		if (dateDeducted) {
			this.dateDeducted = new Date(dateDeducted)
		}
		this.deductedCount = deductedCount
		if (endDate) {
			this.endDate = new Date(endDate)
		}
		this.walletId = walletId
		this.prevBalanceTaken = prevBalanceTaken
		this.newBalanceTaken = newBalanceTaken
		this.prevWalletBalance = prevWalletBalance
		this.newWalletBalance = newWalletBalance
		if (dateAdded) {
			this.dateAdded = new Date(dateAdded * 1000)
		}
		this.addedCount = addedCount
		if (approvedBy) {
			this.approvedBy = new SnapshotEmployee(approvedBy)
		}
		if (forwardedTo) {
			this.forwardedTo = new SnapshotEmployee(forwardedTo)
		}
		if (forwardedToId) {
			this.forwardedTo = new SnapshotEmployee(forwardedToId)
		}
		if (approvedAt) {
			this.approvedAt = new Date(approvedAt * 1000)
		}
		if (rejectedBy) {
			this.rejectedBy = new SnapshotEmployee(rejectedBy)
		}
		if (rejectedAt) {
			this.rejectedAt = new Date(rejectedAt * 1000)
		}
		if (oldRequestSnapshot) {
			this.oldRequestSnapshot = new SnapshotTimeoffRequest(oldRequestSnapshot)
		}
		if (newRequestSnapshot) {
			this.newRequestSnapshot = new SnapshotTimeoffRequest(newRequestSnapshot)
		}
		if (cancelledAt) {
			this.cancelledAt = new Date(cancelledAt * 1000)
		}
	}
}

export const TimeoffRequestLogExtInfoType = s({
	timeoffRequest: SnapshotTimeoffRequestType,
	startDate: object,
	dateDeducted: object,
	deductedCount: number,
	endDate: object,
	walletId: number,
	prevBalanceTaken: number,
	newBalanceTaken: number,
	prevWalletBalance: number,
	newWalletBalance: number,
	dateAdded: object,
	addedCount: number,
	approvedBy: SnapshotEmployeeType,
	forwardedTo: SnapshotEmployeeType,
	approvedAt: object,
	rejectedBy: SnapshotEmployeeType,
	rejectedAt: object,
	oldRequestSnapshot: SnapshotTimeoffRequestType,
	newRequestSnapshot: SnapshotTimeoffRequestType,
	cancelledAt: object,
})

export default TimeoffRequestLogExtInfo
