import { s, number, string } from 'modules/types'
import { AnswerChoice, AnswerChoiceType } from 'modules/models/survey_admin/AnswerChoice'

export class AnswerStatistic {
	constructor({ answer_choice: answerChoice, respondent, percentage, employee_name: employeeName, answer }) {
		if (answerChoice) {
			this.answerChoice = new AnswerChoice(answerChoice)
		}
		this.respondent = respondent
		this.percentage = percentage
		this.employeeName = employeeName
		this.answer = answer
	}
}

export const AnswerStatisticType = s({
	answerChoice: AnswerChoiceType,
	respondent: number,
	percentage: string,
	employeeName: string,
	answer: string,
})

export default AnswerStatistic
