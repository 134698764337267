import { s, bool, number } from 'modules/types'
import DetailRuleType, { TimeoffGroupDetailRuleTypeType } from './TimeoffGroupDetailRuleType'
import { TIMEOFF_RULE_PERIOD } from './index'

export class TimeoffGroupDetailRule {
	constructor({
		id,
		type,
		period,
		allowance,
		max_accumulation: maxAccumulation,
		allow_over_limit: allowOverLimit,
		initial_balance: initialBalance,
	}) {
		this.id = id
		this.type = new DetailRuleType(type)
		this.period = period
		this.allowance = allowance
		this.maxAccumulation = maxAccumulation
		this.allowOverLimit = allowOverLimit
		this.initialBalance = initialBalance
	}

	displayPeriod() {
		switch (this.period) {
			case TIMEOFF_RULE_PERIOD.DAILY:
				return 'DAILY'
			case TIMEOFF_RULE_PERIOD.WEEKLY:
				return 'WEEKLY'
			case TIMEOFF_RULE_PERIOD.MONTHLY:
				return 'MONTHLY'
			case TIMEOFF_RULE_PERIOD.ANNUALLY:
				return 'ANNUALLY'
			default:
				throw new Error('Unknown period for timeoff rule')
		}
	}
}

export const TimeoffGroupDetailRuleType = s({
	id: number.isRequired,
	type: TimeoffGroupDetailRuleTypeType,
	period: number.isRequired,
	allowance: number,
	maxAccumulation: number,
	allowOverLimit: bool,
	initialBalance: number,
})

export default TimeoffGroupDetailRule
