import { s, number, string } from 'modules/types'

export class Section {
	constructor({ title, content, order }) {
		this.title = title
		this.content = content
		this.order = order
	}
}

export const SectionType = s({
	title: string.isRequired,
	content: string.isRequired,
	order: number.isRequired,
})

export default Section
