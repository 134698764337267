import React from 'react'
import store from '../store/createStore'
import { Icon } from '@blueprintjs/core'
import { showToast } from '../store/toastr'
import { QUESTION_TYPE, TIMEOFF_TYPE_COLOR } from 'modules/constants'
import { APPRAISER_TYPE } from 'modules/models/appraisal_admin/session'
import { errorDict, errorRegexes } from 'modules/errors'
import datefnsFormat from 'date-fns/format'
import enLocale from 'date-fns/locale/en'
import idLocale from 'date-fns/locale/id'
import _ from 'lodash'

const locales = {
	id: idLocale,
	en: enLocale,
}

function format(dateObject, format = 'DD MMMM YYYY') {
	const {
		session: {
			frontendSetting: { language },
		},
	} = store.getState()
	return datefnsFormat(dateObject, format, { locale: locales[language] || enLocale })
}

function toast(message, props = {}) {
	store.dispatch(showToast(message, props))
	return
}

function translateError(errorMessage) {
	let translatedErrorMessage
	const settingState = store.getState().session.frontendSetting
	if (settingState) {
		const errorTranslationDict = getObjectOrUndefined(errorDict, errorMessage)
		// check if error message is simple string i.e. no dynamic variable
		if (errorTranslationDict) {
			translatedErrorMessage = getObjectOrUndefined(errorTranslationDict, settingState.language)
		} else {
			try {
				const errorRegexMatch = errorRegexes.find(errorTranslation => errorTranslation.regex.test(errorMessage))
				const translationFunc = getObjectOrUndefined(errorRegexMatch.translation, settingState.language)
				translatedErrorMessage = translationFunc(errorMessage.split(errorRegexMatch.regex).slice(1, -1))
			} catch (e) {
				console.error('ERROR: Missing translation')
			}
		}
	}
	return translatedErrorMessage ? translatedErrorMessage : errorMessage
}

export function toastErrors(errors) {
	if (typeof errors === 'string') {
		toast(translateError(errors), { color: 'danger' })
		return
	}
	try {
		errors = JSON.parse(errors[0])
		if (Array.isArray(errors)) {
			toast(errors.map(error => translateError(error)), { color: 'danger' })
		} else if (_.isPlainObject(errors)) {
			toast(Object.keys(errors).map(errorKey => `${errorKey}: ${errors['errorKey']}`), { color: 'danger' })
		}
		toast(translateError(errors), { color: 'danger' })
	} catch {
		toast(errors.map(error => translateError(error)), { color: 'danger' })
	}
}

function mapAppraiserTypeToDisplay(type) {
	switch (type) {
		case APPRAISER_TYPE.SELF:
			return 'SELF'
		case APPRAISER_TYPE.PEER:
			return 'PEER'
		case APPRAISER_TYPE.SECOND_DEGREE_SUPERIOR:
			return 'SECOND_DEGREE_SUPERIOR'
		case APPRAISER_TYPE.SUBORDINATE:
			return 'SUBORDINATE'
		case APPRAISER_TYPE.SUPERIOR:
			return 'SUPERIOR'
		case APPRAISER_TYPE.OTHERS:
			return 'OTHERS'
		case APPRAISER_TYPE.EXTERNAL:
			return 'EXTERNAL'
		case APPRAISER_TYPE.GROUP_LEADER:
			return 'GROUP_LEADER'
		case APPRAISER_TYPE.DEPARTMENT_HEAD:
			return 'DEPARTMENT_HEAD'
		case APPRAISER_TYPE.RANK_SUPERVISOR:
			return 'RANK_SUPERVISOR'
		default:
			throw new Error(type + 'Unhandled appraiser type')
	}
}

function mapQuestionTypeToDisplay(type) {
	switch (type) {
		case QUESTION_TYPE.RADIO:
			return 'Multiple choice'
		case QUESTION_TYPE.INPUT:
			return 'Short answer'
		case QUESTION_TYPE.TEXT:
			return 'Paragraph'
		case QUESTION_TYPE.LINEAR:
			return 'Linear scale'
		case QUESTION_TYPE.CHECKBOX:
			return 'Checkbox'
		default:
			throw new Error('Unhandled question type')
	}
}

function mapQuestionTypeToIcon(type, iconSize = 16) {
	let iconName = 'pt-icon-selection'

	switch (type) {
		case 0:
			iconName = 'pt-icon-selection'
			break
		case 1:
			iconName = 'pt-icon-italic'
			break
		case 2:
			iconName = 'pt-icon-paragraph'
			break
		case 3:
			iconName = 'pt-icon-layout-linear'
			break
		case 4:
			iconName = 'pt-icon-square'
			break
		default:
			throw new Error('Unhandled question type')
	}
	return <Icon iconSize={iconSize} iconName={iconName} />
}

function mapQuestionTypeToYOffset(type) {
	switch (type) {
		case QUESTION_TYPE.RADIO:
			return '-48px'
		case QUESTION_TYPE.INPUT:
			return '-1680px'
		case QUESTION_TYPE.TEXT:
			return '-1824px'
		case QUESTION_TYPE.LINEAR:
			return '-3240px'
		case QUESTION_TYPE.CHECKBOX:
			return '-2303px'
		default:
			throw new Error('Unhandled question type')
	}
}

function renderText(str) {
	if (str) {
		return str.split('\n').map((item, idx) => (
			<span key={idx}>
				{item}
				<br />
			</span>
		))
	}
	return null
}

function getColorHexCode(color) {
	switch (color) {
		case TIMEOFF_TYPE_COLOR.RED:
			return '#e74c3c'
		case TIMEOFF_TYPE_COLOR.GREEN:
			return '#2ecc71'
		case TIMEOFF_TYPE_COLOR.BLUE:
			return '#3498db'
		case TIMEOFF_TYPE_COLOR.YELLOW:
			return '#f1c40f'
		case TIMEOFF_TYPE_COLOR.ORANGE:
			return '#e67e22'
		case TIMEOFF_TYPE_COLOR.PINK:
			return '#f78fb3'
		case TIMEOFF_TYPE_COLOR.PURPLE:
			return '#be2edd'
		default:
			throw new Error(`Unknown timeoff type color: ${color}`)
	}
}

function getColorCode(hexCode) {
	switch (hexCode) {
		case '#e74c3c':
			return TIMEOFF_TYPE_COLOR.RED
		case '#2ecc71':
			return TIMEOFF_TYPE_COLOR.GREEN
		case '#3498db':
			return TIMEOFF_TYPE_COLOR.BLUE
		case '#f1c40f':
			return TIMEOFF_TYPE_COLOR.YELLOW
		case '#e67e22':
			return TIMEOFF_TYPE_COLOR.ORANGE
		case '#f78fb3':
			return TIMEOFF_TYPE_COLOR.PINK
		case '#be2edd':
		default:
			return TIMEOFF_TYPE_COLOR.PURPLE
	}
}

function isUndefinedOrEmptyArray(item) {
	return item === undefined || item.length === 0
}

function getObjectOrUndefined(obj, path, defaultValue = undefined) {
	if (!path) return obj
	path = path.split('.')
	for (let i = 0; i < path.length; i++) {
		if (obj === undefined) return defaultValue

		if (path[i] in obj) {
			obj = obj[path[i]]
		} else {
			return defaultValue
		}
	}
	return obj
}

function replaceObject(obj, path, data) {
	path = path.split('.')
	let tmp = obj
	for (let i = 0; i < path.length - 1; i++) {
		if (path[i] in obj) {
			obj = obj[path[i]]
		} else {
			obj[path[i]] = {}
		}
	}
	obj[path[path.length - 1]] = data
	return tmp
}

function setDocumentTitle(title) {
	document.title = `${title} | Skleem`
}

class Singleton {
	constructor(store, renderFunc) {
		this.store = store
		this.renderFunc = renderFunc
	}

	getInstance() {
		if (!this.obj) {
			this.obj = this.renderFunc()
		}
		return this.obj
	}
}

export function mockAPI(response) {
	return new Promise(function mockPromise(resolve) {
		setTimeout(function mockResolve() {
			resolve({ data: response })
		}, 100)
	})
}

export default {
	renderText,
	isUndefinedOrEmptyArray,
	format,
	getColorCode,
	getColorHexCode,
	getObjectOrUndefined,
	replaceObject,
	setDocumentTitle,
	mapAppraiserTypeToDisplay,
	mapQuestionTypeToDisplay,
	mapQuestionTypeToIcon,
	mapQuestionTypeToYOffset,
	mockAPI,
	toast,
	toastErrors,
	Singleton,
}
