import { s, number, string } from 'modules/types'

export class EmployeeWithGroup {
	constructor({ id, name, NIK, group_name: groupName }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.groupName = groupName
	}
}

export const EmployeeWithGroupType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	groupName: string,
})

export default EmployeeWithGroup
