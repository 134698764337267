import Contract from './Contract'

export const CONTRACT_STATUS = {
	VALID: 1,
	CANCELLED: 2,
}

export default {
	Contract,
}
