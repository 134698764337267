// ##############################
// // // Header styles
// #############################

import {
	containerFluid,
	defaultFont,
	primaryColor,
	defaultBoxShadow,
	infoColor,
	successColor,
	warningColor,
	dangerColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'

const headerStyle = theme => ({
	appBar: {
		backgroundColor: 'transparent',
		boxShadow: 'none',
		borderBottom: '0',
		marginBottom: '0',
		position: 'absolute',
		width: '100%',
		paddingTop: '10px',
		zIndex: '1029',
		color: '#555555',
		border: '0',
		borderRadius: '3px',
		padding: '10px 0',
		transition: 'all 150ms ease 0s',
		minHeight: '50px',
		display: 'block',
	},
	container: {
		...containerFluid,
		minHeight: '50px',
	},
	flex: {
		flex: 1,
	},
	title: {
		...defaultFont,
		lineHeight: '24px',
		fontSize: '12px',
		borderRadius: '3px',
		textTransform: 'uppercase',
		fontWeight: '500',
		color: '#555555',
		'&:hover,&:focus': {
			background: 'transparent',
		},
	},
	titleSelected: {
		...defaultFont,
		lineHeight: '24px',
		fontSize: '12px',
		borderRadius: '3px',
		textTransform: 'uppercase',
		fontWeight: '500',
		color: 'white',
		background: '#ffa726',
		'&:hover,&:focus': {
			background: '#ffa726',
		},
	},
	primary: {
		backgroundColor: primaryColor[0],
		color: '#FFFFFF',
		...defaultBoxShadow,
	},
	info: {
		backgroundColor: infoColor[0],
		color: '#FFFFFF',
		...defaultBoxShadow,
	},
	success: {
		backgroundColor: successColor[0],
		color: '#FFFFFF',
		...defaultBoxShadow,
	},
	warning: {
		backgroundColor: warningColor[0],
		color: '#FFFFFF',
		...defaultBoxShadow,
	},
	danger: {
		backgroundColor: dangerColor[0],
		color: '#FFFFFF',
		...defaultBoxShadow,
	},
	sidebarMinimize: {
		float: 'left',
		padding: '0 0 0 15px',
		display: 'block',
		color: '#555555',
	},
	sidebarMinimizeRTL: {
		padding: '0 15px 0 0 !important',
	},
	sidebarMiniIcon: {
		width: '20px',
		height: '17px',
	},
})

export default headerStyle
