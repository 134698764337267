import { s, bool, number, object, string } from 'modules/types'

export class DepartmentCount {
	constructor({
		id,
		name,
		active_employee_count: activeEmployeeCount,
		archived_employee_count: archivedEmployeeCount,
		head = null,
	}) {
		this.id = id
		this.name = name
		this.activeEmployeeCount = activeEmployeeCount
		this.archivedEmployeeCount = archivedEmployeeCount
		this.hasEmployee = Boolean(activeEmployeeCount) || Boolean(archivedEmployeeCount)
		if (head !== null) {
			this.head = head
		}
	}
}

export const DepartmentCountType = s({
	id: number.isRequired,
	name: string.isRequired,
	activeEmployeeCount: number.isRequired,
	archivedEmployeeCount: number.isRequired,
	head: object,
	hasEmployee: bool.isRequired,
})

export default DepartmentCount
