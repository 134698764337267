import { s, arrayOf, number, string } from 'modules/types'
import TimeoffGroupDetailRule, { TimeoffGroupDetailRuleType } from './TimeoffGroupDetailRule'

export class TimeoffGroupDetail {
	constructor({ id, name, employee_count: employeeCount, timeoff_rules: timeoffRules }) {
		this.id = id
		this.name = name
		this.employeeCount = employeeCount
		this.timeoffRules = timeoffRules.map(item => new TimeoffGroupDetailRule(item))
	}
}

export const TimeoffGroupDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	employeeCount: number.isRequired,
	timeoffRules: arrayOf(TimeoffGroupDetailRuleType),
})

export default TimeoffGroupDetail
