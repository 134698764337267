import { s, arrayOf, number, string } from 'modules/types'
import { alphabetical } from 'utils/sort'

class DistributionDetailEmployee {
	constructor({ employee_id: id, full_name: fullName, NIK }) {
		this.id = id
		this.fullName = fullName
		this.NIK = NIK
	}
}

const DistributionDetailEmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
})

class DistributionDetail {
	constructor({
		first_level_filter_type: firstLevelFilterType,
		first_level_filter_value_id: firstLevelFilterValueId,
		first_level_filter_value_name: firstLevelFilterValueName,
		second_level_filter_type: secondLevelFilterType,
		second_level_filter_value_id: secondLevelFilterValueId,
		second_level_filter_value_name: secondLevelFilterValueName,
		employees,
	}) {
		this.firstLevelFilterType = firstLevelFilterType
		this.firstLevelFilterValueId = firstLevelFilterValueId
		this.firstLevelFilterValueName = firstLevelFilterValueName
		this.secondLevelFilterType = secondLevelFilterType
		this.secondLevelFilterValueId = secondLevelFilterValueId
		this.secondLevelFilterValueName = secondLevelFilterValueName
		this.employees = employees
			.sort(alphabetical({ path: 'full_name' }))
			.map(item => new DistributionDetailEmployee(item))
	}
}

export const DistributionDetailType = s({
	firstLevelFilterType: number.isRequired,
	firstLevelFilterId: number.isRequired,
	firstLevelFilterName: string.isRequired,
	secondLevelFilterType: number.isRequired,
	secondLevelFilterId: number.isRequired,
	secondLevelFilterName: string.isRequired,
	employees: arrayOf(DistributionDetailEmployeeType),
})

export default DistributionDetail
