import { s, number } from 'modules/types'
import ExperienceTypeSimple, { ExperienceTypeSimpleType } from './ExperienceTypeSimple'

class ActiveWallet {
	constructor({ id, type }) {
		this.id = id
		this.type = new ExperienceTypeSimple(type)
	}
}

export const ActiveWalletType = s({
	id: number.isRequired,
	type: ExperienceTypeSimpleType.isRequired,
})

export default ActiveWallet
