import { s, number, string } from 'modules/types'

export class Template {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

export const TemplateType = s({
	id: number.isRequired,
	name: string.isRequired,
})

export default Template
