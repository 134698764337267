import { s, number, object } from 'modules/types'

export class Stage {
	constructor({ id, stage_number: stageNumber, start_date: startDate, end_date: endDate }) {
		this.id = id
		this.stageNumber = stageNumber
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
	}
}

export const StageType = s({
	id: number.isRequired,
	stageNumber: number,
	startDate: object,
	endDate: object,
})

export default Stage
