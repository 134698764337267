import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { Intent } from '@blueprintjs/core'
// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-job-description'
export const API_URLS = {
	jobDescs: () => `/api/setting/job_description/job_descriptions/`,
	jobDescDetail: jobDescId => `/api/setting/job_description/job_descriptions/${jobDescId}/`,
	jobDescVersion: (jobDescId, versionId) =>
		`/api/setting/job_description/job_descriptions/${jobDescId}/versions/${versionId}/`,
}

const CREATE_JOB_DESC_VERSION = `${MODULE_NAME} | CREATE JOB DESC VERSION`

const TOGGLE_EDIT_MODE = `${MODULE_NAME} | TOGGLE EDIT MODE`
const OPEN_JOB_DESC_DETAIL = `${MODULE_NAME} | OPEN JOB DESC DETAIL`
const OPEN_JOB_DESC_PREVIOUS_VERSION = `${MODULE_NAME} | OPEN JOB DESC PREVIOUS VERSION`

const REFRESHING_JOB_DESC_VERSION = `${MODULE_NAME} | REFRESHING JOB DESC VERSION`

const REFRESHED_JOB_DESCS = `${MODULE_NAME} | REFRESHED JOB DESCS`
const REFRESHED_JOB_DESC_DETAIL = `${MODULE_NAME} | REFRESHED JOB DESC DETAIL`
const REFRESHED_JOB_DESC_VERSION = `${MODULE_NAME} | REFRESHED JOB DESC VERSION`

const UPDATE_JOB_DESC_LIST = `${MODULE_NAME} | UPDATE JOB DESC LIST`

const ERROR_CREATING_JOB_DESC_VERSION = `${MODULE_NAME} | ERROR CREATING JOB DESC VERSION`
const ERROR_REFRESHING_JOB_DESC_DETAIL = `${MODULE_NAME} | ERROR REFRESHING JOB DESC DETAIL`
const ERROR_REFRESHING_JOB_DESC_VERSION = `${MODULE_NAME} | ERROR REFRESHING JOB DESC VERSION`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshJobDescs() {
	return dispatch => {
		const apiUrl = API_URLS.jobDescs()
		return api.setting.job_description.get().then(({ data: jobDescs }) => {
			dispatch({ type: REFRESHED_JOB_DESCS, data: jobDescs, apiUrl })
			return jobDescs
		})
	}
}

function refreshJobDescDetail(jobDescId) {
	return dispatch => {
		const apiUrl = API_URLS.jobDescDetail(jobDescId)
		return api.setting.job_description
			.getDetail(jobDescId)
			.then(({ data: jobDescriptionDetail }) => {
				dispatch({ type: REFRESHED_JOB_DESC_DETAIL, data: jobDescriptionDetail, apiUrl })
				return jobDescriptionDetail
			})
			.catch(err => {
				console.error('err:', err)
				const errors = err.response.jsonData.errors
				dispatch({ type: ERROR_REFRESHING_JOB_DESC_DETAIL })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

function refreshJobDescVersion(jobDescId, versionId) {
	return dispatch => {
		const apiUrl = API_URLS.jobDescVersion(jobDescId, versionId)
		dispatch({ type: REFRESHING_JOB_DESC_VERSION, apiUrl })
		return api.setting.job_description.versions
			.get(jobDescId, versionId)
			.then(({ data: jobDescVersionDetail }) => {
				dispatch({ type: REFRESHED_JOB_DESC_VERSION, data: jobDescVersionDetail, apiUrl })
				return jobDescVersionDetail
			})
			.catch(err => {
				const errors = err.response.jsonData.errors
				dispatch({ type: ERROR_REFRESHING_JOB_DESC_VERSION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function createNewVersion(jobDescId, sections) {
	return dispatch => {
		dispatch({ type: CREATE_JOB_DESC_VERSION, sections })
		return api.setting.job_description
			.createNewVersion(jobDescId, sections)
			.then(() => {
				dispatch(refreshJobDescDetail(jobDescId)).then(jobDescDetail => {
					dispatch({ type: UPDATE_JOB_DESC_LIST, jobDescId, jobDescription: jobDescDetail.jobDescription })
					return jobDescDetail
				})
			})
			.catch(err => {
				const errors = err.response.jsonData.errors
				dispatch({ type: ERROR_CREATING_JOB_DESC_VERSION, jobDescId })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function toggleEditMode(newEditMode) {
	return dispatch => {
		dispatch({ type: TOGGLE_EDIT_MODE, newEditMode: newEditMode })
	}
}

export function openJobDescDetail(jobDescId) {
	return (dispatch, getState) => {
		const apiUrl = API_URLS.jobDescDetail(jobDescId)
		const {
			jobDescription: { api },
		} = getState()
		dispatch({ type: OPEN_JOB_DESC_DETAIL, jobDescId })
		if (jobDescId === undefined) {
			return
		}
		if (api[apiUrl] === undefined) {
			dispatch(refreshJobDescDetail(jobDescId))
		}
	}
}

export function openJobDescPreviousVersion(jobDescId, versionId) {
	return (dispatch, getState) => {
		const apiUrl = API_URLS.jobDescVersion(jobDescId, versionId)
		const {
			jobDescription: { api },
		} = getState()
		dispatch({ type: OPEN_JOB_DESC_PREVIOUS_VERSION, versionId })
		if (jobDescId === undefined) {
			return
		}
		if (api[apiUrl] === undefined) {
			dispatch(refreshJobDescVersion(jobDescId, versionId))
		}
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshJobDescs())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		isEditMode: false,
		openedDetailJobDescId: undefined,
		openedVersionId: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_JOB_DESC_VERSION:
			return {
				...state,
				refreshing: {
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_JOB_DESCS:
		case REFRESHED_JOB_DESC_DETAIL:
		case REFRESHED_JOB_DESC_VERSION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case UPDATE_JOB_DESC_LIST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.jobDescs()]: state.api[API_URLS.jobDescs()].map(jobDesc => {
						if (jobDesc.id === action.jobDescId) {
							return action.jobDescription
						}
						return jobDesc
					}),
				},
			}
		case TOGGLE_EDIT_MODE:
			return {
				...state,
				data: {
					...state.data,
					isEditMode: action.newEditMode !== undefined ? action.newEditMode : !state.data.isEditMode,
				},
			}
		case OPEN_JOB_DESC_DETAIL:
			return {
				...state,
				data: {
					...state.data,
					openedDetailJobDescId: action.jobDescId,
				},
			}
		case OPEN_JOB_DESC_PREVIOUS_VERSION:
			return {
				...state,
				data: {
					...state.data,
					openedVersionId: action.versionId,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
