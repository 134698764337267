import { s, number, string } from 'modules/types'

export class EmploymentField {
	constructor({ employee_detail_question_id: employeeDetailQuestionId, name }) {
		this.employeeDetailQuestionId = employeeDetailQuestionId
		this.name = name
	}
}

export const EmploymentFieldType = s({
	employeeDetailQuestionId: number.isRequired,
	name: string.isRequired,
})

export default EmploymentField
