import { s, number } from 'modules/types'

export class EditTimeoffApprover {
	constructor({ type, approverId = null, rankId = null, groupLeaderRoleId = null }) {
		this.type = type
		if (rankId !== null) {
			this.rank_id = rankId
		}
		if (groupLeaderRoleId !== null) {
			this.group_role_id = groupLeaderRoleId
		}
		if (approverId !== null) {
			this.approver_id = approverId
		}
	}
}

export const EditTimeoffApproverType = s({
	type: number.isRequired,
	rank_id: number,
	approver_id: number,
	group_role_id: number,
})

export default EditTimeoffApprover
