import { s, arrayOf, number } from 'modules/types'

class AppraiserTypeData {
	constructor({ appraiserTypeId: appraiser_type_id, appraiserId: appraiser_id }) {
		this.appraiser_type_id = appraiser_type_id
		this.appraiser_id = appraiser_id
	}
}

const AppraiserTypeDataType = {
	appraiser_type_id: number.isRequired,
	appraiser_id: number.isRequired,
}

class GroupStageData {
	constructor({ groupStageId: group_stage_id, appraiserTypes: appraiser_types }) {
		this.group_stage_id = group_stage_id
		this.appraiser_types = appraiser_types.map(item => new AppraiserTypeData(item))
	}
}

const GroupStageDataType = {
	group_stage_id: number.isRequired,
	appraiser_types: arrayOf(AppraiserTypeDataType),
}

class AppraiseeData {
	constructor({ employeeId: employee_id, groupStages: group_stages }) {
		this.employee_id = employee_id
		this.group_stages = group_stages.map(item => new GroupStageData(item))
	}
}

const AppraiseeDataType = {
	employee_id: number.isRequired,
	group_stages: arrayOf(GroupStageDataType),
}

export class EditGroupData {
	constructor({ groupId: group_id, appraisees }) {
		this.group_id = group_id
		this.appraisees = appraisees.map(item => new AppraiseeData(item))
	}
}

export const EditGroupDataType = s({
	group_id: number.isRequired,
	appraisees: arrayOf(AppraiseeDataType),
})

export default EditGroupData
