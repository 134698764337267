import { s, string, number } from 'modules/types'

export class Employee {
	constructor({ id, full_name: fullName, NIK, picture }) {
		this.id = id
		this.fullName = fullName
		this.NIK = NIK
		this.picture = picture
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
	picture: string,
})

export default Employee
