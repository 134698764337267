import { s, arrayOf } from 'modules/types'
import TemplateWithQuestion, { TemplateWithQuestionType } from './TemplateWithQuestion'
import AppraiseeSuperiorScoring, { AppraiseeSuperiorScoringType } from './AppraiseeSuperiorScoring'
import EmployeeWithDetail, { EmployeeWithDetailType } from './EmployeeWithDetail'

export class AppraiseesSuperiorScoring {
	constructor({ appraisees, template, superior }) {
		this.appraisees = appraisees.map(item => new AppraiseeSuperiorScoring(item))
		this.template = new TemplateWithQuestion(template)
		this.superior = new EmployeeWithDetail(superior)
	}
}

export const AppraiseesSuperiorScoringType = s({
	appraisees: arrayOf(AppraiseeSuperiorScoringType),
	template: TemplateWithQuestionType,
	superior: EmployeeWithDetailType,
})

export default AppraiseesSuperiorScoring
