import { s, number } from 'modules/types'

export class IdeaReportCounter {
	constructor({
		received,
		forwarded,
		commented,
		posted_to_idea_wall: postedToIdeaWall,
		awarded_like: awardedLike,
		awarded_flower: awardedFlower,
		awarded_star: awardedStar,
		no_response: noResponse,
	}) {
		this.received = received
		if (Number.isInteger(forwarded)) {
			this.forwarded = forwarded
		}
		if (Number.isInteger(commented)) {
			this.commented = commented
		}
		if (Number.isInteger(postedToIdeaWall)) {
			this.postedToIdeaWall = postedToIdeaWall
		}
		if (Number.isInteger(awardedLike)) {
			this.awardedLike = awardedLike
		}
		if (Number.isInteger(awardedFlower)) {
			this.awardedFlower = awardedFlower
		}
		if (Number.isInteger(awardedStar)) {
			this.awardedStar = awardedStar
		}
		this.noResponse = noResponse
	}
}

export const IdeaReportCounterType = s({
	received: number,
	forwarded: number,
	commented: number,
	postedToIdeaWall: number,
	awardedLike: number,
	awardedFlower: number,
	awardedStar: number,
	noResponse: number,
})

export default IdeaReportCounter
