import { httpGet, httpPost, httpPostMultipart, mockAPI } from 'utils'
import { alphabetical, by } from 'utils/sort'
import moment from 'moment'
import format from 'date-fns/format'
import Pagination, { PaginationV2 } from 'modules/models/Pagination'
import AnalyticsAppraisal from 'modules/models/analytics/appraisal'
import AnalyticsDemographics from 'modules/models/analytics/demographics'
import Announcement from 'modules/models/announcement'
import Appraisal from 'modules/models/appraisal'
import AppraisalAdminTemplate from 'modules/models/appraisal_admin/template'
import AppraisalAdminSession from 'modules/models/appraisal_admin/session'
import AppraisalAdminScoring from 'modules/models/appraisal_admin/scoring'
import Brainstorming from 'modules/models/brainstorming'
import CompanyPolicy from 'modules/models/company_policy'
import Dashboard from 'modules/models/dashboard'
import EmployeeContract, { CONTRACT_STATUS } from 'modules/models/employee_contract'
import EmployeeDatabase, { QUESTION_TYPE } from 'modules/models/employee_database'
import Engagement from 'modules/models/engagement'
import Experience from 'modules/models/experience'
import HrTimeoff from 'modules/models/hr_timeoff'
import SettingJobDescription from 'modules/models/setting/job_description'
import HrExperience from 'modules/models/hr_experience'
import JobDescription from 'modules/models/job_description'
import Offboarding from 'modules/models/offboarding'
import PublicHolidaySetting from 'modules/models/setting/holiday/'
import Setting from 'modules/models/setting'
import SettingIdea, { REVIEWER_TYPE } from 'modules/models/setting/idea'
import SettingEmploymentInfo from 'modules/models/setting/employment_info'
import SettingDashboard from 'modules/models/setting/dashboard'
import SettingPermissions from 'modules/models/setting/permissions'
import Survey from 'modules/models/survey'
import SurveyAdmin from 'modules/models/survey_admin'
import Timeoff from 'modules/models/timeoff'
import WorkingCycleSetting from 'modules/models/working_cycle_setting'
import TimeoffSetting from 'modules/models/setting/timeoff/'
import Workstation from 'modules/models/workstation'

const api = {
	employee_database: {
		getEmployees: (param = {}) => {
			return httpPost(`/api/employee_database/get_employees/`, param).then(({ success, data, pagination, errors }) => ({
				success,
				data: new EmployeeDatabase.EmployeeWithColumn(data),
				pagination: new Pagination(pagination),
				errors,
			}))
		},
		getFieldGroups: () => {
			return httpGet(`/api/employee_database/field_groups/`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new EmployeeDatabase.FieldGroup(item)),
				errors,
			}))
		},
		columns: {
			getAllQuestionColumns: () => {
				return httpGet(`/api/employee_database/columns/get_all_question_columns/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new EmployeeDatabase.QuestionWithField(item)),
						errors,
					})
				)
			},
			edit: (columnId, questionId) => {
				let data = {
					question_id: questionId,
				}
				return httpPost(`/api/employee_database/columns/${columnId}/edit/`, data).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
		},
		employees: {
			restore_employee: employeeId => {
				return httpPost(`/api/employee_database/employees/${employeeId}/restore_employee/`).then(({ success }) => ({
					success,
				}))
			},
			archive_employee: employeeId => {
				return httpPost(`/api/employee_database/employees/${employeeId}/archive_employee/`).then(({ success }) => ({
					success,
				}))
			},
			profile_summary: employeeId => {
				return httpGet(`/api/employee_database/employees/${employeeId}/profile_summary/`).then(({ success, data }) => ({
					success,
					data: new EmployeeDatabase.ProfileSummary(data),
				}))
			},
			getDetailChangeLogs: (employeeId, page = 1) => {
				return httpGet(`/api/employee_database/employees/${employeeId}/detail_change_logs/?page=${page}`).then(
					({ success, data, pagination }) => ({
						success,
						data,
						pagination: new Pagination(pagination),
					})
				)
			},
			get_register_employee_choices: () => {
				return httpGet(`/api/employee_database/employees/get_register_employee_choices/`)
			},
			change_profile_picture: (employeeId, newPictureData) => {
				let data = {
					picture: newPictureData,
				}
				return httpPost(`/api/employee_database/employees/${employeeId}/change_profile_picture/`, data)
			},
			register_employee: formData => {
				return httpPostMultipart(`/api/employee_database/employees/register_employee/`, formData)
			},
			fieldGroups: {
				get: (employeeId, fieldGroupId) => {
					return httpGet(`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new EmployeeDatabase.FieldWithQuestionAnswer(item)),
							errors,
						})
					)
				},
				answer: (employeeId, fieldGroupId, fieldId, questionId, answer) => {
					let data = { answer }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/answer/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				answerMultipleChoice: (employeeId, fieldGroupId, fieldId, questionId, choiceId) => {
					let data = { choice_id: choiceId }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/answer_multiple_choice/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				answerWithChoices: (employeeId, fieldGroupId, fieldId, questionId, choiceIds) => {
					let data = { choices_id: choiceIds }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/answer_with_choices/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				uploadFile: (employeeId, fieldGroupId, fieldId, questionId, formData) => {
					return httpPostMultipart(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/upload_file/`,
						formData
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				removeFile: (employeeId, fieldGroupId, fieldId, questionId) => {
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/remove_file/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},

				addFieldAnswer: (employeeId, fieldGroupId, fieldId) => {
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/add_field_answer/`
					).then(({ success, data, errors }) => ({
						success,
						data: new EmployeeDatabase.FieldAnswer(data),
						errors,
					}))
				},
				deleteFieldAnswer: (employeeId, fieldGroupId, fieldId, fieldAnswerId) => {
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${fieldAnswerId}/delete/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},

				answerFieldAns: (employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, answer) => {
					let data = { answer }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/${fieldAnswerId}/answer/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				answerMultipleChoiceFieldAns: (employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceId) => {
					let data = { choice_id: choiceId }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/${fieldAnswerId}/answer_multiple_choice/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				answerWithChoicesFieldAns: (employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, choiceIds) => {
					let data = { choices_id: choiceIds }
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/${fieldAnswerId}/answer_with_choices/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				uploadFileFieldAns: (employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId, formData) => {
					return httpPostMultipart(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/${fieldAnswerId}/upload_file/`,
						formData
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				removeFileFieldAns: (employeeId, fieldGroupId, fieldId, questionId, fieldAnswerId) => {
					return httpPost(
						`/api/employee_database/employees/${employeeId}/field_groups/${fieldGroupId}/${fieldId}/${questionId}/${fieldAnswerId}/remove_file/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
			},
			contracts: {
				get: employeeId => {
					return httpGet(`/api/employee_database/employees/${employeeId}/contracts/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new EmployeeContract.Contract(item)),
							errors,
						})
					)
				},
				getDetail: (employeeId, contractId) => {
					return httpGet(`/api/employee_database/employees/${employeeId}/contracts/${contractId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new EmployeeContract.Contract(data),
							errors,
						})
					)
				},
				create: (employeeId, { noPKWT, endDate, notes, attachments, status = CONTRACT_STATUS.VALID }) => {
					let data = {
						no_pkwt: noPKWT,
						end_date: endDate,
						notes,
						attachments,
						status,
					}
					return httpPost(`/api/employee_database/employees/${employeeId}/contracts/create/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new EmployeeContract.Contract(data),
							errors,
						})
					)
				},
				edit: (employeeId, contractId, { noPKWT, endDate, notes, attachments }) => {
					let data = {
						no_pkwt: noPKWT,
						end_date: endDate,
						notes,
						attachments,
					}
					return httpPost(`/api/employee_database/employees/${employeeId}/contracts/${contractId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new EmployeeContract.Contract(data),
							errors,
						})
					)
				},
				markAsCancelled: (employeeId, contractId) => {
					return httpPost(
						`/api/employee_database/employees/${employeeId}/contracts/${contractId}/mark_as_cancelled/`
					).then(({ success, data, errors }) => ({
						success,
						data: new EmployeeContract.Contract(data),
						errors,
					}))
				},
			},
		},
	},
	analytics: {
		appraisal: {
			sessions: {
				get: () => {
					return httpGet(`/api/analytics/appraisal/sessions/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsAppraisal.SessionWithTotalParticipants(item)),
					}))
				},
				getEmployees: sessionId => {
					return httpGet(`/api/analytics/appraisal/sessions/${sessionId}/employees/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsAppraisal.SessionEmployee(item)),
					}))
				},
				getGroupEmployees: (sessionId, defaultTemplateId, groupIds) => {
					let data = {
						group_ids: groupIds,
					}
					return httpPost(
						`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/employees/`,
						data
					).then(({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsAppraisal.GroupEmployeeWithScoreCardFinished(item)),
					}))
				},
				getEmploymentFields: () => {
					return httpGet(`/api/analytics/appraisal/sessions/get_employment_fields/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsAppraisal.EmploymentField(item)),
					}))
				},
				groups: {
					getStatistics: (sessionId, groupId) => {
						return httpPost(`/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/`).then(
							({ success, data }) => ({
								success,
								data: new AnalyticsAppraisal.GroupStatisticsWithCount(data),
							})
						)
					},
					getEmployeeCounts: (sessionId, groupId, employeeDetailQuestionId) => {
						return httpGet(
							`/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/get_employee_counts/${employeeDetailQuestionId}/`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new AnalyticsAppraisal.GroupingWithCount(item)),
						}))
					},
					getGroupEmploymentFieldGroupings: (sessionId, groupId) => {
						return httpGet(`/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/groupings/`).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new AnalyticsAppraisal.GroupingWithQuestion(item)),
							})
						)
					},
				},
				default_templates_with_groups: {
					get: sessionId => {
						return httpGet(`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/`).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new AnalyticsAppraisal.DefaultTemplateWithGroups(item)),
							})
						)
					},
					getCategoryStatistics: (sessionId, defaultTemplateId, defaultCategoryId, groupIds = []) => {
						let data = {
							group_ids: groupIds,
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_category_statistics/${defaultCategoryId}/`,
							data
						).then(({ success, data }) => ({
							success,
							data: new AnalyticsAppraisal.CategoryStatistics(data),
						}))
					},
					getBestPerformers: (sessionId, defaultTemplateId, defaultCategoryId, groupIds = [], questionId = -1) => {
						let data = {
							group_ids: groupIds,
						}
						if (questionId > 0) {
							data.default_question_link_id = questionId
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_best_performers/${defaultCategoryId}/`,
							data
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new AnalyticsAppraisal.EmployeeWithScore(item)).slice(0, 10),
						}))
					},
					getWorstPerformers: (sessionId, defaultTemplateId, defaultCategoryId, groupIds = [], questionId = -1) => {
						let data = {
							group_ids: groupIds,
						}
						if (questionId > 0) {
							data.default_question_link_id = questionId
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_worst_performers/${defaultCategoryId}/`,
							data
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new AnalyticsAppraisal.EmployeeWithScore(item)).slice(0, 10),
						}))
					},
					getMcqAnswers: (sessionId, defaultTemplateId, questionId, groupIds = []) => {
						let data = {
							group_ids: groupIds,
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_mcq_answers/${questionId}/`,
							data
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new AnalyticsAppraisal.ChoiceWithEmployees(item)),
						}))
					},
					getTextAnswers: (sessionId, defaultTemplateId, questionId, groupIds = []) => {
						let data = {
							group_ids: groupIds,
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_text_answers/${questionId}/`,
							data
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new AnalyticsAppraisal.EmployeeWithAnswer(item)),
						}))
					},
					compareStatistics: (sessionId, defaultTemplateId, { groupIds = [], defaultQuestionLinkId, filters }) => {
						let data = {
							group_ids: groupIds,
							filters: filters.map(filter => ({
								type: filter.type,
								employee_id: filter.employeeId,
								employee_detail_question_id: filter.employeeDetailQuestionId,
								employee_detail_answer_id: filter.employeeDetailAnswerId,
							})),
						}
						if (defaultQuestionLinkId) {
							data.default_question_link_id = defaultQuestionLinkId
						}
						return httpPost(
							`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/comparison_statistics/`,
							data
						).then(({ success, data }) => ({
							success,
							data: new AnalyticsAppraisal.CategoriesWithComparisons(data),
						}))
					},
				},
			},
		},
		demographics: {
			getFilters: (param = '') => {
				// list of params:
				// int filter_type
				return httpGet(`/api/analytics/demographics/get_filters/?filter_type=${param}`).then(({ success, data }) => ({
					success,
					data: data.map(item => new AnalyticsDemographics.Filter(item)),
				}))
			},
			getEmployeeCountChart: (param = '') => {
				// list of params:
				// int year
				// int filter_type
				// int filter_value
				return httpGet(`/api/analytics/demographics/get_employee_count_chart/${param}`).then(({ success, data }) => ({
					success,
					data: data.map(item => new AnalyticsDemographics.EmployeeMonthCount(item)),
				}))
			},
			getEmployeeCountDetailDays: (param = '') => {
				// list of params:
				// int month
				// int year
				return httpGet(`/api/analytics/demographics/get_employee_count_detail_days/${param}`).then(
					({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsDemographics.EmployeeCountDetailDay(item)),
					})
				)
			},
			getInOutEmployees: date => {
				return httpGet(`/api/analytics/demographics/get_employee_count_detail_date/?date=${date}`).then(
					({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsDemographics.InOutEmployee(item)),
					})
				)
			},
			getCompanyDistributions: (param = '') => {
				// list of params:
				// int month
				// int year
				// int grouping_filter_type
				return httpGet(`/api/analytics/demographics/get_company_distributions/${param}`, { withoutToast: true }).then(
					({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsDemographics.CompanyDistribution(item)),
					})
				)
			},
			getCompanyDistributionsEmployees: (param = '') => {
				// list of params:
				// int month
				// int year
				// int grouping_filter_type
				// int first_level_filter_value_id
				return httpGet(`/api/analytics/demographics/get_company_distributions/employees/${param}`, {
					withoutToast: true,
				}).then(({ success, data }) => ({
					success,
					data: {
						employees: data.employees
							.sort(alphabetical({ path: 'full_name' }))
							.map(item => new AnalyticsDemographics.Employee(item)),
					},
				}))
			},
			getBreakdownCharts: dateString => {
				return httpGet(`/api/analytics/demographics/breakdown_charts/?date=${dateString}`).then(
					({ success, data }) => ({
						success,
						data: data.map(item => new AnalyticsDemographics.FieldGroupChart(item)),
					})
				)
			},
			getChartDistributions: (questionId, filterType, dateString) => {
				return httpGet(
					`/api/analytics/demographics/breakdown_charts/${questionId}/${filterType}/distributions/?date=${dateString}`
				).then(({ success, data }) => ({
					success,
					data: data.map(item => new AnalyticsDemographics.BreakdownChartDistribution(item)),
				}))
			},
			getChartDistributionsDetail: (
				questionId,
				filterType,
				firstLevelFilterValueId,
				secondLevelFilterValueId,
				dateString
			) => {
				let params = `?date=${dateString}&first_level_filter_value_id=${firstLevelFilterValueId}&second_level_filter_value_id=${secondLevelFilterValueId}`
				return httpGet(
					`/api/analytics/demographics/breakdown_charts/${questionId}/${filterType}/distributions/employees/${params}`
				).then(({ success, data }) => ({
					success,
					data: new AnalyticsDemographics.DistributionDetail(data),
				}))
			},
		},
	},
	announcement: {
		get: () => {
			return httpGet(`/api/announcements/`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Announcement.Announcement(item)),
			}))
		},
		getDetail: announcementId => {
			return httpGet(`/api/announcements/${announcementId}/`).then(({ success, data, errors }) => ({
				success,
				data: new Announcement.AnnouncementDetail(data),
				errors,
			}))
		},
		getFilters: () => {
			return httpGet(`/api/announcements/get_filters/`).then(({ success, data }) => ({
				success,
				data: {
					departments: data.departments
						.sort(alphabetical({ path: 'name' }))
						.map(item => new Announcement.Department(item)),
					groups: data.groups.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Group(item)),
					superiors: data.superiors.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Superior(item)),
					titles: data.titles.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Title(item)),
					ranks: data.ranks
						.sort(alphabetical({ path: 'name' }))
						.map(item => new AppraisalAdminSession.FilterItem(item)),
					positions: data.positions
						.sort(alphabetical({ path: 'name' }))
						.map(item => new AppraisalAdminSession.FilterItem(item)),
				},
			}))
		},
		getEmployees: (params = '') => {
			return httpGet(`/api/announcements/get_employees/${params}`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Announcement.Employee(item)),
			}))
		},
		getAudiences: announcementId => {
			return httpGet(`/api/announcements/${announcementId}/get_audiences/`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new Announcement.Audience(item)),
				errors,
			}))
		},
		create: ({
			title,
			content = 'Announcement content',
			attachment = null,
			type = 0,
			audienceIds: audience_ids = [],
		}) => {
			let data = {
				title,
				content,
				attachment,
				type,
				audience_ids,
			}
			return httpPost(`/api/announcements/create/`, data).then(({ success, data, errors }) => ({
				success,
				data: new Announcement.AnnouncementDetail(data),
				errors,
			}))
		},
		edit: (announcementId, { title, content, type, attachment, audienceIds: audience_ids }) => {
			let data = {
				title,
				content,
				type,
				attachment,
				audience_ids,
			}
			return httpPost(`/api/announcements/${announcementId}/edit/`, data).then(({ success, data, errors }) => ({
				success,
				data: new Announcement.AnnouncementDetail(data),
				errors,
			}))
		},
		delete: announcementId => {
			return httpPost(`/api/announcements/${announcementId}/delete/`).then(({ success, errors }) => ({
				success,
				errors,
			}))
		},
	},
	appraisal: {
		subSessions: {
			ongoing: {
				getSubsessions: () => {
					return httpGet('/api/appraisal/sub_sessions/ongoing/').then(({ success, data }) => ({
						success,
						data: data.map(item => new Appraisal.SubSessionSimple(item)),
					}))
				},
				getSubsessionDetail: subSessionId => {
					return httpGet(`/api/appraisal/sub_sessions/ongoing/${subSessionId}/`).then(({ success, data }) => ({
						success,
						data: new Appraisal.SubSessionDetail(data),
					}))
				},
				submit: (subSessionId, data) => {
					return httpPost(`/api/appraisal/sub_sessions/ongoing/${subSessionId}/submit/`, data)
				},
				saveProgress: (subSessionId, data) => {
					return httpPost(`/api/appraisal/sub_sessions/ongoing/${subSessionId}/save_progress/`, data)
				},
				sheet_view: {
					requestSubSessions: (groupId, subSessionIds) => {
						let data = { sub_session_ids: subSessionIds }
						return httpPost(`/api/appraisal/sub_sessions/ongoing/sheet_view/${groupId}/`, data).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new Appraisal.SheetViewCategoryResult(item)),
							})
						)
					},
					requestSubSessionSnapshots: (groupId, subSessionIds) => {
						let data = { sub_session_ids: subSessionIds }
						return httpPost(`/api/appraisal/sub_sessions/ongoing/sheet_view/${groupId}/get_snapshot/`, data).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new Appraisal.SubSessionSnapshot(item)),
							})
						)
					},
					submit: (groupId, { subSessionsWithAnswers }) => {
						let data = {
							sub_sessions_with_answers: subSessionsWithAnswers.map(item => new Appraisal.SubSessionsWithAnswers(item)),
						}
						return httpPost(`/api/appraisal/sub_sessions/ongoing/sheet_view/${groupId}/submit/`, data).then(
							({ success, errors }) => ({
								success,
								errors,
							})
						)
					},
					saveProgress: (groupId, { subSessionsWithAnswers }) => {
						let data = {
							sub_sessions_with_answers: subSessionsWithAnswers.map(item => new Appraisal.SubSessionsWithAnswers(item)),
						}
						return httpPost(`/api/appraisal/sub_sessions/ongoing/sheet_view/${groupId}/save_progress/`, data).then(
							({ success, errors }) => ({
								success,
								errors,
							})
						)
					},
				},
				snapshot: {
					get: (sessionId, subSessionId) => {
						return httpGet(`/api/appraisal/sub_sessions/ongoing/${sessionId}/${subSessionId}/snapshot/get/`).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new Appraisal.Snapshot(item)),
							})
						)
					},
					updateSingle: (sessionId, subSessionId, { questionLinkId, answer }) => {
						let data = {
							question_link_id: questionLinkId,
							answer,
						}
						return httpPost(
							`/api/appraisal/sub_sessions/ongoing/${sessionId}/${subSessionId}/snapshot/update_single/`,
							data
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new Appraisal.Snapshot(item)),
						}))
					},
					cancel: (sessionId, subSessionId) => {
						return httpPost(`/api/appraisal/sub_sessions/ongoing/${sessionId}/${subSessionId}/snapshot/cancel/`).then(
							({ success }) => ({
								success,
							})
						)
					},
				},
			},
			completed: {
				getSubsessions: () => {
					return httpGet('/api/appraisal/sub_sessions/completed/').then(({ success, data }) => ({
						success,
						data: data.map(item => new Appraisal.SubSessionSimple(item)),
					}))
				},
				getSubsessionDetail: subSessionId => {
					return httpGet(`/api/appraisal/sub_sessions/completed/${subSessionId}/`).then(({ success, data }) => ({
						success,
						data: new Appraisal.SubSessionDetail(data),
					}))
				},
			},
		},
	},
	appraisal_admin: {
		appraisal_template: {
			default_templates: {
				getTemplatesSimple: () => {
					return httpGet('/api/appraisal_admin/appraisal_template/default_templates/simple/').then(
						({ success, data }) => ({
							success,
							data: data.map(item => new AppraisalAdminTemplate.DefaultTemplateSimple(item)),
						})
					)
				},
				getTemplateDetail: defaultTemplateId => {
					return httpGet(`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.DefaultTemplate(data),
							errors,
						})
					)
				},
				getUnaddedQuestions: defaultTemplateId => {
					return httpGet(
						`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/get_unadded_questions/`
					).then(({ success, data }) => ({
						success,
						data: data.map(item => new AppraisalAdminTemplate.Question(item)),
					}))
				},
				create: ({ name, description, categories }) => {
					let data = {
						name,
						description,
						categories: categories.map(category => new AppraisalAdminTemplate.CreateDefaultTemplateCategory(category)),
					}

					return httpPost('/api/appraisal_admin/appraisal_template/default_templates/create/', data).then(
						({ success, data }) => ({
							success,
							data: new AppraisalAdminTemplate.DefaultTemplate(data),
						})
					)
				},
				copy: defaultTemplateId => {
					return httpPost(
						`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/create_copy/`
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminTemplate.DefaultTemplate(data),
						errors,
					}))
				},
				edit: (defaultTemplateId, { name, description = '', categories }) => {
					let data = {
						name,
						description,
						categories: categories.map(category => new AppraisalAdminTemplate.CreateDefaultTemplateCategory(category)),
					}

					return httpPost(
						`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/edit/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminTemplate.DefaultTemplate(data),
						errors,
					}))
				},
				delete: defaultTemplateId => {
					return httpPost(
						`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/delete/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				templates: {
					getDetail: (defaultTemplateId, templateId) => {
						return httpGet(
							`/api/appraisal_admin/appraisal_template/default_templates/${defaultTemplateId}/templates/${templateId}/`
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.TemplateWithQuestions(data),
							errors,
						}))
					},
				},
			},
			questions: {
				getQuestions: (page = 1) => {
					return httpGet(`/api/appraisal_admin/appraisal_template/questions/?page=${page}`).then(
						({ success, data, pagination }) => ({
							success,
							data: data.map(item => new AppraisalAdminTemplate.Question(item)),
							pagination: new Pagination(pagination),
						})
					)
				},
				getQuestionDetail: questionId => {
					return httpGet(`/api/appraisal_admin/appraisal_template/questions/${questionId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.Question(data),
							errors,
						})
					)
				},
				create: ({ selfContent, alternativeContent, type, linearLowestLabel, linearHighestLabel, choices }) => {
					let data = {
						self_content: selfContent,
						alternative_content: alternativeContent,
						type,
						linear_lowest_label: linearLowestLabel,
						linear_highest_label: linearHighestLabel,
					}
					if (choices) {
						data.choices = choices
					}

					return httpPost(`/api/appraisal_admin/appraisal_template/questions/create/`, data).then(
						({ success, data }) => ({
							success,
							data: new AppraisalAdminTemplate.Question(data),
						})
					)
				},
				edit: (
					questionId,
					{ selfContent, alternativeContent, linearLowestLabel, linearHighestLabel, type, choices }
				) => {
					let data = {
						self_content: selfContent,
						alternative_content: alternativeContent,
						type,
						linear_lowest_label: linearLowestLabel,
						linear_highest_label: linearHighestLabel,
					}
					if (choices) {
						data.choices = choices
					}
					return httpPost(`/api/appraisal_admin/appraisal_template/questions/${questionId}/edit/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				delete: questionId => {
					return httpPost(`/api/appraisal_admin/appraisal_template/questions/${questionId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				change_linear_size: (questionId, linearSize, disabledChoices) => {
					let data = {
						linear_size: linearSize,
						disabled_choices: disabledChoices,
					}

					return httpPost(
						`/api/appraisal_admin/appraisal_template/questions/${questionId}/change_linear_size/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminTemplate.Question(data),
						errors,
					}))
				},
				answer_choices: {
					edit: (questionId, answerChoiceId, { content, disabled }) => {
						let data = { content, disabled }

						return httpPost(
							`/api/appraisal_admin/appraisal_template/questions/${questionId}/answer_choices/${answerChoiceId}/edit/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.AnswerChoice(data),
							errors,
						}))
					},
					delete: (questionId, answerChoiceId) => {
						return httpPost(
							`/api/appraisal_admin/appraisal_template/questions/${questionId}/answer_choices/${answerChoiceId}/delete/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					move_up: (questionId, answerChoiceId) => {
						return httpPost(
							`/api/appraisal_admin/appraisal_template/questions/${questionId}/answer_choices/${answerChoiceId}/move_up/`
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.AnswerChoice(data),
							errors,
						}))
					},
					move_down: (questionId, answerChoiceId) => {
						return httpPost(
							`/api/appraisal_admin/appraisal_template/questions/${questionId}/answer_choices/${answerChoiceId}/move_down/`
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminTemplate.AnswerChoice(data),
							errors,
						}))
					},
				},
			},
		},
		appraisal_session: {
			sessions: {
				getRanks: (sessionId) => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_ranks_simple/`).then(({ success, data }) => ({
						success,
						data: data,
					}))
				},
				getSessionsSimple: () => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/simple/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new AppraisalAdminSession.Session(item)),
					}))
				},
				getSessionDetail: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/`).then(({ success, data }) => ({
						success,
						data: new AppraisalAdminSession.SessionDetail(data),
					}))
				},
				getDefaultTemplates: () => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/get_default_templates/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new AppraisalAdminSession.DefaultTemplate(item)),
						})
					)
				},
				getSubsessions: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/sub_sessions/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new AppraisalAdminSession.SubSession(item)),
							errors,
						})
					)
				},
				getUnusedCategorizeOptions: sessionId => {
					return httpGet(
						`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_unused_categorize_options/`
					).then(({ data }) => ({
						data: data.map(item => new AppraisalAdminSession.CategorizeOption(item)),
					}))
				},
				getFilters: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_filters/`).then(
						({ success, data }) => ({
							success,
							data: {
								departments: data.departments
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
								groups: data.groups
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
								superiors: data.superiors
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
								titles: data.titles
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
								ranks: data.ranks
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
								positions: data.positions
									.sort(alphabetical({ path: 'name' }))
									.map(item => new AppraisalAdminSession.FilterItem(item)),
							},
						})
					)
				},
				getEmployees: (sessionId, params = '') => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_employees/${params}`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new AppraisalAdminSession.UnaddedAppraiseeEmployee(item)),
						})
					)
				},
				getAllEmployees: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_all_employees/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new AppraisalAdminSession.EmployeeSimple(item)),
						})
					)
				},
				getExternalEmployees: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_external_employees/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new AppraisalAdminSession.EmployeeExternal(item)),
						})
					)
				},
				getGroupsWithAppraisees: sessionId => {
					return httpGet(
						`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_groups_with_appraisees/`
					).then(({ success, data }) => ({
						success,
						data: {
							groups: data.groups.map(item => new AppraisalAdminSession.GroupWithAppraisees(item)),
						},
					}))
				},
				create: ({ name, description = '' }) => {
					let data = { name, description }
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/create/`, data).then(
						({ success, data }) => ({
							success,
							data: new AppraisalAdminSession.Session(data),
						})
					)
				},
				resetSubSessionForm: (sessionId, subSessionIds) => {
					const data = { sub_session_ids: subSessionIds }
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/sub_sessions/reset_subsessions/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				start: sessionId => {
					let data = {}
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/start/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				stop: sessionId => {
					let data = {}
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/stop/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				edit: (sessionId, { name, description = '', categorizeBy }) => {
					let data = { name, description, categorize_by: categorizeBy }
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminSession.Session(data),
							errors,
						})
					)
				},
				delete: sessionId => {
					let data = {}
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/delete/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				removeAppraisees: (sessionId, appraiseeIds) => {
					let data = {
						appraisee_id_list: appraiseeIds,
					}
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/remove_appraisees/`, data).then(
						({ success }) => ({
							success,
						})
					)
				},
				addGroupByCategorize: (sessionId, { categorizeId, template }) => {
					let data = {
						categorize_id: categorizeId,
						template: new AppraisalAdminSession.AddGroupCustomTemplate(template),
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/add_group_by_categorize/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminSession.AppraisalGroup(data),
						errors,
					}))
				},
				editAppraisees: (sessionId, { groups }) => {
					let data = { groups: groups.map(item => new AppraisalAdminSession.EditGroupData(item)) }
					return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/edit_appraisees/`, data).then(
						({ success, data, errors }) => ({
							success,
							data,
							errors,
						})
					)
				},
				stages: {
					create: (sessionId, { startDate, endDate }) => {
						let data = {
							start_date: format(startDate, 'YYYY-MM-DD'),
							end_date: format(endDate, 'YYYY-MM-DD'),
						}
						return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/stages/create/`, data).then(
							({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminSession.Stage(data),
								errors,
							})
						)
					},
					edit: (sessionId, stageId, { startDate, endDate }) => {
						let data = {
							start_date: format(startDate, 'YYYY-MM-DD'),
							end_date: format(endDate, 'YYYY-MM-DD'),
						}
						return httpPost(
							`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/stages/${stageId}/edit/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminSession.Stage(data),
							errors,
						}))
					},
					delete: (sessionId, stageId) => {
						return httpPost(
							`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/stages/${stageId}/delete/`
						).then(({ success, data, errors }) => ({
							success,
							errors,
						}))
					},
				},
				groups: {
					create: (sessionId, { name, template }) => {
						let data = {
							name,
							template: new AppraisalAdminSession.AddGroupCustomTemplate(template),
						}
						return httpPost(`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/create/`, data).then(
							({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminSession.Group(data),
								errors,
							})
						)
					},
					edit: (sessionId, groupId, { name, template = null }) => {
						let data = { name }
						if (template !== null) {
							data.template = new AppraisalAdminSession.AddGroupCustomTemplate(template)
						}
						return httpPost(
							`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/edit/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminSession.Group(data),
							errors,
						}))
					},
					delete: (sessionId, groupId) => {
						return httpPost(
							`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/delete/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					groupStages: {
						getSessionGroupStages: (sessionId, groupId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/group_stages/`
							).then(({ success, data }) => ({
								success,
								data: data.map(item => new AppraisalAdminSession.GroupStage(item)),
							}))
						},
						createAppraiserType: (sessionId, groupId, groupStageId, { type, groupRoleId, rankId }) => {
							let data = { type }
							if (groupRoleId) {
								data.group_role_id = groupRoleId
							}
							if (rankId) {
								data.rank_id = rankId
							}
							return httpPost(
								`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/group_stages/${groupStageId}/appraiser_types/create/`,
								data
							).then(({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminSession.AppraiserType(data),
								errors,
							}))
						},
						deleteAppraiserType: (sessionId, groupId, groupStageId, appraiserTypeId) => {
							return httpPost(
								`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/group_stages/${groupStageId}/appraiser_types/${appraiserTypeId}/delete/`
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
					},
				},
			},
		},
		appraisal_scoring: {
			sessions: {
				getSessionsSimple: () => {
					return httpGet(`/api/appraisal_admin/appraisal_scoring/sessions/simple/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new AppraisalAdminScoring.Session(item)),
					}))
				},
				getSessionDetail: sessionId => {
					return httpGet(`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminScoring.Session(data),
							errors,
						})
					)
				},
				getAllowedGroupsToMove: (sessionId, employeeIds) => {
					let data = {
						employee_ids: employeeIds,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/get_allowed_group_to_move/`,
						data,
						{ withoutToast: true }
					).then(({ success, data }) => ({
						success,
						data: new AppraisalAdminScoring.AllowedGroupsToMoveResponse(data),
					}))
				},
				exportAllPdfZip: sessionId => {
					let data = {}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_all_pdf_zip/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminScoring.ExportResult(data),
						errors,
					}))
				},
				exportSelectedScoreCardsZip: (sessionId, superiorIds, employeeIds, groupIds) => {
					let data = {
						superior_ids: superiorIds,
						employee_ids: employeeIds,
						group_ids: groupIds,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_selected_score_cards_zip/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new AppraisalAdminScoring.ExportResult(data),
						errors,
					}))
				},
				exportAllExcelResume: sessionId => {
					return httpPost(`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_all_excel_resume_view/`)
				},
				exportSelectedExcelResume: (sessionId, superiorIds, employeeIds, groupIds) => {
					let data = {
						superior_ids: superiorIds,
						employee_ids: employeeIds,
						group_ids: groupIds,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_selected_score_cards_excel_resume_view/`,
						data
					)
				},
				exportAllExcelRank: sessionId => {
					return httpPost(`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_all_excel_rank_view/`)
				},
				exportSelectedExcelRank: (sessionId, superiorIds, employeeIds, groupIds) => {
					let data = {
						superior_ids: superiorIds,
						employee_ids: employeeIds,
						group_ids: groupIds,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/export_selected_score_cards_excel_rank_view/`,
						data
					)
				},
				moveEmployeesToExistingGroup: (sessionId, employeeIds, groupId) => {
					let data = {
						employee_ids: employeeIds,
						group_id: groupId,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/move_employee_group/`,
						data
					).then(({ success }) => ({
						success,
					}))
				},
				moveEmployeesToNewGroup: (sessionId, employeeIds, { groupName, sampleTemplateId, questionLinkWeights }) => {
					let data = {
						employee_ids: employeeIds,
						group_id: null,
						new_group_name: groupName,
						sample_template_id: sampleTemplateId,
						question_link_weights: questionLinkWeights,
					}
					return httpPost(
						`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/move_employee_group/`,
						data
					).then(({ success }) => ({
						success,
					}))
				},
				groups: {
					getSessionGroups: sessionId => {
						return httpGet(`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/`).then(
							({ success, data }) => ({
								success,
								data: data.map(item => new AppraisalAdminScoring.AppraisalGroup(item)),
							})
						)
					},
					getTemplate: (sessionId, groupId) => {
						return httpGet(
							`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/get_template/`
						).then(({ success, data }) => ({
							success,
							data: new AppraisalAdminScoring.TemplateWithQuestion(data),
						}))
					},
					editTemplateWeight: (sessionId, groupId, { questionLinkWeights }) => {
						let data = { question_link_weights: questionLinkWeights }
						return httpPost(
							`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/edit_template_weight/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminScoring.TemplateWithQuestion(data),
							errors,
						}))
					},
					group_stage_appraiser_types: {
						editProportion: (sessionId, groupId, groupStageAppraiserTypeId, proportion) => {
							let data = { proportion }
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/group_stage_appraiser_types/${groupStageAppraiserTypeId}/edit_proportion/`,
								data
							).then(({ success }) => ({
								success,
							}))
						},
					},
					stages: {
						get: (sessionId, groupId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupId}/stages/`
							).then(({ success, data }) => ({
								success,
								data: data.map(item => new AppraisalAdminScoring.GroupStage(item)),
							}))
						},
					},
					individual: {
						getSessionEmployeesIndividualView: (sessionId, groupId, page = 1, name = '') => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/?page=${page}${name &&
									`&name=${name}`}`
							).then(({ success, data, errors, pagination }) => ({
								success,
								data: data.map(item => new AppraisalAdminScoring.EmployeeWithScoreCardStatus(item)),
								errors,
								pagination: new Pagination(pagination),
							}))
						},
						getSessionEmployeesIndividualViewDetail: (sessionId, groupId, employeeId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/`
							).then(({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminScoring.AppraiseeScoring(data),
								errors,
							}))
						},
						getSnapshot: (sessionId, groupId, employeeId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/snapshot/get/`
							).then(({ data }) => ({
								data: data.map(item => new AppraisalAdminScoring.AnswerSnapshot(item)),
							}))
						},
						update: (sessionId, groupId, employeeId, data) => {
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/snapshot/update_single/`,
								data
							).then(({ success, data, errors }) => ({
								success,
								data: data.map(item => new AppraisalAdminScoring.AnswerSnapshot(item)),
								errors,
							}))
						},
						saveProgress: (sessionId, groupId, employeeId) => {
							let data = {}
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/snapshot/save_progress/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						cancel: (sessionId, groupId, employeeId) => {
							let data = {}
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/snapshot/cancel/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						submit: (sessionId, groupId, employeeId, data) => {
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/individual/${groupId}/employees/${employeeId}/submit/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
					},
					custom: {
						getSessionEmployeesCustomView: (sessionId, groupId, groupType, page = 1, name = '') => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/?page=${page}${name &&
									`&name=${name}`}`
							).then(({ success, data, errors }) => ({
								success,
								data: data
									.sort((a, b) => a.name.localeCompare(b.name))
									.map(item => new AppraisalAdminScoring.EmployeeWithScoreCardStatus(item)),
								errors,
							}))
						},
						getSessionEmployeesCustomViewDetail: (sessionId, groupId, employeeId, groupType) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/`
							).then(({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminScoring.AppraiseesCustomEmployeeScoring(data),
								errors,
							}))
						},
						getSnapshot: (sessionId, groupId, employeeId, groupType) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/snapshot/get/`
							).then(({ data }) => ({
								data: data.map(item => new AppraisalAdminScoring.SnapshotsWithEmployeeId(item)),
							}))
						},
						saveProgress: (sessionId, groupId, employeeId, groupType) => {
							let data = {}
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/snapshot/save_progress/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						cancel: (sessionId, groupId, employeeId, groupType) => {
							let data = {}
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/snapshot/cancel/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						submit: (sessionId, groupId, employeeId, formData, groupType) => {
							let data = {
								employees_answers: formData,
							}
							return httpPost(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/${groupType}/${groupId}/employees/${employeeId}/submit/`,
								data
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
					},
					superior: {
						getSessionEmployeesSuperiorView: (sessionId, groupId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/superior/${groupId}/employees/`
							).then(({ success, data, errors }) => ({
								success,
								data: data.map(item => new AppraisalAdminScoring.EmployeeWithScoreCardStatus(item)),
								errors,
							}))
						},
						getSessionEmployeesSuperiorViewDetail: (sessionId, groupId, employeeId) => {
							return httpGet(
								`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/groups/superior/${groupId}/employees/${employeeId}/`
							).then(({ success, data, errors }) => ({
								success,
								data: new AppraisalAdminScoring.AppraiseesSuperiorScoring(data),
								errors,
							}))
						},
					},
				},
				templates: {
					getDetail: (sessionId, templateId) => {
						return httpGet(
							`/api/appraisal_admin/appraisal_scoring/sessions/${sessionId}/templates/${templateId}/`
						).then(({ success, data, errors }) => ({
							success,
							data: new AppraisalAdminScoring.TemplateWithQuestion(data),
							errors,
						}))
					},
				},
			},
			settings: {
				getCurrentTableHeader: () => {
					return httpGet(`/api/appraisal_admin/appraisal_scoring/settings/get_current_table_header/`).then(
						({ success, data }) => ({
							success,
							data,
						})
					)
				},
				editSettingTableHeader: (tableHeaders = [1, 2, 3]) => {
					let data = {
						table_header: tableHeaders,
					}
					return httpPost(`/api/appraisal_admin/appraisal_scoring/settings/edit_setting_table_header/`, data).then(
						({ success }) => ({
							success,
						})
					)
				},
			},
		},
	},
	brainstorm: {
		questions: {
			get: (audienceType, page = 1) => {
				let apiUrl = `/api/brainstorm/questions/?page=${page}`
				if (audienceType) {
					apiUrl += `&audience_type=${audienceType}`
				}
				return httpGet(apiUrl).then(({ success, data, pagination, errors }) => ({
					success,
					data: data.map(item => new Brainstorming.BrainstormQuestion(item)),
					pagination: new Pagination(pagination),
					errors,
				}))
			},
			getMyQuestions: (page = 1) => {
				return httpGet(`/api/brainstorm/questions/my_questions/?page=${page}`).then(
					({ success, data, pagination, errors }) => ({
						success,
						data: data.map(item => new Brainstorming.BrainstormQuestion(item)),
						pagination: new Pagination(pagination),
						errors,
					})
				)
			},
			getAnsweredQuestions: (page = 1) => {
				return httpGet(`/api/brainstorm/questions/answered_questions/?page=${page}`).then(
					({ success, data, pagination, errors }) => ({
						success,
						data: data.map(item => new Brainstorming.BrainstormQuestion(item)),
						pagination: new Pagination(pagination),
						errors,
					})
				)
			},
			getDetail: questionId => {
				return httpGet(`/api/brainstorm/questions/${questionId}/`).then(({ success, data, errors }) => ({
					success,
					data: new Brainstorming.BrainstormQuestionDetail(data),
					errors,
				}))
			},
			getMyAnswers: questionId => {
				return httpGet(`/api/brainstorm/questions/${questionId}/get_my_answers/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new Brainstorming.BrainstormAnswer(item)),
					errors,
				}))
			},
			getOtherAnswers: questionId => {
				return httpGet(`/api/brainstorm/questions/${questionId}/get_other_answers/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new Brainstorming.BrainstormAnswer(item)),
						errors,
					})
				)
			},
			create: formData => {
				return httpPostMultipart(`/api/brainstorm/questions/create/`, formData).then(({ success, data, errors }) => ({
					success,
					data: new Brainstorming.BrainstormQuestionDetail(data),
					errors,
				}))
			},
			answerQuestion: (questionId, content) => {
				let data = { content }
				return httpPost(`/api/brainstorm/questions/${questionId}/answer_question/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new Brainstorming.BrainstormAnswer(data),
						errors,
					})
				)
			},
			toggleNotification: (questionId, isActive) => {
				let data = {
					is_active: isActive,
				}
				return httpPost(`/api/brainstorm/questions/${questionId}/toggle_notification/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			changeStatus: (questionId, status) => {
				let data = {
					status,
				}
				return httpPost(`/api/brainstorm/questions/${questionId}/change_status/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new Brainstorming.BrainstormQuestion(data),
						errors,
					})
				)
			},
		},
	},
	company_policies: {
		get: () => {
			return httpGet(`/api/company_policies/`).then(({ success, data }) => ({
				success,
				data: data.map(item => new CompanyPolicy.CompanyPolicy(item)),
			}))
		},
		getDetail: policyId => {
			return httpGet(`/api/company_policies/${policyId}/`).then(({ success, data, errors }) => ({
				success,
				data: new CompanyPolicy.CompanyPolicyDetail(data),
				errors,
			}))
		},
		create: ({ title, content = 'Policy content', attachment = null }) => {
			let data = {
				title,
				content,
				attachment,
			}
			return httpPost(`/api/company_policies/create/`, data).then(({ success, data, errors }) => ({
				success,
				data: new CompanyPolicy.CompanyPolicyDetail(data),
				errors,
			}))
		},
		edit: (policyId, { title, content, attachment }) => {
			let data = {
				title,
				content,
				attachment,
			}
			return httpPost(`/api/company_policies/${policyId}/edit/`, data).then(({ success, data, errors }) => ({
				success,
				data: new CompanyPolicy.CompanyPolicyDetail(data),
				errors,
			}))
		},
		delete: policyId => {
			return httpPost(`/api/company_policies/${policyId}/delete/`).then(({ success, errors }) => ({
				success,
				errors,
			}))
		},
	},
	dashboard: {
		getCompanyDetails: () => {
			return httpGet(`/api/dashboard/get_company_details/`).then(({ success, data }) => ({
				success,
				data: new Dashboard.Company(data),
			}))
		},
		getBirthdays: () => {
			return httpGet(`/api/dashboard/get_birthdays/`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new Dashboard.Employee(item)),
				errors,
			}))
		},
		createBirthdayNote: (employeeId, note) => {
			let data = { note }
			return httpPost(`/api/dashboard/create_birthday_note/${employeeId}/`, data).then(({ success, errors }) => ({
				success,
				errors,
			}))
		},
		achievements: {
			create: formData => {
				return httpPost(`/api/dashboard/create/`, formData).then(({ success, data }) => ({
					success,
					data: new Dashboard.Achievement(data),
				}))
			},
		},
		announcements: {
			get: (page = 1) => {
				return httpGet(`/api/dashboard/announcements/?page=${page}`).then(({ success, data, pagination }) => ({
					success,
					data: data.map(item => new Dashboard.Announcement(item)),
					pagination: new Pagination(pagination),
				}))
			},
		},
		ideas: {
			create: formData => {
				return httpPostMultipart(`/api/dashboard/ideas/create/`, formData).then(({ success, data }) => ({
					success,
					data: new Dashboard.Idea(data),
				}))
			},
		},
		news: {
			get: (category = -1, page = 1) => {
				let apiUrl = `/api/dashboard/news/?page=${page}`
				if (category !== -1) {
					apiUrl += `&category=${category}`
				}
				return httpGet(apiUrl).then(({ success, data, pagination }) => ({
					success,
					data: data.map(item => new Dashboard.News(item)),
					pagination: new Pagination(pagination),
				}))
			},
			create: data => {
				return httpPost(`/api/dashboard/news/create/`, data).then(({ success, data }) => ({
					success,
					data: new Dashboard.News(data),
				}))
			},
			edit: (newsId, url) => {
				let data = { url }
				return httpPost(`/api/dashboard/news/${newsId}/edit/`, data).then(({ success, data, errors }) => ({
					success,
					data: new Dashboard.News(data),
					errors,
				}))
			},
			delete: newsId => {
				return httpPost(`/api/dashboard/news/${newsId}/delete/`).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
		},
	},
	engagement: {
		getTelegramRequests: () => {
			return httpGet(`/api/engagement/get_telegram_requests/`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Engagement.TelegramRequest(item)),
			}))
		},
		getIdeaReport: (startDate, endDate) => {
			return httpGet(`/api/engagement/get_idea_report/?start_date=${startDate}&end_date=${endDate}`).then(
				({
					data: {
						summary_by_month: summaryByMonth,
						summary,
						summary_by_employee: summaryByEmployee,
						no_response_reviewer: noResponseReviewer,
					},
				}) => ({
					data: {
						summaryByMonth: summaryByMonth
							.sort((a, b) => moment(a.date, 'MM-YYYY').format('X') - moment(b.date, 'MM-YYYY').format('X')) // sort by date
							.map(item => new Engagement.IdeaReportDate(item)),
						summary: new Engagement.IdeaReportSummary(summary),
						summaryByEmployee: summaryByEmployee.map(item => new Engagement.IdeaReportDetail(item)),
						noResponseReviewer: noResponseReviewer.map(item => new Engagement.NoResponseReviewer(item)),
					},
				})
			)
		},
		getBrainstormReport: (startDate, endDate) => {
			return httpGet(`/api/engagement/get_brainstorm_report/?start_date=${startDate}&end_date=${endDate}`).then(
				({ data: { summary_by_month: summaryByMonth, details, summary } }) => ({
					data: {
						summaryByMonth: summaryByMonth.map(item => new Engagement.BrainstormReportDate(item)),
						details: details.map(item => new Engagement.BrainstormQuestionReport(item)),
						summary: new Engagement.BrainstormSummaryReport(summary),
					},
				})
			)
		},
		telegramRequests: {
			approve: telegramRequestId => {
				return httpPost(`/api/engagement/telegram_requests/${telegramRequestId}/approve/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			reject: telegramRequestId => {
				return httpPost(`/api/engagement/telegram_requests/${telegramRequestId}/reject/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
		},
	},
	experience: {
		experience_setting: {
			get: () => {
				return httpGet(`/api/experience/experience_setting/`).then(({ success, data, errors }) => ({
					success,
					data: new Experience.ExperienceSetting(data),
					errors,
				}))
			},
		},
		experience_requests: {
			my_pending_submissions: {
				get: () => {
					return httpGet(`/api/experience/experience_requests/my_pending_submissions/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Experience.SubordinateExperienceRequest(item)),
							errors,
						})
					)
				},
				approve: (requestId, { approvedHours, notes = '' }) => {
					let data = {
						approved_hours: approvedHours,
						notes,
					}
					return httpPost(
						`/api/experience/experience_requests/my_pending_submissions/${requestId}/approve/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				decline: (requestId, notes = '') => {
					let data = { notes }
					return httpPost(
						`/api/experience/experience_requests/my_pending_submissions/${requestId}/decline/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
			},
			all_pending_submissions: {
				get: (param = '') => {
					return httpGet(`/api/experience/experience_requests/all_pending_submissions/${param}`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Experience.SubordinateExperienceRequest(item)),
							errors,
						})
					)
				},
			},
			approvee_requests: {
				getApprovees: (param = '') => {
					return httpGet(`/api/experience/experience_requests/approvee_requests/get_approvees/${param}`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Experience.EmployeeWithWallets(item)),
							errors,
						})
					)
				},
				getExperienceTypes: (employeeId, date) => {
					return httpGet(
						`/api/experience/experience_requests/approvee_requests/${employeeId}/get_experience_types/?date=${format(
							date,
							'YYYY-MM-DD'
						)}`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Experience.ExperienceWallet(item)),
						errors,
					}))
				},
				getWorkingCycleForDate: (employeeId, date) => {
					return httpGet(
						`/api/experience/experience_requests/approvee_requests/${employeeId}/get_working_cycles_for_date/?date=${format(
							date,
							'YYYY-MM-DD'
						)}`
					).then(({ success, data, errors }) => ({
						success,
						data: data ? new WorkingCycleSetting.WorkingCycleType(data) : null,
						errors,
					}))
				},
				create: (employeeId, { date, experienceWalletId, submittedHours, notes = '' }) => {
					let data = {
						date: format(date, 'YYYY-MM-DD'), // YYYY-MM-DD
						experience_wallet_id: experienceWalletId,
						submitted_hours: submittedHours,
						notes,
					}
					return httpPost(`/api/experience/experience_requests/approvee_requests/${employeeId}/create/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Experience.SubordinateExperienceRequest(data),
							errors,
						})
					)
				},
			},
			my_experiences: {
				get: () => {
					return httpGet(`/api/experience/experience_requests/my_experiences/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Experience.MyExperienceRequest(item)),
						errors,
					}))
				},
				getExperienceTypes: date => {
					return httpGet(
						`/api/experience/experience_requests/my_experiences/get_experience_types/?date=${format(
							date,
							'YYYY-MM-DD'
						)}`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Experience.ExperienceWallet(item)),
						errors,
					}))
				},
				getWorkingCycleForDate: date => {
					return httpGet(
						`/api/experience/experience_requests/my_experiences/get_working_cycle_for_date/?date=${format(
							date,
							'YYYY-MM-DD'
						)}`
					).then(({ success, data, errors }) => ({
						success,
						data: data ? new WorkingCycleSetting.WorkingCycleType(data) : null,
						errors,
					}))
				},
				create: ({ date, experienceWalletId, submittedHours, notes = '' }) => {
					let data = {
						date: format(date, 'YYYY-MM-DD'), // YYYY-MM-DD
						experience_wallet_id: experienceWalletId,
						submitted_hours: submittedHours,
						notes,
					}
					return httpPost(`/api/experience/experience_requests/my_experiences/create/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Experience.MyExperienceRequest(data),
							errors,
						})
					)
				},
			},
		},
	},
	hr_timeoff: {
		getTimeoffFeeds: () => {
			return httpGet(`/api/hr_timeoff/timeoff_feeds/`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new HrTimeoff.TimeoffFeed(item)),
				errors,
			}))
		},
		getTimeoffCalendar: (param = '') => {
			// params:
			// filter_type @ALL_REQUEST_FILTER_TYPE
			// filter_value
			// page
			// limit
			return httpGet(`/api/hr_timeoff/timeoff_calendar/${param}`).then(({ success, data, errors, pagination }) => ({
				success,
				data: data.map(item => new HrTimeoff.EmployeeCalendar(item)),
				errors,
				pagination: new Pagination(pagination),
			}))
		},
		getTimeoffCalendarFilters: filterType => {
			// params:
			// filter_type @ALL_REQUEST_FILTER_TYPE
			let param = ''
			if (filterType) {
				param = `?filter_type=${filterType}`
			}
			return httpGet(`/api/hr_timeoff/timeoff_calendar/get_filters/${param}`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new HrTimeoff.TimeoffCalendarFilter(item)),
				errors,
			}))
		},
		getAllRequests: (param = '') => {
			// params:
			// filter_type @ALL_REQUEST_FILTER_TYPE
			// filter_value
			// page
			// limit
			return httpGet(`/api/hr_timeoff/all_requests/${param}`).then(({ success, data, errors, pagination }) => ({
				success,
				data: data.map(item => new HrTimeoff.AllRequest(item)),
				errors,
				pagination: new Pagination(pagination),
			}))
		},
		getAllRequestFilters: filterType => {
			// params:
			// filter_type @ALL_REQUEST_FILTER_TYPE
			let param = ''
			if (filterType) {
				param = `?filter_type=${filterType}`
			}
			return httpGet(`/api/hr_timeoff/all_requests/get_filters/${param}`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new HrTimeoff.TimeoffCalendarFilter(item)),
				errors,
			}))
		},
		timeoff_requests: {
			getById: timeoffRequestId => {
				return httpGet(`/api/hr_timeoff/timeoff_requests/${timeoffRequestId}/`).then(({ success, data, errors }) => ({
					success,
					data: new HrTimeoff.TimeoffFeedWithCalendar(data),
					errors,
				}))
			},
			confirm: timeoffRequestId => {
				return httpPost(`/api/hr_timeoff/timeoff_requests/${timeoffRequestId}/confirm/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			createComment: (timeoffRequestId, comment) => {
				let data = { comment }
				return httpPost(`/api/hr_timeoff/timeoff_requests/${timeoffRequestId}/comments/create/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new HrTimeoff.TimeoffComment(data),
						errors,
					})
				)
			},
		},
		employee_profile: {
			get: employeeId => {
				return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/`).then(({ success, data, errors }) => ({
					success,
					data: new HrTimeoff.EmployeeProfile(data),
					errors,
				}))
			},
			getBalances: (employeeId, param = '') => {
				// params: start_date, end_date
				return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/balances/${param}`).then(
					({ success, data, errors }) => ({
						success,
						data: data
							.map(item => new HrTimeoff.EmployeeBalance(item)),
						errors,
					})
				)
			},
			createManualAdjustment: (employeeId, { timeoffTypeId, balance, notes }) => {
				let data = {
					timeoff_type_id: timeoffTypeId,
					balance,
					notes,
				}
				return httpPost(`/api/hr_timeoff/employee_profile/${employeeId}/create_manual_adjustment/`, data).then(
					({ success, data }) => ({
						success,
						data: new HrTimeoff.WalletLog(data),
					})
				)
			},
			getCreateTimeoffBalances: employeeId => {
				return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/create_timeoff/get_balances/`).then(
					({ success, data, errors }) => ({
						success,
						data: [
							...data.no_wallet_rules.map(item => new HrTimeoff.TimeoffWallet(item)),
							...data.wallets.map(item => new HrTimeoff.TimeoffWallet(item)),
						],
						errors,
					})
				)
			},
			createTimeoff: (employeeId, { timeoffWalletId, startDate, endDate, startHalfDay, endHalfDay, comment }) => {
				let data = {
					timeoff_wallet_id: timeoffWalletId,
					start_date: format(startDate, 'YYYY-MM-DD'),
					end_date: format(endDate, 'YYYY-MM-DD'),
					start_half_day: startHalfDay,
					end_half_day: endHalfDay,
					comment,
				}
				return httpPost(`/api/hr_timeoff/employee_profile/${employeeId}/create_timeoff/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new HrTimeoff.EmployeePeriodRequest(data),
						errors,
					})
				)
			},
			calculateDays: (employeeId, { startDate, endDate, startHalfDay, endHalfDay }) => {
				let data = {
					start_date: format(startDate, 'YYYY-MM-DD'),
					end_date: format(endDate, 'YYYY-MM-DD'),
					start_half_day: startHalfDay,
					end_half_day: endHalfDay,
				}
				return httpPost(`/api/hr_timeoff/employee_profile/${employeeId}/create_timeoff/calculate_days/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new HrTimeoff.DayCount(data),
						errors,
					})
				)
			},
			activities: {
				get: (employeeId, param = '') => {
					// param: start_date, end_date, page, limit, timeoff_type_id
					return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/activities/${param}`).then(
						({ success, data, errors, pagination }) => ({
							success,
							data: new HrTimeoff.WalletLogWithInitial(data),
							errors,
							pagination: new Pagination(pagination),
						})
					)
				},
				getTimeoffTypes: employeeId => {
					return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/activities/get_timeoff_types/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new HrTimeoff.TimeoffType(item)).sort(alphabetical({ path: 'name' })),
							errors,
						})
					)
				},
			},
			requests: {
				get: (employeeId, param = '') => {
					// param: start_date, end_date, page, limit, timeoff_status @TIMEOFF_REQUEST_STATUS
					return httpGet(`/api/hr_timeoff/employee_profile/${employeeId}/requests/${param}`).then(
						({ success, data, errors, pagination }) => ({
							success,
							data: data.map(item => new HrTimeoff.EmployeePeriodRequest(item)),
							errors,
							pagination: new Pagination(pagination),
						})
					)
				},
				edit: (employeeId, timeoffRequestId, { startDate, endDate, startHalfDay, endHalfDay, comment }) => {
					let data = {
						start_date: format(startDate, 'YYYY-MM-DD'),
						end_date: format(endDate, 'YYYY-MM-DD'),
						start_half_day: startHalfDay,
						end_half_day: endHalfDay,
						comment,
					}
					return httpPost(
						`/api/hr_timeoff/employee_profile/${employeeId}/requests/${timeoffRequestId}/edit/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new HrTimeoff.EmployeePeriodRequest(data),
						errors,
					}))
				},
				cancel: (employeeId, timeoffRequestId) => {
					return httpPost(`/api/hr_timeoff/employee_profile/${employeeId}/requests/${timeoffRequestId}/cancel/`).then(
						({ success, data, errors }) => ({
							success,
							data: new HrTimeoff.EmployeePeriodRequest(data),
							errors,
						})
					)
				},
			},
		},
	},
	hr_experience: {
		getRanks: () => {
			return httpGet(`/api/hr_experience/ranks/`).then(({ success, data, errors }) => ({
				success,
				data: data.sort(by('order')).map(item => new HrExperience.RankWithOrder(item)),
				errors,
			}))
		},
		getGroupLeaderRoles: () => {
			return httpGet(`/api/hr_experience/group_leader_roles/`).then(({ success, data, errors }) => ({
				success,
				data: data.map(item => new HrExperience.ExperienceTypeWithCount(item)),
				errors,
			}))
		},
		experience_types: {
			get: () => {
				return httpGet(`/api/hr_experience/experience_types/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new HrExperience.ExperienceTypeWithCount(item)),
					errors,
				}))
			},
			create: ({ name, code, notes }) => {
				let data = { name, code, notes }
				return httpPost(`/api/hr_experience/experience_types/create/`, data).then(({ success, data, errors }) => ({
					success,
					data: new HrExperience.ExperienceType(data),
					errors,
				}))
			},
			archive: experienceTypeId => {
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/archive/`).then(
					({ success, data, errors }) => ({
						success,
						data: new HrExperience.ExperienceType(data),
						errors,
					})
				)
			},
			unarchive: experienceTypeId => {
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/unarchive/`).then(
					({ success, data, errors }) => ({
						success,
						data: new HrExperience.ExperienceType(data),
						errors,
					})
				)
			},
			edit: (experienceTypeId, { notes }) => {
				let data = { notes }
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/edit/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new HrExperience.ExperienceType(data),
						errors,
					})
				)
			},
			delete: experienceTypeId => {
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/delete/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			moveUp: experienceTypeId => {
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/move_up/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			moveDown: experienceTypeId => {
				return httpPost(`/api/hr_experience/experience_types/${experienceTypeId}/move_down/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
		},
		employees: {
			get: () => {
				return httpGet(`/api/hr_experience/employees/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new HrExperience.EmployeeWithApprovers(item)),
					errors,
				}))
			},
			getDetail: employeeId => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/`).then(({ success, data, errors }) => ({
					success,
					data: new HrExperience.EmployeeProfile(data),
					errors,
				}))
			},
			getActivities: employeeId => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/get_activities/`).then(({ success, data }) => ({
					success,
					data: data.map(item => new HrExperience.ExperienceRequestLog(item)),
				}))
			},
			getCharts: employeeId => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/get_charts/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new HrExperience.WalletCalendar(item)),
					errors,
				}))
			},
			getCalendars: (employeeId, param = '') => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/get_calendars/${param}`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.WalletCalendar(item)),
						errors,
					})
				)
			},
			getFilters: () => {
				return httpGet(`/api/hr_experience/employees/get_filters/`).then(({ success, data }) => ({
					success,
					data: {
						positions: data.positions
							.sort(alphabetical({ path: 'name' }))
							.map(item => new Announcement.Department(item)),
						departments: data.departments
							.sort(alphabetical({ path: 'name' }))
							.map(item => new Announcement.Department(item)),
						groups: data.groups.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Group(item)),
						superiors: data.superiors.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Superior(item)),
						titles: data.titles.sort(alphabetical({ path: 'name' })).map(item => new Announcement.Title(item)),
						ranks: data.ranks
							.sort(alphabetical({ path: 'name' }))
							.map(item => new AppraisalAdminSession.FilterItem(item)),
					},
				}))
			},
			getUnaddedEmployees: (param = '') => {
				return httpGet(`/api/hr_experience/employees/get_unadded_employees/${param}`).then(({ success, data }) => ({
					success,
					data: data.map(item => new HrExperience.EmployeeDetail(item)),
				}))
			},
			getActiveExperienceWallets: employeeId => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/get_active_experience_wallets/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.ActiveWallet(item)),
						errors,
					})
				)
			},
			getAllExperienceWalletSummaries: employeeId => {
				return httpGet(`/api/hr_experience/employees/${employeeId}/get_all_experience_wallet_summaries/`).then(
					({ success, data, errors }) => ({
						success,
						data: {
							hasAccessToExperience: data.has_access_to_experience,
							wallets: data.wallets
								.sort(alphabetical({ path: 'type.name' }))
								.map(item => new HrExperience.ExperienceWalletSummary(item)),
						},
						errors,
					})
				)
			},
			removeEmployees: employeeIds => {
				let data = {
					employee_ids: employeeIds,
				}
				return httpPost(`/api/hr_experience/employees/remove_employees/`, data).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
			editExperiences: (employeeId, experienceTypeIds) => {
				let data = { experience_type_ids: experienceTypeIds }
				return httpPost(`/api/hr_experience/employees/${employeeId}/edit_experiences/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			editApprovers: (employeeId, approvers) => {
				let data = { experience_approvers: approvers.map(item => item && new HrExperience.EditApprover(item)) }
				return httpPost(`/api/hr_experience/employees/${employeeId}/edit_approvers/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.Approver(item)),
						errors,
					})
				)
			},
			add_employees: {
				getExperienceTypesFilter: () => {
					return httpGet(`/api/hr_experience/employees/add_employees/get_experience_types_filter/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new HrExperience.ExperienceType(item)),
						})
					)
				},
				getApproversFilter: () => {
					return httpGet(`/api/hr_experience/employees/add_employees/get_approvers_filter/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new HrExperience.Employee(item)),
						})
					)
				},
				post: employees => {
					let data = {
						employees: employees.map(employee => ({
							id: employee.id,
							experience_type_ids: employee.experienceTypeIds,
							experience_approvers: employee.approvers.map(item => item && new HrExperience.EditApprover(item)),
						})),
					}
					return httpPost(`/api/hr_experience/employees/add_employees/`, data).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.EmployeeWithApprovers(item)),
						errors,
					}))
				},
			},
			experience_requests: {
				get: (employeeId, date) => {
					// date format : 'YYYY-MM-DD'
					return httpGet(`/api/hr_experience/employees/${employeeId}/experience_requests/?date=${date}`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new HrExperience.ExperienceRequestWithApproverScore(item)),
							errors,
						})
					)
				},
				getActivities: (employeeId, date) => {
					// date format : 'YYYY-MM-DD'
					return httpGet(
						`/api/hr_experience/employees/${employeeId}/experience_requests/get_activities/?date=${date}`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.ExperienceRequestLog(item)),
						errors,
					}))
				},
				getExperienceTypes: (employeeId, date) => {
					// date format : 'YYYY-MM-DD'
					return httpGet(
						`/api/hr_experience/employees/${employeeId}/experience_requests/get_experience_types/?date=${date}`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new HrExperience.ExperienceWallet(item)),
						errors,
					}))
				},
				getWorkingCycleForDate: (employeeId, date) => {
					// date format : 'YYYY-MM-DD'
					return httpGet(
						`/api/hr_experience/employees/${employeeId}/experience_requests/get_working_cycle_for_date/?date=${date}`
					).then(({ success, data, errors }) => ({
						success,
						data: data ? new HrExperience.WorkingCycleType(data) : null, // null if 'Off' shift on that date
						errors,
					}))
				},
				create: (employeeId, { date, experienceWalletId, submittedHours, notes = '' }) => {
					let data = {
						date,
						experience_wallet_id: experienceWalletId,
						submitted_hours: submittedHours,
						notes,
					}
					return httpPost(`/api/hr_experience/employees/${employeeId}/experience_requests/create/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new HrExperience.ExperienceRequest(data),
							errors,
						})
					)
				},
				edit: (employeeId, experienceRequestId, { finalHours, notes = '' }) => {
					let data = {
						final_hours: finalHours,
						notes,
					}
					return httpPost(
						`/api/hr_experience/employees/${employeeId}/experience_requests/${experienceRequestId}/edit/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new HrExperience.ExperienceRequest(data),
						errors,
					}))
				},
				experience_comments: {
					get: (employeeId, experienceRequestId) => {
						return httpGet(
							`/api/hr_experience/employees/${employeeId}/experience_requests/${experienceRequestId}/experience_comments/`
						).then(({ success, data, errors }) => ({
							success,
							data: data.map(item => new HrExperience.ExperienceRequestComment(item)),
							errors,
						}))
					},
					create: (employeeId, experienceRequestId, comment) => {
						let data = { comment }
						return httpPost(
							`/api/hr_experience/employees/${employeeId}/experience_requests/${experienceRequestId}/experience_comments/create/`,
							data
						).then(({ success, data }) => ({
							success,
							data: new HrExperience.ExperienceRequestComment(data),
						}))
					},
				},
			},
		},
		experience_requests: {
			getPending: () => {
				return httpGet(`/api/hr_experience/experience_requests/pending/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new HrExperience.ExperienceRequest(item)),
					errors,
				}))
			},
		},
		experience_setting: {
			get: () => {
				return httpGet(`/api/hr_experience/experience_setting/`).then(({ success, data, errors }) => ({
					success,
					data: new HrExperience.ExperienceSetting(data),
					errors,
				}))
			},
			edit: ({ employeeAllowedToSubmit, approverAllowedToSubmit, hoursToSubmit, approverType, numberOfApprover }) => {
				let data = {
					employee_allowed_to_submit: employeeAllowedToSubmit,
					approver_allowed_to_submit: approverAllowedToSubmit,
					approver_type: approverType,
					number_of_approver: numberOfApprover,
				}
				if (hoursToSubmit !== undefined) {
					data.hours_to_submit = parseInt(hoursToSubmit, 10)
				}
				return httpPost(`/api/hr_experience/experience_setting/edit/`, data).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
		},
	},
	job_description: {
		myJobInformation: () => {
			return httpGet(`/api/job_description/my_job_information/`).then(({ success, data }) => ({
				success,
				data: new JobDescription.JobInformation(data),
			}))
		},
	},
	offboarding: {
		getPendingOffboardingRequests: () => {
			return httpGet(`/api/offboarding/get_pending_offboarding_requests/`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Offboarding.PendingRequest(item)),
			}))
		},
		getOffboardingReasons: () => {
			return httpGet(`/api/offboarding/offboarding_reasons/`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Offboarding.Reason(item)),
			}))
		},
		getAllEmployees: () => {
			return httpGet(`/api/offboarding/get_active_employees/`).then(({ success, data }) => ({
				success,
				data: data.sort(alphabetical({ path: 'full_name' })).map(item => new Offboarding.Employee(item)),
			}))
		},
		getFeatureReviewerTypes: () => {
			return mockAPI([
				{
					featureName: 'Timeoff',
					reviewerType: 2,
					minReviewer: 2,
					maxReviewer: 1000,
				},
				{
					featureName: 'Idea',
					reviewerType: 1,
					stages: [{ id: 1, name: 'Manager' }, { id: 2, name: 'Supervisor' }],
				},
				{
					featureName: 'Experience',
					reviewerType: 2,
					minReviewer: 2,
					maxReviewer: 2,
				},
			])
			// return httpGet(`/api/offboarding/get_feature_reviewer_types/`)
			// 	.then(({ success, data }) => ({
			// 		success,
			// 		data,
			// 	}))
		},
		getPermissions: () => {
			return mockAPI([
				{
					rootPermissionId: 1,
					rootPermissionName: 'Performance Appraisal Admin',
					permissions: [
						{ featurePermissionId: 1, featurePermissionName: 'Create PA Template ' },
						{ featurePermissionId: 2, featurePermissionName: 'Edit PA Template ' },
					],
				},
				{
					rootPermissionId: 2,
					rootPermissionName: 'Survey Admin',
					permissions: [
						{ featurePermissionId: 3, featurePermissionName: 'Create Survey Session ' },
						{ featurePermissionId: 4, featurePermissionName: 'Edit Survey Session ' },
					],
				},
			])
			// return httpGet(`/api/offboarding/get_permissions/`)
			// 	.then(({ success, data }) => ({
			// 		success,
			// 		data: data.map(item => item),
			// 	}))
		},
		employees: {
			getPendingOffboardingRequest: employeeId => {
				return httpGet(`/api/offboarding/employees/${employeeId}/current_pending_offboarding_request/`).then(
					({ success, data }) => ({
						success,
						data: data ? new Offboarding.PendingRequest(data) : null,
					})
				)
			},
			getEmploymentInfo: employeeId => {
				return httpGet(`/api/offboarding/employees/${employeeId}/get_employment_info/`).then(({ success, data }) => ({
					success,
					data: new Offboarding.EmploymentInfo(data),
				}))
			},
		},
		offboarding_requests: {
			getDetail: requestId => {
				return httpGet(`/api/offboarding/offboarding_requests/${requestId}/`).then(({ success, data, errors }) => ({
					success,
					data: new Offboarding.RequestDetail(data),
					errors,
				}))
			},
			getEmployeeDependencies: requestId => {
				return httpGet(`/api/offboarding/offboarding_requests/${requestId}/get_employee_dependencies/`).then(
					({ success, data }) => ({
						success,
						data: data.map(item => new Offboarding.EmployeeDependency(item)),
					})
				)
			},
			create: (employeeId, { lastDate, reason, notes, attachments }) => {
				let data = {
					employee_id: employeeId,
					last_date: format(lastDate, 'YYYY-MM-DD'),
					reason,
					notes,
					attachments,
				}
				return httpPost(`/api/offboarding/offboarding_requests/create/`, data).then(({ success, data, errors }) => ({
					success,
					data: new Offboarding.PendingRequest(data),
					errors,
				}))
			},
			edit: (requestId, { lastDate, reason, notes, attachments }) => {
				let data = {
					last_date: format(lastDate, 'YYYY-MM-DD'),
					reason,
					notes,
					attachments,
				}
				return httpPost(`/api/offboarding/offboarding_requests/${requestId}/edit/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new Offboarding.RequestDetail(data),
						errors,
					})
				)
			},
			cancel: requestId => {
				return httpPost(`/api/offboarding/offboarding_requests/${requestId}/cancel/`).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
			process: (requestId, data) => {
				return httpPost(`/api/offboarding/offboarding_requests/${requestId}/process/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
		},
		getOnboardingFields: () => {
			return mockAPI({
				accountAndPersonalInfo: [
					{
						field: { id: 1, name: 'Username', order: 1, allowMultipleAnswers: false },
						question: { id: 1, name: 'Username', type: QUESTION_TYPE.TEXT },
					},
				],
				employmentInfo: [
					{
						field: { id: 2, name: 'Position', order: 1 },
						question: {
							id: 2,
							name: 'Position',
							type: QUESTION_TYPE.MULTIPLE_CHOICE,
							choices: [{ id: 1, content: 'On top' }, { id: 1, content: 'At the bottom' }],
						},
					},
				],
			})
			// return httpGet(`/api/offboarding/get_onboarding_fields/`)
			// 	.then(({ success, data }) => ({
			// 		success,
			// 		data: data.map(item => item),
			// 	}))
		},
		onboardEmployee: data => {
			return mockAPI({ success: true, data })
			// return httpPost(`/api/offboarding/onboard_employee/`, data)
			// .then(({ success, errors }) => ({
			// 	success,
			// 	errors,
			// }))
		},
	},
	setting: {
		dashboard: {
			getVisionMission: () => {
				return httpGet('/api/setting/dashboard/get_vision_and_mission/').then(({ success, data }) => ({
					success,
					data: new SettingDashboard.VisionAndMission(data),
				}))
			},
			getCompanySettingLogs: (page = 1) => {
				return httpGet(`/api/setting/dashboard/get_company_setting_logs/?page=${page}`).then(
					({ success, data, pagination }) => ({
						success,
						data: data.map(item => new SettingDashboard.CompanySettingLog(item)),
						pagination: new PaginationV2(pagination),
					})
				)
			},
			editVisionMission: ({ vision, mission, readMore }) => {
				const data = {
					vision,
					mission,
					read_more: readMore,
				}
				return httpPost('/api/setting/dashboard/edit_vision_and_mission/', data).then(({ success, data }) => ({
					success,
					data: new SettingDashboard.VisionAndMission(data),
				}))
			},
			editCompanySetting: ({ companyLogo, birthdaySetting }) => {
				const data = {
					company_logo: companyLogo,
					birthday_setting: birthdaySetting,
				}
				return httpPost(`/api/setting/dashboard/edit_company_setting/`, data).then(({ success }) => ({
					success,
				}))
			},
		},
		permission: {
			get_employees_with_permissions: (param = '') => {
				let PermissionWithExt = ({ permission_id, below_rank_id }) => ({
					id: permission_id,
					belowRankId: below_rank_id,
				})
				let EmployeeWithPermissions = ({ id, name, NIK, permissions }) => ({
					id,
					name,
					NIK,
					permissions: permissions.map(item => PermissionWithExt(item)),
				})

				return httpGet(`/api/setting/permission/get_employees_with_permissions/${param}`).then(
					({ success, data, errors, pagination }) => ({
						success: success,
						data: data.map(item => EmployeeWithPermissions(item)),
						pagination: new Pagination(pagination),
						errors: errors,
					})
				)
			},
			get_ranks: () => {
				return httpGet('/api/setting/permission/get_ranks/').then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingEmploymentInfo.RankCount(item)),
				}))
			},
			get_ranks_with_permissions: page => {
				let PermissionWithExt = ({ permission_id, ext_rank_level }) => ({
					id: permission_id,
					extRankLevel: ext_rank_level,
				})
				let RankWithPermissions = ({ id, name, level, permissions }) => ({
					id,
					name,
					level,
					permissions: permissions.map(item => PermissionWithExt(item)),
				})

				return httpGet(`/api/setting/permission/get_ranks_with_permissions?page=${page}`).then(response => ({
					success: response.success,
					data: response.data.map(item => RankWithPermissions(item)),
				}))
			},
			get_titles: () => {
				return httpGet('/api/setting/permission/get_titles/').then(({ success, data }) => ({
					success,
					data: data.map(item => new Setting.Title(item)),
				}))
			},
			get_groups: () => {
				return httpGet('/api/setting/permission/get_groups/').then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingEmploymentInfo.GroupCount(item)),
				}))
			},
			get_departments: () => {
				return httpGet('/api/setting/permission/get_departments/').then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingEmploymentInfo.DepartmentCount(item)),
				}))
			},
			get_positions: () => {
				return httpGet('/api/setting/permission/get_positions/').then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingEmploymentInfo.PositionCount(item)),
				}))
			},
			getActiveEmployees: () => {
				return httpGet('/api/setting/permission/get_active_employees/').then(({ success, data }) => ({
					success,
					data: data.sort(alphabetical({ path: 'name' })).map(item => new SettingEmploymentInfo.EmployeeSimple(item)),
				}))
			},
			employees: {
				get_available_permissions: parent => {
					return httpGet(`/api/setting/permission/employees/get_available_permissions/?parent=${parent}`).then(
						response => ({
							success: response.success,
							permissions: response.data.map(item => new SettingPermissions.Permission(item)),
						})
					)
				},
				getEmployeeWithPermissions: employeeId => {
					return httpGet(`/api/setting/permission/employees/${employeeId}/get_employee_with_permissions/`).then(
						({ success, data, errors }) => ({
							success,
							data: new SettingPermissions.EmployeeWithPermissions(data),
							errors,
						})
					)
				},
				edit_permissions: (employeeId, permissions) => {
					let data = {
						permissions: permissions.map(permission => ({
							permission_id: permission.id,
							below_rank_id: permission.belowRankId,
						})),
					}

					return httpPost(`/api/setting/permission/employees/${employeeId}/edit_permissions/`, data).then(response => ({
						success: response.success,
						errors: response.errors,
					}))
				},
			},
			ranks: {
				get_available_permissions: () => {
					let Permission = ({ id, name, description }) => ({ id, name, description })

					return httpGet(`/api/setting/permission/ranks/get_available_permissions/`).then(response => ({
						success: response.success,
						permissions: response.data.map(item => Permission(item)),
					}))
				},
				edit_permissions: (rankId, permissions) => {
					let data = { permissions }

					return httpPost(`/api/setting/permission/ranks/${rankId}/edit_permissions/`, data).then(response => ({
						success: response.success,
						errors: response.errors,
					}))
				},
				create: name => {
					let data = { name }
					return httpPost(`/api/setting/permission/ranks/create/`, data).then(response => ({
						success: response.success,
						errors: response.errors,
						data: new SettingEmploymentInfo.RankCount(response.data),
					}))
				},
				edit: (rankId, name) => {
					let data = { name }
					return httpPost(`/api/setting/permission/ranks/${rankId}/edit/`, data).then(({ success, data, errors }) => ({
						success,
						data: new Setting.Rank(data),
						errors,
					}))
				},
				move_up: rankId => {
					return httpPost(`/api/setting/permission/ranks/${rankId}/move_up/`).then(response => ({
						success: response.success,
					}))
				},
				move_down: rankId => {
					return httpPost(`/api/setting/permission/ranks/${rankId}/move_down/`).then(response => ({
						success: response.success,
					}))
				},
				delete: rankId => {
					return httpPost(`/api/setting/permission/ranks/${rankId}/delete/`).then(response => ({
						success: response.success,
						errors: response.errors,
					}))
				},
				employees: {
					get: (rankId, isActive) => {
						return httpGet(
							`/api/setting/permission/ranks/${rankId}/employees/${isActive === false ? '?is_active=false' : ''}`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new SettingEmploymentInfo.EmployeeDetail(item)),
						}))
					},
				},
			},
			titles: {
				create: name => {
					let data = { name }
					return httpPost(`/api/setting/permission/titles/create/`, data).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				edit: (titleId, name) => {
					let data = { name }
					return httpPost(`/api/setting/permission/titles/${titleId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Setting.Title(data),
							errors,
						})
					)
				},
				delete: titleId => {
					return httpPost(`/api/setting/permission/titles/${titleId}/delete/`).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				employees: {
					get: (titleId, isActive) => {
						return httpGet(
							`/api/setting/permission/titles/${titleId}/employees/${isActive === false ? '?is_active=false' : ''}`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new SettingEmploymentInfo.EmployeeDetail(item)),
						}))
					},
				},
			},
			groups: {
				create: ({ name, leaders }) => {
					let data = {
						name,
						leaders: leaders.map(item => new SettingEmploymentInfo.CreateGroupLeader(item)),
					}
					return httpPost(`/api/setting/permission/groups/create/`, data).then(({ success, data, errors }) => ({
						success: success,
						data: new SettingEmploymentInfo.GroupCount(data),
						errors: errors,
					}))
				},
				edit: (groupId, { name, leaders }) => {
					let data = {
						name,
						leaders: leaders.map(item => new SettingEmploymentInfo.CreateGroupLeader(item)),
					}
					return httpPost(`/api/setting/permission/groups/${groupId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new SettingEmploymentInfo.GroupCount(data),
							errors,
						})
					)
				},
				delete: groupId => {
					return httpPost(`/api/setting/permission/groups/${groupId}/delete/`).then(({ success, errors }) => ({
						success: success,
						errors: errors,
					}))
				},
				employees: {
					get: (groupId, isActive) => {
						return httpGet(
							`/api/setting/permission/groups/${groupId}/employees/${isActive === false ? '?is_active=false' : ''}`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new SettingEmploymentInfo.EmployeeDetail(item)),
						}))
					},
				},
			},
			departments: {
				create: ({ headEmployeeId, name }) => {
					let data = new SettingEmploymentInfo.CreateDepartment({ headEmployeeId, name })
					return httpPost(`/api/setting/permission/departments/create/`, data).then(({ success, errors }) => ({
						success: success,
						errors: errors,
					}))
				},
				edit: (departmentId, { headEmployeeId, name }) => {
					let data = new SettingEmploymentInfo.CreateDepartment({ headEmployeeId, name })
					return httpPost(`/api/setting/permission/departments/${departmentId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							errors,
						})
					)
				},
				delete: departmentId => {
					return httpPost(`/api/setting/permission/departments/${departmentId}/delete/`).then(
						({ success, errors }) => ({
							success: success,
							errors: errors,
						})
					)
				},
				employees: {
					get: (departmentId, isActive) => {
						return httpGet(
							`/api/setting/permission/departments/${departmentId}/employees/${
								isActive === false ? '?is_active=false' : ''
							}`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new SettingEmploymentInfo.EmployeeDetail(item)),
						}))
					},
				},
			},
			positions: {
				create: name => {
					let data = { name }
					return httpPost(`/api/setting/permission/positions/create/`, data).then(({ success, errors }) => ({
						success: success,
						errors: errors,
					}))
				},
				edit: (positionId, name) => {
					let data = { name }
					return httpPost(`/api/setting/permission/positions/${positionId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							errors,
						})
					)
				},
				delete: positionId => {
					return httpPost(`/api/setting/permission/positions/${positionId}/delete/`).then(({ success, errors }) => ({
						success: success,
						errors: errors,
					}))
				},
				employees: {
					get: (positionId, isActive) => {
						return httpGet(
							`/api/setting/permission/positions/${positionId}/employees/${
								isActive === false ? '?is_active=false' : ''
							}`
						).then(({ success, data }) => ({
							success,
							data: data.map(item => new SettingEmploymentInfo.EmployeeDetail(item)),
						}))
					},
				},
			},
			group_leader_roles: {
				get: () => {
					return httpGet(`/api/setting/permission/group_leader_roles/`).then(({ success, data }) => ({
						success,
						data: data
							.sort(alphabetical({ path: 'name' }))
							.map(item => new SettingEmploymentInfo.GroupLeaderRole(item)),
					}))
				},
			},
		},
		employee_database: {
			field_groups: {
				get: () => {
					return httpGet(`/api/setting/employee_database/field_groups/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new Setting.FieldGroup(item)),
					}))
				},
				create: name => {
					let data = { name }
					return httpPost(`/api/setting/employee_database/field_groups/create/`, data).then(({ success, data }) => ({
						success,
						data: new Setting.FieldGroup(data),
					}))
				},
				edit: (fieldGroupId, name) => {
					let data = { name }
					return httpPost(`/api/setting/employee_database/field_groups/${fieldGroupId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Setting.FieldGroup(data),
							errors,
						})
					)
				},
				delete: fieldGroupId => {
					return httpPost(`/api/setting/employee_database/field_groups/${fieldGroupId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveUp: fieldGroupId => {
					return httpPost(`/api/setting/employee_database/field_groups/${fieldGroupId}/move_up/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveDown: fieldGroupId => {
					return httpPost(`/api/setting/employee_database/field_groups/${fieldGroupId}/move_down/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				fields: {
					get: fieldGroupId => {
						return httpGet(`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/`).then(
							({ success, data, errors }) => ({
								success,
								data: data.map(item => new Setting.FieldWithQuestion(item)),
								errors,
							})
						)
					},
					create: (fieldGroupId, { name, isMoreDetails = false, allowMultipleAnswers = false }) => {
						let data = {
							name,
							is_more_details: isMoreDetails,
							allow_multiple_answers: allowMultipleAnswers,
						}
						return httpPost(`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/create/`, data).then(
							({ success, data, errors }) => ({
								success,
								data: new Setting.Field(data),
								errors,
							})
						)
					},
					edit: (fieldGroupId, fieldId, { name, isMoreDetails = false, allowMultipleAnswers = true }) => {
						let data = {
							name,
							is_more_details: isMoreDetails,
							allow_multiple_answers: allowMultipleAnswers,
						}
						return httpPost(
							`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/edit/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new Setting.Field(data),
							errors,
						}))
					},
					delete: (fieldGroupId, fieldId) => {
						return httpPost(
							`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/delete/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					moveUp: (fieldGroupId, fieldId) => {
						return httpPost(
							`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/move_up/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					moveDown: (fieldGroupId, fieldId) => {
						return httpPost(
							`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/move_down/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					questions: {
						create: (fieldGroupId, fieldId, { name, type, isRequired = false, allowMultipleAnswers = false }) => {
							let data = {
								name,
								type,
								is_required: isRequired,
								allow_multiple_answers: allowMultipleAnswers,
							}
							return httpPost(
								`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/create/`,
								data
							).then(({ success, data, errors }) => ({
								success,
								data: new Setting.Question(data),
								errors,
							}))
						},
						edit: (fieldGroupId, fieldId, questionId, { name, isRequired }) => {
							let data = {
								name,
								is_required: isRequired,
							}
							return httpPost(
								`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/edit/`,
								data
							).then(({ success, data, errors }) => ({
								success,
								data: new Setting.Question(data),
								errors,
							}))
						},
						delete: (fieldGroupId, fieldId, questionId) => {
							return httpPost(
								`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/delete/`
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						moveUp: (fieldGroupId, fieldId, questionId) => {
							return httpPost(
								`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/move_up/`
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						moveDown: (fieldGroupId, fieldId, questionId) => {
							return httpPost(
								`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/move_down/`
							).then(({ success, errors }) => ({
								success,
								errors,
							}))
						},
						choices: {
							create: (fieldGroupId, fieldId, questionId, content) => {
								let data = { content }
								return httpPost(
									`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/choices/create/`,
									data
								).then(({ success, data, errors }) => ({
									success,
									data: new Setting.Choice(data),
									errors,
								}))
							},
							edit: (fieldGroupId, fieldId, questionId, answerChoiceId, content) => {
								let data = { content }
								return httpPost(
									`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/choices/${answerChoiceId}/edit/`,
									data
								).then(({ success, data, errors }) => ({
									success,
									data: new Setting.Choice(data),
									errors,
								}))
							},
							delete: (fieldGroupId, fieldId, questionId, answerChoiceId) => {
								return httpPost(
									`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/choices/${answerChoiceId}/delete/`
								).then(({ success, errors }) => ({
									success,
									errors,
								}))
							},
							moveUp: (fieldGroupId, fieldId, questionId, answerChoiceId) => {
								return httpPost(
									`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/choices/${answerChoiceId}/move_up/`
								).then(({ success, errors }) => ({
									success,
									errors,
								}))
							},
							moveDown: (fieldGroupId, fieldId, questionId, answerChoiceId) => {
								return httpPost(
									`/api/setting/employee_database/field_groups/${fieldGroupId}/fields/${fieldId}/questions/${questionId}/choices/${answerChoiceId}/move_down/`
								).then(({ success, errors }) => ({
									success,
									errors,
								}))
							},
						},
					},
				},
			},
		},
		job_description: {
			get: () => {
				return httpGet(`/api/setting/job_description/job_descriptions/`).then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingJobDescription.JobDescription(item)),
				}))
			},
			getDetail: jobDescId => {
				return httpGet(`/api/setting/job_description/job_descriptions/${jobDescId}/`).then(
					({ success, data, errors }) => ({
						success,
						data: new SettingJobDescription.JobDescriptionDetail(data),
						errors,
					})
				)
			},
			createNewVersion: (jobDescId, sections) => {
				let data = { sections }
				return httpPost(`/api/setting/job_description/job_descriptions/${jobDescId}/create_new_version/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new SettingJobDescription.JobDescriptionVersionDetail(data),
						errors,
					})
				)
			},
			versions: {
				get: (jobDescId, versionId) => {
					return httpGet(`/api/setting/job_description/job_descriptions/${jobDescId}/versions/${versionId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new SettingJobDescription.JobDescriptionVersionDetail(data),
							errors,
						})
					)
				},
			},
		},
		idea: {
			getReviewers: (param = '') => {
				return httpGet(`/api/setting/idea/idea_reviewers/${param}`).then(({ success, data, pagination, errors }) => ({
					success,
					data: new SettingIdea.IdeaReviewers({
						...data,
						idea_flows: data.idea_flows.sort((a, b) => a.level - b.level),
					}),
					pagination: new Pagination(pagination),
					errors,
				}))
			},
			getEmployeeReviewers: employeeId => {
				return httpGet(`/api/setting/idea/idea_reviewers/${employeeId}/`).then(({ success, data }) => ({
					success,
					data: new SettingIdea.EmployeeIdeaFlow(data),
				}))
			},
			getEmployeeReviewees: employeeId => {
				return httpGet(`/api/setting/idea/idea_reviewees/${employeeId}/`).then(({ success, data }) => ({
					success,
					data: data.map(item => new SettingIdea.IdeaRevieweeIdeaFlow(item)),
				}))
			},
			getEmployees: () => {
				return httpGet(`/api/setting/idea/employees/`).then(({ success, data, errors }) => ({
					success,
					data: data.sort(alphabetical({ path: 'name' })).map(item => new SettingIdea.Employee(item)),
					errors,
				}))
			},
			getEmployeeDetail: employeeId => {
				return httpGet(`/api/setting/idea/employees/${employeeId}/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new SettingIdea.EmployeeDetail(item)),
					errors,
				}))
			},
			ideaFlows: {
				get: () => {
					return httpGet(`/api/setting/idea/idea_flows/`).then(({ success, data, errors }) => ({
						success,
						data: data.sort((a, b) => a.level - b.level).map(item => new SettingIdea.IdeaFlow(item)),
						errors,
					}))
				},
				create: () => {
					return httpPost(`/api/setting/idea/idea_flows/create/`).then(({ success, data, errors }) => ({
						success,
						data: new SettingIdea.IdeaFlow(data),
						errors,
					}))
				},
				edit: (
					ideaFlowId,
					{ commentPermission, postPermission, likePermission, flowerPermission, starPermission, name }
				) => {
					let data = {
						comment_permission: commentPermission,
						post_permission: postPermission,
						like_permission: likePermission,
						flower_permission: flowerPermission,
						star_permission: starPermission,
						name,
					}
					return httpPost(`/api/setting/idea/idea_flows/${ideaFlowId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new SettingIdea.IdeaFlow(data),
							errors,
						})
					)
				},
				delete: ideaFlowId => {
					return httpPost(`/api/setting/idea/idea_flows/${ideaFlowId}/delete/`).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				changeReviewer: (ideaFlowId, employeeId, reviewer) => {
					let data = { reviewer_id: null, reviewer_type: REVIEWER_TYPE.CUSTOM }
					if (reviewer) {
						const { reviewerId, reviewerType, groupRoleId, rankId } = reviewer
						data = {
							reviewer_id: reviewerId,
							reviewer_type: reviewerType,
						}
						if (groupRoleId) {
							data.group_role_id = groupRoleId
						}
						if (rankId) {
							data.rank_id = rankId
						}
					}
					return httpPost(`/api/setting/idea/idea_flows/${ideaFlowId}/${employeeId}/change_reviewer/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
			},
			ranks: {
				get: () => {
					return httpGet(`/api/setting/idea/ranks/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new SettingIdea.RankSimple(item)),
					}))
				},
			},
			group_leader_roles: {
				get: () => {
					return httpGet(`/api/setting/idea/group_leader_roles/`).then(({ success, data }) => ({
						success,
						data: data.sort(alphabetical({ path: 'name' })).map(item => new SettingIdea.GroupLeaderRole(item)),
					}))
				},
			},
		},
		timeoff: {
			ranks: {
				get: () => {
					return httpGet(`/api/setting/timeoff/ranks/`).then(({ success, data }) => ({
						success,
						data: data.map(item => new TimeoffSetting.RankSimple(item)),
					}))
				},
			},
			group_leader_roles: {
				get: () => {
					return httpGet(`/api/setting/timeoff/group_leader_roles/`).then(({ success, data }) => ({
						success,
						data: data
							.sort(alphabetical({ path: 'name' }))
							.map(item => new SettingEmploymentInfo.GroupLeaderRole(item)),
					}))
				},
			},
			employees: {
				getTimeoffSetting: employeeId => {
					return httpGet(`/api/setting/timeoff/employees/${employeeId}/`).then(({ success, data }) => ({
						success,
						data: new TimeoffSetting.EmployeeTimeoffSetting(data),
					}))
				},
				getTimeoffApprovers: employeeId => {
					return httpGet(`/api/setting/timeoff/employees/${employeeId}/get_timeoff_approvers/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new TimeoffSetting.TimeoffApprover(item)),
						})
					)
				},
				getTimeoffApprovees: employeeId => {
					return httpGet(`/api/setting/timeoff/employees/${employeeId}/get_timeoff_approvees/`).then(
						({ success, data }) => ({
							success,
							data: data.map(item => new TimeoffSetting.TimeoffApprovee(item)),
						})
					)
				},
			},
			holiday_types: {
				get: () => {
					return httpGet(`/api/setting/timeoff/holiday_types/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new PublicHolidaySetting.HolidayType(item)),
						errors,
					}))
				},
				getYearSummary: () => {
					return httpGet(`/api/setting/timeoff/holiday_types/year_summary/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new PublicHolidaySetting.HolidayYearSummary(item)),
						errors,
					}))
				},
				editYearSummary: (year, holidayTypes) => {
					let data = {
						holiday_types: holidayTypes, // EditYearSummaryHolidayType: { id, name, holiday_date_ranges }
					}
					return httpPost(`/api/setting/timeoff/holiday_types/year_summary/${year}/edit/`, data).then(
						({ success }) => ({
							success,
						})
					)
				},
				create: ({ name, holidayDateRanges }) => {
					let data = {
						name,
						holiday_date_ranges: holidayDateRanges, // CreateHolidayTypeDateRange: { start_date, end_date, holiday_group_ids }
					}
					return httpPost(`/api/setting/timeoff/holiday_types/create/`, data).then(({ success, data, errors }) => ({
						success,
						data: new PublicHolidaySetting.HolidayType(data),
						errors,
					}))
				},
				delete: holidayTypeId => {
					return httpPost(`/api/setting/timeoff/holiday_types/${holidayTypeId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
			},
			holiday_groups: {
				get: () => {
					return httpGet(`/api/setting/timeoff/holiday_groups/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new PublicHolidaySetting.HolidayGroup(item)),
						errors,
					}))
				},
				getWithCount: () => {
					return httpGet(`/api/setting/timeoff/holiday_groups/with_employee_count/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new PublicHolidaySetting.HolidayGroupWithEmployeeCount(item)),
							errors,
						})
					)
				},
				getDetail: holidayGroupId => {
					return httpGet(`/api/setting/timeoff/holiday_groups/${holidayGroupId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new PublicHolidaySetting.HolidayGroupWithRules(data),
							errors,
						})
					)
				},
				getEmployees: holidayGroupId => {
					return httpGet(`/api/setting/timeoff/holiday_groups/${holidayGroupId}/get_employees/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new PublicHolidaySetting.Employee(item)),
							errors,
						})
					)
				},
				create: ({ name, rules }) => {
					let data = {
						name,
						rules,
					}
					return httpPost(`/api/setting/timeoff/holiday_groups/create/`, data).then(({ success, data, errors }) => ({
						success,
						data: new PublicHolidaySetting.HolidayGroupWithEmployeeCount(data),
						errors,
					}))
				},
				edit: (holidayGroupId, { name, rules }) => {
					let data = {
						name,
						rules,
					}
					return httpPost(`/api/setting/timeoff/holiday_groups/${holidayGroupId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new PublicHolidaySetting.HolidayGroup(data),
							errors,
						})
					)
				},
				delete: holidayGroupId => {
					return httpPost(`/api/setting/timeoff/holiday_groups/${holidayGroupId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveEmployees: ({ employeeIds, holidayGroupId }) => {
					let data = {
						employee_ids: employeeIds,
						holiday_group_id: holidayGroupId,
					}
					return httpPost(`/api/setting/timeoff/holiday_groups/move_employees/`, data).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				holiday_group_logs: {
					get: holidayGroupId => {
						return httpGet(`/api/setting/timeoff/holiday_groups/${holidayGroupId}/holiday_group_logs/`).then(
							({ success, data, errors }) => ({
								success,
								data: data.map(item => new PublicHolidaySetting.HolidayGroupLog(item)),
								errors,
							})
						)
					},
					getDetail: (holidayGroupId, groupLogId) => {
						return httpGet(
							`/api/setting/timeoff/holiday_groups/${holidayGroupId}/holiday_group_logs/${groupLogId}/`
						).then(({ success, data, errors }) => ({
							success,
							data: new PublicHolidaySetting.HolidayGroupLogDetail(data),
							errors,
						}))
					},
				},
			},
			timeoff_types: {
				get: () => {
					return httpGet(`/api/setting/timeoff/timeoff_types/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new TimeoffSetting.TimeoffType(item)),
						errors,
					}))
				},
				getSummary: () => {
					return httpGet(`/api/setting/timeoff/timeoff_types/get_summary/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new TimeoffSetting.TimeoffSummary(item)),
						errors,
					}))
				},
				create: ({ name, color }) => {
					let data = { name, color }
					return httpPost(`/api/setting/timeoff/timeoff_types/create/`, data).then(({ success, data, errors }) => ({
						success,
						data: new TimeoffSetting.TimeoffType(data),
						errors,
					}))
				},
				edit: (timeoffTypeId, { name, color }) => {
					let data = { name, color }
					return httpPost(`/api/setting/timeoff/timeoff_types/${timeoffTypeId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new TimeoffSetting.TimeoffType(data),
							errors,
						})
					)
				},
				delete: timeoffTypeId => {
					return httpPost(`/api/setting/timeoff/timeoff_types/${timeoffTypeId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveUp: timeoffTypeId => {
					return httpPost(`/api/setting/timeoff/timeoff_types/${timeoffTypeId}/move_up/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveDown: timeoffTypeId => {
					return httpPost(`/api/setting/timeoff/timeoff_types/${timeoffTypeId}/move_down/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
			},
			timeoff_groups: {
				get: () => {
					return httpGet(`/api/setting/timeoff/timeoff_groups/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new TimeoffSetting.TimeoffGroup(item)),
						errors,
					}))
				},
				getWithDetail: () => {
					return httpGet(`/api/setting/timeoff/timeoff_groups/with_detail/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new TimeoffSetting.TimeoffGroupDetail(item)),
						errors,
					}))
				},
				getDetail: timeoffGroupId => {
					return httpGet(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new TimeoffSetting.TimeoffGroupDetail(data),
							errors,
						})
					)
				},
				getEmployees: timeoffGroupId => {
					return httpGet(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/employees/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new TimeoffSetting.TimeoffGroupEmployee(item)),
							errors,
						})
					)
				},
				getApproversDropdown: () => {
					return httpGet(`/api/setting/timeoff/timeoff_groups/get_approvers_dropdown/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.sort((a, b) => a.name.localeCompare(b.name)).map(item => new TimeoffSetting.Employee(item)),
							errors,
						})
					)
				},
				create: ({ name, timeoffRules }) => {
					let data = {
						name,
						timeoff_rules: timeoffRules, // [TimeoffSetting.CreateTimeoffGroupRule]
					}
					return httpPost(`/api/setting/timeoff/timeoff_groups/create/`, data).then(({ success, data, errors }) => ({
						success,
						data: new TimeoffSetting.TimeoffGroup(data),
						errors,
					}))
				},
				edit: (timeoffGroupId, { name, timeoffRules }) => {
					let data = {
						name,
						timeoff_rules: timeoffRules, // [TimeoffSetting.CreateTimeoffGroupRule]
					}
					return httpPost(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new TimeoffSetting.TimeoffGroup(data),
							errors,
						})
					)
				},
				editApprovers: employeeWithApprovers => {
					let data = {
						employee_with_approvers: employeeWithApprovers,
					}
					return httpPost(`/api/setting/timeoff/timeoff_groups/edit_approvers/`, data).then(({ success, data }) => ({
						success,
						data: data.map(x => new TimeoffSetting.TimeoffGroupEmployee(x)),
					}))
				},
				delete: timeoffGroupId => {
					return httpPost(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/delete/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				moveEmployees: ({ employeeIds, timeoffGroupId }) => {
					let data = {
						employee_ids: employeeIds,
						timeoff_group_id: timeoffGroupId,
					}
					return httpPost(`/api/setting/timeoff/timeoff_groups/move_employees/`, data).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				timeoff_group_logs: {
					get: timeoffGroupId => {
						return httpGet(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/timeoff_group_logs/`).then(
							({ success, data, errors }) => ({
								success,
								data: data.map(item => new TimeoffSetting.TimeoffGroupLog(item)),
								errors,
							})
						)
					},
					getDetail: (timeoffGroupId, logId) => {
						return httpGet(`/api/setting/timeoff/timeoff_groups/${timeoffGroupId}/timeoff_group_logs/${logId}/`).then(
							({ success, data, errors }) => ({
								success,
								data: new TimeoffSetting.TimeoffGroupLogDetail(data),
								errors,
							})
						)
					},
				},
			},
		},
	},
	survey: {
		ongoing: {
			getSurveySession: () => {
				return httpGet(`/api/survey/ongoing/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new Survey.SubSessionSimple(item)),
					errors,
				}))
			},
			getSurveySubSession: (sessionId, subSessionId) => {
				return httpGet(`/api/survey/ongoing/${sessionId}/${subSessionId}/`).then(({ success, data, errors }) => ({
					success,
					data: new Survey.SubSessionDetail(data),
					errors,
				}))
			},
			submitSubSession: (sessionId, subSessionId, answers, isDraft = false) => {
				let data = {
					answers,
					is_draft: isDraft,
				}
				return httpPost(`/api/survey/ongoing/${sessionId}/${subSessionId}/submit/`, data)
			},
		},
		completed: {
			getSurveySession: () => {
				return httpGet(`/api/survey/completed/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new Survey.SubSessionSimple(item)),
					errors,
				}))
			},
			getSurveySubSession: (sessionId, subSessionId) => {
				return httpGet(`/api/survey/completed/${sessionId}/${subSessionId}/`).then(({ success, data, errors }) => ({
					success,
					data: new Survey.SubSessionDetail(data),
					errors,
				}))
			},
		},
	},
	survey_admin: {
		report: {
			getSummary: sessionId => {
				return httpGet(`/api/survey_admin/survey_report/sessions/${sessionId}/get_summary/`).then(
					({ success, data }) => ({
						success,
						data: data.sort((a, b) => a.question.order - b.question.order).map(item => new SurveyAdmin.Summary(item)),
					})
				)
			},
			getSummaryDetail: sessionId => {
				return httpGet(`/api/survey_admin/survey_report/sessions/${sessionId}/summary_detail/`).then(
					({ success, data }) => ({
						success,
						data: new SurveyAdmin.SummaryDetail(data),
					})
				)
			},
			getSummaryExportUrl: sessionId => {
				return httpGet(`/api/survey_admin/survey_report/sessions/${sessionId}/export_summary/`).then(
					({ success, data }) => ({
						success,
						data,
					})
				)
			},
			getIndividual: (sessionId, page = 1) => {
				return httpGet(`/api/survey_admin/survey_report/sessions/${sessionId}/individual/?page=${page}`).then(
					({ success, data, pagination, errors }) => ({
						success,
						data: data ? new SurveyAdmin.IndividualSummary(data) : null,
						pagination: new Pagination(pagination),
						errors,
					})
				)
			},
		},
		sessions: {
			get: () => {
				return httpGet(`/api/survey_admin/survey_session/sessions/simple/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new SurveyAdmin.SessionSimple(item)),
					errors,
				}))
			},
			getActiveEmployees: () => {
				return httpGet(`/api/survey_admin/survey_session/sessions/get_active_employees/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.sort(alphabetical({ path: 'name' })).map(item => new SurveyAdmin.ActiveEmployee(item)),
						errors,
					})
				)
			},
			getSessionDetail: sessionId => {
				return httpGet(`/api/survey_admin/survey_session/sessions/${sessionId}/`).then(({ success, data, errors }) => ({
					success,
					data: new SurveyAdmin.SessionDetail(data),
					errors,
				}))
			},
			create: ({ name, description = '' }) => {
				let data = {
					name,
					description,
				}
				return httpPost(`/api/survey_admin/survey_session/sessions/create/`, data).then(({ success, data }) => ({
					success,
					data: new SurveyAdmin.Session(data),
				}))
			},
			createCopy: sessionId => {
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/create_copy/`).then(
					({ success, data, errors }) => ({
						success,
						data: new SurveyAdmin.SessionDetail(data),
						errors,
					})
				)
			},
			start: sessionId => {
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/start/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			stop: sessionId => {
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/stop/`).then(({ success, errors }) => ({
					success,
					errors,
				}))
			},
			edit: (sessionId, { name, description = '', startDate, endDate }) => {
				let data = {
					name,
					description,
					start_date: Math.floor(startDate.getTime() / 1000),
					end_date: Math.floor(endDate.getTime() / 1000),
				}
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/edit/`, data).then(
					({ success, data, errors }) => ({
						success,
						errors,
					})
				)
			},
			delete: sessionId => {
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/delete/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			createQuestion: (
				sessionId,
				{ selfContent, alternativeContent, type, linearLowestLabel, linearHighestLabel, choices }
			) => {
				let data = {
					self_content: selfContent,
					alternative_content: alternativeContent,
					type,
					linear_lowest_label: linearLowestLabel,
					linear_highest_label: linearHighestLabel,
				}
				if (choices) {
					data.choices = choices
				}
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/create_question/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new SurveyAdmin.Question(data),
						errors,
					})
				)
			},
			getFilters: sessionId => {
				return httpGet(`/api/survey_admin/survey_session/sessions/${sessionId}/get_filters/`).then(
					({ success, data }) => ({
						success,
						data: {
							departments: data.departments
								.sort(alphabetical({ path: 'name' }))
								.map(item => new SurveyAdmin.Department(item)),
							groups: data.groups.sort(alphabetical({ path: 'name' })).map(item => new SurveyAdmin.Group(item)),
							superiors: data.superiors
								.sort(alphabetical({ path: 'name' }))
								.map(item => new SurveyAdmin.Superior(item)),
							titles: data.titles.sort(alphabetical({ path: 'name' })).map(item => new SurveyAdmin.Title(item)),
							ranks: data.ranks
								.sort(alphabetical({ path: 'name' }))
								.map(item => new AppraisalAdminSession.FilterItem(item)),
							positions: data.positions
								.sort(alphabetical({ path: 'name' }))
								.map(item => new AppraisalAdminSession.FilterItem(item)),
						},
					})
				)
			},
			// unused
			getPeers: (sessionId, employeeId) => {
				return httpGet(`/api/survey_admin/survey_session/sessions/${sessionId}/get_peers/${employeeId}/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new SurveyAdmin.Employee(item)),
						errors,
					})
				)
			},
			// unused
			getSubordinates: (sessionId, employeeId) => {
				return httpGet(`/api/survey_admin/survey_session/sessions/${sessionId}/get_subordinates/${employeeId}/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new SurveyAdmin.Employee(item)),
						errors,
					})
				)
			},
			participants: (sessionId, params) => {
				return httpGet(`/api/survey_admin/survey_session/sessions/${sessionId}/participants/${params}`).then(
					({ success, data, pagination, errors }) => ({
						success,
						data: data.map(item => new SurveyAdmin.Participant(item)),
						pagination: new Pagination(pagination),
						errors,
					})
				)
			},
			addParticipants: (sessionId, employeeIds) => {
				let data = {
					employee_id_list: employeeIds,
				}
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/add_participants/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			removeParticipants: (sessionId, participantIds) => {
				let data = {
					participant_id_list: participantIds,
				}
				return httpPost(`/api/survey_admin/survey_session/sessions/${sessionId}/remove_participants/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			questions: {
				edit: (
					sessionId,
					questionId,
					{ selfContent, alternativeContent, linearLowestLabel, linearHighestLabel, type, choices }
				) => {
					let data = {
						self_content: selfContent,
						alternative_content: alternativeContent,
						linear_lowest_label: linearLowestLabel,
						linear_highest_label: linearHighestLabel,
						type: type,
					}

					if (choices) {
						data.choices = choices
					}

					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/edit/`,
						data
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				delete: (sessionId, questionId) => {
					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/delete/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				moveUp: (sessionId, questionId) => {
					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/move_up/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				moveDown: (sessionId, questionId) => {
					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/move_down/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				changeLinearSize: (sessionId, questionId, linearSize, disabledChoices) => {
					let data = {
						linear_size: linearSize,
						disabled_choices: disabledChoices,
					}

					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/change_linear_size/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new SurveyAdmin.Question(data),
						errors,
					}))
				},
				addAnswerChoice: (sessionId, questionId, { content, disabled }) => {
					let data = { content, disabled }

					return httpPost(
						`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/add_answer_choice/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new SurveyAdmin.AnswerChoice(data),
						errors,
					}))
				},
				answer_choices: {
					edit: (sessionId, questionId, answerChoiceId, { content, disabled }) => {
						let data = { content, disabled }

						return httpPost(
							`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/answer_choices/${answerChoiceId}/edit/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new SurveyAdmin.AnswerChoice(data),
							errors,
						}))
					},
					delete: (sessionId, questionId, answerChoiceId) => {
						return httpPost(
							`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/answer_choices/${answerChoiceId}/delete/`
						).then(({ success, errors }) => ({
							success,
							errors,
						}))
					},
					moveUp: (sessionId, questionId, answerChoiceId) => {
						return httpPost(
							`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/answer_choices/${answerChoiceId}/move_up/`
						).then(({ success, data, errors }) => ({
							success,
							data: new SurveyAdmin.AnswerChoice(data),
							errors,
						}))
					},
					moveDown: (sessionId, questionId, answerChoiceId) => {
						return httpPost(
							`/api/survey_admin/survey_session/sessions/${sessionId}/questions/${questionId}/answer_choices/${answerChoiceId}/move_down/`
						).then(({ success, data, errors }) => ({
							success,
							data: new SurveyAdmin.AnswerChoice(data),
							errors,
						}))
					},
				},
			},
		},
	},
	timeoff: {
		getTimeoffCalendar: (param = '') => {
			return httpGet(`/api/timeoff/timeoff_calendar/${param}`).then(({ success, data }) => ({
				success,
				data: data.map(item => new Timeoff.EmployeeCalendar(item)),
			}))
		},
		timeoff_requests: {
			pending_my_approval: {
				get: () => {
					return httpGet(`/api/timeoff/timeoff_requests/pending_my_approval/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Timeoff.ApproverTimeoffRequest(item)),
						errors,
					}))
				},
				getById: timeoffRequestId => {
					return httpGet(`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new Timeoff.ApproverTimeoffRequest(data),
							errors,
						})
					)
				},
				getCalendar: timeoffRequestId => {
					return httpGet(
						`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/timeoff_calendar/`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Timeoff.EmployeeCalendar(item)),
						errors,
					}))
				},
				approve: timeoffRequestId => {
					return httpPost(`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/approve/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				decline: timeoffRequestId => {
					return httpPost(`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/decline/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				comments: {
					get: timeoffRequestId => {
						return httpGet(`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/comments/`).then(
							({ success, data, errors }) => ({
								success,
								data: data.map(item => new Timeoff.TimeoffComment(item)),
								errors,
							})
						)
					},
					create: (timeoffRequestId, comment) => {
						let data = { comment }
						return httpPost(
							`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/comments/create/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new Timeoff.TimeoffComment(data),
							errors,
						}))
					},
				},
			},
			my_requests: {
				get: () => {
					return httpGet(`/api/timeoff/timeoff_requests/my_requests/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Timeoff.MyTimeoffRequest(item)),
						errors,
					}))
				},
				getById: timeoffRequestId => {
					return httpGet(`/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/`).then(
						({ success, data, errors }) => ({
							success,
							data: new Timeoff.MyTimeoffRequest(data),
							errors,
						})
					)
				},
				getComments: timeoffRequestId => {
					return httpGet(`/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/comments/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Timeoff.TimeoffComment(item)),
							errors,
						})
					)
				},
				edit: (timeoffRequestId, { startDate, endDate, startHalfDay, endHalfDay, comment }) => {
					let data = {
						start_date: format(startDate, 'YYYY-MM-DD'),
						end_date: format(endDate, 'YYYY-MM-DD'),
						start_half_day: startHalfDay,
						end_half_day: endHalfDay,
						comment,
					}
					return httpPost(`/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Timeoff.MyTimeoffRequest(data),
							errors,
						})
					)
				},
				cancel: timeoffRequestId => {
					return httpPost(`/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/cancel/`).then(
						({ success, data, errors }) => ({
							success,
							data: new Timeoff.MyTimeoffRequest(data),
							errors,
						})
					)
				},
			},
			getBalances: () => {
				return httpGet(`/api/timeoff/timeoff_requests/get_balances/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new Timeoff.TimeoffWallet(item)),
					errors,
				}))
			},
			createRequest: ({ timeoffWalletId, startDate, endDate, startHalfDay, endHalfDay, comment }) => {
				let data = {
					timeoff_wallet_id: timeoffWalletId,
					start_date: format(startDate, 'YYYY-MM-DD'),
					end_date: format(endDate, 'YYYY-MM-DD'),
					start_half_day: startHalfDay,
					end_half_day: endHalfDay,
					comment,
				}
				return httpPost(`/api/timeoff/timeoff_requests/create/`, data).then(({ success, data, errors }) => ({
					success,
					data: new Timeoff.MyTimeoffRequest(data),
					errors,
				}))
			},
			calculateDays: ({ startDate, endDate, startHalfDay, endHalfDay }) => {
				let data = {
					start_date: format(startDate, 'YYYY-MM-DD'),
					end_date: format(endDate, 'YYYY-MM-DD'),
					start_half_day: startHalfDay,
					end_half_day: endHalfDay,
				}
				return httpPost(`/api/timeoff/timeoff_requests/create/calculate_days/`, data).then(({ data }) => ({
					data: new Timeoff.DayCount(data),
				}))
			},
		},
		employee_timeoff_profile: {
			getProfile: employeeId => {
				return httpGet(`/api/timeoff/employee_timeoff_profile/${employeeId}/`).then(({ success, data, errors }) => ({
					success,
					data: new Timeoff.EmployeeTimeoffProfile(data),
					errors,
				}))
			},
			getTimeoffHistories: employeeId => {
				return httpGet(`/api/timeoff/employee_timeoff_profile/${employeeId}/timeoff_history/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new Timeoff.EmployeeTimeoffRequestHistory(item)),
						errors,
					})
				)
			},
			getTimeoffHistoryById: (employeeId, timeoffRequestId) => {
				return httpGet(`/api/timeoff/employee_timeoff_profile/${employeeId}/timeoff_history/${timeoffRequestId}/`).then(
					({ success, data, errors }) => ({
						success,
						data: new Timeoff.EmployeeTimeoffRequestHistory(data),
						errors,
					})
				)
			},
			current_requests: {
				get: employeeId => {
					return httpGet(`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Timeoff.EmployeeTimeoffRequest(item)),
							errors,
						})
					)
				},
				getById: (employeeId, timeoffRequestId) => {
					return httpGet(
						`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/${timeoffRequestId}/`
					).then(({ success, data, errors }) => ({
						success,
						data: new Timeoff.EmployeeTimeoffRequest(data),
						errors,
					}))
				},
				approve: (employeeId, timeoffRequestId) => {
					return httpPost(
						`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/${timeoffRequestId}/approve/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				decline: (employeeId, timeoffRequestId) => {
					return httpPost(
						`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/${timeoffRequestId}/decline/`
					)
				},
				comments: {
					create: (employeeId, timeoffRequestId, comment) => {
						let data = { comment }
						return httpPost(
							`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/${timeoffRequestId}/comments/create/`,
							data
						).then(({ success, data, errors }) => ({
							success,
							data: new Timeoff.TimeoffComment(data),
							errors,
						}))
					},
				},
			},
			timeoff_history: {
				get: employeeId => {
					return httpGet(`/api/timeoff/employee_timeoff_profile/${employeeId}/timeoff_history/`).then(
						({ success, data, errors }) => ({
							success,
							data: data.map(item => new Timeoff.EmployeeTimeoffRequestHistory(item)),
							errors,
						})
					)
				},
				getById: (employeeId, timeoffRequestId) => {
					return httpGet(
						`/api/timeoff/employee_timeoff_profile/${employeeId}/timeoff_history/${timeoffRequestId}/`
					).then(({ success, data, errors }) => ({
						success,
						data: new Timeoff.EmployeeTimeoffRequestHistory(data),
						errors,
					}))
				},
			},
		},
	},
	working_cycle_setting: {
		working_cycle_types: {
			get: () => {
				return httpGet(`/api/setting/timeoff/working_cycle_types/`).then(({ success, data }) => ({
					success,
					data: data.map(item => new WorkingCycleSetting.WorkingCycleType(item)),
				}))
			},
			create: ({ name, startTime, endTime }) => {
				let data = {
					name,
					start_time: startTime,
					end_time: endTime,
				}
				return httpPost(`/api/setting/timeoff/working_cycle_types/create/`, data).then(({ success, data, errors }) => ({
					success,
					data: new WorkingCycleSetting.WorkingCycleType(data),
					errors,
				}))
			},
			edit: (workingCycleTypeId, { name, startTime, endTime }) => {
				let data = {
					name,
					start_time: startTime,
					end_time: endTime,
				}
				return httpPost(`/api/setting/timeoff/working_cycle_types/${workingCycleTypeId}/edit/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleType(data),
						errors,
					})
				)
			},
			delete: workingCycleTypeId => {
				return httpPost(`/api/setting/timeoff/working_cycle_types/${workingCycleTypeId}/delete/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
		},
		working_cycle_groups: {
			get: () => {
				return httpGet(`/api/setting/timeoff/working_cycle_groups/`).then(({ success, data, errors }) => ({
					success,
					data: data.map(item => new WorkingCycleSetting.WorkingCycleGroupDetail(item)),
					errors,
				}))
			},
			getDetail: workingCycleGroupId => {
				return httpGet(`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/`).then(
					({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleGroupDetail(data),
						errors,
					})
				)
			},
			getEmployees: workingCycleGroupId => {
				return httpGet(`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/employees/`).then(
					({ success, data, errors }) => ({
						success,
						data: data.map(item => new WorkingCycleSetting.WorkingCycleGroupEmployee(item)),
						errors,
					})
				)
			},
			getTodaySummary: () => {
				return httpGet(`/api/setting/timeoff/working_cycle_groups/get_today_summary/`).then(
					({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.TodaySummary(data),
						errors,
					})
				)
			},
			create: ({ name, dayToday, workingCycleTypeIds }) => {
				let data = {
					name,
					day_today: dayToday,
					working_cycle_type_ids: workingCycleTypeIds,
				}
				return httpPost(`/api/setting/timeoff/working_cycle_groups/create/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleGroup(data),
						errors,
					})
				)
			},
			edit: (workingCycleGroupId, { name, dayToday, workingCycleTypeIds }) => {
				let data = {
					name,
					day_today: dayToday,
					working_cycle_type_ids: workingCycleTypeIds,
				}
				return httpPost(`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/edit/`, data).then(
					({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleGroup(data),
						errors,
					})
				)
			},
			delete: workingCycleGroupId => {
				return httpPost(`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/delete/`).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			moveEmployees: ({ employeeIds, workingCycleGroupId }) => {
				let data = {
					employee_ids: employeeIds,
					working_cycle_group_id: workingCycleGroupId,
				}
				return httpPost(`/api/setting/timeoff/working_cycle_groups/move_employees/`, data).then(
					({ success, errors }) => ({
						success,
						errors,
					})
				)
			},
			working_cycle_rules: {
				create: (workingCycleGroupId, workingCycleTypeId) => {
					let data = {
						working_cycle_type_id: workingCycleTypeId,
					}
					return httpPost(
						`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_rules/create/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleRule(data),
						errors,
					}))
				},
				edit: (workingCycleGroupId, workingCycleRuleId, workingCycleTypeId) => {
					let data = {
						working_cycle_type_id: workingCycleTypeId, // allowed to be NULL, NULL means off day
					}
					return httpPost(
						`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_rules/${workingCycleRuleId}/edit/`,
						data
					).then(({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleRule(data),
						errors,
					}))
				},
				delete: (workingCycleGroupId, workingCycleRuleId) => {
					return httpPost(
						`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_rules/${workingCycleRuleId}/delete/`
					).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
			},
			working_cycle_logs: {
				get: workingCycleGroupId => {
					return httpGet(
						`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_group_logs/`
					).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new WorkingCycleSetting.WorkingCycleGroupLog(item)),
						errors,
					}))
				},
				getDetail: (workingCycleGroupId, workingCycleGroupLogId) => {
					return httpGet(
						`/api/setting/timeoff/working_cycle_groups/${workingCycleGroupId}/working_cycle_group_logs/${workingCycleGroupLogId}/`
					).then(({ success, data, errors }) => ({
						success,
						data: new WorkingCycleSetting.WorkingCycleGroupLogDetail(data),
						errors,
					}))
				},
			},
		},
	},
	workstation: {
		idea: {
			ideas: {
				get: () => {
					return httpGet(`/api/workstation/idea/ideas/`).then(({ success, data, errors }) => ({
						success,
						data: new Workstation.Ideas(data),
						errors,
					}))
				},
				getDetail: ideaSubscriptionId => {
					return httpGet(`/api/workstation/idea/ideas/${ideaSubscriptionId}/`).then(({ success, data, errors }) => ({
						success,
						data: new Workstation.IdeaSubscriptionDetail(data),
						errors,
					}))
				},
				getInWall: () => {
					return httpGet(`/api/workstation/idea/ideas/idea_wall/`).then(({ success, data, errors }) => ({
						success,
						data: data.map(item => new Workstation.Idea(item)),
						errors,
					}))
				},
				getDetailInWall: ideaId => {
					return httpGet(`/api/workstation/idea/ideas/idea_wall/${ideaId}/`).then(({ success, data, errors }) => ({
						success,
						data: new Workstation.IdeaDetail(data),
						errors,
					}))
				},
				getSelfIdeaFlow: () => {
					return httpGet(`/api/workstation/idea/self_idea_flow/`).then(({ success, data, errors }) => ({
						success,
						data: new Workstation.IdeaFlow(data),
						errors,
					}))
				},
				create: data => {
					return httpPost(`/api/workstation/idea/ideas/create/`, data).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				edit: (ideaSubscriptionId, title, description, attachments, submit) => {
					let data = { title, description, attachments, submit }
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/edit/`, data).then(
						({ success, data, errors }) => ({
							success,
							data: new Workstation.IdeaSubscriptionDetail(data),
							errors,
						})
					)
				},
				delete: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/delete/`).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				comment: (ideaSubscriptionId, message) => {
					let data = {
						message,
					}
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/comment/`, data).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				resend: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/resend/`).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				forward: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/forward/`).then(({ success, errors }) => ({
						success,
						errors,
					}))
				},
				addToIdeaWall: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/add_to_idea_wall/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				sendStar: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/send_star/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				awardLike: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/award_like/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
				awardFlower: ideaSubscriptionId => {
					return httpPost(`/api/workstation/idea/ideas/${ideaSubscriptionId}/award_flower/`).then(
						({ success, errors }) => ({
							success,
							errors,
						})
					)
				},
			},
		},
	},
}

export default api
