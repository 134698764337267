import CategoryStatistics from './CategoryStatistics'
import CategoriesWithComparisons from './CategoriesWithComparisons'
import ChoiceWithEmployees from './ChoiceWithEmployees'
import DefaultTemplateWithGroups from './DefaultTemplateWithGroups'
import EmployeeWithAnswer from './EmployeeWithAnswer'
import EmployeeWithScore from './EmployeeWithScore'
import EmploymentField from './EmploymentField'
import GroupStatisticsWithCount from './GroupStatisticsWithCount'
import GroupingWithCount from './GroupingWithCount'
import GroupingWithQuestion from './GroupingWithQuestion'
import SessionEmployee from './SessionEmployee'
import GroupEmployeeWithScoreCardFinished from './GroupEmployeeWithScoreCardFinished'
import SessionWithTotalParticipants from './SessionWithTotalParticipants'

export const COMPARISON_FILTER_TYPE = {
	EMPLOYEE: 1,
	CUSTOM_GROUPING: 2,
}

export default {
	CategoryStatistics,
	CategoriesWithComparisons,
	ChoiceWithEmployees,
	DefaultTemplateWithGroups,
	EmployeeWithAnswer,
	EmployeeWithScore,
	EmploymentField,
	GroupStatisticsWithCount,
	GroupingWithCount,
	GroupingWithQuestion,
	SessionEmployee,
	GroupEmployeeWithScoreCardFinished,
	SessionWithTotalParticipants,
}
