import { s, number } from 'modules/types'
import Idea, { IdeaType } from './Idea'

export class IdeaSubscription {
	constructor({ id, idea }) {
		this.id = id
		this.idea = new Idea(idea)
	}
}

export const IdeaSubscriptionType = s({
	id: number.isRequired,
	idea: IdeaType,
})

export default IdeaSubscription
