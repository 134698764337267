import { s, number } from 'modules/types'

export class PermissionWithExt {
	constructor({ permission_id: permissionId, below_rank_id: belowRankId }) {
		this.permissionId = permissionId
		this.belowRankId = belowRankId
	}
}

export const PermissionWithExtType = s({
	permissionId: number.isRequired,
	belowRankId: number.isRequired,
})

export default PermissionWithExt
