import { s, arrayOf } from 'modules/types'
import WorkingCycleGroupSummary, { WorkingCycleGroupSummaryType } from './WorkingCycleGroupSummary'
import WorkingCycleTypeDetail, { WorkingCycleTypeDetailType } from './WorkingCycleTypeDetail'

export class TodaySummary {
	constructor({ off_groups: offGroups, shifts }) {
		this.offGroups = offGroups.map(item => new WorkingCycleGroupSummary(item))
		this.shifts = shifts.map(item => new WorkingCycleTypeDetail(item))
	}
}

export const TodaySummaryType = s({
	offGroups: arrayOf(WorkingCycleGroupSummaryType),
	shifts: arrayOf(WorkingCycleTypeDetailType),
})

export default TodaySummary
