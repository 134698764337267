import { s, bool, number, string } from 'modules/types'

export class RankCount {
	constructor({
		id,
		name,
		level,
		active_employee_count: activeEmployeeCount,
		archived_employee_count: archivedEmployeeCount,
	}) {
		this.id = id
		this.name = name
		this.level = level
		this.activeEmployeeCount = activeEmployeeCount
		this.archivedEmployeeCount = archivedEmployeeCount
		this.hasEmployee = Boolean(activeEmployeeCount) || Boolean(archivedEmployeeCount)
	}
}

export const RankCountType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
	activeEmployeeCount: number.isRequired,
	archivedEmployeeCount: number.isRequired,
	hasEmployee: bool.isRequired,
})

export default RankCount
