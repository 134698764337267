import { s, number, string } from 'modules/types'

class Employee {
	constructor({ employee_id: id, full_name: fullName, NIK }) {
		this.id = id
		this.fullName = fullName
		this.NIK = NIK
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
})

export default Employee
