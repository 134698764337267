import { s, arrayOf, bool, oneOfType, number, object, string } from 'modules/types'
import { QUESTION_TYPE } from './Question'

class DateRangeSnapshot {
	constructor({ start_date_answer: startDateAnswer, end_date_answer: endDateAnswer }) {
		this.startDateAnswer = new Date(startDateAnswer)
		this.endDateAnswer = new Date(endDateAnswer)
	}
}

const DateRangeSnapshotType = s({
	startDateAnswer: object.isRequired,
	endDateAnswer: object.isRequired,
})

class QuestionSnapshot {
	constructor({
		id,
		name,
		type,
		order,
		is_required: isRequired,
		is_default: isDefault,
		employee_attr_map: employeeAttrMap,
		field_name: fieldName,
		group_name: groupName,
	}) {
		this.id = id
		this.name = name
		this.type = type
		this.order = order
		this.isRequired = isRequired
		this.isDefault = isDefault
		this.employeeAttrMap = employeeAttrMap
		this.fieldName = fieldName
		this.groupName = groupName
	}
}

const QuestionSnapshotType = s({
	id: number.isRequired,
	name: string.isRequired,
	type: number.isRequired, // @QUESTION_TYPE
	order: number.isRequired,
	isRequired: bool.isRequired,
	isDefault: bool.isRequired,
	employeeAttrMap: string.isRequired,
	fieldName: string,
	groupName: string,
})

class EmployeeDetailQuestionAnswerLogExtInfo {
	constructor({
		question_snapshot: questionSnapshot = null,
		answer = null,
		previous_answer: previousAnswer = null,
		new_answer: newAnswer = null,
	}) {
		if (questionSnapshot !== null) {
			this.questionSnapshot = new QuestionSnapshot(questionSnapshot)
		}

		// CREATED
		if (answer !== null) {
			if (questionSnapshot.type === QUESTION_TYPE.DATE_RANGE) {
				this.answer = new DateRangeSnapshot(answer)
			} else if (questionSnapshot.is_default) {
				if (questionSnapshot.type === QUESTION_TYPE.COMBOBOX) {
					this.answer = answer.title_name || answer.position_name || answer.group_name || answer.department_name
				} else if (questionSnapshot.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
					this.answer = answer.employee_full_name || answer.rank_name
				} else {
					this.answer = answer
				}
			} else {
				const namePropKey = Object.keys(answer).find(key => key.includes('name'))
				if (Boolean(namePropKey)) {
					this.answer = answer[namePropKey]
				} else {
					this.answer = answer
				}
			}
		}

		// EDITED
		if (previousAnswer !== null) {
			if (questionSnapshot.type === QUESTION_TYPE.DATE_RANGE) {
				this.previousAnswer = new DateRangeSnapshot(previousAnswer)
			} else if (questionSnapshot.is_default) {
				if (questionSnapshot.type === QUESTION_TYPE.COMBOBOX) {
					this.previousAnswer =
						previousAnswer.title_name ||
						previousAnswer.position_name ||
						previousAnswer.group_name ||
						previousAnswer.department_name
				} else if (questionSnapshot.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
					this.previousAnswer = previousAnswer.employee_full_name || previousAnswer.rank_name
				} else {
					this.previousAnswer = previousAnswer
				}
			} else {
				const namePropKey = Object.keys(previousAnswer).find(key => key.includes('name'))
				if (Boolean(namePropKey)) {
					this.previousAnswer = previousAnswer[namePropKey]
				} else {
					this.previousAnswer = previousAnswer
				}
			}
		}
		if (newAnswer !== null) {
			if (questionSnapshot.type === QUESTION_TYPE.DATE_RANGE) {
				this.newAnswer = new DateRangeSnapshot(newAnswer)
			} else if (questionSnapshot.is_default) {
				if (questionSnapshot.type === QUESTION_TYPE.COMBOBOX) {
					this.newAnswer =
						newAnswer.title_name || newAnswer.position_name || newAnswer.group_name || newAnswer.department_name
				} else if (questionSnapshot.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
					this.newAnswer = newAnswer.employee_full_name || newAnswer.rank_name
				} else {
					this.newAnswer = newAnswer
				}
			} else {
				const namePropKey = Object.keys(newAnswer).find(key => key.includes('name'))
				if (Boolean(namePropKey)) {
					this.newAnswer = newAnswer[namePropKey]
				} else {
					this.newAnswer = newAnswer
				}
			}
		}
	}
}

export const EmployeeDetailQuestionAnswerLogExtInfoType = s({
	questionSnapshot: QuestionSnapshotType,
	answer: oneOfType([
		string,
		// DATE: %Y-%m-%d
		// FILE: file URL
		// TEXT: input value
		// COMBOBOX: choice content
		// MULTIPLE_CHOICE: choice content
		number,
		// NUMBER: input value
		arrayOf(string),
		// CHECKBOX: array of choice content
		DateRangeSnapshotType,
		// DATE_RANGE
	]),
	previousAnswer: oneOfType([
		// types as described in answer
		string,
		number,
		arrayOf(string),
		DateRangeSnapshotType,
	]),
	newAnswer: oneOfType([
		// types as described in answer
		string,
		number,
		arrayOf(string),
		DateRangeSnapshotType,
	]),
})

export default EmployeeDetailQuestionAnswerLogExtInfo
