import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let EngagementComponent

const Engagement = store => {
	if (EngagementComponent === undefined) {
		EngagementComponent = Loadable({
			loader: () => {
				return import('views/Engagement/containers/EngagementContainer')
			},
			loading: RouteLoader,
		})
	}
	return EngagementComponent
}

export default Engagement
