import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { Manager, Target, Popper } from 'react-popper'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

// material ui icons
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Notifications from '@material-ui/icons/Notifications'
import Settings from '@material-ui/icons/Settings'

// modules
import headerLinksStyle from './headerLinksStyle'
import skleemi18n from 'utils/skleemi18n'
import personPlaceholder from 'assets/person_placeholder.jpg'

const i18nDict = {
	LOADING: {
		id: 'Memuat...',
		en: 'Loading...',
	},
	LOAD_MORE: {
		id: 'Muat lainnya...',
		en: 'Load more...',
	},
	CHANGE_PASSWORD: {
		id: 'Ubah sandi',
		en: 'Change password',
	},
	LOGOUT: {
		id: 'Keluar',
		en: 'Logout',
	},
	ENGLISH: {
		id: 'Inggris',
		en: 'English',
	},
	INDONESIAN: {
		id: 'Bahasa Indonesia',
		en: 'Indonesian',
	},
	SIDEBAR_FILTERS: {
		id: 'FILTER SIDEBAR',
		en: 'SIDEBAR FILTERS',
	},
	SIDEBAR_BACKGROUND: {
		id: 'LATAR SIDEBAR',
		en: 'SIDEBAR BACKGROUND',
	},
	LANGUAGE: {
		id: 'BAHASA',
		en: 'LANGUAGE',
	},
	NEW_PASSWORD: {
		id: 'Sandi baru',
		en: 'New password',
	},
	CONFIRM_NEW_PASSWORD: {
		id: 'Konfirmasi sandi baru',
		en: 'New password confirmation',
	},
	SAVE: {
		id: 'Simpan',
		en: 'Save',
	},
}

class HeaderLinks extends React.Component {
	state = {
		isShowingPassword: false,
		showChangePasswordDialog: false,
		profileOpen: false,
		notificationOpen: false,
		settingOpen: false,
	}
	handleClick = () => {
		this.setState({ profileOpen: !this.state.profileOpen, notificationOpen: false, settingOpen: false })
	}
	handleClose = () => {
		this.setState({ profileOpen: false, notificationOpen: false, settingOpen: false })
	}
	handleClickNotification = () => {
		this.setState({ notificationOpen: !this.state.notificationOpen, profileOpen: false, settingOpen: false })
	}
	handleClickSetting = () => {
		this.setState({ settingOpen: !this.state.settingOpen, profileOpen: false, notificationOpen: false })
	}
	isSidebarSettingSelectColor = color => {
		const { frontendSetting } = this.props
		return frontendSetting.sidebar_select_color === color
	}
	isSidebarSettingBgColor = color => {
		const { frontendSetting } = this.props
		return frontendSetting.sidebar_bg_color === color
	}
	changeSettingSelectColor = color => {
		const { handleChangeSetting } = this.props
		this.setState({ settingOpen: true })
		handleChangeSetting('sidebar_select_color', color)
	}
	changeSettingBgColor = color => {
		const { handleChangeSetting } = this.props
		this.setState({ settingOpen: true })
		handleChangeSetting('sidebar_bg_color', color)
	}
	changeSettingLanguage = language => {
		const { handleChangeSetting } = this.props
		this.setState({ settingOpen: true })
		handleChangeSetting('language', language)
	}
	getSettingLanguage = () => {
		const { frontendSetting } = this.props
		return frontendSetting.language || 'en'
	}

	handleClickSavePassword = () => {
		const { changePassword } = this.props
		changePassword(this.state).then(() =>
			this.setState({
				showChangePasswordDialog: false,
				password: '',
				repeatPassword: '',
				isShowingPassword: false,
			})
		)
	}

	render() {
		const {
			classes,
			rtlActive,
			currentUser,
			handleLogout,
			redirect,
			notifications,
			notificationPagination,
			translater,
		} = this.props
		const { profileOpen, notificationOpen, settingOpen } = this.state
		let currentPage
		let totalPage
		let totalCount = 0
		if (notificationPagination) {
			currentPage = notificationPagination.currentPage
			totalPage = notificationPagination.totalPage
			totalCount = notificationPagination.totalCount
		}
		const dropdownItem =
			classes.dropdownItem +
			' ' +
			classNames({
				[classes.dropdownItemRTL]: rtlActive,
			})
		const wrapper = classNames({
			[classes.wrapperRTL]: rtlActive,
		})
		const managerClasses = classNames({
			[classes.managerClasses]: true,
		})
		return (
			<div className={wrapper}>
				<Dialog
					open={this.state.showChangePasswordDialog}
					classes={{ paperWidthSm: classes.passwordDialog }}
					onClose={() =>
						this.setState({
							showChangePasswordDialog: false,
							password: '',
							repeatPassword: '',
							isShowingPassword: false,
						})
					}
				>
					<DialogTitle>{translater.CHANGE_PASSWORD}</DialogTitle>
					<DialogContent className={'d-flex fd-c'}>
						<TextField
							autoFocus
							required
							value={this.state.password}
							type={this.state.isShowingPassword ? 'text' : 'password'}
							margin={'normal'}
							label={translater.NEW_PASSWORD}
							InputProps={{
								endAdornment: (
									<InputAdornment position={'end'}>
										<IconButton
											tabIndex={-1}
											aria-label={'Toggle password visibility'}
											onClick={() => this.setState({ isShowingPassword: !this.state.isShowingPassword })}
										>
											{this.state.isShowingPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							onChange={e => this.setState({ password: e.target.value })}
						/>
						<TextField
							required
							value={this.state.repeatPassword}
							type={this.state.isShowingPassword ? 'text' : 'password'}
							margin={'normal'}
							label={translater.CONFIRM_NEW_PASSWORD}
							InputProps={{
								endAdornment: (
									<InputAdornment position={'end'}>
										<IconButton
											tabIndex={-1}
											aria-label={'Toggle password visibility'}
											onClick={() => this.setState({ isShowingPassword: !this.state.isShowingPassword })}
										>
											{this.state.isShowingPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							onChange={e => this.setState({ repeatPassword: e.target.value })}
						/>
						<Button
							className={'mt-1'}
							disabled={!this.state.password || !this.state.repeatPassword}
							color={'primary'}
							onClick={this.handleClickSavePassword}
						>
							{translater.SAVE}
						</Button>
					</DialogContent>
				</Dialog>
				<Hidden smDown>
					<ClickAwayListener onClickAway={this.handleClose}>
						<React.Fragment>
							<Manager className={managerClasses}>
								<React.Fragment>
									<Target>
										<IconButton
											color="inherit"
											aria-label="Person"
											aria-owns={profileOpen ? 'menu-list' : null}
											aria-haspopup="true"
											onClick={this.handleClick}
											className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
											classes={{
												label: rtlActive ? classes.labelRTL : '',
											}}
										>
											<div className={classes.photo}>
												<img
													src={currentUser && currentUser.picture ? currentUser.picture : personPlaceholder}
													className={classes.avatarImg}
													alt="..."
												/>
											</div>
										</IconButton>
										<Hidden smDown>
											<Button variant={'text'} onClick={this.handleClick}>
												{currentUser.full_name}
											</Button>
										</Hidden>
									</Target>
									{ReactDOM.createPortal(
										<Popper
											placement="bottom-start"
											eventsEnabled={profileOpen}
											style={{ zIndex: 3 }}
											className={classNames({ [classes.popperClose]: !profileOpen }) + ' ' + classes.pooperResponsive}
										>
											<Grow in={profileOpen} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
												<Paper className={classes.dropdown}>
													<MenuList role="menu">
														<MenuItem
															onClick={() => this.setState({ showChangePasswordDialog: true })}
															className={dropdownItem}
														>
															{translater.CHANGE_PASSWORD}
														</MenuItem>
														<MenuItem
															onClick={() => {
																this.handleClose()
																handleLogout()
															}}
															className={dropdownItem}
														>
															{translater.LOGOUT}
														</MenuItem>
													</MenuList>
												</Paper>
											</Grow>
										</Popper>,
										document.querySelector('#portal-root')
									)}
								</React.Fragment>
							</Manager>
							<Manager className={managerClasses}>
								<React.Fragment>
									<Target>
										<IconButton
											color="inherit"
											aria-label="Notifications"
											aria-owns={notificationOpen ? 'menu-list' : null}
											aria-haspopup="true"
											onClick={this.handleClickNotification}
											className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
											classes={{
												label: rtlActive ? classes.labelRTL : '',
											}}
										>
											<Notifications className={rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links} />
											{notifications && <span className={classes.notifications}>{totalCount}</span>}
										</IconButton>
									</Target>
									{ReactDOM.createPortal(
										<Popper
											placement="bottom-start"
											eventsEnabled={notificationOpen}
											style={{ zIndex: 3 }}
											className={
												classNames({ [classes.popperClose]: !notificationOpen }) + ' ' + classes.pooperResponsive
											}
										>
											<Grow in={notificationOpen} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
												<Paper className={classes.dropdown}>
													<MenuList role="menu" style={{ maxHeight: '300px', maxWidth: '400px', overflowY: 'auto' }}>
														{notifications === undefined ? (
															<MenuItem className={classes.dropdownItemWithoutHover}>{translater.LOADING}</MenuItem>
														) : (
															[
																...notifications.map(notification => (
																	<MenuItem
																		key={notification.id}
																		className={dropdownItem}
																		onClick={
																			notification.getRedirectURL()
																				? () => redirect(notification.getRedirectURL())
																				: null
																		}
																	>
																		<notification.icon color={'primary'} className={'mr-half dropdownIcon'} />
																		{notification.shortContent}
																	</MenuItem>
																)),
																currentPage < totalPage ? (
																	<MenuItem
																		key="loadmore"
																		onClick={e => {
																			e.preventDefault()
																			this.setState({
																				notificationOpen: true,
																			})
																			this.props.fetchMoreNotifications()
																		}}
																		className={classes.dropdownItemWithoutHover}
																	>
																		{translater.LOAD_MORE}
																	</MenuItem>
																) : null,
															]
														)}
													</MenuList>
												</Paper>
											</Grow>
										</Popper>,
										document.querySelector('#portal-root')
									)}
								</React.Fragment>
							</Manager>
							<Manager className={managerClasses}>
								<React.Fragment>
									<Target>
										<IconButton
											color="inherit"
											aria-label="Settings"
											aria-owns={settingOpen ? 'menu-list' : null}
											aria-haspopup="true"
											onClick={this.handleClickSetting}
											className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
											classes={{
												label: rtlActive ? classes.labelRTL : '',
											}}
										>
											<Settings className={rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links} />
										</IconButton>
									</Target>
									{ReactDOM.createPortal(
										<Popper
											placement="bottom-start"
											eventsEnabled={settingOpen}
											style={{ zIndex: 3 }}
											className={classNames({ [classes.popperClose]: !settingOpen }) + ' ' + classes.pooperResponsive}
										>
											<Grow in={settingOpen} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
												<Paper className={classes.frontendSettingContainer}>
													<div className={classes.headerTitle}>{translater.SIDEBAR_FILTERS}</div>
													<div style={{ textAlign: 'center ' }}>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgePurple +
																' ' +
																(this.isSidebarSettingSelectColor('purple') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('purple')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeBlue +
																' ' +
																(this.isSidebarSettingSelectColor('blue') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('blue')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeGreen +
																' ' +
																(this.isSidebarSettingSelectColor('green') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('green')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeRed +
																' ' +
																(this.isSidebarSettingSelectColor('red') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('red')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeOrange +
																' ' +
																(this.isSidebarSettingSelectColor('orange') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('orange')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeWhite +
																' ' +
																(this.isSidebarSettingSelectColor('white') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('white')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeRose +
																' ' +
																(this.isSidebarSettingSelectColor('rose') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingSelectColor('rose')}
														/>
													</div>
													<div className={classes.headerTitle}>{translater.SIDEBAR_BACKGROUND}</div>
													<div style={{ textAlign: 'center ' }}>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeBlue +
																' ' +
																(this.isSidebarSettingBgColor('blue') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingBgColor('blue')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeWhite +
																' ' +
																(this.isSidebarSettingBgColor('white') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingBgColor('white')}
														/>
														<span
															className={
																classes.badge +
																' ' +
																classes.badgeBlack +
																' ' +
																(this.isSidebarSettingBgColor('black') ? classes.badgeActive : '')
															}
															onClick={() => this.changeSettingBgColor('black')}
														/>
													</div>
													<div className={classes.headerTitle}>{translater.LANGUAGE}</div>
													<div style={{ textAlign: 'center ' }}>
														<FormControlLabel
															control={
																<Switch
																	checked={this.getSettingLanguage() === 'en'}
																	onChange={() =>
																		this.changeSettingLanguage(this.getSettingLanguage() === 'en' ? 'id' : 'en')
																	}
																	value="checkedA"
																	classes={{
																		checked: classes.switchChecked,
																		bar: classes.switchBarChecked,
																		icon: classes.switchIcon,
																		default: classes.switchUnchecked,
																		iconChecked: classes.switchIconChecked,
																	}}
																/>
															}
															classes={{
																label: classes.label,
															}}
															label={this.getSettingLanguage() === 'en' ? translater.ENGLISH : translater.INDONESIAN}
														/>
													</div>
												</Paper>
											</Grow>
										</Popper>,
										document.querySelector('#portal-root')
									)}
								</React.Fragment>
							</Manager>
						</React.Fragment>
					</ClickAwayListener>
				</Hidden>
			</div>
		)
	}
}

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool,
}

export default withStyles(headerLinksStyle)(skleemi18n(HeaderLinks, i18nDict))
