import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let WorkingCycleSettingComponent

const WorkingCycleSetting = store => {
	if (WorkingCycleSettingComponent === undefined) {
		WorkingCycleSettingComponent = Loadable({
			loader: () => {
				return import('views/WorkingCycleSetting/containers/WorkingCycleSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return WorkingCycleSettingComponent
}
export default WorkingCycleSetting
