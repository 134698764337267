import { s, number, string, bool } from 'modules/types'

class Rank {
	constructor({ id, name, level }) {
		this.id = id
		this.name = name
		this.level = level
	}
}

const RankType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
})

export class EmployeeWithRank {
	constructor({ id, name, NIK, profile_picture: profilePicture, is_archived: isArchived, rank }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.profilePicture = profilePicture
		this.isArchived = isArchived
		this.rank = new Rank(rank)
	}
}

export const EmployeeWithRankType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	profilePicture: string,
	isArchived: bool,
	rank: RankType,
})

export default EmployeeWithRank
