import { cloneDeep } from 'lodash'
import api from 'modules/api'
import { push } from 'react-router-redux'
import { getObjectOrUndefined } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisalAdminAnalytics`

export const API_URLS = {
	sessions: () => `/api/analytics/appraisal/sessions/`,
	sessionEmployees: sessionId => `/api/analytics/appraisal/sessions/${sessionId}/employees/`,
	sessionGroupEmployees: (sessionId, defaultTemplateId, groupIds) =>
		`/api/analytics/appraisal/sessions/${sessionId}/employees/default_templates_with_groups/${defaultTemplateId}/employees/?group_ids=${groupIds}`,
	employmentFields: () => `/api/analytics/appraisal/sessions/get_employment_fields/`,
	groupStatistics: (sessionId, groupId) => `/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/`,
	employeeCounts: (sessionId, groupId, employeeDetailQuestionId) =>
		`/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/get_employee_counts/${employeeDetailQuestionId}/`,
	employmentFieldGroupings: (sessionId, groupId) =>
		`/api/analytics/appraisal/sessions/${sessionId}/groups/${groupId}/groupings/`,
	defaultTemplatesWithGroups: sessionId =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/`,
	categoryStatistics: (sessionId, defaultTemplateId, defaultCategoryId, groupIds) =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_category_statistics/${defaultCategoryId}/?group_ids=${groupIds}`,
	bestPerformers: (sessionId, defaultTemplateId, defaultCategoryId, groupIds, questionId) =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_best_performers/${defaultCategoryId}/?group_ids=${groupIds}&question_id=${questionId}`,
	worstPerformers: (sessionId, defaultTemplateId, defaultCategoryId, groupIds, questionId) =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_worst_performers/${defaultCategoryId}/?group_ids=${groupIds}&question_id=${questionId}`,
	mcqAnswers: (sessionId, defaultTemplateId, questionId, groupIds) =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_mcq_answers/${questionId}/?group_ids=${groupIds}`,
	textAnswers: (sessionId, defaultTemplateId, questionId, groupIds) =>
		`/api/analytics/appraisal/sessions/${sessionId}/default_templates_with_groups/${defaultTemplateId}/get_text_answers/${questionId}/?group_ids=${groupIds}`,
}

const REFRESHED_SESSIONS = `${MODULE_NAME} | REFRESHED_SESSIONS`
const REFRESHED_SESSION_EMPLOYEES = `${MODULE_NAME} | REFRESHED_SESSION_EMPLOYEES`
const REFRESHED_SESSION_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHED_SESSION_EMPLOYEES`
const REFRESHED_EMPLOYMENT_FIELDS = `${MODULE_NAME} | REFRESHED_EMPLOYMENT_FIELDS`
const REFRESHED_GROUP_STATISTICS = `${MODULE_NAME} | REFRESHED_GROUP_STATISTICS`
const REFRESHED_EMPLOYEE_COUNTS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_COUNTS`
const REFRESHED_EMPLOYMENT_FIELD_GROUPINGS = `${MODULE_NAME} | REFRESHED_EMPLOYMENT_FIELD_GROUPINGS`
const REFRESHED_DEFAULT_TEMPLATES_WITH_GROUPS = `${MODULE_NAME} | REFRESHED_DEFAULT_TEMPLATES_WITH_GROUPS`
const REFRESHED_QUESTION_CATEGORY_STATISTICS = `${MODULE_NAME} | REFRESHED_QUESTION_CATEGORY_STATISTICS`
const REFRESHED_BEST_PERFORMERS = `${MODULE_NAME} | REFRESHED_BEST_PERFORMERS`
const REFRESHED_WORST_PERFORMERS = `${MODULE_NAME} | REFRESHED_WORST_PERFORMERS`
const REFRESHED_MCQ_ANSWERS = `${MODULE_NAME} | REFRESHED_MCQ_ANSWERS`
const REFRESHED_TEXT_ANSWERS = `${MODULE_NAME} | REFRESHED_TEXT_ANSWERS`

const COMPARE_STATISTCS = `${MODULE_NAME} | COMPARE_STATISTCS`

const SELECT_EMPLOYEE_GROUP = `${MODULE_NAME} | SELECT_EMPLOYEE_GROUP`
const UNSELECT_EMPLOYEE_GROUP = `${MODULE_NAME} | UNSELECT_EMPLOYEE_GROUP`
const CHANGE_SESSION = `${MODULE_NAME} | CHANGE_SESSION`
const CHANGE_DEFAULT_TEMPLATE = `${MODULE_NAME} | CHANGE_DEFAULT_TEMPLATE`
const CHANGE_QUESTION_CATEGORY = `${MODULE_NAME} | CHANGE_QUESTION_CATEGORY`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------
export function refreshSessions() {
	return dispatch => {
		const apiUrl = API_URLS.sessions()
		return api.analytics.appraisal.sessions.get().then(({ data: sessions }) => {
			dispatch({ type: REFRESHED_SESSIONS, apiUrl, data: sessions })
			return sessions
		})
	}
}

export function refreshSessionEmployees(sessionId) {
	return dispatch => {
		const apiUrl = API_URLS.sessionEmployees(sessionId)
		return api.analytics.appraisal.sessions.getEmployees(sessionId).then(({ data: employees }) => {
			dispatch({ type: REFRESHED_SESSION_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

export function refreshSessionGroupEmployees(groupIds) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.sessionGroupEmployees(selectedSessionId, selectedDefaultTemplateId, groupIds)
		return api.analytics.appraisal.sessions
			.getGroupEmployees(selectedSessionId, selectedDefaultTemplateId, groupIds)
			.then(({ data: employees }) => {
				dispatch({ type: REFRESHED_SESSION_GROUP_EMPLOYEES, apiUrl, data: employees })
				return employees
			})
	}
}

export function refreshEmploymentFields() {
	return dispatch => {
		const apiUrl = API_URLS.employmentFields()
		return api.analytics.appraisal.sessions.getEmploymentFields().then(({ data: fields }) => {
			dispatch({ type: REFRESHED_EMPLOYMENT_FIELDS, apiUrl, data: fields })
			return fields
		})
	}
}

export function refreshGroupStatistics(groupId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId },
			},
		} = getState()
		const apiUrl = API_URLS.groupStatistics(selectedSessionId, groupId)
		return api.analytics.appraisal.sessions.groups
			.getStatistics(selectedSessionId, groupId)
			.then(({ data: groupStatistics }) => {
				dispatch({ type: REFRESHED_GROUP_STATISTICS, apiUrl, data: groupStatistics })
				return groupStatistics
			})
	}
}

export function refreshEmployeeCounts(sessionId, groupId, employeeDetailQuestionId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId = sessionId },
			},
		} = getState()
		const apiUrl = API_URLS.employeeCounts(selectedSessionId, groupId, employeeDetailQuestionId)
		return api.analytics.appraisal.sessions.groups
			.getEmployeeCounts(selectedSessionId, groupId, employeeDetailQuestionId)
			.then(({ data: employeeCounts }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_COUNTS, apiUrl, data: employeeCounts })
				return employeeCounts
			})
	}
}

export function refreshEmploymentFieldGroupings(groupId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId },
			},
		} = getState()
		const apiUrl = API_URLS.employmentFieldGroupings(selectedSessionId, groupId)
		return api.analytics.appraisal.sessions.groups
			.getGroupEmploymentFieldGroupings(selectedSessionId, groupId)
			.then(({ data: employmentFieldGroupings }) => {
				dispatch({ type: REFRESHED_EMPLOYMENT_FIELD_GROUPINGS, apiUrl, data: employmentFieldGroupings })
				return employmentFieldGroupings
			})
	}
}

export function refreshDefaultTemplatesWithGroups(sessionId) {
	return dispatch => {
		const apiUrl = API_URLS.defaultTemplatesWithGroups(sessionId)
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.get(sessionId)
			.then(({ data: defaultTemplatesWithGroups }) => {
				dispatch({ type: REFRESHED_DEFAULT_TEMPLATES_WITH_GROUPS, apiUrl, data: defaultTemplatesWithGroups })
				return defaultTemplatesWithGroups
			})
	}
}

export function refreshCategoryStatistics(defaultCategoryId, groupIds = []) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.categoryStatistics(
			selectedSessionId,
			selectedDefaultTemplateId,
			defaultCategoryId,
			groupIds
		)
		dispatch({ type: REFRESHED_QUESTION_CATEGORY_STATISTICS, apiUrl, data: null })
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.getCategoryStatistics(selectedSessionId, selectedDefaultTemplateId, defaultCategoryId, groupIds)
			.then(({ data: categoryStatistics }) => {
				dispatch({ type: REFRESHED_QUESTION_CATEGORY_STATISTICS, apiUrl, data: categoryStatistics })
				return categoryStatistics
			})
	}
}

export function refreshBestPerformers(defaultCategoryId, groupIds = [], questionId = -1) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.bestPerformers(
			selectedSessionId,
			selectedDefaultTemplateId,
			defaultCategoryId,
			groupIds,
			questionId
		)
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.getBestPerformers(selectedSessionId, selectedDefaultTemplateId, defaultCategoryId, groupIds, questionId)
			.then(({ data: employeesWithScore }) => {
				dispatch({ type: REFRESHED_BEST_PERFORMERS, apiUrl, data: employeesWithScore })
				return employeesWithScore
			})
	}
}

export function refreshWorstPerformers(defaultCategoryId, groupIds = [], questionId = -1) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.worstPerformers(
			selectedSessionId,
			selectedDefaultTemplateId,
			defaultCategoryId,
			groupIds,
			questionId
		)
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.getWorstPerformers(selectedSessionId, selectedDefaultTemplateId, defaultCategoryId, groupIds, questionId)
			.then(({ data: employeesWithScore }) => {
				dispatch({ type: REFRESHED_WORST_PERFORMERS, apiUrl, data: employeesWithScore })
				return employeesWithScore
			})
	}
}

export function refreshMcqAnswers(questionId, groupIds) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.mcqAnswers(selectedSessionId, selectedDefaultTemplateId, questionId, groupIds)
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.getMcqAnswers(selectedSessionId, selectedDefaultTemplateId, questionId, groupIds)
			.then(({ data: mcqAnswers }) => {
				dispatch({ type: REFRESHED_MCQ_ANSWERS, apiUrl, data: mcqAnswers })
				return mcqAnswers
			})
	}
}

export function refreshTextAnswers(questionId, groupIds) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		const apiUrl = API_URLS.textAnswers(selectedSessionId, selectedDefaultTemplateId, questionId, groupIds)
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.getTextAnswers(selectedSessionId, selectedDefaultTemplateId, questionId, groupIds)
			.then(({ data: textAnswers }) => {
				dispatch({ type: REFRESHED_TEXT_ANSWERS, apiUrl, data: textAnswers })
				return textAnswers
			})
	}
}

export function compareStatistics(data) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				data: { selectedSessionId, selectedDefaultTemplateId },
			},
		} = getState()
		return api.analytics.appraisal.sessions.default_templates_with_groups
			.compareStatistics(selectedSessionId, selectedDefaultTemplateId, data)
			.then(({ data: categoriesWithComparisons }) => {
				dispatch({ type: COMPARE_STATISTCS })
				return categoriesWithComparisons
			})
	}
}

export function changeSession(sessionId) {
	return dispatch => {
		dispatch({ type: CHANGE_SESSION, sessionId })
	}
}

export function selectSession(sessionId) {
	return dispatch => {
		dispatch(changeSession(sessionId))
		dispatch(push(`/admin_appraisal/analytics/sessions/${sessionId}`))
		dispatch(refreshSessionEmployees(sessionId))
		dispatch(refreshDefaultTemplatesWithGroups(sessionId)).then(defaultTemplates => {
			if (defaultTemplates.length > 0) {
				dispatch(selectDefaultTemplate(defaultTemplates[0].defaultTemplate.defaultTemplateId))
			}
		})
	}
}

export function changeDefaultTemplate(defaultTemplateId) {
	return dispatch => {
		dispatch({ type: CHANGE_DEFAULT_TEMPLATE, defaultTemplateId })
	}
}

export function selectDefaultTemplate(defaultTemplateId) {
	return (dispatch, getState) => {
		const {
			appraisalAdminAnalytics: {
				api,
				data: { selectedSessionId },
			},
		} = getState()
		const defaultTemplatesWithGroups = getObjectOrUndefined(api, API_URLS.defaultTemplatesWithGroups(selectedSessionId))
		const selectedTemplate = defaultTemplatesWithGroups.find(
			item => item.defaultTemplate.defaultTemplateId === defaultTemplateId
		)
		if (selectedTemplate) {
			dispatch(changeDefaultTemplate(defaultTemplateId))
			if (selectedTemplate.defaultTemplate.categories.length > 0) {
				dispatch(selectDefaultCategory(selectedTemplate.defaultTemplate.categories[0].defaultQuestionCategoryId))
			}
		}
	}
}

export function selectEmployeeGroup(groupId) {
	return (dispatch, getState) => {
		dispatch({ type: SELECT_EMPLOYEE_GROUP, groupId })
		const {
			appraisalAdminAnalytics: {
				data: { selectedQuestionCategoryId, selectedGroupIds },
			},
		} = getState()
		dispatch(refreshCategoryStatistics(selectedQuestionCategoryId, selectedGroupIds))
		dispatch(refreshBestPerformers(selectedQuestionCategoryId, selectedGroupIds))
		dispatch(refreshWorstPerformers(selectedQuestionCategoryId, selectedGroupIds))
	}
}

export function unselectEmployeeGroup(groupId) {
	return (dispatch, getState) => {
		dispatch({ type: UNSELECT_EMPLOYEE_GROUP, groupId })
		const {
			appraisalAdminAnalytics: {
				data: { selectedQuestionCategoryId, selectedGroupIds },
			},
		} = getState()
		dispatch(refreshCategoryStatistics(selectedQuestionCategoryId, selectedGroupIds))
		dispatch(refreshBestPerformers(selectedQuestionCategoryId, selectedGroupIds))
		dispatch(refreshWorstPerformers(selectedQuestionCategoryId, selectedGroupIds))
	}
}

export function changeDefaultCategory(defaultCategoryId) {
	return dispatch => {
		dispatch({ type: CHANGE_QUESTION_CATEGORY, defaultCategoryId })
	}
}

export function selectDefaultCategory(defaultCategoryId) {
	return dispatch => {
		dispatch(changeDefaultCategory(defaultCategoryId))
		dispatch(refreshCategoryStatistics(defaultCategoryId))
		dispatch(refreshBestPerformers(defaultCategoryId))
		dispatch(refreshWorstPerformers(defaultCategoryId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshEmploymentFields())
		dispatch(refreshSessions()).then(sessions => {
			const directUrlAccess = location.pathname.includes('sessions') // eslint-disable-line
			if (!directUrlAccess && sessions.length > 0) {
				dispatch(selectSession(sessions[0].appraisalSessionId))
			}
		})
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		selectedSessionId: undefined,
		selectedDefaultTemplateId: undefined,
		selectedQuestionCategoryId: undefined,
		selectedGroupIds: [],
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_SESSIONS:
		case REFRESHED_SESSION_EMPLOYEES:
		case REFRESHED_SESSION_GROUP_EMPLOYEES:
		case REFRESHED_EMPLOYMENT_FIELDS:
		case REFRESHED_GROUP_STATISTICS:
		case REFRESHED_EMPLOYEE_COUNTS:
		case REFRESHED_EMPLOYMENT_FIELD_GROUPINGS:
		case REFRESHED_DEFAULT_TEMPLATES_WITH_GROUPS:
		case REFRESHED_QUESTION_CATEGORY_STATISTICS:
		case REFRESHED_BEST_PERFORMERS:
		case REFRESHED_WORST_PERFORMERS:
		case REFRESHED_MCQ_ANSWERS:
		case REFRESHED_TEXT_ANSWERS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CHANGE_SESSION:
			return {
				...state,
				data: {
					...state.data,
					selectedSessionId: action.sessionId,
				},
			}
		case CHANGE_DEFAULT_TEMPLATE:
			return {
				...state,
				data: {
					...state.data,
					selectedDefaultTemplateId: action.defaultTemplateId,
				},
			}
		case CHANGE_QUESTION_CATEGORY:
			return {
				...state,
				data: {
					...state.data,
					selectedQuestionCategoryId: action.defaultCategoryId,
				},
			}
		case SELECT_EMPLOYEE_GROUP:
			return {
				...state,
				data: {
					...state.data,
					selectedGroupIds: [...state.data.selectedGroupIds, action.groupId],
				},
			}
		case UNSELECT_EMPLOYEE_GROUP:
			return {
				...state,
				data: {
					...state.data,
					selectedGroupIds: state.data.selectedGroupIds.filter(id => id !== action.groupId),
				},
			}
		case UNLOAD_MODULE:
			return cloneDeep(initialState)
		default:
			return state
	}
}
