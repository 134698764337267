import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { AUDIENCE_TYPE } from 'modules/models/brainstorming/BrainstormQuestion'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = 'brainstorming'

export const QUESTION_LIST_TYPE = {
	ALL: 0,
	GROUP: 1,
	MY: 2,
	ANSWERED_BY_ME: 3,
}

const CREATED_QUESTION = `${MODULE_NAME} | CREATED QUESTION`
const ANSWER_QUESTION = `${MODULE_NAME} | ANSWER QUESTION`
const TOGGLE_NOTIFICATION = `${MODULE_NAME} | TOGGLE NOTIFICATION`
const CHANGE_QUESTION_STATUS = `${MODULE_NAME} | CHANGE QUESTION STATUS`

const OPEN_CREATE_QUESTION_FORM = `${MODULE_NAME} | OPEN CREATE QUESTION FORM`
const OPEN_QUESTION_LIST = `${MODULE_NAME} | OPEN QUESTION LIST`
const EXPAND_QUESTION = `${MODULE_NAME} | EXPAND QUESTION`
const COLLAPSE_QUESTION = `${MODULE_NAME} | COLLAPSE QUESTION`

const REFRESHING_QUESTIONS = `${MODULE_NAME} | REFRESHING QUESTIONS`
const REFRESHING_MY_QUESTIONS = `${MODULE_NAME} | REFRESHING MY QUESTIONS`
const REFRESHING_ANSWERED_BY_ME_QUESTIONS = `${MODULE_NAME} | REFRESHING ANSWERED BY ME QUESTIONS`

const REFRESHED_QUESTIONS = `${MODULE_NAME} | REFRESHED QUESTIONS`
const REFRESHED_MY_QUESTIONS = `${MODULE_NAME} | REFRESHED MY QUESTIONS`
const REFRESHED_ANSWERED_BY_ME_QUESTIONS = `${MODULE_NAME} | REFRESHED ANSWERED BY ME QUESTIONS`
const REFRESHED_QUESTION_DETAIL = `${MODULE_NAME} | REFRESHED QUESTION DETAIL`
const CLEAR_ANSWERS = `${MODULE_NAME} | CLEAR_ANSWERS`
const REFRESHED_MY_ANSWERS = `${MODULE_NAME} | REFRESHED MY ANSWERS`
const REFRESHED_OTHER_ANSWERS = `${MODULE_NAME} | REFRESHED OTHER ANSWERS`

const LOAD_MORE_QUESTIONS = `${MODULE_NAME} | LOAD MORE QUESTIONS`
const LOAD_MORE_MY_GROUP_QUESTIONS = `${MODULE_NAME} | LOAD MORE MY GROUP QUESTIONS`
const LOAD_MORE_MY_QUESTIONS = `${MODULE_NAME} | LOAD MORE MY QUESTIONS`
const LOAD_MORE_ANSWERED_BY_ME_QUESTIONS = `${MODULE_NAME} | LOAD MORE ANSWERED BY ME QUESTIONS`

const ERROR_REFRESHING_QUESTIONS = `${MODULE_NAME} | ERROR REFRESHING QUESTIONS`
const ERROR_REFRESHING_MY_QUESTIONS = `${MODULE_NAME} | ERROR REFRESHING MY QUESTIONS`
const ERROR_REFRESHING_ANSWERED_BY_ME_QUESTIONS = `${MODULE_NAME} | ERROR REFRESHING ANSWERED BY ME QUESTIONS`
const ERROR_REFRESHING_QUESTION_DETAIL = `${MODULE_NAME} | ERROR REFRESHING QUESTION DETAIL`

const ERROR_CREATING_QUESTION = `${MODULE_NAME} | ERROR CREATING QUESTION`
const ERROR_ANSWERING_QUESTION = `${MODULE_NAME} | ERROR ANSWERING QUESTION`
const ERROR_TOGGLING_NOTIFICATION = `${MODULE_NAME} | ERROR TOGGLING NOTIFICATION`
const ERROR_CHANGING_QUESTION_STATUS = `${MODULE_NAME} | ERROR CHANGING QUESTION STATUS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshQuestion(audienceType, page = 1) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/`
		dispatch({ type: REFRESHING_QUESTIONS, apiUrl })
		return api.brainstorm.questions
			.get(audienceType, page)
			.then(({ data, pagination }) => {
				dispatch({ type: REFRESHED_QUESTIONS, data, apiUrl, pagination })
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_QUESTIONS })
				throw err
			})
	}
}

function refreshMyQuestions(page = 1) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/my_questions/`
		dispatch({ type: REFRESHING_MY_QUESTIONS, apiUrl })
		return api.brainstorm.questions
			.getMyQuestions(page)
			.then(({ data, pagination }) => {
				dispatch({ type: REFRESHED_MY_QUESTIONS, data, apiUrl, pagination })
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_MY_QUESTIONS })
				throw err
			})
	}
}

function refreshAnsweredByMeQuestions(page = 1) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/answered_questions/`
		dispatch({ type: REFRESHING_ANSWERED_BY_ME_QUESTIONS, apiUrl })
		return api.brainstorm.questions
			.getAnsweredQuestions(page)
			.then(({ data, pagination }) => {
				dispatch({ type: REFRESHED_ANSWERED_BY_ME_QUESTIONS, data, apiUrl, pagination })
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_ANSWERED_BY_ME_QUESTIONS })
				throw err
			})
	}
}

function refreshQuestionDetail(questionId) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/${questionId}/`
		return api.brainstorm.questions
			.getDetail(questionId)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_QUESTION_DETAIL, data, questionId, apiUrl })
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_QUESTION_DETAIL })
				throw err
			})
	}
}

function clearAnswers(questionId) {
	return dispatch => {
		dispatch({ type: CLEAR_ANSWERS, questionId })
	}
}

function refreshMyAnswers(questionId) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/${questionId}/get_my_answers/`
		return api.brainstorm.questions.getMyAnswers(questionId).then(({ data }) => {
			dispatch({ type: REFRESHED_MY_ANSWERS, data, questionId, apiUrl })
		})
	}
}

function refreshOtherAnswers(questionId) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/${questionId}/get_other_answers/`
		return api.brainstorm.questions.getOtherAnswers(questionId).then(({ data }) => {
			dispatch({ type: REFRESHED_OTHER_ANSWERS, data, questionId, apiUrl })
		})
	}
}

export function loadMoreQuestions() {
	return (dispatch, getState) => {
		const apiUrl = `/api/brainstorm/questions/`
		dispatch({ type: REFRESHING_QUESTIONS, apiUrl })
		const {
			brainstorming: { pagination },
		} = getState()
		const { currentPage } = pagination[apiUrl]
		return api.brainstorm.questions.get(undefined, currentPage + 1).then(({ data, pagination }) => {
			dispatch({ type: LOAD_MORE_QUESTIONS, data, apiUrl, pagination })
		})
	}
}

export function loadMoreMyGroupQuestions() {
	return (dispatch, getState) => {
		const apiUrl = `/api/brainstorm/questions/`
		dispatch({ type: REFRESHING_QUESTIONS, apiUrl })
		const {
			brainstorming: { pagination },
		} = getState()
		const { currentPage } = pagination[apiUrl]
		return api.brainstorm.questions.get(AUDIENCE_TYPE.GROUP, currentPage + 1).then(({ data, pagination }) => {
			dispatch({ type: LOAD_MORE_MY_GROUP_QUESTIONS, data, apiUrl, pagination })
		})
	}
}

export function loadMoreMyQuestions() {
	return (dispatch, getState) => {
		const apiUrl = `/api/brainstorm/questions/my_questions/`
		dispatch({ type: REFRESHING_MY_QUESTIONS, apiUrl })
		const {
			brainstorming: { pagination },
		} = getState()
		const { currentPage, totalPage } = pagination[apiUrl]
		if (currentPage <= totalPage) {
			return api.brainstorm.questions.getMyQuestions(currentPage + 1).then(({ data, pagination }) => {
				dispatch({ type: LOAD_MORE_MY_QUESTIONS, data, apiUrl, pagination })
			})
		}
		return null
	}
}

export function loadMoreAnsweredByMeQuestions() {
	return (dispatch, getState) => {
		const apiUrl = `/api/brainstorm/questions/answered_questions/`
		dispatch({ type: REFRESHING_ANSWERED_BY_ME_QUESTIONS, apiUrl })
		const {
			brainstorming: { pagination },
		} = getState()
		const { currentPage } = pagination[apiUrl]
		return api.brainstorm.questions.getAnsweredQuestions(currentPage + 1).then(({ data, pagination }) => {
			dispatch({ type: LOAD_MORE_ANSWERED_BY_ME_QUESTIONS, data, apiUrl, pagination })
		})
	}
}

export function createQuestion(formData) {
	return dispatch => {
		return api.brainstorm.questions
			.create(formData)
			.then(({ data }) => {
				dispatch(openQuestionList())
				dispatch(refreshQuestion())
				dispatch({ type: CREATED_QUESTION })
				toast(`Successfully posted new question`)
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_CREATING_QUESTION })
				throw err
			})
	}
}

export function answerQuestion(questionId, content) {
	return dispatch => {
		const apiUrl = `/api/brainstorm/questions/${questionId}/`
		dispatch({ type: ANSWER_QUESTION, questionId, apiUrl, content })
		return api.brainstorm.questions
			.answerQuestion(questionId, content)
			.then(({ data }) => {
				dispatch(refreshQuestionDetail(questionId))
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_ANSWERING_QUESTION, questionId })
				throw err
			})
	}
}

export function toggleNotification(questionId, isActive) {
	return (dispatch, getState) => {
		let apiUrl
		const {
			brainstorming: {
				data: { openedListType },
			},
		} = getState()
		if (openedListType === QUESTION_LIST_TYPE.MY) {
			apiUrl = `/api/brainstorm/questions/my_questions/`
		} else if (openedListType === QUESTION_LIST_TYPE.ANSWERED_BY_ME) {
			apiUrl = `/api/brainstorm/questions/answered_questions/`
		} else {
			apiUrl = `/api/brainstorm/questions/`
		}
		dispatch({ type: TOGGLE_NOTIFICATION, apiUrl, questionId, isActive })
		return api.brainstorm.questions.toggleNotification(questionId, isActive).catch(err => {
			dispatch({ type: ERROR_TOGGLING_NOTIFICATION })
			throw err
		})
	}
}

export function changeQuestionStatus(questionId, status) {
	return (dispatch, getState) => {
		let apiUrl
		const {
			brainstorming: {
				data: { openedListType },
			},
		} = getState()
		if (openedListType === QUESTION_LIST_TYPE.MY) {
			apiUrl = `/api/brainstorm/questions/my_questions/`
		} else if (openedListType === QUESTION_LIST_TYPE.ANSWERED_BY_ME) {
			apiUrl = `/api/brainstorm/questions/answered_questions/`
		} else {
			apiUrl = `/api/brainstorm/questions/`
		}
		dispatch({ type: CHANGE_QUESTION_STATUS, apiUrl, questionId, status })
		return api.brainstorm.questions
			.changeStatus(questionId, status)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				dispatch({ type: ERROR_CHANGING_QUESTION_STATUS })
				throw err
			})
	}
}

export function openCreateQuestionForm() {
	return dispatch => {
		dispatch({ type: OPEN_CREATE_QUESTION_FORM })
	}
}

export function openQuestionList(listType = QUESTION_LIST_TYPE.ALL) {
	return dispatch => {
		dispatch({ type: OPEN_QUESTION_LIST, listType })
		switch (listType) {
			case QUESTION_LIST_TYPE.ANSWERED_BY_ME:
				return dispatch(refreshAnsweredByMeQuestions())
			case QUESTION_LIST_TYPE.MY:
				return dispatch(refreshMyQuestions())
			case QUESTION_LIST_TYPE.GROUP:
				return dispatch(refreshQuestion(AUDIENCE_TYPE.GROUP))
			case QUESTION_LIST_TYPE.ALL:
			default:
				return dispatch(refreshQuestion())
		}
	}
}

export function expandQuestion(questionId) {
	return (dispatch, getState) => {
		const apiUrl = `/api/brainstorm/questions/${questionId}/`
		const {
			brainstorming: { api, data },
		} = getState()
		dispatch({ type: EXPAND_QUESTION, questionId })
		if (data.openedListType === QUESTION_LIST_TYPE.ANSWERED_BY_ME) {
			dispatch(clearAnswers(questionId))
			dispatch(refreshMyAnswers(questionId))
			dispatch(refreshOtherAnswers(questionId))
		} else if (api[apiUrl] === undefined) {
			dispatch(refreshQuestionDetail(questionId))
		}
	}
}

export function collapseQuestion(questionId) {
	return dispatch => {
		dispatch({ type: COLLAPSE_QUESTION, questionId })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshQuestion())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		isCreateQuestionFormOpen: false,
		openedListType: QUESTION_LIST_TYPE.ALL,
		answersDict: {},
		expandedQuestionIds: [],
	},
	pagination: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_QUESTIONS:
		case REFRESHING_MY_QUESTIONS:
		case REFRESHING_ANSWERED_BY_ME_QUESTIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_QUESTIONS:
		case REFRESHED_ANSWERED_BY_ME_QUESTIONS:
		case REFRESHED_MY_QUESTIONS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case REFRESHED_QUESTION_DETAIL:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				data: {
					...state.data,
					answersDict: {
						...state.data.answersDict,
						[action.questionId]: action.data.answers,
					},
				},
			}
		case CLEAR_ANSWERS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: undefined,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
				data: {
					...state.data,
					answersDict: {
						...state.data.answersDict,
						[action.questionId]: [],
					},
				},
			}
		case REFRESHED_MY_ANSWERS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				data: {
					...state.data,
					answersDict: {
						...state.data.answersDict,
						[action.questionId]: [...action.data, ...(state.data.answersDict || [])],
					},
				},
			}
		case REFRESHED_OTHER_ANSWERS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				data: {
					...state.data,
					answersDict: {
						...state.data.answersDict,
						[action.questionId]: [...(state.data.answersDict[action.questionId] || []), ...action.data],
					},
				},
			}
		case LOAD_MORE_QUESTIONS:
		case LOAD_MORE_MY_GROUP_QUESTIONS:
		case LOAD_MORE_MY_QUESTIONS:
		case LOAD_MORE_ANSWERED_BY_ME_QUESTIONS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [...state.api[action.apiUrl], ...action.data],
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case ANSWER_QUESTION:
			return {
				...state,
				api: {
					...state.api,
					'/api/brainstorm/questions/': state.api['/api/brainstorm/questions/'].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								answerCount: question.answerCount + 1,
							}
						}
						return question
					}),
					[action.apiUrl]: {
						...state.api[action.apiUrl],
						answers:
							state.api[action.apiUrl] === undefined
								? [
										{
											content: action.content,
											added: new Date(),
										},
								  ]
								: [
										...state.api[action.apiUrl].answers,
										{
											content: action.content,
											added: new Date(),
										},
								  ],
					},
				},
			}
		case ERROR_ANSWERING_QUESTION:
			return {
				...state,
				api: {
					...state.api,
					'/api/brainstorm/questions': state.api['/api/brainstorm/questions/'].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								answerCount: question.answerCount - 1,
							}
						}
						return question
					}),
					[action.apiUrl]: {
						...state.api[action.apiUrl],
						answers: state.api[action.apiUrl].answers.slice(0, -1),
					},
				},
			}
		case TOGGLE_NOTIFICATION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: state.api[action.apiUrl].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								notification: action.isActive,
							}
						}
						return question
					}),
				},
			}
		case ERROR_TOGGLING_NOTIFICATION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: state.api[action.apiUrl].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								notification: !action.isActive,
							}
						}
						return question
					}),
				},
			}
		case CHANGE_QUESTION_STATUS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: state.api[action.apiUrl].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								status: action.status,
							}
						}
						return question
					}),
				},
			}
		case ERROR_CHANGING_QUESTION_STATUS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: state.api[action.apiUrl].map(question => {
						if (question.id === action.questionId) {
							return {
								...question,
								status: !action.status,
							}
						}
						return question
					}),
				},
			}
		case OPEN_CREATE_QUESTION_FORM:
			return {
				...state,
				data: {
					...state.data,
					isCreateQuestionFormOpen: true,
					openedListType: undefined,
				},
			}
		case OPEN_QUESTION_LIST:
			return {
				...state,
				data: {
					...state.data,
					isCreateQuestionFormOpen: false,
					openedListType: action.listType,
					expandedQuestionIds: [],
				},
			}
		case EXPAND_QUESTION:
			return {
				...state,
				data: {
					...state.data,
					expandedQuestionIds: _.union(state.data.expandedQuestionIds, [action.questionId]),
				},
			}
		case COLLAPSE_QUESTION:
			return {
				...state,
				data: {
					...state.data,
					expandedQuestionIds: state.data.expandedQuestionIds.filter(id => id !== action.questionId),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
