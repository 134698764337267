import { s, number, object, string } from 'modules/types'
import WalletLogExtInfo, { WalletLogExtInfoType } from './WalletLogExtInfo'
import { WALLET_LOG_TYPE } from './index'

export class WalletLog {
	constructor({
		posting_date: postingDate,
		timeoff_type_name: timeoffTypeName,
		balance_change: balanceChange,
		balance_on_date: balanceOnDate,
		created_by_name: createdByName,
		type,
		ext_info: extInfo,
		added,
	}) {
		this.postingDate = new Date(postingDate)
		this.timeoffTypeName = timeoffTypeName
		this.balanceChange = balanceChange
		this.balanceOnDate = balanceOnDate
		this.createdByName = createdByName
		this.type = type
		this.extInfo = new WalletLogExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}

	displayType() {
		switch (this.type) {
			case WALLET_LOG_TYPE.TIMEOFF_DEDUCTED:
				return 'TIMEOFF_DEDUCTED'
			case WALLET_LOG_TYPE.ENTITLEMENT:
				return 'ENTITLEMENT'
			case WALLET_LOG_TYPE.MANUAL_ADJUSTMENT:
				return 'MANUAL_ADJUSTMENT'
			case WALLET_LOG_TYPE.AUTO_ADJUSTMENT:
				return 'AUTO_ADJUSTMENT'
			case WALLET_LOG_TYPE.INITIAL_BALANCE:
				return 'INITIAL_BALANCE'
			default:
				return 'UNKNOWN_TYPE'
		}
	}
}

export const WalletLogType = s({
	postingDate: object.isRequired,
	timeoffTypeName: string.isRequired,
	balanceChange: number.isRequired,
	balanceOnDate: object.isRequired,
	createdByName: string.isRequired,
	type: number.isRequired,
	extInfo: WalletLogExtInfoType,
	added: object.isRequired,
})

export default WalletLog
