import { s, arrayOf, number, string } from 'modules/types'

class DistributionWithCount {
	constructor({ filter_value_id: filterValueId, filter_value_name: filterValueName, count }) {
		this.filterValueId = filterValueId
		this.filterValueName = filterValueName
		this.count = count
		this.name = filterValueName // used for PieChart API
		this.value = count // used for PieChart API
	}
}

const DistributionWithCountType = s({
	filterValueId: number.isRequired,
	filterValueName: string.isRequired,
	count: number.isRequired,
})

class QuestionWithStatistics {
	constructor({ question_id: questionId, question_name: questionName, statistics }) {
		this.questionId = questionId
		this.questionName = questionName
		this.statistics = statistics.map(item => new DistributionWithCount(item))
	}
}

const QuestionWithStatisticsType = s({
	questionId: number.isRequired,
	questionName: string.isRequired,
	statistics: arrayOf(DistributionWithCountType),
})

class FieldGroupChart {
	constructor({ field_group_id: fieldGroupId, field_group_name: fieldGroupName, charts }) {
		this.fieldGroupId = fieldGroupId
		this.fieldGroupName = fieldGroupName
		this.charts = charts.map(item => new QuestionWithStatistics(item))
	}
}

export const FieldGroupChartType = s({
	fieldGroupId: number.isRequired,
	fieldGroupName: string.isRequired,
	charts: arrayOf(QuestionWithStatisticsType),
})

export default FieldGroupChart
