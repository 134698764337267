import { push } from 'react-router-redux'
import { reset } from 'redux-form'
import _ from 'lodash'
import api from 'modules/api'
import { getObjectOrUndefined, mapAppraiserTypeToDisplay, toast } from 'utils'
import AppraisalAdminSession, { APPRAISAL_SESSION_STATUS } from 'modules/models/appraisal_admin/session'
import { PERMISSIONS } from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisal_admin_session`

export const API_URLS = {
	sessionsSimple: () => `/api/appraisal_admin/appraisal_session/sessions/simple/`,
	sessionDetail: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/`,
	defaultTemplates: () => `/api/appraisal_admin/appraisal_session/sessions/get_default_templates/`,
	sessionStages: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/stages/`,
	sessionGroups: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/`,
	sessionSubSessions: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/sub_sessions/`,
	sessionGroupStages: (sessionId, groupId) =>
		`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/groups/${groupId}/group_stages/`,
	unusedCategorizeOptions: sessionId =>
		`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_unused_categorize_options/`,
	filters: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_filters/`,
	employees: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_employees/`,
	allEmployees: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_all_employees/`,
	externalEmployees: sessionId =>
		`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_external_employees/`,
	groupsWithAppraisees: sessionId =>
		`/api/appraisal_admin/appraisal_session/sessions/${sessionId}/get_groups_with_appraisees/`,
	groupLeaderRoles: () => `/api/setting/permission/group_leader_roles/`,
	ranks: sessionId => `/api/appraisal_admin/appraisal_session/sessions/${sessionId}/ranks/`,
}

const CREATED_SESSION = `${MODULE_NAME} | CREATED SESSION`
const STARTED_SESSION = `${MODULE_NAME} | STARTED SESSION`
const STOPPED_SESSION = `${MODULE_NAME} | STOPPED SESSION`
const EDITED_SESSION = `${MODULE_NAME} | EDITED SESSION`
const DELETED_SESSION = `${MODULE_NAME} | DELETED SESSION`
export const SELECT_SESSION = `${MODULE_NAME} | SELECT SESSION`
const REMOVED_APPRAISEES = `${MODULE_NAME} | REMOVED APPRAISEES`
const ADDED_GROUP_BY_CATEGORIZE = `${MODULE_NAME} | ADDED GROUP BY CATEGORIZE`
const EDITED_APPRAISEES = `${MODULE_NAME} | EDITED APPRAISEES`

const CREATED_STAGE = `${MODULE_NAME} | CREATED STAGE`
const EDITED_STAGE = `${MODULE_NAME} | EDITED STAGE`
const DELETED_STAGE = `${MODULE_NAME} | DELETED STAGE`

const CREATED_GROUP = `${MODULE_NAME} | CREATED GROUP`
const EDITED_GROUP = `${MODULE_NAME} | EDITED GROUP`
const DELETED_GROUP = `${MODULE_NAME} | DELETED GROUP`

const CREATED_APPRAISER_TYPE = `${MODULE_NAME} | CREATED APPRAISER TYPE`
const DELETED_APPRAISER_TYPE = `${MODULE_NAME} | DELETED APPRAISER TYPE`

const CHANGE_ACTIVE_EMPLOYEE_GROUP = `${MODULE_NAME} | CHANGE ACTIVE EMPLOYEE GROUP`

const REFRESHING_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHING SESSIONS SIMPLE`
const REFRESHED_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHED SESSIONS SIMPLE`
const REFRESHING_SESSION_DETAIL = `${MODULE_NAME} | REFRESHING SESSION DETAIL`
const REFRESHED_SESSION_DETAIL = `${MODULE_NAME} | REFRESHED SESSION DETAIL`
const REFRESHING_DEFAULT_TEMPLATES = `${MODULE_NAME} | REFRESHING DEFAULT TEMPLATES`
const REFRESHED_DEFAULT_TEMPLATES = `${MODULE_NAME} | REFRESHED DEFAULT TEMPLATES`
const REFRESHING_SESSION_STAGES = `${MODULE_NAME} | REFRESHING SESSION STAGES`
const REFRESHED_SESSION_STAGES = `${MODULE_NAME} | REFRESHED SESSION STAGES`
const REFRESHING_SESSION_GROUPS = `${MODULE_NAME} | REFRESHING SESSION GROUPS`
const REFRESHED_SESSION_GROUPS = `${MODULE_NAME} | REFRESHED SESSION GROUPS`
const REFRESHING_SESSION_SUB_SESSIONS = `${MODULE_NAME} | REFRESHING SESSIONS SUB SESSIONS`
const REFRESHED_SESSION_SUB_SESSIONS = `${MODULE_NAME} | REFRESHED SESSIONS SUB SESSIONS`
const REFRESHING_UNUSED_CATEGORIZE_OPTIONS = `${MODULE_NAME} | REFRESHING UNUSED CATEGORIZE OPTIONS`
const REFRESHED_UNUSED_CATEGORIZE_OPTIONS = `${MODULE_NAME} | REFRESHED UNUSED CATEGORIZE OPTIONS`
const REFRESHING_FILTERS = `${MODULE_NAME} | REFRESHING FILTERS`
const REFRESHED_FILTERS = `${MODULE_NAME} | REFRESHED FILTERS`
const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING EMPLOYEES`
const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED EMPLOYEES`
const REFRESHING_ALL_EMPLOYEES = `${MODULE_NAME} | REFRESHING ALL EMPLOYEES`
const REFRESHED_ALL_EMPLOYEES = `${MODULE_NAME} | REFRESHED ALL EMPLOYEES`
const REFRESHING_EXTERNAL_EMPLOYEES = `${MODULE_NAME} | REFRESHING EXTERNAL EMPLOYEES`
const REFRESHED_EXTERNAL_EMPLOYEES = `${MODULE_NAME} | REFRESHED EXTERNAL EMPLOYEES`
const REFRESHING_GROUPS_WITH_APPRAISEES = `${MODULE_NAME} | REFRESHING GROUPS WITH APPRAISEES`
const REFRESHED_GROUPS_WITH_APPRAISEES = `${MODULE_NAME} | REFRESHED GROUPS WITH APPRAISEES`
const REFRESHED_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHED GROUP LEADER ROLES`
const REFRESHING_RANKS = `${MODULE_NAME} | REFRESHING RANKS`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED RANKS`
const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------
function refreshSessionsSimple() {
	return dispatch => {
		let apiUrl = API_URLS.sessionsSimple()
		dispatch({ type: REFRESHING_SESSIONS_SIMPLE, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getSessionsSimple().then(({ data: sessions }) => {
			dispatch({ type: REFRESHED_SESSIONS_SIMPLE, data: sessions, apiUrl })
			return sessions
		})
	}
}

export function refreshSessionDetail(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.sessionDetail(sessionId)
		dispatch({ type: REFRESHING_SESSION_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getSessionDetail(sessionId).then(({ data: session }) => {
			dispatch({ type: REFRESHED_SESSION_DETAIL, data: session, apiUrl })
			return session
		})
	}
}

export function refreshDefaultTemplates() {
	return dispatch => {
		let apiUrl = API_URLS.defaultTemplates()
		dispatch({ type: REFRESHING_SESSION_DETAIL, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getDefaultTemplates().then(({ data: defaultTemplates }) => {
			dispatch({ type: REFRESHED_DEFAULT_TEMPLATES, data: defaultTemplates, apiUrl })
			return defaultTemplates
		})
	}
}

export function refreshSubsessions(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.sessionSubSessions(sessionId)
		dispatch({ type: REFRESHING_SESSION_SUB_SESSIONS, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getSubsessions(sessionId).then(({ data: subSessions }) => {
			dispatch({ type: REFRESHED_SESSION_SUB_SESSIONS, data: subSessions, apiUrl })
			return subSessions
		})
	}
}

export function refreshUnusedCategorizeOptions(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.unusedCategorizeOptions(sessionId)
		dispatch({ type: REFRESHING_UNUSED_CATEGORIZE_OPTIONS, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions
			.getUnusedCategorizeOptions(sessionId)
			.then(({ data: categorizeOptions }) => {
				dispatch({
					type: REFRESHED_UNUSED_CATEGORIZE_OPTIONS,
					data: categorizeOptions.sort((a, b) => a.name.localeCompare(b.name)),
					apiUrl,
				})
				return categorizeOptions
			})
	}
}

export function refreshFilters(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.filters(sessionId)
		dispatch({ type: REFRESHING_FILTERS, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getFilters(sessionId).then(({ data: filters }) => {
			dispatch({ type: REFRESHED_FILTERS, data: filters, apiUrl })
			return filters
		})
	}
}

export function refreshEmployees(sessionId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.employees(sessionId)
		const { data } = getState().appraisalAdminSession
		let args = {}
		if (data.departmentFilter) {
			args.department_id = data.departmentFilter
		}
		if (data.groupFilter) {
			args.group_id = data.groupFilter
		}
		if (data.superiorFilter) {
			args.superior_id = data.superiorFilter
		}
		if (data.titleFilter) {
			args.title_id = data.titleFilter
		}
		if (data.rankFilter) {
			args.rank_id = data.rankFilter
		}
		if (data.positionFilter) {
			args.position_id = data.positionFilter
		}
		let params = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (params) params = `?${params}`
		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions
			.getEmployees(sessionId, params)
			.then(({ data: employees }) => {
				dispatch({ type: REFRESHED_EMPLOYEES, data: _.sortBy(employees, ['name']), apiUrl })
				return employees
			})
	}
}

export function refreshAllEmployees(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.allEmployees(sessionId)
		dispatch({ type: REFRESHING_ALL_EMPLOYEES, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions.getAllEmployees(sessionId).then(({ data: employees }) => {
			dispatch({ type: REFRESHED_ALL_EMPLOYEES, data: employees, apiUrl })
			return employees
		})
	}
}

export function refreshExternalEmployees(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.externalEmployees(sessionId)
		dispatch({ type: REFRESHING_EXTERNAL_EMPLOYEES, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions
			.getExternalEmployees(sessionId)
			.then(({ data: externalEmployees }) => {
				dispatch({
					type: REFRESHED_EXTERNAL_EMPLOYEES,
					data: _.sortBy(externalEmployees, ['company.name', 'name']),
					apiUrl,
				})
				return externalEmployees
			})
	}
}

export function refreshRanks(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.ranks(sessionId)
		dispatch({ type: REFRESHING_RANKS, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions
			.getRanks(sessionId)
			.then(({ data: ranks }) => {
				dispatch({ type: REFRESHED_RANKS, data: ranks, apiUrl })
				return ranks
			})
	}
}

export function refreshGroupsWithAppraisees(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.groupsWithAppraisees(sessionId)
		dispatch({ type: REFRESHING_GROUPS_WITH_APPRAISEES, apiUrl })
		return api.appraisal_admin.appraisal_session.sessions
			.getGroupsWithAppraisees(sessionId)
			.then(({ data: groupsWithAppraisees }) => {
				dispatch({ type: REFRESHED_GROUPS_WITH_APPRAISEES, data: groupsWithAppraisees, apiUrl })
				return groupsWithAppraisees
			})
	}
}

export function refreshGroupLeaderRoles() {
	return dispatch => {
		const apiUrl = API_URLS.groupLeaderRoles()
		return api.setting.permission.group_leader_roles.get().then(({ data: roles }) => {
			dispatch({ type: REFRESHED_GROUP_LEADER_ROLES, data: roles, apiUrl })
			return roles
		})
	}
}

export function selectSession(session) {
	return (dispatch, getState) => {
		const {
			appraisalAdminSession: { api },
			session: { currentUser },
		} = getState()
		const currentUserPermissions = getObjectOrUndefined(currentUser, 'permissions')
		if (
			session.status === APPRAISAL_SESSION_STATUS.DRAFT &&
			Boolean(currentUserPermissions) &&
			currentUserPermissions.includes(PERMISSIONS.CREATE_EDIT_SESSION)
		) {
			dispatch(push(`/admin_appraisal/session/${session.id}/edit/first_page`))
		} else {
			dispatch(push(`/admin_appraisal/session/${session.id}`))
		}
		dispatch({ type: SELECT_SESSION, sessionId: session.id })
		if (getObjectOrUndefined(api, API_URLS.sessionDetail(session.id)) === undefined) {
			dispatch(refreshSessionDetail(session.id))
		}
		if (getObjectOrUndefined(api, API_URLS.sessionSubSessions(session.id)) === undefined) {
			dispatch(refreshSubsessions(session.id))
		}
	}
}

export function createSession(session) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.create(session).then(({ data: session }) => {
			dispatch({ type: CREATED_SESSION, session })
			dispatch(selectSession(session))
			toast(`Created new session`)
			return session
		})
	}
}

export function resetSubSessionForm(sessionId, subSessionIds) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.resetSubSessionForm(sessionId, subSessionIds).then(() => {
			toast(`Successful`)
			// TODO: ini bisa dibikin offline update. datanya udah di return
			dispatch(refreshSubsessions(sessionId))
		})
	}
}

export function startSession(sessionId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.start(sessionId).then(() => {
			dispatch({ type: STARTED_SESSION, sessionId })
			toast(`Session started`)
			return dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function stopSession(sessionId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.stop(sessionId).then(() => {
			dispatch({ type: STOPPED_SESSION, sessionId })
			toast('Session stopped')
			return dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function editSession(sessionId, session) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.edit(sessionId, session).then(({ data: session }) => {
			dispatch({ type: EDITED_SESSION, session })
			dispatch(refreshSessionDetail(sessionId))
			toast(`Edited "${session.name}" session`)
			return session
		})
	}
}

export function deleteSession(sessionId) {
	return (dispatch, getState) => {
		return api.appraisal_admin.appraisal_session.sessions.delete(sessionId).then(() => {
			Promise.resolve(dispatch({ type: DELETED_SESSION, sessionId })).then(() => {
				const {
					appraisalAdminSession: { api },
				} = getState()
				const sessions = getObjectOrUndefined(api, API_URLS.sessionsSimple())
				if (sessions.length > 0) {
					dispatch(selectSession(sessions[0]))
				} else {
					dispatch(push(`/admin_appraisal/session/`))
				}
			})
			toast(`Successfully deleted session`)
		})
	}
}

export function removeAppraisees(sessionId, groupId, appraiseeIds) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.removeAppraisees(sessionId, appraiseeIds).then(() => {
			dispatch({ type: REMOVED_APPRAISEES })
			dispatch(refreshSubsessions(sessionId))
			toast(`Successfully removed appraisee`)
			// return dispatch(refreshSessionGroupDetail(sessionId, groupId))
		})
	}
}

export function addGroupByCategorize(sessionId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions
			.addGroupByCategorize(sessionId, data)
			.then(({ data: appraisalGroup }) => {
				dispatch(refreshSessionDetail(sessionId))
				dispatch(refreshUnusedCategorizeOptions(sessionId))
				dispatch({ type: ADDED_GROUP_BY_CATEGORIZE, appraisalGroup })
				toast(`Successfully added group`)
				return appraisalGroup
			})
	}
}

export function editAppraisees(sessionId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.editAppraisees(sessionId, data).then(() => {
			dispatch({ type: EDITED_APPRAISEES })
			dispatch(refreshSubsessions(sessionId))
			dispatch(refreshSessionDetail(sessionId))
			toast(`Successfully edited appraisees`)
		})
	}
}

export function createStage(sessionId, stageDates) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.stages
			.create(sessionId, stageDates)
			.then(({ data: stage }) => {
				dispatch({ type: CREATED_STAGE, sessionId, stage })
				dispatch(refreshSessionDetail(sessionId))
				dispatch(reset('create-stage'))
				toast(`Successfully created stage ${stage.stageNumber} `)
			})
	}
}

export function editStage(sessionId, stageId, stageDates) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.stages
			.edit(sessionId, stageId, stageDates)
			.then(({ data: stage }) => {
				dispatch({ type: EDITED_STAGE, sessionId, stage })
				toast(`Successfully edited stage ${stage.stageNumber} `)
			})
	}
}

export function deleteStage(sessionId, stageId) {
	return (dispatch, getState) => {
		return api.appraisal_admin.appraisal_session.sessions.stages.delete(sessionId, stageId).then(() => {
			dispatch({ type: DELETED_STAGE, sessionId, stageId })
			toast('Succesfully deleted stage')
		})
	}
}

export function createGroup(sessionId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.groups.create(sessionId, data).then(({ data: group }) => {
			dispatch({ type: CREATED_GROUP, sessionId, group })
			toast(`Successfully created group`)
		})
	}
}

export function editGroup(sessionId, groupId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.groups
			.edit(sessionId, groupId, data)
			.then(({ data: group }) => {
				dispatch({ type: EDITED_GROUP, sessionId, group })
				toast(`Successfully edited group`)
			})
	}
}

export function deleteGroup(sessionId, groupId) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.groups.delete(sessionId, groupId).then(() => {
			dispatch({ type: DELETED_GROUP, sessionId, groupId })
			dispatch(refreshSubsessions(sessionId))
			dispatch(refreshUnusedCategorizeOptions(sessionId))
			toast(`Successfully deleted group`)
		})
	}
}

export function createAppraiserType(sessionId, groupId, groupStageId, data) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.groups.groupStages
			.createAppraiserType(sessionId, groupId, groupStageId, data)
			.then(({ data: appraiserType }) => {
				dispatch({ type: CREATED_APPRAISER_TYPE, sessionId, groupId, groupStageId, appraiserType })
				dispatch(refreshSubsessions(sessionId))
				toast(`Added "${mapAppraiserTypeToDisplay(appraiserType.type)}" type`)
			})
	}
}

export function deleteAppraiserType(sessionId, groupId, groupStageId, appraiserType) {
	return dispatch => {
		return api.appraisal_admin.appraisal_session.sessions.groups.groupStages
			.deleteAppraiserType(sessionId, groupId, groupStageId, appraiserType.id)
			.then(() => {
				dispatch({ type: DELETED_APPRAISER_TYPE, sessionId, groupId, groupStageId, appraiserTypeId: appraiserType.id })
				dispatch(refreshSubsessions(sessionId))
				toast(`Removed "${mapAppraiserTypeToDisplay(appraiserType.type)}" type`)
			})
	}
}

export function changeActiveEmployeeGroup(employeeGroupId) {
	return dispatch => {
		dispatch({ type: CHANGE_ACTIVE_EMPLOYEE_GROUP, employeeGroupId })
	}
}

export function loadModule() {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		dispatch(refreshSessionsSimple()).then(sessions => {
			if (sessions.length > 0 && pathname === `/admin_appraisal/session/`) {
				dispatch(selectSession(sessions[0]))
			}
		})
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		activeEmployeeGroupId: undefined,
		selectedSessionId: undefined,
		param: '',
	},
}

export default function registerReducer(state = initialState, action) {
	const sessionsSimple = state.api[API_URLS.sessionsSimple()]
	let sessionDetail
	if (action.sessionId) {
		sessionDetail = state.api[API_URLS.sessionDetail(action.sessionId)]
	}
	switch (action.type) {
		case REFRESHING_SESSIONS_SIMPLE:
		case REFRESHING_DEFAULT_TEMPLATES:
		case REFRESHING_SESSION_DETAIL:
		case REFRESHING_SESSION_STAGES:
		case REFRESHING_SESSION_GROUPS:
		case REFRESHING_SESSION_SUB_SESSIONS:
		case REFRESHING_UNUSED_CATEGORIZE_OPTIONS:
		case REFRESHING_FILTERS:
		case REFRESHING_EMPLOYEES:
		case REFRESHING_ALL_EMPLOYEES:
		case REFRESHING_EXTERNAL_EMPLOYEES:
		case REFRESHING_GROUPS_WITH_APPRAISEES:
		case REFRESHING_RANKS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_SESSIONS_SIMPLE:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case REFRESHED_DEFAULT_TEMPLATES:
		case REFRESHED_SESSION_DETAIL:
		case REFRESHED_SESSION_STAGES:
		case REFRESHED_SESSION_GROUPS:
		case REFRESHED_SESSION_SUB_SESSIONS:
		case REFRESHED_UNUSED_CATEGORIZE_OPTIONS:
		case REFRESHED_FILTERS:
		case REFRESHED_EMPLOYEES:
		case REFRESHED_ALL_EMPLOYEES:
		case REFRESHED_EXTERNAL_EMPLOYEES:
		case REFRESHED_GROUPS_WITH_APPRAISEES:
		case REFRESHED_RANKS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case CREATED_SESSION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionsSimple()]: [action.session, ...sessionsSimple],
				},
			}
		case EDITED_SESSION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionsSimple()]: sessionsSimple.map(session => {
						if (session.id === action.session.id) {
							return action.session
						}
						return session
					}),
				},
			}
		case DELETED_SESSION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionsSimple()]: sessionsSimple.filter(session => session.id !== action.sessionId),
				},
			}
		case SELECT_SESSION:
			return {
				...state,
				data: {
					...state.data,
					selectedSessionId: action.sessionId,
				},
			}
		case CREATED_STAGE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						stages: [...sessionDetail.stages, action.stage],
					},
				},
			}
		case EDITED_STAGE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						stages: sessionDetail.stages.map(stage => {
							if (stage.id === action.stage.id) {
								return action.stage
							}
							return stage
						}),
					},
				},
			}
		case DELETED_STAGE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: sessionDetail.groups.map(group => ({
							...group,
							groupStages: group.groupStages.filter(groupStage => groupStage.stageId !== action.stageId),
						})),
						stages: sessionDetail.stages
							.filter(stage => stage.id !== action.stageId)
							.map((stage, index) => ({ ...stage, stageNumber: index + 1 })),
					},
				},
			}
		case CREATED_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: [...sessionDetail.groups, action.group],
					},
				},
			}
		case EDITED_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: sessionDetail.groups.map(group => {
							if (group.id === action.group.id) {
								return action.group
							}
							return group
						}),
					},
				},
			}
		case DELETED_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: sessionDetail.groups.filter(group => group.id !== action.groupId),
					},
				},
			}
		case CREATED_APPRAISER_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: sessionDetail.groups.map(group => {
							if (group.id === action.groupId) {
								return {
									...group,
									groupStages: group.groupStages.map(groupStage => {
										if (groupStage.id === action.groupStageId) {
											return {
												...groupStage,
												groupAppraiserTypes: [...groupStage.groupAppraiserTypes, action.appraiserType],
											}
										}
										return groupStage
									}),
								}
							}
							return group
						}),
					},
				},
			}
		case DELETED_APPRAISER_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionDetail(action.sessionId)]: {
						...sessionDetail,
						groups: sessionDetail.groups.map(group => {
							if (group.id === action.groupId) {
								return {
									...group,
									groupStages: group.groupStages.map(groupStage => {
										if (groupStage.id === action.groupStageId) {
											return {
												...groupStage,
												groupAppraiserTypes: groupStage.groupAppraiserTypes.filter(
													appraiserType => appraiserType.id !== action.appraiserTypeId
												),
											}
										}
										return groupStage
									}),
								}
							}
							return group
						}),
					},
				},
			}
		case STARTED_SESSION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionsSimple()]: state.api[API_URLS.sessionsSimple()].map(session => {
						if (session.id === action.sessionId) {
							return new AppraisalAdminSession.Session({
								...session,
								status: APPRAISAL_SESSION_STATUS.STARTED,
							})
						}
						return session
					}),
				},
			}
		case STOPPED_SESSION:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.sessionsSimple()]: state.api[API_URLS.sessionsSimple()].map(session => {
						if (session.id === action.sessionId) {
							return new AppraisalAdminSession.Session({
								...session,
								status: APPRAISAL_SESSION_STATUS.STOPPED,
							})
						}
						return session
					}),
				},
			}
		case CHANGE_ACTIVE_EMPLOYEE_GROUP:
			return {
				...state,
				data: {
					...state.data,
					activeEmployeeGroupId: action.employeeGroupId,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
