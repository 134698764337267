import { s, number, object, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class TimeoffComment {
	constructor({ id, created_by: createdBy, comment, added }) {
		this.id = id
		this.createdBy = new Employee(createdBy)
		this.comment = comment
		this.added = new Date(added * 1000)
	}
}

export const TimeoffCommentType = s({
	id: number.isRequired,
	createdBy: EmployeeType,
	comment: string.isRequired,
	added: object.isRequired,
})

export default TimeoffComment
