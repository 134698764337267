import ActiveWallet from './ActiveWallet'
import Approver from './Approver'
import CalendarData from './CalendarData'
import EditApprover from './EditApprover'
import Employee from './Employee'
import EmployeeProfile from './EmployeeProfile'
import EmployeeDetail from './EmployeeDetail'
import EmployeeWithApprovers from './EmployeeWithApprovers'
import ExperienceRequest from './ExperienceRequest'
import ExperienceRequestComment from './ExperienceRequestComment'
import ExperienceRequestWithApproverScore from './ExperienceRequestWithApproverScore'
import ExperienceRequestLog from './ExperienceRequestLog'
import ExperienceSetting from './ExperienceSetting'
import ExperienceSubscription from './ExperienceSubscription'
import ExperienceType from './ExperienceType'
import ExperienceTypeWithCount from './ExperienceTypeWithCount'
import ExperienceWallet from './ExperienceWallet'
import GroupLeaderRole from './GroupLeaderRole'
import RankWithOrder from './RankWithOrder'
import ExperienceWalletSummary from './ExperienceWalletSummary'
import WalletCalendar from './WalletCalendar'
import WorkingCycleRule from './WorkingCycleRule'
import WorkingCycleType from './WorkingCycleType'

export const EXPERIENCE_SETTING_APPROVER_TYPE = {
	IN_ORDER: 1,
	SUBSTITUTE: 2,
}

export const EXPERIENCE_REQUEST_LOG_TYPE = {
	CREATED: 1,
	APPROVED_IN_ORDER: 2,
	APPROVED_SUBSTITUTE: 3,
	CREATED_APPROVED: 4,
	APPROVER_CREATED: 5,
	HR_CREATED: 6,
	APPROVED_FORWARDED_IN_ORDER: 7,
	EDITED_HR: 8,
	REJECTED: 9,
	HR_REMOVED_APPROVER: 10,
	HR_CHANGED_APPROVER: 11,
	APPROVED_HR_CHANGE_SETTING_IN_ORDER_SUBSTITUTE: 12,
	APPROVED_IN_ORDER_REMOVED_APPROVER: 13,
	APPROVED_SUBSTITUTE_REMOVED_APPROVER: 14,
}

export const APPROVER_TYPE = {
	GROUP_LEADER: 1,
	DEPARTMENT_HEAD: 2,
	CUSTOM: 3,
	RANK_SUPERVISOR: 4,
	DIRECT_SUPERVISOR: 5,
}

export default {
	ActiveWallet,
	CalendarData,
	Approver,
	EditApprover,
	Employee,
	EmployeeProfile,
	EmployeeDetail,
	EmployeeWithApprovers,
	ExperienceRequest,
	ExperienceRequestComment,
	ExperienceRequestWithApproverScore,
	ExperienceRequestLog,
	ExperienceSetting,
	ExperienceSubscription,
	ExperienceType,
	ExperienceTypeWithCount,
	ExperienceWallet,
	ExperienceWalletSummary,
	GroupLeaderRole,
	RankWithOrder,
	WalletCalendar,
	WorkingCycleRule,
	WorkingCycleType,
}
