import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let HrTimeoffComponent

const HrTimeoff = store => {
	if (HrTimeoffComponent === undefined) {
		HrTimeoffComponent = Loadable({
			loader: () => {
				return import('views/HrTimeoff/containers/HrTimeoffContainer')
			},
			loading: RouteLoader,
		})
	}
	return HrTimeoffComponent
}

export default HrTimeoff
