import { s, number, string } from 'modules/types'

class HolidayType {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const HolidayTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
})

class HolidayGroup {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const HolidayGroupType = s({
	id: number.isRequired,
	name: string.isRequired,
})

export class PublicHolidayExtInfo {
	constructor({ holiday_type: holidayType, holiday_group: holidayGroup }) {
		this.holidayType = new HolidayType(holidayType)
		this.holidayGroup = new HolidayGroup(holidayGroup)
	}
}

export const PublicHolidayExtInfoType = s({
	holidayType: HolidayTypeType,
	holidayGroup: HolidayGroupType,
})

export default PublicHolidayExtInfo
