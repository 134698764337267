import { s, arrayOf } from 'modules/types'
import IdeaFlow, { IdeaFlowType } from './IdeaFlow'
import IdeaSubscription, { IdeaSubscriptionType } from './IdeaSubscription'

export class IdeaFlowWithSubscription {
	constructor({ idea_flow: ideaFlow, idea_subscriptions: ideaSubscriptions }) {
		this.ideaFlow = new IdeaFlow(ideaFlow)
		this.ideaSubscriptions = ideaSubscriptions.map(item => new IdeaSubscription(item))
	}
}

export const IdeaFlowWithSubscriptionType = s({
	ideaFlow: IdeaFlowType,
	ideaSubscriptions: arrayOf(IdeaSubscriptionType),
})

export default IdeaFlowWithSubscription
