import { s, number } from 'modules/types'

export const APPRAISER_TYPE = {
	SELF: 0,
	PEER: 1,
	SUPERIOR: 2,
	SECOND_DEGREE_SUPERIOR: 3,
	SUBORDINATE: 4,
	OTHERS: 5,
	EXTERNAL: 6,
}

export class AppraiserType {
	constructor({ id, type }) {
		this.id = id
		this.type = type
	}

	getAppraiserTypeDisplay() {
		switch (this.type) {
			case APPRAISER_TYPE.SELF:
				return 'SELF'
			case APPRAISER_TYPE.PEER:
				return 'PEER'
			case APPRAISER_TYPE.SUPERIOR:
				return 'SUPERIOR'
			case APPRAISER_TYPE.SECOND_DEGREE_SUPERIOR:
				return 'SECOND_DEGREE_SUPERIOR'
			case APPRAISER_TYPE.SUBORDINATE:
				return 'SUBORDINATE'
			case APPRAISER_TYPE.OTHERS:
				return 'OTHERS'
			case APPRAISER_TYPE.EXTERNAL:
				return 'EXTERNAL'
			default:
				return `Unknown (${this.type})`
		}
	}
}

export const AppraiserTypeType = s({
	id: number.isRequired,
	type: number.isRequired, // @APPRAISER_TYPE
})

export default AppraiserType
