import { s, number, string } from 'modules/types'

export const TIMEOFF_TYPE_COLOR = {
	RED: 1, // #e74c3c
	GREEN: 2, // #2ecc71
	BLUE: 3, //#3498db
	YELLOW: 4, //#f1c40f
	ORANGE: 5, //#e67e22
	PINK: 6, //#f78fb3
	PURPLE: 7, //#be2edd
}

export class TimeoffType {
	constructor({ id, name, color }) {
		this.id = id
		this.name = name
		this.color = color
	}

	static getColorHexCode(color) {
		switch (color) {
			case TIMEOFF_TYPE_COLOR.RED:
				return '#e74c3c'
			case TIMEOFF_TYPE_COLOR.GREEN:
				return '#2ecc71'
			case TIMEOFF_TYPE_COLOR.BLUE:
				return '#3498db'
			case TIMEOFF_TYPE_COLOR.YELLOW:
				return '#f1c40f'
			case TIMEOFF_TYPE_COLOR.ORANGE:
				return '#e67e22'
			case TIMEOFF_TYPE_COLOR.PINK:
				return '#f78fb3'
			case TIMEOFF_TYPE_COLOR.PURPLE:
			default:
				return '#be2edd'
		}
	}

	static getColorCode(hexCode) {
		switch (hexCode) {
			case '#e74c3c':
				return TIMEOFF_TYPE_COLOR.RED
			case '#2ecc71':
				return TIMEOFF_TYPE_COLOR.GREEN
			case '#3498db':
				return TIMEOFF_TYPE_COLOR.BLUE
			case '#f1c40f':
				return TIMEOFF_TYPE_COLOR.YELLOW
			case '#e67e22':
				return TIMEOFF_TYPE_COLOR.ORANGE
			case '#f78fb3':
				return TIMEOFF_TYPE_COLOR.PINK
			case '#be2edd':
			default:
				return TIMEOFF_TYPE_COLOR.PURPLE
		}
	}
}

export const TimeoffTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
	color: number.isRequired,
})

export default TimeoffType
