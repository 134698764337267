import { s, number, string } from 'modules/types'

export class Reason {
	constructor({ id, content }) {
		this.id = id
		this.content = content
	}
}

export const ReasonType = s({
	id: number.isRequired,
	content: string.isRequired,
})

export default Reason
