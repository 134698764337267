import { s, arrayOf } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class TimeoffGroupLogExtInfoDetail {
	constructor({ employees }) {
		this.employees = employees.map(item => new Employee(item))
	}
}

export const TimeoffGroupLogExtInfoDetailType = s({
	employees: arrayOf(EmployeeType),
})

export default TimeoffGroupLogExtInfoDetail
