import { s, arrayOf, number, object, string } from 'modules/types'
import CreatedGroupSnapshot, { CreatedGroupSnapshotType } from './CreatedGroupSnapshot'
import WorkingCycleGroupSnapshot, { WorkingCycleGroupSnapshotType } from './WorkingCycleGroupSnapshot'
import RuleSnapshot, { RuleSnapshotType } from './RuleSnapshot'
import SerializedRule, { SerializedRuleType } from './SerializedRule'

export class WorkingCycleGroupLogExtInfo {
	constructor({
		group = null,
		employee_ids: employeeIds = null,
		from_working_cycle_group: fromWorkingCycleGroup = null,
		to_working_cycle_group: toWorkingCycleGroup = null,
		rule = null,
		old_day: oldDay = null,
		new_day: newDay = null,
		old_rule: oldRule = null,
		new_rule: newRule = null,
		old_name: oldName = null,
		new_name: newName = null,
		old_start_date: oldStartDate = null,
		new_start_date: newStartDate = null,
		serialized_new_rules: serializedNewRules = null,
		serialized_old_rules: serializedOldRules = null,
	}) {
		this.oldName = oldName
		this.newName = newName
		this.oldDay = oldDay
		this.newDay = newDay
		if (oldStartDate) {
			this.oldStartDate = new Date(oldStartDate)
		}
		if (newStartDate) {
			this.newStartDate = new Date(newStartDate)
		}
		if (group) {
			this.group = new CreatedGroupSnapshot(group)
		}
		if (fromWorkingCycleGroup) {
			this.fromWorkingCycleGroup = new WorkingCycleGroupSnapshot(fromWorkingCycleGroup)
		}
		if (toWorkingCycleGroup) {
			this.toWorkingCycleGroup = new WorkingCycleGroupSnapshot(toWorkingCycleGroup)
		}
		if (employeeIds) {
			this.employeeIds = employeeIds
		}
		if (rule) {
			this.rule = new RuleSnapshot(rule)
		}
		if (oldRule) {
			this.oldRule = new RuleSnapshot(oldRule)
		}
		if (newRule) {
			this.newRule = new RuleSnapshot(newRule)
		}
		if (serializedOldRules) {
			this.serializedOldRules = serializedOldRules.map(item => new SerializedRule(item))
		}
		if (serializedNewRules) {
			this.serializedNewRules = serializedNewRules.map(item => new SerializedRule(item))
		}
	}
}

export const WorkingCycleGroupLogExtInfoType = s({
	group: CreatedGroupSnapshotType,
	employeeIds: arrayOf(number),
	fromWorkingCycleGroup: WorkingCycleGroupSnapshotType,
	toWorkingCycleGroup: WorkingCycleGroupSnapshotType,
	rule: RuleSnapshotType,
	oldRule: RuleSnapshotType,
	newRule: RuleSnapshotType,
	oldName: string,
	newName: string,
	oldDay: number,
	newDay: number,
	oldStartDate: object,
	newStartDate: object,
	serializedNewRules: arrayOf(SerializedRuleType),
	serializedOldRules: arrayOf(SerializedRuleType),
})

export default WorkingCycleGroupLogExtInfo
