import BreakdownChartDistribution from './BreakdownChartDistribution'
import CompanyDistribution from './CompanyDistribution'
import DistributionDetail from './DistributionDetail'
import Employee from './Employee'
import EmployeeCountDetailDay from './EmployeeCountDetailDay'
import EmployeeMonthCount from './EmployeeMonthCount'
import FieldGroupChart from './FieldGroupChart'
import Filter from './Filter'
import InOutEmployee from './InOutEmployee'

export const FILTER_TYPE = {
	POSITION: 1,
	DEPARTMENT: 2,
	GROUP: 3,
	RANK: 4,
	SUPERIOR: 5,
	TITLE: 6,
	AGE: 7,
	GENDER: 8,
	GENERATION: 9,
}

export const IN_OUT_TYPE = {
	IN: 1,
	OUT: 2,
	REJOIN: 3,
}

export default {
	BreakdownChartDistribution,
	CompanyDistribution,
	DistributionDetail,
	Employee,
	EmployeeCountDetailDay,
	EmployeeMonthCount,
	FieldGroupChart,
	Filter,
	InOutEmployee,
}
