import { createModel } from '@rematch/core'
import contractAPI from './contractAPI'
import { ContractLogType } from 'features/contract_log/types/ContractLogType'

interface ContractModel {
	contractLogs: {
		byId: { [employeeId: number]: ContractLogType[] }
	}
}

export const contractLog = createModel<ContractModel>({
	name: 'contractLog',
	state: {
		contractLogs: {
			byId: {},
		},
	},
	reducers: {
		putEmployeeContractLogs: (state, payload) => {
			state.contractLogs.byId[payload.employeeId] = payload.contractLogs
			return state
		},
	},
	effects: {
		async fetchEmployeeContractLogs(employeeId: number) {
			const contractLogs = await contractAPI.fetchEmployeeContractsLogs(employeeId)
			this.putEmployeeContractLogs({ employeeId, contractLogs })
		},
	},
	// TODO: pake ini pas @rematch udah bener selectornya
	// selectors: {
	// 	getEmployeeContractLogs() {},
	// },
})

export const contractLogSelectors = {
	getEmployeeContractLogs: (state: any, employeeId: number) => {
		return state.contractLog.contractLogs.byId[employeeId] || null
	},
}
