import Column from './Column'
import ColumnContent from './ColumnContent'
import Employee from './Employee'
import EmployeeColumn from './EmployeeColumn'
import EmployeeDetailQuestionAnswerLog from './EmployeeDetailQuestionAnswerLog'
import EmployeeWithColumn from './EmployeeWithColumn'
import Field from './Field'
import FieldAnswer from './FieldAnswer'
import FieldGroup from './FieldGroup'
import FieldWithQuestionAnswer from './FieldWithQuestionAnswer'
import ProfileSummary from './ProfileSummary'
import Question from './Question'
import QuestionAnswer from './QuestionAnswer'
import QuestionAnswerChoice from './QuestionAnswerChoice'
import QuestionWithField from './QuestionWithField'

export const CHANGE_LOG_TYPE = {
	CREATED: 1,
	EDITED: 2,
}

export const QUESTION_TYPE = {
	CHECKBOX: 0,
	COMBOBOX: 1,
	DATE: 2,
	DATE_RANGE: 3,
	FILE: 4,
	MULTIPLE_CHOICE: 5,
	NUMBER: 6,
	TEXT: 7,
	READ_ONLY: 8,
}

export default {
	Column,
	ColumnContent,
	Employee,
	EmployeeColumn,
	EmployeeDetailQuestionAnswerLog,
	EmployeeWithColumn,
	Field,
	FieldAnswer,
	FieldGroup,
	FieldWithQuestionAnswer,
	ProfileSummary,
	Question,
	QuestionAnswer,
	QuestionAnswerChoice,
	QuestionWithField,
}
