import { s, arrayOf, number, object } from 'modules/types'

export class HolidayDateRangeSummary {
	constructor({ id, start_date: startDate, end_date: endDate, holiday_group_ids: holidayGroupIds }) {
		this.id = id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.holidayGroupIds = holidayGroupIds
	}
}

export const HolidayDateRangeSummaryType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
	holidayGroupIds: arrayOf(number),
})

export default HolidayDateRangeSummary
