import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let HumanResourceSettingComponent

const HumanResourceSetting = store => {
	if (HumanResourceSettingComponent === undefined) {
		HumanResourceSettingComponent = Loadable({
			loader: () => {
				return import('views/HumanResourceSetting/containers/HumanResourceSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return HumanResourceSettingComponent
}

export default HumanResourceSetting
