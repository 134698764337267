import api from './api'
import helper from './helper'

export { MONTHS } from './constant'

export const httpGet = api.httpGet
export const httpPost = api.httpPost
export const httpPostMultipart = api.httpPostMultipart
export const getColorCode = helper.getColorCode
export const getColorHexCode = helper.getColorHexCode
export const getObjectOrUndefined = helper.getObjectOrUndefined
export const handleError = api.handleError
export const mapAppraiserTypeToDisplay = helper.mapAppraiserTypeToDisplay
export const mapQuestionTypeToDisplay = helper.mapQuestionTypeToDisplay
export const mapQuestionTypeToIcon = helper.mapQuestionTypeToIcon
export const mapQuestionTypeToYOffset = helper.mapQuestionTypeToYOffset
export const renderText = helper.renderText
export const toast = helper.toast
export const toastErrors = helper.toastErrors
export const uploadFile = api.uploadFile

export const format = helper.format
export const mockAPI = helper.mockAPI
