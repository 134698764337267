import { s, arrayOf, number } from 'modules/types'
import EmployeeWithDetail, { EmployeeWithDetailType } from './EmployeeWithDetail'
import TableHeaderContent, { TableHeaderContentType } from './TableHeaderContent'

export class Appraisee {
	constructor({ id, employee, score_card_status: scoreCardStatus, table_header_contents: tableHeaderContents }) {
		this.id = id
		this.employee = new EmployeeWithDetail(employee)
		this.scoreCardStatus = scoreCardStatus
		this.tableHeaderContents = tableHeaderContents.map(item => new TableHeaderContent(item))
	}
}

export const AppraiseeType = s({
	id: number.isRequired,
	employee: EmployeeWithDetailType,
	scoreCardStatus: number.isRequired,
	tableHeaderContents: arrayOf(TableHeaderContentType),
})

export default Appraisee
