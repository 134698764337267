import { s, arrayOf, number, string } from 'modules/types'
import EmployeeWithPicture, { EmployeeWithPictureType } from './EmployeeWithPicture'

class Choice {
	constructor({ answer_choice_id: answerChoiceId, content }) {
		this.answerChoiceId = answerChoiceId
		this.content = content
	}
}

const ChoiceType = s({
	answerChoiceId: number.isRequired,
	content: string.isRequired,
})

export class ChoiceWithEmployees {
	constructor({ choice, employees }) {
		this.choice = new Choice(choice)
		this.employees = employees.map(item => new EmployeeWithPicture(item))
	}
}

export const ChoiceWithEmployeesType = s({
	choice: ChoiceType,
	employees: arrayOf(EmployeeWithPictureType),
})

export default ChoiceWithEmployees
