import store from 'store/createStore'
import { replace } from 'react-router-redux'
import { toastErrors } from './helper'
import { ForbiddenError, InternalServerError, NotFoundError } from './apiErrors'

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
}

function getAuthToken() {
	return localStorage.getItem('skleemAuthToken')
}

function handleError(dispatch, type) {
	return err => {
		try {
			if (err.response && err.response.jsonData) {
				let errors = err.response.jsonData.errors
				toastErrors(errors)
				if (type) {
					dispatch({ type, errors })
				} else {
					dispatch({ type: 'UNHANDLED ERRORS', errors })
				}
			} else {
				console.error(err)
				if (type) {
					dispatch({ type, errors: err })
				} else {
					dispatch({ type: 'UNHANDLED ERRORS', errors: err })
				}
			}
		} catch (e) {
			throw err
		}
		throw err
	}
}

function buildHeaders(overrideHeader = {}) {
	const authToken = 'JWT ' + getAuthToken()
	return { ...defaultHeaders, ...overrideHeader, Authorization: authToken }
}

async function checkStatus(response) {
	// TODO: if the status is token expired, refresh the token, and restart the request
	if (response.status >= 200 && response.status < 300) {
		return response
	} else if (response.status === 401) {
		store.dispatch(replace('/login'))
	} else if (response.status === 400) {
		let error = new Error(response.statusText)
		error.response = response

		try {
			const data = await response.json()
			error.response.json = () => new Promise(resolve => resolve(data))
			error.response.jsonData = data
		} catch (e) {
			throw error
		}
		throw error
	} else if (response.status === 403) {
		throw new ForbiddenError(await response.json())
	}
	if (response.status === 404) {
		throw new NotFoundError(await response.json)
	}
	if (response.status < 200 || response.status >= 300) {
		throw new InternalServerError(await response.json)
	} else {
		let error = new Error(response.statusText)
		error.response = response
		throw error
	}
}

function parseJSON(response) {
	try {
		return response.json().then(data => {
			if (process.env.NODE_ENV === 'development') {
				console.log('API Response:', response.url, data)
			}
			return data
		})
	} catch (err) {
		return response
	}
}

function fetchAPISkleem(url, data) {
	if (process.env.REACT_APP_SKLEEM_ENV === 'PROD') {
		return fetch('https://app.skleem.co/x-api' + url, data)
	} else if (process.env.REACT_APP_SKLEEM_ENV === 'STAGING') {
		return fetch('https://staging.skleem.co/x-api' + url, data)
	} else {
		if (process.env.REACT_APP_DEV === 'kevin') {
			return fetch('http://localhost:8000' + url, data)
		} else {
			return fetch('https://app.skleem.co/x-api' + url, data)
		}
	}
}

export function httpPostMultipart(url, data, type) {
	// parameter data is a formData object
	let headers = buildHeaders()
	delete headers['Content-Type']

	return fetchAPISkleem(url, {
		method: 'post',
		headers: headers,
		body: data,
	})
		.then(checkStatus)
		.then(parseJSON)
		.catch(err => {
			if (process.env.NODE_ENV === 'development') {
				console.log('Error: ', err.response, err)
			}
			throw err
		})
		.catch(handleError(store.dispatch, 'ERROR_HTTP_POST_MULTIPART'))
}

/*
Perform HTTP POST request, parse response data, and handles error

Args:
- url: String
  API endpoint URL
- data: Object
  Request body
- options: Object
  Parameters to modify this function behaviour (See "List of options" below)

List of options:
- withoutToast: Boolean (Default: false)
  If true, prevent error message being shown in the default error toast
*/
export function httpPost(url, data = {}, options = {}) {
	const body = JSON.stringify(data)

	return fetchAPISkleem(url, {
		method: 'post',
		headers: buildHeaders(),
		body: body,
		credentials: 'same-origin',
	})
		.then(checkStatus)
		.then(parseJSON)
		.catch(err => {
			if (process.env.NODE_ENV === 'development') {
				console.log('Error: ', err.response, err)
			}
			throw err
		})
		.catch(err => {
			if (options.withoutToast) {
				throw err
			} else {
				handleError(store.dispatch, 'ERROR_HTTP_POST')(err)
			}
		})
}

/*
Perform HTTP GET request, parse response data, and handles error

Args:
- url: String
  API endpoint URL
- options: Object
  Parameters to modify this function behaviour (See "List of options" below)

List of options:
- withoutToast: Boolean (Default: false)
  If true, prevent error message being shown in the default error toast
*/
export function httpGet(url, options = {}) {
	return fetchAPISkleem(url, {
		method: 'get',
		headers: buildHeaders(),
	})
		.then(checkStatus)
		.then(parseJSON)
		.catch(err => {
			if (process.env.NODE_ENV === 'development') {
				console.log('Error: ', err.response, err)
			}
			throw err
		})
		.catch(err => {
			if (options.withoutToast) {
				throw err
			} else {
				handleError(store.dispatch, 'ERROR_HTTP_GET')(err)
			}
		})
}

export function httpGet2(url) {
	return fetchAPISkleem(url, {
		method: 'get',
		headers: buildHeaders(),
	})
		.then(checkStatus)
		.then(parseJSON)
		.then(({ success, data }) => data)
		.catch(err => {
			if (process.env.NODE_ENV === 'development') {
				console.log('Error: ', err.response, err)
			}
			throw err
		})
		.catch(handleError(store.dispatch, 'ERROR_HTTP_GET'))
}

export function uploadFile(file, signal) {
	let contentType = file.type
	let fileName = file.name
	return httpPost('/auth/generate_presigned_url/', {
		content_type: contentType,
		file_name: fileName,
	})
		.then(({ success, url, file_name: fileName }) => {
			return fetch(url, {
				method: 'put',
				signal: signal,
				headers: {
					'Content-Type': contentType,
				},
				body: file,
			}).then(() => fileName)
		})
		.catch(handleError(store.dispatch, 'ERROR_UPLOAD_FILE'))
}

export default {
	httpPostMultipart,
	httpPost,
	httpGet,
	httpGet2,
	uploadFile,
}
