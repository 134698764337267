import Dashboard from 'views/Dashboard'
import IdeaWall from 'views/IdeaWall'
import SurveyAdmin from 'views/SurveyAdmin'
import Brainstorming from 'views/Brainstorming'
import HrExperience from 'views/HrExperience'
import JobInformation from 'views/JobInformation'
import Idea from 'views/Idea'
import SurveyOngoing from 'views/SurveyOngoing'
import SurveyCompleted from 'views/SurveyCompleted'
import AppraisalOngoing from 'views/AppraisalOngoing'
import AppraisalCompleted from 'views/AppraisalCompleted'
import Engagement from 'views/Engagement'
import Experience from 'views/Experience'
import EmployeeDatabase from 'views/EmployeeDatabase'
import Announcement from 'views/Announcement'
import AppraisalAdminTemplate from 'views/AppraisalAdminTemplate'
import AppraisalAdminSession from 'views/AppraisalAdminSession'
import AppraisalAdminScoring from 'views/AppraisalAdminScoring'
import AppraisalAdminAnalytics from 'views/AppraisalAdminAnalytics'
import HrTimeoff from 'views/HrTimeoff'
import HumanResourceSetting from 'views/HumanResourceSetting'
import PermissionSetting from 'views/PermissionSetting'
import PublicHolidaySetting from 'views/PublicHolidaySetting'
import TimeoffSetting from 'views/TimeoffSetting'
import WorkstationSetting from 'views/WorkstationSetting'
import WorkingCycleSetting from 'views/WorkingCycleSetting'
import DashboardSetting from 'views/DashboardSetting'
import RequestTimeoff from 'views/RequestTimeoff'
import Demographics from 'views/Demographics'
import { PERMISSIONS } from 'modules/constants'

import BorderColorIcon from '@material-ui/icons/BorderColor'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DateRangeIcon from '@material-ui/icons/DateRange'
import HomeIcon from '@material-ui/icons/Home'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import WorkIcon from '@material-ui/icons/Work'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import PeopleIcon from '@material-ui/icons/People'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline'
import AvTimerIcon from '@material-ui/icons/AvTimer'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import SettingsIcon from '@material-ui/icons/Settings'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import TimerOffIcon from '@material-ui/icons/TimerOff'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import DomainIcon from '@material-ui/icons/Domain'
import DescriptionIcon from '@material-ui/icons/Description'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import CompanyPolicy from '../../views/CompanyPolicy'

const Home = [
	{
		headerTab: true,
		collapse: true,
		path: '/dashboard/',
		name: 'DASHBOARD',
		state: 'openDashboard',
		icon: HomeIcon,
		views: [
			{
				path: '/dashboard/home/',
				name: 'HOME',
				icon: DashboardIcon,
				component: Dashboard,
			},
			{
				path: '/dashboard/idea_wall/',
				name: 'IDEA_WALL',
				icon: WhatshotIcon,
				component: IdeaWall,
			},
			{
				path: '/dashboard/brainstorming/',
				name: 'BRAINSTORMING',
				icon: QuestionAnswerIcon,
				component: Brainstorming,
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/workstation/',
		name: 'WORKSTATION',
		state: 'openWorkstation',
		icon: WorkIcon,
		views: [
			{
				path: '/workstation/job_information/',
				name: 'JOB_INFORMATION',
				icon: DesktopMacIcon,
				component: JobInformation,
			},
			{
				path: '/workstation/idea/',
				name: 'IDEA',
				icon: WhatshotIcon,
				component: Idea,
			},
			{
				path: '/workstation/experience/',
				name: 'EXPERIENCE',
				icon: WhatshotIcon,
				component: Experience,
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/company/',
		name: 'COMPANY',
		state: 'openCompany',
		icon: DomainIcon,
		views: [
			{
				path: '/company/announcements/',
				name: 'ANNOUNCEMENT',
				icon: AnnouncementIcon,
				component: Announcement,
			},
			{
				path: '/company/policies/',
				name: 'COMPANY_POLICY',
				icon: DescriptionIcon,
				component: CompanyPolicy,
			},
			{
				path: '/company/demographics/',
				name: 'DEMOGRAPHICS',
				icon: DescriptionIcon,
				component: Demographics,
				permissionsRequired: [PERMISSIONS.ACCESS_DEMOGRAPHICS],
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/survey/',
		name: 'SURVEY',
		state: 'openSurvey',
		icon: PlaylistAddCheckIcon,
		views: [
			{
				path: '/survey/ongoing/',
				name: 'ONGOING',
				icon: ArrowForwardIcon,
				component: SurveyOngoing,
			},
			{
				path: '/survey/completed/',
				name: 'COMPLETED',
				icon: PlaylistAddCheck,
				component: SurveyCompleted,
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/appraisal/',
		name: 'APPRAISAL',
		state: 'openAppraisal',
		icon: ShowChartIcon,
		views: [
			{
				path: '/appraisal/ongoing/',
				name: 'ONGOING',
				icon: ArrowForwardIcon,
				component: AppraisalOngoing,
			},
			{
				path: '/appraisal/completed/',
				name: 'COMPLETED',
				icon: AssignmentTurnedInIcon,
				component: AppraisalCompleted,
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/request/',
		name: 'REQUEST',
		state: 'openRequest',
		icon: BorderColorIcon,
		views: [
			{
				path: '/request/timeoff/',
				name: 'TIMEOFF',
				icon: AvTimerIcon,
				component: RequestTimeoff,
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/human_resources/',
		name: 'HUMAN_RESOURCES',
		state: 'openHumanResources',
		icon: PeopleIcon,
		permissionsRequired: [PERMISSIONS.HR_ACCESS],
		views: [
			{
				path: '/human_resources/engagement/',
				name: 'ENGAGEMENT',
				icon: SentimentVerySatisfiedIcon,
				component: Engagement,
				permissionsRequired: [PERMISSIONS.HR_ACCESS],
			},
			{
				path: '/human_resources/employee_database/',
				name: 'EMPLOYEE_DATABASE',
				icon: ChromeReaderModeIcon,
				component: EmployeeDatabase,
				permissionsRequired: [PERMISSIONS.HR_ACCESS],
			},
			{
				path: '/human_resources/timeoff/',
				name: 'TIMEOFF',
				icon: AvTimerIcon,
				component: HrTimeoff,
				permissionsRequired: [PERMISSIONS.HR_ACCESS],
			},
			{
				path: '/human_resources/experience/',
				name: 'EXPERIENCE',
				icon: AccessTimeIcon,
				component: HrExperience,
				permissionsRequired: [PERMISSIONS.HR_ACCESS],
			},
		],
	},
	{
		path: '/admin_survey/',
		name: 'SURVEY_ADMIN',
		icon: PlaylistAddCheckIcon,
		component: SurveyAdmin,
		permissionsRequired: [PERMISSIONS.SURVEY_ADMIN_ACCESS],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/admin_appraisal/',
		name: 'APPRAISAL_ADMIN',
		state: 'openAppraisalAdmin',
		permissionsRequired: [PERMISSIONS.PA_ADMIN_ACCESS],
		icon: ShowChartIcon,
		views: [
			{
				path: '/admin_appraisal/template/',
				name: 'TEMPLATE',
				icon: ViewHeadlineIcon,
				component: AppraisalAdminTemplate,
				permissionsRequired: [PERMISSIONS.PA_ADMIN_ACCESS],
			},
			{
				path: '/admin_appraisal/session/',
				name: 'SESSION',
				icon: AvTimerIcon,
				component: AppraisalAdminSession,
				permissionsRequired: [PERMISSIONS.PA_ADMIN_ACCESS],
			},
			{
				path: '/admin_appraisal/scoring/',
				name: 'SCORING',
				icon: PlaylistAddCheckIcon,
				component: AppraisalAdminScoring,
				permissionsRequired: [PERMISSIONS.PA_ADMIN_ACCESS],
			},
			{
				path: '/admin_appraisal/analytics/',
				name: 'ANALYTICS',
				icon: ShowChartIcon,
				component: AppraisalAdminAnalytics,
				permissionsRequired: [PERMISSIONS.PA_ADMIN_ACCESS],
			},
		],
	},
	{
		headerTab: true,
		collapse: true,
		path: '/setting/',
		name: 'SETTINGS',
		state: 'openSetting',
		icon: SettingsIcon,
		permissionsRequired: [PERMISSIONS.SETTING_ACCESS],
		views: [
			{
				path: '/setting/permissions/',
				name: 'PERMISSIONS',
				icon: VerifiedUserIcon,
				component: PermissionSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_PERMISSION_ACCESS],
			},
			{
				path: '/setting/dashboard/',
				name: 'DASHBOARD',
				icon: PeopleIcon,
				component: DashboardSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_DASHBOARD_ACCESS],
			},
			{
				path: '/setting/workstation/',
				name: 'WORK_STATION',
				icon: WorkIcon,
				component: WorkstationSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_WORKSTATION_ACCESS],
			},
			{
				path: '/setting/human_resources/',
				name: 'HUMAN_RESOURCES',
				icon: PeopleIcon,
				component: HumanResourceSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_HR_ACCESS],
			},
			{
				path: '/setting/working-cycle/',
				name: 'WORKING_CYCLE',
				icon: TimelapseIcon,
				component: WorkingCycleSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_WORKING_CYCLE],
			},
			{
				path: '/setting/public-holiday/',
				name: 'PUBLIC_HOLIDAY',
				icon: DateRangeIcon,
				component: PublicHolidaySetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_PUBLIC_HOLIDAY],
			},
			{
				path: '/setting/timeoff/',
				name: 'TIMEOFF',
				icon: TimerOffIcon,
				component: TimeoffSetting,
				permissionsRequired: [PERMISSIONS.SETTING_ACCESS, PERMISSIONS.SETTING_TIMEOFF],
			},
		],
	},
	{ redirect: true, path: '/', pathTo: '/dashboard/home/', name: 'Dashboard' },
]

export default Home
