import { s, bool, string, number } from 'modules/types'

export class Field {
	constructor({
		id,
		name,
		order,
		allow_multiple_answers: allowMultipleAnswers,
		is_default: isDefault,
		is_more_details: isMoreDetails,
	}) {
		this.id = id
		this.name = name
		this.order = order
		this.allowMultipleAnswers = allowMultipleAnswers
		this.isMoreDetails = isMoreDetails
		this.isDefault = isDefault
	}
}

export const FieldType = s({
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
	allowMultipleAnswers: bool.isRequired,
	isMoreDetails: bool.isRequired,
	isDefault: bool.isRequired,
})

export default Field
