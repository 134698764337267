import { s, string, number } from 'modules/types'

export class Choice {
	constructor({ id, content, order }) {
		this.id = id
		this.content = content
		this.order = order
	}
}

export const ChoiceType = s({
	id: number.isRequired,
	content: string.isRequired,
	order: number.isRequired,
})

export default Choice
