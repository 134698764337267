import AnswerChoice from './AnswerChoice'
import AnswerStatistic from './AnswerStatistic'
import Employee from './Employee'
import ActiveEmployee from './ActiveEmployee'
import IndividualQuestionSummary from './IndividualQuestionSummary'
import IndividualSummary from './IndividualSummary'
import Participant from './Participant'
import Question from './Question'
import Session from './Session'
import SessionDetail from './SessionDetail'
import SessionSimple from './SessionSimple'
import Summary from './Summary'
import SummaryDetail from './SummaryDetail'
import Department from './Department'
import Group from './Group'
import Superior from './Superior'
import Title from './Title'
import DepartmentWithEmployees from './DepartmentWithEmployees'
import SuperiorWithEmployees from './SuperiorWithEmployees'

export default {
	AnswerChoice,
	AnswerStatistic,
	Employee,
	ActiveEmployee,
	IndividualQuestionSummary,
	IndividualSummary,
	Participant,
	Question,
	Session,
	SessionDetail,
	SessionSimple,
	Summary,
	SummaryDetail,
	Department,
	Group,
	Superior,
	Title,
	DepartmentWithEmployees,
	SuperiorWithEmployees,
}
