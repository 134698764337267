import { s, arrayOf, number, string } from 'modules/types'
import DefaultQuestionCategory, { DefaultQuestionCategoryType } from './DefaultQuestionCategory'

export class TemplateWithQuestionCategory {
	constructor({ id, default_template_id: defaultTemplateId, categories, name }) {
		this.id = id
		this.defaultTemplateId = defaultTemplateId
		this.name = name
		this.categories = categories.map(item => new DefaultQuestionCategory(item))
	}
}

export const TemplateWithQuestionCategoryType = s({
	id: number.isRequired,
	defaultTemplateId: number.isRequired,
	name: string.isRequired,
	categories: arrayOf(DefaultQuestionCategoryType),
})

export default TemplateWithQuestionCategory
