import { s, number, object } from 'modules/types'
import Rank, { RankType } from './Rank'
import Title, { TitleType } from './Title'

export class JobDescription {
	constructor({ id, rank, title, employee_count: employeeCount, latest_version_created_at: latestVersionCreatedAt }) {
		this.id = id
		this.rank = new Rank(rank)
		this.title = new Title(title)
		this.employeeCount = employeeCount
		this.latestVersionCreatedAt = latestVersionCreatedAt && new Date(latestVersionCreatedAt * 1000)
	}
}

export const JobDescriptionType = s({
	id: number.isRequired,
	rank: RankType,
	title: TitleType,
	employeeCount: number.isRequired,
	latestVersionCreatedAt: object,
})

export default JobDescription
