import { s, arrayOf, bool, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'
import TimeoffRequestLog, { TimeoffRequestLogType } from './TimeoffRequestLog'

export class MyTimeoffRequest {
	constructor({
		id,
		start_date: startDate,
		end_date: endDate,
		start_half_day: startHalfDay,
		end_half_day: endHalfDay,
		total_days: totalDays,
		status,
		next_approver: nextApprover,
		timeoff_type: timeoffType,
		current_balance: currentBalance,
		added,
		rejected_by: rejectedBy,
		rejected_at: rejectedAt,
		approved_by: approvedBy,
		approved_at: approvedAt,
		timeoff_request_logs: timeoffRequestLogs,
	}) {
		this.id = id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.startHalfDay = startHalfDay
		this.endHalfDay = endHalfDay
		this.totalDays = totalDays
		this.status = status
		if (nextApprover) {
			this.nextApprover = new Employee(nextApprover)
		}
		this.timeoffType = new TimeoffType(timeoffType)
		this.currentBalance = currentBalance
		this.added = new Date(added * 1000)
		if (rejectedBy) {
			this.rejectedBy = new Employee(rejectedBy)
		}
		if (rejectedAt) {
			this.rejectedAt = new Date(rejectedAt * 1000)
		}
		if (approvedBy) {
			this.approvedBy = new Employee(approvedBy)
		}
		if (approvedAt) {
			this.approvedAt = new Date(approvedAt * 1000)
		}
		if (timeoffRequestLogs) {
			this.timeoffRequestLogs = timeoffRequestLogs.map(item => new TimeoffRequestLog(item))
		}
	}
}

export const MyTimeoffRequestType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
	startHalfDay: bool.isRequired,
	endHalfDay: bool.isRequired,
	totalDays: number.isRequired,
	status: number.isRequired, // timeoff TIMEOFF_REQUEST_STATUS
	nextApprover: EmployeeType,
	timeoffType: TimeoffTypeType,
	currentBalance: number.isRequired,
	added: object.isRequired,
	rejectedBy: EmployeeType,
	rejectedAt: object,
	approvedBy: EmployeeType,
	approvedAt: object,
	timeoffRequestLogs: arrayOf(TimeoffRequestLogType),
})

export default MyTimeoffRequest
