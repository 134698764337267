import BrainstormAnswer from './BrainstormAnswer'
import BrainstormQuestion from './BrainstormQuestion'
import BrainstormQuestionDetail from './BrainstormQuestionDetail'
import Employee from './Employee'

export default {
	BrainstormAnswer,
	BrainstormQuestion,
	BrainstormQuestionDetail,
	Employee,
}
