import { s, arrayOf, oneOfType, number, string } from 'modules/types'
import { Question, QuestionType } from './Question'
import { PreviousStageAnswer, PreviousStageAnswerType } from './PreviousStageAnswer'

export class QuestionLinkWithAnswer {
	constructor({
		id,
		question_link_id: questionLinkId,
		question,
		order,
		answer,
		previous_stage_answers: previousStageAnswers = null,
	}) {
		this.id = id || questionLinkId
		if (question) {
			this.question = new Question(question)
		}
		if (order) {
			this.order = order
		}
		this.answer = answer
		if (previousStageAnswers !== null) {
			this.previousStageAnswers = previousStageAnswers.map(item => new PreviousStageAnswer(item))
		}
	}
}

export const QuestionLinkWithAnswerType = s({
	id: number,
	question: QuestionType,
	order: number,
	answer: oneOfType([string, number]),
	previousStageAnswers: arrayOf(PreviousStageAnswerType),
})

export default QuestionLinkWithAnswer
