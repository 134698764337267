import { s, number, string } from 'modules/types'

export class CompanyPolicy {
	constructor({ id, title }) {
		this.id = id
		this.title = title
	}
}

export const CompanyPolicyType = s({
	id: number.isRequired,
	title: string,
})

export default CompanyPolicy
