import { s, arrayOf } from 'modules/types'
import QuestionWithPreviousStageAnswer, { QuestionWithPreviousStageAnswerType } from './QuestionWithPreviousStageAnswer'
import Appraisee, { AppraiseeType } from './Appraisee'

export class AppraiseeSuperiorScoring {
	constructor({ questions, appraisee }) {
		this.questions = questions.map(item => new QuestionWithPreviousStageAnswer(item))
		this.appraisee = new Appraisee(appraisee)
	}
}

export const AppraiseeSuperiorScoringType = s({
	questions: arrayOf(QuestionWithPreviousStageAnswerType),
	appraisee: AppraiseeType,
})

export default AppraiseeSuperiorScoring
