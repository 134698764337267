import { s, number, object, string } from 'modules/types'
import Creator, { CreatorType } from './Creator'

export class CompanyPolicyDetail {
	constructor({ id, title, content, attachment, attachment_url: attachmentUrl, created_by: createdBy, added }) {
		this.id = id
		this.title = title
		this.content = content
		this.attachment = attachment
		this.attachmentUrl = attachmentUrl
		this.createdBy = new Creator(createdBy)
		this.added = new Date(added * 1000)
	}

	getFileName() {
		if (this.attachmentUrl) {
			return this.attachmentUrl
				.split('/')
				.slice(-1)
				.pop()
		}
		return ''
	}
}

export const CompanyPolicyDetailType = s({
	id: number.isRequired,
	title: string,
	content: string,
	attachment: string,
	attachmentUrl: string,
	createdBy: CreatorType,
	added: object.isRequired,
})

export default CompanyPolicyDetail
