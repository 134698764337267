import { s, arrayOf, number } from 'modules/types'
import Approver, { ApproverType } from './Approver'
import EmployeeDetail, { EmployeeDetailType } from './EmployeeDetail'

export class EmployeeWithApprovers {
	constructor({ employee, experience_type_ids: experienceTypeIds, approvers }) {
		this.employee = new EmployeeDetail(employee)
		this.experienceTypeIds = experienceTypeIds
		this.approvers = approvers.map(item => new Approver(item))
	}
}

export const EmployeeWithApproversType = s({
	employee: EmployeeDetailType,
	experienceTypeIds: arrayOf(number),
	approvers: arrayOf(ApproverType),
})

export default EmployeeWithApprovers
