import { s, string, number, object } from 'modules/types'

export const SESSION_STATUS = {
	DRAFT: 0,
	STARTED: 1,
	STOPPED: 2,
}

export class Session {
	constructor({ id, name, description, status, start_date: startDate, end_date: endDate }) {
		this.id = id
		this.name = name
		this.description = description
		this.status = status
		this.startDate = new Date(startDate * 1000)
		this.endDate = new Date(endDate * 1000)
	}
}

export const SessionType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	status: number.isRequired, // @SESSION_STATUS
	startDate: object,
	endDate: object,
})

export default Session
