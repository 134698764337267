import { s, number, string } from 'modules/types'

export class SubmitAnswer {
	constructor({ question_id: questionId, answer }) {
		this.questionId = questionId
		this.answer = answer
	}
}

export const SubmitAnswerType = s({
	questionId: number.isRequired,
	answer: string,
})

export default SubmitAnswer
