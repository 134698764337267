import { s, arrayOf, number, string } from 'modules/types'
import WorkingCycleGroupSummary, { WorkingCycleGroupSummaryType } from './WorkingCycleGroupSummary'
import AllowDelete, { AllowDeleteType } from './AllowDelete'

export class WorkingCycleTypeDetail {
	constructor({
		id,
		name,
		start_time: startTime,
		end_time: endTime,
		groups_working_today: groupsWorkingToday,
		allow_delete: allowDelete,
	}) {
		this.id = id
		this.name = name
		this.startTime = startTime
		this.endTime = endTime
		this.groupsWorkingToday = groupsWorkingToday.map(item => new WorkingCycleGroupSummary(item))
		this.allowDelete = new AllowDelete(allowDelete)
	}
}

export const WorkingCycleTypeDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	startTime: string.isRequired,
	endTime: string.isRequired,
	groupsWorkingToday: arrayOf(WorkingCycleGroupSummaryType),
	allowDelete: AllowDeleteType,
})

export default WorkingCycleTypeDetail
