import { s, arrayOf, number } from 'modules/types'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'
import Stage, { StageType } from './Stage'

export class GroupStage {
	constructor({ id, stage, appraiser_types: appraiserTypes }) {
		this.id = id
		this.stage = new Stage(stage)
		this.appraiserTypes = appraiserTypes.map(item => new AppraiserType(item))
	}
}

export const GroupStageType = s({
	id: number.isRequired,
	stage: StageType,
	appraiserTypes: arrayOf(AppraiserTypeType),
})

export default GroupStage
