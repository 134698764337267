import { s, number, string } from 'modules/types'

export class GroupingWithQuestion {
	constructor({
		employee_detail_answer_id: employeeDetailAnswerId,
		employee_detail_answer_name: employeeDetailAnswerName,
		employee_detail_question_id: employeeDetailQuestionId,
		employee_detail_question_name: employeeDetailQuestionName,
		all_employee_in_group_finished_score_card: allEmployeeInGroupFinishedScoreCard,
	}) {
		this.employeeDetailAnswerId = employeeDetailAnswerId // NOTE: -1 if not having any superior
		this.employeeDetailAnswerName = employeeDetailAnswerName // NOTE: 'No superior' if not having any superior
		this.employeeDetailQuestionId = employeeDetailQuestionId
		this.employeeDetailQuestionName = employeeDetailQuestionName
		this.allEmployeeInGroupFinishedScoreCard = allEmployeeInGroupFinishedScoreCard
	}
}

export const GroupingWithQuestionType = s({
	employeeDetailAnswerId: number.isRequired,
	employeeDetailAnswerName: string.isRequired,
	employeeDetailQuestionId: number.isRequired,
	employeeDetailQuestionName: string.isRequired,
})

export default GroupingWithQuestion
