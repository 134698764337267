import { s, bool, arrayOf, number, string } from 'modules/types'
import { alphabetical } from 'utils/sort'
import GroupLeader, { GroupLeaderType } from './GroupLeader'

export class GroupCount {
	constructor({
		id,
		name,
		active_employee_count: activeEmployeeCount,
		archived_employee_count: archivedEmployeeCount,
		leaders,
	}) {
		this.id = id
		this.name = name
		this.activeEmployeeCount = activeEmployeeCount
		this.archivedEmployeeCount = archivedEmployeeCount
		this.leaders = leaders.sort(alphabetical({ path: 'employee.name' })).map(item => new GroupLeader(item))
		this.hasEmployee = Boolean(activeEmployeeCount) || Boolean(archivedEmployeeCount)
	}
}

export const GroupCountType = s({
	id: number.isRequired,
	name: string.isRequired,
	activeEmployeeCount: number.isRequired,
	archivedEmployeeCount: number.isRequired,
	leaders: arrayOf(GroupLeaderType),
	hasEmployee: bool.isRequired,
})

export default GroupCount
