import { s, number, string } from 'modules/types'
import { APPRAISAL_SESSION_STATUS } from './index.js'

export class Session {
	constructor({ id, name, description, status }) {
		this.id = id
		this.name = name
		this.description = description
		this.status = status
	}

	displayStatus() {
		switch (this.status) {
			case APPRAISAL_SESSION_STATUS.STOPPED:
				return 'STOPPED'
			case APPRAISAL_SESSION_STATUS.STARTED:
				return 'STARTED'
			case APPRAISAL_SESSION_STATUS.DRAFT:
				return 'DRAFT'
			default:
				throw new Error('Unknown session status')
		}
	}
}

export const SessionType = s({
	id: number.isRequired,
	name: string,
	description: string,
	status: number,
})

export default Session
