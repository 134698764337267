import { s, arrayOf, number } from 'modules/types'
import ExperienceRequest, { ExperienceRequestType } from './ExperienceRequest'
import ExperienceSubscription, { ExperienceSubscriptionType } from './ExperienceSubscription'

export class ExperienceRequestWithApproverScore {
	constructor({ experience_request: experienceRequest, final_hours: finalHours, subscriptions }) {
		this.experienceRequest = new ExperienceRequest(experienceRequest)
		this.finalHours = finalHours
		this.subscriptions = subscriptions.map(item => new ExperienceSubscription(item))
	}
}

export const ExperienceRequestWithApproverScoreType = s({
	experienceRequest: ExperienceRequestType,
	finalHours: number.isRequired,
	subscriptions: arrayOf(ExperienceSubscriptionType),
})

export default ExperienceRequestWithApproverScore
