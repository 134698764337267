import { s, arrayOf, number } from 'modules/types'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'

export class GroupStage {
	constructor({ id, stage_id: stageId, stage_number: stageNumber, group_appraiser_types: groupAppraiserTypes }) {
		this.id = id
		this.stageId = stageId
		this.stageNumber = stageNumber
		this.groupAppraiserTypes = groupAppraiserTypes.map(item => new AppraiserType(item))
	}
}

export const GroupStageType = s({
	id: number.isRequired,
	stageId: number.isRequired,
	stageNumber: number.isRequired,
	groupAppraiserTypes: arrayOf(AppraiserTypeType),
})

export default GroupStage
