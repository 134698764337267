import { s, number } from 'modules/types'
import WorkingCycleType, { WorkingCycleTypeType } from './WorkingCycleType'

export class SerializedRule {
	constructor({ day, id, type }) {
		this.id = id
		this.day = day
		if (type) {
			this.type = new WorkingCycleType(type)
		}
	}
}

export const SerializedRuleType = s({
	id: number.isRequired,
	day: number.isRequired,
	type: WorkingCycleTypeType,
})

export default SerializedRule
