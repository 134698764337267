import { s, number, string } from 'modules/types'

class Employee {
	constructor({
		id,
		name,
		NIK,
		group_name: groupName,
		department_name: departmentName,
		title_name: titleName,
		rank_name: rankName,
		position_name: positionName,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.groupName = groupName
		this.departmentName = departmentName
		this.titleName = titleName
		this.rankName = rankName
		this.positionName = positionName
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	groupName: string.isRequired,
	departmentName: string.isRequired,
	titleName: string.isRequired,
	rankName: string.isRequired,
	positionName: string.isRequired,
})

export default Employee
