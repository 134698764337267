import { s, string, number } from 'modules/types'

export class Employee {
	constructor({ id, name, NIK }) {
		this.id = id
		this.name = name
		this.NIK = NIK
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
})

export default Employee
