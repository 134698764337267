import { s, number, string } from 'modules/types'

export class Employee {
	constructor({ id, name, NIK, profile_picture: profilePicture }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.profilePicture = profilePicture
	}
}

export const EmployeeType = s({
	id: number,
	name: string.isRequired,
	NIK: string.isRequired,
	profilePicture: string,
})

export default Employee
