import React from 'react'

import SweetAlert from 'components/SweetAlert'

// lib
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { ReactTableDefaults } from 'react-table'

// material ui
import Button from '@material-ui/core/Button'

// custom components
import RouteLoader from '../RouteLoader'
import CustomTablePagination from 'components/CustomTablePagination'

// modules
import { refreshCurrentUser } from 'store/session'
import skleemi18n from 'utils/skleemi18n'

const i18nDict = {
	PREVIOUS: {
		id: 'Sebelumnya',
		en: 'Previous',
	},
	NEXT: {
		id: 'Selanjutnya',
		en: 'Next',
	},
	OF: {
		id: 'dari',
		en: 'of',
	},
	ROWS: {
		id: 'baris',
		en: 'rows',
	},
	PAGE: {
		id: 'Halaman',
		en: 'Page',
	},
	LOADING: {
		id: 'Memuat...',
		en: 'Loading...',
	},
}

class PrivateRoute extends React.Component {
	state = { loaded: false }

	componentWillMount() {
		const { dispatch, currentUser } = this.props

		if (!currentUser && localStorage.getItem('skleemAuthToken')) {
			return dispatch(refreshCurrentUser()).then(() => {
				this.setState({ loaded: true })
			})
		} else {
			this.setState({ loaded: true })
		}
	}

	renderAlert() {
		const { alertProps } = this.props
		if (!alertProps) return null
		return <SweetAlert {...alertProps} />
	}

	render() {
		const { component: Component, alertProps, store, translater, ...rest } = this.props

		Object.assign(ReactTableDefaults, {
			className: '-striped',
			PreviousComponent: Button,
			NextComponent: Button,
			sortable: false,
			defaultPageSize: 25,
			loadingText: translater.LOADING,
			pageText: translater.PAGE,
			ofText: translater.OF,
			rowsText: translater.ROWS,
			nextText: translater.NEXT,
			previousText: translater.PREVIOUS,
			column: {
				...ReactTableDefaults.column,
				style: {
					wordWrap: 'break-word',
					whiteSpace: 'pre-wrap',
				},
			},
			defaultFilterMethod: (filter, row, column) => {
				const id = filter.pivotId || filter.id
				const regexp = new RegExp(filter.value, 'i') // case insensitive, finds matching substring anywhere within string
				return row[id] !== undefined ? regexp.test(row[id]) : true
			},
			getNoDataProps: () => ({
				style: {
					background: 'none',
					top: '5.3rem',
				},
			}),
			// provides total count of the data to custom pagination component
			getPaginationProps: state => {
				return {
					count: state.data.length,
				}
			},
			getTrGroupProps: () => {
				return {
					style: {
						borderBottom: 0,
					},
				}
			},
			PaginationComponent: CustomTablePagination,
		})

		let component

		if (!localStorage.getItem('skleemAuthToken')) {
			component = props => (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location },
					}}
				/>
			)
		} else {
			if (!this.state.loaded) {
				component = () => <RouteLoader />
			} else {
				component = props => <Component store={store} {...props} />
			}
		}
		return (
			<React.Fragment>
				{this.renderAlert()}
				<Route {...rest} render={component} />
			</React.Fragment>
		)
	}
}

const mapStateWithProps = state => ({
	currentUser: state.session.currentUser,
	alertProps: state.alert.alertProps,
})

export default connect(mapStateWithProps)(skleemi18n(PrivateRoute, i18nDict))
