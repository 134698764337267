import CompanySettingLog from './CompanySettingLog'
import VisionAndMission from './VisionAndMission'

export const COMPANY_SETTING_LOG_TYPE = {
	EDIT_VISION: 1,
	EDIT_MISSION: 2,
	EDIT_LOGO: 3,
	EDIT_BIRTHDAY_SETTING: 4,
	EDIT_READ_MORE: 5,
}

export const BIRTHDAY_SETTING = {
	ALL: 1,
	DIRECT_SUBORDINATES: 2,
	ALL_SUBORDINATES: 3,
	PEER_SAME_DEPARTMENT: 4,
	OFF: 5,
	PEER_SAME_GROUP: 6,
	DIRECT_TWO_SUBORDINATES: 7,
}
export default {
	CompanySettingLog,
	VisionAndMission,
}
