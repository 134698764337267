import { s, number, object } from 'modules/types'
import ExperienceWallet, { ExperienceWalletType } from './ExperienceWallet'

export class MyExperienceRequest {
	constructor({
		id,
		posting_date: postingDate,
		status,
		experience_wallet: experienceWallet,
		submitted_hours: submittedHours,
		final_hours: finalHours = null,
	}) {
		this.id = id
		this.postingDate = new Date(postingDate)
		this.status = status
		this.experienceWallet = new ExperienceWallet(experienceWallet)
		this.submittedHours = submittedHours
		this.finalHours = finalHours || submittedHours
	}
}

export const MyExperienceRequestType = s({
	id: number.isRequired,
	postingDate: object.isRequired,
	status: number.isRequired, // @EXPERIENCE_REQUEST_STATUS
	experienceWallet: ExperienceWalletType,
	submittedHours: number.isRequired,
	finalHours: number,
})

export default MyExperienceRequest
