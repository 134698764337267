import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-idea'
export const API_URLS = {
	employees: `/api/setting/idea/employees/`,
	employeeDetail: employeeId => `/api/setting/idea/employees/${employeeId}/`,
	ideaFlows: `/api/setting/idea/idea_flows/`,
	reviewers: `/api/setting/idea/idea_reviewers/`,
	groupLeaderRoles: () => `/api/setting/idea/group_leader_roles/`,
	ranks: () => `/api/setting/idea/ranks/`,
}

const CREATED_IDEA_FLOW = `${MODULE_NAME} | CREATED IDEA FLOW`
const EDITED_IDEA_FLOW = `${MODULE_NAME} | EDITED IDEA FLOW`
const DELETED_IDEA_FLOW = `${MODULE_NAME} | DELETED IDEA FLOW`
const CHANGED_REVIEWER = `${MODULE_NAME} | CHANGED REVIEWER`
const CHANGE_PAGE_FILTER = `${MODULE_NAME} } CHANGE PAGE FILTER`
const CHANGE_LIMIT_FILTER = `${MODULE_NAME} } CHANGE LIMIT FILTER`
const CHANGE_NAME_OR_NIK_FILTER = `${MODULE_NAME} } CHANGE NAME OR NIK FILTER`

const REFRESHED_REVIEWERS = `${MODULE_NAME} | REFRESHED REVIEWERS`
const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED EMPLOYEES`
const REFRESHED_IDEA_FLOWS = `${MODULE_NAME} | REFRESHED IDEA FLOWS`
const REFRESHING_REVIEWERS = `${MODULE_NAME} | REFRESHING REVIEWERS`
const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING EMPLOYEES`
const REFRESHING_IDEA_FLOWS = `${MODULE_NAME} | REFRESHING IDEA FLOWS`
const REFRESHED_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHED GROUP LEADER ROLES`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED RANKS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshReviewers() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.reviewers
		let {
			settingIdea: {
				data: { nameOrNIKFilter, pageFilter, limitFilter },
			},
		} = getState()
		let args = {}
		if (nameOrNIKFilter) {
			args.name_or_nik = nameOrNIKFilter
		}
		if (pageFilter) {
			args.page = pageFilter
		}
		if (limitFilter) {
			args.limit = limitFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_REVIEWERS, apiUrl })
		return api.setting.idea.getReviewers(getParam).then(({ data: { employees, ideaFlows }, pagination }) => {
			dispatch({
				type: REFRESHED_REVIEWERS,
				data: {
					employees: employees.map(employee => ({
						employee: employee.employee,
						ideaFlows: employee.ideaFlows.slice(1), // remember to slice 1, to remove idea 'Owner' ideaflow
					})),
					ideaFlows: ideaFlows.slice(1), // remember to slice 1, to remove idea 'Owner' ideaflow
				},
				apiUrl,
				pagination,
			})
		})
	}
}

function refreshEmployees() {
	return dispatch => {
		let apiUrl = API_URLS.employees
		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.setting.idea.getEmployees().then(({ data }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, data, apiUrl })
		})
	}
}

function refreshIdeaFlows() {
	return dispatch => {
		let apiUrl = API_URLS.ideaFlows
		dispatch({ type: REFRESHING_IDEA_FLOWS, apiUrl })
		return api.setting.idea.ideaFlows.get().then(({ data }) => {
			dispatch({ type: REFRESHED_IDEA_FLOWS, data, apiUrl })
		})
	}
}

export function refreshGroupLeaderRoles() {
	return dispatch => {
		const apiUrl = API_URLS.groupLeaderRoles()
		return api.setting.idea.group_leader_roles.get().then(({ data: roles }) => {
			dispatch({ type: REFRESHED_GROUP_LEADER_ROLES, data: roles, apiUrl })
			return roles
		})
	}
}

export function refreshRanks() {
	return dispatch => {
		const apiUrl = API_URLS.ranks()
		return api.setting.idea.ranks.get().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, data: ranks, apiUrl })
			return ranks
		})
	}
}

export function changeNameOrNIKFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_NAME_OR_NIK_FILTER, name })
		dispatch(changePageFilter(1))
		dispatch(refreshReviewers())
	}
}

export function changePageFilter(page) {
	return dispatch => {
		dispatch({ type: CHANGE_PAGE_FILTER, page })
		dispatch(refreshReviewers())
	}
}

export function changeLimitFilter(limit) {
	return dispatch => {
		dispatch({ type: CHANGE_LIMIT_FILTER, limit })
		dispatch(refreshReviewers())
	}
}

export function createIdeaFlow() {
	return dispatch => {
		return api.setting.idea.ideaFlows.create().then(({ data }) => {
			toast(`Successfully added reviewer`)
			dispatch(refreshIdeaFlows())
			dispatch(refreshReviewers())
			dispatch({ type: CREATED_IDEA_FLOW, data })
			return data
		})
	}
}

export function editIdeaFlow(ideaFlowId, ideaFlow) {
	return dispatch => {
		return api.setting.idea.ideaFlows.edit(ideaFlowId, ideaFlow).then(({ data }) => {
			toast(`Edited "${data.name}"`)
			dispatch(refreshIdeaFlows())
			dispatch({ type: EDITED_IDEA_FLOW, data })
			return data
		})
	}
}

export function deleteIdeaFlow(ideaFlowId) {
	return dispatch => {
		return api.setting.idea.ideaFlows.delete(ideaFlowId).then(() => {
			toast('Successfully deleted reviewer')
			dispatch(refreshIdeaFlows())
			dispatch(refreshReviewers())
			dispatch({ type: DELETED_IDEA_FLOW })
		})
	}
}

export function changeReviewer(ideaFlowId, employeeId, data) {
	return dispatch => {
		return api.setting.idea.ideaFlows.changeReviewer(ideaFlowId, employeeId, data).then(() => {
			dispatch(refreshReviewers())
			dispatch({ type: CHANGED_REVIEWER })
		})
	}
}

export function fetchEmployees() {
	return (dispatch, getState) => {
		const {
			settingIdea: { api },
		} = getState()
		if (api[API_URLS.employees] === undefined) {
			dispatch(refreshEmployees())
		}
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshIdeaFlows())
		dispatch(refreshReviewers())
		dispatch(refreshGroupLeaderRoles())
		dispatch(refreshRanks())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	data: {
		nameOrNIKFilter: '',
		pageFilter: 1,
		limitFilter: 25,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_EMPLOYEES:
		case REFRESHING_IDEA_FLOWS:
		case REFRESHING_REVIEWERS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_EMPLOYEES:
		case REFRESHED_IDEA_FLOWS:
		case REFRESHED_GROUP_LEADER_ROLES:
		case REFRESHED_RANKS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case REFRESHED_REVIEWERS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case CHANGE_NAME_OR_NIK_FILTER:
			return {
				...state,
				data: {
					...state.data,
					nameOrNIKFilter: action.name,
					pageFilter: 1,
				},
			}
		case CHANGE_PAGE_FILTER:
			return {
				...state,
				data: {
					...state.data,
					pageFilter: action.page,
				},
			}
		case CHANGE_LIMIT_FILTER:
			return {
				...state,
				data: {
					...state.data,
					limitFilter: action.limit,
					pageFilter: 1,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
