import { s } from 'modules/types'
import IdeaFlow, { IdeaFlowType } from './IdeaFlow'
import IdeaReportCounter, { IdeaReportCounterType } from './IdeaReportCounter'

export class IdeaFlowReport {
	constructor({ idea_flow: ideaFlow, counter }) {
		this.ideaFlow = new IdeaFlow(ideaFlow)
		this.counter = new IdeaReportCounter(counter)
	}
}

export const IdeaFlowReportType = s({
	ideaFlow: IdeaFlowType.isRequired,
	counter: IdeaReportCounterType.isRequired,
})

export default IdeaFlowReport
