import { s, number, string } from 'modules/types'

export class Snapshot {
	constructor({ question_link_id: questionLinkId, answer }) {
		this.questionLinkId = questionLinkId
		this.answer = answer
	}
}

export const SnapshotType = s({
	questionLinkId: number.isRequired,
	answer: string,
})

export default Snapshot
