import { s, number, string } from 'modules/types'

export class Employee {
	constructor({ id, full_name: fullName, profile_picture_url: profilePictureUrl, NIK }) {
		this.id = id
		this.fullName = fullName
		this.profilePictureUrl = profilePictureUrl
		this.NIK = NIK
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	profilePictureUrl: string,
	NIK: string.isRequired,
})

export default Employee
