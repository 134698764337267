import React from 'react'
import { any, object, string } from 'modules/types'

// lib
import SweetAlert from './CustomSweetAlert/SweetAlert'

// material ui
import withStyles from '@material-ui/core/styles/withStyles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx'

const styles = () => ({
	...buttonStyle,
	customSweetAlert: {
		width: 'unset !important',
		minWidth: '478px',
		maxWidth: '688px',
	},
})

class CustomSweetAlert extends React.Component {
	static propTypes = {
		confirmClass: string,
		cancelClass: string,
		classes: object.isRequired,
		children: any,
	}

	static defaultProps = {
		confirmClass: 'success',
		cancelClass: 'white simple',
	}

	render() {
		const { classes, confirmClass, cancelClass, children, ...rest } = this.props
		return (
			<SweetAlert
				customClass={classes.customSweetAlert}
				confirmBtnCssClass={classes.button + ' ' + classes[confirmClass]}
				cancelBtnCssClass={classes.button + ' ' + classes[cancelClass]}
				{...rest}
			>
				{children}
			</SweetAlert>
		)
	}
}

export default withStyles(styles)(CustomSweetAlert)
