import { s, any, number } from 'modules/types'

export class QuestionAnswer {
	constructor({ question_id: questionId, question_type: questionType, answer }) {
		this.questionId = questionId
		this.questionType = questionType
		this.answer = answer
	}
}

export const QuestionAnswerType = s({
	questionId: number.isRequired,
	questionType: number.isRequired,
	answer: any,
})

export default QuestionAnswer
