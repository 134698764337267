import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let BrainstormingComponent

const Brainstorming = store => {
	if (BrainstormingComponent === undefined) {
		BrainstormingComponent = Loadable({
			loader: () => {
				return import('views/Brainstorming/containers/BrainstormingContainer')
			},
			loading: RouteLoader,
		})
	}
	return BrainstormingComponent
}

export default Brainstorming
