import { s, number, string } from 'modules/types'

export class TitleCount {
	constructor({
		id,
		name,
		level,
		active_employee_count: activeEmployeeCount,
		archived_employee_count: archivedEmployeeCount,
	}) {
		this.id = id
		this.name = name
		this.level = level
		this.activeEmployeeCount = activeEmployeeCount
		this.archivedEmployeeCount = archivedEmployeeCount
	}
}

export const TitleCountType = s({
	id: number.isRequired,
	name: string.isRequired,
	level: number.isRequired,
	employeeCount: number.isRequired,
})

export default TitleCount
