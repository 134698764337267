import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let DashboardSettingComponent

const DashboardSetting = store => {
	if (DashboardSettingComponent === undefined) {
		DashboardSettingComponent = Loadable({
			loader: () => {
				return import('views/DashboardSetting/containers/DashboardSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return DashboardSettingComponent
}

export default DashboardSetting
