import { s, object, string } from 'modules/types'

export class CreatedGroupSnapshot {
	constructor({ name, start_date: startDate }) {
		this.name = name
		this.startDate = new Date(startDate)
	}
}

export const CreatedGroupSnapshotType = s({
	name: string.isRequired,
	startDate: object,
})

export default CreatedGroupSnapshot
