import { s, number, string } from 'modules/types'

export class EmployeeDetail {
	constructor({
		id,
		name,
		NIK,
		group_name: groupName,
		department_name: departmentName,
		title_name: titleName,
		superior_name: superiorName,
		rank_name: rankName,
		position_name: positionName,
		picture,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.groupName = groupName
		this.departmentName = departmentName
		this.titleName = titleName
		this.superiorName = superiorName
		this.rankName = rankName
		this.positionName = positionName
		this.picture = picture
	}
}

export const EmployeeDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	groupName: string,
	departmentName: string,
	titleName: string,
	superiorName: string,
	rankName: string,
	positionName: string,
	picture: string,
})

export default EmployeeDetail
