import Provider from 'react-redux/es/components/Provider'
import { ConnectedRouter } from 'react-router-redux'
import React from 'react'

const ProviderWrapper = ({ children, history, store }) => (
	<Provider store={store}>
		<ConnectedRouter history={history}>{children}</ConnectedRouter>
	</Provider>
)
export default ProviderWrapper
