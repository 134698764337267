import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let DashboardComponent

const Dashboard = store => {
	if (DashboardComponent === undefined) {
		DashboardComponent = Loadable({
			loader: () => {
				return import('views/Dashboard/containers/DashboardContainer')
			},
			loading: RouteLoader,
		})
	}
	return DashboardComponent
}

export default Dashboard
