import AnswerChoice from './AnswerChoice'
import CreateDefaultTemplateCategory from './CreateDefaultTemplateCategory'
import DefaultQuestionCategory from './DefaultQuestionCategory'
import DefaultTemplate from './DefaultTemplate'
import DefaultTemplateSimple from './DefaultTemplateSimple'
import Question from './Question'
import QuestionLink from './DefaultQuestionLink'
import TemplateWithQuestions from './TemplateWithQuestions'

export const APPRAISAL_CATEGORY_VIEW_PERMISSION = {
	ALL: 0,
	SELF: 1,
	PEER: 2,
	SUBORDINATE: 3,
	SUPERIOR: 4,
	SECOND_DEGREE_SUPERIOR: 5,
	OTHERS: 6,
	EXTERNAL: 7,
	GROUP_LEADER: 8,
	DEPARTMENT_HEAD: 9,
}

export const APPRAISAL_CATEGORY_ANSWER_PERMISSION = {
	ALL: 0,
	SELF: 1,
	PEER: 2,
	SUBORDINATE: 3,
	SUPERIOR: 4,
	SECOND_DEGREE_SUPERIOR: 5,
	OTHERS: 6,
	EXTERNAL: 7,
	GROUP_LEADER: 8,
	DEPARTMENT_HEAD: 9,
}

export const APPRAISAL_TEMPLATE_LOG_TYPE = {
	CREATED: 1,
	EDITED: 2,
}

export default {
	AnswerChoice,
	CreateDefaultTemplateCategory,
	DefaultQuestionCategory,
	DefaultTemplate,
	DefaultTemplateSimple,
	Question,
	QuestionLink,
	TemplateWithQuestions,
}
