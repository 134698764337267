import { s, arrayOf } from 'modules/types'
import { Field, FieldType } from './Field'
import { Question, QuestionType } from './Question'

export class FieldWithQuestion {
	constructor({ field, questions }) {
		this.field = new Field(field)
		this.questions = questions.map(item => new Question(item))
	}
}

export const FieldWithQuestionType = s({
	field: FieldType.isRequired,
	questions: arrayOf(QuestionType).isRequired,
})

export default FieldWithQuestion
