import api from 'modules/api'
import isBefore from 'date-fns/is_before'
import { refreshEmployeeList } from './employee_database'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `onOffboarding`

export const API_URLS = {
	offboardingReasons: () => `/api/offboarding/offboarding_reasons/`,
	allEmployees: () => `/api/offboarding/get_active_employees/`,
	types: () => `/api/offboarding/get_types/`,
	ranks: () => `/api/offboarding/get_ranks/`,
	featureReviewerTypes: () => `/api/offboarding/get_feature_reviewer_types/`,
	permissions: () => `/api/offboarding/get_permissions/`,
	offboardingRequests: () => `/api/offboarding/get_pending_offboarding_requests/`,
	onboardingFields: () => `/api/offboarding/get_onboarding_fields/`,
	requestDetail: requestId => `/api/offboarding/offboarding_requests/${requestId}/`,
	employeeDependencies: requestId => `/api/offboarding/offboarding_requests/${requestId}/get_employee_dependencies/`,
	employmentInfo: employeeId => `/api/offboarding/employees/${employeeId}/get_employment_info/`,
	employeeOffboardingRequest: employeeId =>
		`/api/offboarding/employees/${employeeId}/current_pending_offboarding_request/`,
}

const CREATED_OFFBOARDING_REQUEST = `${MODULE_NAME} | CREATED_OFFBOARDING_REQUEST`
const EDITED_OFFBOARDING_REQUEST = `${MODULE_NAME} | EDITED_OFFBOARDING_REQUEST`
const CANCELLED_OFFBOARDING_REQUEST = `${MODULE_NAME} | CANCELLED_OFFBOARDING_REQUEST`
const ONBOARD_EMPLOYEE = `${MODULE_NAME} | ONBOARD_EMPLOYEE`
const OFFBOARD_EMPLOYEE = `${MODULE_NAME} | OFFBOARD_EMPLOYEE`

const REFRESHED_OFFBOARDING_REASONS = `${MODULE_NAME} | REFRESHED_OFFBOARDING_REASONS`
const REFRESHED_ALL_EMPLOYEES = `${MODULE_NAME} | REFRESHED_ALL_EMPLOYEES`
const REFRESHED_TYPES = `${MODULE_NAME} | REFRESHED_TYPES`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED_RANKS`
const REFRESHED_FEATURE_REVIEWER_TYPES = `${MODULE_NAME} | REFRESHED_FEATURE_REVIEWER_TYPES`
const REFRESHED_PERMISSIONS = `${MODULE_NAME} | REFRESHED_PERMISSIONS`
const REFRESHED_OFFBOARDING_REQUESTS = `${MODULE_NAME} | REFRESHED_OFFBOARDING_REQUESTS`
const REFRESHED_ONBOARDING_FIELDS = `${MODULE_NAME} | REFRESHED_ONBOARDING_FIELDS`
const REFRESHED_REQUEST_DETAIL = `${MODULE_NAME} | REFRESHED_REQUEST_DETAIL`
const REFRESHED_EMPLOYEE_DEPENDENCIES = `${MODULE_NAME} | REFRESHED_EMPLOYEE_DEPENDENCIES`
const REFRESHED_EMPLOYMENT_INFO = `${MODULE_NAME} | REFRESHED_EMPLOYMENT_INFO`
const REFRESHED_EMPLOYEE_OFFBOARDING_REQUEST = `${MODULE_NAME} | REFRESHED_EMPLOYEE_OFFBOARDING_REQUEST`

// ------------------------------------
// Actions
// ------------------------------------
export function refreshOffboardingReasons() {
	return dispatch => {
		const apiUrl = API_URLS.offboardingReasons()
		return api.offboarding.getOffboardingReasons().then(({ data: reasons }) => {
			dispatch({ type: REFRESHED_OFFBOARDING_REASONS, apiUrl, data: reasons })
			return reasons
		})
	}
}

export function refreshAllEmployees() {
	return dispatch => {
		const apiUrl = API_URLS.allEmployees()
		return api.offboarding.getAllEmployees().then(({ data: employees }) => {
			dispatch({ type: REFRESHED_ALL_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

export function refreshTypes() {
	return dispatch => {
		const apiUrl = API_URLS.types()
		return api.offboarding.getTypes().then(({ data: types }) => {
			dispatch({ type: REFRESHED_TYPES, apiUrl, data: types })
			return types
		})
	}
}

export function refreshRanks() {
	return dispatch => {
		const apiUrl = API_URLS.ranks()
		return api.offboarding.getRanks().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, apiUrl, data: ranks })
			return ranks
		})
	}
}

export function refreshFeatureReviewerTypes() {
	return dispatch => {
		const apiUrl = API_URLS.featureReviewerTypes()
		return api.offboarding.getFeatureReviewerTypes().then(({ data: featureReviewerTypes }) => {
			dispatch({ type: REFRESHED_FEATURE_REVIEWER_TYPES, apiUrl, data: featureReviewerTypes })
			return featureReviewerTypes
		})
	}
}

export function refreshPermissions() {
	return dispatch => {
		const apiUrl = API_URLS.permissions()
		return api.offboarding.getPermissions().then(({ data: permissions }) => {
			dispatch({ type: REFRESHED_PERMISSIONS, apiUrl, data: permissions })
			return permissions
		})
	}
}

export function refreshOffboardingRequests() {
	return dispatch => {
		const apiUrl = API_URLS.offboardingRequests()
		return api.offboarding.getPendingOffboardingRequests().then(({ data: requests }) => {
			dispatch({ type: REFRESHED_OFFBOARDING_REQUESTS, apiUrl, data: requests })
			return requests
		})
	}
}

export function refreshOnboardingFields() {
	return dispatch => {
		const apiUrl = API_URLS.onboardingFields()
		return api.offboarding.getOnboardingFields().then(({ data: fieldGroups }) => {
			dispatch({ type: REFRESHED_ONBOARDING_FIELDS, apiUrl, data: fieldGroups })
			return fieldGroups
		})
	}
}

export function refreshRequestDetail(requestId) {
	return dispatch => {
		const apiUrl = API_URLS.requestDetail(requestId)
		return api.offboarding.offboarding_requests.getDetail(requestId).then(({ data: requestDetail }) => {
			dispatch({ type: REFRESHED_REQUEST_DETAIL, apiUrl, data: requestDetail })
			return requestDetail
		})
	}
}

export function refreshEmployeeDependencies(requestId) {
	return dispatch => {
		const apiUrl = API_URLS.employeeDependencies(requestId)
		return api.offboarding.offboarding_requests
			.getEmployeeDependencies(requestId)
			.then(({ data: employeeDependencies }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_DEPENDENCIES, apiUrl, data: employeeDependencies })
				return employeeDependencies
			})
	}
}

export function refreshEmploymentInfo(employeeId) {
	return dispatch => {
		const apiUrl = API_URLS.employmentInfo(employeeId)
		return api.offboarding.employees.getEmploymentInfo(employeeId).then(({ data: info }) => {
			dispatch({ type: REFRESHED_EMPLOYMENT_INFO, apiUrl, data: info })
			return info
		})
	}
}

export function refreshEmployeeOffboardingRequest(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeOffboardingRequest(employeeId)
		return api.offboarding.employees.getPendingOffboardingRequest(employeeId).then(({ data: requests }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_OFFBOARDING_REQUEST, apiUrl, data: requests })
			return requests
		})
	}
}

export function createRequest(employeeId, data) {
	return dispatch => {
		return api.offboarding.offboarding_requests.create(employeeId, data).then(({ data: request }) => {
			dispatch({ type: CREATED_OFFBOARDING_REQUEST, request })
			return request
		})
	}
}

export function editRequest(requestId, data) {
	return dispatch => {
		return api.offboarding.offboarding_requests.edit(requestId, data).then(({ data: requestDetail }) => {
			dispatch(refreshOffboardingRequests())
			dispatch({ type: EDITED_OFFBOARDING_REQUEST, requestDetail })
			return requestDetail
		})
	}
}

export function cancelRequest(requestId) {
	return dispatch => {
		return api.offboarding.offboarding_requests.cancel(requestId).then(() => {
			dispatch({ type: CANCELLED_OFFBOARDING_REQUEST, requestId })
		})
	}
}

export function offboardEmployee(requestId, data) {
	return dispatch => {
		return api.offboarding.offboarding_requests.process(requestId, data).then(() => {
			dispatch(refreshOffboardingRequests())
			dispatch(refreshEmployeeList())
			dispatch({ type: OFFBOARD_EMPLOYEE })
		})
	}
}

export function onboardEmployee(data) {
	return dispatch => {
		return api.offboarding.employees.onboardEmployee(data).then(() => {
			dispatch({ type: ONBOARD_EMPLOYEE })
		})
	}
}

export function loadInitRequestRoute() {
	return dispatch => {
		dispatch(refreshOffboardingReasons())
	}
}

export function loadEditRequestRoute(requestId) {
	return dispatch => {
		return dispatch(refreshOffboardingReasons()).then(() => {
			return dispatch(refreshRequestDetail(requestId))
		})
	}
}

export function loadProcessOffboardingRoute(requestId) {
	return dispatch => {
		dispatch(refreshAllEmployees())
		dispatch(refreshOffboardingReasons())
		dispatch(refreshRequestDetail(requestId))
		dispatch(refreshEmployeeDependencies(requestId))
	}
}

export function loadOnboardingRoute() {
	return dispatch => {
		dispatch(refreshOnboardingFields())
		dispatch(refreshTypes())
		dispatch(refreshRanks())
		dispatch(refreshPermissions())
		dispatch(refreshAllEmployees())
		dispatch(refreshFeatureReviewerTypes())
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_OFFBOARDING_REASONS:
		case REFRESHED_ALL_EMPLOYEES:
		case REFRESHED_TYPES:
		case REFRESHED_RANKS:
		case REFRESHED_FEATURE_REVIEWER_TYPES:
		case REFRESHED_PERMISSIONS:
		case REFRESHED_OFFBOARDING_REQUESTS:
		case REFRESHED_REQUEST_DETAIL:
		case REFRESHED_ONBOARDING_FIELDS:
		case REFRESHED_EMPLOYEE_DEPENDENCIES:
		case REFRESHED_EMPLOYMENT_INFO:
		case REFRESHED_EMPLOYEE_OFFBOARDING_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CREATED_OFFBOARDING_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.offboardingRequests()]: [...state.api[API_URLS.offboardingRequests()], action.request].sort(
						(a, b) => (isBefore(a.lastDate, b.lastDate) ? -1 : 1)
					),
				},
			}
		case CANCELLED_OFFBOARDING_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.offboardingRequests()]: state.api[API_URLS.offboardingRequests()].filter(
						item => item.id !== action.requestId
					),
				},
			}
		default:
			return state
	}
}
