import { s, number } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class GroupLeader {
	constructor({ id, employee, role_id: roleId }) {
		this.id = id
		this.employee = new Employee(employee)
		this.roleId = roleId
	}
}

export const GroupLeaderType = s({
	id: number.isRequired,
	employee: EmployeeType,
	roleId: number.isRequired,
})

export default GroupLeader
