import { s, arrayOf, number, string } from 'modules/types'
import PermissionWithExt, { PermissionWithExtType } from './PermissionWithExt'

export class EmployeeWithPermissions {
	constructor({ id, name, permissions }) {
		this.id = id
		this.name = name
		this.permissions = permissions.map(item => new PermissionWithExt(item))
	}
}

export const EmployeeWithPermissionsType = s({
	id: number.isRequired,
	name: string.isRequired,
	permissions: arrayOf(PermissionWithExtType),
})

export default EmployeeWithPermissions
