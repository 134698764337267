import { s, bool, number, object, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import { TIMEOFF_REQUEST_STATUS } from './index'

export class EmployeePeriodRequest {
	constructor({
		id,
		start_date: startDate,
		end_date: endDate,
		start_half_day: startHalfDay,
		end_half_day: endHalfDay,
		duration,
		timeoff_type_name: timeoffTypeName,
		status,
		next_approver: nextApprover,
	}) {
		this.id = id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.startHalfDay = startHalfDay
		this.endHalfDay = endHalfDay
		this.duration = duration
		this.timeoffTypeName = timeoffTypeName
		this.status = status
		if (nextApprover) {
			this.nextApprover = new Employee(nextApprover)
		}
	}

	displayStatus() {
		switch (this.status) {
			case TIMEOFF_REQUEST_STATUS.PENDING:
				return 'PENDING'
			case TIMEOFF_REQUEST_STATUS.APPROVED:
				return 'APPROVED'
			case TIMEOFF_REQUEST_STATUS.REJECTED:
				return 'REJECTED'
			case TIMEOFF_REQUEST_STATUS.CANCELLED:
				return 'CANCELLED'
			case TIMEOFF_REQUEST_STATUS.TAKEN:
				return 'TAKEN'
			default:
				return 'Unknown status'
		}
	}
}

export const EmployeePeriodRequestType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
	startHalfDay: bool.isRequired,
	endHalfDay: bool.isRequired,
	duration: number.isRequired,
	timeoffTypeName: string.isRequired,
	status: number.isRequired, // @TIMEOFF_REQUEST_STATUS
	nextApprover: EmployeeType,
})

export default EmployeePeriodRequest
