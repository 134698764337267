import { s, arrayOf, number, string } from 'modules/types'
import DefaultQuestionCategory, { DefaultQuestionCategoryType } from './DefaultQuestionCategory'

export class DefaultTemplate {
	constructor({ id, name, description, categories }) {
		this.id = id
		this.name = name
		this.description = description
		this.categories = categories.map(item => new DefaultQuestionCategory(item))
	}
}

export const DefaultTemplateType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	categories: arrayOf(DefaultQuestionCategoryType),
})

export default DefaultTemplate
