import Announcement from './Announcement'
import AnnouncementDetail from './AnnouncementDetail'
import Audience from './Audience'
import Department from './Department'
import Employee from './Employee'
import Superior from './Superior'
import Group from './Group'
import Title from './Title'

export default {
	Announcement,
	AnnouncementDetail,
	Audience,
	Department,
	Employee,
	Superior,
	Group,
	Title,
}
