import React from 'react'
import { Spinner } from '@blueprintjs/core'
import { IconButton } from '@material-ui/core'

// TODO: refactor all project to only use CustomButtons/Button with justIcon

function IconCustomButton({ ...props }) {
	const { loading, children, ...rest } = props
	return <IconButton {...rest}>{loading ? <Spinner className="pt-small" /> : children}</IconButton>
}
export default IconCustomButton
