import { s, arrayOf } from 'modules/types'
import IdeaFlow, { IdeaFlowType } from './IdeaFlow'
import EmployeeWithPosition, { EmployeeWithPositionType } from './EmployeeWithPosition'

export class IdeaRevieweeIdeaFlow {
	constructor({ idea_flow: ideaFlow, reviewees }) {
		this.ideaFlow = new IdeaFlow(ideaFlow)
		this.reviewees = reviewees.map(item => new EmployeeWithPosition(item))
	}
}

export const IdeaRevieweeIdeaFlowType = s({
	ideaFlow: IdeaFlowType,
	reviewees: arrayOf(EmployeeWithPositionType),
})

export default IdeaRevieweeIdeaFlow
