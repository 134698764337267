import { s, number } from 'modules/types'
import WorkingCycleType, { WorkingCycleTypeType } from './WorkingCycleType'

class WorkingCycleRule {
	constructor({ id, working_cycle_type: workingCycleType, day }) {
		this.id = id
		this.workingCycleType = new WorkingCycleType(workingCycleType)
		this.day = day
	}
}

export const WorkingCycleRuleType = s({
	id: number.isRequired,
	workingCycleType: WorkingCycleTypeType,
	day: number.isRequired,
})

export default WorkingCycleRule
