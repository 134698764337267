import Employee from './Employee'
import EmployeeDependency from './EmployeeDependency'
import EmploymentInfo from './EmploymentInfo'
import PendingRequest from './PendingRequest'
import Reason from './Reason'
import RequestDetail from './RequestDetail'

export const EMPLOYEE_DEPENDENCY_TYPE = {
	SUBORDINATE: 1,
	TIMEOFF_APPROVEE: 2,
	EXPERIENCE_APPROVEE: 3,
	IDEA_REVIEWEE: 4,
	LEADERSHIP_POSITION: 5,
}

export default {
	Employee,
	EmployeeDependency,
	EmploymentInfo,
	PendingRequest,
	Reason,
	RequestDetail,
}
