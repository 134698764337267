import { s, bool, number, string } from 'modules/types'

export class ExperienceType {
	constructor({ id, name, code, notes, is_archived: isArchived }) {
		this.id = id
		this.name = name
		this.code = code
		this.notes = notes
		this.isArchived = isArchived
	}
}

export const ExperienceTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
	code: string.isRequired,
	notes: string,
	isArchived: bool.isRequired,
})

export default ExperienceType
