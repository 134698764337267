import _ from 'lodash'
import format from 'date-fns/format'
import api from 'modules/api'
import { FILTER_TYPE } from 'modules/models/analytics/demographics'
// import {toast, getObjectOrUndefined} from 'utils'
// import {PERMISSIONS} from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'demographics'
export const API_URLS = {
	filters: filterType => `/api/analytics/demographics/get_filters/?filter_type=${filterType}`,
	employeeCountChart: () => `/api/analytics/demographics/get_employee_count_chart/`,
	employeeCountDetailDays: (month, year) =>
		`/api/analytics/demographics/get_employee_count_detail_days/?month=${month}&year=${year}`,
	inOutEmployees: date => `/api/analytics/demographics/get_employee_count_detail_date/?date=${date}`,
	companyDistributions: (month, year, filterType) =>
		`/api/analytics/demographics/get_company_distributions/?month=${month}&year=${year}&grouping_filter_type=${filterType}`,
	companyDistributionsEmployees: (month, year, filterType, firstLevelFilterValueId) =>
		`/api/analytics/demographics/get_company_distributions/?month=${month}&year=${year}&grouping_filter_type=${filterType}&first_level_filter_value_id=${firstLevelFilterValueId}`,
	breakdownCharts: date => `/api/analytics/demographics/breakdown_charts/?date=${date}`,
	chartDistributions: (questionId, filterType, date) =>
		`/api/analytics/demographics/breakdown_charts/${questionId}/${filterType}/distributions/?date=${date}`,
	chartDistributionsDetail: (questionId, filterType, firstLevelFilterValueId, secondLevelFilterValueId, date) =>
		`/api/analytics/demographics/breakdown_charts/${questionId}/${filterType}/distributions/employees/?date=${date}&first_level_filter_value_id=${firstLevelFilterValueId}&second_level_filter_value_id=${secondLevelFilterValueId}`,
}

const REFRESHED_EMPLOYEE_COUNT_CHART = `${MODULE_NAME} | REFRESHED_EMPLOYEE_COUNT_CHART`
const REFRESHED_FILTERS = `${MODULE_NAME} | REFRESHED_FILTERS`
const REFRESHED_EMPLOYEE_COUNT_DETAIL_DAYS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_COUNT_DETAIL_DAYS`
const REFRESHED_IN_OUT_EMPLOYEES = `${MODULE_NAME} | REFRESHED_IN_OUT_EMPLOYEES`
const REFRESHED_COMPANY_DISTRIBUTIONS = `${MODULE_NAME} | REFRESHED_COMPANY_DISTRIBUTIONS`
const REFRESHED_COMPANY_DISTRIBUTIONS_EMPLOYEES = `${MODULE_NAME} | REFRESHED_COMPANY_DISTRIBUTIONS_EMPLOYEES`
const REFRESHED_BREAKDOWN_CHARTS = `${MODULE_NAME} | REFRESHED_BREAKDOWN_CHARTS`
const REFRESHED_CHART_DISTRIBUTIONS = `${MODULE_NAME} | REFRESHED_CHART_DISTRIBUTIONS`
const REFRESHED_CHART_DISTRIBUTIONS_DETAIL = `${MODULE_NAME} | REFRESHED_CHART_DISTRIBUTIONS_DETAIL`

const CHANGE_TURNOVER_YEAR = `${MODULE_NAME} | CHANGE_TURNOVER_YEAR`
const CHANGE_TURNOVER_DETAIL_TIME = `${MODULE_NAME} | CHANGE_TURNOVER_DETAIL_TIME`
const CHANGE_TURNOVER_FILTER_TYPE = `${MODULE_NAME} | CHANGE_TURNOVER_FILTER_TYPE`
const CHANGE_TURNOVER_FILTER_VALUE = `${MODULE_NAME} | CHANGE_TURNOVER_FILTER_VALUE`
const CHANGE_DETAIL_FILTER_TYPE = `${MODULE_NAME} | CHANGE_DETAIL_FILTER_TYPE`
const CHANGE_BREAKDOWN_DATE = `${MODULE_NAME} | CHANGE_BREAKDOWN_DATE`

const NO_DATA_AVAILABLE_ERROR = `${MODULE_NAME} | NO_DATA_AVAILABLE_ERROR`

const LOAD_MODULE = `${MODULE_NAME} | LOAD_MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD_MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshEmployeeCountChart() {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: { turnoverYear, filterType, filterValue },
			},
		} = getState()
		const apiUrl = API_URLS.employeeCountChart()
		const args = { year: turnoverYear }
		if (filterType && filterType !== 'ALL') {
			args.filter_type = filterType
		}
		if (filterValue !== 'ALL' && filterValue > -1) {
			// check if number, can have 0 value
			args.filter_value_id = filterValue
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		return api.analytics.demographics.getEmployeeCountChart(getParam).then(({ data: chart }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_COUNT_CHART, apiUrl, data: chart })
			return chart
		})
	}
}

function refreshFilters(filterType) {
	return dispatch => {
		if (filterType === 'ALL') return
		const apiUrl = API_URLS.filters(filterType)
		return api.analytics.demographics.getFilters(filterType).then(({ data: filters }) => {
			dispatch({ type: REFRESHED_FILTERS, apiUrl, data: filters })
			return filters
		})
	}
}

export function refreshEmployeeCountDetailDays() {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: {
					turnoverDetailTime: { month, year },
				},
			},
		} = getState()
		const apiUrl = API_URLS.employeeCountDetailDays(month, year)
		const args = { month, year }
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		return api.analytics.demographics.getEmployeeCountDetailDays(getParam).then(({ data: detail }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_COUNT_DETAIL_DAYS, apiUrl, data: detail })
			return detail
		})
	}
}

export function refreshInOutEmployees(date) {
	return dispatch => {
		const formattedDate = format(date, 'YYYY-MM-DD')
		const apiUrl = API_URLS.inOutEmployees(formattedDate)
		return api.analytics.demographics.getInOutEmployees(formattedDate).then(({ data: employees }) => {
			dispatch({ type: REFRESHED_IN_OUT_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

export function refreshCompanyDistributions() {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: {
					turnoverDetailTime: { month, year },
					detailFilterType,
				},
			},
		} = getState()
		const apiUrl = API_URLS.companyDistributions(month, year, detailFilterType)
		const args = { month, year, grouping_filter_type: detailFilterType }
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		return api.analytics.demographics
			.getCompanyDistributions(getParam)
			.then(({ data: distributions }) => {
				dispatch({ type: REFRESHED_COMPANY_DISTRIBUTIONS, apiUrl, data: distributions })
				return distributions
			})
			.catch(err => {
				dispatch({ type: NO_DATA_AVAILABLE_ERROR, apiUrl })
			})
	}
}

export function refreshCompanyDistributionsEmployees(firstLevelFilterValueId) {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: {
					turnoverDetailTime: { month, year },
					detailFilterType,
				},
			},
		} = getState()
		const apiUrl = API_URLS.companyDistributionsEmployees(month, year, detailFilterType, firstLevelFilterValueId)
		const args = {
			month,
			year,
			grouping_filter_type: detailFilterType,
			first_level_filter_value_id: firstLevelFilterValueId,
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		return api.analytics.demographics.getCompanyDistributionsEmployees(getParam).then(({ data: employees }) => {
			dispatch({ type: REFRESHED_COMPANY_DISTRIBUTIONS_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

export function refreshBreakdownCharts() {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: { breakdownDate },
			},
		} = getState()
		const breakdownDateString = format(breakdownDate, 'YYYY-MM-DD')
		const apiUrl = API_URLS.breakdownCharts(breakdownDateString)
		return api.analytics.demographics.getBreakdownCharts(breakdownDateString).then(({ data: charts }) => {
			dispatch({ type: REFRESHED_BREAKDOWN_CHARTS, apiUrl, data: charts })
			return charts
		})
	}
}

export function refreshChartDistributions(questionId, filterType) {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: { breakdownDate },
			},
		} = getState()
		const breakdownDateString = format(breakdownDate, 'YYYY-MM-DD')
		const apiUrl = API_URLS.chartDistributions(questionId, filterType, breakdownDateString)
		return api.analytics.demographics
			.getChartDistributions(questionId, filterType, breakdownDateString)
			.then(({ data: distributions }) => {
				dispatch({ type: REFRESHED_CHART_DISTRIBUTIONS, apiUrl, data: distributions })
				return distributions
			})
	}
}

export function refreshChartDistributionsDetail(
	questionId,
	filterType,
	firstLevelFilterValueId,
	secondLevelFilterValueId
) {
	return (dispatch, getState) => {
		const {
			demographics: {
				data: { breakdownDate },
			},
		} = getState()
		const breakdownDateString = format(breakdownDate, 'YYYY-MM-DD')
		const apiUrl = API_URLS.chartDistributionsDetail(
			questionId,
			filterType,
			firstLevelFilterValueId,
			secondLevelFilterValueId,
			breakdownDateString
		)
		return api.analytics.demographics
			.getChartDistributionsDetail(
				questionId,
				filterType,
				firstLevelFilterValueId,
				secondLevelFilterValueId,
				breakdownDateString
			)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_CHART_DISTRIBUTIONS_DETAIL, apiUrl, data })
				return data
			})
	}
}

export function changeTurnoverYear(year) {
	return dispatch => {
		dispatch({ type: CHANGE_TURNOVER_YEAR, year })
		dispatch(refreshEmployeeCountChart())
	}
}

export function changeTurnoverDetailTime({ month, year }) {
	return dispatch => {
		dispatch({ type: CHANGE_TURNOVER_DETAIL_TIME, month, year })
		dispatch(refreshEmployeeCountDetailDays())
		dispatch(refreshCompanyDistributions())
	}
}

export function changeTurnoverFilterType(filterType) {
	return dispatch => {
		dispatch({ type: CHANGE_TURNOVER_FILTER_TYPE, filterType })
		dispatch(refreshFilters(filterType))
	}
}

export function changeTurnoverFilterValue(filterValue) {
	return dispatch => {
		dispatch({ type: CHANGE_TURNOVER_FILTER_VALUE, filterValue })
		dispatch(refreshEmployeeCountChart())
	}
}

export function changeDetailFilterType(filterType) {
	return dispatch => {
		dispatch({ type: CHANGE_DETAIL_FILTER_TYPE, filterType })
		dispatch(refreshCompanyDistributions())
	}
}

export function changeBreakdownDate(date) {
	return dispatch => {
		dispatch({ type: CHANGE_BREAKDOWN_DATE, date })
		dispatch(refreshBreakdownCharts())
	}
}

export function loadModule() {
	return dispatch => {
		// const { session: { currentUser: { permissions } } } = getState()
		// if (permissions.includes(PERMISSIONS.MANAGE_ANNOUNCEMENT) && permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY)) {
		// 	dispatch(showCreateAnnouncementForm())
		// }
		// dispatch(refreshAnnouncements())
		// 	.then((announcements) => {
		// 		if (announcements.length > 0 && !(permissions.includes(PERMISSIONS.MANAGE_ANNOUNCEMENT) && permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY))) {
		// 			dispatch(selectAnnouncement(announcements[0].id))
		// 		}
		// 	})
		dispatch(refreshEmployeeCountChart())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	error: {},
	data: {
		filterType: 'ALL',
		filterValue: 'ALL',
		turnoverYear: new Date().getFullYear(),
		turnoverDetailTime: {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
		},
		detailFilterType: FILTER_TYPE.AGE,
		breakdownDate: new Date(),
		// openedDistribution: [{
		//     fieldId,
		//     filterType,
		//     filterValue,
		// }]
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_EMPLOYEE_COUNT_CHART:
		case REFRESHED_FILTERS:
		case REFRESHED_EMPLOYEE_COUNT_DETAIL_DAYS:
		case REFRESHED_IN_OUT_EMPLOYEES:
		case REFRESHED_COMPANY_DISTRIBUTIONS:
		case REFRESHED_COMPANY_DISTRIBUTIONS_EMPLOYEES:
		case REFRESHED_BREAKDOWN_CHARTS:
		case REFRESHED_CHART_DISTRIBUTIONS:
		case REFRESHED_CHART_DISTRIBUTIONS_DETAIL:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CHANGE_TURNOVER_YEAR:
			return {
				...state,
				data: {
					...state.data,
					turnoverYear: action.year,
				},
			}
		case CHANGE_TURNOVER_DETAIL_TIME:
			return {
				...state,
				data: {
					...state.data,
					turnoverDetailTime: {
						month: action.month,
						year: action.year,
					},
				},
			}
		case CHANGE_TURNOVER_FILTER_TYPE:
			return {
				...state,
				data: {
					...state.data,
					filterType: action.filterType,
				},
			}
		case CHANGE_TURNOVER_FILTER_VALUE:
			return {
				...state,
				data: {
					...state.data,
					filterValue: action.filterValue,
				},
			}
		case CHANGE_DETAIL_FILTER_TYPE:
			return {
				...state,
				data: {
					...state.data,
					detailFilterType: action.filterType,
				},
			}
		case CHANGE_BREAKDOWN_DATE:
			return {
				...state,
				data: {
					...state.data,
					breakdownDate: action.date,
				},
			}
		case NO_DATA_AVAILABLE_ERROR:
			return {
				...state,
				error: {
					[action.apiUrl]: 'NO_DATA_AVAILABLE_ERROR',
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
