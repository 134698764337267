import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { getObjectOrUndefined } from 'utils'
import { refreshDetailChangeLogs } from 'views/EmployeeDatabase/modules/employee_database'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `employeeContract`

export const API_URLS = {
	contracts: employeeId => `/api/employee_database/employees/${employeeId}/contracts/`,
	contractDetail: (employeeId, contractId) => `/api/employee_database/employees/${employeeId}/contracts/${contractId}/`,
}

const REFRESHING_CONTRACTS = `${MODULE_NAME} | REFRESHING CONTRACTS`
const REFRESHING_CONTRACT_DETAIL = `${MODULE_NAME} | REFRESHING CONTRACT DETAIL`

const REFRESHED_CONTRACTS = `${MODULE_NAME} | REFRESHED CONTRACTS`
const REFRESHED_CONTRACT_DETAIL = `${MODULE_NAME} | REFRESHED CONTRACT DETAIL`

const CREATED_CONTRACT = `${MODULE_NAME} | CREATED CONTRACT`
const EDITED_CONTRACT = `${MODULE_NAME} | EDITED CONTRACT`
const CANCELLED_CONTRACT = `${MODULE_NAME} | CANCELLED CONTRACT`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------

function refreshContracts(employeeId) {
	return dispatch => {
		const apiUrl = API_URLS.contracts(employeeId)
		dispatch({ type: REFRESHING_CONTRACTS, apiUrl })
		return api.employee_database.employees.contracts.get(employeeId).then(({ data: contracts }) => {
			dispatch({ type: REFRESHED_CONTRACTS, data: contracts, apiUrl })
			return contracts
		})
	}
}

export function refreshContractDetail(employeeId, contractId) {
	return dispatch => {
		const apiUrl = API_URLS.contractDetail(employeeId, contractId)
		dispatch({ type: REFRESHING_CONTRACT_DETAIL, apiUrl })
		return api.employee_database.employees.contracts.getDetail(employeeId, contractId).then(({ data: contract }) => {
			dispatch({ type: REFRESHED_CONTRACT_DETAIL, data: contract, apiUrl, employeeId })
			return contract
		})
	}
}

export function createContract(employeeId, data) {
	return dispatch => {
		return api.employee_database.employees.contracts.create(employeeId, data).then(({ data: contract }) => {
			dispatch({ type: CREATED_CONTRACT, contract })
			dispatch(refreshContracts(employeeId))
			dispatch(refreshDetailChangeLogs(employeeId))
			toast('Successfully created a new contract')
			return contract
		})
	}
}

export function editContract(employeeId, contractId, data) {
	return dispatch => {
		return api.employee_database.employees.contracts.edit(employeeId, contractId, data).then(({ data: contract }) => {
			dispatch({ type: EDITED_CONTRACT, employeeId, contractId, contract })
			dispatch(refreshDetailChangeLogs(employeeId))
			toast('Successfully edited contract')
			return contract
		})
	}
}

export function cancelContract(employeeId, contractId) {
	return dispatch => {
		return api.employee_database.employees.contracts
			.markAsCancelled(employeeId, contractId)
			.then(({ data: contract }) => {
				dispatch({ type: CANCELLED_CONTRACT, contract })
				dispatch(refreshContracts(employeeId))
				dispatch(refreshDetailChangeLogs(employeeId))
				toast('Contract cancelled')
				return contract
			})
	}
}

export function loadModule(employeeId) {
	return dispatch => {
		dispatch(refreshContracts(employeeId))
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	let contracts = getObjectOrUndefined(state.api, API_URLS.contracts(action.employeeId))
	switch (action.type) {
		case REFRESHING_CONTRACTS:
		case REFRESHING_CONTRACT_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_CONTRACTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_CONTRACT_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[API_URLS.contracts(action.employeeId)]: contracts.map(contract => {
						if (contract.id === action.data.id) {
							return action.data
						}
						return contract
					}),
				},
			}
		case EDITED_CONTRACT:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.contracts(action.employeeId)]: contracts.map(contract => {
						if (contract.id === action.contractId) {
							return action.contract
						}
						return contract
					}),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
