import { s, arrayOf, string, number } from 'modules/types'
import { AnswerChoice, AnswerChoiceType } from './AnswerChoice'
import _ from 'lodash'

export const QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
	CHECKBOX: 4,
}

export const TYPE_SELECT_OPTIONS = [
	{
		content: 'Multiple choice',
		value: QUESTION_TYPE.RADIO,
	},
	{
		content: 'Short answer',
		value: QUESTION_TYPE.INPUT,
	},
	{
		content: 'Paragraph',
		value: QUESTION_TYPE.TEXT,
	},
	{
		content: 'Linear scale',
		value: QUESTION_TYPE.LINEAR,
	},
	{
		content: 'Checkbox',
		value: QUESTION_TYPE.CHECKBOX,
	},
]

export const SIZE_SELECT_OPTIONS = _.range(10).map(item => ({ content: item + 1, value: item + 1 }))

export class Question {
	constructor({
		id,
		self_content: selfContent,
		alternative_content: alternativeContent,
		type,
		linear_lowest_label: linearLowestLabel,
		linear_highest_label: linearHighestLabel,
		order,
		choices,
	}) {
		this.id = id
		this.selfContent = selfContent
		this.alternativeContent = alternativeContent
		this.type = type
		this.linearLowestLabel = linearLowestLabel
		this.linearHighestLabel = linearHighestLabel
		this.order = order
		if (choices) {
			this.choices = choices.map(item => new AnswerChoice(item))
		}
	}
}

export const QuestionType = s({
	id: number.isRequired,
	selfContent: string,
	alternativeContent: string.isRequired,
	type: number.isRequired,
	linearLowestLabel: string,
	linearHighestLabel: string,
	choices: arrayOf(AnswerChoiceType),
	order: number.isRequired,
})

export default Question
