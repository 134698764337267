import { s, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import AppraisalTemplateLogExtInfo, { AppraisalTemplateLogExtInfoType } from './AppraisalTemplateLogExtInfo'

export class AppraisalTemplateLog {
	constructor({ id, type, operator = null, ext_info: extInfo, added }) {
		this.id = id
		this.type = type
		if (operator !== null) {
			this.operator = new Employee(operator)
		}
		this.extInfo = new AppraisalTemplateLogExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}
}

export const AppraisalTemplateLogType = s({
	id: number.isRequired,
	type: number.isRequired, // @APPRAISAL_TEMPLATE_LOG_TYPE
	operator: EmployeeType,
	extInfo: AppraisalTemplateLogExtInfoType,
	added: object.isRequired,
})

export default AppraisalTemplateLog
