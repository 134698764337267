import { s, number } from 'modules/types'

export class CalendarData {
	constructor({ day, month, year, total_hours: totalHours }) {
		this.day = day
		this.month = month
		this.year = year
		this.totalHours = totalHours
	}
}

export const CalendarDataType = s({
	day: number.isRequired,
	month: number.isRequired,
	year: number.isRequired,
	totalHours: number.isRequired,
})

export default CalendarData
