import { s, number, string } from 'modules/types'

export class EmployeeScoreCardUrl {
	constructor({ employee_id: employeeId, employee_name: employeeName, url }) {
		this.employeeId = employeeId
		this.employeeName = employeeName
		this.url = url
	}
}

export const EmployeeScoreCardUrlType = s({
	employeeId: number.isRequired,
	employeeName: string.isRequired,
	url: string.isRequired,
})

export default EmployeeScoreCardUrl
