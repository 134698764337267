import { s, number, string } from 'modules/types'

export class EmployeeDetail {
	constructor({ id, name, NIK, title, department, group }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.title = title
		this.department = department
		this.group = group
	}
}

export const EmployeeDetailType = s({
	id: number.isRequired,
	name: string,
	NIK: string,
	title: string,
	department: string,
	group: string,
})

export default EmployeeDetail
