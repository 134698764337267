import { s, bool, number, string } from 'modules/types'

export class FieldGroup {
	constructor({ id, name, is_default: isDefault, order }) {
		this.id = id
		this.name = name
		this.isDefault = isDefault
		this.order = order
	}
}

export const FieldGroupType = s({
	id: number.isRequired,
	name: string.isRequired,
	isDefault: bool.isRequired,
	order: number.isRequired,
})

export default FieldGroup
