// import { httpGet } from '../utils'
// ------------------------------------
// Constants
// ------------------------------------

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state
	}
}
