import { s, number, object, string } from 'modules/types'
import TimeoffGroupLogExtInfo, { TimeoffGroupLogExtInfoType } from './TimeoffGroupLogExtInfo'

export const TIMEOFF_GROUP_LOG_TYPE = {
	CREATED: 1,
	EMPLOYEE_MOVED_IN: 2, // ext_info: {from_timeoff_group: {id, name}, to_timeoff_group: {id, name}, employee_ids: [id]}
	EMPLOYEE_MOVED_OUT: 3, // ext_info: {from_timeoff_group: {id, name}, to_timeoff_group: {id, name}, employee_ids: [id]}
	EDITED_NAME: 7, // ext_info: {old_name, new_name}
	EDITED_RULES: 8, // ext_info: {serialized_old_rules: {id, type: {id, name}/None, date_range: {id, start_date, end_date}}, serialized_new_rules: {id, type: {id, name}/None, date_range: {id, start_date, end_date}}}
}

export class TimeoffGroupLog {
	constructor({
		id,
		operator_name: operatorName,
		operator_picture_url: operatorPictureUrl,
		type,
		ext_info: extInfo,
		added,
	}) {
		this.id = id
		this.operatorName = operatorName
		this.operatorPictureUrl = operatorPictureUrl
		this.type = type
		this.extInfo = new TimeoffGroupLogExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}
}

export const TimeoffGroupLogType = s({
	id: number.isRequired,
	operatorName: string.isRequired,
	operatorPictureUrl: string.isRequired,
	type: number.isRequired,
	extInfo: TimeoffGroupLogExtInfoType,
	added: object.isRequired,
})

export default TimeoffGroupLog
