import { s, number, string } from 'modules/types'

export class NotAllowedAction {
	constructor({ action, reason }) {
		this.action = action
		this.reason = reason
	}
}

export const NotAllowedActionType = s({
	action: number.isRequired, // @IdeaAction in IdeaSubscriptionDetail.js
	reason: string.isRequired,
})

export default NotAllowedAction
