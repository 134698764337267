import _ from 'lodash'
import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
import { PERMISSIONS } from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'companyPolicy'
export const API_URLS = {
	companyPolicies: () => '/api/company_policies/',
	companyPolicyDetail: companyPolicyId => `/api/company_policies/${companyPolicyId}/`,
}

const CREATED_COMPANY_POLICY = `${MODULE_NAME} | CREATED COMPANY POLICY`
const EDITED_COMPANY_POLICY = `${MODULE_NAME} | EDITED COMPANY POLICY`
const DELETED_COMPANY_POLICY = `${MODULE_NAME} | DELETED COMPANY POLICY`

const REFRESHED_COMPANY_POLICIES = `${MODULE_NAME} | REFRESHED COMPANY POLICIES`
const REFRESHED_COMPANY_POLICY_DETAIL = `${MODULE_NAME} | REFRESHED COMPANY POLICY DETAIL`

const REFRESHING_COMPANY_POLICIES = `${MODULE_NAME} | REFRESHING COMPANY POLICIES`
const REFRESHING_COMPANY_POLICY_DETAIL = `${MODULE_NAME} | REFRESHING COMPANY POLICY DETAIL`

const SELECT_COMPANY_POLICY = `${MODULE_NAME} | SELECT COMPANY POLICY`
const TURN_ON_EDITING = `${MODULE_NAME} | TURN ON EDITING`
const TURN_OFF_EDITING = `${MODULE_NAME} | TURN OFF EDITING`
const SHOW_CREATE_POLICY_FORM = `${MODULE_NAME} | SHOW CREATE POLICY FORM`
const HIDE_CREATE_POLICY_FORM = `${MODULE_NAME} | HIDE CREATE POLICY FORM`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshCompanyPolicies() {
	return dispatch => {
		let apiUrl = API_URLS.companyPolicies()
		dispatch({ type: REFRESHING_COMPANY_POLICIES, apiUrl })
		return api.company_policies.get().then(({ data }) => {
			dispatch({ type: REFRESHED_COMPANY_POLICIES, data, apiUrl })
			return data
		})
	}
}

function refreshCompanyPolicyDetail(companyPolicyId) {
	return dispatch => {
		let apiUrl = API_URLS.companyPolicyDetail(companyPolicyId)
		dispatch({ type: REFRESHING_COMPANY_POLICY_DETAIL, apiUrl })
		return api.company_policies.getDetail(companyPolicyId).then(({ data }) => {
			dispatch({ type: REFRESHED_COMPANY_POLICY_DETAIL, data, apiUrl })
		})
	}
}

export function createCompanyPolicy(companyPolicy) {
	return dispatch => {
		return api.company_policies.create(companyPolicy).then(({ data }) => {
			toast(`Successfully added company policy`)
			// clear selected policy, so hideCreatePolicyForm will select the newly created one
			dispatch(selectCompanyPolicy(undefined))
			dispatch({ type: CREATED_COMPANY_POLICY, data })
			dispatch(refreshCompanyPolicies()).then(() => {
				dispatch(hideCreatePolicyForm())
			})
			return data
		})
	}
}

export function editCompanyPolicy(companyPolicyId, companyPolicy) {
	return dispatch => {
		return api.company_policies.edit(companyPolicyId, companyPolicy).then(({ data }) => {
			toast(`Edited "${data.title}"`)
			dispatch(refreshCompanyPolicies())
			dispatch(refreshCompanyPolicyDetail(companyPolicyId))
			dispatch({ type: EDITED_COMPANY_POLICY, data })
			return data
		})
	}
}

export function deleteCompanyPolicy(companyPolicy) {
	return dispatch => {
		return api.company_policies.delete(companyPolicy.id).then(() => {
			toast(`Successfully deleted "${companyPolicy.title}" company policy`)
			dispatch({ type: DELETED_COMPANY_POLICY, deletedCompanyPolicyId: companyPolicy.id })
			dispatch(refreshCompanyPolicies()).then(companyPolicies => {
				if (companyPolicies && companyPolicies.length > 0) {
					dispatch(selectCompanyPolicy(companyPolicies[0].id))
				} else {
					dispatch(selectCompanyPolicy(undefined))
				}
			})
		})
	}
}

export function selectCompanyPolicy(companyPolicyId) {
	return (dispatch, getState) => {
		// TODO: in the future, we need to make create form a route. and then any route changes from there will trigger alert, instead of hardcoding it like this
		// show alert if there is any data in the createForm
		const { companyPolicy, form } = getState()
		const isShowingCreateForm = getObjectOrUndefined(companyPolicy, 'data.isShowingCreateForm')
		const formData = getObjectOrUndefined(form, 'create-company-policy.values')
		if (isShowingCreateForm) {
			let hasContent = false
			if (formData) {
				for (let fieldName in formData) {
					if (!formData[fieldName] || _.isEmpty(formData[fieldName])) {
					} else {
						hasContent = true
					}
				}
			}
			if (hasContent) {
				let confirmMove = confirm('Are you sure you want to discard this company policy?') // eslint-disable-line no-restricted-globals
				if (!confirmMove) {
					return
				}
			}
		}
		dispatch({ type: SELECT_COMPANY_POLICY, companyPolicyId })
		dispatch(turnOffEditing())
		if (companyPolicyId) {
			dispatch(refreshCompanyPolicyDetail(companyPolicyId))
		}
	}
}

export function turnOnEditing() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING })
	}
}

export function turnOffEditing() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING })
	}
}

export function showCreatePolicyForm() {
	return dispatch => {
		dispatch({ type: SHOW_CREATE_POLICY_FORM })
	}
}

export function hideCreatePolicyForm() {
	return (dispatch, getState) => {
		const {
			companyPolicy: {
				api,
				data: { selectedCompanyPolicyId },
			},
		} = getState()
		const companyPolicies = api[API_URLS.companyPolicies()]
		if (!selectedCompanyPolicyId && companyPolicies.length > 0) {
			dispatch(selectCompanyPolicy(companyPolicies[0].id))
		}
		dispatch({ type: HIDE_CREATE_POLICY_FORM })
		dispatch(turnOffEditing())
	}
}

export function loadModule() {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { permissions },
			},
		} = getState()
		if (
			permissions.includes(PERMISSIONS.MANAGE_COMPANY_POLICY) &&
			permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY)
		) {
			dispatch(showCreatePolicyForm())
		}
		dispatch(refreshCompanyPolicies()).then(companyPolicies => {
			if (
				companyPolicies.length > 0 &&
				!(
					permissions.includes(PERMISSIONS.MANAGE_COMPANY_POLICY) && permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY)
				)
			) {
				dispatch(selectCompanyPolicy(companyPolicies[0].id))
			}
		})
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	data: {
		isEditing: false,
		isShowingCreateForm: false,
		selectedCompanyPolicyId: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_COMPANY_POLICIES:
		case REFRESHING_COMPANY_POLICY_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_COMPANY_POLICIES:
		case REFRESHED_COMPANY_POLICY_DETAIL:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case SELECT_COMPANY_POLICY:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: false,
					selectedCompanyPolicyId: action.companyPolicyId,
				},
			}
		case DELETED_COMPANY_POLICY:
			return {
				...state,
				api: {
					[API_URLS.companyPolicies()]: [
						...(state.api[API_URLS.companyPolicies()] || []).filter(
							companyPolicy => companyPolicy.id !== action.deletedCompanyPolicyId
						),
					],
				},
			}
		case TURN_ON_EDITING:
			return {
				...state,
				data: {
					...state.data,
					isEditing: true,
				},
			}
		case TURN_OFF_EDITING:
			return {
				...state,
				data: {
					...state.data,
					isEditing: false,
				},
			}
		case SHOW_CREATE_POLICY_FORM:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: true,
				},
			}
		case HIDE_CREATE_POLICY_FORM:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: false,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
