import { s, arrayOf, number, object, string } from 'modules/types'
import GroupLeader, { GroupLeaderType } from './GroupLeader'

export class Employee {
	constructor({
		id,
		name,
		NIK,
		superior_name: superiorName,
		title_name: titleName,
		rank_name: rankName,
		group_name: groupName,
		group_leaders: groupLeaders = null,
		department_name: departmentName,
		department_head_name: departmentHeadName = null,
		company_join_date: companyJoinDate,
		position_name: positionName,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.superiorName = superiorName
		this.titleName = titleName
		this.rankName = rankName
		this.groupName = groupName
		if (groupLeaders !== null) {
			this.groupLeaders = groupLeaders.map(item => new GroupLeader(item))
		}
		this.departmentName = departmentName
		if (departmentHeadName !== null) {
			this.departmentHeadName = departmentHeadName
		}
		this.companyJoinDate = new Date(companyJoinDate * 1000)
		this.positionName = positionName
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	superiorName: string,
	titleName: string,
	rankName: string,
	groupName: string,
	groupLeaders: arrayOf(GroupLeaderType),
	departmentName: string,
	departmentHeadName: string,
	companyJoinDate: object.isRequired,
	positionName: string,
})

export default Employee
