import _ from 'lodash'
import api from 'modules/api'
import { PERMISSIONS } from 'modules/constants'
import { refreshRanks, refreshGroupLeaderRoles } from '../../WorkstationSetting/routes/Idea/modules/settingIdea'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `employeeIdea`

export const API_URLS = {
	employees: () => `/api/setting/idea/employees/`,
	ideaFlows: () => `/api/setting/idea/idea_flows/`,
	employeeReviewers: employeeId => `/api/setting/idea/idea_reviewers/${employeeId}/`,
	employeeReviewees: employeeId => `/api/setting/idea/idea_reviewees/${employeeId}/`,
}

const CHANGED_REVIEWER = `${MODULE_NAME} | CHANGED_REVIEWER`
const ADDED_REVIEWEE = `${MODULE_NAME} | ADDED_REVIEWEE`
const REMOVED_REVIEWEE = `${MODULE_NAME} | REMOVED_REVIEWEE`

const REFRESHING_IDEA_FLOWS = `${MODULE_NAME} | REFRESHING_IDEA_FLOWS`
const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING_EMPLOYEES`
const REFRESHING_EMPLOYEE_REVIEWERS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REVIEWERS`
const REFRESHING_EMPLOYEE_REVIEWEES = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REVIEWEES`

const REFRESHED_IDEA_FLOWS = `${MODULE_NAME} | REFRESHED_IDEA_FLOWS`
const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED_EMPLOYEES`
const REFRESHED_EMPLOYEE_REVIEWERS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REVIEWERS`
const REFRESHED_EMPLOYEE_REVIEWEES = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REVIEWEES`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
// ------------------------------------
// Actions
// ------------------------------------

function refreshIdeaFlows() {
	return dispatch => {
		let apiUrl = API_URLS.ideaFlows()
		dispatch({ type: REFRESHING_IDEA_FLOWS, apiUrl })
		return api.setting.idea.ideaFlows.get().then(({ data }) => {
			dispatch({ type: REFRESHED_IDEA_FLOWS, data, apiUrl })
		})
	}
}

function refreshEmployees() {
	return dispatch => {
		let apiUrl = API_URLS.employees()
		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.setting.idea.getEmployees().then(({ data }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, data, apiUrl })
		})
	}
}

function refreshEmployeeReviewers(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeReviewers(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_REVIEWERS, apiUrl })
		return api.setting.idea.getEmployeeReviewers(employeeId).then(({ data: { employee, ideaFlows } }) => {
			const editableIdeaFlows = ideaFlows.slice(1) // remove 'Owner' idea flow
			dispatch({ type: REFRESHED_EMPLOYEE_REVIEWERS, data: { employee, ideaFlows: editableIdeaFlows }, apiUrl })
			return { employee, ideaFlows: editableIdeaFlows }
		})
	}
}

function refreshEmployeeReviewees(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeReviewees(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_REVIEWEES, apiUrl })
		return api.setting.idea.getEmployeeReviewees(employeeId).then(({ data }) => {
			let ideaRevieweeIdeaFlowList = data.slice(1) // remove 'Owner' idea flow
			ideaRevieweeIdeaFlowList.forEach(ideaRevieweeIdeaFlow => ({
				...ideaRevieweeIdeaFlow,
				reviewees: ideaRevieweeIdeaFlow.reviewees.sort((a, b) => a.name.localeCompare(b.name)),
			}))
			dispatch({ type: REFRESHED_EMPLOYEE_REVIEWEES, data: ideaRevieweeIdeaFlowList, apiUrl })
			return ideaRevieweeIdeaFlowList
		})
	}
}

export function addReviewee(ideaFlowId, employeeId, reviewerId) {
	return dispatch => {
		return api.setting.idea.ideaFlows.changeReviewer(ideaFlowId, employeeId, reviewerId).then(() => {
			dispatch(refreshEmployeeReviewees(reviewerId))
			dispatch({ type: ADDED_REVIEWEE })
		})
	}
}

export function removeReviewee(ideaFlowId, employeeId, previousReviewerId) {
	return dispatch => {
		const employeeRevieweesApiUrl = API_URLS.employeeReviewees(previousReviewerId)
		return api.setting.idea.ideaFlows.changeReviewer(ideaFlowId, employeeId, null).then(() => {
			dispatch({
				type: REMOVED_REVIEWEE,
				employeeRevieweesApiUrl,
				ideaFlowId,
				employeeId,
				previousReviewerId,
			})
		})
	}
}

export function changeReviewer(ideaFlowId, employeeId, reviewer) {
	return dispatch => {
		const employeeReviewersApiUrl = API_URLS.employeeReviewers(employeeId)
		return api.setting.idea.ideaFlows.changeReviewer(ideaFlowId, employeeId, reviewer).then(() => {
			dispatch({
				type: CHANGED_REVIEWER,
				employeeReviewersApiUrl,
				ideaFlowId,
				employeeId,
				...reviewer,
			})
		})
	}
}

export function loadModule(employeeId) {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { permissions },
			},
		} = getState()
		if (permissions.includes(PERMISSIONS.SETTING_WORKSTATION_ACCESS)) {
			dispatch(refreshRanks())
			dispatch(refreshGroupLeaderRoles())
			dispatch(refreshIdeaFlows())
			dispatch(refreshEmployees())
			dispatch(refreshEmployeeReviewers(employeeId))
			dispatch(refreshEmployeeReviewees(employeeId))
		}
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	let employeeIdeaFlows
	let employeeReviewees
	switch (action.type) {
		case REFRESHING_IDEA_FLOWS:
		case REFRESHING_EMPLOYEES:
		case REFRESHING_EMPLOYEE_REVIEWERS:
		case REFRESHING_EMPLOYEE_REVIEWEES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_IDEA_FLOWS:
		case REFRESHED_EMPLOYEES:
		case REFRESHED_EMPLOYEE_REVIEWERS:
		case REFRESHED_EMPLOYEE_REVIEWEES:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case CHANGED_REVIEWER:
			employeeIdeaFlows = state.api[action.employeeReviewersApiUrl]
			return {
				...state,
				api: {
					...state.api,
					[action.employeeReviewersApiUrl]: {
						...employeeIdeaFlows,
						ideaFlows: employeeIdeaFlows.ideaFlows.map(ideaFlowColumn => {
							if (ideaFlowColumn.ideaFlowId === action.ideaFlowId) {
								return {
									...ideaFlowColumn,
									reviewerId: action.reviewerId,
									reviewerType: action.reviewerType,
									rankId: action.rankId,
									groupRoleId: action.groupRoleId,
								}
							}
							return ideaFlowColumn
						}),
					},
				},
			}
		case REMOVED_REVIEWEE:
			employeeReviewees = state.api[action.employeeRevieweesApiUrl]
			return {
				...state,
				api: {
					...state.api,
					[action.employeeRevieweesApiUrl]: employeeReviewees.map(ideaRevieweeIdeaFlow => {
						if (ideaRevieweeIdeaFlow.ideaFlow.id === action.ideaFlowId) {
							return {
								...ideaRevieweeIdeaFlow,
								reviewees: ideaRevieweeIdeaFlow.reviewees.filter(reviewee => reviewee.id !== action.employeeId),
							}
						}
						return ideaRevieweeIdeaFlow
					}),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
