import api from 'modules/api'
import _ from 'lodash'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-permission'

export const API_URLS = {
	ranks: () => `/api/setting/permission/get_ranks/`,
	employeesWithPermissions: () => `/api/setting/permission/get_employees_with_permissions/`,
}

const REFRESHING_RANKS = `${MODULE_NAME} | REFRESHING RANKS`
const REFRESHING_AVAILABLE_PERMISSIONS = `${MODULE_NAME} | REFRESHING AVAILABLE PERMISSIONS`
const REFRESHING_EMPLOYEES_WITH_PERMISSIONS = `${MODULE_NAME} | REFRESHING EMPLOYEES WITH PERMISSIONS`

const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED RANKS`
const REFRESHED_AVAILABLE_PERMISSIONS = `${MODULE_NAME} | REFRESHED AVAILABLE PERMISSIONS`
const REFRESHED_EMPLOYEES_WITH_PERMISSIONS = `${MODULE_NAME} | REFRESHED EMPLOYEES WITH PERMISSIONS`
const EDITED_PERMISSIONS = `${MODULE_NAME} | EDITED PERMISSIONS`
const TOGGLE_EDIT = `${MODULE_NAME} | TOGGLE EDIT`
const CHANGE_PAGE_FILTER = `${MODULE_NAME} | CHANGE PAGE FILTER`
const CHANGE_LIMIT_FILTER = `${MODULE_NAME} | CHANGE LIMIT FILTER`
const CHANGE_NAME_OR_NIK_FILTER = `${MODULE_NAME} | CHANGE NAME OR NIK FILTER`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
const NEXT_PAGE = `${MODULE_NAME} | NEXT PAGE`
const PREV_PAGE = `${MODULE_NAME} | PREVIOUS PAGE`

// ------------------------------------
// Actions
// ------------------------------------
export function refreshRanks() {
	return dispatch => {
		const apiUrl = `/api/setting/permission/get_ranks/`
		dispatch({ type: REFRESHING_RANKS, apiUrl })
		return api.setting.permission.get_ranks().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, data: ranks, apiUrl })
			return ranks
		})
	}
}

export function refreshAvailablePermissions(parent) {
	return dispatch => {
		dispatch({ type: REFRESHING_AVAILABLE_PERMISSIONS })
		return api.setting.permission.employees.get_available_permissions(parent).then(({ success, permissions }) => {
			if (success) {
				dispatch({ type: REFRESHED_AVAILABLE_PERMISSIONS, permissions })
			}
		})
	}
}

export function refreshEmployeesWithPermissions(parent) {
	return (dispatch, getState) => {
		let apiUrl = `/api/setting/permission/get_employees_with_permissions/`
		let state = getState()
		if (state.settingPermissionEmployeeAccess === undefined) {
			state = {}
		} else {
			state = state.settingPermissionEmployeeAccess
		}
		let args = { parent }
		if (state.data.pageFilter) {
			args.page = state.data.pageFilter
		}
		if (state.data.nameOrNIKFilter) {
			args.name_or_nik = state.data.nameOrNIKFilter
		}
		if (state.data.limitFilter) {
			args.limit = state.data.limitFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_EMPLOYEES_WITH_PERMISSIONS, apiUrl })
		return api.setting.permission.get_employees_with_permissions(getParam).then(({ success, data, pagination }) => {
			if (success) {
				dispatch({ type: REFRESHED_EMPLOYEES_WITH_PERMISSIONS, data, apiUrl, pagination })
			}
		})
	}
}

export function editPermissions(parent, employeeId, permissions) {
	return dispatch => {
		return api.setting.permission.employees.edit_permissions(employeeId, permissions).then(() => {
			dispatch({
				type: EDITED_PERMISSIONS,
				employeeId,
				permissions,
			})
		})
	}
}

export function toggleEdit() {
	return dispatch => {
		dispatch({ type: TOGGLE_EDIT })
	}
}

export function changePageFilter(parent, page) {
	return dispatch => {
		dispatch({ type: CHANGE_PAGE_FILTER, page })
		dispatch(refreshEmployeesWithPermissions(parent))
	}
}

export function changeLimitFilter(parent, limit) {
	return dispatch => {
		dispatch({ type: CHANGE_LIMIT_FILTER, limit })
		dispatch(refreshEmployeesWithPermissions(parent))
	}
}

export function changeNameOrNIKFilter(parent, name) {
	return dispatch => {
		dispatch({ type: CHANGE_NAME_OR_NIK_FILTER, name })
		dispatch(refreshEmployeesWithPermissions(parent))
	}
}

export function loadModule(parent) {
	return dispatch => {
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshRanks())
		dispatch(refreshAvailablePermissions(parent))
		dispatch(refreshEmployeesWithPermissions(parent))
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	refreshing: {},
	pagination: {},
	api: {},
	availablePermissions: [],
	data: {
		nameOrNIKFilter: '',
		pageFilter: 1,
		limitFilter: 25,
	},
	isEditing: false,
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_RANKS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_RANKS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHING_AVAILABLE_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					availablePermissions: true,
				},
			}
		case REFRESHING_EMPLOYEES_WITH_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_AVAILABLE_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					availablePermissions: false,
				},
				availablePermissions: action.permissions,
			}
		case REFRESHED_EMPLOYEES_WITH_PERMISSIONS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case EDITED_PERMISSIONS:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employeesWithPermissions()]: state.api[API_URLS.employeesWithPermissions()].map(
						employeeWithPermissions => {
							if (employeeWithPermissions.id === action.employeeId) {
								return {
									...employeeWithPermissions,
									permissions: action.permissions,
								}
							}
							return employeeWithPermissions
						}
					),
				},
			}
		case TOGGLE_EDIT:
			return {
				...state,
				isEditing: !state.isEditing,
			}
		case CHANGE_PAGE_FILTER:
			return {
				...state,
				data: {
					...state.data,
					pageFilter: action.page,
				},
			}
		case CHANGE_LIMIT_FILTER:
			return {
				...state,
				data: {
					...state.data,
					limitFilter: action.limit,
					pageFilter: 1,
				},
			}
		case CHANGE_NAME_OR_NIK_FILTER:
			return {
				...state,
				data: {
					...state.data,
					nameOrNIKFilter: action.name,
					pageFilter: 1,
				},
			}
		case LOAD_MODULE: {
			return {
				...state,
				data: {
					...state.data,
					nameOrNIKFilter: '',
				},
			}
		}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		case NEXT_PAGE:
			return {
				...state,
				page: state.page + 1,
			}
		case PREV_PAGE:
			return {
				...state,
				page: state.page - 1,
			}
		default:
			return state
	}
}
