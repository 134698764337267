import { s, number } from 'modules/types'
import { AppraiserType, AppraiserTypeType } from './AppraiserType'
import AppraiseeWithRankTitle, { AppraiseeWithRankTitleType } from './AppraiseeWithRankTitle'
import Session, { SessionType } from './Session'

export class SubSession {
	constructor({ id, session, appraisee, appraiser_type }) {
		this.id = id
		this.session = new Session(session)
		this.appraisee = new AppraiseeWithRankTitle(appraisee)
		this.appraiserType = new AppraiserType(appraiser_type)
	}
}

export const SubSessionType = s({
	id: number.isRequired,
	session: SessionType,
	appraisee: AppraiseeWithRankTitleType,
	appraiserType: AppraiserTypeType,
})

export default SubSession
