import { s, arrayOf, number, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class EmployeeProfile {
	constructor({
		id,
		name,
		NIK,
		picture,
		approvers,
		superior,
		department_name: departmentName,
		rank_name: rankName,
		title_name: titleName,
		group_name: groupName,
		position_name: positionName,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.picture = picture
		this.approvers = approvers.map(item => new Employee(item))
		if (superior) {
			this.superior = new Employee(superior)
		}
		this.departmentName = departmentName
		this.rankName = rankName
		this.titleName = titleName
		this.groupName = groupName
		this.positionName = positionName
	}
}

export const EmployeeProfileType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	approvers: arrayOf(EmployeeType),
	superior: EmployeeType,
	departmentName: string.isRequired,
	rankName: string.isRequired,
	titleName: string.isRequired,
	groupName: string,
	positionName: string,
})

export default EmployeeProfile
