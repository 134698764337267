import { s, string } from 'modules/types'

export class AppraiseeWithRankTitle {
	constructor({ name, NIK, rank_name: rankName, title_name: titleName }) {
		this.name = name
		this.NIK = NIK
		this.rankName = rankName
		this.titleName = titleName
	}
}

export const AppraiseeWithRankTitleType = s({
	name: string.isRequired,
	NIK: string.isRequired,
	rankName: string.isRequired,
	titleName: string.isRequired,
})

export default AppraiseeWithRankTitle
