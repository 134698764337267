import _ from 'lodash'
import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
import { push } from 'react-router-redux'
// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-timeoff'

export const API_URLS = {
	groupLeaderRoles: () => `/api/setting/timeoff/group_leader_roles/`,
	ranks: () => `/api/setting/timeoff/ranks/`,
	timeoffTypes: () => `/api/setting/timeoff/timeoff_types/`,
	timeoffSummary: () => `/api/setting/timeoff/timeoff_types/get_summary/`,
	timeoffGroups: () => `/api/setting/timeoff/timeoff_groups/`,
	timeoffGroupsWithDetail: () => `/api/setting/timeoff/timeoff_groups/with_detail/`,
	timeoffGroupDetail: groupId => `/api/setting/timeoff/timeoff_groups/${groupId}/`,
	timeoffGroupEmployees: groupId => `/api/setting/timeoff/timeoff_groups/${groupId}/employees/`,
	timeoffGroupApproversDropdown: () => `/api/setting/timeoff/timeoff_groups/get_approvers_dropdown/`,
	timeoffGroupLogs: groupId => `/api/setting/timeoff/timeoff_groups/${groupId}/timeoff_group_logs/`,
	timeoffGroupLogDetail: (groupId, logId) =>
		`/api/setting/timeoff/timeoff_groups/${groupId}/timeoff_group_logs/${logId}/`,
}

const REFRESHING_TIMEOFF_TYPES = `${MODULE_NAME} | REFRESHING_TIMEOFF_TYPES`
const REFRESHED_TIMEOFF_TYPES = `${MODULE_NAME} | REFRESHED_TIMEOFF_TYPES`
const REFRESHING_TIMEOFF_SUMMARY = `${MODULE_NAME} | REFRESHING_TIMEOFF_SUMMARY`
const REFRESHED_TIMEOFF_SUMMARY = `${MODULE_NAME} | REFRESHED_TIMEOFF_SUMMARY`
const REFRESHING_TIMEOFF_GROUPS = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUPS`
const REFRESHED_TIMEOFF_GROUPS = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUPS`
const REFRESHING_TIMEOFF_GROUPS_WITH_DETAIL = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUPS_WITH_DETAIL`
const REFRESHED_TIMEOFF_GROUPS_WITH_DETAIL = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUPS_WITH_DETAIL`
const REFRESHING_TIMEOFF_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_DETAIL`
const REFRESHED_TIMEOFF_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUP_DETAIL`
const REFRESHING_TIMEOFF_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_EMPLOYEES`
const REFRESHED_TIMEOFF_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUP_EMPLOYEES`
const REFRESHING_TIMEOFF_GROUP_APPROVERS_DROPDOWN = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_APPROVERS_DROPDOWN`
const REFRESHED_TIMEOFF_GROUP_APPROVERS_DROPDOWN = `${MODULE_NAME} | REFRESHED_TIMEOFF_GROUP_APPROVERS_DROPDOWN`
const REFRESHING_TIMEOFF_GROUP_LOGS = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_LOGS`
const REFRESHED_TIMEOFF_GROUP_LOGS = `${MODULE_NAME} | REFRESHED_TIMEOFF_TYPES_LOGS`
const REFRESHING_TIMEOFF_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHING_TIMEOFF_GROUP_LOG_DETAIL`
const REFRESHED_TIMEOFF_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHED_TIMEOFF_TYPES_LOG_DETAIL`
const REFRESHED_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHED GROUP LEADER ROLES`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED_RANKS`

const CREATED_TIMEOFF_TYPE = `${MODULE_NAME} | CREATED_TIMEOFF_TYPE`
const EDITED_TIMEOFF_TYPE = `${MODULE_NAME} | EDITED_TIMEOFF_TYPE`
const DELETED_TIMEOFF_TYPE = `${MODULE_NAME} | DELETED_TIMEOFF_TYPE`
const CREATED_TIMEOFF_GROUP = `${MODULE_NAME} | CREATED_TIMEOFF_GROUP`
const EDITED_TIMEOFF_GROUP = `${MODULE_NAME} | EDITED_TIMEOFF_GROUP`
const EDITED_TIMEOFF_GROUP_APPROVERS = `${MODULE_NAME} | EDITED_TIMEOFF_GROUP_APPROVERS`
const DELETED_TIMEOFF_GROUP = `${MODULE_NAME} | DELETED_TIMEOFF_GROUP`
const SELECT_EMPLOYEES = `${MODULE_NAME} | SELECT_EMPLOYEES`
const UNSELECT_EMPLOYEES = `${MODULE_NAME} | UNSELECT_EMPLOYEES`
const UNSELECT_ALL_EMPLOYEES = `${MODULE_NAME} | UNSELECT_ALL_EMPLOYEES`
const MOVED_EMPLOYEES = `${MODULE_NAME} | MOVED_EMPLOYEES`

const CHANGE_OPENED_GROUP = `${MODULE_NAME} | CHANGE_OPENED_GROUP`
const OPEN_GROUP_DETAIL = `${MODULE_NAME} | OPEN_GROUP_DETAIL`
const TURN_ON_EDITING_SUMMARY = `${MODULE_NAME} | TURN_ON_EDITING_SUMMARY`
const TURN_OFF_EDITING_SUMMARY = `${MODULE_NAME} | TURN_OFF_EDITING_SUMMARY`
const TURN_ON_EDITING_GROUP = `${MODULE_NAME} | TURN_ON_EDITING_GROUP`
const TURN_OFF_EDITING_GROUP = `${MODULE_NAME} | TURN_OFF_EDITING_GROUP`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshTimeoffTypes() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffTypes()
		dispatch({ type: REFRESHING_TIMEOFF_TYPES, apiUrl })
		return api.setting.timeoff.timeoff_types.get().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_TYPES, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffSummary() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffSummary()
		dispatch({ type: REFRESHING_TIMEOFF_SUMMARY, apiUrl })
		return api.setting.timeoff.timeoff_types.getSummary().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_SUMMARY, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroups() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroups()
		dispatch({ type: REFRESHING_TIMEOFF_GROUPS, apiUrl })
		return api.setting.timeoff.timeoff_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUPS, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupsWithDetail() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupsWithDetail()
		dispatch({ type: REFRESHING_TIMEOFF_GROUPS_WITH_DETAIL, apiUrl })
		return api.setting.timeoff.timeoff_groups.getWithDetail().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUPS_WITH_DETAIL, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupDetail(timeoffGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupDetail(timeoffGroupId)
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_DETAIL, apiUrl })
		return api.setting.timeoff.timeoff_groups.getDetail(timeoffGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupEmployees(timeoffGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupEmployees(timeoffGroupId)
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_EMPLOYEES, apiUrl })
		return api.setting.timeoff.timeoff_groups.getEmployees(timeoffGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_EMPLOYEES, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupApproversDropdown() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupApproversDropdown()
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_APPROVERS_DROPDOWN, apiUrl })
		return api.setting.timeoff.timeoff_groups.getApproversDropdown().then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_APPROVERS_DROPDOWN, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupLogs(timeoffGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupLogs(timeoffGroupId)
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_LOGS, apiUrl })
		return api.setting.timeoff.timeoff_groups.timeoff_group_logs.get(timeoffGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_LOGS, data, apiUrl })
			return data
		})
	}
}

function refreshTimeoffGroupLogDetail(timeoffGroupId, logId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffGroupLogDetail(timeoffGroupId, logId)
		dispatch({ type: REFRESHING_TIMEOFF_GROUP_LOG_DETAIL, apiUrl })
		return api.setting.timeoff.timeoff_groups.timeoff_group_logs.getDetail(timeoffGroupId, logId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_GROUP_LOG_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function refreshGroupLeaderRoles() {
	return dispatch => {
		const apiUrl = API_URLS.groupLeaderRoles()
		return api.setting.timeoff.group_leader_roles.get().then(({ data: roles }) => {
			dispatch({ type: REFRESHED_GROUP_LEADER_ROLES, data: roles, apiUrl })
			return roles
		})
	}
}

export function refreshRanks() {
	return dispatch => {
		const apiUrl = API_URLS.ranks()
		return api.setting.timeoff.ranks.get().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, data: ranks, apiUrl })
			return ranks
		})
	}
}

export function createTimeoffType(data) {
	return dispatch => {
		return api.setting.timeoff.timeoff_types.create(data).then(({ data: timeoffType }) => {
			dispatch({
				type: CREATED_TIMEOFF_TYPE,
				timeoffType: { ...timeoffType, allowDelete: { isAllowed: true, error: null } },
			})
			toast(`Successfully created time off`)
			return timeoffType
		})
	}
}

export function editTimeoffType(timeoffTypeId, data) {
	return dispatch => {
		return api.setting.timeoff.timeoff_types.edit(timeoffTypeId, data).then(({ data: timeoffType }) => {
			dispatch({ type: EDITED_TIMEOFF_TYPE, timeoffType })
			return timeoffType
		})
	}
}

export function deleteTimeoffType(timeoffTypeId) {
	return dispatch => {
		return api.setting.timeoff.timeoff_types.delete(timeoffTypeId).then(() => {
			dispatch(refreshTimeoffSummary())
			dispatch({ type: DELETED_TIMEOFF_TYPE, timeoffTypeId })
			toast(`Successfully deleted time off`)
		})
	}
}

export function moveUpTimeoffType(timeoffTypeId) {
	return dispatch => {
		return api.setting.timeoff.timeoff_types.moveUp(timeoffTypeId).then(() => {
			dispatch(refreshTimeoffSummary())
		})
	}
}

export function moveDownTimeoffType(timeoffTypeId) {
	return dispatch => {
		return api.setting.timeoff.timeoff_types.moveDown(timeoffTypeId).then(() => {
			dispatch(refreshTimeoffSummary())
		})
	}
}

export function createTimeoffGroup(data) {
	return dispatch => {
		return api.setting.timeoff.timeoff_groups.create(data).then(({ data: timeoffGroup }) => {
			dispatch(refreshTimeoffSummary())
			toast(`Successfully created "${timeoffGroup.name}" group`)
			dispatch(openGroupDetail(undefined))
			dispatch({ type: CREATED_TIMEOFF_GROUP, timeoffGroup })
			dispatch(refreshTimeoffGroupsWithDetail())
			dispatch(push(`/setting/timeoff/`))
			return timeoffGroup
		})
	}
}

export function editTimeoffGroup(timeoffGroupId, data) {
	return dispatch => {
		return api.setting.timeoff.timeoff_groups.edit(timeoffGroupId, data).then(({ data: timeoffGroup }) => {
			toast(`Successfully edited "${timeoffGroup.name}" group`)
			dispatch({ type: EDITED_TIMEOFF_GROUP, timeoffGroup })
			dispatch(refreshTimeoffSummary())
			dispatch(refreshTimeoffGroupsWithDetail())
			dispatch(refreshTimeoffGroupDetail(timeoffGroupId))
			dispatch(refreshTimeoffGroupLogs(timeoffGroupId))
			return timeoffGroup
		})
	}
}

export function editApprovers(timeoffGroupId, data) {
	return dispatch => {
		return api.setting.timeoff.timeoff_groups.editApprovers(data).then(({ data: editedEmployees }) => {
			let editedEmployeeIds = editedEmployees.map(x => x.id)
			let editedEmployeeMap = editedEmployees.reduce((prev, cur) => {
				prev[cur.id] = cur
				return prev
			}, {})
			dispatch({ type: EDITED_TIMEOFF_GROUP_APPROVERS, data, timeoffGroupId, editedEmployeeIds, editedEmployeeMap })
		})
	}
}

export function deleteTimeoffGroup(timeoffGroupId) {
	return dispatch => {
		return api.setting.timeoff.timeoff_groups.delete(timeoffGroupId).then(() => {
			dispatch(refreshTimeoffSummary())
			toast(`Successfully deleted group`)
			dispatch(changeOpenedGroup(undefined))
			dispatch({ type: DELETED_TIMEOFF_GROUP, groupId: timeoffGroupId })
			dispatch(push(`/setting/timeoff/`))
		})
	}
}

export function moveEmployees({ employeeIds, timeoffGroupId }) {
	return (dispatch, getState) => {
		const {
			timeoffSetting: {
				data: { openedGroupId },
			},
		} = getState()
		return api.setting.timeoff.timeoff_groups.moveEmployees({ employeeIds, timeoffGroupId }).then(() => {
			dispatch({ type: MOVED_EMPLOYEES, employeeIds })
			dispatch(refreshTimeoffSummary())
			dispatch(refreshTimeoffGroupsWithDetail())
			dispatch(refreshTimeoffGroupEmployees(timeoffGroupId))
			dispatch(refreshTimeoffGroupLogs(openedGroupId))
			dispatch(refreshTimeoffGroupLogs(timeoffGroupId))
			toast(`Successfully moved employees`)
		})
	}
}

export function selectEmployees(employeeIds) {
	return dispatch => {
		dispatch({ type: SELECT_EMPLOYEES, employeeIds })
	}
}

export function unselectEmployees(employeeIds) {
	return dispatch => {
		dispatch({ type: UNSELECT_EMPLOYEES, employeeIds })
	}
}

export function unselectAllEmployees() {
	return dispatch => {
		dispatch({ type: UNSELECT_ALL_EMPLOYEES })
	}
}

export function openGroupDetail(groupId) {
	return (dispatch, getState) => {
		// to clear openedGroupId
		if (!groupId) {
			dispatch(changeOpenedGroup(undefined))
			return null
		}
		dispatch(changeOpenedGroup(groupId))
		dispatch(push(`/setting/timeoff/${groupId}`))
		const {
			timeoffSetting: { api },
		} = getState()
		const groupDetail = getObjectOrUndefined(api, API_URLS.timeoffGroupDetail(groupId))
		const employees = getObjectOrUndefined(api, API_URLS.timeoffGroupEmployees(groupId))
		const groupLogs = getObjectOrUndefined(api, API_URLS.timeoffGroupLogs(groupId))
		const approversDropdown = getObjectOrUndefined(api, API_URLS.timeoffGroupApproversDropdown())
		if (!groupDetail) {
			dispatch(refreshTimeoffGroupDetail(groupId))
		}
		if (!employees) {
			dispatch(refreshTimeoffGroupEmployees(groupId))
		}
		if (!groupLogs) {
			dispatch(refreshTimeoffGroupLogs(groupId))
		}
		if (!approversDropdown) {
			dispatch(refreshTimeoffGroupApproversDropdown())
		}
		dispatch({ type: OPEN_GROUP_DETAIL, groupId })
	}
}

function changeOpenedGroup(groupId) {
	return dispatch => {
		dispatch({ type: CHANGE_OPENED_GROUP, groupId })
	}
}

export function turnOnEditingSummary() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING_SUMMARY })
	}
}

export function turnOffEditingSummary() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING_SUMMARY })
	}
}

export function turnOnEditingGroup() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING_GROUP })
	}
}

export function turnOffEditingGroup() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING_GROUP })
	}
}

export function fetchLogDetail(logId) {
	return (dispatch, getState) => {
		const {
			timeoffSetting: {
				data: { openedGroupId },
			},
		} = getState()
		dispatch(refreshTimeoffGroupLogDetail(openedGroupId, logId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshTimeoffSummary())
		dispatch(refreshTimeoffGroupsWithDetail())
		dispatch(refreshTimeoffGroups())
		dispatch(refreshTimeoffTypes())
		dispatch(refreshRanks())
		dispatch(refreshGroupLeaderRoles())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		isEditingGroup: false, // bool : true when group view is in editing state
		isEditingSummary: false, // bool : true when summary view is in editing state
		openedGroupId: undefined, // number : id of the selected group
		selectedEmployeeIds: [], // [number] : ids of employees to be moved to another group
	},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_TIMEOFF_TYPES:
		case REFRESHING_TIMEOFF_SUMMARY:
		case REFRESHING_TIMEOFF_GROUPS:
		case REFRESHING_TIMEOFF_GROUPS_WITH_DETAIL:
		case REFRESHING_TIMEOFF_GROUP_DETAIL:
		case REFRESHING_TIMEOFF_GROUP_EMPLOYEES:
		case REFRESHING_TIMEOFF_GROUP_APPROVERS_DROPDOWN:
		case REFRESHING_TIMEOFF_GROUP_LOGS:
		case REFRESHING_TIMEOFF_GROUP_LOG_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_TIMEOFF_TYPES:
		case REFRESHED_TIMEOFF_SUMMARY:
		case REFRESHED_TIMEOFF_GROUPS:
		case REFRESHED_TIMEOFF_GROUPS_WITH_DETAIL:
		case REFRESHED_TIMEOFF_GROUP_DETAIL:
		case REFRESHED_TIMEOFF_GROUP_EMPLOYEES:
		case REFRESHED_TIMEOFF_GROUP_APPROVERS_DROPDOWN:
		case REFRESHED_TIMEOFF_GROUP_LOGS:
		case REFRESHED_TIMEOFF_GROUP_LOG_DETAIL:
		case REFRESHED_GROUP_LEADER_ROLES:
		case REFRESHED_RANKS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		// uncomment these to update local state after each corresponding POST action
		case CREATED_TIMEOFF_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffTypes()]: [action.timeoffType, ...state.api[API_URLS.timeoffTypes()]],
					[API_URLS.timeoffSummary()]: [
						...state.api[API_URLS.timeoffSummary()],
						{
							...action.timeoffType,
							totalEmployees: 0,
							timeoffRules: [],
						},
					],
				},
			}
		case EDITED_TIMEOFF_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffTypes()]: state.api[API_URLS.timeoffTypes()].map(timeoffType => {
						if (timeoffType.id === action.timeoffType.id) {
							return action.timeoffType
						}
						return timeoffType
					}),
					[API_URLS.timeoffSummary()]: state.api[API_URLS.timeoffSummary()].map(timeoffTypeSummary => {
						if (timeoffTypeSummary.id === action.timeoffType.id) {
							return {
								...timeoffTypeSummary,
								...action.timeoffType,
							}
						}
						return timeoffTypeSummary
					}),
				},
			}
		case DELETED_TIMEOFF_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffTypes()]: state.api[API_URLS.timeoffTypes()].filter(
						timeoffType => timeoffType.id !== action.timeoffTypeId
					),
				},
			}
		// case CREATED_TIMEOFF_GROUP:
		case EDITED_TIMEOFF_GROUP_APPROVERS:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffGroupEmployees(action.timeoffGroupId)]: state.api[API_URLS.timeoffGroupEmployees(action.timeoffGroupId)].map(employee => {
						if (action.editedEmployeeIds.indexOf(employee.id) > -1) {
							return action.editedEmployeeMap[employee.id]
						} else {
							return employee
						}
					})
				}
			}
		case DELETED_TIMEOFF_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffGroupsWithDetail()]: state.api[API_URLS.timeoffGroupsWithDetail()].filter(
						group => group.id !== action.groupId
					),
					[API_URLS.timeoffGroups()]: state.api[API_URLS.timeoffGroups()].filter(group => group.id !== action.groupId),
				},
			}
		case MOVED_EMPLOYEES:
			let openedGroupDetail = state.api[API_URLS.timeoffGroupDetail(state.data.openedGroupId)]
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffGroupEmployees(state.data.openedGroupId)]: state.api[
						API_URLS.timeoffGroupEmployees(state.data.openedGroupId)
					].filter(employee => !action.employeeIds.includes(employee.id)),
					[API_URLS.timeoffGroupDetail(state.data.openedGroupId)]: {
						...openedGroupDetail,
						employeeCount: openedGroupDetail.employeeCount - action.employeeIds.length,
					},
				},
				data: {
					...state.data,
					selectedEmployeeIds: [],
				},
			}
		case SELECT_EMPLOYEES:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: [...state.data.selectedEmployeeIds, ...action.employeeIds],
				},
			}
		case UNSELECT_EMPLOYEES:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: state.data.selectedEmployeeIds.filter(
						selectedId => !action.employeeIds.includes(selectedId)
					),
				},
			}
		case UNSELECT_ALL_EMPLOYEES:
			return {
				...state,
				data: {
					...state.data,
					selectedEmployeeIds: [],
				},
			}
		case CHANGE_OPENED_GROUP:
			return {
				...state,
				data: {
					...state.data,
					openedGroupId: action.groupId,
				},
			}
		case TURN_ON_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingSummary: true,
				},
			}
		case TURN_OFF_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingSummary: false,
				},
			}
		case TURN_ON_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroup: true,
				},
			}
		case TURN_OFF_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroup: false,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
