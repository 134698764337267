import { s, number, oneOfType } from 'modules/types'
import PublicHolidayExtInfo, { PublicHolidayExtInfoType } from './PublicHolidayExtInfo'
import TimeoffExtInfo, { TimeoffExtInfoType } from './TimeoffExtInfo'
import { CALENDAR_EVENT_DATE_TYPE } from './index'

export class CalendarEventDate {
	constructor({ type, ext_info: extInfo }) {
		this.type = type
		if (type === CALENDAR_EVENT_DATE_TYPE.PUBLIC_HOLIDAY) {
			this.extInfo = new PublicHolidayExtInfo(extInfo)
		} else if (type === CALENDAR_EVENT_DATE_TYPE.TIMEOFF) {
			this.extInfo = new TimeoffExtInfo(extInfo)
		}
	}
}

export const CalendarEventDateType = s({
	type: number.isRequired,
	extInfo: oneOfType([PublicHolidayExtInfoType, TimeoffExtInfoType]),
})

export default CalendarEventDate
