import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let DemographicsComponent

const Demographics = store => {
	if (DemographicsComponent === undefined) {
		DemographicsComponent = Loadable({
			loader: () => {
				return import('views/Demographics/containers/DemographicsContainer')
			},
			loading: RouteLoader,
		})
	}
	return DemographicsComponent
}

export default Demographics
