import { s, number, string } from 'modules/types'

export class GroupEmployeeWithScoreCardFinished {
	constructor({ employee_id: employeeId, full_name: fullName, NIK, is_finished_score_card: isFinishedScoreCard }) {
		this.employeeId = employeeId
		this.fullName = fullName
		this.NIK = NIK
		this.isFinishedScoreCard = isFinishedScoreCard
	}
}

export const GroupEmployeeTypeWithScoreCardFinished = s({
	employeeId: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
})

export default GroupEmployeeWithScoreCardFinished
