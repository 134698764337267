import { s, number, object } from 'modules/types'

export class HolidayRuleGroupDetail {
	constructor({ id, start_date: startDate, end_date: endDate, type_id: typeId, date_range_id: dateRangeId }) {
		this.id = id
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
		this.typeId = typeId
		this.dateRangeId = dateRangeId
	}
}

export const HolidayRuleGroupDetailType = s({
	id: number.isRequired,
	startDate: object.isRequired,
	endDate: object.isRequired,
	typeId: number.isRequired,
	dateRangeId: number.isRequired,
})

export default HolidayRuleGroupDetail
