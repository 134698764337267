import _ from 'lodash'
import api from 'modules/api'
// import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `surveyCompleted`

const REFRESHING_COMPLETED_SUBSESSIONS = `${MODULE_NAME} | REFRESHING COMPLETED SUBSESSIONS`
const REFRESHING_COMPLETED_SUBSESSION_DETAIL = `${MODULE_NAME} | REFRESHING COMPLETED SUBSESSION DETAIL`
const REFRESHED_COMPLETED_SUBSESSIONS = `${MODULE_NAME} | REFRESHED COMPLETED SUBSESSIONS`
const REFRESHED_COMPLETED_SUBSESSION_DETAIL = `${MODULE_NAME} | REFRESHED COMPLETED SUBSESSION DETAIL`

const SELECT_SUBSESSION = `${MODULE_NAME} | SELECT SUBSESSION`

const ERROR_REFRESHING_COMPLETED_SUBSESSIONS = `${MODULE_NAME} | ERROR REFRESHING COMPLETED SUBSESSIONS`
const ERROR_REFRESHING_COMPLETED_SUBSESSION_DETAIL = `${MODULE_NAME} | ERROR REFRESHING COMPLETED SUBSESSION DETAIL`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshCompletedSessions() {
	return dispatch => {
		const apiUrl = `/api/survey/completed/`
		dispatch({ type: REFRESHING_COMPLETED_SUBSESSIONS, apiUrl })
		return api.survey.completed
			.getSurveySession()
			.then(({ success, data }) => {
				dispatch({ type: REFRESHED_COMPLETED_SUBSESSIONS, data, apiUrl })
				if (data.length > 0) {
					dispatch(selectSubSession(data[0]))
				}
			})
			.catch(err => {
				console.error(err)
				err.response.json().then(({ errors }) => {
					dispatch({ type: ERROR_REFRESHING_COMPLETED_SUBSESSIONS })
				})
			})
	}
}

export function refreshCompletedSubsessionDetail(sessionId, subSessionId) {
	return dispatch => {
		const apiUrl = `/api/survey/completed/${sessionId}/${subSessionId}/`
		dispatch({ type: REFRESHING_COMPLETED_SUBSESSION_DETAIL, apiUrl })
		return api.survey.completed
			.getSurveySubSession(sessionId, subSessionId)
			.then(({ success, data }) => {
				dispatch({ type: REFRESHED_COMPLETED_SUBSESSION_DETAIL, data, apiUrl })
			})
			.catch(err => {
				console.error(err)
				err.response.json().then(({ errors }) => {
					dispatch({ type: ERROR_REFRESHING_COMPLETED_SUBSESSION_DETAIL })
				})
			})
	}
}

export function selectSubSession(subSessionSimple) {
	return dispatch => {
		dispatch({ type: SELECT_SUBSESSION, subSessionSimple })
		dispatch(refreshCompletedSubsessionDetail(subSessionSimple.session.id, subSessionSimple.id))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshCompletedSessions())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		selectedSubSessionSimple: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_COMPLETED_SUBSESSIONS:
		case REFRESHING_COMPLETED_SUBSESSION_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_COMPLETED_SUBSESSIONS:
		case REFRESHED_COMPLETED_SUBSESSION_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case SELECT_SUBSESSION:
			return {
				...state,
				data: {
					...state.data,
					selectedSubSessionSimple: action.subSessionSimple,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
