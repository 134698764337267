import { s, string } from 'modules/types'

export class GroupSnapshot {
	constructor({ name }) {
		this.name = name
	}
}

export const GroupSnapshotType = s({
	name: string.isRequired,
})

export default GroupSnapshot
