import { s, number, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class EmployeeDetail {
	// used in /api/hr_experience/employees/ and /api/hr_experience/employees/get_unadded_employees/
	constructor({
		id,
		name,
		NIK,
		superior = null,
		group_name: groupName,
		position_name: positionName,
		// below properties exist only in /api/hr_experience/employees/
		rank_order: rankOrder = 7,
		// below properties exist only in /api/hr_experience/employees/get_unadded_employees/
		rank_id: rankId,
		position_id: positionId,
		title_id: titleId,
		superior_id: superiorId,
		department_id: departmentId,
		group_id: groupId,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		if (superior !== null) {
			this.superior = new Employee(superior)
		}
		this.groupName = groupName
		this.positionName = positionName
		this.rankOrder = rankOrder
		this.rankId = rankId
		this.positionId = positionId
		this.titleId = titleId
		this.superiorId = superiorId
		this.departmentId = departmentId
		this.groupId = groupId
	}
}

export const EmployeeDetailType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	superior: EmployeeType,
	groupName: string,
	positionName: string,
	rankOrder: number,
	rankId: number,
	positionId: number,
	titleId: number,
	superiorId: number,
	departmentId: number,
	groupId: number,
})

export default EmployeeDetail
