import { s, number, string } from 'modules/types'

export class TypeSnapshot {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

export const TypeSnapshotType = s({
	id: number.isRequired, // TimeoffType id
	name: string.isRequired,
})

export default TypeSnapshot
