import { s, string } from 'modules/types'

export class GroupLeader {
	constructor({ id, name, role_name: roleName }) {
		this.id = id
		this.name = name
		this.roleName = roleName
	}
}

export const GroupLeaderType = s({
	id: string.isRequired,
	name: string.isRequired,
	roleName: string.isRequired,
})

export default GroupLeader
