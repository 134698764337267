import { s, number, object } from 'modules/types'

export class JobDescriptionVersion {
	constructor({ id, section_count: sectionCount, created_at: createdAt }) {
		this.id = id
		this.sectionCount = sectionCount
		this.createdAt = new Date(createdAt * 1000)
	}
}

export const JobDescriptionVersionType = s({
	id: number.isRequired,
	sectionCount: number.isRequired,
	createdAt: object,
})

export default JobDescriptionVersion
