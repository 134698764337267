import { s, number, string } from 'modules/types'

export class SessionEmployee {
	constructor({ employee_id: employeeId, full_name: fullName, NIK }) {
		this.employeeId = employeeId
		this.fullName = fullName
		this.NIK = NIK
	}
}

export const SessionEmployeeType = s({
	employeeId: number.isRequired,
	fullName: string.isRequired,
	NIK: string.isRequired,
})

export default SessionEmployee
