import { s, arrayOf, number, string } from 'modules/types'
import Group, { GroupType } from './Group'
import Stage, { StageType } from './Stage'

export class SessionDetail {
	constructor({ id, name, description, categorize_by: categorizeBy, groups, stages, status }) {
		this.id = id
		this.name = name
		this.description = description
		this.categorizeBy = categorizeBy
		this.groups = groups.map(item => new Group(item))
		this.stages = stages.map(item => new Stage(item))
		this.status = status
	}
}

export const SessionDetailType = s({
	id: number.isRequired,
	name: string,
	description: string,
	categorizeBy: number.isRequired,
	groups: arrayOf(GroupType),
	stages: arrayOf(StageType),
	status: number.isRequired,
})

export default SessionDetail
