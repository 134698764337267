import { s, number } from 'modules/types'
import Question, { QuestionType } from './Question'

export class QuestionLink {
	constructor({ id, question, weight, order, default_question_link_id: defaultQuestionLinkId = null }) {
		this.id = id
		this.question = new Question(question)
		this.weight = weight
		this.order = order
		if (defaultQuestionLinkId !== null) {
			this.defaultQuestionLinkId = defaultQuestionLinkId
		}
	}
}

export const QuestionLinkType = s({
	id: number.isRequired,
	question: QuestionType,
	weight: number.isRequired,
	order: number.isRequired,
	defaultQuestionLinkId: number,
})

export default QuestionLink
