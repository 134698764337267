import Employee from './Employee'
import EmployeeDetail from './EmployeeDetail'
import EmployeeIdeaFlow from './EmployeeIdeaFlow'
import GroupLeaderRole from './GroupLeaderRole'
import IdeaFlow from './IdeaFlow'
import IdeaFlowColumn from './IdeaFlowColumn'
import IdeaRevieweeIdeaFlow from './IdeaRevieweeIdeaFlow'
import IdeaReviewer from './IdeaReviewer'
import IdeaReviewers from './IdeaReviewers'
import RankSimple from './RankSimple'

export const REVIEWER_TYPE = {
	GROUP_LEADER: 1,
	DEPARTMENT_HEAD: 2,
	CUSTOM: 3,
	DIRECT_SUPERVISOR: 4,
	RANK_SUPERVISOR: 5,
}

export default {
	Employee,
	EmployeeDetail,
	EmployeeIdeaFlow,
	GroupLeaderRole,
	IdeaFlow,
	IdeaFlowColumn,
	IdeaRevieweeIdeaFlow,
	IdeaReviewer,
	IdeaReviewers,
	RankSimple,
}
