import { s, arrayOf } from 'modules/types'
import IdeaSubscription, { IdeaSubscriptionType } from './IdeaSubscription'
import IdeaFlowWithSubscription, { IdeaFlowWithSubscriptionType } from './IdeaFlowWithSubscription'

export class Ideas {
	constructor({
		saved_idea_subscription_list: savedIdeaSubscriptionList,
		submitted_idea_subscription_list: submittedIdeaSubscriptionList,
		idea_flow_list: ideaFlowList,
	}) {
		this.savedIdeaSubscriptionList = savedIdeaSubscriptionList.map(item => new IdeaSubscription(item))
		this.submittedIdeaSubscriptionList = submittedIdeaSubscriptionList.map(item => new IdeaSubscription(item))
		this.ideaFlowList = ideaFlowList.map(item => new IdeaFlowWithSubscription(item))
	}
}

export const IdeasType = s({
	savedIdeaSubscriptionList: arrayOf(IdeaSubscriptionType),
	submittedIdeaSubscriptionList: arrayOf(IdeaSubscriptionType),
	ideaFlowList: arrayOf(IdeaFlowWithSubscriptionType),
})

export default Ideas
