import React from 'react'
import _ from 'lodash'
import { connect, DispatchProp } from 'react-redux'
import store from 'store/createStore'

export enum Language {
	EN = 'en',
	ID = 'id',
}

export type LanguageDict<T extends string, U> = { [K in T]: U }

export interface SkleemI18NDict {
	[textEnum: string]: LanguageDict<Language, any>
}

export interface SingleLanguageSkleemI18NDict {
	[textEnum: string]: any
}

interface SkleemI18NProps {
	frontendSettingLanguage: Language
}

interface WrappedComponentProps {
	translater: SingleLanguageSkleemI18NDict
}

export interface SkleemAuthStore {
	session: {
		frontendSetting: {
			language: Language
		}
	}
}

const skleemi18n = <P extends WrappedComponentProps>(
	WrappedComponent: React.ComponentType<P>,
	dict: SkleemI18NDict
) => {
	class WrappedI18NComponent extends React.Component<P & SkleemI18NProps> {
		render() {
			// check if all dict has all required language
			assertLanguageDictHasAllRequiredLanguages(WrappedComponent.toString(), dict)

			const { frontendSettingLanguage, ...restProps } = this.props
			const language = frontendSettingLanguage || 'en'
			const newDict: SingleLanguageSkleemI18NDict = makeSingleLanguageDict(dict, language)
			return <WrappedComponent translater={newDict} {...restProps as P} />
		}
	}

	const mapStateToProps = (state: P & SkleemAuthStore): SkleemI18NProps => {
		return {
			frontendSettingLanguage: state.session.frontendSetting ? state.session.frontendSetting.language : Language.ID,
		}
	}

	// @ts-ignore until https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31363 fixed
	return connect(mapStateToProps)(WrappedI18NComponent)
}

export const skleemi18nFunction = <P extends any, T extends any>(WrappedFunction: (translater: SingleLanguageSkleemI18NDict, ...rest: T[]) => P, languageDict: SkleemI18NDict) => {
	const state: any = store.getState()
	const frontendSettingLanguage = state.session.frontendSetting ? state.session.frontendSetting.language : Language.ID
	return (...rest: T[]) => {
		assertLanguageDictHasAllRequiredLanguages(WrappedFunction.toString(), languageDict)

		const language = frontendSettingLanguage || 'en'
		const newDict: SingleLanguageSkleemI18NDict = makeSingleLanguageDict(languageDict, language)
		return WrappedFunction(newDict, ...rest)
	}
}

const assertLanguageDictHasAllRequiredLanguages = (componentName: string, languageDict: SkleemI18NDict) => {
	const requiredLanguage = ['en', 'id']
	const keys: string[] = Object.keys(languageDict)

	for (let i = 0; i < keys.length; i++) {
		const isEqual = _.isEqual(Object.keys(languageDict[keys[i]]).sort(), requiredLanguage.sort())
		if (!isEqual) {
			console.log('[ERROR] component:' + componentName + ', key:' + keys[i] + ' dont have all required language')
			throw new Error('component:' + componentName + ', key:' + keys[i] + ' dont have all required language')
		}
	}
	return true
}
export const makeSingleLanguageDict = (languageDict: SkleemI18NDict, language: Language) => {
	const keys: string[] = Object.keys(languageDict)

	return keys.reduce((prev, cur) => {
		return {
			...prev,
			[cur]: languageDict[cur][language],
		}
	}, {})
}

export default skleemi18n
