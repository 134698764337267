import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'
let AppraisalOngoingComponent

const AppraisalOngoing = store => {
	if (AppraisalOngoingComponent === undefined) {
		AppraisalOngoingComponent = Loadable({
			loader: () => {
				return import('views/AppraisalOngoing/containers/AppraisalOngoingContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalOngoingComponent
}

export default AppraisalOngoing
