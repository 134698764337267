import _ from 'lodash'
import api from 'modules/api'
import { push, goBack as goToPreviousRoute } from 'react-router-redux'
import { getObjectOrUndefined } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `idea-wall`

export const API_URLS = {
	ideasInWall: () => `/api/workstation/idea/ideas/idea_wall/`,
	ideaDetailInWall: ideaId => `/api/workstation/idea/ideas/idea_wall/${ideaId}/`,
}

const REFRESHING_IDEAS_WITH_STAR = `${MODULE_NAME} | REFRESHING_IDEAS WITH STAR`
const REFRESHING_IDEA_DETAIL_WITH_STAR = `${MODULE_NAME} | REFRESHING_IDEA DETAIL WITH STAR`

const REFRESHED_IDEAS_WITH_STAR = `${MODULE_NAME} | REFRESHED IDEAS WITH STAR`
const REFRESHED_IDEA_DETAIL_WITH_STAR = `${MODULE_NAME} | REFRESHED_IDEA DETAIL WITH STAR`

const OPEN_IDEA = `${MODULE_NAME} | OPEN IDEA`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshIdeasInWall() {
	return dispatch => {
		let apiUrl = API_URLS.ideasInWall()
		dispatch({ type: REFRESHING_IDEAS_WITH_STAR, apiUrl })
		return api.workstation.idea.ideas.getInWall().then(({ data: ideas }) => {
			dispatch({ type: REFRESHED_IDEAS_WITH_STAR, data: ideas, apiUrl })
			return ideas
		})
	}
}

export function refreshIdeaDetailInWall(ideaId) {
	return dispatch => {
		let apiUrl = API_URLS.ideaDetailInWall(ideaId)
		dispatch({ type: REFRESHING_IDEA_DETAIL_WITH_STAR, apiUrl })
		return api.workstation.idea.ideas.getDetailInWall(ideaId).then(({ data: ideaDetail }) => {
			dispatch({ type: REFRESHED_IDEA_DETAIL_WITH_STAR, data: ideaDetail, apiUrl })
			return ideaDetail
		})
	}
}

export function openIdea(ideaId) {
	return (dispatch, getState) => {
		const {
			ideaWall: { api },
		} = getState()
		dispatch({ type: OPEN_IDEA, ideaId })
		const ideaDetailInWall = getObjectOrUndefined(api, API_URLS.ideaDetailInWall(ideaId))
		dispatch(push(`/dashboard/idea_wall/${ideaId}`))
		if (ideaDetailInWall === undefined) {
			dispatch(refreshIdeaDetailInWall(ideaId))
		}
	}
}

export function goBack() {
	return dispatch => {
		dispatch(goToPreviousRoute())
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshIdeasInWall())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		openedIdeaId: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_IDEA_DETAIL_WITH_STAR:
		case REFRESHED_IDEAS_WITH_STAR:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case OPEN_IDEA:
			return {
				...state,
				data: {
					...state.data,
					openedIdeaId: action.ideaId,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
