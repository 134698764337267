import { s, arrayOf, number } from 'modules/types'
import GroupStatistics, { GroupStatisticsType } from './GroupStatistics'

export class GroupStatisticsWithCount {
	constructor({ employee_count: employeeCount, category_statistics: categoryStatistics }) {
		this.employeeCount = employeeCount
		this.categoryStatistics = categoryStatistics.map(item => new GroupStatistics(item))
	}
}

export const GroupStatisticsWithCountType = s({
	employeeCount: number.isRequired,
	categoryStatistics: arrayOf(GroupStatisticsType).isRequired,
})

export default GroupStatisticsWithCount
