import { push, replace } from 'react-router-redux'
import _ from 'lodash'
import { httpGet, httpPost, toast } from '../utils'
import Pagination from 'modules/models/Pagination'
import Notification from 'modules/models/Notification'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER'
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT'
export const SET_CURRENT_USER_WEB_SETTING = 'SET_CURRENT_USER_WEB_SETTING'
export const EDIT_WEB_SETTING = 'EDIT_WEB_SETTING'
export const SET_CURRENT_USER_NOTIFICATION = 'SET_CURRENT_USER_NOTIFICATION'

export const SET_CURRENT_USER_SECONDARY_ACCOUNTS = 'SET_CURRENT_USER_SECONDARY_ACCOUNTS'

// ------------------------------------
// Actions
// ------------------------------------

let notificationRefresher

export function refreshCurrentUserWebSetting() {
	return dispatch => {
		return httpGet('/auth/get_logged_user_web_setting/').then(data => {
			dispatch(setCurrentUserWebSetting(data))
		})
	}
}

export function refreshNotifications(page = 1) {
	return dispatch => {
		return httpGet(`/auth/get_logged_user_notifications/?page=${page}`).then(data => {
			dispatch(setCurrentUserNotification(data))
		})
	}
}

export function refreshSecondaryAccounts() {
	return dispatch => {
		return httpGet(`/auth/get_secondary_accounts/`).then(({ data }) => data)
	}
}

export function fetchMoreNotifications() {
	return (dispatch, getState) => {
		let pagination = getState().session.pagination
		if (pagination.notifications) {
			return dispatch(refreshNotifications(pagination.notifications.currentPage + 1))
		}
	}
}

export function switchUser({ accountId, companyId }) {
	return (dispatch, getState) => {
		const { pathname } = getState().routing.location
		const currentModuleBasePath =
			pathname
				.split('/')
				.slice(0, 3)
				.join('/') + '/'
		let data = {
			user_id: accountId,
			company_id: companyId,
		}
		return httpPost(`/auth/switch_user/`, data).then(({ data }) => {
			localStorage.setItem('skleemAuthToken', data.token)
			dispatch(push(currentModuleBasePath)) // change URL to opened module base path before refreshing the browser
			window.location.reload()
		})
	}
}

export function changePassword({ password, repeatPassword }) {
	return dispatch => {
		let data = {
			password,
			repeat_password: repeatPassword,
		}
		return httpPost(`/auth/change_password/`, data).then(() => toast(`Successfully changed password`))
	}
}

export function refreshCurrentUser() {
	return dispatch => {
		httpGet('/auth/get_logged_user_web_setting/').then(data => {
			dispatch(setCurrentUserWebSetting(data))
		})
		if (notificationRefresher === undefined) {
			dispatch(refreshNotifications())
			notificationRefresher = setInterval(function() {
				dispatch(refreshNotifications())
			}, 60000)
		}

		return httpGet('/auth/get_logged_user/').then(data => {
			dispatch(setCurrentUser(data))
			dispatch(refreshSecondaryAccounts()).then(data => {
				dispatch(setCurrentUserSecondaryAccounts(data))
			})
		})
	}
}

export function setWebSetting(key, value) {
	return dispatch => {
		return httpPost('/auth/set_web_setting/', { key, value }).then(data => {
			dispatch({ type: EDIT_WEB_SETTING, key, value })
			return dispatch(refreshCurrentUserWebSetting())
		})
	}
}

export function logout() {
	return dispatch => {
		localStorage.removeItem('skleemAuthToken')
		dispatch({ type: USER_SIGNED_OUT })
		clearInterval(notificationRefresher)
		notificationRefresher = undefined
		dispatch(replace('/login'))
	}
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export function setCurrentUser(user) {
	return dispatch => {
		dispatch({
			type: SET_CURRENT_USER,
			currentUser: user,
		})
	}
}

export function setCurrentUserWebSetting(setting) {
	return dispatch => {
		dispatch({
			type: SET_CURRENT_USER_WEB_SETTING,
			setting,
		})
	}
}

export function setCurrentUserNotification({ data, pagination }) {
	return dispatch => {
		dispatch({
			type: SET_CURRENT_USER_NOTIFICATION,
			data,
			pagination,
		})
	}
}

export function setCurrentUserSecondaryAccounts(data) {
	return dispatch => {
		dispatch({
			type: SET_CURRENT_USER_SECONDARY_ACCOUNTS,
			data,
		})
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	currentUser: undefined,
	frontendSetting: undefined,
	notifications: [],
	pagination: {
		notifications: undefined,
	},
}

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		// case LOGGING_IN:
		//     return { ...state, error: undefined }
		case USER_SIGNED_OUT:
			return initialState
		case SET_CURRENT_USER:
			return { ...state, currentUser: action.currentUser, error: undefined }
		case SET_CURRENT_USER_WEB_SETTING:
			return { ...state, frontendSetting: action.setting }
		case EDIT_WEB_SETTING:
			return {
				...state,
				frontendSetting: {
					...state.frontendSetting,
					[action.key]: action.value,
				},
			}
		case SET_CURRENT_USER_NOTIFICATION:
			return {
				...state,
				notifications: [
					...state.notifications,
					...action.data.notifications
						.filter(notification => !_.find(state.notifications, ['id', notification.id]))
						.map(item => new Notification(item)),
				].sort((a, b) => b.id - a.id),
				pagination: {
					...state.pagination,
					notifications: new Pagination(action.pagination),
				},
			}
		case SET_CURRENT_USER_SECONDARY_ACCOUNTS:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					secondaryAccounts: action.data,
				},
			}
		// case LOGIN_ERROR:
		//     return { ...state, error: { login: action.error } }
		case CLEAR_CURRENT_USER:
			return initialState
		// case SOCKET_CONNECTED:
		//     return { ...state, socket: action.socket, channel: action.channel }
		default:
			return state
	}
}
