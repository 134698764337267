import React from 'react'

const Anchor = ({ children, ...props }) => {
	return (
		<a href={null} {...props}>
			{children}
		</a>
	)
}

export default Anchor
