import { s, number, string } from 'modules/types'

export class BrainstormReportDate {
	constructor({
		date,
		brainstorm_count: brainstormCount,
		answered_count: answeredCount,
		not_answered_count: notAnsweredCount,
	}) {
		this.date = date
		this.brainstormCount = brainstormCount
		this.answeredCount = answeredCount
		this.notAnsweredCount = notAnsweredCount
	}
}

export const BrainstormReportDateType = s({
	date: string.isRequired,
	brainstormCount: number.isRequired,
	answeredCount: number.isRequired,
	notAnsweredCount: number.isRequired,
})

export default BrainstormReportDate
