import { s, number } from 'modules/types'

export class IdeaReportSummarySecondSuperior {
	constructor({ no_response: noResponse, commented, idea_wall: ideaWall, awarded_star: awardedStar }) {
		this.noResponse = noResponse
		this.commented = commented
		this.ideaWall = ideaWall
		this.awardedStar = awardedStar
	}
}

export const IdeaReportSummarySecondSuperiorType = s({
	noResponse: number.isRequired,
	commented: number.isRequired,
	ideaWall: number.isRequired,
	awardedStar: number.isRequired,
})

export default IdeaReportSummarySecondSuperior
