import { s, number, string } from 'modules/types'

export class Employee {
	constructor({ id, name, NIK, picture }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.picture = picture
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	picture: string,
})

export default Employee
