import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let IdeaWallComponent

const IdeaWall = store => {
	if (IdeaWallComponent === undefined) {
		IdeaWallComponent = Loadable({
			loader: () => {
				return import('views/IdeaWall/containers/IdeaWallContainer')
			},
			loading: RouteLoader,
		})
	}
	return IdeaWallComponent
}

export default IdeaWall
