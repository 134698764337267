import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

// @material-ui/icons
import Menu from '@material-ui/icons/Menu'

import pagesRoutes from 'routes/pages.jsx'

import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx'

class PagesHeader extends React.Component {
	handleDrawerToggle = () => {
		this.setState({ open: !this.state.open })
	}
	getPageHeaderTitle = () => {
		if (
			window.location.host === 'nph.skleem.co' ||
			window.location.host === 'test.skleem.co' ||
			window.location.host === 'localhost:3000'
		) {
			return 'NPH'
		}
	}

	constructor(props) {
		super(props)
		this.state = {
			open: false,
		}
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
	}

	render() {
		const { classes, color } = this.props
		const appBarClasses = cx({
			[' ' + classes[color]]: color,
		})
		var list = (
			<List className={classes.list}>
				{/* Hide dashboard cause people havent logged in */}
				{/*<ListItem className={classes.listItem}>*/}
				{/*<NavLink to={"/dashboard"} className={classes.navLink}>*/}
				{/*<ListItemIcon className={classes.listItemIcon}>*/}
				{/*<Dashboard />*/}
				{/*</ListItemIcon>*/}
				{/*<ListItemText*/}
				{/*primary={"Dashboard"}*/}
				{/*disableTypography={true}*/}
				{/*className={classes.listItemText}*/}
				{/*/>*/}
				{/*</NavLink>*/}
				{/*</ListItem>*/}
				{pagesRoutes.map((prop, key) => {
					if (prop.redirect) {
						return null
					}
					const navLink =
						classes.navLink +
						cx({
							[' ' + classes.navLinkActive]: this.activeRoute(prop.path),
						})
					return (
						<ListItem key={key} className={classes.listItem}>
							<NavLink to={prop.path} className={navLink}>
								<ListItemIcon className={classes.listItemIcon}>
									<prop.icon />
								</ListItemIcon>
								<ListItemText primary={prop.short} disableTypography={true} className={classes.listItemText} />
							</NavLink>
						</ListItem>
					)
				})}
			</List>
		)
		return (
			<AppBar position="static" className={classes.appBar + appBarClasses}>
				<Toolbar className={classes.container}>
					<div className={classes.flex}>
						<Button href="#" className={classes.title}>
							{this.getPageHeaderTitle()}
						</Button>
					</div>
					<Hidden smDown implementation="css">
						{list}
					</Hidden>
					<Hidden mdUp>
						<IconButton
							justIcon
							className={classes.sidebarButton}
							color="inherit"
							aria-label="open drawer"
							onClick={this.handleDrawerToggle}
						>
							<Menu />
						</IconButton>
					</Hidden>
					<Hidden mdUp implementation="css">
						<Hidden mdUp>
							<Drawer
								variant="temporary"
								anchor={'right'}
								open={this.state.open}
								classes={{
									paper: classes.drawerPaper,
								}}
								onClose={this.handleDrawerToggle}
								ModalProps={{
									keepMounted: true, // Better open performance on mobile.
								}}
							>
								{list}
							</Drawer>
						</Hidden>
					</Hidden>
				</Toolbar>
			</AppBar>
		)
	}
}

PagesHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
}

export default withStyles(pagesHeaderStyle)(PagesHeader)
