import { s, arrayOf, number, string } from 'modules/types'
import HolidayDateRangeSummary, { HolidayDateRangeSummaryType } from './HolidayDateRangeSummary'
import AllowDelete, { AllowDeleteType } from './AllowDelete'

export class HolidayTypeSummary {
	constructor({ id, name, allow_delete: allowDelete, holiday_date_ranges: holidayDateRanges }) {
		this.id = id
		this.name = name
		this.allowDelete = new AllowDelete(allowDelete)
		this.holidayDateRanges = holidayDateRanges.map(item => new HolidayDateRangeSummary(item))
	}
}

export const HolidayTypeSummaryType = s({
	id: number.isRequired,
	name: string.isRequired,
	allowDelete: AllowDeleteType,
	holidayDateRanges: arrayOf(HolidayDateRangeSummaryType),
})

export default HolidayTypeSummary
