import { s, number, string } from 'modules/types'

export class TableHeaderContent {
	constructor({ header_type: headerType, content }) {
		this.headerType = headerType
		this.content = content
	}
}

export const TableHeaderContentType = s({
	headerType: number.isRequired,
	content: string.isRequired,
})

export default TableHeaderContent
