import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { SESSION_STATUS } from 'modules/models/survey_admin/Session'
import { STATUS_TEXT } from 'modules/models/survey_admin/SessionSimple'
import { PARTICIPANT_ORDER_DIRECTION, PARTICIPANT_ORDER_TYPE } from 'modules/models/survey_admin/Participant'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `survey_admin`
export const API_URLS = {
	exportUrl: sessionId => `/api/survey_admin/survey_report/sessions/${sessionId}/export_summary/`,
}
export const CHARTS = {
	OFF: 0, // data in table form
	PIE: 1, // pie chart
	BAR: 2, // bar chart
	COL: 3, // column chart
}

const SELECT_SESSION = `${MODULE_NAME} | SELECT SESSION`
const CREATED_SESSION = `${MODULE_NAME} | CREATED SESSION`
const COPIED_SESSION = `${MODULE_NAME} | COPIED SESSION`
const STARTED_SESSION = `${MODULE_NAME} | STARTED SESSION`
const STOPPED_SESSION = `${MODULE_NAME} | STOPPED SESSION`
const EDITED_SESSION = `${MODULE_NAME} | EDITED SESSION`
const DELETED_SESSION = `${MODULE_NAME} | DELETED SESSION`

const IS_ALL_EMPLOYEE_ADDED = `${MODULE_NAME} | IS ALL EMPLOYEE ADDED`
const ADDED_PARTICIPANTS = `${MODULE_NAME} | ADDED PARTICIPANTS`
const REMOVED_PARTICIPANTS = `${MODULE_NAME} | REMOVED PARTICIPANTS`

const CREATED_QUESTION = `${MODULE_NAME} | CREATED QUESTION`
const EDITED_QUESTION = `${MODULE_NAME} | EDITED QUESTION`
const DELETED_QUESTION = `${MODULE_NAME} | DELETED QUESTION`
const MOVED_UP_QUESTION = `${MODULE_NAME} | MOVED UP QUESTION`
const MOVED_DOWN_QUESTION = `${MODULE_NAME} | MOVED DOWN QUESTION`
const CHANGED_LINEAR_SIZE = `${MODULE_NAME} | CHANGED LINEAR SIZE`
const ADDED_ANSWER_CHOICE = `${MODULE_NAME} | ADDED ANSWER CHOICE`

const EDITED_ANSWER_CHOICE = `${MODULE_NAME} | EDITED ANSWER CHOICE`
const DELETED_ANSWER_CHOICE = `${MODULE_NAME} | DELETED ANSWER CHOICE`
const MOVED_UP_ANSWER_CHOICE = `${MODULE_NAME} | MOVED UP ANSWER CHOICE`
const MOVED_DOWN_ANSWER_CHOICE = `${MODULE_NAME} | MOVED DOWN ANSWER CHOICE`

const CHANGE_PAGE_FILTER = `${MODULE_NAME} | CHANGE PAGE FILTER`
const CHANGE_LIMIT_FILTER = `${MODULE_NAME} | CHANGE LIMIT FILTER`
const CHANGE_NAME_FILTER = `${MODULE_NAME} | CHANGE NAME FILTER`
const CHANGE_SORT_ORDER = `${MODULE_NAME} | CHANGE SORT ORDER`
const CHANGE_SORT_DIRECTION = `${MODULE_NAME} | CHANGE SORT DIRECTION`

const TOGGLE_EDIT_MODE = `${MODULE_NAME} | TOGGLE EDIT MODE`
const CHANGED_TAB = `${MODULE_NAME} | CHANGED TAB`
const CHANGED_EDITING_QUESTION = `${MODULE_NAME} | CHANGED EDITING QUESTION`
const CHANGED_INDIVIDUAL_PAGE_NUMBER = `${MODULE_NAME} | CHANGED INDIVIDUAL PAGE NUMBER`

const REFRESHING_SUMMARY = `${MODULE_NAME} | REFRESHING SUMMARY`
const REFRESHING_SUMMARY_DETAIL = `${MODULE_NAME} | REFRESHING SUMMARY DETAIL`
const REFRESHING_EXPORT_URL = `${MODULE_NAME} | REFRESHING EXPORT URL`
const REFRESHING_INDIVIDUAL = `${MODULE_NAME} | REFRESHING INDIVIDUAL`
const REFRESHING_SESSION_DETAIL = `${MODULE_NAME} | REFRESHING SESSION DETAIL`
const REFRESHING_FILTERS = `${MODULE_NAME} | REFRESHING FILTERS`
const REFRESHING_PARTICIPANTS = `${MODULE_NAME} | REFRESHING PARTICIPANTS`

const REFRESHED_INDIVIDUAL = `${MODULE_NAME} | REFRESHED INDIVIDUAL`
const REFRESHED_SUMMARY = `${MODULE_NAME} | REFRESHED SUMMARY`
const REFRESHED_SUMMARY_DETAIL = `${MODULE_NAME} | REFRESHED SUMMARY DETAIL`
const REFRESHED_EXPORT_URL = `${MODULE_NAME} | REFRESHED EXPORT URL`
const REFRESHED_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHED SESSIONS SIMPLE`
const REFRESHED_SESSION_DETAIL = `${MODULE_NAME} | REFRESHED SESSION DETAIL`
const REFRESHING_SESSIONS_SIMPLE = `${MODULE_NAME} | REFRESHING SESSIONS SIMPLE`
const REFRESHED_FILTERS = `${MODULE_NAME} | REFRESHED FILTERS`
const REFRESHED_PARTICIPANTS = `${MODULE_NAME} | REFRESHED PARTICIPANTS`

const ERROR_REFRESHING_INDIVIDUAL = `${MODULE_NAME} | ERROR REFRESHING INDIVIDUAL`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

export const SURVEY_TABS = {
	QUESTIONS: 0,
	DETAILS: 1,
	SUMMARY: 2,
	INDIVIDUAL: 3,
}

// ------------------------------------
// Actions
// ------------------------------------

export function refreshSummary(sessionId) {
	return dispatch => {
		let apiUrl = `/api/survey_admin/survey_report/sessions/${sessionId}/get_summary/`
		dispatch({ type: REFRESHING_SUMMARY, apiUrl })
		return api.survey_admin.report.getSummary(sessionId).then(({ data }) => {
			dispatch({ type: REFRESHED_SUMMARY, data, apiUrl })
		})
	}
}

export function refreshSummaryDetail(sessionId) {
	return dispatch => {
		let apiUrl = `/api/survey_admin/survey_report/sessions/${sessionId}/summary_detail/`
		dispatch({ type: REFRESHING_SUMMARY_DETAIL, apiUrl })
		return api.survey_admin.report.getSummaryDetail(sessionId).then(({ data }) => {
			dispatch({ type: REFRESHED_SUMMARY_DETAIL, data, apiUrl })
		})
	}
}

export function refreshExportUrl(sessionId) {
	return dispatch => {
		let apiUrl = API_URLS.exportUrl(sessionId)
		dispatch({ type: REFRESHING_EXPORT_URL, apiUrl })
		return api.survey_admin.report.getSummaryExportUrl(sessionId).then(({ data: url }) => {
			dispatch({ type: REFRESHED_EXPORT_URL, data: url, apiUrl })
			return url
		})
	}
}

export function refreshIndividual(sessionId, page = 1) {
	return dispatch => {
		let apiUrl = `/api/survey_admin/survey_report/sessions/${sessionId}/individual/?page=${page}`
		let paginationUrl = `/api/survey_admin/survey_report/sessions/${sessionId}/individual/`
		dispatch({ type: REFRESHING_INDIVIDUAL, apiUrl })
		return api.survey_admin.report
			.getIndividual(sessionId, page)
			.then(({ data, pagination }) => {
				dispatch({ type: REFRESHED_INDIVIDUAL, data, apiUrl, pagination, paginationUrl })
			})
			.catch(err => {
				dispatch({ type: ERROR_REFRESHING_INDIVIDUAL })
			})
	}
}

export function refreshSessionsSimple() {
	return (dispatch, getState) => {
		let apiUrl = `/api/survey_admin/survey_session/sessions/simple/`
		dispatch({ type: REFRESHING_SESSIONS_SIMPLE, apiUrl })
		return api.survey_admin.sessions.get().then(({ data }) => {
			const {
				surveyAdmin: {
					data: { selectedSessionId },
				},
			} = getState()
			dispatch({ type: REFRESHED_SESSIONS_SIMPLE, data, apiUrl })
			if (data.length > 0 && !selectedSessionId) {
				dispatch(selectSession(data[0]))
			}
			return data
		})
	}
}

export function refreshSessionDetail(sessionId) {
	return dispatch => {
		let apiUrl = `/api/survey_admin/survey_session/sessions/${sessionId}/`
		dispatch({ type: REFRESHING_SESSION_DETAIL, apiUrl })
		return api.survey_admin.sessions.getSessionDetail(sessionId).then(({ data }) => {
			dispatch({ type: REFRESHED_SESSION_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function refreshFilters(sessionId) {
	return dispatch => {
		let apiUrl = `/api/survey_admin/survey_session/sessions/${sessionId}/get_filters/`
		dispatch({ type: REFRESHING_FILTERS, apiUrl })
		return api.survey_admin.sessions.getFilters(sessionId).then(({ data }) => {
			dispatch({ type: REFRESHED_FILTERS, data, apiUrl })
			return data
		})
	}
}

export function refreshParticipants(sessionId) {
	return (dispatch, getState) => {
		let apiUrl = `/api/survey_admin/survey_session/sessions/${sessionId}/participants/`
		let state = getState().surveyAdmin
		let args = {}
		if (state.data.nameFilter) {
			args.name = state.data.nameFilter
		}
		if (state.data.pageFilter) {
			args.page = state.data.pageFilter
		}
		if (state.data.limitFilter) {
			args.limit = state.data.limitFilter
		}
		if (state.data.sortOrder !== undefined) {
			args.order_type = state.data.sortOrder
		}
		if (state.data.sortDirection !== undefined) {
			args.order_direction = state.data.sortDirection
		}
		let params = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (params) params = `?${params}`
		dispatch({ type: REFRESHING_PARTICIPANTS, apiUrl })
		return api.survey_admin.sessions.participants(sessionId, params).then(({ data, pagination }) => {
			dispatch({ type: REFRESHED_PARTICIPANTS, data, pagination, apiUrl })
		})
	}
}

export function changeNameFilter(name) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch({ type: CHANGE_NAME_FILTER, name })
		dispatch(changePageFilter(1))
		dispatch(refreshParticipants(selectedSessionId))
	}
}

export function changeLimitFilter(limit) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch({ type: CHANGE_LIMIT_FILTER, limit })
		dispatch(refreshParticipants(selectedSessionId))
	}
}

export function changePageFilter(page) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch({ type: CHANGE_PAGE_FILTER, page })
		dispatch(refreshParticipants(selectedSessionId))
	}
}

export function changeSortDirection(direction = PARTICIPANT_ORDER_DIRECTION.ASCENDING) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch({ type: CHANGE_SORT_DIRECTION, direction })
		dispatch(refreshParticipants(selectedSessionId))
	}
}

export function changeSortOrder(orderType = PARTICIPANT_ORDER_TYPE.NAME) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch({ type: CHANGE_SORT_ORDER, orderType })
		dispatch(refreshParticipants(selectedSessionId))
	}
}

export function selectSession(session) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { isEditMode },
			},
		} = getState()
		dispatch(refreshSessionDetail(session.id))
		dispatch({ type: SELECT_SESSION, sessionId: session.id })
		if (session.statusText === STATUS_TEXT.ONGOING) {
			dispatch(changeTab(SURVEY_TABS.DETAILS))
			if (isEditMode) {
				dispatch(toggleEditMode())
			}
		} else if (session.statusText === STATUS_TEXT.DRAFT && !isEditMode) {
			dispatch(toggleEditMode())
		} else if (session.statusText === STATUS_TEXT.ENDED) {
			dispatch(changeTab(SURVEY_TABS.SUMMARY))
			if (isEditMode) {
				dispatch(toggleEditMode())
			}
		}
	}
}

export function createSession(session) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { isEditMode },
			},
		} = getState()
		return api.survey_admin.sessions.create(session).then(({ data }) => {
			dispatch({ type: CREATED_SESSION, data })
			dispatch(refreshSessionsSimple())
			dispatch(selectSession(data))
			toast(`New survey created`)
			if (!isEditMode) {
				dispatch(toggleEditMode())
			}
			return data
		})
	}
}

export function copySession(session) {
	return dispatch => {
		return api.survey_admin.sessions.createCopy(session.id).then(({ data }) => {
			dispatch({ type: COPIED_SESSION, data })
			dispatch(refreshSessionsSimple())
			toast(`Successfully copied "${session.name}" survey`)
		})
	}
}

export function startSession(sessionId) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { isEditMode },
			},
		} = getState()
		return api.survey_admin.sessions.start(sessionId).then(() => {
			dispatch({ type: STARTED_SESSION })
			toast('Survey started')
			if (isEditMode) {
				dispatch(toggleEditMode())
			}
			dispatch(refreshSessionsSimple())
			return dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function stopSession(sessionId) {
	return dispatch => {
		return api.survey_admin.sessions.stop(sessionId).then(() => {
			dispatch({ type: STOPPED_SESSION })
			toast('Survey stopped')
			dispatch(refreshSessionsSimple())
			return dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function editSession(sessionId, session) {
	return dispatch => {
		return api.survey_admin.sessions.edit(sessionId, session).then(({ data }) => {
			dispatch({ type: EDITED_SESSION, data })
			toast(`Edited "${session.name}" survey`)
			dispatch(refreshSessionsSimple())
			return dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function deleteSession(session) {
	return dispatch => {
		return api.survey_admin.sessions.delete(session.id).then(() => {
			dispatch({ type: DELETED_SESSION })
			toast(`Successfully deleted "${session.name}" survey`)
			return dispatch(refreshSessionsSimple()).then(sessions => {
				if (sessions.length > 0) {
					dispatch(selectSession(sessions[0]))
				}
			})
		})
	}
}

export function addParticipants(sessionId, employeeIds) {
	return dispatch => {
		return api.survey_admin.sessions.addParticipants(sessionId, employeeIds).then(() => {
			dispatch({ type: ADDED_PARTICIPANTS })
			dispatch(refreshParticipants(sessionId))
		})
	}
}

export function removeParticipants(sessionId, employeeIds) {
	return dispatch => {
		return api.survey_admin.sessions.removeParticipants(sessionId, employeeIds).then(() => {
			dispatch({ type: REMOVED_PARTICIPANTS })
			dispatch(refreshParticipants(sessionId))
		})
	}
}

export function createQuestion(sessionId, question) {
	return dispatch => {
		return api.survey_admin.sessions.createQuestion(sessionId, question).then(({ data }) => {
			dispatch({ type: CREATED_QUESTION })
			dispatch(refreshSessionDetail(sessionId))
			toast(`Created "${question.alternativeContent}" question`)
			return data
		})
	}
}

export function editQuestion(sessionId, questionId, data) {
	return dispatch => {
		return api.survey_admin.sessions.questions.edit(sessionId, questionId, data).then(() => {
			dispatch({ type: EDITED_QUESTION })
			dispatch(refreshSessionDetail(sessionId))
			toast(`Edited "${data.alternativeContent}" question`)
		})
	}
}

export function deleteQuestion(sessionId, question) {
	return dispatch => {
		return api.survey_admin.sessions.questions.delete(sessionId, question.id).then(() => {
			dispatch({ type: DELETED_QUESTION })
			dispatch(refreshSessionDetail(sessionId))
			toast(`Deleted "${question.alternativeContent}" question`)
		})
	}
}

export function moveUpQuestion(sessionId, questionId) {
	return dispatch => {
		return api.survey_admin.sessions.questions.moveUp(sessionId, questionId).then(() => {
			dispatch({ type: MOVED_UP_QUESTION })
			dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function moveDownQuestion(sessionId, questionId) {
	return dispatch => {
		return api.survey_admin.sessions.questions.moveDown(sessionId, questionId).then(() => {
			dispatch({ type: MOVED_DOWN_QUESTION })
			dispatch(refreshSessionDetail(sessionId))
		})
	}
}

export function changeLinearSize(sessionId, questionId, linearSize, disabledChoices) {
	return dispatch => {
		return api.survey_admin.sessions.questions
			.changeLinearSize(sessionId, questionId, linearSize, disabledChoices)
			.then(({ data }) => {
				dispatch({ type: CHANGED_LINEAR_SIZE, data })
				dispatch(refreshSessionDetail(sessionId))
				return data
			})
	}
}

export function addAnswerChoice(sessionId, questionId, { content, disabled }) {
	let data = { content, disabled }

	return dispatch => {
		return api.survey_admin.sessions.questions.addAnswerChoice(sessionId, questionId, data).then(({ data }) => {
			dispatch({ type: ADDED_ANSWER_CHOICE, data })
			return data
		})
	}
}

export function editAnswerChoice(sessionId, questionId, answerChoiceId, { content, disabled }) {
	let data = { content, disabled }
	return dispatch => {
		return api.survey_admin.sessions.questions.answer_choices
			.edit(sessionId, questionId, answerChoiceId, data)
			.then(({ data }) => {
				dispatch({ type: EDITED_ANSWER_CHOICE, data })
				dispatch(refreshSessionDetail(sessionId))
				return data
			})
	}
}

export function deleteAnswerChoice(sessionId, questionId, answerChoiceId) {
	return dispatch => {
		return api.survey_admin.sessions.questions.answer_choices.delete(sessionId, questionId, answerChoiceId).then(() => {
			dispatch({ type: DELETED_ANSWER_CHOICE })
		})
	}
}

export function moveUpAnswerChoice(sessionId, questionId, answerChoiceId) {
	return dispatch => {
		return api.survey_admin.sessions.questions.answer_choices
			.moveUp(sessionId, questionId, answerChoiceId)
			.then(({ data }) => {
				dispatch({ type: MOVED_UP_ANSWER_CHOICE, data })
				return data
			})
	}
}

export function moveDownAnswerChoice(sessionId, questionId, answerChoiceId) {
	return dispatch => {
		return api.survey_admin.sessions.questions.answer_choices
			.moveDown(sessionId, questionId, answerChoiceId)
			.then(({ data }) => {
				dispatch({ type: MOVED_DOWN_ANSWER_CHOICE, data })
				return data
			})
	}
}

export function toggleEditMode() {
	return dispatch => {
		dispatch({ type: TOGGLE_EDIT_MODE })
	}
}

export function changeTab(tab) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				api,
				data: { selectedSessionId },
			},
		} = getState()
		let selectedSessionDetail = api[`/api/survey_admin/survey_session/sessions/${selectedSessionId}/`]
		if (tab === SURVEY_TABS.DETAILS) {
			dispatch(refreshFilters(selectedSessionId))
			dispatch(refreshParticipants(selectedSessionId))
		} else if (tab === SURVEY_TABS.SUMMARY) {
			dispatch(refreshSummary(selectedSessionId))
			dispatch(refreshSummaryDetail(selectedSessionId))
		} else if (
			tab === SURVEY_TABS.INDIVIDUAL &&
			selectedSessionDetail &&
			selectedSessionDetail.session.status !== SESSION_STATUS.DRAFT
		) {
			dispatch(refreshIndividual(selectedSessionId))
		}
		dispatch({ type: CHANGED_TAB, tab })
	}
}

export function changeEditingQuestion(questionId) {
	return dispatch => {
		dispatch({ type: CHANGED_EDITING_QUESTION, questionId })
	}
}

export function changeIndividualPage(page) {
	return (dispatch, getState) => {
		const {
			surveyAdmin: {
				data: { selectedSessionId },
			},
		} = getState()
		dispatch(refreshIndividual(selectedSessionId, page))
		dispatch({ type: CHANGED_INDIVIDUAL_PAGE_NUMBER, page })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshSessionsSimple())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		isEditMode: false,
		selectedSessionId: undefined,
		activeTab: SURVEY_TABS.QUESTIONS, // @SURVEY_TABS
		editingQuestionId: undefined,
		individualPage: 1,
		limitFilter: 25,
		pageFilter: 1,
		nameFilter: '',
		sortOrder: PARTICIPANT_ORDER_TYPE.NAME,
		sortDirection: PARTICIPANT_ORDER_DIRECTION.ASCENDING,
	},
	pagination: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_SESSIONS_SIMPLE:
		case REFRESHING_SESSION_DETAIL:
		case REFRESHING_FILTERS:
		case REFRESHING_PARTICIPANTS:
		case REFRESHING_SUMMARY:
		case REFRESHING_SUMMARY_DETAIL:
		case REFRESHING_EXPORT_URL:
		case REFRESHING_INDIVIDUAL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_SESSIONS_SIMPLE:
		case REFRESHED_SESSION_DETAIL:
		case REFRESHED_FILTERS:
		case REFRESHED_SUMMARY:
		case REFRESHED_SUMMARY_DETAIL:
		case REFRESHED_EXPORT_URL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_INDIVIDUAL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.paginationUrl]: action.pagination,
				},
			}
		case REFRESHED_PARTICIPANTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case ERROR_REFRESHING_INDIVIDUAL:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: [],
				},
			}
		case SELECT_SESSION:
			return {
				...state,
				data: {
					...state.data,
					activeTab: SURVEY_TABS.QUESTIONS,
					selectedSessionId: action.sessionId,
					sortDirection: PARTICIPANT_ORDER_DIRECTION.ASCENDING,
					sortOrder: PARTICIPANT_ORDER_TYPE.NAME,
					pageFilter: 1,
					nameFilter: '',
				},
			}
		case CHANGE_NAME_FILTER:
			return {
				...state,
				data: {
					...state.data,
					nameFilter: action.name,
					pageFilter: 1,
				},
			}
		case CHANGE_PAGE_FILTER:
			return {
				...state,
				data: {
					...state.data,
					pageFilter: action.page,
				},
			}
		case CHANGE_LIMIT_FILTER:
			return {
				...state,
				data: {
					...state.data,
					limitFilter: action.limit,
					pageFilter: 1,
				},
			}
		case CHANGE_SORT_DIRECTION:
			return {
				...state,
				data: {
					...state.data,
					sortDirection: action.direction,
					pageFilter: 1,
				},
			}
		case CHANGE_SORT_ORDER:
			return {
				...state,
				data: {
					...state.data,
					sortOrder: action.orderType,
					sortDirection: PARTICIPANT_ORDER_DIRECTION.ASCENDING,
					pageFilter: 1,
				},
			}
		case TOGGLE_EDIT_MODE:
			return {
				...state,
				data: {
					...state.data,
					isEditMode: !state.data.isEditMode,
				},
			}
		case CHANGED_TAB:
			return {
				...state,
				data: {
					...state.data,
					activeTab: action.tab,
				},
			}
		case CHANGED_EDITING_QUESTION:
			return {
				...state,
				data: {
					...state.data,
					editingQuestionId: action.questionId,
				},
			}
		case CHANGED_INDIVIDUAL_PAGE_NUMBER:
			return {
				...state,
				data: {
					...state.data,
					individualPage: action.page,
				},
			}
		case IS_ALL_EMPLOYEE_ADDED:
			return {
				...state,
				data: {
					...state.data,
					departmentFilter: undefined,
					groupFilter: undefined,
					superiorFilter: undefined,
					titleFilter: undefined,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
