export const errorDict = {
	'Template must have at least 1 question': {
		id: 'Minimal 1 pertanyaan dalam survei untuk memulai survei',
		en: 'Survey must have at least 1 question',
	},
	'Survey end date has passed': {
		id: 'Tanggal akhir survei telah berlalu',
		en: 'Survey end date has passed',
	},
	'Session does not exist': {
		id: 'Survei tidak ada',
		en: 'Survey does not exist',
	},
	'No audience in the survey': {
		id: 'Tidak ada responden survei',
		en: 'No audience in the survey',
	},
	'Question does not exist': {
		id: 'Pertanyaan tidak ada',
		en: 'Question does not exist',
	},
	'Appraiser type is not valid': {
		id: 'Tipe penilai tidak valid',
		en: 'Appraiser type is not valid',
	},
	'Disabled choices content must be integer': {
		id: 'Konten pilihan nonaktif wajib bilangan bulat',
		en: 'Disabled choices content must be integer',
	},
	'Question link does not exist': {
		id: 'Link pertanyaan tidak ada',
		en: 'Question link does not exist',
	},
	'Answer choice does not exist': {
		id: 'Pilihan pertanyaan tidak ada',
		en: 'Answer choice does not exist',
	},
	'Question must be linear or radio to have answer choice': {
		id: 'Pilihan hanya dapat ditambah pada pertanyaan bertipe linear atau radio',
		en: 'Question must be linear or radio to have answer choice',
	},
	'Category permission used is not the same': {
		id: 'Akses kategori pertanyaan untuk karyawan yang akan dipindah berbeda',
		en: 'Category permission used is not the same',
	},
	'Group already has answer book': {
		id: 'Karyawan dalam grup telah menjawab penilaian. Tidak bisa mengganti template',
		en: 'Employees in the group has answered this session. Unable to change template',
	},
	'Timeoff request is still pending': {
		id: 'Permohonan cuti ini masih menunggu persetujuan',
		en: 'This time off request is still waiting for approval',
	},
	'Subsession does not exist': {
		id: 'Sesi penilaian tidak ditemukan',
		en: 'Appraisal not found',
	},
	'No data available for that time': {
		id: 'Data tidak ditemukan untuk waktu tersebut',
		en: 'No data available for that time',
	},
}

export const errorRegexes = [
	{
		regex: /Question "(.*)" must have at least 1 answer choice/,
		translation: {
			id: params => {
				return `Pertanyaan "${params[0]}" wajib mempunyai setidaknya 1 pilihan`
			},
			en: params => {
				return `Question "${params[0]}" must have at least 1 answer choice`
			},
		},
	},
	{
		regex: /(.*) is already an appraisee/,
		translation: {
			id: params => {
				return `${params[0]} telah menjadi responden`
			},
			en: params => {
				return `${params[0]} is already a respondent`
			},
		},
	},
	{
		regex: /Answer for question number (.*) is invalid/,
		translation: {
			id: params => {
				return `Pertanyaan nomor ${params[0]} belum dijawab`
			},
			en: params => {
				return `You have not answered question number ${params[0]}`
			},
		},
	},
]
