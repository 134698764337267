import { s, arrayOf, number, string } from 'modules/types'
import CreateDefaultTemplateQuestionLink, {
	CreateDefaultTemplateQuestionLinkType,
} from './CreateDefaultTemplateQuestionLink'

export class CreateDefaultTemplateCategory {
	constructor({
		name,
		questionLinks: question_links,
		viewPermissions: view_permissions,
		answerPermissions: answer_permissions,
	}) {
		this.name = name
		this.question_links = question_links.map(questionLink => new CreateDefaultTemplateQuestionLink(questionLink))
		this.view_permissions = view_permissions
		this.answer_permissions = answer_permissions
	}
}

export const CreateDefaultTemplateCategoryType = s({
	name: string.isRequired,
	question_links: arrayOf(CreateDefaultTemplateQuestionLinkType),
	view_permissions: arrayOf(number), // @APPRAISAL_CATEGORY_VIEW_PERMISSION
	answer_permissions: arrayOf(number), // @APPRAISAL_CATEGORY_ANSWER_PERMISSION
})

export default CreateDefaultTemplateCategory
