import { s, number, string } from 'modules/types'

export class Permission {
	constructor({ id, name, description, type }) {
		this.id = id
		this.name = name
		this.description = description
		this.type = type
	}
}

export const PermissionType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	type: number.isRequired, // @PERMISSION_TYPE
})

export default Permission
