import { s, number, string } from 'modules/types'
import AnnouncementCreator from 'modules/models/announcement/AnnouncementCreator'

export class Announcement {
	constructor({ id, title, created_by: createdBy, attachment_url: attachmentUrl }) {
		this.id = id
		this.title = title
		this.createdBy = new AnnouncementCreator(createdBy)
		this.attachmentUrl = attachmentUrl
	}
}

export const AnnouncementType = s({
	id: number.isRequired,
	title: string,
})

export default Announcement
