import { string, s, number } from 'modules/types'
import AnswerChoice from './AnswerChoice'

export const QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
}

export class Question {
	constructor({
		id,
		self_content: selfContent,
		alternative_content: altContent,
		type,
		linear_lowest_label: linearLowestLabel,
		linear_highest_label: linearHighestLabel,
		choices,
	}) {
		this.id = id
		this.selfContent = selfContent
		this.alternativeContent = altContent
		this.type = type
		this.linearLowestLabel = linearLowestLabel
		this.linearHighestLabel = linearHighestLabel
		if (choices) {
			this.choices = choices.map(item => new AnswerChoice(item))
		}
	}
}

export const QuestionType = s({
	id: number.isRequired,
	selfContent: string.isRequired,
	alternativeContent: string.isRequired,
	type: number.isRequired, // @QUESTION_TYPE
	linearLowestLabel: string,
	linearHighestLabel: string,
})

export default Question
