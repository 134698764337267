import { s, number, string } from 'modules/types'

export class EmployeeWithPicture {
	constructor({ employee_id: employeeId, NIK, full_name: fullName, picture }) {
		this.employeeId = employeeId
		this.NIK = NIK
		this.fullName = fullName
		this.picture = picture
	}
}

export const EmployeeWithPictureType = s({
	employeeId: number.isRequired,
	NIK: string.isRequired,
	fullName: string.isRequired,
	picture: string,
})

export default EmployeeWithPicture
