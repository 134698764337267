import { s, number, string } from 'modules/types'

import Home from '@material-ui/icons/Home'
import Info from '@material-ui/icons/Info'
import QuestionAnswer from '@material-ui/icons/QuestionAnswer'
import ShowChart from '@material-ui/icons/ShowChart'
import Whatshot from '@material-ui/icons/Whatshot'
import Work from '@material-ui/icons/Work'

export const NOTIFICATION_TYPE = {
	NO_HANDLER: 0,
	NOTIFICATION_IDEA_STAR: 3,
	NOTIFICATION_IDEA_LIKE: 4,
	NOTIFICATION_IDEA_COMMENT: 7,
	NOTIFICATION_IDEA_FLOWER: 8,
	NOTIFICATION_IDEA_IDEA_WALL: 9,
	NOTIFICATION_GROUP_BRAINSTORM: 101,
	NOTIFICATION_ALL_BRAINSTORM: 102,
	NOTIFICATION_ANSWER_BRAINSTORM: 201,
	NOTIFICATION_TELEGRAM_REQUEST_HANDLER: 301,
	NOTIFICATION_APPROVED_TELEGRAM_REQUEST_HANDLER: 302,
	NOTIFICATION_FORWARD_IDEA: 401,
	NOTIFICATION_SUBORDINATE_BIRTHDAY: 501,
	NOTIFICATION_JOB_DESCRIPTION_CHANGED: 601,
	NOTIFICATION_EMPLOYMENT_HISTORY: 701,
}

export class Notification {
	constructor({ id, short_content: shortContent, type }) {
		this.id = id
		this.shortContent = shortContent
		this.type = type
		this.icon = this.getIcon()
	}

	getRedirectURL() {
		switch (this.type) {
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_STAR:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_LIKE:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_COMMENT:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_FLOWER:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_IDEA_WALL:
			case NOTIFICATION_TYPE.NOTIFICATION_FORWARD_IDEA:
				return '/workstation/idea/'
			case NOTIFICATION_TYPE.NOTIFICATION_JOB_DESCRIPTION_CHANGED:
			case NOTIFICATION_TYPE.NOTIFICATION_EMPLOYMENT_HISTORY:
				return '/workstation/job_information/'
			case NOTIFICATION_TYPE.NOTIFICATION_GROUP_BRAINSTORM:
			case NOTIFICATION_TYPE.NOTIFICATION_ALL_BRAINSTORM:
			case NOTIFICATION_TYPE.NOTIFICATION_ANSWER_BRAINSTORM:
				return '/dashboard/brainstorming/'
			case NOTIFICATION_TYPE.NOTIFICATION_TELEGRAM_REQUEST_HANDLER:
				return '/human_resources/engagement/'
			case NOTIFICATION_TYPE.NOTIFICATION_SUBORDINATE_BIRTHDAY:
				return ''
			case NOTIFICATION_TYPE.NOTIFICATION_APPROVED_TELEGRAM_REQUEST_HANDLER:
			case NOTIFICATION_TYPE.NO_HANDLER:
			default:
				return null
		}
	}

	getIcon() {
		switch (this.type) {
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_STAR:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_LIKE:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_COMMENT:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_FLOWER:
			case NOTIFICATION_TYPE.NOTIFICATION_IDEA_IDEA_WALL:
			case NOTIFICATION_TYPE.NOTIFICATION_FORWARD_IDEA:
				return Whatshot
			case NOTIFICATION_TYPE.NOTIFICATION_JOB_DESCRIPTION_CHANGED:
			case NOTIFICATION_TYPE.NOTIFICATION_EMPLOYMENT_HISTORY:
				return Work
			case NOTIFICATION_TYPE.NOTIFICATION_GROUP_BRAINSTORM:
			case NOTIFICATION_TYPE.NOTIFICATION_ALL_BRAINSTORM:
			case NOTIFICATION_TYPE.NOTIFICATION_ANSWER_BRAINSTORM:
				return QuestionAnswer
			case NOTIFICATION_TYPE.NOTIFICATION_TELEGRAM_REQUEST_HANDLER:
				return ShowChart
			case NOTIFICATION_TYPE.NOTIFICATION_SUBORDINATE_BIRTHDAY:
				return Home
			case NOTIFICATION_TYPE.NOTIFICATION_APPROVED_TELEGRAM_REQUEST_HANDLER:
			case NOTIFICATION_TYPE.NO_HANDLER:
			default:
				return Info
		}
	}
}

export const NotificationType = s({
	id: number.isRequired,
	shortContent: string.isRequired,
	type: number.isRequired, // @NOTIFICATION_TYPE
})

export default Notification
