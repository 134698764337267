import { s, string, number } from 'modules/types'

export class Rank {
	constructor({ id, name, employee_count: employeeCount, level }) {
		this.id = id
		this.name = name
		this.employeeCount = employeeCount
		this.level = level
	}
}

export const RankType = s({
	id: number.isRequired,
	name: string.isRequired,
	employeeCount: number.isRequired,
	level: number.isRequired,
})

export default Rank
