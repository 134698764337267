import { s, number } from 'modules/types'

export class BrainstormAudienceSummaryReport {
	constructor({ no_response: noResponse, commented }) {
		this.noResponse = noResponse
		this.commented = commented
	}
}

export const BrainstormAudienceSummaryReportType = s({
	noResponse: number.isRequired,
	commented: number.isRequired,
})

export default BrainstormAudienceSummaryReport
