import { s, number, object } from 'modules/types'

class EmployeeCountDetailDay {
	constructor({ date, in_count: inCount, out_count: outCount, rejoin_count: rejoinCount }) {
		this.date = new Date(date)
		this.inCount = inCount
		this.outCount = outCount
		this.rejoinCount = rejoinCount
	}
}

export const EmployeeCountDetailDayType = s({
	date: object.isRequired,
	inCount: number.isRequired,
	outCount: number.isRequired,
	rejoinCount: number.isRequired,
})

export default EmployeeCountDetailDay
