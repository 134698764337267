import { s, number } from 'modules/types'

export class WorkingCycleRule {
	constructor({ id, type_id: typeId = null, day }) {
		this.id = id
		if (typeId !== null) {
			this.typeId = typeId
		}
		this.day = day
	}
}

export const WorkingCycleRuleType = s({
	id: number.isRequired,
	typeId: number,
	day: number.isRequired,
})

export default WorkingCycleRule
