import _ from 'lodash'
import api from 'modules/api'
import { format, toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `hr-timeoff`

export const API_URLS = {
	timeoffFeeds: () => `/api/hr_timeoff/timeoff_feeds/`,
	timeoffCalendar: () => `/api/hr_timeoff/timeoff_calendar/`,
	calendarFilters: () => `/api/hr_timeoff/timeoff_calendar/get_filters/`,
	allRequests: () => `/api/hr_timeoff/all_requests/`,
	requestFilters: () => `/api/hr_timeoff/all_requests/get_filters/`,
	timeoffRequestById: timeoffRequestById => `/api/hr_timeoff/timeoff_requests/${timeoffRequestById}/`,

	employeeProfile: employeeId => `/api/hr_timeoff/employee_profile/${employeeId}/`,
	profileBalances: employeeId => `/api/hr_timeoff/employee_profile/${employeeId}/balances/`,
	profileCreateTimeoffBalances: employeeId =>
		`/api/hr_timeoff/employee_profile/${employeeId}/create_timeoff/get_balances/`,
	profileActivities: employeeId => `/api/hr_timeoff/employee_profile/${employeeId}/activities/`,
	profileTimeoffTypes: employeeId => `/api/hr_timeoff/employee_profile/${employeeId}/activities/get_timeoff_types/`,
	profileRequests: employeeId => `/api/hr_timeoff/employee_profile/${employeeId}/requests/`,
}

const REFRESHING_TIMEOFF_FEEDS = `${MODULE_NAME} | REFRESHING_TIMEOFF_FEEDS`
const REFRESHED_TIMEOFF_FEEDS = `${MODULE_NAME} | REFRESHED_TIMEOFF_FEEDS`
const REFRESHING_TIMEOFF_CALENDAR = `${MODULE_NAME} | REFRESHING_TIMEOFF_CALENDAR`
const REFRESHED_TIMEOFF_CALENDAR = `${MODULE_NAME} | REFRESHED_TIMEOFF_CALENDAR`
const REFRESHING_CALENDAR_FILTERS = `${MODULE_NAME} | REFRESHING_CALENDAR_FILTERS`
const REFRESHED_CALENDAR_FILTERS = `${MODULE_NAME} | REFRESHED_CALENDAR_FILTERS`
const REFRESHING_ALL_REQUESTS = `${MODULE_NAME} | REFRESHING_ALL_REQUESTS`
const REFRESHED_ALL_REQUESTS = `${MODULE_NAME} | REFRESHED_ALL_REQUESTS`
const REFRESHING_REQUEST_FILTERS = `${MODULE_NAME} | REFRESHING_REQUEST_FILTERS`
const REFRESHED_REQUEST_FILTERS = `${MODULE_NAME} | REFRESHED_REQUEST_FILTERS`
const REFRESHING_TIMEOFF_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHING_TIMEOFF_REQUEST_BY_ID`
const REFRESHED_TIMEOFF_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHED_TIMEOFF_REQUEST_BY_ID`

const REFRESHING_EMPLOYEE_PROFILE = `${MODULE_NAME} | REFRESHING_EMPLOYEE_PROFILE`
const REFRESHED_EMPLOYEE_PROFILE = `${MODULE_NAME} | REFRESHED_EMPLOYEE_PROFILE`
const REFRESHING_PROFILE_BALANCES = `${MODULE_NAME} | REFRESHING_PROFILE_BALANCES`
const REFRESHED_PROFILE_BALANCES = `${MODULE_NAME} | REFRESHED_PROFILE_BALANCES`
const REFRESHING_PROFILE_CREATE_TIMEOFF_BALANCES = `${MODULE_NAME} | REFRESHING_PROFILE_CREATE_TIMEOFF_BALANCES`
const REFRESHED_PROFILE_CREATE_TIMEOFF_BALANCES = `${MODULE_NAME} | REFRESHED_PROFILE_CREATE_TIMEOFF_BALANCES`
const REFRESHING_PROFILE_ACTIVITIES = `${MODULE_NAME} | REFRESHING_PROFILE_ACTIVITIES`
const REFRESHED_PROFILE_ACTIVITIES = `${MODULE_NAME} | REFRESHED_PROFILE_ACTIVITIES`
const REFRESHING_PROFILE_TIMEOFF_TYPES = `${MODULE_NAME} | REFRESHING_PROFILE_TIMEOFF_TYPES`
const REFRESHED_PROFILE_TIMEOFF_TYPES = `${MODULE_NAME} | REFRESHED_PROFILE_TIMEOFF_TYPES`
const REFRESHING_PROFILE_REQUESTS = `${MODULE_NAME} | REFRESHING_PROFILE_REQUESTS`
const REFRESHED_PROFILE_REQUESTS = `${MODULE_NAME} | REFRESHED_PROFILE_REQUESTS`

const CONFIRMED_REQUEST = `${MODULE_NAME} | CONFIRMED_REQUEST`
const CREATED_COMMENT = `${MODULE_NAME} | CREATED_COMMENT`
const CREATED_MANUAL_ADJUSTMENT = `${MODULE_NAME} | CREATED_MANUAL_ADJUSTMENT`
const CREATED_REQUEST = `${MODULE_NAME} | CREATED_REQUEST`
const EDITED_REQUEST = `${MODULE_NAME} | EDITED_REQUEST`
const CANCELLED_REQUEST = `${MODULE_NAME} | CANCELLED_REQUEST`

const EXPAND_REQUEST = `${MODULE_NAME} | EXPAND_REQUEST`
const COLLAPSE_REQUEST = `${MODULE_NAME} | COLLAPSE_REQUEST`

const CHANGE_CALENDAR_FILTER_TYPE = `${MODULE_NAME} | CHANGE_CALENDAR_FILTER_TYPE`
const CHANGE_CALENDAR_FILTER_VALUE = `${MODULE_NAME} | CHANGE_CALENDAR_FILTER_VALUE`
const CHANGE_CALENDAR_PAGE = `${MODULE_NAME} | CHANGE_CALENDAR_PAGE`
const CHANGE_CALENDAR_LIMIT = `${MODULE_NAME} | CHANGE_CALENDAR_LIMIT`
const CHANGE_CALENDAR_MONTH = `${MODULE_NAME} | CHANGE_CALENDAR_MONTH`
const CHANGE_CALENDAR_YEAR = `${MODULE_NAME} | CHANGE_CALENDAR_YEAR`
const CHANGE_CALENDAR_NAME_FILTER = `${MODULE_NAME} | CHANGE_CALENDAR_NAME_FILTER`
const CHANGE_CALENDAR_IS_ARCHIVED_FILTER = `${MODULE_NAME} | CHANGE_CALENDAR_IS_ARCHIVED_FILTER`

const CHANGE_ALL_REQUESTS_FILTER_TYPE = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_FILTER_TYPE`
const CHANGE_ALL_REQUESTS_FILTER_VALUE = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_FILTER_VALUE`
const CHANGE_ALL_REQUESTS_PAGE = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_PAGE`
const CHANGE_ALL_REQUESTS_LIMIT = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_LIMIT`
const CHANGE_ALL_REQUESTS_START_DATE = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_START_DATE`
const CHANGE_ALL_REQUESTS_END_DATE = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_END_DATE`
const CHANGE_ALL_REQUESTS_NAME_FILTER = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_NAME_FILTER`

const CHANGE_PROFILE_ACTIVITIES_PAGE = `${MODULE_NAME} | CHANGE_PROFILE_ACTIVITIES_PAGE`
const CHANGE_PROFILE_ACTIVITIES_LIMIT = `${MODULE_NAME} | CHANGE_PROFILE_ACTIVITIES_LIMIT`

const CHANGE_PROFILE_REQUESTS_PAGE = `${MODULE_NAME} | CHANGE_PROFILE_REQUESTS_PAGE`
const CHANGE_PROFILE_REQUESTS_LIMIT = `${MODULE_NAME} | CHANGE_PROFILE_REQUESTS_LIMIT`

const CHANGE_PROFILE_START_DATE = `${MODULE_NAME} | CHANGE_PROFILE_START_DATE`
const CHANGE_PROFILE_END_DATE = `${MODULE_NAME} | CHANGE_PROFILE_END_DATE`
const CHANGE_SELECTED_STATUS = `${MODULE_NAME} | CHANGE_SELECTED_STATUS`
const CHANGE_SELECTED_TIMEOFF_TYPE_ID = `${MODULE_NAME} | CHANGE_SELECTED_TIMEOFF_TYPE_ID`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshTimeoffFeeds() {
	return dispatch => {
		let apiUrl = API_URLS.timeoffFeeds()
		dispatch({ type: REFRESHING_TIMEOFF_FEEDS, apiUrl })
		return api.hr_timeoff.getTimeoffFeeds().then(({ data: timeoffFeeds }) => {
			dispatch({ type: REFRESHED_TIMEOFF_FEEDS, apiUrl, data: timeoffFeeds })
			return timeoffFeeds
		})
	}
}

export function refreshTimeoffCalendar() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.timeoffCalendar()
		let {
			hrTimeoff: { data },
		} = getState()
		let args = {}
		let filters = data.calendarFilters
		if (filters) {
			if (filters.page) {
				args.page = filters.page
			}
			if (filters.limit) {
				args.limit = filters.limit
			}
			if (filters.filterType && filters.filterValue) {
				args.filter_type = filters.filterType
			}
			if (filters.filterValue) {
				args.filter_value = filters.filterValue
			}
			if (filters.month) {
				args.month = filters.month
			}
			if (filters.year) {
				args.year = filters.year
			}
			if (filters.employeeName) {
				args.employee_name = filters.employeeName
			}
			if (filters.isArchived) {
				args.is_archived = filters.isArchived
			}
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_TIMEOFF_CALENDAR, apiUrl })
		return api.hr_timeoff.getTimeoffCalendar(getParam).then(({ data: calendars, pagination }) => {
			dispatch({ type: REFRESHED_TIMEOFF_CALENDAR, apiUrl, data: calendars, pagination })
			return calendars
		})
	}
}

export function refreshCalendarFilters(filterType) {
	return dispatch => {
		let apiUrl = API_URLS.calendarFilters()
		dispatch({ type: REFRESHING_CALENDAR_FILTERS, apiUrl })
		return api.hr_timeoff.getTimeoffCalendarFilters(filterType).then(({ data: calendarFilters }) => {
			dispatch({ type: REFRESHED_CALENDAR_FILTERS, apiUrl, data: calendarFilters })
			return calendarFilters
		})
	}
}

export function refreshAllRequests() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.allRequests()
		let {
			hrTimeoff: { data },
		} = getState()
		let args = {}
		let filters = data.allRequestFilters
		if (filters) {
			if (filters.page) {
				args.page = filters.page
			}
			if (filters.limit) {
				args.limit = filters.limit
			}
			if (filters.filterType && filters.filterValue) {
				args.filter_type = filters.filterType
			}
			if (filters.filterValue) {
				args.filter_value = filters.filterValue
			}
			if (filters.startDate) {
				args.start_date = format(filters.startDate, 'YYYY-MM-DD')
			}
			if (filters.endDate) {
				args.end_date = format(filters.endDate, 'YYYY-MM-DD')
			}
			if (filters.employeeName) {
				args.employee_name = filters.employeeName
			}
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_ALL_REQUESTS, apiUrl })
		return api.hr_timeoff.getAllRequests(getParam).then(({ data: allRequests, pagination }) => {
			dispatch({ type: REFRESHED_ALL_REQUESTS, apiUrl, data: allRequests, pagination })
			return allRequests
		})
	}
}

export function refreshRequestFilters(filterType) {
	return dispatch => {
		let apiUrl = API_URLS.requestFilters()
		dispatch({ type: REFRESHING_REQUEST_FILTERS, apiUrl })
		return api.hr_timeoff.getAllRequestFilters(filterType).then(({ data: requestFilters }) => {
			dispatch({ type: REFRESHED_REQUEST_FILTERS, apiUrl, data: requestFilters })
			return requestFilters
		})
	}
}

function refreshRequestById(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffRequestById(timeoffRequestId)
		dispatch({ type: REFRESHING_TIMEOFF_REQUEST_BY_ID, apiUrl })
		return api.hr_timeoff.timeoff_requests.getById(timeoffRequestId).then(({ data: timeoffFeedWithCalendar }) => {
			dispatch({ type: REFRESHED_TIMEOFF_REQUEST_BY_ID, apiUrl, timeoffRequestId, data: timeoffFeedWithCalendar })
			return timeoffFeedWithCalendar
		})
	}
}

function refreshEmployeeProfile(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeProfile(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_PROFILE, apiUrl })
		return api.hr_timeoff.employee_profile.get(employeeId).then(({ data: employeeProfile }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_PROFILE, apiUrl, data: employeeProfile })
			return employeeProfile
		})
	}
}

export function refreshProfileBalances(employeeId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.profileBalances(employeeId)
		let {
			hrTimeoff: { data },
		} = getState()
		let args = {}
		if (data.profileStartDate) {
			args.start_date = format(data.profileStartDate, 'YYYY-MM-DD')
		}
		if (data.profileEndDate) {
			args.end_date = format(data.profileEndDate, 'YYYY-MM-DD')
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_PROFILE_BALANCES, apiUrl })
		return api.hr_timeoff.employee_profile.getBalances(employeeId, getParam).then(({ data: employeeBalances }) => {
			dispatch({ type: REFRESHED_PROFILE_BALANCES, apiUrl, data: employeeBalances })
			return employeeBalances
		})
	}
}

function refreshProfileCreateTimeoffBalances(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.profileCreateTimeoffBalances(employeeId)
		dispatch({ type: REFRESHING_PROFILE_CREATE_TIMEOFF_BALANCES, apiUrl })
		return api.hr_timeoff.employee_profile.getCreateTimeoffBalances(employeeId).then(({ data: timeoffWallets }) => {
			dispatch({ type: REFRESHED_PROFILE_CREATE_TIMEOFF_BALANCES, apiUrl, data: timeoffWallets })
			return timeoffWallets
		})
	}
}

export function refreshProfileActivities(employeeId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.profileActivities(employeeId)
		let {
			hrTimeoff: { data },
		} = getState()
		let args = {}
		let filters = data.activityFilters
		if (filters) {
			if (filters.page) {
				args.page = filters.page
			}
			if (filters.limit) {
				args.limit = filters.limit
			}
		}
		if (data.selectedTimeoffTypeId) {
			args.timeoff_type_id = data.selectedTimeoffTypeId
		}
		if (data.profileStartDate) {
			args.start_date = format(data.profileStartDate, 'YYYY-MM-DD')
		}
		if (data.profileEndDate) {
			args.end_date = format(data.profileEndDate, 'YYYY-MM-DD')
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_PROFILE_ACTIVITIES, apiUrl })
		return api.hr_timeoff.employee_profile.activities
			.get(employeeId, getParam)
			.then(({ data: walletLogWithInitialList, pagination }) => {
				dispatch({ type: REFRESHED_PROFILE_ACTIVITIES, apiUrl, data: walletLogWithInitialList, pagination })
				return walletLogWithInitialList
			})
	}
}

function refreshProfileTimeoffTypes(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.profileTimeoffTypes(employeeId)
		dispatch({ type: REFRESHING_PROFILE_TIMEOFF_TYPES, apiUrl })
		return api.hr_timeoff.employee_profile.activities.getTimeoffTypes(employeeId).then(({ data: timeoffTypes }) => {
			dispatch({ type: REFRESHED_PROFILE_TIMEOFF_TYPES, apiUrl, data: timeoffTypes })
			return timeoffTypes
		})
	}
}

export function refreshProfileRequests(employeeId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.profileRequests(employeeId)
		let {
			hrTimeoff: { data },
		} = getState()
		let args = {}
		let filters = data.requestFilters
		if (filters) {
			if (filters.page) {
				args.page = filters.page
			}
			if (filters.limit) {
				args.limit = filters.limit
			}
		}
		if (data.selectedStatus) {
			args.timeoff_status = data.selectedStatus
		}
		if (data.profileStartDate) {
			args.start_date = format(data.profileStartDate, 'YYYY-MM-DD')
		}
		if (data.profileEndDate) {
			args.end_date = format(data.profileEndDate, 'YYYY-MM-DD')
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_PROFILE_REQUESTS, apiUrl })
		return api.hr_timeoff.employee_profile.requests
			.get(employeeId, getParam)
			.then(({ data: employeePeriodRequestList, pagination }) => {
				dispatch({ type: REFRESHED_PROFILE_REQUESTS, apiUrl, data: employeePeriodRequestList, pagination })
				return employeePeriodRequestList
			})
	}
}

export function confirmRequest(timeoffRequestId) {
	return dispatch => {
		return api.hr_timeoff.timeoff_requests.confirm(timeoffRequestId).then(() => {
			toast(`Successfully confirm the request`)
			dispatch({ type: CONFIRMED_REQUEST, timeoffRequestId })
		})
	}
}

export function createComment(timeoffRequestId, comment) {
	return dispatch => {
		return api.hr_timeoff.timeoff_requests.createComment(timeoffRequestId, comment).then(({ data: timeoffComment }) => {
			dispatch(refreshRequestById(timeoffRequestId))
			dispatch({ type: CREATED_COMMENT })
			return timeoffComment
		})
	}
}

export function createManualAdjustment(employeeId, data) {
	return dispatch => {
		return api.hr_timeoff.employee_profile.createManualAdjustment(employeeId, data).then(({ data: walletLog }) => {
			toast('Successfully adjusted time off balance')
			dispatch({ type: CREATED_MANUAL_ADJUSTMENT })
			dispatch(refreshProfileBalances(employeeId))
			dispatch(refreshProfileActivities(employeeId))
			dispatch(refreshProfileCreateTimeoffBalances(employeeId))
			return walletLog
		})
	}
}

export function createRequest(employeeId, data) {
	return dispatch => {
		return api.hr_timeoff.employee_profile.createTimeoff(employeeId, data).then(({ data: employeePeriodRequest }) => {
			toast('Successfully created request')
			dispatch({ type: CREATED_REQUEST })
			dispatch(refreshProfileRequests(employeeId))
			return employeePeriodRequest
		})
	}
}

export function calculateDays(employeeId, data) {
	return dispatch => {
		return api.hr_timeoff.employee_profile.calculateDays(employeeId, data).then(({ data: { days } }) => days)
	}
}

export function editRequest(employeeId, timeoffRequestId, data) {
	return dispatch => {
		return api.hr_timeoff.employee_profile.requests
			.edit(employeeId, timeoffRequestId, data)
			.then(({ data: employeePeriodRequest }) => {
				toast(`Successfully edited request`)
				dispatch(refreshRequestById(timeoffRequestId))
				dispatch({ type: EDITED_REQUEST, employeeId, employeePeriodRequest })
				return employeePeriodRequest
			})
	}
}

export function cancelRequest(employeeId, timeoffRequestId) {
	return dispatch => {
		return api.hr_timeoff.employee_profile.requests
			.cancel(employeeId, timeoffRequestId)
			.then(({ data: employeePeriodRequest }) => {
				toast(`Successfully cancelled request`)
				dispatch({ type: CANCELLED_REQUEST })
				dispatch(refreshProfileRequests(employeeId))
				return employeePeriodRequest
			})
	}
}

export function expandRequest(timeoffRequestId) {
	return dispatch => {
		dispatch({ type: EXPAND_REQUEST, timeoffRequestId })
	}
}

export function collapseRequest(timeoffRequestId) {
	return dispatch => {
		dispatch({ type: COLLAPSE_REQUEST, timeoffRequestId })
	}
}

export function changeCalendarFilterType(filterType) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_FILTER_TYPE, filterType })
		dispatch(refreshCalendarFilters(filterType))
	}
}

export function changeCalendarFilterValue(filterValue) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_FILTER_VALUE, filterValue })
		dispatch(changeCalendarPage(1))
	}
}

export function changeCalendarPage(page) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_PAGE, page })
		dispatch(refreshTimeoffCalendar())
	}
}

export function changeCalendarLimit(limit) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_LIMIT, limit })
		dispatch(changeCalendarPage(1))
	}
}

export function changeCalendarMonth(month) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_MONTH, month })
		dispatch(changeCalendarPage(1))
	}
}

export function changeCalendarYear(year) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_YEAR, year })
		dispatch(changeCalendarPage(1))
	}
}

export function changeCalendarNameFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_NAME_FILTER, name })
		dispatch(changeCalendarPage(1))
	}
}

export function changeCalendarIsArchivedFilter(isArchived) {
	return dispatch => {
		dispatch({ type: CHANGE_CALENDAR_IS_ARCHIVED_FILTER, isArchived })
		dispatch(changeCalendarPage(1))
	}
}

export function changeAllRequestsFilterType(filterType) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_FILTER_TYPE, filterType })
		dispatch(refreshRequestFilters(filterType))
	}
}

export function changeAllRequestsFilterValue(filterValue) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_FILTER_VALUE, filterValue })
		dispatch(changeAllRequestsPage(1))
	}
}

export function changeAllRequestsPage(page) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_PAGE, page })
		dispatch(refreshAllRequests())
	}
}

export function changeAllRequestsLimit(limit) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_LIMIT, limit })
		dispatch(changeAllRequestsPage(1))
	}
}

export function changeAllRequestsStartDate(startDate) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_START_DATE, startDate })
		dispatch(changeAllRequestsPage(1))
	}
}

export function changeAllRequestsEndDate(endDate) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_END_DATE, endDate })
		dispatch(changeAllRequestsPage(1))
	}
}

export function changeAllRequestsNameFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_NAME_FILTER, name })
		dispatch(changeAllRequestsPage(1))
	}
}

export function changeProfileActivitiesPage(page) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_ACTIVITIES_PAGE, page })
		dispatch(refreshProfileActivities(employeeId))
	}
}

export function changeProfileActivitiesLimit(limit) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_ACTIVITIES_LIMIT, limit })
		dispatch(refreshProfileActivities(employeeId))
	}
}

export function changeProfileRequestsPage(page) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_REQUESTS_PAGE, page })
		dispatch(refreshProfileRequests(employeeId))
	}
}

export function changeProfileRequestsLimit(limit) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_REQUESTS_LIMIT, limit })
		dispatch(refreshProfileRequests(employeeId))
	}
}

export function changeProfileStartDate(startDate) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_START_DATE, startDate })
		dispatch(refreshProfileActivities(employeeId))
		dispatch(refreshProfileBalances(employeeId))
		dispatch(refreshProfileRequests(employeeId))
	}
}

export function changeProfileEndDate(endDate) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_PROFILE_END_DATE, endDate })
		dispatch(refreshProfileActivities(employeeId))
		dispatch(refreshProfileBalances(employeeId))
		dispatch(refreshProfileRequests(employeeId))
	}
}

export function changeSelectedStatus(status) {
	return (dispatch, getState) => {
		const {
			routing: {
				location: { pathname },
			},
		} = getState()
		const employeeId = parseInt(pathname.split('/')[pathname.split('/').length - 1], 10)
		dispatch({ type: CHANGE_SELECTED_STATUS, status })
		dispatch(refreshProfileRequests(employeeId))
	}
}

export function changeSelectedTimeoffTypeId(employeeId, timeoffTypeId) {
	return (dispatch, getState) => {
		dispatch({ type: CHANGE_SELECTED_TIMEOFF_TYPE_ID, timeoffTypeId })
		dispatch(refreshProfileActivities(employeeId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshTimeoffFeeds())
		dispatch(refreshTimeoffCalendar())
		dispatch({ type: LOAD_MODULE })
	}
}

export function loadAllRequestsViewData() {
	return dispatch => {
		dispatch(refreshAllRequests())
	}
}

export function loadProfileViewData(employeeId) {
	return dispatch => {
		dispatch(refreshEmployeeProfile(employeeId))
		dispatch(refreshProfileBalances(employeeId))
		dispatch(refreshProfileRequests(employeeId))
		dispatch(refreshProfileTimeoffTypes(employeeId)).then(timeoffTypes => {
			try {
				dispatch(changeSelectedTimeoffTypeId(employeeId, timeoffTypes[0].id))
			} catch (e) {
				console.error('There is no time off type assigned for this employee')
			}
		})
	}
}

export function loadTimeoffRequestDetailData(timeoffRequestId) {
	return dispatch => {
		dispatch(refreshRequestById(timeoffRequestId))
	}
}

export function loadCreateRequestData(employeeId) {
	return dispatch => {
		dispatch(refreshProfileCreateTimeoffBalances(employeeId))
	}
}

export function loadEditRequestData(employeeId, timeoffRequestId) {
	return dispatch => {
		dispatch(refreshProfileCreateTimeoffBalances(employeeId))
		dispatch(refreshRequestById(timeoffRequestId))
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	data: {
		expandedRequestIds: [],
		selectedStatus: undefined,
		selectedTimeoffTypeId: undefined,
		profileStartDate: new Date(new Date().getFullYear(), 0, 1),
		profileEndDate: new Date(new Date().getFullYear(), 11, 31),
		activityFilters: {
			page: 1,
			limit: 25,
		},
		requestFilters: {
			page: 1,
			limit: 25,
		},
		calendarFilters: {
			filterType: undefined,
			filterValue: undefined,
			employeeName: undefined,
			page: 1,
			limit: 25,
			month: new Date().getMonth() + 1,
			year: new Date().getFullYear(),
		},
		allRequestFilters: {
			filterType: undefined,
			filterValue: undefined,
			employeeName: undefined,
			page: 1,
			limit: 25,
			startDate: new Date(new Date().getFullYear(), 0, 1),
			endDate: new Date(new Date().getFullYear(), 11, 31),
		},
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_TIMEOFF_FEEDS:
		case REFRESHING_TIMEOFF_CALENDAR:
		case REFRESHING_CALENDAR_FILTERS:
		case REFRESHING_ALL_REQUESTS:
		case REFRESHING_REQUEST_FILTERS:
		case REFRESHING_TIMEOFF_REQUEST_BY_ID:
		case REFRESHING_EMPLOYEE_PROFILE:
		case REFRESHING_PROFILE_BALANCES:
		case REFRESHING_PROFILE_CREATE_TIMEOFF_BALANCES:
		case REFRESHING_PROFILE_ACTIVITIES:
		case REFRESHING_PROFILE_TIMEOFF_TYPES:
		case REFRESHING_PROFILE_REQUESTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_TIMEOFF_FEEDS:
		case REFRESHED_TIMEOFF_CALENDAR:
		case REFRESHED_CALENDAR_FILTERS:
		case REFRESHED_ALL_REQUESTS:
		case REFRESHED_REQUEST_FILTERS:
		case REFRESHED_EMPLOYEE_PROFILE:
		case REFRESHED_PROFILE_BALANCES:
		case REFRESHED_PROFILE_CREATE_TIMEOFF_BALANCES:
		case REFRESHED_PROFILE_ACTIVITIES:
		case REFRESHED_PROFILE_TIMEOFF_TYPES:
		case REFRESHED_PROFILE_REQUESTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case REFRESHED_TIMEOFF_REQUEST_BY_ID:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
					[API_URLS.timeoffFeeds()]: state.api[API_URLS.timeoffFeeds()]
						? state.api[API_URLS.timeoffFeeds()].map(timeoffRequest => {
								if (timeoffRequest.id === action.timeoffRequestId) {
									return action.data
								}
								return timeoffRequest
						  })
						: undefined,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case CONFIRMED_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.timeoffFeeds()]: state.api[API_URLS.timeoffFeeds()].filter(
						timeoffRequest => timeoffRequest.id !== action.timeoffRequestId
					),
				},
			}
		case EDITED_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.profileRequests(action.employeeId)]: state.api[API_URLS.profileRequests(action.employeeId)].map(
						request => {
							if (request.id === action.employeePeriodRequest.id) {
								return action.employeePeriodRequest
							}
							return request
						}
					),
				},
			}
		case CHANGE_CALENDAR_FILTER_TYPE:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						filterType: action.filterType,
					},
				},
			}
		case CHANGE_CALENDAR_FILTER_VALUE:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						filterValue: action.filterValue,
					},
				},
			}
		case CHANGE_CALENDAR_PAGE:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						page: action.page,
					},
				},
			}
		case CHANGE_CALENDAR_LIMIT:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						limit: action.limit,
					},
				},
			}
		case CHANGE_CALENDAR_MONTH:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						month: action.month,
					},
				},
			}
		case CHANGE_CALENDAR_YEAR:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						year: action.year,
					},
				},
			}
		case CHANGE_CALENDAR_NAME_FILTER:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						page: 1,
						employeeName: action.name,
					},
				},
			}
		case CHANGE_CALENDAR_IS_ARCHIVED_FILTER:
			return {
				...state,
				data: {
					...state.data,
					calendarFilters: {
						...state.data.calendarFilters,
						page: 1,
						isArchived: action.isArchived,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_FILTER_TYPE:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						filterType: action.filterType,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_FILTER_VALUE:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						filterValue: action.filterValue,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_PAGE:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						page: action.page,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_LIMIT:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						limit: action.limit,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_START_DATE:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						startDate: action.startDate,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_END_DATE:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						endDate: action.endDate,
					},
				},
			}
		case CHANGE_ALL_REQUESTS_NAME_FILTER:
			return {
				...state,
				data: {
					...state.data,
					allRequestFilters: {
						...state.data.allRequestFilters,
						employeeName: action.name,
					},
				},
			}
		case CHANGE_PROFILE_ACTIVITIES_PAGE:
			return {
				...state,
				data: {
					...state.data,
					activityFilters: {
						...state.data.activityFilters,
						page: action.page,
					},
				},
			}
		case CHANGE_PROFILE_ACTIVITIES_LIMIT:
			return {
				...state,
				data: {
					...state.data,
					activityFilters: {
						...state.data.activityFilters,
						limit: action.limit,
					},
				},
			}
		case CHANGE_PROFILE_REQUESTS_PAGE:
			return {
				...state,
				data: {
					...state.data,
					requestFilters: {
						...state.data.requestFilters,
						page: action.page,
					},
				},
			}
		case CHANGE_PROFILE_REQUESTS_LIMIT:
			return {
				...state,
				data: {
					...state.data,
					requestFilters: {
						...state.data.requestFilters,
						limit: action.limit,
					},
				},
			}
		case CHANGE_PROFILE_START_DATE:
			return {
				...state,
				data: {
					...state.data,
					profileStartDate: action.startDate,
				},
			}
		case CHANGE_PROFILE_END_DATE:
			return {
				...state,
				data: {
					...state.data,
					profileEndDate: action.endDate,
				},
			}
		case CHANGE_SELECTED_STATUS:
			return {
				...state,
				data: {
					...state.data,
					selectedStatus: action.status,
				},
			}
		case CHANGE_SELECTED_TIMEOFF_TYPE_ID:
			return {
				...state,
				data: {
					...state.data,
					selectedTimeoffTypeId: action.timeoffTypeId,
				},
			}
		case EXPAND_REQUEST:
			return {
				...state,
				data: {
					...state.data,
					expandedRequestIds: [...state.data.expandedRequestIds, action.timeoffRequestId],
				},
			}
		case COLLAPSE_REQUEST:
			return {
				...state,
				data: {
					...state.data,
					expandedRequestIds: state.data.expandedRequestIds.filter(
						expandedRequestId => expandedRequestId !== action.timeoffRequestId
					),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
