import { s, number } from 'modules/types'

export class TimeoffApprover {
	constructor({
		id,
		type,
		group_leader_role_id: groupLeaderRoleId = null,
		rank_id: rankId = null,
		approver_id: approverId = null,
	}) {
		this.id = id
		this.type = type
		if (groupLeaderRoleId !== null) {
			this.groupLeaderRoleId = groupLeaderRoleId
		}
		if (approverId !== null) {
			this.approverId = approverId
		}
		if (rankId !== null) {
			this.rankId = rankId
		}
	}
}

export const TimeoffApproverType = s({
	id: number.isRequired,
	type: number.isRequired, // @APPROVER_TYPE
	groupLeaderRoleId: number,
	approverId: number,
	rankId: number,
})

export default TimeoffApprover
