export default {
	INDIVIDUAL: 'individual',
	SUPERIOR: 'superior',
	PEER: 'peer',
	SECOND_SUPERIOR: 'second_superior',
	SUBORDINATE: 'subordinate',
	OTHERS: 'others',
	EXTERNAL: 'external',
	SUMMARY: 'summary',
	DIRECT_SUPERVISOR: 'direct_supervisor',
}
