import { s, number, object, string } from 'modules/types'
import HolidayGroupLogExtInfo, { HolidayGroupLogExtInfoType } from './HolidayGroupLogExtInfo'
import HolidayGroupLogExtInfoDetail, { HolidayGroupLogExtInfoDetailType } from './HolidayGroupLogExtInfoDetail'

export class HolidayGroupLogDetail {
	constructor({
		id,
		operator_name: operatorName,
		operator_picture_url: operatorPictureUrl,
		type,
		ext_info: extInfo,
		ext_info_detail: extInfoDetail,
		added,
	}) {
		this.id = id
		this.operatorName = operatorName
		this.operatorPictureUrl = operatorPictureUrl
		this.type = type
		this.extInfo = new HolidayGroupLogExtInfo(extInfo)
		this.extInfoDetail = new HolidayGroupLogExtInfoDetail(extInfoDetail)
		this.added = new Date(added * 1000)
	}
}

export const HolidayGroupLogDetailType = s({
	id: number.isRequired,
	operatorName: string.isRequired,
	operatorPictureUrl: string.isRequired,
	type: number.isRequired,
	extInfo: HolidayGroupLogExtInfoType,
	extInfoDetail: HolidayGroupLogExtInfoDetailType,
	added: object.isRequired,
})

export default HolidayGroupLogDetail
