import { s, arrayOf, number, string } from 'modules/types'
import TimeoffApprover, { TimeoffApproverType } from './TimeoffApprover'

export class TimeoffGroupEmployee {
	constructor({
		id,
		name,
		NIK,
		group_name: groupName,
		rank_id: rankId,
		rank_name: rankName,
		title_name: titleName,
		timeoff_approvers: timeoffApprovers,
	}) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.titleName = titleName
		this.rankId = rankId
		this.rankName = rankName
		this.groupName = groupName
		this.timeoffApprovers = timeoffApprovers.map(item => new TimeoffApprover(item))
	}
}

export const TimeoffGroupEmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	titleName: string.isRequired,
	rankId: number.isRequired,
	rankName: string.isRequired,
	groupName: string.isRequired,
	timeoffApprovers: arrayOf(TimeoffApproverType),
})

export default TimeoffGroupEmployee
