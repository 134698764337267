import { s, bool, number } from 'modules/types'
import { TIMEOFF_RULE_PERIOD } from './index'

export class TimeoffRule {
	constructor({ id, allowance, period, max_accumulation: maxAccumulation, allow_over_limit: allowOverLimit }) {
		this.id = id
		this.allowance = allowance
		this.period = period
		this.maxAccumulation = maxAccumulation
		this.allowOverLimit = allowOverLimit
	}

	displayPeriod() {
		switch (this.period) {
			case TIMEOFF_RULE_PERIOD.ANNUALLY:
				return 'ANNUALLY'
			case TIMEOFF_RULE_PERIOD.MONTHLY:
				return 'MONTHLY'
			case TIMEOFF_RULE_PERIOD.WEEKLY:
				return 'WEEKLY'
			case TIMEOFF_RULE_PERIOD.DAILY:
				return 'DAILY'
			default:
				return 'UNKNOWN_PERIOD'
		}
	}
}

export const TimeoffRuleType = s({
	id: number.isRequired,
	allowance: number.isRequired,
	period: number.isRequired,
	maxAccumulation: number.isRequired,
	allowOverLimit: bool.isRequired,
})

export default TimeoffRule
