import AllowedGroupsToMoveResponse from './AllowedGroupsToMoveResponse'
import Answer from './Answer'
import AnswerChoice from './AnswerChoice'
import AnswerSnapshot from './AnswerSnapshot'
import AppraisalGroup from './AppraisalGroup'
import Appraisee from './Appraisee'
import AppraiseeScoring from './AppraiseeScoring'
import AppraiseesCustomEmployeeScoring from './AppraiseesCustomEmployeeScoring'
import AppraiseesSuperiorScoring from './AppraiseesSuperiorScoring'
import AppraiseeSuperiorScoring from './AppraiseeSuperiorScoring'
import AppraiserType from './AppraiserType'
import Category from './Category'
import EmployeeWithScoreCardStatus from './EmployeeWithScoreCardStatus'
import EmployeeScoreCardUrl from './EmployeeScoreCardUrl'
import EmployeeWithDetail from './EmployeeWithDetail'
import ExportResult from './ExportResult'
import GroupStage from './GroupStage'
import IndividualSnapshot from './IndividualSnapshot'
import PreviousStage from './PreviousStage'
import PreviousStageAnswer from './PreviousStageAnswer'
import Question from './Question'
import QuestionLink from './QuestionLink'
import QuestionWithPreviousStageAnswer from './QuestionWithPreviousStageAnswer'
import Session from './Session'
import SnapshotsWithEmployeeId from './SnapshotsWithEmployeeId'
import Stage from './Stage'
import Template from './Template'
import TemplateWithQuestion from './TemplateWithQuestion'

export const APPRAISER_TYPE = {
	SELF: 0,
	PEER: 1,
	SUPERIOR: 2,
	SECOND_DEGREE_SUPERIOR: 3,
	SUBORDINATE: 4,
	OTHERS: 5,
	EXTERNAL: 6,
	GROUP_LEADER: 7,
	DEPARTMENT_HEAD: 8,
	RANK_SUPERVISOR: 9,
}

export const TABLE_HEADER_TYPE = {
	NIK: 1,
	NAME: 2,
	POSITION_NAME: 3,
	DIRECT_SUPERVISOR_NAME: 4,
	TITLE_NAME: 5,
	RANK_NAME: 6,
	DEPARTMENT_NAME: 7,
	GROUP_NAME: 8,
	SECOND_SUPERVISOR_NAME: 9,
}

export const SCORE_CARD_STATUS = {
	UNKNOWN: -1,
	NOT_STARTED: 0,
	IN_PROGRESS: 1,
	COMPLETED: 2,
}

export const QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
}

export default {
	AllowedGroupsToMoveResponse,
	Answer,
	AnswerChoice,
	AnswerSnapshot,
	AppraisalGroup,
	Appraisee,
	AppraiseeScoring,
	AppraiseesCustomEmployeeScoring,
	AppraiseesSuperiorScoring,
	AppraiseeSuperiorScoring,
	AppraiserType,
	Category,
	EmployeeWithScoreCardStatus,
	EmployeeScoreCardUrl,
	EmployeeWithDetail,
	ExportResult,
	GroupStage,
	IndividualSnapshot,
	PreviousStage,
	PreviousStageAnswer,
	Question,
	QuestionLink,
	QuestionWithPreviousStageAnswer,
	Session,
	SnapshotsWithEmployeeId,
	Stage,
	Template,
	TemplateWithQuestion,
}
