import { s, arrayOf, bool, number, string } from 'modules/types'
import QuestionCategory, { QuestionCategoryType } from './QuestionCategory'
import AppraisalTemplateLog, { AppraisalTemplateLogType } from './AppraisalTemplateLog'

export class TemplateWithQuestions {
	constructor({
		id,
		name,
		description,
		categories,
		is_allowed_to_modify: isAllowedToModify,
		template_logs: templateLogs,
		session_name: sessionName,
		group_name: groupName,
	}) {
		this.id = id
		this.name = name
		this.description = description
		this.categories = categories.map(item => new QuestionCategory(item))
		this.isAllowedToModify = isAllowedToModify
		this.templateLogs = templateLogs.map(item => new AppraisalTemplateLog(item))
		this.sessionName = sessionName
		this.groupName = groupName
	}
}

export const TemplateWithQuestionsType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
	categories: arrayOf(QuestionCategoryType),
	isAllowedToModify: bool.isRequired,
	templateLogs: arrayOf(AppraisalTemplateLogType),
	sessionName: string.isRequired,
	groupName: string.isRequired,
})

export default TemplateWithQuestions
