import { s, bool, number, string } from 'modules/types'

export class ExperienceTypeWithCount {
	constructor({
		id,
		name,
		order,
		code,
		notes,
		is_archived: isArchived,
		employee_count: employeeCount,
		allow_delete: allowDelete,
	}) {
		this.id = id
		this.name = name
		this.code = code
		this.order = order
		this.notes = notes
		this.isArchived = isArchived
		this.employeeCount = employeeCount
		this.allowDelete = allowDelete
	}
}

export const ExperienceTypeWithCountType = s({
	id: number.isRequired,
	name: string.isRequired,
	code: string.isRequired,
	notes: string,
	isArchived: bool.isRequired,
	employeeCount: number.isRequired,
	allowDelete: bool.isRequired,
})

export default ExperienceTypeWithCount
