import { s, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import TimeoffRequestLogExtInfo, { TimeoffRequestLogExtInfoType } from './TimeoffRequestLogExtInfo'

export class TimeoffRequestLog {
	constructor({ id, operator, type, ext_info: extInfo, added }) {
		this.id = id
		if (operator) {
			this.operator = new Employee(operator)
		}
		this.type = type
		this.extInfo = new TimeoffRequestLogExtInfo(extInfo)
		this.added = new Date(added * 1000)
	}
}

export const TimeoffRequestLogType = s({
	id: number.isRequired,
	operator: EmployeeType,
	type: number.isRequired,
	extInfo: TimeoffRequestLogExtInfoType,
	added: object.isRequired,
})

export default TimeoffRequestLog
