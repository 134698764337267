import { s, arrayOf, number, object, string } from 'modules/types'

export class RequestDetail {
	constructor({ employee_id: employeeId, full_name: fullName, last_date: lastDate, reason, notes, attachments }) {
		this.employeeId = employeeId
		this.fullName = fullName
		this.lastDate = new Date(lastDate)
		this.reason = reason
		this.notes = notes
		this.attachments = attachments
	}
}

export const RequestDetailType = s({
	employeeId: number,
	fullName: string,
	lastDate: object,
	reason: string,
	notes: string,
	attachments: arrayOf(string),
})

export default RequestDetail
