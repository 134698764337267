import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `hr-experience`

export const API_URLS = {
	employees: () => `/api/hr_experience/employees/`,
	employeeDetail: employeeId => `/api/hr_experience/employees/${employeeId}/`,
	employeeActivities: employeeId => `/api/hr_experience/employees/${employeeId}/get_activities/`,
	employeeCharts: employeeId => `/api/hr_experience/employees/${employeeId}/get_charts/`,
	employeeCalendars: employeeId => `/api/hr_experience/employees/${employeeId}/get_calendars/`,
	employeeRequests: (employeeId, date) =>
		`/api/hr_experience/employees/${employeeId}/experience_requests/?date=${date}`,
	employeeRequestsActivities: (employeeId, date) =>
		`/api/hr_experience/employees/${employeeId}/experience_requests/get_activities/?date=${date}`,
	employeeRequestsExperienceTypes: (employeeId, date) =>
		`/api/hr_experience/employees/${employeeId}/experience_requests/get_experience_types/?date=${date}`,
	employeeRequestsWorkingCycleForDate: (employeeId, date) =>
		`/api/hr_experience/employees/${employeeId}/experience_requests/get_working_cycle_for_date/?date=${date}`,
	employeeRequestsComments: (employeeId, requestId) =>
		`/api/hr_experience/employees/${employeeId}/experience_requests/${requestId}/experience_comments/`,
	experienceRequests: () => `/api/hr_experience/experience_requests/pending/`,
	experienceSetting: () => `/api/hr_experience/experience_setting/`,
	experienceTypes: () => `/api/hr_experience/experience_types/`,
	experienceTypesFilter: () => `/api/hr_experience/employees/add_employees/get_experience_types_filters/`,
	approversFilter: () => `/api/hr_experience/employees/add_employees/get_approvers_filters/`,
	employeeFilters: () => `/api/hr_experience/employees/get_filters/`,
	unaddedEmployees: () => `/api/hr_experience/employees/get_unadded_employees/`,
	groupLeaderRoles: () => `/api/hr_experience/group_leader_roles/`,
	ranks: () => `/api/hr_experience/ranks`,
}

const REFRESHED_EMPLOYEES = `${MODULE_NAME} | REFRESHED_EMPLOYEES`
const REFRESHING_EMPLOYEES = `${MODULE_NAME} | REFRESHING_EMPLOYEES`
const REFRESHED_EMPLOYEE_DETAIL = `${MODULE_NAME} | REFRESHED_EMPLOYEE_DETAIL`
const REFRESHING_EMPLOYEE_DETAIL = `${MODULE_NAME} | REFRESHING_EMPLOYEE_DETAIL`
const REFRESHED_EMPLOYEE_ACTIVITIES = `${MODULE_NAME} | REFRESHED_EMPLOYEE_ACTIVITIES`
const REFRESHING_EMPLOYEE_ACTIVITIES = `${MODULE_NAME} | REFRESHING_EMPLOYEE_ACTIVITIES`
const REFRESHED_EMPLOYEE_CHARTS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_CHARTS`
const REFRESHING_EMPLOYEE_CHARTS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_CHARTS`
const REFRESHED_EMPLOYEE_CALENDARS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_CALENDARS`
const REFRESHING_EMPLOYEE_CALENDARS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_CALENDARS`
const REFRESHED_EMPLOYEE_REQUESTS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REQUESTS`
const REFRESHING_EMPLOYEE_REQUESTS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REQUESTS`
const REFRESHED_EMPLOYEE_REQUESTS_ACTIVITIES = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REQUESTS_ACTIVITIES`
const REFRESHING_EMPLOYEE_REQUESTS_ACTIVITIES = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REQUESTS_ACTIVITIES`
const REFRESHED_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES`
const REFRESHING_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES`
const REFRESHED_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE`
const REFRESHING_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE`
const REFRESHED_EMPLOYEE_REQUESTS_COMMENTS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_REQUESTS_COMMENTS`
const REFRESHING_EMPLOYEE_REQUESTS_COMMENTS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_REQUESTS_COMMENTS`
const REFRESHED_EXPERIENCE_REQUESTS = `${MODULE_NAME} | REFRESHED_EXPERIENCE_REQUESTS`
const REFRESHING_EXPERIENCE_REQUESTS = `${MODULE_NAME} | REFRESHING_EXPERIENCE_REQUESTS`
const REFRESHED_EXPERIENCE_SETTING = `${MODULE_NAME} | REFRESHED_EXPERIENCE_SETTING`
const REFRESHING_EXPERIENCE_SETTING = `${MODULE_NAME} | REFRESHING_EXPERIENCE_SETTING`
const REFRESHED_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHED_EXPERIENCE_TYPES`
const REFRESHING_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHING_EXPERIENCE_TYPES`
const REFRESHED_EXPERIENCE_TYPES_FILTER = `${MODULE_NAME} | REFRESHED_EXPERIENCE_TYPES_FILTER`
const REFRESHING_EXPERIENCE_TYPES_FILTER = `${MODULE_NAME} | REFRESHING_EXPERIENCE_TYPES_FILTER`
const REFRESHED_APPROVERS_FILTER = `${MODULE_NAME} | REFRESHED_APPROVERS_FILTER`
const REFRESHING_APPROVERS_FILTER = `${MODULE_NAME} | REFRESHING_APPROVERS_FILTER`
const REFRESHED_EMPLOYEE_FILTERS = `${MODULE_NAME} | REFRESHED_EMPLOYEE_FILTERS`
const REFRESHING_EMPLOYEE_FILTERS = `${MODULE_NAME} | REFRESHING_EMPLOYEE_FILTERS`
const REFRESHED_UNADDED_EMPLOYEES = `${MODULE_NAME} | REFRESHED_UNADDED_EMPLOYEES`
const REFRESHING_UNADDED_EMPLOYEES = `${MODULE_NAME} | REFRESHING_UNADDED_EMPLOYEES`
const REFRESHED_RANKS = `${MODULE_NAME} | REFRESHED_RANKS`
const REFRESHING_RANKS = `${MODULE_NAME} | REFRESHING_RANKS`
const REFRESHED_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHED_GROUP_LEADER_ROLES`
const REFRESHING_GROUP_LEADER_ROLES = `${MODULE_NAME} | REFRESHING_GROUP_LEADER_ROLES`

const EDITED_EXPERIENCE_SETTING = `${MODULE_NAME} | EDITED_EXPERIENCE_SETTING`

const CREATED_EXPERIENCE_TYPE = `${MODULE_NAME} | CREATED_EXPERIENCE_TYPE`
const EDITED_EXPERIENCE_TYPE = `${MODULE_NAME} | EDITED_EXPERIENCE_TYPE`
const DELETED_EXPERIENCE_TYPE = `${MODULE_NAME} | DELETED_EXPERIENCE_TYPE`
const ARCHIVE_EXPERIENCE_TYPE = `${MODULE_NAME} | ARCHIVE_EXPERIENCE_TYPE`
const UNARCHIVE_EXPERIENCE_TYPE = `${MODULE_NAME} | UNARCHIVE_EXPERIENCE_TYPE`

const CREATED_EXPERIENCE_REQUEST = `${MODULE_NAME} | CREATED_EXPERIENCE_REQUEST`
const EDITED_EXPERIENCE_REQUEST = `${MODULE_NAME} | EDITED_EXPERIENCE_REQUEST`
const CREATED_EXPERIENCE_REQUEST_COMMENT = `${MODULE_NAME} | CREATED_EXPERIENCE_REQUEST_COMMENT`

const ADDED_EMPLOYEES = `${MODULE_NAME} | ADDED_EMPLOYEES`
const REMOVED_EMPLOYEES = `${MODULE_NAME} | REMOVED_EMPLOYEES`
const EDITED_EMPLOYEE_EXPERIENCES = `${MODULE_NAME} | EDITED_EMPLOYEE_EXPERIENCES`
const EDITED_EMPLOYEE_APPROVERS = `${MODULE_NAME} | EDITED_EMPLOYEE_APPROVERS`

const CHANGE_EMPLOYEE_MONTH_FILTER = `${MODULE_NAME} | CHANGE_EMPLOYEE_MONTH_FILTER`
const CHANGE_EMPLOYEE_YEAR_FILTER = `${MODULE_NAME} | CHANGE_EMPLOYEE_YEAR_FILTER`

const LOAD_ADJUSTMENT_NOTES_DATA = `${MODULE_NAME} | LOAD_ADJUSTMENT_NOTES_DATA`
const LOAD_ADD_EMPLOYEES_DATA = `${MODULE_NAME} | LOAD_ADD_EMPLOYEES_DATA`
const LOAD_EXPERIENCE_ADJUSTMENT_DATA = `${MODULE_NAME} | LOAD_EXPERIENCE_ADJUSTMENT_DATA`
const LOAD_EXPERIENCE_PROFILE_DATA = `${MODULE_NAME} | LOAD_EXPERIENCE_PROFILE_DATA`
const LOAD_EXPERIENCE_SETTING_DATA = `${MODULE_NAME} | LOAD_EXPERIENCE_SETTING_DATA`
const LOAD_EXPERIENCE_EQUIPMENT_DATA = `${MODULE_NAME} | LOAD_EXPERIENCE_EQUIPMENT_DATA`
const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshEmployees() {
	return dispatch => {
		let apiUrl = API_URLS.employees()
		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.hr_experience.employees.get().then(({ data: employees }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

function refreshEmployeeDetail(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeDetail(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_DETAIL, apiUrl })
		return api.hr_experience.employees.getDetail(employeeId).then(({ data: employeeProfile }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_DETAIL, apiUrl, data: employeeProfile })
			return employeeProfile
		})
	}
}

function refreshEmployeeActivities(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeActivities(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_ACTIVITIES, apiUrl })
		return api.hr_experience.employees.getActivities(employeeId).then(({ data: requestLogs }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_ACTIVITIES, apiUrl, data: requestLogs })
			return requestLogs
		})
	}
}

function refreshEmployeeCharts(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeCharts(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_CHARTS, apiUrl })
		return api.hr_experience.employees.getCharts(employeeId).then(({ data: calendarDataList }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_CHARTS, apiUrl, data: calendarDataList })
			return calendarDataList
		})
	}
}

function refreshEmployeeCalendars(employeeId) {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.employeeCalendars(employeeId)
		let {
			hrExperience: { data },
		} = getState()
		let args = {}
		if (data.employeeMonthFilter) {
			args.month = data.employeeMonthFilter
		}
		if (data.employeeYearFilter) {
			args.year = data.employeeYearFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`

		dispatch({ type: REFRESHING_EMPLOYEES, apiUrl })
		return api.hr_experience.employees.getCalendars(employeeId, getParam).then(({ data: walletCalendars }) => {
			dispatch({ type: REFRESHED_EMPLOYEES, apiUrl, data: walletCalendars })
			return walletCalendars
		})
	}
}

function refreshEmployeeRequests(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.employeeRequests(employeeId, date)
		dispatch({ type: REFRESHING_EMPLOYEE_REQUESTS, apiUrl })
		return api.hr_experience.employees.experience_requests
			.get(employeeId, date)
			.then(({ data: experienceRequests }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_REQUESTS, apiUrl, data: experienceRequests })
				return experienceRequests
			})
	}
}

function refreshEmployeeRequestsActivities(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.employeeRequestsActivities(employeeId, date)
		dispatch({ type: REFRESHING_EMPLOYEE_REQUESTS_ACTIVITIES, apiUrl })
		return api.hr_experience.employees.experience_requests
			.getActivities(employeeId, date)
			.then(({ data: experienceRequestLogs }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_REQUESTS_ACTIVITIES, apiUrl, data: experienceRequestLogs })
				return experienceRequestLogs
			})
	}
}

function refreshEmployeeRequestsExperienceTypes(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.employeeRequestsExperienceTypes(employeeId, date)
		dispatch({ type: REFRESHING_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES, apiUrl })
		return api.hr_experience.employees.experience_requests
			.getExperienceTypes(employeeId, date)
			.then(({ data: experienceWallets }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES, apiUrl, data: experienceWallets })
				return experienceWallets
			})
	}
}

function refreshEmployeeRequestsWorkingCycleForDate(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.employeeRequestsWorkingCycleForDate(employeeId, date)
		dispatch({ type: REFRESHING_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE, apiUrl })
		return api.hr_experience.employees.experience_requests
			.getWorkingCycleForDate(employeeId, date)
			.then(({ data: workingCycle }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE, apiUrl, data: workingCycle })
				return workingCycle
			})
	}
}

function refreshEmployeeRequestsComments(employeeId, requestId) {
	return dispatch => {
		let apiUrl = API_URLS.employeeRequestsComments(employeeId, requestId)
		dispatch({ type: REFRESHING_EMPLOYEE_REQUESTS_COMMENTS, apiUrl })
		return api.hr_experience.employees.experience_requests.experience_comments
			.get(employeeId, requestId)
			.then(({ data: experienceRequestComments }) => {
				dispatch({ type: REFRESHED_EMPLOYEE_REQUESTS_COMMENTS, apiUrl, data: experienceRequestComments })
				return experienceRequestComments
			})
	}
}

function refreshExperienceRequests() {
	return dispatch => {
		let apiUrl = API_URLS.experienceRequests()
		dispatch({ type: REFRESHING_EXPERIENCE_REQUESTS, apiUrl })
		return api.hr_experience.experience_requests.getPending().then(({ data: experienceRequests }) => {
			dispatch({ type: REFRESHED_EXPERIENCE_REQUESTS, apiUrl, data: experienceRequests })
			return experienceRequests
		})
	}
}

function refreshExperienceSetting() {
	return dispatch => {
		let apiUrl = API_URLS.experienceSetting()
		dispatch({ type: REFRESHING_EXPERIENCE_SETTING, apiUrl })
		return api.hr_experience.experience_setting.get().then(({ data: experienceSetting }) => {
			dispatch({ type: REFRESHED_EXPERIENCE_SETTING, apiUrl, data: experienceSetting })
			return experienceSetting
		})
	}
}

function refreshExperienceTypes() {
	return dispatch => {
		let apiUrl = API_URLS.experienceTypes()
		dispatch({ type: REFRESHING_EXPERIENCE_TYPES, apiUrl })
		return api.hr_experience.experience_types.get().then(({ data: experienceTypes }) => {
			dispatch({ type: REFRESHED_EXPERIENCE_TYPES, apiUrl, data: experienceTypes })
			return experienceTypes
		})
	}
}

function refreshEmployeeFilters() {
	return dispatch => {
		let apiUrl = API_URLS.employeeFilters()
		dispatch({ type: REFRESHING_EMPLOYEE_FILTERS, apiUrl })
		return api.hr_experience.employees.getFilters().then(({ data: filters }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_FILTERS, apiUrl, data: filters })
			return filters
		})
	}
}

function refreshUnaddedEmployees() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.unaddedEmployees()
		let {
			hrExperience: {
				data: { superiorFilter, departmentFilter, groupFilter, titleFilter },
			},
		} = getState()
		let args = {}
		if (superiorFilter) {
			args.superior_id = superiorFilter
		}
		if (departmentFilter) {
			args.department_id = departmentFilter
		}
		if (groupFilter) {
			args.group_id = groupFilter
		}
		if (titleFilter) {
			args.title_id = titleFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_UNADDED_EMPLOYEES, apiUrl })
		return api.hr_experience.employees.getUnaddedEmployees(getParam).then(({ data: employees }) => {
			dispatch({ type: REFRESHED_UNADDED_EMPLOYEES, apiUrl, data: employees })
			return employees
		})
	}
}

function refreshExperienceTypesFilter() {
	return dispatch => {
		let apiUrl = API_URLS.experienceTypesFilter()
		dispatch({ type: REFRESHING_EXPERIENCE_TYPES_FILTER, apiUrl })
		return api.hr_experience.employees.add_employees.getExperienceTypesFilter().then(({ data: experienceTypes }) => {
			dispatch({ type: REFRESHED_EXPERIENCE_TYPES_FILTER, apiUrl, data: experienceTypes })
			return experienceTypes
		})
	}
}

function refreshApproversFilter() {
	return dispatch => {
		let apiUrl = API_URLS.approversFilter()
		dispatch({ type: REFRESHING_APPROVERS_FILTER, apiUrl })
		return api.hr_experience.employees.add_employees.getApproversFilter().then(({ data: employees }) => {
			dispatch({
				type: REFRESHED_APPROVERS_FILTER,
				apiUrl,
				data: employees.sort((a, b) => a.name.localeCompare(b.name)),
			})
			return employees
		})
	}
}

function refreshRanks() {
	return dispatch => {
		let apiUrl = API_URLS.ranks()
		dispatch({ type: REFRESHING_RANKS, apiUrl })
		return api.hr_experience.getRanks().then(({ data: ranks }) => {
			dispatch({ type: REFRESHED_RANKS, apiUrl, data: ranks })
			return ranks
		})
	}
}

function refreshGroupLeaderRoles() {
	return dispatch => {
		let apiUrl = API_URLS.groupLeaderRoles()
		dispatch({ type: REFRESHING_GROUP_LEADER_ROLES, apiUrl })
		return api.hr_experience.getGroupLeaderRoles().then(({ data: roles }) => {
			dispatch({ type: REFRESHED_GROUP_LEADER_ROLES, apiUrl, data: roles })
			return roles
		})
	}
}

export function changeEmployeeMonthFilter(employeeId, month) {
	return dispatch => {
		dispatch({ type: CHANGE_EMPLOYEE_MONTH_FILTER, month })
		dispatch(refreshEmployeeCalendars(employeeId))
	}
}

export function changeEmployeeYearFilter(employeeId, year) {
	return dispatch => {
		dispatch({ type: CHANGE_EMPLOYEE_YEAR_FILTER, year })
		dispatch(refreshEmployeeCalendars(employeeId))
	}
}

export function addEmployees(employees) {
	return dispatch => {
		return api.hr_experience.employees.add_employees.post(employees).then(({ data: employeeWithApproversList }) => {
			toast(`Successfully added employees`)
			dispatch({ type: ADDED_EMPLOYEES, employeeWithApproversList })
			return employeeWithApproversList
		})
	}
}

export function removeEmployees(employeeIds) {
	return dispatch => {
		return api.hr_experience.employees.removeEmployees(employeeIds).then(() => {
			toast(`Successfully removed employees`)
			dispatch({ type: REMOVED_EMPLOYEES, employeeIds })
		})
	}
}

export function editEmployeeExperiences(employeeId, experienceTypeIds) {
	return dispatch => {
		return api.hr_experience.employees.editExperiences(employeeId, experienceTypeIds).then(() => {
			dispatch({ type: EDITED_EMPLOYEE_EXPERIENCES, employeeId, experienceTypeIds })
		})
	}
}

export function editEmployeeApprovers(employeeId, approvers) {
	return dispatch => {
		return api.hr_experience.employees.editApprovers(employeeId, approvers).then(({ data: approvers }) => {
			dispatch({ type: EDITED_EMPLOYEE_APPROVERS, employeeId, approvers })
		})
	}
}

export function editExperienceSetting(data) {
	return dispatch => {
		let apiUrl = API_URLS.experienceSetting()
		return api.hr_experience.experience_setting.edit(data).then(() => {
			toast(`Successfully edited experience settings`)
			dispatch({ type: EDITED_EXPERIENCE_SETTING, apiUrl, data })
		})
	}
}

export function createExperienceType(data) {
	return dispatch => {
		return api.hr_experience.experience_types.create(data).then(({ data: experienceType }) => {
			toast(`Successfully added new equipment`)
			dispatch({ type: CREATED_EXPERIENCE_TYPE, experienceType })
		})
	}
}

export function editExperienceType(experienceTypeId, data) {
	return dispatch => {
		return api.hr_experience.experience_types.edit(experienceTypeId, data).then(({ data: experienceType }) => {
			dispatch({ type: EDITED_EXPERIENCE_TYPE, experienceType })
			toast(`Successfully edited equipment`)
		})
	}
}

export function deleteExperienceType(experienceTypeId) {
	return dispatch => {
		return api.hr_experience.experience_types.delete(experienceTypeId).then(() => {
			dispatch({ type: DELETED_EXPERIENCE_TYPE, experienceTypeId })
			toast(`Successfully deleted equipment`)
		})
	}
}

export function archiveExperienceType(experienceTypeId) {
	return dispatch => {
		return api.hr_experience.experience_types.archive(experienceTypeId).then(({ data: experienceType }) => {
			toast(`Successfully archived equipment`)
			dispatch({ type: ARCHIVE_EXPERIENCE_TYPE, experienceTypeId })
			return experienceType
		})
	}
}

export function moveUpExperienceType(experienceTypeId) {
	return dispatch => {
		return api.hr_experience.experience_types.moveUp(experienceTypeId).then(({ data: experienceType }) => {
			dispatch(refreshExperienceTypes())
		})
	}
}

export function moveDownExperienceType(experienceTypeId) {
	return dispatch => {
		return api.hr_experience.experience_types.moveDown(experienceTypeId).then(({ data: experienceType }) => {
			dispatch(refreshExperienceTypes())
		})
	}
}

export function unarchiveExperienceType(experienceTypeId) {
	return dispatch => {
		return api.hr_experience.experience_types.unarchive(experienceTypeId).then(({ data: experienceType }) => {
			toast(`Successfully unarchived equipment`)
			dispatch({ type: UNARCHIVE_EXPERIENCE_TYPE, experienceTypeId })
			return experienceType
		})
	}
}

export function createExperienceRequest(employeeId, data) {
	return dispatch => {
		return api.hr_experience.employees.experience_requests
			.create(employeeId, data)
			.then(({ data: experienceRequest }) => {
				dispatch(refreshEmployeeRequests(employeeId, data.date))
				dispatch(refreshEmployeeRequestsActivities(employeeId, data.date))
				dispatch({ type: CREATED_EXPERIENCE_REQUEST, experienceRequest })
				toast(`Successfully submitted hours of experience`)
				return experienceRequest
			})
	}
}

export function editExperienceRequest(employeeId, requestId, data, date) {
	return dispatch => {
		return api.hr_experience.employees.experience_requests
			.edit(employeeId, requestId, data)
			.then(({ data: experienceRequest }) => {
				dispatch(refreshEmployeeRequests(employeeId, date))
				dispatch(refreshEmployeeRequestsActivities(employeeId, date))
				dispatch({ type: EDITED_EXPERIENCE_REQUEST, experienceRequest })
				return experienceRequest
			})
	}
}

export function createExperienceRequestComment(employeeId, requestId, comment) {
	return dispatch => {
		return api.hr_experience.employees.experience_requests.experience_comments
			.create(employeeId, requestId, comment)
			.then(({ data: experienceRequestComment }) => {
				dispatch(refreshEmployeeRequestsComments(employeeId, requestId))
				dispatch({ type: CREATED_EXPERIENCE_REQUEST_COMMENT, experienceRequestComment })
				return experienceRequestComment
			})
	}
}

export function loadAddEmployeesData() {
	return dispatch => {
		dispatch(refreshApproversFilter())
		dispatch(refreshExperienceSetting())
		dispatch(refreshExperienceTypesFilter())
		dispatch(refreshEmployeeFilters())
		dispatch(refreshUnaddedEmployees())
		dispatch(refreshRanks())
		dispatch(refreshGroupLeaderRoles())
		dispatch({ type: LOAD_ADD_EMPLOYEES_DATA })
	}
}

export function loadAdjustmentNotesData(employeeId, requestId) {
	return dispatch => {
		dispatch(refreshEmployeeRequestsComments(employeeId, requestId))
		dispatch({ type: LOAD_ADJUSTMENT_NOTES_DATA })
	}
}

export function loadExperienceAdjustmentData(employeeId, date) {
	return dispatch => {
		dispatch(refreshEmployeeRequests(employeeId, date))
		dispatch(refreshEmployeeRequestsActivities(employeeId, date))
		dispatch(refreshEmployeeRequestsExperienceTypes(employeeId, date))
		dispatch(refreshEmployeeRequestsWorkingCycleForDate(employeeId, date))
		dispatch({ type: LOAD_EXPERIENCE_ADJUSTMENT_DATA })
	}
}

export function loadExperienceProfileData(employeeId) {
	return dispatch => {
		dispatch(refreshEmployeeDetail(employeeId))
		dispatch(refreshEmployeeActivities(employeeId))
		dispatch(refreshEmployeeCharts(employeeId))
		dispatch(refreshEmployeeCalendars(employeeId))
		dispatch({ type: LOAD_EXPERIENCE_PROFILE_DATA })
	}
}

export function loadExperienceEquipmentData() {
	return dispatch => {
		dispatch(refreshExperienceTypes())
		dispatch({ type: LOAD_EXPERIENCE_EQUIPMENT_DATA })
	}
}

export function loadExperienceSettingData() {
	return dispatch => {
		dispatch(refreshExperienceSetting())
		dispatch({ type: LOAD_EXPERIENCE_SETTING_DATA })
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshExperienceSetting())
		dispatch(refreshApproversFilter())
		dispatch(refreshExperienceTypesFilter())
		dispatch(refreshEmployees())
		dispatch(refreshExperienceRequests())
		dispatch(refreshRanks())
		dispatch(refreshGroupLeaderRoles())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		employeeMonthFilter: new Date().getMonth() + 1, // Current month with January as 1
		employeeYearFilter: new Date().getFullYear(), // Current year
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_EMPLOYEES:
		case REFRESHING_EMPLOYEE_DETAIL:
		case REFRESHING_EMPLOYEE_ACTIVITIES:
		case REFRESHING_EMPLOYEE_CHARTS:
		case REFRESHING_EMPLOYEE_CALENDARS:
		case REFRESHING_EMPLOYEE_REQUESTS:
		case REFRESHING_EMPLOYEE_REQUESTS_ACTIVITIES:
		case REFRESHING_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES:
		case REFRESHING_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE:
		case REFRESHING_EMPLOYEE_REQUESTS_COMMENTS:
		case REFRESHING_EXPERIENCE_REQUESTS:
		case REFRESHING_EXPERIENCE_SETTING:
		case REFRESHING_EXPERIENCE_TYPES:
		case REFRESHING_EXPERIENCE_TYPES_FILTER:
		case REFRESHING_APPROVERS_FILTER:
		case REFRESHING_EMPLOYEE_FILTERS:
		case REFRESHING_UNADDED_EMPLOYEES:
		case REFRESHING_RANKS:
		case REFRESHING_GROUP_LEADER_ROLES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_EMPLOYEES:
		case REFRESHED_EMPLOYEE_DETAIL:
		case REFRESHED_EMPLOYEE_ACTIVITIES:
		case REFRESHED_EMPLOYEE_CHARTS:
		case REFRESHED_EMPLOYEE_CALENDARS:
		case REFRESHED_EMPLOYEE_REQUESTS:
		case REFRESHED_EMPLOYEE_REQUESTS_ACTIVITIES:
		case REFRESHED_EMPLOYEE_REQUESTS_EXPERIENCE_TYPES:
		case REFRESHED_EMPLOYEE_REQUESTS_WORKING_CYCLE_FOR_DATE:
		case REFRESHED_EMPLOYEE_REQUESTS_COMMENTS:
		case REFRESHED_EXPERIENCE_REQUESTS:
		case REFRESHED_EXPERIENCE_SETTING:
		case REFRESHED_EXPERIENCE_TYPES:
		case REFRESHED_EXPERIENCE_TYPES_FILTER:
		case REFRESHED_APPROVERS_FILTER:
		case REFRESHED_EMPLOYEE_FILTERS:
		case REFRESHED_UNADDED_EMPLOYEES:
		case REFRESHED_RANKS:
		case REFRESHED_GROUP_LEADER_ROLES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case ADDED_EMPLOYEES:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employees()]: [...state.api[API_URLS.employees()], ...action.employeeWithApproversList],
				},
			}
		case REMOVED_EMPLOYEES:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employees()]: state.api[API_URLS.employees()].filter(
						employeeWithApprover => !action.employeeIds.includes(employeeWithApprover.employee.id)
					),
				},
			}
		case EDITED_EMPLOYEE_EXPERIENCES:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employees()]: state.api[API_URLS.employees()].map(employeeWithApprover => {
						if (employeeWithApprover.employee.id === action.employeeId) {
							return {
								...employeeWithApprover,
								experienceTypeIds: action.experienceTypeIds,
							}
						}
						return employeeWithApprover
					}),
				},
			}
		case EDITED_EMPLOYEE_APPROVERS:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.employees()]: state.api[API_URLS.employees()].map(employeeWithApprover => {
						if (employeeWithApprover.employee.id === action.employeeId) {
							return {
								...employeeWithApprover,
								approvers: action.approvers,
							}
						}
						return employeeWithApprover
					}),
				},
			}
		case CHANGE_EMPLOYEE_MONTH_FILTER:
			return {
				...state,
				data: {
					...state.data,
					employeeMonthFilter: action.month,
				},
			}
		case CHANGE_EMPLOYEE_YEAR_FILTER:
			return {
				...state,
				data: {
					...state.data,
					employeeYearFilter: action.year,
				},
			}
		case EDITED_EXPERIENCE_SETTING:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CREATED_EXPERIENCE_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.experienceTypes()]: [
						...state.api[API_URLS.experienceTypes()],
						{ ...action.experienceType, employeeCount: 0, allowDelete: true },
					],
				},
			}
		case EDITED_EXPERIENCE_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.experienceTypes()]: state.api[API_URLS.experienceTypes()].map(experienceType => {
						if (experienceType.id === action.experienceType.id) {
							return {
								...experienceType,
								notes: action.experienceType.notes,
							}
						}
						return experienceType
					}),
				},
			}
		case DELETED_EXPERIENCE_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.experienceTypes()]: state.api[API_URLS.experienceTypes()].filter(
						experienceType => experienceType.id !== action.experienceTypeId
					),
				},
			}
		case ARCHIVE_EXPERIENCE_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.experienceTypes()]: state.api[API_URLS.experienceTypes()].map(experienceType => {
						if (experienceType.id === action.experienceTypeId) {
							return {
								...experienceType,
								isArchived: true,
							}
						}
						return experienceType
					}),
				},
			}
		case UNARCHIVE_EXPERIENCE_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.experienceTypes()]: state.api[API_URLS.experienceTypes()].map(experienceType => {
						if (experienceType.id === action.experienceTypeId) {
							return {
								...experienceType,
								isArchived: false,
							}
						}
						return experienceType
					}),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
