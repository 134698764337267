import { s, number } from 'modules/types'
import CalendarWorkingCycleTypeModel, { CalendarWorkingCycleTypeType } from './CalendarWorkingCycleType'

export class CalendarWorkingCycle {
	constructor({ day, working_cycle_type: workingCycleType }) {
		this.day = day
		if (workingCycleType) {
			this.workingCycleType = new CalendarWorkingCycleTypeModel(workingCycleType)
		}
	}
}

export const CalendarWorkingCycleType = s({
	day: number.isRequired,
	workingCycleType: CalendarWorkingCycleTypeType,
})

export default CalendarWorkingCycle
