import { s, number, string } from 'modules/types'
import Template, { TemplateType } from './Template'

export class AppraisalGroup {
	constructor({ id, name, template }) {
		this.id = id
		this.name = name
		this.template = new Template(template)
	}
}

export const AppraisalGroupType = s({
	id: number.isRequired,
	name: string.isRequired,
	template: TemplateType,
})

export default AppraisalGroup
