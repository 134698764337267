import store from 'store/createStore'
// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const CHANGE_ALERT_PROPS = 'CHANGE_ALERT_PROPS'

// ------------------------------------
// Actions
// ------------------------------------

export function showAlert({
	input = false,
	required = true,
	placeholder = '',
	validationMsg,
	title,
	children,
	confirmClass = 'success',
	cancelClass = 'default simple',
	confirmBtnText,
	cancelBtnText,
	onConfirm,
	onCancel = hideAlert,
	style = {},
	success,
	warning = false,
	showCancel = false,
	disabled = false,
}) {
	store.dispatch({
		type: SHOW_ALERT,
		input,
		required,
		placeholder,
		validationMsg,
		title,
		children,
		confirmClass,
		cancelClass,
		confirmBtnText,
		cancelBtnText,
		onConfirm,
		onCancel,
		style,
		success,
		warning,
		showCancel,
		disabled,
	})
}

export function changeAlertProps(props) {
	store.dispatch({ type: CHANGE_ALERT_PROPS, props })
	return Promise.resolve()
}

export function hideAlert() {
	store.dispatch({ type: HIDE_ALERT })
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	alertProps: null,
}

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case SHOW_ALERT:
			return {
				...state,
				alertProps: {
					...state.alertProps,
					input: action.input,
					required: action.required,
					validationMsg: action.validationMsg,
					placeholder: action.placeholder,
					showCancel: action.showCancel,
					success: action.success,
					warning: action.warning,
					title: action.title,
					confirmClass: action.confirmClass,
					cancelClass: action.cancelClass,
					confirmBtnText: action.confirmBtnText,
					cancelBtnText: action.cancelBtnText,
					style: action.style,
					onConfirm: action.onConfirm,
					onCancel: action.onCancel,
					disabled: action.disabled,
					children: action.children,
				},
			}
		case HIDE_ALERT:
			return {
				...state,
				alertProps: null,
			}
		case CHANGE_ALERT_PROPS:
			return {
				...state,
				alertProps: {
					...state.alertProps,
					...action.props,
					children: action.children,
				},
			}
		default:
			return state
	}
}
