import { s, arrayOf, oneOfType, number, string } from 'modules/types'
import PreviousStageAnswer, { PreviousStageAnswerType } from './PreviousStageAnswer'

export class QuestionWithPreviousStageAnswer {
	constructor({
		question_link_id: questionLinkId,
		answer,
		notes,
		previous_stages: previousStages,
		default_question_link_id: defaultQuestionLinkId,
	}) {
		this.questionLinkId = questionLinkId
		this.answer = answer
		this.notes = notes
		this.previousStages = previousStages.map(item => new PreviousStageAnswer(item))
		this.defaultQuestionLinkId = defaultQuestionLinkId
	}
}

export const QuestionWithPreviousStageAnswerType = s({
	questionLinkId: number.isRequired,
	answer: oneOfType([number, string]),
	notes: string,
	previousStages: arrayOf(PreviousStageAnswerType),
	defaultQuestionLinkId: number.isRequired,
})

export default QuestionWithPreviousStageAnswer
