import { s, number } from 'modules/types'

export class EmployeeTimeoffSetting {
	constructor({
		timeoff_group_id: timeoffGroupId,
		working_cycle_group_id: workingCycleGroupId,
		holiday_group_id: holidayGroupId,
	}) {
		this.timeoffGroupId = timeoffGroupId
		this.workingCycleGroupId = workingCycleGroupId
		this.holidayGroupId = holidayGroupId
	}
}

export const EmployeeTimeoffSettingType = s({
	timeoffGroupId: number,
	workingCycleGroupId: number,
	holidayGroupId: number,
})

export default EmployeeTimeoffSetting
