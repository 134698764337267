import { s, arrayOf, number } from 'modules/types'
import TimeoffRule, { TimeoffRuleType } from './TimeoffRule'
import TimeoffType, { TimeoffTypeType } from './TimeoffType'

export class EmployeeBalance {
	constructor({
		timeoff_type: timeoffType,
		beginning_balance: beginningBalance,
		earned,
		taken,
		approved,
		manual_adjustment: manualAdjustment,
		final_balance: finalBalance,
		name,
		timeoff_type_name: timeoffTypeName,
		timeoff_rules: timeoffRules,
	}) {
		this.timeoffType = new TimeoffType(timeoffType)
		this.beginningBalance = beginningBalance
		this.earned = earned
		this.taken = taken
		this.approved = approved
		this.manualAdjustment = manualAdjustment
		this.finalBalance = finalBalance
		this.timeoffRules = timeoffRules.map(item => new TimeoffRule(item))
	}
}

export const EmployeeBalanceType = s({
	timeoffType: TimeoffTypeType,
	beginningBalance: number.isRequired,
	earned: number.isRequired,
	taken: number.isRequired,
	approved: number.isRequired,
	manualAdjustment: number.isRequired,
	finalBalance: number.isRequired,
	timeoffRules: arrayOf(TimeoffRuleType),
})

export default EmployeeBalance
