import { s, oneOfType, number, string } from 'modules/types'

export class AnswerSnapshot {
	constructor({ question_link_id: questionLinkId, answer, notes }) {
		this.questionLinkId = questionLinkId
		this.answer = answer
		this.notes = notes
	}
}

export const AnswerSnapshotType = s({
	questionLinkId: number.isRequired,
	answer: oneOfType([number, string]),
	notes: string,
})

export default AnswerSnapshot
