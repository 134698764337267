import { s, number, object, string } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class ExperienceRequestComment {
	constructor({ id, comment, created_by: createdBy, added }) {
		this.id = id
		this.comment = comment
		this.createdBy = new Employee(createdBy)
		this.added = new Date(added * 1000)
	}
}

export const ExperienceRequestCommentType = s({
	id: number.isRequired,
	comment: string.isRequired,
	createdBy: EmployeeType,
	added: object.isRequired,
})

export default ExperienceRequestComment
