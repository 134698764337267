import { s, arrayOf, number } from 'modules/types'
import Snapshot, { SnapshotType } from './Snapshot'

class SubSessionSnapshot {
	constructor({ id, snapshot }) {
		this.id = id
		this.snapshot = snapshot.map(item => new Snapshot(item))
	}
}

export const SubSessionSnapshotType = s({
	id: number.isRequired,
	snapshot: arrayOf(SnapshotType),
})

export default SubSessionSnapshot
