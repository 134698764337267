import ApproverTimeoffRequest from './ApproverTimeoffRequest'
import Calendar from './Calendar'
import DayCount from './DayCount'
import EmployeeCalendar from './EmployeeCalendar'
import TimeoffCalendar from './TimeoffCalendar'
import TimeoffComment from './TimeoffComment'
import TimeoffWallet from './TimeoffWallet'
import MyTimeoffRequest from './MyTimeoffRequest'
import EmployeeTimeoffProfile from './EmployeeTimeoffProfile'
import EmployeeTimeoffRequest from './EmployeeTimeoffRequest'
import EmployeeTimeoffRequestHistory from './EmployeeTimeoffRequestHistory'

export const TIMEOFF_REQUEST_STATUS = {
	PENDING: 1,
	APPROVED: 2,
	REJECTED: 3,
	CANCELLED: 4,
	TAKEN: 5,
}

export const TIMEOFF_REQUEST_LOG_TYPE = {
	CREATED: 1,
	APPROVED_FORWARDED: 2,
	APPROVED: 3,
	REJECTED: 4,
	COMMENTED: 5,
	EDITED_USER: 6,
	EDITED_HR: 7,
	CANCELLED_USER: 8,
	CANCELLED_HR: 9,
	BALANCE_DEDUCTED: 10,
	BALANCE_ADDED: 11,
	AUTO_APPROVE: 12,
	TAKEN: 13,
	DEDUCTED_SKIPPED: 14,
	CHANGED_APPROVER_LOG: 15,
}

export const CALENDAR_EVENT_DATE_TYPE = {
	PUBLIC_HOLIDAY: 1,
	TIMEOFF: 2,
}

export default {
	ApproverTimeoffRequest,
	Calendar,
	DayCount,
	EmployeeCalendar,
	TimeoffCalendar,
	TimeoffComment,
	TimeoffWallet,
	MyTimeoffRequest,
	EmployeeTimeoffProfile,
	EmployeeTimeoffRequest,
	EmployeeTimeoffRequestHistory,
}
