import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let TimeoffSettingComponent

const TimeoffSetting = store => {
	if (TimeoffSettingComponent === undefined) {
		TimeoffSettingComponent = Loadable({
			loader: () => {
				return import('views/TimeoffSetting/containers/TimeoffSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return TimeoffSettingComponent
}
export default TimeoffSetting
