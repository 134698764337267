import { s, arrayOf, number, object, string } from 'modules/types'
import GroupSnapshot, { GroupSnapshotType } from './GroupSnapshot'
import HolidayGroupSnapshot, { HolidayGroupSnapshotType } from './HolidayGroupSnapshot'
import HolidayRuleSnapshot, { HolidayRuleSnapshotType } from './HolidayRuleSnapshot'

export class HolidayGroupLogExtInfo {
	constructor({
		group = null,
		employee_ids: employeeIds = null,
		from_holiday_group: fromHolidayGroup = null,
		to_holiday_group: toHolidayGroup = null,
		old_name: oldName = null,
		new_name: newName = null,
		old_start_date: oldStartDate = null,
		new_start_date: newStartDate = null,
		serialized_new_rules: serializedNewRules = null,
		serialized_old_rules: serializedOldRules = null,
	}) {
		this.employeeIds = employeeIds
		this.oldName = oldName
		this.newName = newName
		if (oldStartDate) {
			this.oldStartDate = new Date(oldStartDate)
		}
		if (newStartDate) {
			this.newStartDate = new Date(newStartDate)
		}
		// CREATED
		if (group) {
			this.group = new GroupSnapshot(group)
		}
		// EMPLOYEE_MOVED_IN, EMPLOYEE_MOVED_OUT
		if (fromHolidayGroup) {
			this.fromHolidayGroup = new HolidayGroupSnapshot(fromHolidayGroup)
		}
		if (toHolidayGroup) {
			this.toHolidayGroup = new HolidayGroupSnapshot(toHolidayGroup)
		}
		// EDITED_RULES
		if (serializedOldRules) {
			this.serializedOldRules = serializedOldRules.map(item => new HolidayRuleSnapshot(item))
		}
		if (serializedNewRules) {
			this.serializedNewRules = serializedNewRules.map(item => new HolidayRuleSnapshot(item))
		}
	}
}

export const HolidayGroupLogExtInfoType = s({
	group: GroupSnapshotType,
	fromHolidayGroup: HolidayGroupSnapshotType,
	toHolidayGroup: HolidayGroupSnapshotType,
	employeeIds: arrayOf(number),
	oldName: string,
	newName: string,
	oldStartDate: object,
	newStartDate: object,
	serializedNewRules: arrayOf(HolidayRuleSnapshotType),
	serializedOldRules: arrayOf(HolidayRuleSnapshotType),
})

export default HolidayGroupLogExtInfo
