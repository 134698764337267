import { s, oneOfType, number, string } from 'modules/types'

export class PreviousStageAnswer {
	constructor({
		stage_id: stageId,
		group_stage_id: groupStageId,
		group_appraiser_type_type: groupAppraiserTypeType,
		group_appraiser_type_id: groupAppraiserTypeId,
		appraiser_name: appraiserName = null, // null if no superior or second-degree superior
		answer,
		stage_number: stageNumber,
	}) {
		this.stageId = stageId
		this.groupStageId = groupStageId
		this.groupAppraiserTypeType = groupAppraiserTypeType
		this.groupAppraiserTypeId = groupAppraiserTypeId
		if (appraiserName !== null) {
			this.appraiserName = appraiserName
		}
		this.answer = answer
		this.stageNumber = stageNumber
	}
}

export const PreviousStageAnswerType = s({
	stageId: number.isRequired,
	groupStageId: number.isRequired,
	groupAppraiserTypeType: number.isRequired,
	groupAppraiserTypeId: number.isRequired,
	appraiserName: string,
	answer: oneOfType([number, string]),
	stageNumber: number,
})

export default PreviousStageAnswer
