import { s, arrayOf, number, string } from 'modules/types'
import AnswerChoice, { AnswerChoiceType } from './AnswerChoice'
import _ from 'lodash'

export const APPRAISAL_QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
}

export const TYPE_SELECT_OPTIONS = [
	{
		content: 'Multiple choice',
		value: APPRAISAL_QUESTION_TYPE.RADIO,
	},
	{
		content: 'Short answer',
		value: APPRAISAL_QUESTION_TYPE.INPUT,
	},
	{
		content: 'Paragraph',
		value: APPRAISAL_QUESTION_TYPE.TEXT,
	},
	{
		content: 'Linear scale',
		value: APPRAISAL_QUESTION_TYPE.LINEAR,
	},
]

export const SIZE_SELECT_OPTIONS = _.range(10).map(item => ({ content: item + 1, value: item + 1 }))

export class Question {
	constructor({
		id,
		self_content: selfContent,
		alternative_content: altContent,
		type,
		linear_lowest_label: linearLowestLabel,
		linear_highest_label: linearHighestLabel,
		choices = null,
	}) {
		this.id = id
		this.selfContent = selfContent
		this.alternativeContent = altContent
		this.type = type
		this.linearLowestLabel = linearLowestLabel
		this.linearHighestLabel = linearHighestLabel
		if (choices !== null) {
			this.choices = choices.map(item => new AnswerChoice(item))
		}
	}
}

export const QuestionType = s({
	id: number.isRequired,
	selfContent: string.isRequired,
	alternativeContent: string.isRequired,
	type: number.isRequired, // @APPRAISAL_QUESTION_TYPE
	linearLowestLabel: string,
	linearHighestLabel: string,
	choices: arrayOf(AnswerChoiceType),
})

export default Question
