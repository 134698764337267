import { s, bool, number } from 'modules/types'
import TypeSnapshot, { TypeSnapshotType } from './TypeSnapshot'
import { TIMEOFF_RULE_PERIOD } from './TimeoffRule'

export class RuleSnapshot {
	constructor({ id, type, allowance, period, max_accumulation: maxAccumulation, allow_over_limit: allowOverLimit }) {
		this.id = id
		this.type = new TypeSnapshot(type)
		this.allowance = allowance
		this.period = period
		this.maxAccumulation = maxAccumulation
		this.allowOverLimit = allowOverLimit
	}

	displayPeriod() {
		switch (this.period) {
			case TIMEOFF_RULE_PERIOD.ANNUALLY:
				return 'ANNUALLY'
			case TIMEOFF_RULE_PERIOD.MONTHLY:
				return 'MONTHLY'
			case TIMEOFF_RULE_PERIOD.WEEKLY:
				return 'WEEKLY'
			case TIMEOFF_RULE_PERIOD.DAILY:
				return 'DAILY'
			default:
				throw new Error(`Unknown timeoff rule period: ${this.period}`)
		}
	}
}

export const RuleSnapshotType = s({
	id: number.isRequired,
	type: TypeSnapshotType,
	period: number,
	maxAccumulation: number,
	allowOverLimit: bool,
})

export default RuleSnapshot
