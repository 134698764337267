import { s, bool, number } from 'modules/types'

export class CreateTimeoffGroupRule {
	constructor({ timeoffTypeId, allowance, period, maxAccumulation, allowOverLimit, initialBalance = 0 }) {
		this.timeoff_type_id = timeoffTypeId
		this.allowance = parseFloat(allowance)
		this.period = period
		this.max_accumulation = maxAccumulation ? parseFloat(maxAccumulation) : 0
		this.allow_over_limit = allowOverLimit
		this.initial_balance = initialBalance
	}
}

export const CreateTimeoffGroupRuleType = s({
	timeoff_type_id: number,
	allowance: number,
	period: number,
	max_accumulation: number,
	allow_over_limit: bool,
	initialBalance: number,
})

export default CreateTimeoffGroupRule
