import _ from 'lodash'
import api from 'modules/api'
import { refreshCurrentUser } from 'store/session'
// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'vision-mission-setting'

export const API_URLS = {
	visionMission: () => `/api/setting/dashboard/get_vision_and_mission/`,
	companySettingLogs: () => `/api/setting/dashboard/get_company_setting_logs/`,
}

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`
const REFRESHING_COMPANY_SETTING_LOGS = `${MODULE_NAME} | REFRESHING_COMPANY_SETTING_LOGS`
const REFRESHED_VISION_MISSION = `${MODULE_NAME} | REFRESHED_VISION_MISSION`
const REFRESHED_COMPANY_SETTING_LOGS = `${MODULE_NAME} | REFRESHED_COMPANY_SETTING_LOGS`
const EDITED_VISION_MISSION = `${MODULE_NAME} | EDITED_VISION_MISSION`
const EDITED_COMPANY_SETTING = `${MODULE_NAME} | EDITED_COMPANY_SETTING`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshVisionMission() {
	return dispatch => {
		const apiUrl = API_URLS.visionMission()
		return api.setting.dashboard.getVisionMission().then(({ data }) => {
			dispatch({ type: REFRESHED_VISION_MISSION, data, apiUrl })
		})
	}
}

export function refreshCompanySettingLogs(page = 1) {
	return dispatch => {
		const apiUrl = API_URLS.companySettingLogs()
		dispatch({ type: REFRESHING_COMPANY_SETTING_LOGS, apiUrl })
		return api.setting.dashboard.getCompanySettingLogs(page).then(({ data: logs, pagination }) => {
			dispatch({ type: REFRESHED_COMPANY_SETTING_LOGS, apiUrl, pagination, data: logs })
			return logs
		})
	}
}

export function editVisionMission(visionMissionReadMore) {
	return dispatch => {
		const apiUrl = API_URLS.visionMission()
		return api.setting.dashboard.editVisionMission(visionMissionReadMore).then(({ data }) => {
			dispatch({ type: EDITED_VISION_MISSION, data, apiUrl })
			dispatch(refreshCompanySettingLogs())
		})
	}
}

export function editCompanySetting(data) {
	return dispatch => {
		return api.setting.dashboard.editCompanySetting(data).then(() => {
			dispatch({ type: EDITED_COMPANY_SETTING, data })
			dispatch(refreshCompanySettingLogs())
			if (data.companyLogo || data.birthdaySetting) {
				dispatch(refreshCurrentUser())
			}
		})
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshVisionMission())
		dispatch(refreshCompanySettingLogs())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_COMPANY_SETTING_LOGS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_VISION_MISSION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_COMPANY_SETTING_LOGS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				pagination: {
					...state.pagination,
					[action.apiUrl]: action.pagination,
				},
			}
		case EDITED_VISION_MISSION:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
