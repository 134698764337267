import { s, arrayOf, number } from 'modules/types'
import AnswerSnapshot, { AnswerSnapshotType } from './AnswerSnapshot'

export class SnapshotsWithEmployeeId {
	constructor({ employee_id: employeeId, snapshot }) {
		this.employeeId = employeeId
		this.snapshot = snapshot.map(item => new AnswerSnapshot(item))
	}
}

export const SnapshotsWithEmployeeIdType = s({
	employeeId: number.isRequired,
	snapshot: arrayOf(AnswerSnapshotType),
})

export default SnapshotsWithEmployeeId
