import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `surveyOngoing`

const REFRESHING_ONGOING_SUBSESSIONS = `${MODULE_NAME} | REFRESHING ONGOING SUBSESSIONS`
const REFRESHING_ONGOING_SUBSESSION_DETAIL = `${MODULE_NAME} | REFRESHING ONGOING SUBSESSION DETAIL`
const REFRESHING_COMPLETED_SUBSESSIONS = `${MODULE_NAME} | REFRESHING COMPLETED SUBSESSIONS`
const REFRESHING_COMPLETED_SUBSESSION_DETAIL = `${MODULE_NAME} | REFRESHING COMPLETED SUBSESSION DETAIL`
const REFRESHED_ONGOING_SUBSESSIONS = `${MODULE_NAME} | REFRESHED ONGOING SUBSESSIONS`
const REFRESHED_ONGOING_SUBSESSION_DETAIL = `${MODULE_NAME} | REFRESHED ONGOING SUBSESSION DETAIL`

const SUBMIT_SUBSESSION = `${MODULE_NAME} | SUBMIT SUBSESSION`

const SELECT_SUBSESSION = `${MODULE_NAME} | SELECT SUBSESSION`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshOngoingSubSessions() {
	return dispatch => {
		const apiUrl = `/api/survey/ongoing/`
		dispatch({ type: REFRESHING_ONGOING_SUBSESSIONS, apiUrl })
		return api.survey.ongoing.getSurveySession().then(({ success, data }) => {
			dispatch({ type: REFRESHED_ONGOING_SUBSESSIONS, data, apiUrl })
			if (data.length > 0) {
				dispatch(selectSubSession(data[0]))
			}
		})
	}
}

export function refreshOngoingSubsessionDetail(sessionId, subSessionId) {
	return dispatch => {
		const apiUrl = `/api/survey/ongoing/${sessionId}/${subSessionId}/`
		dispatch({ type: REFRESHING_ONGOING_SUBSESSION_DETAIL, apiUrl })
		return api.survey.ongoing.getSurveySubSession(sessionId, subSessionId).then(({ success, data }) => {
			dispatch({ type: REFRESHED_ONGOING_SUBSESSION_DETAIL, data, apiUrl })
		})
	}
}

export function submitSubSession(sessionId, subSessionId, answers) {
	return dispatch => {
		return api.survey.ongoing.submitSubSession(sessionId, subSessionId, answers).then(() => {
			dispatch({ type: SUBMIT_SUBSESSION })
			dispatch(refreshOngoingSubSessions())
			toast(`Successfully submitted`)
		})
	}
}

export function selectSubSession(subSessionSimple) {
	return dispatch => {
		dispatch({ type: SELECT_SUBSESSION, subSessionSimple })
		dispatch(refreshOngoingSubsessionDetail(subSessionSimple.session.id, subSessionSimple.id))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshOngoingSubSessions())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		selectedSubSessionSimple: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_ONGOING_SUBSESSIONS:
		case REFRESHING_ONGOING_SUBSESSION_DETAIL:
		case REFRESHING_COMPLETED_SUBSESSIONS:
		case REFRESHING_COMPLETED_SUBSESSION_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_ONGOING_SUBSESSIONS:
		case REFRESHED_ONGOING_SUBSESSION_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case SELECT_SUBSESSION:
			return {
				...state,
				data: {
					...state.data,
					selectedSubSessionSimple: action.subSessionSimple,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
