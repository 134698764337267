import { s, number, string } from 'modules/types'
import { Session, SessionType } from './Session'

export class SubSession {
	constructor({ id, employee_name: employeeName, session }) {
		this.id = id
		this.employeeName = employeeName
		this.session = new Session(session)
	}
}

export const SubSessionType = s({
	id: number.isRequired,
	employeeName: string,
	session: SessionType,
})

export default SubSession
