import { s, number, string } from 'modules/types'

export class Employee {
	constructor({ id, name, NIK, profile_picture: profilePicture = null }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		if (profilePicture !== null) {
			this.profilePicture = profilePicture
		}
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	profilePicture: string,
})

export default Employee
