import { s, arrayOf, number, string } from 'modules/types'
import TimeoffWallet, { TimeoffWalletType } from './TimeoffWallet'

export class EmployeeTimeoffProfile {
	constructor({
		id,
		NIK,
		profile_picture_url: picture,
		full_name: fullName,
		title_name: titleName,
		department_name: departmentName,
		rank_name: rankName,
		group_name: groupName,
		timeoff_wallets: timeoffWallets,
	}) {
		this.id = id
		this.NIK = NIK
		this.picture = picture
		this.fullName = fullName
		this.titleName = titleName
		this.departmentName = departmentName
		this.rankName = rankName
		this.groupName = groupName
		this.timeoffWallets = timeoffWallets.map(item => new TimeoffWallet(item))
	}
}

export const EmployeeTimeoffProfileType = s({
	id: number.isRequired,
	NIK: string.isRequired,
	picture: string,
	fullName: string.isRequired,
	titleName: string.isRequired,
	departmentName: string.isRequired,
	rankName: string.isRequired,
	groupName: string.isRequired,
	timeoffWallets: arrayOf(TimeoffWalletType),
})

export default EmployeeTimeoffProfile
