import { s, number, string } from 'modules/types'
import { Employee, EmployeeType } from './Employee'
import { EmployeeDetail, EmployeeDetailType } from './EmployeeDetail'

export const STATUS_TEXT = {
	NOT_STARTED: 'Not Started',
	IN_PROGRESS: 'In Progress',
	COMPLETED: 'Completed',
}

export const PARTICIPANT_ORDER_TYPE = {
	NAME: 0,
	NIK: 1,
	SUPERIOR_NAME: 2,
	TITLE: 3,
	DEPARTMENT: 4,
	STATUS: 5,
	GROUP: 6,
}

export const PARTICIPANT_ORDER_DIRECTION = {
	ASCENDING: 0,
	DESCENDING: 1,
}

export class Participant {
	constructor({ id, employee, direct_supervisor: directSupervisor, status_text: statusText }) {
		this.id = id
		this.directSupervisor = directSupervisor && new Employee(directSupervisor)
		this.employee = new EmployeeDetail(employee)
		this.statusText = statusText
	}
}

export const ParticipantType = s({
	id: number.isRequired,
	directSupervisor: EmployeeType,
	employee: EmployeeDetailType.isRequired,
	statusText: string.isRequired,
})

export default Participant
