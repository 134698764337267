import { s, number } from 'modules/types'

export class CreateDefaultTemplateQuestionLink {
	constructor({ questionId: question_id, weight }) {
		this.question_id = question_id
		this.weight = weight
	}
}

export const CreateDefaultTemplateQuestionLinkType = s({
	question_id: number.isRequired,
	weight: number,
})

export default CreateDefaultTemplateQuestionLink
