import { s, number, string } from 'modules/types'

export class QuestionAnswerChoice {
	constructor({ id, content, order }) {
		this.id = id
		this.content = content
		this.order = order
	}
}

export const QuestionAnswerChoiceType = s({
	id: number.isRequired,
	content: string.isRequired,
	order: number.isRequired,
})

export default QuestionAnswerChoice
