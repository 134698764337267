import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
import _ from 'lodash'
import { CREATE_ACTION } from '../../Idea/modules/idea'
import { BIRTHDAY_SETTING } from 'modules/models/setting/dashboard'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `dashboard`

const REFRESHING_COMPANY_DETAILS = `${MODULE_NAME} | REFRESHING COMPANY DETAILS`
const REFRESHED_COMPANY_DETAILS = `${MODULE_NAME} | REFRESHED COMPANY DETAILS`
const REFRESHING_BIRTHDAYS = `${MODULE_NAME} | REFRESHING BIRTHDAYS`
const REFRESHED_BIRTHDAYS = `${MODULE_NAME} | REFRESHED BIRTHDAYS`
const REFRESHING_ANNOUNCEMENTS = `${MODULE_NAME} | REFRESHING ANNOUNCEMENTS`
const REFRESHING_ANNOUNCEMENT_DETAIL = `${MODULE_NAME} | REFRESHING ANNOUNCEMENT DETAIL`
const REFRESHED_ANNOUNCEMENTS = `${MODULE_NAME} | REFRESHED ANNOUNCEMENTS`
const REFRESHED_ANNOUNCEMENT_DETAIL = `${MODULE_NAME} | REFRESHED ANNOUNCEMENT DETAIL`
const REFRESHING_NEWS = `${MODULE_NAME} |REFRESHING NEWS`
// const REFRESHED_NEWS = `${MODULE_NAME} | REFRESHED NEWS`

const LOAD_MORE_ANNOUNCEMENTS = `${MODULE_NAME} | LOAD MORE ANNOUNCEMENTS`
// const LOADED_MORE_NEWS = `${MODULE_NAME} | LOADED MORE NEWS`

const CREATED_BIRTHDAY_NOTE = `${MODULE_NAME} | CREATED BIRTHDAY NOTE`
const CREATED_QUESTION = `${MODULE_NAME} | CREATED QUESTION`
const CREATED_IDEA = `${MODULE_NAME} | CREATED IDEA`
// const CREATED_NEWS = `${MODULE_NAME} | CREATED NEWS`

const OPEN_COMPANY_PAGE = `${MODULE_NAME} | OPEN COMPANY PAGE`
const CLOSE_COMPANY_PAGE = `${MODULE_NAME} | CLOSE COMPANY PAGE`
// const CHANGED_NEWS_CATEGORY = `${MODULE_NAME} | CHANGED NEWS CATEGORY`
const CHANGED_FEED_VIEW = `${MODULE_NAME} | CHANGED FEED VIEW`
const OPEN_ANNOUNCEMENT = `${MODULE_NAME} | OPEN ANNOUNCEMENT`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

export const FEED_VIEW = {
	NEWS: 0,
	ANNOUNCEMENTS: 1,
}

const API_URLS = {
	announcements: () => `/api/announcements/`,
	announcementDetail: announcementId => `/api/announcements/${announcementId}/`,
	birthdays: () => `/api/dashboard/get_birthdays/`,
	companyDetails: () => `/api/dashboard/get_company_details/`,
}

// ------------------------------------
// Actions
// ------------------------------------

export function loadModule() {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { company_birthday_setting },
			},
		} = getState()
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshCompanyDetails())
		if (company_birthday_setting !== BIRTHDAY_SETTING.OFF) {
			dispatch(refreshBirthdays())
		}
		// dispatch(refreshNews())
		dispatch(refreshAnnouncements())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

export function refreshAnnouncements() {
	return dispatch => {
		let apiUrl = API_URLS.announcements()
		dispatch({ type: REFRESHING_ANNOUNCEMENTS, apiUrl })
		return api.announcement.get().then(({ data }) => {
			dispatch({ type: REFRESHED_ANNOUNCEMENTS, data: data, apiUrl })
		})
	}
}

function refreshAnnouncementDetail(announcementId) {
	return dispatch => {
		let apiUrl = API_URLS.announcementDetail(announcementId)
		dispatch({ type: REFRESHING_ANNOUNCEMENT_DETAIL, apiUrl })
		return api.announcement.getDetail(announcementId).then(({ data }) => {
			dispatch({ type: REFRESHED_ANNOUNCEMENT_DETAIL, data, apiUrl })
		})
	}
}

export function loadMoreAnnouncements() {
	return dispatch => {
		dispatch({ type: LOAD_MORE_ANNOUNCEMENTS })
	}
}

export function refreshBirthdays() {
	return dispatch => {
		let apiUrl = API_URLS.birthdays()
		dispatch({ type: REFRESHING_BIRTHDAYS, apiUrl })
		return api.dashboard.getBirthdays().then(({ data }) => {
			dispatch({ type: REFRESHED_BIRTHDAYS, data, apiUrl })
		})
	}
}

export function refreshCompanyDetails() {
	return dispatch => {
		let apiUrl = API_URLS.companyDetails()
		dispatch({ type: REFRESHING_COMPANY_DETAILS, apiUrl })
		return api.dashboard.getCompanyDetails().then(({ data }) => {
			dispatch({ type: REFRESHED_COMPANY_DETAILS, data, apiUrl })
		})
	}
}

// export function refreshNews (category = -1, page = 1) {
// 	return (dispatch) => {
// 		let apiUrl = `/api/dashboard/news/`
// 		dispatch({ type: REFRESHING_NEWS, apiUrl })
// 		return api.dashboard.news.get(category, page)
// 			.then(({ data, pagination }) => {
// 				dispatch({ type: REFRESHED_NEWS, data, pagination, apiUrl })
// 			})
// 	}
// }

// export function loadMoreNews (category) {
// 	return (dispatch, getState) => {
// 		const apiUrl = `/api/dashboard/news/`
// 		const { dashboard: { pagination } } = getState()
// 		const { currentPage } = getObjectOrUndefined(pagination, apiUrl)
// 		return api.dashboard.news.get(category, currentPage + 1)
// 			.then(({ data, pagination }) => {
// 				dispatch({ type: LOADED_MORE_NEWS, data, pagination, apiUrl })
// 			})
// 	}
// }

export function createQuestion(formData) {
	return dispatch => {
		return api.brainstorm.questions.create(formData).then(({ data }) => {
			dispatch({ type: CREATED_QUESTION, data })
			toast('Successfully posted new question')
		})
	}
}

export function createBirthdayNote(employeeId, note) {
	return dispatch => {
		return api.dashboard.createBirthdayNote(employeeId, note).then(() => {
			dispatch({ type: CREATED_BIRTHDAY_NOTE })
		})
	}
}

export function createIdea(formData, action) {
	return dispatch => {
		return api.workstation.idea.ideas.create(formData).then(() => {
			dispatch({ type: CREATED_IDEA })
			if (action === CREATE_ACTION.SAVE_AS_DRAFT) {
				toast('Successfully saved idea')
			} else {
				toast('Successfully submitted idea')
			}
		})
	}
}

// export function createNews ({ url, category }) {
// 	return (dispatch) => {
// 		return api.dashboard.news.create({ url, category })
// 			.then(({ data }) => {
// 				dispatch({ type: CREATED_NEWS, data })
// 				dispatch(refreshNews(category))
// 				toast('Successfully posted news')
// 			})
// 	}
// }

export function openCompanyPage() {
	return dispatch => {
		dispatch({ type: OPEN_COMPANY_PAGE })
	}
}

export function closeCompanyPage() {
	return dispatch => {
		dispatch({ type: CLOSE_COMPANY_PAGE })
	}
}

export function openAnnouncement(announcementId) {
	return (dispatch, getState) => {
		const {
			dashboard: { api },
		} = getState()
		dispatch({ type: OPEN_ANNOUNCEMENT, announcementId })
		if (announcementId && !getObjectOrUndefined(api, API_URLS.announcementDetail(announcementId))) {
			dispatch(refreshAnnouncementDetail(announcementId))
		}
	}
}

// export function changeNewsCategory (category) {
// 	return (dispatch) => {
// 		dispatch(refreshNews(category))
// 		dispatch({ type: CHANGED_NEWS_CATEGORY })
// 	}
// }

// export function changeFeedView (view) {
// 	return (dispatch, getState) => {
// 		const newsUrl = `/api/dashboard/news/`
// 		const announcementUrl = `/api/dashboard/announcements/`
// 		const { dashboard: { api } } = getState()
// 		const news = getObjectOrUndefined(api, newsUrl)
// 		const announcements = getObjectOrUndefined(api, announcementUrl)
// 		if (view === FEED_VIEW.ANNOUNCEMENTS && announcements === undefined) {
// 			dispatch(refreshAnnouncements())
// 		} else if (view === FEED_VIEW.NEWS && news === undefined) {
// 			dispatch(refreshNews())
// 		}
// 		dispatch({ type: CHANGED_FEED_VIEW, view })
// 	}
// }

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		isCompanyPageOpen: false,
		openedAnnouncementId: undefined,
		announcements: undefined,
		// feedView: FEED_VIEW.NEWS, // @FEED_VIEW
	},
	pagination: {
		announcements: 1,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_ANNOUNCEMENTS:
		case REFRESHING_ANNOUNCEMENT_DETAIL:
		case REFRESHING_BIRTHDAYS:
		case REFRESHING_COMPANY_DETAILS:
		case REFRESHING_NEWS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_BIRTHDAYS:
		case REFRESHED_COMPANY_DETAILS:
		case REFRESHED_ANNOUNCEMENT_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_ANNOUNCEMENTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				data: {
					...state.data,
					announcements: _.take(action.data, state.pagination.announcements * 5),
				},
			}
		// case LOADED_MORE_NEWS:
		case LOAD_MORE_ANNOUNCEMENTS:
			return {
				...state,
				data: {
					...state.data,
					announcements: _.take(state.api[API_URLS.announcements()], (state.pagination.announcements + 1) * 5),
				},
				pagination: {
					...state.pagination,
					announcements: state.pagination.announcements + 1,
				},
			}
		case OPEN_COMPANY_PAGE:
			return {
				...state,
				data: {
					...state.data,
					isCompanyPageOpen: true,
				},
			}
		case CLOSE_COMPANY_PAGE:
			return {
				...state,
				data: {
					...state.data,
					isCompanyPageOpen: false,
				},
			}
		case CHANGED_FEED_VIEW:
			return {
				...state,
				data: {
					...state.data,
					feedView: action.view,
				},
			}
		case OPEN_ANNOUNCEMENT:
			return {
				...state,
				data: {
					...state.data,
					openedAnnouncementId: action.announcementId,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
