import { s, number, string } from 'modules/types'

export class Session {
	constructor({ id, name, description }) {
		this.id = id
		this.name = name
		this.description = description
	}
}

export const SessionType = s({
	id: number.isRequired,
	name: string.isRequired,
	description: string,
})

export default Session
