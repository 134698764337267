import { s, bool, string } from 'modules/types'

export class AllowDelete {
	constructor({ is_allowed: isAllowed, error }) {
		this.isAllowed = isAllowed
		this.error = error
	}
}

export const AllowDeleteType = s({
	isAllowed: bool.isRequired,
	error: string,
})

export default AllowDelete
