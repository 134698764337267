import { s, number } from 'modules/types'
import TimeoffSummaryGroup, { TimeoffSummaryGroupType } from './TimeoffSummaryGroup'
import { TIMEOFF_RULE_PERIOD } from './TimeoffRule'

export class TimeoffSummaryRule {
	constructor({ id, allowance, period, group, employee_count: employeeCount, initial_balance: initialBalance }) {
		this.id = id
		this.allowance = allowance
		this.period = period
		this.group = new TimeoffSummaryGroup(group)
		this.employeeCount = employeeCount
		this.initialBalance = initialBalance
	}

	renderPeriodDisplay() {
		switch (this.period) {
			case TIMEOFF_RULE_PERIOD.ANNUALLY:
				return 'ANNUALLY'
			case TIMEOFF_RULE_PERIOD.MONTHLY:
				return 'MONTHLY'
			case TIMEOFF_RULE_PERIOD.WEEKLY:
				return 'WEEKLY'
			case TIMEOFF_RULE_PERIOD.DAILY:
				return 'DAILY'
			default:
				throw new Error(`Unknown timeoff rule period: ${this.period}`)
		}
	}
}

export const TimeoffSummaryRuleType = s({
	id: number.isRequired,
	allowance: number,
	period: number, // @TimeoffRule TIMEOFF_RULE_PERIOD
	group: TimeoffSummaryGroupType,
	employeeCount: number,
	initialBalance: number,
})

export default TimeoffSummaryRule
