import { s, number, object, string } from 'modules/types'

class ExtInfo {
	constructor({
		prevVision,
		newVision,
		prevMission,
		newMission,
		prevSetting,
		newSetting,
		prevLogo,
		newLogo,
		prevReadMore,
		newReadMore,
	}) {
		this.prevVision = prevVision
		this.newVision = newVision
		this.prevMission = prevMission
		this.newMission = newMission
		this.prevSetting = prevSetting
		this.newSetting = newSetting
		this.prevLogo = prevLogo
		this.newLogo = newLogo
		this.prevReadMore = prevReadMore
		this.newReadMore = newReadMore
	}
}

const ExtInfoType = s({
	prevVision: string,
	newVision: string,
	prevMission: string,
	newMission: string,
	prevSetting: string,
	newSetting: string,
	prevLogo: string,
	newLogo: string,
	prevReadMore: string,
	newReadMore: string,
})

class Employee {
	constructor({ id, fullName, picture }) {
		this.id = id
		this.fullName = fullName
		this.picture = picture
	}
}

const EmployeeType = s({
	id: number.isRequired,
	fullName: string.isRequired,
	picture: string,
})

export class CompanySettingLog {
	constructor({ id, operator, type, extInfo, added }) {
		this.id = id
		this.operator = new Employee(operator)
		this.extInfo = new ExtInfo(extInfo)
		this.added = new Date(added)
		this.type = type
	}
}

export const CompanySettingLogType = s({
	id: number.isRequired,
	operator: EmployeeType,
	extInfo: ExtInfoType,
	added: object.isRequired,
	type: number.isRequired,
})

export default CompanySettingLog
