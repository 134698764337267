import { s, arrayOf, number, string } from 'modules/types'
import GroupSnapshot, { GroupSnapshotType } from './GroupSnapshot'
import TimeoffGroupSnapshot, { TimeoffGroupSnapshotType } from './TimeoffGroupSnapshot'
import RuleSnapshot, { RuleSnapshotType } from './RuleSnapshot'

export class TimeoffGroupLogExtInfo {
	constructor({
		group = null,
		employee_ids: employeeIds = null,
		from_timeoff_group: fromTimeoffGroup = null,
		to_timeoff_group: toTimeoffGroup = null,
		old_name: oldName = null,
		new_name: newName = null,
		serialized_new_rules: serializedNewRules = null,
		serialized_old_rules: serializedOldRules = null,
	}) {
		this.oldName = oldName
		this.newName = newName
		this.employeeIds = employeeIds
		// CREATED
		if (group) {
			this.group = new GroupSnapshot(group)
		}
		// EMPLOYEE_MOVED_IN, EMPLOYEE_MOVED_OUT
		if (fromTimeoffGroup) {
			this.fromTimeoffGroup = new TimeoffGroupSnapshot(fromTimeoffGroup)
		}
		if (toTimeoffGroup) {
			this.toTimeoffGroup = new TimeoffGroupSnapshot(toTimeoffGroup)
		}
		// EDITED_RULES
		if (serializedOldRules) {
			this.serializedOldRules = serializedOldRules.map(item => new RuleSnapshot(item))
		}
		if (serializedNewRules) {
			this.serializedNewRules = serializedNewRules.map(item => new RuleSnapshot(item))
		}
	}
}

export const TimeoffGroupLogExtInfoType = s({
	group: GroupSnapshotType,
	fromTimeoffGroup: TimeoffGroupSnapshotType,
	toTimeoffGroup: TimeoffGroupSnapshotType,
	employeeIds: arrayOf(number),
	oldName: string,
	newName: string,
	serializedNewRules: arrayOf(RuleSnapshotType),
	serializedOldRules: arrayOf(RuleSnapshotType),
})

export default TimeoffGroupLogExtInfo
