import React from 'react'
import SweetAlert from 'components/SweetAlert'
import skleemi18n from 'utils/skleemi18n'

const i18nDict = {
	NEW_VERSION: {
		id: 'Versi Baru',
		en: 'New Version',
	},
	PLEASE_REFRESH: {
		id: 'Kami telah merilis versi terbaru dari Skleem. Silakan perbarui halaman ini untuk menggunakan versi terbaru!',
		en: 'We have deployed a new version of Skleem. Please refresh your page to use the latest version!',
	},
	LOADING: {
		id: 'Memuat...',
		en: 'Loading...',
	},
	REFRESH: {
		id: 'Perbarui',
		en: 'Refresh',
	},
	ERROR: {
		id: 'Galat!',
		en: 'Error!',
	},
	RETRY: {
		id: 'Coba lagi',
		en: 'Retry',
	},
}

const RouteLoader = props => {
	if (props.error) {
		if (/Loading chunk \d+ failed./.test(props.error.message)) {
			return (
				<SweetAlert
					warning
					title={props.translater.NEW_VERSION}
					onConfirm={() => window.location.reload()}
					confirmBtnText={props.translater.REFRESH}
				>
					{props.translater.PLEASE_REFRESH}
				</SweetAlert>
			)
		} else {
			console.log(props.error)
			return (
				<div>
					{props.translater.ERROR}
					<button onClick={props.retry}>{props.translater.RETRY}</button>
				</div>
			)
		}
	} else if (props.pastDelay) {
		return <div>{props.translater.LOADING}</div>
	} else {
		return null
	}
}

export default skleemi18n(RouteLoader, i18nDict)
