import { s, arrayOf, bool, number, string } from 'modules/types'
import { getColorHexCode } from 'utils'

class TimeoffType {
	constructor({ id, name, color }) {
		this.id = id
		this.name = name
		this.color = color
		this.colorHexCode = getColorHexCode(color)
	}
}

const TimeoffTypeType = s({
	id: number.isRequired,
	name: string.isRequired,
	color: number.isRequired, // @TIMEOFF_TYPE_COLOR
})

class TimeoffRequest {
	constructor({
		id,
		start_date: startDate,
		end_date: endDate,
		start_half_day: startHalfDay,
		end_half_day: endHalfDay,
		status,
	}) {
		this.id = id
		this.startDate = startDate
		this.endDate = endDate
		this.startHalfDay = startHalfDay
		this.endHalfDay = endHalfDay
		this.status = status
	}
}

const TimeoffRequestType = s({
	id: number.isRequired,
	startDate: string,
	endDate: string,
	startHalfDay: bool.isRequired,
	endHalfDay: bool.isRequired,
	status: number.isRequired, // @TIMEOFF_REQUEST_STATUS
})

class TimeoffCreatedBy {
	constructor({ id, full_name: fullName }) {
		this.id = id
		this.fullName = fullName
	}
}

const TimeoffCreatedByType = s({
	id: number.isRequired,
	fullName: string.isRequired,
})

class TimeoffComment {
	constructor({ id, comment, created_by: createdBy }) {
		this.id = id
		this.comment = comment
		this.createdBy = new TimeoffCreatedBy(createdBy)
	}
}

const TimeoffCommentType = s({
	id: number.isRequired,
	comment: string.isRequired,
	createdBy: TimeoffCreatedByType,
})

export class TimeoffExtInfo {
	constructor({ timeoff_type: timeoffType, timeoff_request: timeoffRequest, timeoff_comments: timeoffComments }) {
		this.timeoffType = new TimeoffType(timeoffType)
		this.timeoffRequest = new TimeoffRequest(timeoffRequest)
		this.timeoffComments = timeoffComments.map(item => new TimeoffComment(item))
	}
}

export const TimeoffExtInfoType = s({
	timeoffType: TimeoffTypeType,
	timeoffRequest: TimeoffRequestType,
	comments: arrayOf(TimeoffCommentType),
})

export default TimeoffExtInfo
