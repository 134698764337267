import { s, number, object, string } from 'modules/types'

export class SessionSimple {
	constructor({ id, name, start_date: startDate, end_date: endDate }) {
		this.id = id
		this.name = name
		this.startDate = new Date(startDate)
		this.endDate = new Date(endDate)
	}
}

export const SessionSimpleType = s({
	id: number.isRequired,
	name: string,
	startDate: object.isRequired,
	endDate: object.isRequired,
})

export default SessionSimple
