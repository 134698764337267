import SubordinateExperienceRequest from './SubordinateExperienceRequest'
import MyExperienceRequest from './MyExperienceRequest'
import ExperienceSetting from './ExperienceSetting'
import ExperienceWallet from './ExperienceWallet'
import EmployeeWithWallets from './EmployeeWithWallets'

export const EXPERIENCE_REQUEST_STATUS = {
	PENDING: 1,
	APPROVED: 2,
	REJECTED: 3,
}

export const EXPERIENCE_APPROVER_TYPE = {
	IN_ORDER: 1,
	SUBSTITUTE: 2,
}

export default {
	SubordinateExperienceRequest,
	MyExperienceRequest,
	ExperienceSetting,
	ExperienceWallet,
	EmployeeWithWallets,
}
