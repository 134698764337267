import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let PermissionSettingComponent

const PermissionSetting = store => {
	if (PermissionSettingComponent === undefined) {
		PermissionSettingComponent = Loadable({
			loader: () => {
				return import('views/PermissionSetting/containers/PermissionSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return PermissionSettingComponent
}

export default PermissionSetting
