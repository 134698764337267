import { s, number, string } from 'modules/types'

export const STATUS_TEXT = {
	DRAFT: 'Draft',
	ONGOING: 'Ongoing',
	PAUSED: 'Paused',
	ENDED: 'Ended',
}

export class SessionSimple {
	constructor({ id, name, status_text: statusText }) {
		this.id = id
		this.name = name
		this.statusText = statusText
	}
}

export const SessionSimpleType = s({
	id: number.isRequired,
	name: string.isRequired,
	statusText: string.isRequired,
})

export default SessionSimple
