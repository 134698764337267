import { s, arrayOf, bool, number, string } from 'modules/types'
import AppraisalQuestionAnswerChoice, { AppraisalQuestionAnswerChoiceType } from './AppraisalQuestionAnswerChoice'

export class AppraisalQuestion {
	constructor({
		id,
		is_allowed_to_modify: isAllowedToModify,
		choices = [],
		linear_lowest_label: linearLowestLabel,
		linear_highest_label: linearHighestLabel,
		self_content: selfContent,
		alternative_content: alternativeContent,
		type,
	}) {
		this.id = id
		this.isAllowedToModify = isAllowedToModify
		this.linearHighestLabel = linearHighestLabel
		this.linearLowestLabel = linearLowestLabel
		this.selfContent = selfContent
		this.alternativeContent = alternativeContent
		this.choices = choices.map(item => new AppraisalQuestionAnswerChoice(item))
		this.type = type
	}
}

export const AppraisalQuestionType = s({
	id: number.isRequired,
	isAllowedToModify: bool,
	linearHighestLabel: string,
	linearLowestLabel: string,
	selfContent: string,
	alternativeContent: string,
	choices: arrayOf(AppraisalQuestionAnswerChoiceType),
	type: number.isRequired,
})

export default AppraisalQuestion
