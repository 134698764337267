import { s, number } from 'modules/types'

export const APPRAISER_TYPE = {
	SELF: 0,
	PEER: 1,
	SUPERIOR: 2,
	SECOND_DEGREE_SUPERIOR: 3,
	SUBORDINATE: 4,
}

export class AppraiserType {
	constructor({ id, type, proportion }) {
		this.id = id
		this.type = type
		this.proportion = proportion
	}
}

export const AppraiserTypeType = s({
	id: number.isRequired,
	type: number.isRequired, // @APPRAISER_TYPE
	proportion: number,
})

export default AppraiserType
