import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let SurveyAdminComponent

const SurveyAdmin = store => {
	if (SurveyAdminComponent === undefined) {
		SurveyAdminComponent = Loadable({
			loader: () => {
				return import('views/SurveyAdmin/containers/SurveyAdminContainer')
			},
			loading: RouteLoader,
		})
	}
	return SurveyAdminComponent
}

export default SurveyAdmin
