import { any, s, number } from 'modules/types'
import { Question, QuestionType } from './Question'

export class QuestionWithAnswer {
	constructor({ question, order, answer }) {
		this.question = new Question(question)
		this.order = order
		this.answer = answer
	}
}

export const QuestionWithAnswerType = s({
	question: QuestionType,
	order: number,
	answer: any,
})

export default QuestionWithAnswer
