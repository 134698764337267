import { s, number } from 'modules/types'

export class EditApprover {
	constructor({ id, approverId: approver_id, groupLeaderRoleId: group_role_id, rankId: rank_id, type }) {
		this.id = id
		this.approver_id = approver_id
		this.group_role_id = group_role_id
		this.rank_id = rank_id
		this.type = type
	}
}

export const EditApproverType = s({
	id: number.isRequired,
	approver_id: number,
	group_role_id: number,
	rank_id: number,
	type: number.isRequired,
})

export default EditApprover
