import { httpPost } from 'utils/api'
import { toast } from 'utils/index'
import { reset } from 'redux-form'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = 'employee_database__onboarding'

export const ROUTES = {}

export const API_URLS = {}

// ------------------------------------
// Actions
// ------------------------------------

export function submitOnboarding(data: any) {
	return (dispatch: any) => {
		return httpPost('/api/onboarding/onboard_employee', data).then(() => {
			toast('Employee onboarded!')
			dispatch(reset('onboardingForm'))
		})
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

type Action = { type: 'reset' } | { type: 'increment' } | { type: 'decrement' }

export default function registerReducer(state = initialState, action: Action) {
	switch (action.type) {
		default:
			return state
	}
}
