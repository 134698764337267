import { s, string, number } from 'modules/types'

export class Company {
	constructor({ id, name, vision, mission, read_more: readMore }) {
		this.id = id
		this.name = name
		this.vision = vision
		this.mission = mission
		this.readMore = readMore
	}
}

export const CompanyType = s({
	id: number.isRequired,
	name: string.isRequired,
	vision: string.isRequired,
	mission: string.isRequired,
	readMore: string,
})

export default Company
