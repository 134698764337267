import { s } from 'modules/types'
import Question, { QuestionType } from './Question'
import Field, { FieldType } from './Field'

export class QuestionWithField {
	constructor({ question, field }) {
		this.question = new Question(question)
		this.field = new Field(field)
	}
}

export const QuestionWithFieldType = s({
	question: QuestionType,
	field: FieldType,
})

export default QuestionWithField
