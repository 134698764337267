import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `experience`

export const API_URLS = {
	myPendingSubmissions: () => `/api/experience/experience_requests/my_pending_submissions/`,
	allPendingSubmissions: () => `/api/experience/experience_requests/all_pending_submissions/`,
	subordinates: () => `/api/experience/experience_requests/approvee_requests/get_subordinates/`,
	experienceTypes: employeeId =>
		`/api/experience/experience_requests/approvee_requests/${employeeId}/get_experience_types/`,
	workingCycleForDate: employeeId =>
		`/api/experience/experience_requests/approvee_requests/${employeeId}/get_working_cycle_for_date/`,
	myExperiences: employeeId => `/api/experience/experience_requests/my_experiences/`,
	myExperienceTypes: employeeId => `/api/experience/experience_requests/my_experiences/get_experience_types/`,
	myWorkingCycleForDate: () => `/api/experience/experience_requests/my_experiences/get_working_cycle_for_date/`,
	experienceSetting: () => `/api/experience/experience_setting/`,
}

const REFRESHED_MY_PENDING_SUBMISSIONS = `${MODULE_NAME} | REFRESHED_MY_PENDING_SUBMISSIONS`
const REFRESHING_MY_PENDING_SUBMISSIONS = `${MODULE_NAME} | REFRESHING_MY_PENDING_SUBMISSIONS`
const REFRESHED_ALL_PENDING_SUBMISSIONS = `${MODULE_NAME} | REFRESHED_ALL_PENDING_SUBMISSIONS`
const REFRESHING_ALL_PENDING_SUBMISSIONS = `${MODULE_NAME} | REFRESHING_ALL_PENDING_SUBMISSIONS`
const REFRESHED_SUBORDINATES = `${MODULE_NAME} | REFRESHED_SUBORDINATES`
const REFRESHING_SUBORDINATES = `${MODULE_NAME} | REFRESHING_SUBORDINATES`
const REFRESHED_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHED_EXPERIENCE_TYPES`
const REFRESHING_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHING_EXPERIENCE_TYPES`
const REFRESHED_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHED_WORKING_CYCLE_FOR_DATE`
const REFRESHING_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHING_WORKING_CYCLE_FOR_DATE`
const REFRESHED_MY_EXPERIENCES = `${MODULE_NAME} | REFRESHED_MY_EXPERIENCES`
const REFRESHING_MY_EXPERIENCES = `${MODULE_NAME} | REFRESHING_MY_EXPERIENCES`
const REFRESHED_MY_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHED_MY_EXPERIENCE_TYPES`
const REFRESHING_MY_EXPERIENCE_TYPES = `${MODULE_NAME} | REFRESHING_MY_EXPERIENCE_TYPES`
const REFRESHED_MY_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHED_MY_WORKING_CYCLE_FOR_DATE`
const REFRESHING_MY_WORKING_CYCLE_FOR_DATE = `${MODULE_NAME} | REFRESHING_MY_WORKING_CYCLE_FOR_DATE`
const REFRESHED_EXPERIENCE_SETTING = `${MODULE_NAME} | REFRESHED_EXPERIENCE_SETTING`
const REFRESHING_EXPERIENCE_SETTING = `${MODULE_NAME} | REFRESHING_EXPERIENCE_SETTING`

const APPROVED_PENDING_SUBMISSION = `${MODULE_NAME} | APPROVED_PENDING_SUBMISSION`
const DECLINED_PENDING_SUBMISSION = `${MODULE_NAME} | DECLINED_PENDING_SUBMISSION`
const CREATED_SUBORDINATE_REQUEST = `${MODULE_NAME} | CREATED_SUBORDINATE_REQUEST`
const CREATED_MY_EXPERIENCE_REQUEST = `${MODULE_NAME} | CREATED_MY_EXPERIENCE_REQUEST`

const CHANGE_ALL_REQUESTS_NAME_FILTER = `${MODULE_NAME} | CHANGE_ALL_REQUESTS_NAME_FILTER`
const CHANGE_SUBORDINATE_NAME_FILTER = `${MODULE_NAME} | CHANGE_SUBORDINATE_NAME_FILTER`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshMyPendingSubmissions() {
	return dispatch => {
		let apiUrl = API_URLS.myPendingSubmissions()
		dispatch({ type: REFRESHING_MY_PENDING_SUBMISSIONS, apiUrl })
		return api.experience.experience_requests.my_pending_submissions.get().then(({ data: subordinateRequests }) => {
			dispatch({ type: REFRESHED_MY_PENDING_SUBMISSIONS, apiUrl, data: subordinateRequests })
			return subordinateRequests
		})
	}
}

function refreshAllPendingSubmissions() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.allPendingSubmissions()
		let {
			experience: { data },
		} = getState()
		let args = {
			name: '',
		}
		if (data.allRequestsNameFilter) {
			args.name = data.allRequestsNameFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_ALL_PENDING_SUBMISSIONS, apiUrl })
		return api.experience.experience_requests.all_pending_submissions
			.get(getParam)
			.then(({ data: subordinateRequests }) => {
				dispatch({ type: REFRESHED_ALL_PENDING_SUBMISSIONS, apiUrl, data: subordinateRequests })
				return subordinateRequests
			})
	}
}

function refreshSubordinates() {
	return (dispatch, getState) => {
		let apiUrl = API_URLS.subordinates()
		let {
			experience: { data },
		} = getState()
		let args = {
			name: '',
		}
		if (data.subordinateNameFilter) {
			args.name = data.subordinateNameFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_SUBORDINATES, apiUrl })
		return api.experience.experience_requests.approvee_requests
			.getApprovees(getParam)
			.then(({ data: employeeWithWalletsList }) => {
				dispatch({ type: REFRESHED_SUBORDINATES, apiUrl, data: employeeWithWalletsList })
				return employeeWithWalletsList
			})
	}
}

function refreshExperienceTypes(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.experienceTypes(employeeId)
		dispatch({ type: REFRESHING_EXPERIENCE_TYPES, apiUrl })
		return api.experience.experience_requests.approvee_requests
			.getExperienceTypes(employeeId, date)
			.then(({ data: experienceWallets }) => {
				dispatch({ type: REFRESHED_EXPERIENCE_TYPES, apiUrl, data: experienceWallets })
				return experienceWallets
			})
	}
}

export function fetchEmployeeExperienceTypes(employeeId, date) {
	return dispatch => {
		dispatch(refreshExperienceTypes(employeeId, date))
	}
}

function refreshWorkingCycleForDate(employeeId, date) {
	return dispatch => {
		let apiUrl = API_URLS.workingCycleForDate(employeeId)
		dispatch({ type: REFRESHING_WORKING_CYCLE_FOR_DATE, apiUrl })
		return api.experience.experience_requests.approvee_requests
			.getWorkingCycleForDate(employeeId, date)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_WORKING_CYCLE_FOR_DATE, apiUrl, data })
				return data
			})
	}
}

export function fetchEmployeeWorkingCycleForDate(employeeId, date) {
	return dispatch => {
		dispatch(refreshWorkingCycleForDate(employeeId, date))
	}
}

function refreshMyExperiences() {
	return dispatch => {
		let apiUrl = API_URLS.myExperiences()
		dispatch({ type: REFRESHING_MY_EXPERIENCES, apiUrl })
		return api.experience.experience_requests.my_experiences.get().then(({ data: myExperienceRequests }) => {
			dispatch({ type: REFRESHED_MY_EXPERIENCES, apiUrl, data: myExperienceRequests })
			return myExperienceRequests
		})
	}
}

function refreshMyExperienceTypes(date) {
	return dispatch => {
		let apiUrl = API_URLS.myExperienceTypes()
		dispatch({ type: REFRESHING_MY_EXPERIENCE_TYPES, apiUrl })
		return api.experience.experience_requests.my_experiences
			.getExperienceTypes(date)
			.then(({ data: experienceWallets }) => {
				dispatch({ type: REFRESHED_MY_EXPERIENCE_TYPES, apiUrl, data: experienceWallets })
				return experienceWallets
			})
	}
}

export function fetchMyExperienceTypes(date) {
	return dispatch => {
		dispatch(refreshMyExperienceTypes(date))
	}
}

function refreshMyWorkingCycleForDate(date) {
	return dispatch => {
		let apiUrl = API_URLS.myWorkingCycleForDate()
		dispatch({ type: REFRESHING_MY_WORKING_CYCLE_FOR_DATE, apiUrl })
		return api.experience.experience_requests.my_experiences.getWorkingCycleForDate(date).then(({ data }) => {
			dispatch({ type: REFRESHED_MY_WORKING_CYCLE_FOR_DATE, apiUrl, data })
			return data
		})
	}
}

export function fetchMyWorkingCycleForDate(date) {
	return dispatch => {
		dispatch(refreshMyWorkingCycleForDate(date))
	}
}

function refreshExperienceSetting() {
	return dispatch => {
		let apiUrl = API_URLS.experienceSetting()
		dispatch({ type: REFRESHING_EXPERIENCE_SETTING, apiUrl })
		return api.experience.experience_setting.get().then(({ data: experienceSetting }) => {
			dispatch({ type: REFRESHED_EXPERIENCE_SETTING, apiUrl, data: experienceSetting })
			return experienceSetting
		})
	}
}

export function approvePendingSubmission(requestId, data) {
	return dispatch => {
		return api.experience.experience_requests.my_pending_submissions.approve(requestId, data).then(() => {
			toast(`Successfully approved submission`)
			dispatch(refreshMyPendingSubmissions())
			dispatch(refreshAllPendingSubmissions())
			dispatch(refreshMyExperiences())
			dispatch({ type: APPROVED_PENDING_SUBMISSION, requestId })
		})
	}
}

export function declinePendingSubmission(requestId, notes) {
	return dispatch => {
		return api.experience.experience_requests.my_pending_submissions.decline(requestId, notes).then(() => {
			toast(`Successfully declined submission`)
			dispatch(refreshMyPendingSubmissions())
			dispatch(refreshAllPendingSubmissions())
			dispatch(refreshMyExperiences())
			dispatch({ type: DECLINED_PENDING_SUBMISSION, requestId })
		})
	}
}

export function createSubordinateRequest(employeeId, data) {
	return dispatch => {
		return api.experience.experience_requests.approvee_requests
			.create(employeeId, data)
			.then(({ data: subordinateRequest }) => {
				toast(`Successfully submitted subordinate's hours`)
				dispatch(refreshAllPendingSubmissions())
				dispatch(refreshMyPendingSubmissions())
				dispatch(refreshSubordinates())
				dispatch({ type: CREATED_SUBORDINATE_REQUEST, subordinateRequest })
			})
	}
}

export function createMyExperienceRequest(data) {
	return dispatch => {
		return api.experience.experience_requests.my_experiences.create(data).then(({ data: myRequest }) => {
			dispatch(refreshMyPendingSubmissions())
			dispatch(refreshAllPendingSubmissions())
			dispatch(refreshMyExperiences())
			toast(`Successfully submitted hours of experience`)
			dispatch({ type: CREATED_MY_EXPERIENCE_REQUEST, myRequest })
		})
	}
}

export function changeAllRequestsNameFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_ALL_REQUESTS_NAME_FILTER, name })
		dispatch(refreshAllPendingSubmissions())
	}
}

export function changeSubordinateNameFilter(name) {
	return dispatch => {
		dispatch({ type: CHANGE_SUBORDINATE_NAME_FILTER, name })
		dispatch(refreshSubordinates())
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshExperienceSetting())
		dispatch(refreshMyPendingSubmissions())
		dispatch(refreshAllPendingSubmissions())
		dispatch(refreshMyExperiences())
		dispatch(refreshSubordinates())
		// dispatch(refreshMyExperienceTypes())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	data: {
		allRequestsNameFilter: '',
		subordinateNameFilter: '',
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_MY_PENDING_SUBMISSIONS:
		case REFRESHING_ALL_PENDING_SUBMISSIONS:
		case REFRESHING_SUBORDINATES:
		case REFRESHING_EXPERIENCE_TYPES:
		case REFRESHING_WORKING_CYCLE_FOR_DATE:
		case REFRESHING_MY_EXPERIENCES:
		case REFRESHING_MY_EXPERIENCE_TYPES:
		case REFRESHING_MY_WORKING_CYCLE_FOR_DATE:
		case REFRESHING_EXPERIENCE_SETTING:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_MY_PENDING_SUBMISSIONS:
		case REFRESHED_ALL_PENDING_SUBMISSIONS:
		case REFRESHED_SUBORDINATES:
		case REFRESHED_EXPERIENCE_TYPES:
		case REFRESHED_WORKING_CYCLE_FOR_DATE:
		case REFRESHED_MY_EXPERIENCES:
		case REFRESHED_EXPERIENCE_SETTING:
		case REFRESHED_MY_WORKING_CYCLE_FOR_DATE:
		case REFRESHED_MY_EXPERIENCE_TYPES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CHANGE_ALL_REQUESTS_NAME_FILTER:
			return {
				...state,
				data: {
					...state.data,
					allRequestsNameFilter: action.name,
				},
			}
		case CHANGE_SUBORDINATE_NAME_FILTER:
			return {
				...state,
				data: {
					...state.data,
					subordinateNameFilter: action.name,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
