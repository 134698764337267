import { s, number, string } from 'modules/types'

export class SessionWithTotalParticipants {
	constructor({
		appraisal_session_id: appraisalSessionId,
		name,
		total_groups: totalGroups,
		total_participants: totalParticipants,
	}) {
		this.appraisalSessionId = appraisalSessionId
		this.name = name
		this.totalGroups = totalGroups
		this.totalParticipants = totalParticipants
	}
}

export const SessionWithTotalParticipantsType = s({
	appraisalSessionId: number.isRequired,
	name: string.isRequired,
	totalGroups: number.isRequired,
	totalParticipants: number.isRequired,
})

export default SessionWithTotalParticipants
