import { s, arrayOf, number, object } from 'modules/types'
import ExperienceWallet, { ExperienceWalletType } from './ExperienceWallet'
import EmployeeWithGroup, { EmployeeWithGroupType } from './EmployeeWithGroup'
import ExperienceRequestSubscription, { ExperienceRequestSubscriptionType } from './ExperienceRequestSubscription'

export class SubordinateExperienceRequest {
	constructor({
		id,
		posting_date: postingDate,
		employee,
		experience_wallet: experienceWallet,
		submitted_hours: submittedHours,
		final_hours: finalHours,
		subscriptions,
		status,
	}) {
		this.id = id
		this.postingDate = new Date(postingDate)
		this.employee = new EmployeeWithGroup(employee)
		this.experienceWallet = new ExperienceWallet(experienceWallet)
		this.submittedHours = submittedHours
		this.finalHours = finalHours
		this.subscriptions = subscriptions.map(item => new ExperienceRequestSubscription(item))
		this.status = status
	}
}

export const SubordinateExperienceRequestType = s({
	id: number.isRequired,
	postingDate: object.isRequired,
	employee: EmployeeWithGroupType,
	experienceWallet: ExperienceWalletType,
	submittedHours: number.isRequired,
	finalHours: number,
	subscriptions: arrayOf(ExperienceRequestSubscriptionType),
	status: number.isRequired, // @EXPERIENCE_REQUEST_STATUS
})

export default SubordinateExperienceRequest
