import { s, arrayOf, bool, number } from 'modules/types'
import Idea, { IdeaType } from './Idea'
import Comment, { CommentType } from './Comment'
import NotAllowedAction, { NotAllowedActionType } from './NotAllowedAction'

export const IDEA_ACTION = {
	FORWARD: 0,
	RESEND: 1,
	COMMENT: 2,
	POST: 3,
	LIKE: 4,
	FLOWER: 5,
	STAR: 6,
}

export class IdeaSubscriptionDetail {
	constructor({
		id,
		idea,
		comments,
		allowed_actions: allowedActions,
		not_allowed_actions: notAllowedActions,
		is_forwarded: isForwarded,
	}) {
		this.id = id
		this.idea = new Idea(idea)
		this.comments = comments.map(item => new Comment(item))
		this.allowedActions = allowedActions
		this.notAllowedActions = notAllowedActions.map(item => new NotAllowedAction(item))
		this.isForwarded = isForwarded
	}
}

export const IdeaSubscriptionDetailType = s({
	id: number.isRequired,
	idea: IdeaType,
	comments: arrayOf(CommentType),
	allowedActions: arrayOf(number), // @IDEA_ACTION
	notAllowedActions: arrayOf(NotAllowedActionType),
	isForwarded: bool.isRequired,
})

export default IdeaSubscriptionDetail
