import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let WorkstationSettingComponent

const WorkstationSetting = store => {
	if (WorkstationSettingComponent === undefined) {
		WorkstationSettingComponent = Loadable({
			loader: () => {
				return import('views/WorkstationSetting/containers/WorkstationSettingContainer')
			},
			loading: RouteLoader,
		})
	}
	return WorkstationSettingComponent
}

export default WorkstationSetting
