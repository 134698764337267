import { s, number, string, bool } from 'modules/types'

export class Employee {
	constructor({ id, name, NIK, profile_picture: profilePicture, is_archived: isArchived }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.profilePicture = profilePicture
		this.isArchived = isArchived
	}
}

export const EmployeeType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	profilePicture: string,
	isArchived: bool,
})

export default Employee
