import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'
import { push } from 'react-router-redux'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `appraisal_ongoing`

export const API_URLS = {
	sheetViewSubSessions: groupStageId => `/api/appraisal/sub_sessions/ongoing/sheet_view/${groupStageId}/`,
	sheetViewSubSessionSnapshots: groupStageId =>
		`/api/appraisal/sub_sessions/ongoing/sheet_view/${groupStageId}/get_snapshot/`,
}

const SELECT_SUB_SESSION = `${MODULE_NAME} | SELECT SUB SESSION`
const CLEAR_SELECTION_SUB_SESSION = `${MODULE_NAME} | CLEAR SELECTION SUB SESSION`
const SUBMITTED_SUB_SESSION = `${MODULE_NAME} | SUBMITTED SUB SESSION`
const SAVED_PROGRESS_SUB_SESSION = `${MODULE_NAME} | SAVED PROGRESS SUB SESSION`
const UPDATED_SINGLE_SNAPSHOT = `${MODULE_NAME} | UPDATED SINGLE SNAPSHOT`
const CANCELLED_PROGRESS = `${MODULE_NAME} | CANCELLED_PROGRESS`

const SUBMITTED_SHEET_VIEW = `${MODULE_NAME} | SUBMITTED SHEET VIEW`
const SAVED_PROGRESS_SHEET_VIEW = `${MODULE_NAME} | SAVED PROGRESS SHEET VIEW`

const REFRESHED_ONGOING_SUB_SESSIONS = `${MODULE_NAME} | REFRESHED ONGOING SUB SESSIONS`
const REFRESHED_ONGOING_SUB_SESSION_DETAIL = `${MODULE_NAME} | REFRESHED ONGOING SUB SESSION DETAIL`
const REFRESHED_ONGOING_SUB_SESSION_SNAPSHOT = `${MODULE_NAME} | REFRESHED ONGOING SUB SESSION SNAPSHOT`

const REFRESHING_SHEET_VIEW_SUB_SESSIONS = `${MODULE_NAME} | REFRESHING SHEET VIEW SUB SESSIONS`
const REFRESHED_SHEET_VIEW_SUB_SESSIONS = `${MODULE_NAME} | REFRESHED SHEET VIEW SUB SESSIONS`
const REFRESHING_SHEET_VIEW_SUB_SESSION_SNAPSHOTS = `${MODULE_NAME} | REFRESHING SHEET VIEW SUB SESSION SNAPSHOTS`
const REFRESHED_SHEET_VIEW_SUB_SESSION_SNAPSHOTS = `${MODULE_NAME} | REFRESHED SHEET VIEW SUB SESSION SNAPSHOTS`

const CHECK_SUB_SESSIONS = `${MODULE_NAME} | CHECK SUB SESSIONS`
const UNCHECK_SUB_SESSIONS = `${MODULE_NAME} | UNCHECK SUB SESSIONS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------
export function refreshOngoingSubSessions() {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.getSubsessions().then(({ data: subSessions }) => {
			dispatch({ type: REFRESHED_ONGOING_SUB_SESSIONS, data: subSessions })
			return subSessions
		})
	}
}

export function refreshSubSessionDetail(subSessionId) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.getSubsessionDetail(subSessionId).then(({ data: subSessionDetail }) => {
			dispatch({ type: REFRESHED_ONGOING_SUB_SESSION_DETAIL, data: subSessionDetail })
			return subSessionDetail
		})
	}
}

export function refreshSubsessionSnapshot(sessionId, subSessionId) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.snapshot.get(sessionId, subSessionId).then(({ data }) => {
			dispatch({ type: REFRESHED_ONGOING_SUB_SESSION_SNAPSHOT, data })
		})
	}
}

export function refreshSheetViewSubSessions(groupStageId, subSessionIds) {
	return dispatch => {
		const apiUrl = API_URLS.sheetViewSubSessions(groupStageId)
		dispatch({ type: REFRESHING_SHEET_VIEW_SUB_SESSIONS, apiUrl })
		return api.appraisal.subSessions.ongoing.sheet_view
			.requestSubSessions(groupStageId, subSessionIds)
			.then(({ data: sheetViewCategoryResult }) => {
				dispatch({ type: REFRESHED_SHEET_VIEW_SUB_SESSIONS, data: sheetViewCategoryResult, apiUrl })
				return sheetViewCategoryResult
			})
	}
}

export function refreshSheetViewSubSessionSnapshots(groupStageId, subSessionIds) {
	return dispatch => {
		const apiUrl = API_URLS.sheetViewSubSessionSnapshots(groupStageId)
		dispatch({ type: REFRESHING_SHEET_VIEW_SUB_SESSION_SNAPSHOTS, apiUrl })
		return api.appraisal.subSessions.ongoing.sheet_view
			.requestSubSessionSnapshots(groupStageId, subSessionIds)
			.then(({ data: sheetViewSubSessionSnapshots }) => {
				dispatch({ type: REFRESHED_SHEET_VIEW_SUB_SESSION_SNAPSHOTS, data: sheetViewSubSessionSnapshots, apiUrl })
				return sheetViewSubSessionSnapshots
			})
	}
}

export function submitSubSession(subSessionId, data) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.submit(subSessionId, data).then(() => {
			toast(`Successfully submitted appraisal`)
			dispatch({ type: SUBMITTED_SUB_SESSION })
			dispatch({ type: CLEAR_SELECTION_SUB_SESSION })
			return dispatch(refreshOngoingSubSessions()).then(subSessions => {
				if (subSessions.length > 0) {
					dispatch(selectSubSession(subSessions[0]))
				}
			})
		})
	}
}

export function saveProgressSubSession(subSessionId, data) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.saveProgress(subSessionId, data).then(() => {
			toast(`Successfully saved`)
			dispatch({ type: SAVED_PROGRESS_SUB_SESSION })
			return dispatch(refreshOngoingSubSessions())
		})
	}
}

export function updateSnapshot(sessionId, subSessionId, snapshot) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.snapshot
			.updateSingle(sessionId, subSessionId, snapshot)
			.then(({ data }) => {
				dispatch({ type: UPDATED_SINGLE_SNAPSHOT, data })
			})
	}
}

export function cancelProgress(sessionId, subSessionId) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.snapshot.cancel(sessionId, subSessionId).then(() => {
			dispatch({ type: CANCELLED_PROGRESS })
			dispatch(refreshSubsessionSnapshot(sessionId, subSessionId))
		})
	}
}

export function submitSheetView(groupId, data) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.sheet_view.submit(groupId, data).then(() => {
			toast(`Successfully submitted appraisals`)
			dispatch({ type: SUBMITTED_SHEET_VIEW })
			dispatch(refreshOngoingSubSessions()).then(subSessions => {
				if (subSessions.length > 0) dispatch(selectSubSession(subSessions[0]))
			})
		})
	}
}

export function saveProgressSheetView(groupId, data) {
	return dispatch => {
		return api.appraisal.subSessions.ongoing.sheet_view.saveProgress(groupId, data).then(() => {
			toast(`Successfully saved`)
			dispatch({ type: SAVED_PROGRESS_SHEET_VIEW })
		})
	}
}

export function selectSubSession(subSession) {
	return dispatch => {
		dispatch({ type: SELECT_SUB_SESSION, subSessionId: subSession.id })
		dispatch(refreshSubSessionDetail(subSession.id))
		dispatch(refreshSubsessionSnapshot(subSession.session.id, subSession.id))
		dispatch(push(`/appraisal/ongoing/${subSession.id}`))
	}
}

export function checkSubSessions(subSessionIds) {
	return dispatch => {
		dispatch({ type: CHECK_SUB_SESSIONS, subSessionIds })
	}
}

export function uncheckSubSessions(subSessionIds) {
	return dispatch => {
		dispatch({ type: UNCHECK_SUB_SESSIONS, subSessionIds })
	}
}

export function loadModule() {
	return (dispatch, getState) => {
		dispatch({ type: LOAD_MODULE })
		dispatch(refreshOngoingSubSessions())
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {
		ongoingSubSessions: undefined,
		selectedSubSession: undefined,
		subSessionSnapshot: undefined,
	},
	data: {
		selectedSubSessionId: undefined,
		checkedSubSessionIds: [],
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_SHEET_VIEW_SUB_SESSIONS:
		case REFRESHING_SHEET_VIEW_SUB_SESSION_SNAPSHOTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_SHEET_VIEW_SUB_SESSIONS:
		case REFRESHED_SHEET_VIEW_SUB_SESSION_SNAPSHOTS:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case REFRESHED_ONGOING_SUB_SESSIONS:
			return {
				...state,
				api: {
					...state.api,
					ongoingSubSessions: action.data,
				},
			}
		case REFRESHED_ONGOING_SUB_SESSION_DETAIL:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: action.data,
				},
			}
		case REFRESHED_ONGOING_SUB_SESSION_SNAPSHOT:
			return {
				...state,
				api: {
					...state.api,
					subSessionSnapshot: action.data,
				},
			}
		case SELECT_SUB_SESSION:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: undefined,
					subSessionSnapshot: undefined,
				},
				data: {
					...state.data,
					selectedSubSessionId: action.subSessionId,
				},
			}
		case CLEAR_SELECTION_SUB_SESSION:
			return {
				...state,
				api: {
					...state.api,
					selectedSubSession: undefined,
					subSessionSnapshot: undefined,
				},
				data: {
					...state.data,
					selectedSubSessionId: undefined,
				},
			}
		case CHECK_SUB_SESSIONS:
			return {
				...state,
				data: {
					...state.data,
					checkedSubSessionIds: [...state.data.checkedSubSessionIds, ...action.subSessionIds],
				},
			}
		case UNCHECK_SUB_SESSIONS:
			return {
				...state,
				data: {
					...state.data,
					checkedSubSessionIds: state.data.checkedSubSessionIds.filter(
						checkedSubSessionId => !action.subSessionIds.includes(checkedSubSessionId)
					),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
