import { s, number } from 'modules/types'

export class IdeaReportSummarySuperior {
	constructor({ no_response: noResponse, commented, forwarded }) {
		this.noResponse = noResponse
		this.commented = commented
		this.forwarded = forwarded
	}
}

export const IdeaReportSummarySuperiorType = s({
	noResponse: number.isRequired,
	commented: number.isRequired,
	forwarded: number.isRequired,
})

export default IdeaReportSummarySuperior
