import _ from 'lodash'
import api from 'modules/api'
import { toast, getObjectOrUndefined } from 'utils'
import { PERMISSIONS } from 'modules/constants'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'announcement'
export const API_URLS = {
	announcements: () => '/api/announcements/',
	announcementDetail: announcementId => `/api/announcements/${announcementId}/`,
	filters: () => `/api/announcements/get_filters/`,
	employees: () => `/api/announcements/get_employees/`,
	audience: announcementId => `/api/announcements/${announcementId}/get_audiences/`,
}

const CREATED_ANNOUNCEMENT = `${MODULE_NAME} | CREATED ANNOUNCEMENT`
const EDITED_ANNOUNCEMENT = `${MODULE_NAME} | EDITED ANNOUNCEMENT`
const DELETED_ANNOUNCEMENT = `${MODULE_NAME} | DELETED ANNOUNCEMENT`
const ADD_AUDIENCE = `${MODULE_NAME} | ADD AUDIENCE`
const REMOVE_AUDIENCE = `${MODULE_NAME} | REMOVE AUDIENCE`

const REFRESHED_ANNOUNCEMENTS = `${MODULE_NAME} | REFRESHED ANNOUNCEMENTS`
const REFRESHED_ANNOUNCEMENT_DETAIL = `${MODULE_NAME} | REFRESHED ANNOUNCEMENT DETAIL`
const REFRESHED_AUDIENCE = `${MODULE_NAME} | REFRESHED AUDIENCE`

const REFRESHING_ANNOUNCEMENTS = `${MODULE_NAME} | REFRESHING ANNOUNCEMENTS`
const REFRESHING_ANNOUNCEMENT_DETAIL = `${MODULE_NAME} | REFRESHING ANNOUNCEMENT DETAIL`
const REFRESHING_AUDIENCE = `${MODULE_NAME} | REFRESHING AUDIENCE`

const SELECT_ANNOUNCEMENT = `${MODULE_NAME} | SELECT ANNOUNCEMENT`
const TURN_ON_EDITING = `${MODULE_NAME} | TURN ON EDITING`
const TURN_OFF_EDITING = `${MODULE_NAME} | TURN OFF EDITING`
const SHOW_CREATE_ANNOUNCEMENT_FORM = `${MODULE_NAME} | SHOW CREATE ANNOUNCEMENT FORM`
const HIDE_CREATE_ANNOUNCEMENT_FORM = `${MODULE_NAME} | HIDE CREATE ANNOUNCEMENT FORM`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshAnnouncements() {
	return dispatch => {
		let apiUrl = API_URLS.announcements()
		dispatch({ type: REFRESHING_ANNOUNCEMENTS, apiUrl })
		return api.announcement.get().then(({ data }) => {
			dispatch({ type: REFRESHED_ANNOUNCEMENTS, data, apiUrl })
			return data
		})
	}
}

function refreshAnnouncementDetail(announcementId) {
	return dispatch => {
		let apiUrl = API_URLS.announcementDetail(announcementId)
		dispatch({ type: REFRESHING_ANNOUNCEMENT_DETAIL, apiUrl })
		return api.announcement.getDetail(announcementId).then(({ data }) => {
			dispatch({ type: REFRESHED_ANNOUNCEMENT_DETAIL, data, apiUrl })
		})
	}
}

function refreshAudience(announcementId) {
	return dispatch => {
		let apiUrl = API_URLS.audience(announcementId)
		dispatch({ type: REFRESHING_AUDIENCE, apiUrl })
		return api.announcement.getAudiences(announcementId).then(({ data }) => {
			dispatch({ type: REFRESHED_AUDIENCE, data, apiUrl })
		})
	}
}

export function createAnnouncement(announcement) {
	return (dispatch, getState) => {
		return api.announcement.create(announcement).then(({ data }) => {
			toast(`Successfully blasted "${data.title}"`)
			// clear selected announcement, so hideCreateAnnouncementForm will select the newly created one
			dispatch(selectAnnouncement(undefined))
			dispatch({ type: CREATED_ANNOUNCEMENT, data })
			dispatch(refreshAnnouncements()).then(() => {
				dispatch(hideCreateAnnouncementForm())
			})
			return data
		})
	}
}

export function editAnnouncement(announcementId, announcement) {
	return dispatch => {
		return api.announcement.edit(announcementId, announcement).then(({ data }) => {
			toast(`Successfully blasted "${data.title}"`)
			dispatch(refreshAnnouncements())
			dispatch(refreshAudience(data.id))
			dispatch(refreshAnnouncementDetail(announcementId))
			dispatch({ type: EDITED_ANNOUNCEMENT, data })
			return data
		})
	}
}

export function deleteAnnouncement(announcement) {
	return dispatch => {
		return api.announcement.delete(announcement.id).then(() => {
			toast(`Successfully deleted "${announcement.title}" announcement`)
			dispatch({ type: DELETED_ANNOUNCEMENT, deletedAnnouncementId: announcement.id })
			dispatch(refreshAnnouncements()).then(announcements => {
				if (announcements && announcements.length > 0) {
					dispatch(selectAnnouncement(announcements[0].id))
				} else {
					dispatch(selectAnnouncement(undefined))
				}
			})
		})
	}
}

export function selectAnnouncement(announcementId) {
	return (dispatch, getState) => {
		// TODO: in the future, we need to make create form a route. and then any route changes from there will trigger alert, instead of hardcoding it like this
		// show alert if there is any data in the createForm
		const {
			announcement,
			form,
			session: {
				currentUser: { permissions },
			},
		} = getState()
		const isShowingCreateForm = getObjectOrUndefined(announcement, 'data.isShowingCreateForm')
		const formData = getObjectOrUndefined(form, 'create-announcement.values')
		if (isShowingCreateForm) {
			let hasContent = false
			if (formData) {
				for (let fieldName in formData) {
					if (!formData[fieldName] || _.isEmpty(formData[fieldName])) {
					} else {
						hasContent = true
					}
				}
			}
			if (hasContent) {
				let confirmMove = confirm('Are you sure you want to discard this announcement?') // eslint-disable-line no-restricted-globals
				if (!confirmMove) {
					return
				}
			}
		}

		dispatch({ type: SELECT_ANNOUNCEMENT, announcementId })
		dispatch(turnOffEditing())
		if (announcementId) {
			dispatch(refreshAnnouncementDetail(announcementId))
			if (permissions.includes(PERMISSIONS.MANAGE_ANNOUNCEMENT)) {
				dispatch(refreshAudience(announcementId))
			}
		}
	}
}

export function addAudience(employeeIds = []) {
	return dispatch => {
		dispatch({ type: ADD_AUDIENCE, employeeIds })
	}
}

export function removeAudience(employeeIds = []) {
	return dispatch => {
		dispatch({ type: REMOVE_AUDIENCE, employeeIds })
	}
}

export function turnOnEditing() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING })
	}
}

export function turnOffEditing() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING })
	}
}

export function showCreateAnnouncementForm() {
	return dispatch => {
		dispatch({ type: SHOW_CREATE_ANNOUNCEMENT_FORM })
	}
}

export function hideCreateAnnouncementForm() {
	return (dispatch, getState) => {
		const {
			announcement: {
				api,
				data: { selectedAnnouncementId },
			},
		} = getState()
		const announcements = api[API_URLS.announcements()]
		if (!selectedAnnouncementId && announcements.length > 0) {
			dispatch(selectAnnouncement(announcements[0].id))
		}
		dispatch({ type: HIDE_CREATE_ANNOUNCEMENT_FORM })
		dispatch(turnOffEditing())
	}
}

export function loadModule() {
	return (dispatch, getState) => {
		const {
			session: {
				currentUser: { permissions },
			},
		} = getState()
		if (permissions.includes(PERMISSIONS.MANAGE_ANNOUNCEMENT) && permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY)) {
			dispatch(showCreateAnnouncementForm())
		}
		dispatch(refreshAnnouncements()).then(announcements => {
			if (
				announcements.length > 0 &&
				!(permissions.includes(PERMISSIONS.MANAGE_ANNOUNCEMENT) && permissions.includes(PERMISSIONS.MANAGE_MY_COMPANY))
			) {
				dispatch(selectAnnouncement(announcements[0].id))
			}
		})
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	refreshing: {},
	pagination: {},
	data: {
		isEditing: false,
		isShowingCreateForm: false,
		selectedAnnouncementId: undefined,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_ANNOUNCEMENTS:
		case REFRESHING_ANNOUNCEMENT_DETAIL:
		case REFRESHING_AUDIENCE:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_ANNOUNCEMENTS:
		case REFRESHED_ANNOUNCEMENT_DETAIL:
		case REFRESHED_AUDIENCE:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
			}
		case SELECT_ANNOUNCEMENT:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: false,
					selectedAnnouncementId: action.announcementId,
				},
			}
		case DELETED_ANNOUNCEMENT:
			return {
				...state,
				api: {
					[API_URLS.announcements()]: [
						...state.api[API_URLS.announcements()].filter(
							announcement => announcement.id !== action.deletedAnnouncementId
						),
					],
				},
			}
		case TURN_ON_EDITING:
			return {
				...state,
				data: {
					...state.data,
					isEditing: true,
				},
			}
		case TURN_OFF_EDITING:
			return {
				...state,
				data: {
					...state.data,
					isEditing: false,
				},
			}
		case SHOW_CREATE_ANNOUNCEMENT_FORM:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: true,
				},
			}
		case HIDE_CREATE_ANNOUNCEMENT_FORM:
			return {
				...state,
				data: {
					...state.data,
					isShowingCreateForm: false,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
