import { s, arrayOf, number } from 'modules/types'
import AppraiserType, { AppraiserTypeType } from './AppraiserType'

class GroupStageStage {
	constructor({ stage_number: stageNumber }) {
		this.stageNumber = stageNumber
	}
}

const GroupStageStageType = s({
	stageNumber: number.isRequired,
})

export class GroupStage {
	constructor({ id, stage, appraiser_types: appraiserTypes }) {
		this.id = id
		this.stage = new GroupStageStage(stage)
		this.appraiserTypes = appraiserTypes.map(item => new AppraiserType(item))
	}
}

export const GroupStageType = s({
	id: number.isRequired,
	stage: GroupStageStageType,
	appraiserTypes: arrayOf(AppraiserTypeType),
})

export default GroupStage
