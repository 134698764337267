import { s, arrayOf, number, string } from 'modules/types'

class LinearResult {
	constructor({
		default_question_link_id: defaultQuestionLinkId,
		number,
		content,
		max,
		min,
		first_quartile: firstQuartile,
		third_quartile: thirdQuartile,
		median,
		average,
	}) {
		this.defaultQuestionLinkId = defaultQuestionLinkId
		this.number = number
		this.content = content
		this.max = max
		this.min = min
		this.firstQuartile = firstQuartile
		this.thirdQuartile = thirdQuartile
		this.median = median
		this.average = average ? average.toFixed(2) : null
	}
}

const LinearResultType = s({
	defaultQuestionLinkId: number.isRequired,
	number: number.isRequired,
	content: string.isRequired,
	max: number,
	min: number,
	firstQuartile: number,
	thirdQuartile: number,
	median: number,
	average: number,
})

class ChoiceWithTotal {
	constructor({ answer_choice_id: answerChoiceId, content, total }) {
		this.answerChoiceId = answerChoiceId
		this.content = content
		this.total = total
	}
}

const ChoiceWithTotalType = s({
	answerChoiceId: number.isRequired,
	content: string.isRequired,
	total: number.isRequired,
})

class RadioResult {
	constructor({ default_question_link_id: defaultQuestionLinkId, number, content, choices }) {
		this.defaultQuestionLinkId = defaultQuestionLinkId
		this.number = number
		this.content = content
		this.choices = choices.map(item => new ChoiceWithTotal(item))
	}
}

const RadioResultType = s({
	defaultQuestionLinkId: number.isRequired,
	number: number.isRequired,
	content: string.isRequired,
	choices: arrayOf(ChoiceWithTotalType),
})

class TextResult {
	constructor({ default_question_link_id: defaultQuestionLinkId, number, content }) {
		this.defaultQuestionLinkId = defaultQuestionLinkId
		this.number = number
		this.content = content
	}
}

const TextResultType = s({
	defaultQuestionLinkId: number.isRequired,
	number: number.isRequired,
	content: string.isRequired,
})

class CategoryStatistics {
	constructor({ linear_questions: linearQuestions, radio_questions: radioQuestions, text_questions: textQuestions }) {
		this.linearQuestions = linearQuestions.map(item => new LinearResult(item))
		this.radioQuestions = radioQuestions.map(item => new RadioResult(item))
		this.textQuestions = textQuestions.map(item => new TextResult(item))
	}
}

export const CategoryStatisticsType = s({
	linearQuestions: arrayOf(LinearResultType),
	radioQuestions: arrayOf(RadioResultType),
	textQuestions: arrayOf(TextResultType),
})

export default CategoryStatistics
