import { s, number, object, string } from 'modules/types'
import { SessionSimple, SessionSimpleType } from './SessionSimple'

export class SubSessionSimple {
	constructor({ id, employee_name: employeeName, session, submitted_at: submittedAt = null }) {
		this.id = id
		this.employeeName = employeeName
		this.session = new SessionSimple(session)
		this.submittedAt = submittedAt !== null ? new Date(submittedAt * 1000) : null
	}
}

export const SubSessionSimpleType = s({
	id: number.isRequired,
	employeeName: string,
	session: SessionSimpleType,
	submittedAt: object,
})

export default SubSessionSimple
