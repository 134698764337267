import { s, arrayOf } from 'modules/types'
import { QuestionType, SessionType } from 'modules/models/survey_admin/types'
import Question from 'modules/models/survey_admin/Question'
import Session from 'modules/models/survey_admin/Session'

export class SessionDetail {
	constructor({ session, questions }) {
		this.session = new Session(session)
		this.questions = questions.map(item => new Question(item))
	}
}

export const SessionDetailType = s({
	session: SessionType.isRequired,
	questions: arrayOf(QuestionType),
})

export default SessionDetail
