import { s, arrayOf, number, string } from 'modules/types'

class SecondLevelDistribution {
	constructor({ filter_value_id: filterValueId, filter_value_name: filterValueName, count, percentage }) {
		this.filterValueId = filterValueId
		this.filterValueName = filterValueName
		this.count = count
		this.percentage = percentage
	}
}

const SecondLevelDistributionType = s({
	filterValueId: number.isRequired,
	filterValueName: string.isRequired,
	count: number.isRequired,
	percentage: number.isRequired,
})

class FirstLevelDistribution {
	constructor({
		filter_value_id: filterValueId,
		filter_value_name: filterValueName,
		second_level_distributions: secondLevelDistributions,
	}) {
		this.filterValueId = filterValueId
		this.filterValueName = filterValueName
		this.secondLevelDistributions = secondLevelDistributions.map(item => new SecondLevelDistribution(item))
	}
}

export const FirstLevelDistributionType = s({
	filterValueId: number.isRequired,
	filterValueName: string.isRequired,
	secondLevelDistributions: arrayOf(SecondLevelDistributionType),
})

export default FirstLevelDistribution
