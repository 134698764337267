import React from 'react'
import { render } from 'react-dom'
import store, { history } from './store/createStore'
import ProviderWrapper from './store/provider'
import { Route, Switch } from 'react-router-dom'

// custom components
import PrivateRoute from 'components/PrivateRoute'

import indexRoutes from 'routes'

import './index.css'
import 'styles/main.css'
import 'normalize.css/normalize.css'
import 'semantic-ui-css/semantic.min.css'
import '@blueprintjs/core/dist/blueprint.css'
import 'assets/scss/material-dashboard-pro-react.css?v=1.1.0'
import 'draft-js/dist/Draft.css';

import * as serviceWorker from './serviceWorker'

const target = document.querySelector('#root')

render(
	<ProviderWrapper store={store} history={history}>
		<Switch>
			{indexRoutes.map((prop, key) => {
				if (prop.authenticatedRoute) {
					return <PrivateRoute path={prop.path} component={prop.component} key={key} store={store} />
				}
				return <Route path={prop.path} component={prop.component} key={key} />
			})}
		</Switch>
	</ProviderWrapper>,
	// $FlowIgnore - we don't want the missing dom element to be a silent error.
	target
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
