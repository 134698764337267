import { s, number } from 'modules/types'
import Question, { QuestionType } from './Question'

export class DefaultQuestionLink {
	constructor({ id, question, weight, order }) {
		this.id = id
		this.question = new Question(question)
		this.weight = weight
		this.order = order
	}
}

export const DefaultQuestionLinkType = s({
	id: number.isRequired,
	question: QuestionType,
	weight: number,
	order: number.isRequired,
})

export default DefaultQuestionLink
