import GROUP_TYPES from 'views/AppraisalAdminScoring/modules/group_types'

export const defaultFieldsDict = {
	Age: {
		id: 'Usia',
		en: 'Age',
	},
	Generation: {
		id: 'Generasi',
		en: 'Generation',
	},
	Department: {
		id: 'Departemen',
		en: 'Department',
	},
	Superior: {
		id: 'Atasan',
		en: 'Superior',
	},
	'Group name': {
		id: 'Grup',
		en: 'Group',
	},
	Position: {
		id: 'Posisi',
		en: 'Position',
	},
	Rank: {
		id: 'Jabatan',
		en: 'Title',
	},
	Title: {
		id: 'Pangkat',
		en: 'Rank',
	},
	'Join date': {
		id: 'Tanggal Bergabung',
		en: 'Join date',
	},
	'First Name': {
		id: 'Nama Depan',
		en: 'First Name',
	},
	'Last Name': {
		id: 'Nama Belakang',
		en: 'Last Name',
	},
	'Date of Birth': {
		id: 'Tanggal Lahir',
		en: 'Date of Birth',
	},
	Gender: {
		id: 'Jenis Kelamin',
		en: 'Gender',
	},
	'KTP Number': {
		id: 'Nomor KTP',
		en: 'KTP Number',
	},
	'Telegram Number': {
		id: 'Nomor Telegram',
		en: 'Telegram Number',
	},
	'Telegram Username': {
		id: 'Username Telegram',
		en: 'Telegram Username',
	},
	Password: {
		id: 'Sandi',
		en: 'Password',
	},
}

export const monthsDict = {
	JANUARY: {
		id: 'Januari',
		en: 'January',
	},
	FEBRUARY: {
		id: 'Februari',
		en: 'February',
	},
	MARCH: {
		id: 'Maret',
		en: 'March',
	},
	APRIL: {
		id: 'April',
		en: 'April',
	},
	MAY: {
		id: 'Mei',
		en: 'May',
	},
	JUNE: {
		id: 'Juni',
		en: 'June',
	},
	JULY: {
		id: 'Juli',
		en: 'July',
	},
	AUGUST: {
		id: 'Agustus',
		en: 'August',
	},
	SEPTEMBER: {
		id: 'September',
		en: 'September',
	},
	OCTOBER: {
		id: 'Oktober',
		en: 'October',
	},
	NOVEMBER: {
		id: 'November',
		en: 'November',
	},
	DECEMBER: {
		id: 'Desember',
		en: 'December',
	},
}

export const employmentInfoDict = {
	NIK: {
		id: 'NIK',
		en: 'NIK',
	},
	NAME: {
		id: 'Nama',
		en: 'Name',
	},
	DEPARTMENT: {
		id: 'Departemen',
		en: 'Department',
	},
	GROUP: {
		id: 'Grup',
		en: 'Group',
	},
	RANK: {
		id: 'Jabatan',
		en: 'Title',
	},
	TITLE: {
		id: 'Pangkat',
		en: 'Rank',
	},
	SUPERIOR: {
		id: 'Atasan',
		en: 'Superior',
	},
	POSITION: {
		id: 'Posisi',
		en: 'Position',
	},
	AGE: {
		id: 'Umur',
		en: 'Age',
	},
	GENERATION: {
		id: 'Generasi',
		en: 'Generation',
	},
	GENDER: {
		id: 'Jenis Kelamin',
		en: 'Gender',
	},
}

export const timeoffRequestStatusDict = {
	PENDING: {
		id: 'Pending',
		en: 'Pending',
	},
	APPROVED: {
		id: 'Disetujui',
		en: 'Approved',
	},
	REJECTED: {
		id: 'Ditolak',
		en: 'Declined',
	},
	CANCELLED: {
		id: 'Dibatalkan',
		en: 'Cancelled',
	},
	TAKEN: {
		id: 'Diambil',
		en: 'Taken',
	},
}

export const timeoffPeriodDict = {
	ANNUALLY: {
		id: 'Tahunan',
		en: 'Annually',
	},
	MONTHLY: {
		id: 'Bulanan',
		en: 'Monthly',
	},
	WEEKLY: {
		id: 'Mingguan',
		en: 'Weekly',
	},
	DAILY: {
		id: 'Harian',
		en: 'Daily',
	},
	UNKNOWN_PERIOD: {
		id: 'Tidak diketahui',
		en: 'Unknown',
	},
}

export const appraiserTypeDict = {
	SELF: {
		id: 'Sendiri',
		en: 'Self',
	},
	SUPERIOR: {
		id: 'Atasan',
		en: 'Superior',
	},
	SECOND_SUPERIOR: {
		id: 'Atasannya atasan',
		en: 'Second-degree superior',
	},
	SECOND_DEGREE_SUPERIOR: {
		id: 'Atasannya atasan',
		en: '2nd superior',
	},
	SUBORDINATE: {
		id: 'Bawahan',
		en: 'Subordinate',
	},
	PEER: {
		id: 'Setingkat',
		en: 'Peer',
	},
	OTHERS: {
		id: 'Lainnya',
		en: 'Others',
	},
	EXTERNAL: {
		id: 'Eksternal',
		en: 'External',
	},
	GROUP_LEADER: {
		id: 'Ketua Grup',
		en: 'Group Leader',
	},
	DEPARTMENT_HEAD: {
		id: 'Kepala Departemen',
		en: 'Department Head',
	},
}

export const groupViewDict = {
	[GROUP_TYPES.SUPERIOR]: {
		id: 'Atasan',
		en: 'Superior',
	},
	[GROUP_TYPES.DIRECT_SUPERVISOR]: {
		id: 'Atasan',
		en: 'Superior',
	},
	[GROUP_TYPES.SECOND_SUPERIOR]: {
		id: 'Atasannya atasan',
		en: 'Second degree superior',
	},
	[GROUP_TYPES.PEER]: {
		id: 'Rekan',
		en: 'Peer',
	},
	[GROUP_TYPES.SUBORDINATE]: {
		id: 'Bawahan',
		en: 'Subordinate',
	},
	[GROUP_TYPES.EXTERNAL]: {
		id: 'Pihak external',
		en: 'External',
	},
	[GROUP_TYPES.OTHERS]: {
		id: 'Pihak lain',
		en: 'Others',
	},
}
