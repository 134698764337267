import _ from 'lodash'
import api from 'modules/api'
import { toast } from 'utils'

// ------------------------------------
// Constants
// ------------------------------------

const MODULE_NAME = `request-timeoff`

export const API_URLS = {
	timeoffCalendar: () => `/api/timeoff/timeoff_calendar/`,
	pendingRequests: () => `/api/timeoff/timeoff_requests/pending_my_approval/`,
	pendingRequestById: timeoffRequestId => `/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/`,
	pendingRequestCalendar: timeoffRequestId =>
		`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/timeoff_calendar/`,
	pendingRequestComments: timeoffRequestId =>
		`/api/timeoff/timeoff_requests/pending_my_approval/${timeoffRequestId}/comments/`,

	myRequests: () => `/api/timeoff/timeoff_requests/my_requests/`,
	myRequestById: timeoffRequestId => `/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/`,
	myRequestComments: timeoffRequestId => `/api/timeoff/timeoff_requests/my_requests/${timeoffRequestId}/comments/`,
	balances: () => `/api/timeoff/timeoff_requests/get_balances/`,

	timeoffProfile: employeeId => `/api/timeoff/employee_timeoff_profile/${employeeId}/`,
	timeoffProfileCurrentRequests: employeeId => `/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/`,
	timeoffProfileCurrentRequestById: (employeeId, timeoffRequestId) =>
		`/api/timeoff/employee_timeoff_profile/${employeeId}/current_requests/${timeoffRequestId}/`,
	timeoffProfileHistories: employeeId => `/api/timeoff/employee_timeoff_profile/${employeeId}/timeoff_history/`,
}

const REFRESHING_TIMEOFF_CALENDAR = `${MODULE_NAME} | REFRESHING_TIMEOFF_CALENDAR`
const REFRESHED_TIMEOFF_CALENDAR = `${MODULE_NAME} | REFRESHED_TIMEOFF_CALENDAR`
const REFRESHING_PENDING_REQUESTS = `${MODULE_NAME} | REFRESHING_PENDING_REQUESTS`
const REFRESHED_PENDING_REQUESTS = `${MODULE_NAME} | REFRESHED_PENDING_REQUESTS`
const REFRESHING_PENDING_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHING_PENDING_REQUEST_BY_ID`
const REFRESHED_PENDING_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHED_PENDING_REQUEST_BY_ID`
const REFRESHING_PENDING_REQUEST_CALENDAR = `${MODULE_NAME} | REFRESHING_PENDING_REQUEST_CALENDAR`
const REFRESHED_PENDING_REQUEST_CALENDAR = `${MODULE_NAME} | REFRESHED_PENDING_REQUEST_CALENDAR`
const REFRESHING_PENDING_REQUEST_COMMENTS = `${MODULE_NAME} | REFRESHING_PENDING_REQUEST_COMMENTS`
const REFRESHED_PENDING_REQUEST_COMMENTS = `${MODULE_NAME} | REFRESHED_PENDING_REQUEST_COMMENTS`

const REFRESHING_MY_REQUESTS = `${MODULE_NAME} | REFRESHING_MY_REQUESTS`
const REFRESHED_MY_REQUESTS = `${MODULE_NAME} | REFRESHED_MY_REQUESTS`
const REFRESHING_MY_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHING_MY_REQUEST_BY_ID`
const REFRESHED_MY_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHED_MY_REQUEST_BY_ID`
const REFRESHING_MY_REQUEST_COMMENTS = `${MODULE_NAME} | REFRESHING_MY_REQUEST_COMMENTS`
const REFRESHED_MY_REQUEST_COMMENTS = `${MODULE_NAME} | REFRESHED_MY_REQUEST_COMMENTS`

const REFRESHING_BALANCES = `${MODULE_NAME} | REFRESHING_BALANCES`
const REFRESHED_BALANCES = `${MODULE_NAME} | REFRESHED_BALANCES`
const REFRESHING_EMPLOYEE_TIMEOFF_PROFILE = `${MODULE_NAME} | REFRESHING_EMPLOYEE_TIMEOFF_PROFILE`
const REFRESHED_EMPLOYEE_TIMEOFF_PROFILE = `${MODULE_NAME} | REFRESHED_EMPLOYEE_TIMEOFF_PROFILE`
const REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUESTS = `${MODULE_NAME} | REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUESTS`
const REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUESTS = `${MODULE_NAME} | REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUESTS`
const REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID`
const REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID = `${MODULE_NAME} | REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID`
const REFRESHING_TIMEOFF_PROFILE_HISTORIES = `${MODULE_NAME} | REFRESHING_TIMEOFF_PROFILE_HISTORIES`
const REFRESHED_TIMEOFF_PROFILE_HISTORIES = `${MODULE_NAME} | REFRESHED_TIMEOFF_PROFILE_HISTORIES`

const APPROVE_PENDING_REQUEST = `${MODULE_NAME} | APPROVE_PENDING_REQUEST`
const DECLINE_PENDING_REQUEST = `${MODULE_NAME} | DECLINE_PENDING_REQUEST`
const CREATE_PENDING_REQUEST_COMMENT = `${MODULE_NAME} | CREATE_PENDING_REQUEST_COMMENT`
const EDITED_MY_REQUEST = `${MODULE_NAME} | EDITED_MY_REQUEST`
const CANCELLED_MY_REQUEST = `${MODULE_NAME} | CANCELLED_MY_REQUEST`
const CREATE_REQUEST = `${MODULE_NAME} | CREATE_REQUEST`
const APPROVE_CURRENT_REQUEST = `${MODULE_NAME} | APPROVE_CURRENT_REQUEST`
const DECLINE_CURRENT_REQUEST = `${MODULE_NAME} | DECLINE_CURRENT_REQUEST`
const CREATE_CURRENT_REQUEST_COMMENT = `${MODULE_NAME} | CREATE_CURRENT_REQUEST_COMMENT`
const EXPAND_REQUEST = `${MODULE_NAME} | EXPAND_REQUEST`
const COLLAPSE_REQUEST = `${MODULE_NAME} | COLLAPSE_REQUEST`
const CHANGE_MONTH_FILTER = `${MODULE_NAME} | CHANGE_MONTH_FILTER`
const CHANGE_YEAR_FILTER = `${MODULE_NAME} | CHANGE_YEAR_FILTER`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshTimeoffCalendar() {
	return (dispatch, getState) => {
		const {
			requestTimeoff: { data },
		} = getState()
		let apiUrl = API_URLS.timeoffCalendar()
		let args = {}
		if (data.monthFilter) {
			args.month = data.monthFilter
		}
		if (data.yearFilter) {
			args.year = data.yearFilter
		}
		let getParam = Object.keys(args)
			.map(key => `${key}=${args[key]}`)
			.join('&')
		if (getParam) getParam = `?${getParam}`
		dispatch({ type: REFRESHING_TIMEOFF_CALENDAR, apiUrl })
		return api.timeoff.getTimeoffCalendar(getParam).then(({ data: calendar }) => {
			dispatch({ type: REFRESHED_TIMEOFF_CALENDAR, apiUrl, data: calendar })
			return calendar
		})
	}
}

function refreshPendingRequests() {
	return dispatch => {
		let apiUrl = API_URLS.pendingRequests()
		dispatch({ type: REFRESHING_PENDING_REQUESTS, apiUrl })
		return api.timeoff.timeoff_requests.pending_my_approval.get().then(({ data }) => {
			dispatch({ type: REFRESHED_PENDING_REQUESTS, apiUrl, data })
			return data
		})
	}
}

export function refreshPendingRequestById(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.pendingRequestById(timeoffRequestId)
		dispatch({ type: REFRESHING_PENDING_REQUEST_BY_ID, apiUrl })
		return api.timeoff.timeoff_requests.pending_my_approval.getById(timeoffRequestId).then(({ data }) => {
			dispatch({ type: REFRESHED_PENDING_REQUEST_BY_ID, apiUrl, data })
			return data
		})
	}
}

export function refreshPendingRequestCalendar(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.pendingRequestCalendar(timeoffRequestId)
		dispatch({ type: REFRESHING_PENDING_REQUEST_CALENDAR, apiUrl })
		return api.timeoff.timeoff_requests.pending_my_approval.getCalendar(timeoffRequestId).then(({ data }) => {
			dispatch({ type: REFRESHED_PENDING_REQUEST_CALENDAR, apiUrl, data })
			return data
		})
	}
}

export function refreshPendingRequestComments(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.pendingRequestComments(timeoffRequestId)
		dispatch({ type: REFRESHING_PENDING_REQUEST_COMMENTS, apiUrl })
		return api.timeoff.timeoff_requests.pending_my_approval.comments.get(timeoffRequestId).then(({ data }) => {
			dispatch({ type: REFRESHED_PENDING_REQUEST_COMMENTS, apiUrl, data })
			return data
		})
	}
}

function refreshMyRequests() {
	return dispatch => {
		let apiUrl = API_URLS.myRequests()
		dispatch({ type: REFRESHING_MY_REQUESTS, apiUrl })
		return api.timeoff.timeoff_requests.my_requests.get().then(({ data }) => {
			dispatch({ type: REFRESHED_MY_REQUESTS, apiUrl, data })
			return data
		})
	}
}

export function refreshMyRequestById(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.myRequestById(timeoffRequestId)
		dispatch({ type: REFRESHING_MY_REQUEST_BY_ID, apiUrl })
		return api.timeoff.timeoff_requests.my_requests.getById(timeoffRequestId).then(({ data }) => {
			dispatch({ type: REFRESHED_MY_REQUEST_BY_ID, apiUrl, data })
			return data
		})
	}
}

function refreshMyRequestComments(timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.myRequestComments(timeoffRequestId)
		dispatch({ type: REFRESHING_MY_REQUEST_COMMENTS, apiUrl })
		return api.timeoff.timeoff_requests.my_requests.getComments(timeoffRequestId).then(({ data }) => {
			dispatch({ type: REFRESHED_MY_REQUEST_COMMENTS, apiUrl, data })
			return data
		})
	}
}

export function refreshBalances() {
	return dispatch => {
		let apiUrl = API_URLS.balances()
		dispatch({ type: REFRESHING_BALANCES, apiUrl })
		return api.timeoff.timeoff_requests.getBalances().then(({ data }) => {
			dispatch({ type: REFRESHED_BALANCES, apiUrl, data })
			return data
		})
	}
}

export function refreshTimeoffProfile(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffProfile(employeeId)
		dispatch({ type: REFRESHING_EMPLOYEE_TIMEOFF_PROFILE, apiUrl })
		return api.timeoff.employee_timeoff_profile.getProfile(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_EMPLOYEE_TIMEOFF_PROFILE, apiUrl, data })
			return data
		})
	}
}

export function refreshTimeoffProfileCurrentRequests(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffProfileCurrentRequests(employeeId)
		dispatch({ type: REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUESTS, apiUrl })
		return api.timeoff.employee_timeoff_profile.current_requests.get(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUESTS, apiUrl, data })
			return data
		})
	}
}

function refreshTimeoffProfileCurrentRequestById(employeeId, timeoffRequestId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffProfileCurrentRequestById(employeeId, timeoffRequestId)
		dispatch({ type: REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID, apiUrl })
		return api.timeoff.employee_timeoff_profile.current_requests
			.getById(employeeId, timeoffRequestId)
			.then(({ data: employeeTimeoffRequest }) => {
				dispatch({
					type: REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID,
					apiUrl,
					employeeId,
					data: employeeTimeoffRequest,
				})
				return employeeTimeoffRequest
			})
	}
}

export function refreshTimeoffProfileHistories(employeeId) {
	return dispatch => {
		let apiUrl = API_URLS.timeoffProfileHistories(employeeId)
		dispatch({ type: REFRESHING_TIMEOFF_PROFILE_HISTORIES, apiUrl })
		return api.timeoff.employee_timeoff_profile.timeoff_history.get(employeeId).then(({ data }) => {
			dispatch({ type: REFRESHED_TIMEOFF_PROFILE_HISTORIES, apiUrl, data })
			return data
		})
	}
}

export function approvePendingRequest(timeoffRequestId) {
	return dispatch => {
		return api.timeoff.timeoff_requests.pending_my_approval.approve(timeoffRequestId).then(() => {
			dispatch(refreshMyRequests())
			dispatch(refreshTimeoffCalendar())
			dispatch({ type: APPROVE_PENDING_REQUEST, timeoffRequestId })
			toast(`Successfully approved request`)
		})
	}
}

export function declinePendingRequest(timeoffRequestId) {
	return dispatch => {
		return api.timeoff.timeoff_requests.pending_my_approval.decline(timeoffRequestId).then(() => {
			dispatch(refreshMyRequests())
			dispatch(refreshTimeoffCalendar())
			dispatch({ type: DECLINE_PENDING_REQUEST, timeoffRequestId })
			toast(`Succesfully declined request`)
		})
	}
}

export function createPendingRequestComment(timeoffRequestId, comment) {
	return dispatch => {
		return api.timeoff.timeoff_requests.pending_my_approval.comments
			.create(timeoffRequestId, comment)
			.then(({ data: timeoffComment }) => {
				dispatch(refreshPendingRequestComments(timeoffRequestId))
				dispatch({ type: CREATE_PENDING_REQUEST_COMMENT })
				return timeoffComment
			})
	}
}

export function editMyRequest(timeoffRequestId, data) {
	return dispatch => {
		return api.timeoff.timeoff_requests.my_requests.edit(timeoffRequestId, data).then(({ data: myTimeoffRequest }) => {
			dispatch(refreshMyRequests())
			dispatch(refreshMyRequestComments(timeoffRequestId))
			dispatch({ type: EDITED_MY_REQUEST, myTimeoffRequest })
			toast(`Successfully edited request`)
			return myTimeoffRequest
		})
	}
}

export function cancelMyRequest(timeoffRequestId) {
	return dispatch => {
		return api.timeoff.timeoff_requests.my_requests.cancel(timeoffRequestId).then(({ data: myTimeoffRequest }) => {
			dispatch(refreshMyRequests())
			dispatch({ type: CANCELLED_MY_REQUEST, myTimeoffRequest })
			toast(`Successfully cancelled request`)
			return myTimeoffRequest
		})
	}
}

export function createRequest(data) {
	return dispatch => {
		return api.timeoff.timeoff_requests.createRequest(data).then(({ data: myTimeoffRequest }) => {
			dispatch(refreshPendingRequests())
			dispatch(refreshMyRequests())
			dispatch(refreshMyRequestComments(myTimeoffRequest.id))
			dispatch(refreshTimeoffCalendar())
			dispatch({ type: CREATE_REQUEST })
			toast(`Successfully applied time off`)
			return myTimeoffRequest
		})
	}
}

export function calculateDays(data) {
	return dispatch => {
		return api.timeoff.timeoff_requests.calculateDays(data).then(({ data }) => {
			return data.days
		})
	}
}

export function approveCurrentRequest(employeeId, timeoffRequestId) {
	return dispatch => {
		return api.timeoff.employee_timeoff_profile.current_requests.approve(employeeId, timeoffRequestId).then(() => {
			dispatch(refreshTimeoffProfileCurrentRequests(employeeId))
			dispatch({ type: APPROVE_CURRENT_REQUEST })
			toast(`Successfully approved request`)
		})
	}
}

export function declineCurrentRequest(employeeId, timeoffRequestId) {
	return dispatch => {
		return api.timeoff.employee_timeoff_profile.current_requests.decline(employeeId, timeoffRequestId).then(() => {
			dispatch(refreshTimeoffProfileCurrentRequests(employeeId))
			dispatch({ type: DECLINE_CURRENT_REQUEST })
			toast(`Successfully declined request`)
		})
	}
}

export function createCurrentRequestComment(employeeId, timeoffRequestId, comment) {
	return dispatch => {
		return api.timeoff.employee_timeoff_profile.current_requests.comments
			.create(employeeId, timeoffRequestId, comment)
			.then(() => {
				dispatch(refreshTimeoffProfileCurrentRequestById(employeeId, timeoffRequestId))
				dispatch({ type: CREATE_CURRENT_REQUEST_COMMENT })
			})
	}
}

export function expandRequest(timeoffRequestId) {
	return dispatch => {
		dispatch(refreshPendingRequestComments(timeoffRequestId))
		dispatch({ type: EXPAND_REQUEST, timeoffRequestId })
	}
}

export function collapseRequest(timeoffRequestId) {
	return dispatch => {
		dispatch({ type: COLLAPSE_REQUEST, timeoffRequestId })
	}
}

export function changeMonthFilter(month) {
	return dispatch => {
		dispatch({ type: CHANGE_MONTH_FILTER, month })
		dispatch(refreshTimeoffCalendar())
	}
}

export function changeYearFilter(year) {
	return dispatch => {
		dispatch({ type: CHANGE_YEAR_FILTER, year })
		dispatch(refreshTimeoffCalendar())
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshPendingRequests())
		dispatch(refreshTimeoffCalendar())
		dispatch(refreshMyRequests()).then(myRequests => {
			myRequests.forEach(request => {
				dispatch(refreshMyRequestComments(request.id))
			})
		})
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		expandedRequestIds: [],
		monthFilter: new Date().getMonth() + 1,
		yearFilter: new Date().getFullYear(),
	},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_TIMEOFF_CALENDAR:
		case REFRESHING_PENDING_REQUESTS:
		case REFRESHING_PENDING_REQUEST_BY_ID:
		case REFRESHING_PENDING_REQUEST_CALENDAR:
		case REFRESHING_PENDING_REQUEST_COMMENTS:
		case REFRESHING_MY_REQUESTS:
		case REFRESHING_MY_REQUEST_BY_ID:
		case REFRESHING_MY_REQUEST_COMMENTS:
		case REFRESHING_BALANCES:
		case REFRESHING_EMPLOYEE_TIMEOFF_PROFILE:
		case REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUESTS:
		case REFRESHING_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID:
		case REFRESHING_TIMEOFF_PROFILE_HISTORIES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_PENDING_REQUESTS:
			// update each requestById api state
			let requestByIdApiStateDict = {}
			action.data.forEach(pendingRequest => {
				requestByIdApiStateDict[API_URLS.pendingRequestById(pendingRequest.id)] = pendingRequest
			})
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
					...requestByIdApiStateDict,
				},
			}
		case REFRESHED_MY_REQUESTS:
			// update each requestById api state
			let myRequestByIdApiStateDict = {}
			action.data.forEach(myRequest => {
				myRequestByIdApiStateDict[API_URLS.myRequestById(myRequest.id)] = myRequest
			})
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
					...myRequestByIdApiStateDict,
				},
			}
		case REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUEST_BY_ID:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
					[API_URLS.timeoffProfileCurrentRequests(action.employeeId)]: state.api[
						API_URLS.timeoffProfileCurrentRequests(action.employeeId)
					].map(timeoffRequest => {
						if (timeoffRequest.id === action.data.id) {
							return action.data
						}
						return timeoffRequest
					}),
				},
			}
		case REFRESHED_TIMEOFF_CALENDAR:
		case REFRESHED_PENDING_REQUEST_BY_ID:
		case REFRESHED_PENDING_REQUEST_CALENDAR:
		case REFRESHED_PENDING_REQUEST_COMMENTS:
		case REFRESHED_MY_REQUEST_BY_ID:
		case REFRESHED_MY_REQUEST_COMMENTS:
		case REFRESHED_BALANCES:
		case REFRESHED_EMPLOYEE_TIMEOFF_PROFILE:
		case REFRESHED_TIMEOFF_PROFILE_CURRENT_REQUESTS:
		case REFRESHED_TIMEOFF_PROFILE_HISTORIES:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case APPROVE_PENDING_REQUEST:
		case DECLINE_PENDING_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.pendingRequests()]: state.api[API_URLS.pendingRequests()].filter(
						pendingRequest => pendingRequest.id !== action.timeoffRequestId
					),
				},
			}
		// case CREATE_COMMENT:
		// case CREATE_REQUEST:
		case CANCELLED_MY_REQUEST:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.pendingRequests()]: state.api[API_URLS.pendingRequests()].filter(
						pendingRequest => pendingRequest.id !== action.myTimeoffRequest.id
					),
				},
			}
		case EXPAND_REQUEST:
			return {
				...state,
				data: {
					...state.data,
					expandedRequestIds: [...state.data.expandedRequestIds, action.timeoffRequestId],
				},
			}
		case COLLAPSE_REQUEST:
			return {
				...state,
				data: {
					...state.data,
					expandedRequestIds: state.data.expandedRequestIds.filter(
						expandedRequestId => expandedRequestId !== action.timeoffRequestId
					),
				},
			}
		case CHANGE_MONTH_FILTER:
			return {
				...state,
				data: {
					...state.data,
					monthFilter: action.month,
				},
			}
		case CHANGE_YEAR_FILTER:
			return {
				...state,
				data: {
					...state.data,
					yearFilter: action.year,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
