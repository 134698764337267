export const QUESTION_TYPE = {
	RADIO: 0,
	INPUT: 1,
	TEXT: 2,
	LINEAR: 3,
	CHECKBOX: 4,
}

export const EMPLOYEE_COLUMNS = {
	NIK: 1,
	NAME: 2,
	POSITION: 3,
	RANK: 4,
	TITLE: 5,
	GROUP: 6,
	DEPARTMENT: 7,
}

export const APPRAISER_TYPE = {
	SELF: 0,
	PEER: 1,
	SUPERIOR: 2,
	SECOND_DEGREE_SUPERIOR: 3,
	SUBORDINATE: 4,
}

// TODO: Refactor this to model folder
export const SCORE_CARD_STATUS = {
	NOT_STARTED: 0,
	IN_PROGRESS: 1,
	COMPLETED: 2,
}

export const PERMISSIONS = {
	HR_ACCESS: 'user.root.hr',
	APPROVE_REJECT_TELEGRAM: 'user.hr.approve_reject_telegram',
	EDIT_EMPLOYEE_DETAILS: 'user.hr.edit_employee_details',
	ADD_EMPLOYEE: 'user.hr.add_employee',
	EDIT_ACCOUNT_INFORMATION: 'user.hr.edit_account_info',
	EDIT_EMPLOYMENT_INFORMATION: 'user.hr.edit_employment_info',

	SURVEY_ADMIN_ACCESS: 'user.root.survey_admin',
	CREATE_SURVEY: 'user.survey_admin.create_survey',
	START_SURVEY: 'user.survey_admin.start_survey',
	STOP_SURVEY: 'user.survey_admin.stop_survey',

	MANAGE_MY_COMPANY: 'user.root.my_company',
	MANAGE_ANNOUNCEMENT: 'user.my_company.manage_announcement',
	MANAGE_COMPANY_POLICY: 'user.my_company.manage_company_policy',
	ACCESS_DEMOGRAPHICS: 'user.my_company.access_demographics',

	PA_ADMIN_ACCESS: 'user.root.pa_admin',
	EXPORT_RESULT: 'rank.pa_admin.pa_scoring_export_result',
	EDIT_SCORING: 'rank.pa_admin.pa_edit_scoring',
	VIEW_CREATE_SCORING: 'rank.pa_admin.pa_view_create_scoring',
	CREATE_EDIT_SESSION: 'user.pa_admin.pa_create_edit_session',
	CREATE_EDIT_TEMPLATE: 'user.pa_admin.pa_create_edit_template',

	SETTING_ACCESS: 'user.root.setting',
	SETTING_PERMISSION_ACCESS: 'user.setting.permission',
	SETTING_WORKSTATION_ACCESS: 'user.setting.workstation',
	SETTING_HR_ACCESS: 'user.setting.hr',
	SETTING_DASHBOARD_ACCESS: 'user.setting.dashboard',
	SETTING_PUBLIC_HOLIDAY: 'user.setting.public_holiday',
	SETTING_WORKING_CYCLE: 'user.setting.working_cycle',
	SETTING_TIMEOFF: 'user.setting.timeoff',
}

export const PERMISSION_PARENT = {
	ROOT: 0,
	SURVEY_ADMIN: 1,
	HR: 2,
	SETTING: 3,
	MY_COMPANY: 4,
	PA_ADMIN: 5,
}

export const TIMEOFF_TYPE_COLOR = {
	RED: 1, // #e74c3c
	GREEN: 2, // #2ecc71
	BLUE: 3, //#3498db
	YELLOW: 4, //#f1c40f
	ORANGE: 5, //#e67e22
	PINK: 6, //#f78fb3
	PURPLE: 7, //#be2edd
}

export const monthOptions = [
	{
		value: 1,
		label: 'JANUARY',
	},
	{
		value: 2,
		label: 'FEBRUARY',
	},
	{
		value: 3,
		label: 'MARCH',
	},
	{
		value: 4,
		label: 'APRIL',
	},
	{
		value: 5,
		label: 'MAY',
	},
	{
		value: 6,
		label: 'JUNE',
	},
	{
		value: 7,
		label: 'JULY',
	},
	{
		value: 8,
		label: 'AUGUST',
	},
	{
		value: 9,
		label: 'SEPTEMBER',
	},
	{
		value: 10,
		label: 'OCTOBER',
	},
	{
		value: 11,
		label: 'NOVEMBER',
	},
	{
		value: 12,
		label: 'DECEMBER',
	},
]

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
