import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'

// material-ui icons
import Menu from '@material-ui/icons/Menu'
import MoreVert from '@material-ui/icons/MoreVert'
import ViewList from '@material-ui/icons/ViewList'

// core components
import HeaderLinks from 'components/HeaderLinks'
import IconButton from 'newComponents/CustomButtons/IconButton'

// modules
import skleemi18n from 'utils/skleemi18n'
import headerStyle from 'assets/jss/skleem/headerStyle.jsx'

const i18nDict = {
	HOME: {
		id: 'Beranda',
		en: 'Home',
	},
	BRAINSTORMING: {
		id: 'Tanya Jawab',
		en: 'Brainstorming',
	},
	JOB_INFORMATION: {
		id: 'Info Pekerjaan',
		en: 'Job Information',
	},
	IDEA: {
		id: 'Ide',
		en: 'Idea',
	},
	ONGOING: {
		id: 'Berlangsung',
		en: 'Ongoing',
	},
	COMPLETED: {
		id: 'Selesai',
		en: 'Complete',
	},
	ENGAGEMENT: {
		id: 'Keterlibatan',
		en: 'Engagement',
	},
	EMPLOYEE_DATABASE: {
		id: 'Database Pegawai',
		en: 'Employee Database',
	},
	TEMPLATE: {
		id: 'Template',
		en: 'Template',
	},
	SESSION: {
		id: 'Sesi',
		en: 'Session',
	},
	SCORING: {
		id: 'Penilaian',
		en: 'Scoring',
	},
	PERMISSIONS: {
		id: 'Akses',
		en: 'Permissions',
	},
	WORK_STATION: {
		id: 'Pekerjaan',
		en: 'Work Station',
	},

	// fallback for sidebar
	DASHBOARD: {
		id: 'Dasbor',
		en: 'Dashboard',
	},
	WORKSTATION: {
		id: 'Pekerjaan',
		en: 'Workstation',
	},
	SURVEY: {
		id: 'Survei',
		en: 'Survey',
	},
	APPRAISAL: {
		id: 'Penilaian Kinerja',
		en: 'Appraisal',
	},
	HUMAN_RESOURCES: {
		id: 'HRD',
		en: 'Human Resources',
	},
	SURVEY_ADMIN: {
		id: 'Admin Survei',
		en: 'Survey Admin',
	},
	APPRAISAL_ADMIN: {
		id: 'Admin Penilaian Kinerja',
		en: 'Appraisal Admin',
	},
	SETTINGS: {
		id: 'Pengaturan',
		en: 'Settings',
	},
	ANNOUNCEMENT: {
		id: 'Pemberitahuan',
		en: 'Announcement',
	},
	COMPANY_POLICY: {
		id: 'Peraturan',
		en: 'Policies',
	},
	IDEA_WALL: {
		id: 'Dinding Ide',
		en: 'Idea Wall',
	},
	WORKING_CYCLE: {
		id: 'Jam Kerja',
		en: 'Working Hours',
	},
	PUBLIC_HOLIDAY: {
		id: 'Hari Libur',
		en: 'Public Holiday',
	},
	TIMEOFF: {
		id: 'Cuti',
		en: 'Timeoff',
	},
	EXPERIENCE: {
		id: 'Jam Terbang',
		en: 'Experience',
	},
	DEMOGRAPHICS: {
		id: 'Demografik',
		en: 'Demographics',
	},
	ANALYTICS: {
		id: 'Analitik',
		en: 'Analytics',
	},
}

function Header({ ...props }) {
	function makeBrand() {
		let name = null
		let views = null
		let maxLenName = -1
		let maxLenViews = -1
		props.routes
			.filter(route => {
				if (route.permissionsRequired) {
					return route.permissionsRequired.reduce((prev, cur) => {
						return prev && props.currentUserPermissions.indexOf(cur) > -1
					}, true)
				} else {
					return true
				}
			})
			.forEach((route, key) => {
				if (route.collapse) {
					route.views.forEach((view, key) => {
						if (props.location.pathname.indexOf(view.path) === 0) {
							if (view.path.length > maxLenViews) {
								maxLenViews = view.path.length
								name = view.name
								views = route.views
							}
						}
					})
				}
				if (props.location.pathname.indexOf(route.path) === 0) {
					if (route.path.length > maxLenName) {
						maxLenName = route.path.length
						name = route.name
					}
				}
			})

		if (views === null) {
			return (
				<Button href="#" className={classes.titleSelected} variant="contained">
					{props.translater[name] || name}
				</Button>
			)
		} else {
			return views.map((view, key) => {
				return (
					<NavLink to={view.path} key={key}>
						<Button
							className={props.location.pathname.indexOf(view.path) === 0 ? classes.titleSelected : classes.title}
							variant={props.location.pathname.indexOf(view.path) === 0 ? 'contained' : 'text'}
						>
							{props.translater[view.name]}
						</Button>
					</NavLink>
				)
			})
		}
	}

	const { classes, color, rtlActive } = props
	const appBarClasses = cx({
		[' ' + classes[color]]: color,
	})
	const sidebarMinimize =
		classes.sidebarMinimize +
		' ' +
		cx({
			[classes.sidebarMinimizeRTL]: rtlActive,
		})
	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<Hidden smDown>
					<div className={sidebarMinimize}>
						{props.miniActive ? (
							<IconButton onClick={props.sidebarMinimize} style={{ borderRadius: '50%' }} color="default">
								<ViewList className={classes.sidebarMiniIcon} />
							</IconButton>
						) : (
							<IconButton onClick={props.sidebarMinimize} style={{ borderRadius: '50%' }} color="default">
								<MoreVert className={classes.sidebarMiniIcon} />
							</IconButton>
						)}
					</div>
					<div style={{ marginRight: '10px' }} />
				</Hidden>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					{makeBrand()}
				</div>
				<Hidden smDown implementation="css">
					<HeaderLinks
						rtlActive={rtlActive}
						handleLogout={props.handleLogout}
						currentUser={props.currentUser}
						frontendSetting={props.frontendSetting}
						handleChangeSetting={props.handleChangeSetting}
						redirect={props.redirect}
						fetchMoreNotifications={props.fetchMoreNotifications}
						notifications={props.notifications}
						notificationPagination={props.notificationPagination}
						changePassword={props.changePassword}
					/>
				</Hidden>
				<Hidden mdUp>
					<IconButton
						className={classes.appResponsive}
						color={'transparent'}
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	)
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	rtlActive: PropTypes.bool,
}

export default withStyles(headerStyle)(skleemi18n(Header, i18nDict))
