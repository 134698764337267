import { string, s, number } from 'modules/types'

export const SESSION_STATUS = {
	DRAFT: 0,
	STARTED: 1,
	STOPPED: 2,
}

export class Session {
	constructor({ id, name, description, status, start_date: startDate, end_date: endDate }) {
		this.id = id
		this.name = name
		this.description = description
		this.status = status
		this.startDate = startDate
		this.endDate = endDate
	}
}

export const SessionType = s({
	id: number,
	name: string,
	description: string,
	status: number, // @SESSION_STATUS
	startDate: number,
	endDate: number,
})

export default Session
