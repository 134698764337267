import { s, arrayOf } from 'modules/types'
import Employee, { EmployeeType } from './Employee'
import JobDescription, { JobDescriptionType } from './JobDescription'
import EmploymentHistory, { EmploymentHistoryType } from './EmploymentHistory'

export class JobInformation {
	constructor({ employee, job_description: jobDescription, employment_histories: employmentHistories }) {
		this.employee = new Employee(employee)
		this.jobDescription = jobDescription && new JobDescription(jobDescription)
		this.employmentHistories = employmentHistories.map(item => new EmploymentHistory(item))
	}
}

export const JobInformationType = s({
	employee: EmployeeType,
	jobDescription: JobDescriptionType,
	employmentHistories: arrayOf(EmploymentHistoryType),
})

export default JobInformation
