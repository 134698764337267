import { s, number, object } from 'modules/types'
import Employee, { EmployeeType } from './Employee'

export class ExperienceRequestSubscription {
	constructor({ id, approver, approver_num: approverNum, approved_hours: approvedHours, added }) {
		this.id = id
		this.approver = new Employee(approver)
		this.approverNum = approverNum
		this.approvedHours = approvedHours
		this.added = new Date(added * 1000)
	}
}

export const ExperienceRequestSubscriptionType = s({
	id: number.isRequired,
	approver: EmployeeType,
	approverNum: number.isRequired,
	approvedHours: number.isRequired,
	added: object.isRequired,
})

export default ExperienceRequestSubscription
