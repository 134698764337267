import { s, number, string } from 'modules/types'

class GroupStageAppraiserType {
	constructor({
		group_id: groupId,
		group_name: groupName,
		group_stage_id: groupStageId,
		id,
		stage_number: stageNumber,
		type,
	}) {
		this.groupId = groupId
		this.groupName = groupName
		this.groupStageId = groupStageId
		this.id = id
		this.stageNumber = stageNumber
		this.type = type
	}
}

export const GroupStageAppraiserTypeType = s({
	groupId: number.isRequired,
	groupName: string.isRequired,
	groupStageId: number.isRequired,
	id: number.isRequired,
	stageNumber: number.isRequired,
	type: number.isRequired,
})

export default GroupStageAppraiserType
