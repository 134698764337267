import { s, arrayOf } from 'modules/types'
import { IndividualQuestionSummary, IndividualQuestionSummaryType } from './IndividualQuestionSummary'
import { Employee, EmployeeType } from './Employee'

export class IndividualSummary {
	constructor({ data, employee }) {
		this.data = data.map(item => new IndividualQuestionSummary(item))
		this.employee = new Employee(employee)
	}
}

export const IndividualSummaryType = s({
	data: arrayOf(IndividualQuestionSummaryType),
	employee: EmployeeType.isRequired,
})

export default IndividualSummary
