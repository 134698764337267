import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let AppraisalAdminSessionComponent

const AppraisalAdminSession = store => {
	if (AppraisalAdminSessionComponent === undefined) {
		AppraisalAdminSessionComponent = Loadable({
			loader: () => {
				return import('views/AppraisalAdminSession/containers/AppraisalAdminSessionContainer')
			},
			loading: RouteLoader,
		})
	}
	return AppraisalAdminSessionComponent
}

export default AppraisalAdminSession
