import { s, arrayOf, string } from 'modules/types'
import Section, { SectionType } from './Section'

export class JobDescription {
	constructor({ title_name: titleName, rank_name: rankName, sections }) {
		this.titleName = titleName
		this.rankName = rankName
		this.sections = sections.map(item => new Section(item))
	}
}

export const JobDescriptionType = s({
	titleName: string.isRequired,
	rankName: string.isRequired,
	sections: arrayOf(SectionType),
})

export default JobDescription
