import _ from 'lodash'
import api from 'modules/api'
import { getObjectOrUndefined, toast } from 'utils'
// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-public-holiday'

export const TABS = {
	HOLIDAY_GROUP: 0,
	HOLIDAY_SUMMARY: 1,
}

export const API_URLS = {
	holidayTypes: () => `/api/setting/timeoff/holiday_types/`,
	yearSummary: () => `/api/setting/timeoff/holiday_types/year_summary/`,
	holidayGroups: () => `/api/setting/timeoff/holiday_groups/`,
	holidayGroupsWithCount: () => `/api/setting/timeoff/holiday_groups/with_employee_count/`,
	holidayGroupDetail: groupId => `/api/setting/timeoff/holiday_groups/${groupId}/`,
	holidayGroupEmployees: groupId => `/api/setting/timeoff/holiday_groups/${groupId}/get_employees/`,
	holidayGroupLogs: groupId => `/api/setting/timeoff/holiday_groups/${groupId}/holiday_group_logs/`,
	holidayGroupLogDetail: (groupId, logId) =>
		`/api/setting/timeoff/holiday_groups/${groupId}/holiday_group_logs/${logId}/`,
}

const REFRESHING_HOLIDAY_TYPES = `${MODULE_NAME} | REFRESHING_HOLIDAY_TYPES`
const REFRESHED_HOLIDAY_TYPES = `${MODULE_NAME} | REFRESHED_HOLIDAY_TYPES`
const REFRESHING_YEAR_SUMMARY = `${MODULE_NAME} | REFRESHING_YEAR_SUMMARY`
const REFRESHED_YEAR_SUMMARY = `${MODULE_NAME} | REFRESHED_YEAR_SUMMARY`
const REFRESHING_HOLIDAY_GROUPS = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUPS`
const REFRESHED_HOLIDAY_GROUPS = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUPS`
const REFRESHING_HOLIDAY_GROUPS_WITH_COUNT = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUPS_WITH_COUNT`
const REFRESHED_HOLIDAY_GROUPS_WITH_COUNT = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUPS_WITH_COUNT`
const REFRESHING_HOLIDAY_GROUP_DETAIL = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUP_DETAIL`
const REFRESHED_HOLIDAY_GROUP_DETAIL = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUP_DETAIL`
const REFRESHING_HOLIDAY_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUP_EMPLOYEES`
const REFRESHED_HOLIDAY_GROUP_EMPLOYEES = `${MODULE_NAME} | REFRESHED_HOLIDAY_GROUP_EMPLOYEES`
const REFRESHING_HOLIDAY_GROUP_LOGS = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUP_LOGS`
const REFRESHED_HOLIDAY_GROUP_LOGS = `${MODULE_NAME} | REFRESHED_HOLIDAY_TYPES_LOGS`
const REFRESHING_HOLIDAY_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHING_HOLIDAY_GROUP_LOG_DETAIL`
const REFRESHED_HOLIDAY_GROUP_LOG_DETAIL = `${MODULE_NAME} | REFRESHED_HOLIDAY_TYPES_LOG_DETAIL`

const EDITED_YEAR_SUMMARY = `${MODULE_NAME} | EDITED_YEAR_SUMMARY`
const CREATED_HOLIDAY_TYPE = `${MODULE_NAME} | CREATED_HOLIDAY_TYPE`
const CREATED_HOLIDAY_GROUP = `${MODULE_NAME} | CREATED_HOLIDAY_GROUP`
const EDITED_HOLIDAY_GROUP = `${MODULE_NAME} | EDITED_HOLIDAY_GROUP`
const DELETED_HOLIDAY_GROUP = `${MODULE_NAME} | DELETED_HOLIDAY_GROUP`
const MOVED_EMPLOYEES = `${MODULE_NAME} | MOVED_EMPLOYEES`

const CHANGE_SELECTED_GROUP = `${MODULE_NAME} | CHANGE_SELECTED_GROUP`
const SELECT_GROUP = `${MODULE_NAME} | SELECT_GROUP`
const TURN_ON_EDITING_SUMMARY = `${MODULE_NAME} | TURN_ON_EDITING_SUMMARY`
const TURN_OFF_EDITING_SUMMARY = `${MODULE_NAME} | TURN_OFF_EDITING_SUMMARY`
const TURN_OFF_EDITING_SUMMARY_ALL_YEARS = `${MODULE_NAME} | TURN_OFF_EDITING_SUMMARY_ALL_YEARS`
const TURN_ON_EDITING_GROUP = `${MODULE_NAME} | TURN_ON_EDITING_GROUP`
const TURN_OFF_EDITING_GROUP = `${MODULE_NAME} | TURN_OFF_EDITING_GROUP`
const CHANGE_TAB = `${MODULE_NAME} | CHANGE_TAB`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

function refreshHolidayTypes() {
	return dispatch => {
		let apiUrl = API_URLS.holidayTypes()
		dispatch({ type: REFRESHING_HOLIDAY_TYPES, apiUrl })
		return api.setting.timeoff.holiday_types.get().then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_TYPES, data, apiUrl })
			return data
		})
	}
}

function refreshYearSummary() {
	return dispatch => {
		let apiUrl = API_URLS.yearSummary()
		dispatch({ type: REFRESHING_YEAR_SUMMARY, apiUrl })
		return api.setting.timeoff.holiday_types.getYearSummary().then(({ data }) => {
			dispatch({ type: REFRESHED_YEAR_SUMMARY, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroups() {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroups()
		dispatch({ type: REFRESHING_HOLIDAY_GROUPS, apiUrl })
		return api.setting.timeoff.holiday_groups.get().then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUPS, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroupsWithCount() {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupsWithCount()
		dispatch({ type: REFRESHING_HOLIDAY_GROUPS_WITH_COUNT, apiUrl })
		return api.setting.timeoff.holiday_groups.getWithCount().then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUPS_WITH_COUNT, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroupDetail(holidayGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupDetail(holidayGroupId)
		dispatch({ type: REFRESHING_HOLIDAY_GROUP_DETAIL, apiUrl })
		return api.setting.timeoff.holiday_groups.getDetail(holidayGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUP_DETAIL, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroupEmployees(holidayGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupEmployees(holidayGroupId)
		dispatch({ type: REFRESHING_HOLIDAY_GROUP_EMPLOYEES, apiUrl })
		return api.setting.timeoff.holiday_groups.getEmployees(holidayGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUP_EMPLOYEES, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroupLogs(holidayGroupId) {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupLogs(holidayGroupId)
		dispatch({ type: REFRESHING_HOLIDAY_GROUP_LOGS, apiUrl })
		return api.setting.timeoff.holiday_groups.holiday_group_logs.get(holidayGroupId).then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUP_LOGS, data, apiUrl })
			return data
		})
	}
}

function refreshHolidayGroupLogDetail(holidayGroupId, logId) {
	return dispatch => {
		let apiUrl = API_URLS.holidayGroupLogDetail(holidayGroupId, logId)
		dispatch({ type: REFRESHING_HOLIDAY_GROUP_LOG_DETAIL, apiUrl })
		return api.setting.timeoff.holiday_groups.holiday_group_logs.getDetail(holidayGroupId, logId).then(({ data }) => {
			dispatch({ type: REFRESHED_HOLIDAY_GROUP_LOG_DETAIL, data, apiUrl })
			return data
		})
	}
}

export function editYearSummary(year, holidayTypes, addedGroupIds) {
	return dispatch => {
		return api.setting.timeoff.holiday_types.editYearSummary(year, holidayTypes).then(() => {
			dispatch({ type: EDITED_YEAR_SUMMARY })
			addedGroupIds.forEach(groupId => dispatch(refreshHolidayGroupDetail(groupId)))
			dispatch(refreshYearSummary())
		})
	}
}

export function createHolidayType(data, addedGroupIds) {
	return dispatch => {
		return api.setting.timeoff.holiday_types.create(data).then(({ data: holidayType }) => {
			dispatch(refreshYearSummary())
			addedGroupIds.forEach(groupId => dispatch(refreshHolidayGroupDetail(groupId)))
			toast(`Successfully created ${data.name} holiday`)
			dispatch({ type: CREATED_HOLIDAY_TYPE, holidayType })
			return holidayType
		})
	}
}

export function deleteHolidayType(holidayTypeId) {
	return dispatch => {
		return api.setting.timeoff.holiday_types.delete(holidayTypeId).then(() => {
			dispatch(refreshHolidayTypes())
			dispatch(refreshYearSummary())
			toast(`Successfully deleted holiday`)
		})
	}
}

export function createHolidayGroup(data) {
	return dispatch => {
		return api.setting.timeoff.holiday_groups.create(data).then(({ data: holidayGroupWithCount }) => {
			toast(`Successfully created "${holidayGroupWithCount.name}" group`)
			dispatch(refreshYearSummary())
			dispatch(selectGroup(undefined))
			dispatch({ type: CREATED_HOLIDAY_GROUP, holidayGroupWithCount })
			dispatch(selectGroup(holidayGroupWithCount.id))
			return holidayGroupWithCount
		})
	}
}

export function editHolidayGroup(holidayGroupId, data) {
	return dispatch => {
		return api.setting.timeoff.holiday_groups.edit(holidayGroupId, data).then(({ data: holidayGroup }) => {
			toast(`Successfully edited "${holidayGroup.name}" group`)
			dispatch({ type: EDITED_HOLIDAY_GROUP, holidayGroup })
			dispatch(refreshHolidayGroupDetail(holidayGroupId))
			dispatch(refreshHolidayGroupLogs(holidayGroupId))
			return holidayGroup
		})
	}
}

export function deleteHolidayGroup(holidayGroupId) {
	return (dispatch, getState) => {
		const {
			publicHolidaySetting: { api: apiState },
		} = getState()
		const holidayGroupsWithCount = getObjectOrUndefined(apiState, API_URLS.holidayGroupsWithCount())
		return api.setting.timeoff.holiday_groups.delete(holidayGroupId).then(() => {
			toast(`Successfully deleted group`)
			dispatch({ type: DELETED_HOLIDAY_GROUP, groupId: holidayGroupId })
			if (holidayGroupsWithCount && holidayGroupsWithCount.length > 0) {
				dispatch(selectGroup(holidayGroupsWithCount[0].id))
			} else {
				dispatch(selectGroup(undefined))
			}
		})
	}
}

export function moveEmployees({ employeeIds, holidayGroupId }) {
	return (dispatch, getState) => {
		const {
			publicHolidaySetting: {
				data: { selectedGroupId },
			},
		} = getState()
		return api.setting.timeoff.holiday_groups.moveEmployees({ employeeIds, holidayGroupId }).then(() => {
			dispatch({ type: MOVED_EMPLOYEES })
			dispatch(refreshHolidayGroupsWithCount())
			dispatch(refreshHolidayGroupEmployees(selectedGroupId))
			dispatch(refreshHolidayGroupEmployees(holidayGroupId))
			dispatch(refreshHolidayGroupLogs(selectedGroupId))
			dispatch(refreshHolidayGroupLogs(holidayGroupId))
		})
	}
}

export function selectGroup(groupId) {
	return (dispatch, getState) => {
		// to clear selectedGroupId
		if (!groupId) {
			dispatch(changeSelectedGroup(undefined))
			return null
		}
		dispatch(turnOffEditingGroup())
		dispatch(changeSelectedGroup(groupId))
		const {
			publicHolidaySetting: { api },
		} = getState()
		const groupDetail = getObjectOrUndefined(api, API_URLS.holidayGroupDetail(groupId))
		const employees = getObjectOrUndefined(api, API_URLS.holidayGroupEmployees(groupId))
		const groupLogs = getObjectOrUndefined(api, API_URLS.holidayGroupLogs(groupId))
		if (!groupDetail) {
			dispatch(refreshHolidayGroupDetail(groupId))
		}
		if (!employees) {
			dispatch(refreshHolidayGroupEmployees(groupId))
		}
		if (!groupLogs) {
			dispatch(refreshHolidayGroupLogs(groupId))
		}
		dispatch({ type: SELECT_GROUP, groupId })
	}
}

function changeSelectedGroup(groupId) {
	return dispatch => {
		dispatch({ type: CHANGE_SELECTED_GROUP, groupId })
	}
}

export function turnOnEditingSummary(year) {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING_SUMMARY, year })
	}
}

export function turnOffEditingSummary(year) {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING_SUMMARY, year })
	}
}

export function turnOnEditingGroup() {
	return dispatch => {
		dispatch({ type: TURN_ON_EDITING_GROUP })
	}
}

export function turnOffEditingGroup() {
	return dispatch => {
		dispatch({ type: TURN_OFF_EDITING_GROUP })
	}
}

export function changeTab(tab) {
	return (dispatch, getState) => {
		const {
			publicHolidaySetting: {
				api,
				data: { selectedGroupId },
			},
		} = getState()
		const holidayGroupsWithCount = getObjectOrUndefined(api, API_URLS.holidayGroupsWithCount())
		const yearSummaryList = getObjectOrUndefined(api, API_URLS.yearSummary())

		// change tab only if tab number is one of the predefined tab
		if (Object.values(TABS).includes(tab)) {
			dispatch({ type: CHANGE_TAB, tab })

			if (tab === TABS.HOLIDAY_GROUP) {
				dispatch({ type: TURN_OFF_EDITING_SUMMARY_ALL_YEARS })
				if (holidayGroupsWithCount === undefined) {
					dispatch(refreshHolidayGroupsWithCount())
				}
				if (holidayGroupsWithCount && holidayGroupsWithCount.length > 0 && selectedGroupId === undefined) {
					dispatch(selectGroup(holidayGroupsWithCount[0].id))
				}
			} else if (tab === TABS.HOLIDAY_SUMMARY) {
				if (yearSummaryList === undefined) {
					dispatch(refreshYearSummary())
				}
			}
		} else {
			throw new Error('Tab not found')
		}
	}
}

export function fetchLogDetail(logId) {
	return (dispatch, getState) => {
		const {
			publicHolidaySetting: {
				data: { selectedGroupId },
			},
		} = getState()
		dispatch(refreshHolidayGroupLogDetail(selectedGroupId, logId))
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshHolidayGroups())
		dispatch(refreshHolidayGroupsWithCount()).then(holidayGroupsWithCount => {
			if (holidayGroupsWithCount.length > 0) {
				dispatch(selectGroup(holidayGroupsWithCount[0].id))
			}
		})
		dispatch(refreshHolidayTypes())
		dispatch(refreshYearSummary())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		isEditingGroup: false, // bool : true when group view is in editing state
		isEditingYears: [], // [number]: years that are currently in editing state
		selectedGroupId: undefined, // number : id of the selected group
		tab: TABS.HOLIDAY_GROUP, // @TABS enum: current view tab
	},
	refreshing: {},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHING_HOLIDAY_TYPES:
		case REFRESHING_YEAR_SUMMARY:
		case REFRESHING_HOLIDAY_GROUPS:
		case REFRESHING_HOLIDAY_GROUPS_WITH_COUNT:
		case REFRESHING_HOLIDAY_GROUP_DETAIL:
		case REFRESHING_HOLIDAY_GROUP_EMPLOYEES:
		case REFRESHING_HOLIDAY_GROUP_LOGS:
		case REFRESHING_HOLIDAY_GROUP_LOG_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: true,
				},
			}
		case REFRESHED_HOLIDAY_TYPES:
		case REFRESHED_YEAR_SUMMARY:
		case REFRESHED_HOLIDAY_GROUPS:
		case REFRESHED_HOLIDAY_GROUPS_WITH_COUNT:
		case REFRESHED_HOLIDAY_GROUP_DETAIL:
		case REFRESHED_HOLIDAY_GROUP_EMPLOYEES:
		case REFRESHED_HOLIDAY_GROUP_LOGS:
		case REFRESHED_HOLIDAY_GROUP_LOG_DETAIL:
			return {
				...state,
				refreshing: {
					...state.refreshing,
					[action.apiUrl]: false,
				},
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		// uncomment these to update local state after each corresponding POST action
		// case EDITED_YEAR_SUMMARY:
		case CREATED_HOLIDAY_TYPE:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.holidayTypes()]: [action.holidayType, ...state.api[API_URLS.holidayTypes()]],
				},
			}
		case CREATED_HOLIDAY_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.holidayGroupsWithCount()]: [
						...state.api[API_URLS.holidayGroupsWithCount()],
						action.holidayGroupWithCount,
					],
					[API_URLS.holidayGroups()]: [...state.api[API_URLS.holidayGroups()], action.holidayGroupWithCount],
				},
			}
		case EDITED_HOLIDAY_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.holidayGroupsWithCount()]: state.api[API_URLS.holidayGroupsWithCount()].map(group => {
						if (group.id === action.holidayGroup.id) {
							return {
								...group,
								name: action.holidayGroup.name,
							}
						}
						return group
					}),
				},
			}
		case DELETED_HOLIDAY_GROUP:
			return {
				...state,
				api: {
					...state.api,
					[API_URLS.holidayGroupsWithCount()]: state.api[API_URLS.holidayGroupsWithCount()].filter(
						group => group.id !== action.groupId
					),
					[API_URLS.holidayGroups()]: state.api[API_URLS.holidayGroups()].filter(group => group.id !== action.groupId),
				},
			}
		// case MOVED_EMPLOYEES:
		case CHANGE_SELECTED_GROUP:
			return {
				...state,
				data: {
					...state.data,
					selectedGroupId: action.groupId,
				},
			}
		case TURN_ON_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingYears: [...state.data.isEditingYears, action.year],
				},
			}
		case TURN_OFF_EDITING_SUMMARY:
			return {
				...state,
				data: {
					...state.data,
					isEditingYears: state.data.isEditingYears.filter(isEditingYear => isEditingYear !== action.year),
				},
			}
		case TURN_OFF_EDITING_SUMMARY_ALL_YEARS:
			return {
				...state,
				data: {
					...state.data,
					isEditingYears: [],
				},
			}
		case TURN_ON_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroup: true,
				},
			}
		case TURN_OFF_EDITING_GROUP:
			return {
				...state,
				data: {
					...state.data,
					isEditingGroup: false,
				},
			}
		case CHANGE_TAB:
			return {
				...state,
				data: {
					...state.data,
					tab: action.tab,
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
