import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let RequestTimeoffComponent

const RequestTimeoff = store => {
	if (RequestTimeoffComponent === undefined) {
		RequestTimeoffComponent = Loadable({
			loader: () => {
				return import('views/RequestTimeoff/containers/RequestTimeoffContainer')
			},
			loading: RouteLoader,
		})
	}
	return RequestTimeoffComponent
}

export default RequestTimeoff
