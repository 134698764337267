import { Intent } from '@blueprintjs/core'
import api from 'modules/api'
import { toast } from 'utils'
import _ from 'lodash'

import Question from 'modules/models/setting/Question'

// ------------------------------------
// Constants
// ------------------------------------
const MODULE_NAME = 'setting-employee-database'

export const API_URLS = {
	fieldGroups: () => `/api/setting/employee_database/field_groups/`,
	fields: fieldGroupId => `/api/setting/employee_database/field_groups/${fieldGroupId}/fields/`,
}

const REFRESHED_FIELD_GROUPS = `${MODULE_NAME} | REFRESHED FIELD GROUPS`
const REFRESHED_FIELDS = `${MODULE_NAME} | REFRESHED FIELDS`

const CREATED_FIELD_GROUP = `${MODULE_NAME} | CREATED FIELD GROUP`
const EDITED_FIELD_GROUP = `${MODULE_NAME} | EDITED FIELD GROUP`
const DELETED_FIELD_GROUP = `${MODULE_NAME} | DELETED FIELD GROUP`
const MOVED_UP_FIELD_GROUP = `${MODULE_NAME} | MOVED UP FIELD GROUP`
const MOVED_DOWN_FIELD_GROUP = `${MODULE_NAME} | MOVED DOWN FIELD GROUP`

const CREATING_FIELD = `${MODULE_NAME} | CREATING FIELD`
const CREATED_FIELD = `${MODULE_NAME} | CREATED FIELD`
const EDITED_FIELD = `${MODULE_NAME} | EDITED FIELD`
const DELETED_FIELD = `${MODULE_NAME} | DELETED FIELD`
const MOVED_UP_FIELD = `${MODULE_NAME} | MOVED UP FIELD`
const MOVED_DOWN_FIELD = `${MODULE_NAME} | MOVED DOWN FIELD`

const CREATING_QUESTION = `${MODULE_NAME} | CREATING QUESTION`
const CREATED_QUESTION = `${MODULE_NAME} | CREATED QUESTION`
const EDITED_QUESTION = `${MODULE_NAME} | EDITED QUESTION`
const DELETED_QUESTION = `${MODULE_NAME} | DELETED QUESTION`
const MOVED_UP_QUESTION = `${MODULE_NAME} | MOVED UP QUESTION`
const MOVED_DOWN_QUESTION = `${MODULE_NAME} | MOVED DOWN QUESTION`

const CREATED_CHOICE = `${MODULE_NAME} | CREATED CHOICE`
const EDITED_CHOICE = `${MODULE_NAME} | EDITED CHOICE`
const DELETED_CHOICE = `${MODULE_NAME} | DELETED CHOICE`
const MOVED_UP_CHOICE = `${MODULE_NAME} | MOVED UP CHOICE`
const MOVED_DOWN_CHOICE = `${MODULE_NAME} | MOVED DOWN CHOICE`

const ERROR_EDITING_FIELD_GROUP = `${MODULE_NAME} | ERROR EDITING FIELD GROUP`
const ERROR_DELETING_FIELD_GROUP = `${MODULE_NAME} | ERROR DELETING FIELD GROUP`
const ERROR_MOVING_UP_FIELD_GROUP = `${MODULE_NAME} | ERROR MOVING UP FIELD GROUP`
const ERROR_MOVING_DOWN_FIELD_GROUP = `${MODULE_NAME} | ERROR MOVING DOWN FIELD GROUP`

const ERROR_CREATING_FIELD = `${MODULE_NAME} | ERROR CREATING FIELD`
const ERROR_EDITING_FIELD = `${MODULE_NAME} | ERROR EDITING FIELD`
const ERROR_DELETING_FIELD = `${MODULE_NAME} | ERROR DELETING FIELD`
const ERROR_MOVING_UP_FIELD = `${MODULE_NAME} | ERROR MOVING UP FIELD`
const ERROR_MOVING_DOWN_FIELD = `${MODULE_NAME} | ERROR MOVING DOWN FIELD`

const ERROR_CREATING_QUESTION = `${MODULE_NAME} | ERROR CREATING QUESTION`
const ERROR_EDITING_QUESTION = `${MODULE_NAME} | ERROR EDITING QUESTION`
const ERROR_DELETING_QUESTION = `${MODULE_NAME} | ERROR DELETING QUESTION`
const ERROR_MOVING_UP_QUESTION = `${MODULE_NAME} | ERROR MOVING UP QUESTION`
const ERROR_MOVING_DOWN_QUESTION = `${MODULE_NAME} | ERROR MOVING DOWN QUESTION`

const ERROR_CREATING_CHOICE = `${MODULE_NAME} | ERROR CREATING CHOICE`
const ERROR_EDITING_CHOICE = `${MODULE_NAME} | ERROR EDITING CHOICE`
const ERROR_DELETING_CHOICE = `${MODULE_NAME} | ERROR DELETING CHOICE`
const ERROR_MOVING_UP_CHOICE = `${MODULE_NAME} | ERROR MOVING UP CHOICE`
const ERROR_MOVING_DOWN_CHOICE = `${MODULE_NAME} | ERROR MOVING DOWN CHOICE`

const ERROR_REFRESHING_FIELDS = `${MODULE_NAME} | ERROR REFRESHING FIELDS`

const LOAD_MODULE = `${MODULE_NAME} | LOAD MODULE`
const UNLOAD_MODULE = `${MODULE_NAME} | UNLOAD MODULE`

// ------------------------------------
// Actions
// ------------------------------------

export function refreshFieldGroups() {
	return dispatch => {
		const apiUrl = API_URLS.fieldGroups()
		return api.setting.employee_database.field_groups
			.get()
			.then(({ data }) => {
				dispatch({
					type: REFRESHED_FIELD_GROUPS,
					data: data.sort((a, b) => a.order - b.order),
					apiUrl,
				})
				return data
			})
			.then(data => {
				data.forEach(fieldGroup => {
					dispatch(refreshFields(fieldGroup.id))
				})
			})
	}
}

export function refreshFields(fieldGroupId) {
	return dispatch => {
		const apiUrl = API_URLS.fields(fieldGroupId)
		return api.setting.employee_database.field_groups.fields
			.get(fieldGroupId)
			.then(({ data }) => {
				dispatch({ type: REFRESHED_FIELDS, data, apiUrl })
				return data
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_REFRESHING_FIELDS })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function createFieldGroup(name) {
	return dispatch => {
		return api.setting.employee_database.field_groups.create(name).then(() => {
			dispatch({ type: CREATED_FIELD_GROUP })
			dispatch(refreshFieldGroups())
			toast(`Created "${name}" group`)
		})
	}
}

export function editFieldGroup(fieldGroup, name) {
	return dispatch => {
		return api.setting.employee_database.field_groups
			.edit(fieldGroup.id, name)
			.then(() => {
				dispatch({ type: EDITED_FIELD_GROUP })
				dispatch(refreshFieldGroups())
				toast(`Renamed "${fieldGroup.name}" group to "${name}"`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_EDITING_FIELD_GROUP })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function deleteFieldGroup(fieldGroup) {
	return dispatch => {
		return api.setting.employee_database.field_groups
			.delete(fieldGroup.id)
			.then(() => {
				dispatch({ type: DELETED_FIELD_GROUP })
				dispatch(refreshFieldGroups())
				return () => toast(`Deleted "${fieldGroup.name}" group`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_DELETING_FIELD_GROUP })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveUpFieldGroup(fieldGroupId) {
	return dispatch => {
		return api.setting.employee_database.field_groups
			.moveUp(fieldGroupId)
			.then(() => {
				dispatch({ type: MOVED_UP_FIELD_GROUP })
				dispatch(refreshFieldGroups())
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_UP_FIELD_GROUP })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveDownFieldGroup(fieldGroupId) {
	return dispatch => {
		return api.setting.employee_database.field_groups
			.moveDown(fieldGroupId)
			.then(() => {
				dispatch({ type: MOVED_DOWN_FIELD_GROUP })
				dispatch(refreshFieldGroups())
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_DOWN_FIELD_GROUP })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function createField(fieldGroup, data) {
	return dispatch => {
		dispatch({ type: CREATING_FIELD })
		return api.setting.employee_database.field_groups.fields
			.create(fieldGroup.id, data)
			.then(({ data }) => {
				dispatch(refreshFields(fieldGroup.id)).then(() => {
					dispatch({ type: CREATED_FIELD })
					toast(`Created "${data.name}" field in "${fieldGroup.name}" group`)
				})
				return data
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_CREATING_FIELD })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function editField(fieldGroupId, fieldId, data) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields
			.edit(fieldGroupId, fieldId, data)
			.then(() => {
				dispatch({ type: EDITED_FIELD })
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_EDITING_FIELD })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function deleteField(fieldGroup, field) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields
			.delete(fieldGroup.id, field.id)
			.then(() => {
				dispatch({ type: DELETED_FIELD })
				dispatch(refreshFields(fieldGroup.id))
				return () => toast(`Deleted "${field.name}" field from "${fieldGroup.name}" group`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_DELETING_FIELD })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveUpField(fieldGroupId, fieldId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields
			.moveUp(fieldGroupId, fieldId)
			.then(() => {
				dispatch({ type: MOVED_UP_FIELD })
				dispatch(refreshFields(fieldGroupId))
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_UP_FIELD })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveDownField(fieldGroupId, fieldId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields
			.moveDown(fieldGroupId, fieldId)
			.then(() => {
				dispatch({ type: MOVED_DOWN_FIELD })
				dispatch(refreshFields(fieldGroupId))
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_DOWN_FIELD })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function createQuestion(fieldGroupId, fieldId, data) {
	return dispatch => {
		dispatch({ type: CREATING_QUESTION })
		return api.setting.employee_database.field_groups.fields.questions
			.create(fieldGroupId, fieldId, data)
			.then(() => {
				dispatch(refreshFields(fieldGroupId)).then(() => {
					dispatch({ type: CREATED_QUESTION })
				})
				return () => toast(`Added "${data.name}" detail`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_CREATING_QUESTION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function editQuestion(fieldGroupId, fieldId, questionId, data) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions
			.edit(fieldGroupId, fieldId, questionId, data)
			.then(() => {
				dispatch({ type: EDITED_QUESTION })
				dispatch(refreshFields(fieldGroupId))
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_EDITING_QUESTION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function deleteQuestion(fieldGroupId, fieldId, question) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions
			.delete(fieldGroupId, fieldId, question.id)
			.then(() => {
				dispatch({ type: DELETED_QUESTION })
				dispatch(refreshFields(fieldGroupId))
				return () => toast(`Deleted "${question.name}" detail`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_DELETING_QUESTION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveUpQuestion(fieldGroupId, fieldId, questionId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions
			.moveUp(fieldGroupId, fieldId, questionId)
			.then(() => {
				dispatch({ type: MOVED_UP_QUESTION })
				dispatch(refreshFields(fieldGroupId))
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_UP_QUESTION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveDownQuestion(fieldGroupId, fieldId, questionId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions
			.moveDown(fieldGroupId, fieldId, questionId)
			.then(() => {
				dispatch({ type: MOVED_DOWN_QUESTION })
				dispatch(refreshFields(fieldGroupId))
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_DOWN_QUESTION })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function createChoice(fieldGroupId, fieldId, questionId, content) {
	return dispatch => {
		let fieldsUrl = API_URLS.fields(fieldGroupId)
		return api.setting.employee_database.field_groups.fields.questions.choices
			.create(fieldGroupId, fieldId, questionId, content)
			.then(({ data: choice }) => {
				dispatch({ type: CREATED_CHOICE, fieldsUrl, fieldId, questionId, choice })
				toast(`Added "${content}" option`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_CREATING_CHOICE })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function editChoice(fieldGroupId, fieldId, questionId, choiceId, content) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions.choices
			.edit(fieldGroupId, fieldId, questionId, choiceId, content)
			.then(() => {
				dispatch({ type: EDITED_CHOICE })
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_EDITING_CHOICE })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function deleteChoice(fieldGroupId, fieldId, question, choice) {
	return dispatch => {
		let fieldsUrl = API_URLS.fields(fieldGroupId)
		return api.setting.employee_database.field_groups.fields.questions.choices
			.delete(fieldGroupId, fieldId, question.id, choice.id)
			.then(() => {
				dispatch({ type: DELETED_CHOICE, fieldsUrl, fieldId, questionId: question.id, choiceId: choice.id })
				toast(`Deleted "${choice.content}" choice from "${question.name}" question`)
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_DELETING_CHOICE })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveUpChoice(fieldGroupId, fieldId, questionId, choiceId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions.choices
			.moveUp(fieldGroupId, fieldId, questionId, choiceId)
			.then(() => {
				dispatch({ type: MOVED_UP_CHOICE })
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_UP_CHOICE })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function moveDownChoice(fieldGroupId, fieldId, questionId, choiceId) {
	return dispatch => {
		return api.setting.employee_database.field_groups.fields.questions.choices
			.moveDown(fieldGroupId, fieldId, questionId, choiceId)
			.then(() => {
				dispatch({ type: MOVED_DOWN_CHOICE })
			})
			.catch(err => {
				console.log(err, 'err')
				let errors = err.response.jsonData.errors
				dispatch({ type: ERROR_MOVING_DOWN_CHOICE })
				toast(errors[0], { intent: Intent.DANGER })
			})
	}
}

export function loadModule() {
	return dispatch => {
		dispatch(refreshFieldGroups())
		dispatch({ type: LOAD_MODULE })
	}
}

export function unloadModule() {
	return dispatch => {
		dispatch({ type: UNLOAD_MODULE })
	}
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	api: {},
	data: {
		isCreating: false,
	},
}

export default function registerReducer(state = initialState, action) {
	switch (action.type) {
		case REFRESHED_FIELD_GROUPS:
		case REFRESHED_FIELDS:
			return {
				...state,
				api: {
					...state.api,
					[action.apiUrl]: action.data,
				},
			}
		case CREATING_FIELD:
		case CREATING_QUESTION:
			return {
				...state,
				data: {
					...state.data,
					isCreating: true,
				},
			}
		case CREATED_FIELD:
		case CREATED_QUESTION:
			return {
				...state,
				data: {
					...state.data,
					isCreating: false,
				},
			}
		case CREATED_CHOICE:
			return {
				...state,
				api: {
					...state.api,
					[action.fieldsUrl]: state.api[action.fieldsUrl].map(fieldWithQuestion => {
						if (fieldWithQuestion.field.id === action.fieldId) {
							return {
								...fieldWithQuestion,
								questions: fieldWithQuestion.questions.map(question => {
									if (question.id === action.questionId) {
										return new Question({
											...question,
											choices: [...question.choices, action.choice],
										})
									}
									return question
								}),
							}
						}
						return fieldWithQuestion
					}),
				},
			}
		case DELETED_CHOICE:
			return {
				...state,
				api: {
					...state.api,
					[action.fieldsUrl]: state.api[action.fieldsUrl].map(fieldWithQuestion => {
						if (fieldWithQuestion.field.id === action.fieldId) {
							return {
								...fieldWithQuestion,
								questions: fieldWithQuestion.questions.map(question => {
									if (question.id === action.questionId) {
										return new Question({
											...question,
											choices: question.choices.filter(choice => choice.id !== action.choiceId),
										})
									}
									return question
								}),
							}
						}
						return fieldWithQuestion
					}),
				},
			}
		case UNLOAD_MODULE:
			return _.cloneDeep(initialState)
		default:
			return state
	}
}
