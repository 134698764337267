import { s, string } from 'modules/types'
import { Question, QuestionType } from './Question'

export class IndividualQuestionSummary {
	constructor({ question, answer }) {
		this.question = new Question(question)
		this.answer = answer
	}
}

export const IndividualQuestionSummaryType = s({
	question: QuestionType,
	answer: string,
})

export default IndividualQuestionSummary
