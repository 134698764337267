import { s, number, string } from 'modules/types'

export class EmployeeWithGroupPosition {
	constructor({ id, name, NIK, group_name: groupName, position_name: positionName }) {
		this.id = id
		this.name = name
		this.NIK = NIK
		this.groupName = groupName
		this.positionName = positionName
	}
}

export const EmployeeWithGroupPositionType = s({
	id: number.isRequired,
	name: string.isRequired,
	NIK: string.isRequired,
	groupName: string.isRequired,
	positionName: string.isRequired,
})

export default EmployeeWithGroupPosition
