import Achievement from './Achievement'
import Announcement from './Announcement'
import Company from './Company'
import Employee from './Employee'
import Idea from './Idea'
import News from './News'

export default {
	Achievement,
	Announcement,
	Company,
	Employee,
	Idea,
	News,
}
