import { s, number } from 'modules/types'

export class DayCount {
	constructor({ days }) {
		this.days = days
	}
}

export const DayCountType = s({
	days: number.isRequired,
})

export default DayCount
