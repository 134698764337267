import { s, arrayOf, bool, number, string } from 'modules/types'

class AppraisalSessionSnapshot {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const AppraisalSessionSnapshotType = {
	id: number.isRequired,
	name: string.isRequired,
}

class AppraisalGroupSnapshot {
	constructor({ id, name }) {
		this.id = id
		this.name = name
	}
}

const AppraisalGroupSnapshotType = {
	id: number.isRequired,
	name: string.isRequired,
}

class AppraisalAnswerChoiceSnapshot {
	constructor({ id, content, order, disabled }) {
		this.id = id
		this.content = content
		this.order = order
		this.disabled = disabled
	}
}

const AppraisalAnswerChoiceSnapshotType = {
	id: number.isRequired,
	content: string.isRequired,
	order: number.isRequired,
	disabled: bool.isRequired,
}

class AppraisalQuestionSnapshot {
	constructor({
		id,
		self_content: selfContent,
		alternative_content: alternativeContent,
		type,
		linear_lowest_label: linearLowestLabel,
		linear_highest_label: linearHighestLabel,
		answer_choices: answerChoices = null,
	}) {
		this.id = id
		this.selfContent = selfContent
		this.alternativeContent = alternativeContent
		this.type = type
		this.linearLowestLabel = linearLowestLabel
		this.linearHighestLabel = linearHighestLabel
		if (answerChoices !== null) {
			this.answerChoices = answerChoices.map(item => new AppraisalAnswerChoiceSnapshot(item))
		}
	}
}

const AppraisalQuestionSnapshotType = {
	id: number.isRequired,
	selfContent: string,
	alternativeContent: string,
	type: number.isRequired,
	linearLowestLabel: string.isRequired,
	linearHighestLabel: string.isRequired,
	answerChoices: arrayOf(AppraisalAnswerChoiceSnapshotType),
}

class AppraisalQuestionLinkSnapshot {
	constructor({ id, question, weight, order }) {
		this.id = id
		this.question = new AppraisalQuestionSnapshot(question)
		this.weight = weight
		this.order = order
	}
}

const AppraisalQuestionLinkSnapshotType = {
	id: number.isRequired,
	question: AppraisalQuestionSnapshotType,
	weight: number.isRequired,
	order: number.isRequired,
}

class AppraisalCategorySnapshot {
	constructor({
		id,
		name,
		order,
		view_permissions: viewPermissions,
		answer_permissions: answerPermissions,
		question_links: questionLinks,
	}) {
		this.id = id
		this.name = name
		this.order = order
		this.viewPermissions = viewPermissions
		this.answerPermissions = answerPermissions
		this.questionLinks = questionLinks.map(item => new AppraisalQuestionLinkSnapshot(item))
	}
}

const AppraisalCategorySnapshotType = {
	id: number.isRequired,
	name: string.isRequired,
	order: number.isRequired,
	viewPermissions: arrayOf(number),
	answerPermissions: arrayOf(number),
	questionLinks: arrayOf(AppraisalQuestionLinkSnapshotType),
}

class AppraisalTemplateLogSnapshot {
	constructor({ id, description, session, group, categories }) {
		this.id = id
		this.description = description
		this.session = new AppraisalSessionSnapshot(session)
		this.group = new AppraisalGroupSnapshot(group)
		this.categories = categories.map(item => new AppraisalCategorySnapshot(item))
	}
}

const AppraisalTemplateLogSnapshotType = {
	id: number.isRequired,
	description: string,
	session: AppraisalSessionSnapshotType,
	group: AppraisalGroupSnapshotType,
	categories: arrayOf(AppraisalCategorySnapshotType),
}

export class AppraisalTemplateLogExtInfo {
	constructor({
		old_appraisal_template_snapshot: oldAppraisalTemplateSnapshot = null,
		new_appraisal_template_snapshot: newAppraisalTemplateSnapshot = null,
		appraisal_template_snapshot: appraisalTemplateSnapshot = null,
	}) {
		if (oldAppraisalTemplateSnapshot !== null) {
			this.oldAppraisalTemplateSnapshot = new AppraisalTemplateLogSnapshot(oldAppraisalTemplateSnapshot)
		}
		if (newAppraisalTemplateSnapshot !== null) {
			this.newAppraisalTemplateSnapshot = new AppraisalTemplateLogSnapshot(newAppraisalTemplateSnapshot)
		}
		if (appraisalTemplateSnapshot !== null) {
			this.appraisalTemplateSnapshot = new AppraisalTemplateLogSnapshot(appraisalTemplateSnapshot)
		}
	}
}

export const AppraisalTemplateLogExtInfoType = s({
	oldAppraisalTemplateSnapshot: AppraisalTemplateLogSnapshotType,
	newAppraisalTemplateSnapshot: AppraisalTemplateLogSnapshotType,
	appraisalTemplateSnapshot: AppraisalTemplateLogSnapshotType,
})

export default AppraisalTemplateLogExtInfo
