import { s, arrayOf, number, object } from 'modules/types'
import WalletLog, { WalletLogType } from './WalletLog'

export class WalletLogWithInitial {
	constructor({ initial_balance: initialBalance, initial_date: initialDate, logs }) {
		this.initialBalance = initialBalance
		this.initialDate = new Date(initialDate)
		this.logs = logs.map(item => new WalletLog(item))
	}
}

export const WalletLogWithInitialType = s({
	initialBalance: number.isRequired,
	initialDate: object.isRequired,
	logs: arrayOf(WalletLogType),
})

export default WalletLogWithInitial
