import { s, arrayOf, number, string } from 'modules/types'

class ComparisonFilter {
	constructor({
		type,
		employee_id: employeeId,
		employee_detail_question_id: employeeDetailQuestionId,
		employee_detail_answer_id: employeeDetailAnswerId,
	}) {
		this.type = type
		this.employeeId = employeeId
		this.employeeDetailQuestionId = employeeDetailQuestionId
		this.employeeDetailAnswerId = employeeDetailAnswerId
	}
}

const ComparisonFilterType = s({
	type: number.isRequired, // @COMPARISON_FILTER_TYPE
	employeeId: number.isRequired,
	employeeDetailQuestionId: number.isRequired,
	employeeDetailAnswerId: number.isRequired,
})

class DefaultQuestionLink {
	constructor({ default_question_link_id: defaultQuestionLinkId, number, alternative_content: alternativeContent }) {
		this.defaultQuestionLinkId = defaultQuestionLinkId
		this.number = number
		this.alternativeContent = alternativeContent
	}
}

const DefaultQuestionLinkType = s({
	defaultQuestionLinkId: number.isRequired,
	number: number.isRequired,
	alternativeContent: string.isRequired,
})

class ComparisonFilterQuestion {
	constructor({ default_question_link_id: defaultQuestionLinkId, average_score: averageScore }) {
		this.defaultQuestionLinkId = defaultQuestionLinkId
		this.averageScore = averageScore
	}
}

const ComparisonFilterQuestionType = s({
	defaultQuestionLinkId: number.isRequired,
	averageScore: number.isRequired,
})

class ComparisonResult {
	constructor({ filter, questions, weighted_average_score: weightedAverageScore = null }) {
		this.filter = new ComparisonFilter(filter)
		this.questions = questions.map(item => new ComparisonFilterQuestion(item))
		if (weightedAverageScore !== null) {
			this.weightedAverageScore = weightedAverageScore.toFixed(2)
		}
	}
}

const ComparisonResultType = s({
	filter: ComparisonFilterType,
	questions: arrayOf(ComparisonFilterQuestionType),
	weightedAverageScore: number.isRequired,
})

class DefaultCategoryWithQuestions {
	constructor({
		default_category_id: defaultCategoryId,
		default_category_name: defaultCategoryName,
		question_links: questionLinks,
	}) {
		this.defaultCategoryId = defaultCategoryId
		this.defaultCategoryName = defaultCategoryName
		this.questionLinks = questionLinks.map(item => new DefaultQuestionLink(item))
	}
}

const DefaultCategoryWithQuestionsType = s({
	defaultCategoryId: number.isRequired,
	defaultCategoryName: string.isRequired,
	questionLinks: arrayOf(DefaultQuestionLinkType),
})

class CategoriesWithComparisons {
	constructor({ default_categories: defaultCategories, comparison_result: comparisonResult }) {
		this.defaultCategories = defaultCategories.map(item => new DefaultCategoryWithQuestions(item))
		this.comparisonResult = comparisonResult.map(item => new ComparisonResult(item))
	}
}

export const CategoriesWithComparisonsType = s({
	defaultCategories: arrayOf(DefaultCategoryWithQuestionsType),
	comparisonResult: arrayOf(ComparisonResultType),
})

export default CategoriesWithComparisons
