import Loadable from 'react-loadable'
import RouteLoader from 'components/RouteLoader'

let EmployeeDatabaseComponent

const EmployeeDatabase = store => {
	if (EmployeeDatabaseComponent === undefined) {
		EmployeeDatabaseComponent = Loadable({
			loader: () => {
				return import('views/EmployeeDatabase/containers/EmployeeDatabaseRouteContainer')
			},
			loading: RouteLoader,
		})
	}
	return EmployeeDatabaseComponent
}

export default EmployeeDatabase
